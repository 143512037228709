import React, { useMemo } from 'react'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'

import { Parameter } from 'gen/cloudTruthRestApi'
import { Select } from 'antd'

interface Props {
  onChange: (value: string) => void
  parameter: Parameter
  value: string
  secret?: boolean
  valueText: string
}

export function EnumValueSelector(props: Props) {
  const { parameter, value, onChange, secret, valueText } = props
  const types = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())
  const withSecrets = secret ? [{ value: '*****', label: '*****' }] : []

  const enumTypes = useMemo(() => {
    const findType = (type: string) => types.find((t) => t.name === type)

    if (parameter.type === 'enum') {
      return parameter?.rules[0]?.constraints
    }
    return findType(parameter.type!)!.rules[0].constraints
  }, [parameter?.type, parameter?.rules, types])

  return (
    <Select
      value={secret ? '*****' : valueText || value}
      disabled={secret}
      placeholder="Select a value"
      style={{ width: 250, margin: '5px 50px 15px 0px' }}
      onChange={onChange}
      options={enumTypes?.map((type: string) => ({ value: type, label: type })).concat(withSecrets)} // enumTypes
    />
  )
}

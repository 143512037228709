//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

interface GrantCreate {
  principal: string
  scope: string
  role?: nullable<RoleEnum>
}

import { Grant, PaginatedGrantList, PatchedGrantUpdate, RoleEnum } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetGrants = createAsyncThunk<PaginatedGrantList, null, ThunkConfig>(
  'grant/getGrants',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'grants', null, thunkApi)
)

export const GetGrant = createAsyncThunk<Grant, string, ThunkConfig>(
  'grant/getGrant',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `grants/${id}`, null, thunkApi)
)

export const CreateGrant = createAsyncThunk<Grant, GrantCreate, ThunkConfig>(
  'grant/createGrant',
  async (grant, thunkApi) => {
    return await handleFetch(Verbs.Post, 'grants', grant, thunkApi)
  }
)

export const UpdateGrant = createAsyncThunk<Grant, PatchedGrantUpdate, ThunkConfig>(
  'grant/updateGrant',
  async (grant, thunkApi) => await handleFetch(Verbs.Put, `grants/${grant.id}`, grant, thunkApi)
)

export const DeleteGrant = createAsyncThunk<string, string, ThunkConfig>(
  'grant/deleteGrant',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `grants/${id}`, null, thunkApi)
)
export const DeleteAllGrants = createAsyncThunk<string, string, ThunkConfig>(
  'grant/deleteAllGrants',
  async (scope, thunkApi) =>
    await handleFetch(Verbs.Delete, `grants/multi`, null, thunkApi, buildQuery({ scope }))
)

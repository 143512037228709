//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { CheckCircleOutlined, ExclamationCircleOutlined, UpOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { TableProps, Tooltip } from 'antd'
import { awsPullEntitySelectors, useSelectPull } from 'data/actions/awsImport/selectors'
import { pushEntitySelectors, useSelectPush } from 'data/actions/awsPush/selectors'

import { ActionEnum } from 'data/actions/utils'
import CopySecretText from 'components/misc/CopySecretText'
import { CustomThunk } from 'data/dataUtils'
import { GetAwsPullTaskSteps } from 'data/actions/awsImport/actions'
import { GetAwsPushTaskSteps } from 'data/actions/awsPush/actions'
import { GetAzureKeyVaultPushTaskSteps } from 'data/actions/akvPush/actions'
import { GetGithubPullTaskSteps } from 'data/actions/githubImport/actions'
import { RenderExpandIconProps } from 'rc-table/lib/interface'
import { Table } from 'components/Table'
import { TaskStep } from 'gen/cloudTruthRestApi'
import styles from './TaskTable.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useOrganizationParams } from 'router/customHooks'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  taskId: TaskStep['id']
  actionType: ActionEnum
  onlyChanges: boolean
}

const PAGE_SIZE = 50

export function StepTable(props: Props) {
  const { taskId, actionType, onlyChanges } = props
  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState<TaskStep[]>([])
  const { actionId, integrationId } = useOrganizationParams()!
  const pushUpdated = pushEntitySelectors.selectById(useSelectPush(), actionId || '')?.latest_task
  const pullUpdated = awsPullEntitySelectors.selectById(
    useSelectPull(),
    actionId || ''
  )?.latest_task
  const dispatch = useAppDispatch()

  const filteredStepsList = useMemo(() => {
    if (onlyChanges) {
      return steps.filter((step) => {
        return !!step.operation
      })
    } else {
      return steps
    }
  }, [onlyChanges, steps])

  useEffect(() => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(
          GetAwsPushTaskSteps({ integrationId: integrationId!, actionId: actionId!, taskId })
        ).then(({ payload }: CustomThunk) => {
          setSteps(payload.results)
          setLoading(false)
        })
        break

      case ActionEnum.AzureKeyVaultPush:
        dispatch(
          GetAzureKeyVaultPushTaskSteps({
            integrationId: integrationId!,
            actionId: actionId!,
            taskId,
          })
        ).then(({ payload }: CustomThunk) => {
          setSteps(payload.results)
          setLoading(false)
        })
        break

      case ActionEnum.AwsPull:
        dispatch(
          GetAwsPullTaskSteps({ integrationId: integrationId!, actionId: actionId!, taskId })
        ).then(({ payload }: CustomThunk) => {
          setSteps(payload.results)
          setLoading(false)
        })
        break

      case ActionEnum.GithubPull:
        dispatch(
          GetGithubPullTaskSteps({ integrationId: integrationId!, actionId: actionId!, taskId })
        ).then(({ payload }: CustomThunk) => {
          setSteps(payload.results)
          setLoading(false)
        })
        break

      default:
        break
    }
  }, [pushUpdated, pullUpdated, actionId, dispatch, integrationId, taskId, actionType])

  const [columns, setColumns] = useState<TableProps<TaskStep>['columns']>([
    {
      title: 'Resource Name',
      key: 'venue_name',
      ellipsis: true,
      width: 350,
      sorter: (a, b) => {
        return (a.venue_name || '').localeCompare(b.venue_name || '')
      },
      render: (_value, task: TaskStep) => (
        <div className={styles.valueEllipses}>{valueOrEmDash(task.venue_name)}</div>
      ),
    },
    {
      title: 'Summary',
      key: 'summary',
      render: (_value, task: TaskStep) => <div>{valueOrEmDash(task.summary)}</div>,
    },
  ])

  const expandedRowRender = (step: TaskStep) => (
    <CopySecretText notSecret label="Error Details" value={step.error_detail || ''} />
  )

  const rowExpandable = (step: TaskStep) => !!step.error_detail

  const expandIcon = ({ expanded, onExpand, record }: RenderExpandIconProps<TaskStep>) =>
    record.error_detail ? (
      expanded ? (
        <Tooltip placement="right" title="Hide error details">
          <UpOutlined onClick={(e) => onExpand(record, e)} />
        </Tooltip>
      ) : (
        <Tooltip placement="right" title="Expand to see error details">
          <ExclamationCircleOutlined
            className={styles.failure}
            onClick={(e) => onExpand(record, e)}
          />
        </Tooltip>
      )
    ) : record.success ? (
      <CheckCircleOutlined className={styles.success} />
    ) : null

  return (
    <div className={styles.container}>
      <Table
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        expandable={{ expandedRowRender, rowExpandable, expandIcon }}
        columns={!loading ? resizableColumns(columns!, setColumns) : undefined}
        pagination={{
          total: filteredStepsList.length,
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
          size: 'small',
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : filteredStepsList}
        rowKey={(push: TaskStep) => push.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>
                    {onlyChanges && steps.length > 0
                      ? 'There are no steps for this task'
                      : 'No resources were found in the integration - check your action resource definition'}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  )
}

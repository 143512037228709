import { ActionButton } from 'components/ActionButton'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import styles from './DeleteErrorModal.module.scss'

interface Props {
  closeModal: () => void
  errorMessage: nullable<string>
  subject: string
  visible: boolean
  plural?: boolean
}

export function DeleteErrorModal(props: Props) {
  const { closeModal, errorMessage, subject, visible, plural } = props

  return (
    <Modal className={styles.modal} closable={false} open={visible} footer={null} destroyOnClose>
      <div className={styles.container}>
        <ExclamationCircleOutlined className={styles.warningIcon} />
        <div className={styles.bodyContainer}>
          <h3>
            {plural ? 'These' : 'This'} {subject} can not be deleted.
          </h3>
          <p>[{errorMessage}]</p>
        </div>

        <ActionButton type="primary" onClick={closeModal}>
          Okay
        </ActionButton>
      </div>
    </Modal>
  )
}

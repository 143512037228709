/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuditTrail {
  /** @format uri */
  url: string
  /**
   * A unique identifier for the audit record.
   * @format uuid
   */
  id: string
  /** The action that was taken. */
  action: string
  /** The id of the object associated with the action. */
  object_id: string
  /** The name of the object associated with the action, if applicable. */
  object_name: string
  /** The type of object associated with the action. */
  object_type: ObjectTypeEnum
  /**
   * The timestamp of the activity that was audited.
   * @format date-time
   */
  timestamp: string
  /** Details of the user associated with this change. */
  user: User
}

export interface AuditTrailSummary {
  /**
   * The earliest audit record timestamp available.
   * @format date-time
   */
  earliest: string | null
  /** The maximum number of days the system will save auditing records, based on your subscription. */
  max_days: number
  /** The maximum number of auditing records the system will save based on your subscription. */
  max_records: number
  /** The total number of audit records available. */
  total: number
}

export interface AwsIntegration {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
  name: string
  /** An optional description for the integration. */
  description?: string
  /** The status of the integration connection with the third-party provider as of the `status_last_checked_at` field.  The status is updated automatically by the server when the integration is modified. */
  status: StatusEnum
  /** If an error occurs, more details will be available in this field. */
  status_detail: string
  /**
   * The last time the status was evaluated.
   * @format date-time
   */
  status_last_checked_at: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  fqn: string
  /** The type of integration. */
  type: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /**
   * The AWS Account ID.
   * @minLength 12
   * @maxLength 12
   * @pattern ^[0-9]+$
   */
  aws_account_id: string
  /** The AWS regions to integrate with. */
  aws_enabled_regions: AwsRegionEnum[]
  /** The AWS services to integrate with. */
  aws_enabled_services: AwsServiceEnum[]
  /** This is a shared secret between the AWS Administrator who set up your IAM trust relationship and your CloudTruth AWS Integration.  CloudTruth will generate a random value for you to give to your AWS Administrator in order to create the necessary IAM role for proper access. */
  aws_external_id: string
  /**
   * If present, this is the KMS Key Id that is used to push values.  This key must be accessible in the AWS account (it cannot be an ARN to a key in another AWS account).
   * @maxLength 256
   * @pattern ^[a-zA-Z0-9:/_\-]+$
   */
  aws_kms_key_id?: string | null
  /**
   * The role that CloudTruth will assume when interacting with your AWS Account through this integration.  The role is configured by your AWS Account Administrator.  If your AWS Administrator provided you with a value use it, otherwise make your own role name and give it to your AWS Administrator.
   * @minLength 2
   * @maxLength 64
   * @pattern ^[\w+=,.@\-]+$
   */
  aws_role_name: string
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
}

export interface AwsIntegrationCreate {
  /** An optional description for the integration. */
  description?: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /**
   * The AWS Account ID.
   * @minLength 12
   * @maxLength 12
   * @pattern ^[0-9]+$
   */
  aws_account_id: string
  /** The AWS regions to integrate with. */
  aws_enabled_regions: AwsRegionEnum[]
  /** The AWS services to integrate with. */
  aws_enabled_services: AwsServiceEnum[]
  /**
   * This is a shared secret between the AWS Administrator who set up your IAM trust relationship and your CloudTruth AWS Integration.  CloudTruth will generate a random value for you to give to your AWS Administrator in order to create the necessary IAM role for proper access.
   * @minLength 2
   * @maxLength 1224
   * @pattern ^[\w+=,.@:/\-]*$
   */
  aws_external_id?: string
  /**
   * If present, this is the KMS Key Id that is used to push values.  This key must be accessible in the AWS account (it cannot be an ARN to a key in another AWS account).
   * @maxLength 256
   * @pattern ^[a-zA-Z0-9:/_\-]+$
   */
  aws_kms_key_id?: string | null
  /**
   * The role that CloudTruth will assume when interacting with your AWS Account through this integration.  The role is configured by your AWS Account Administrator.  If your AWS Administrator provided you with a value use it, otherwise make your own role name and give it to your AWS Administrator.
   * @minLength 2
   * @maxLength 64
   * @pattern ^[\w+=,.@\-]+$
   */
  aws_role_name: string
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
}

export interface AwsIntegrationScan {
  /** The AWS region to use.  This region must be enabled in the integration. */
  region: AwsRegionEnum
  /** The AWS service to use.  This service must be enabled in the integration. */
  service: AwsServiceEnum
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource: string | null
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface AwsPull {
  /** @format uri */
  url: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id: string
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task: AwsPullTask | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode: ModeEnum
  /** The AWS region to use.  This region must be enabled in the integration. */
  region: AwsRegionEnum
  /** The AWS service to use.  This service must be enabled in the integration. */
  service: AwsServiceEnum
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource: string | null
}

export interface AwsPullSyncActionRequest {
  /** Allows you to set the dry_run flag on the pull action before triggering a sync. */
  dry_run?: boolean
}

/** Pull task for an AWS integration. */
export interface AwsPullTask {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the task.
   * @format uuid
   */
  id: string
  /**
   * The reason why this task was triggered.
   * @maxLength 256
   */
  reason?: string | null
  /** Indicates task steps were only simulated, not actually performed. */
  dry_run?: boolean
  /** The current state of this task. */
  state?: StateEnum
  /**
   * If an error occurs early during processing, before attempting to process values, this code may be helpful in determining the problem.
   * @maxLength 256
   */
  error_code?: string | null
  /** If an error occurs early during processing, before attempting to process values, this detail may be helpful in determining the problem. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Pull task step for an AWS integration. */
export interface AwsPullTaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/**
 * Push actions can be configured to send configuration and secrets to
 * integrations when tags are updated.
 */
export interface AwsPush {
  /** @format uri */
  url: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id: string
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task: AwsPushTask | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
  /** Include templates in the values being pushed. */
  include_templates?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** Projects that are included in the push. */
  projects: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags: string[]
  /** The AWS region this push targets.  This region must be enabled in the integration. */
  region: AwsRegionEnum
  /** The AWS service this push targets.  This service must be enabled in the integration. */
  service: AwsServiceEnum
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource: string | null
}

/** Push task for an AWS integration. */
export interface AwsPushTask {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the task.
   * @format uuid
   */
  id: string
  /**
   * The reason why this task was triggered.
   * @maxLength 256
   */
  reason?: string | null
  /** Indicates task steps were only simulated, not actually performed. */
  dry_run?: boolean
  /** The current state of this task. */
  state?: StateEnum
  /**
   * If an error occurs early during processing, before attempting to process values, this code may be helpful in determining the problem.
   * @maxLength 256
   */
  error_code?: string | null
  /** If an error occurs early during processing, before attempting to process values, this detail may be helpful in determining the problem. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Push task step for an AWS integration. */
export interface AwsPushTaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Update a push.  The `region` and `service` cannot be changed on an existing push. */
export interface AwsPushUpdate {
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** Projects that are included in the push. */
  projects: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags: string[]
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource: string | null
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
  /** Include templates in the values being pushed. */
  include_templates?: boolean
}

export enum AwsRegionEnum {
  AfSouth1 = 'af-south-1',
  ApEast1 = 'ap-east-1',
  ApNortheast1 = 'ap-northeast-1',
  ApNortheast2 = 'ap-northeast-2',
  ApNortheast3 = 'ap-northeast-3',
  ApSouth1 = 'ap-south-1',
  ApSoutheast1 = 'ap-southeast-1',
  ApSoutheast2 = 'ap-southeast-2',
  CaCentral1 = 'ca-central-1',
  CnNorth1 = 'cn-north-1',
  CnNorthwest1 = 'cn-northwest-1',
  EuCentral1 = 'eu-central-1',
  EuNorth1 = 'eu-north-1',
  EuSouth1 = 'eu-south-1',
  EuWest1 = 'eu-west-1',
  EuWest2 = 'eu-west-2',
  EuWest3 = 'eu-west-3',
  MeSouth1 = 'me-south-1',
  SaEast1 = 'sa-east-1',
  UsEast1 = 'us-east-1',
  UsEast2 = 'us-east-2',
  UsWest1 = 'us-west-1',
  UsWest2 = 'us-west-2',
}

export enum AwsServiceEnum {
  S3 = 's3',
  Secretsmanager = 'secretsmanager',
  Ssm = 'ssm',
}

export interface AzureKeyVaultIntegration {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
  name: string
  /** An optional description for the integration. */
  description?: string
  /** The status of the integration connection with the third-party provider as of the `status_last_checked_at` field.  The status is updated automatically by the server when the integration is modified. */
  status: StatusEnum
  /** If an error occurs, more details will be available in this field. */
  status_detail: string
  /**
   * The last time the status was evaluated.
   * @format date-time
   */
  status_last_checked_at: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  fqn: string
  /** The type of integration. */
  type: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
  /** The Azure Key Vault name. */
  vault_name: string
  /**
   * The Azure Tenant ID.
   * @format uuid
   */
  tenant_id: string
}

export interface AzureKeyVaultIntegrationCreate {
  /** An optional description for the integration. */
  description?: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
  /**
   * The Azure Key Vault name.
   * @maxLength 24
   */
  vault_name: string
  /**
   * The Azure Tenant ID.
   * @format uuid
   */
  tenant_id: string
}

export interface AzureKeyVaultIntegrationScan {
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource: string | null
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface AzureKeyVaultPull {
  /** @format uri */
  url: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id: string
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task: AzureKeyVaultPullTask | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode: ModeEnum
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource: string | null
}

export interface AzureKeyVaultPullSyncActionRequest {
  /** Allows you to set the dry_run flag on the pull action before triggering a sync. */
  dry_run?: boolean
}

/** Pull task for an Azure Key Vault integration. */
export interface AzureKeyVaultPullTask {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the task.
   * @format uuid
   */
  id: string
  /**
   * The reason why this task was triggered.
   * @maxLength 256
   */
  reason?: string | null
  /** Indicates task steps were only simulated, not actually performed. */
  dry_run?: boolean
  /** The current state of this task. */
  state?: StateEnum
  /**
   * If an error occurs early during processing, before attempting to process values, this code may be helpful in determining the problem.
   * @maxLength 256
   */
  error_code?: string | null
  /** If an error occurs early during processing, before attempting to process values, this detail may be helpful in determining the problem. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Pull task step for an Azure Key Vault integration. */
export interface AzureKeyVaultPullTaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/**
 * Push actions can be configured to send configuration and secrets to
 * integrations when tags are updated.
 */
export interface AzureKeyVaultPush {
  /** @format uri */
  url: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id: string
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task: AzureKeyVaultPushTask | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
  /** Include templates in the values being pushed. */
  include_templates?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** Projects that are included in the push. */
  projects: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags: string[]
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource: string | null
}

/** Push task for an Azure Key Vault integration. */
export interface AzureKeyVaultPushTask {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the task.
   * @format uuid
   */
  id: string
  /**
   * The reason why this task was triggered.
   * @maxLength 256
   */
  reason?: string | null
  /** Indicates task steps were only simulated, not actually performed. */
  dry_run?: boolean
  /** The current state of this task. */
  state?: StateEnum
  /**
   * If an error occurs early during processing, before attempting to process values, this code may be helpful in determining the problem.
   * @maxLength 256
   */
  error_code?: string | null
  /** If an error occurs early during processing, before attempting to process values, this detail may be helpful in determining the problem. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Push task step for an Azure Key Vault integration. */
export interface AzureKeyVaultPushTaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Update a push. */
export interface AzureKeyVaultPushUpdate {
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** Projects that are included in the push. */
  projects: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags: string[]
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource: string | null
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
}

/** Environment, parameter-type, and project (including parameters and values) data at a point in time. */
export interface BackupDataSnapshot {
  environments: Record<string, BackupEnvironment>
  types: Record<string, BackupParameterType>
  projects: Record<string, BackupProject>
  /** @format date-time */
  timestamp: string
}

/** Basic environment data at a point in time. */
export interface BackupEnvironment {
  name: string
  parent?: string | null
  description?: string | null
}

/** External reference data at a point in time. */
export interface BackupExternalReference {
  fqn: string
  jmes_path?: string | null
}

/** Parameter, rule, and value data at a point in time. */
export interface BackupParameter {
  rules: Record<string, BackupParameterRule>
  values: Record<string, BackupParameterValue | null>
  name: string
  param_type: string
  project: string
  secret: boolean
  description?: string | null
}

/** Rule that is applied to a parameter or parameter-type at a point in time. */
export interface BackupParameterRule {
  rule_type: string
  constraints: string[]
}

/** Parameter type (and rule) data at a point in time. */
export interface BackupParameterType {
  rules: Record<string, BackupParameterRule>
  name: string
  parent: string
  description?: string | null
}

/** Parameter value data at a point in time. */
export interface BackupParameterValue {
  external: BackupExternalReference | null
  environment: string
  evaluated: boolean
  source?: string | null
  project?: string | null
  value?: string | null
  raw?: string | null
}

/** Environment, parameter-type, and project (including parameters and values) data at a point in time. */
export interface BackupProject {
  parameters: Record<string, BackupParameter>
  templates: Record<string, BackupTemplate>
  name: string
  parent?: string | null
  description?: string | null
}

/** Template data at a given point in time. */
export interface BackupTemplate {
  name: string
  text: string
  description: string | null
}

export interface DiscoveredContent {
  venue_id: string
  venue_name: string
  environment_name: string
  project_name: string
  parameter_name: string
}

export interface DiscoveryResult {
  matched: Record<string, DiscoveredContent[]>
  skipped: Record<string, string>
}

export interface Environment {
  /**
   * The URL for the environment.
   * @format uri
   */
  url: string
  id: string
  ledger_id: string
  /** The environment name. */
  name: string
  /** A description of the environment.  You may find it helpful to document how this environment is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * Environments can inherit from a single parent environment which provides values for parameters when specific environments do not have a value set.  Every organization has one default environment that cannot be removed.
   * @format uri
   */
  parent: string | null
  /** This is the opposite of `parent`, see that field for more details. */
  children: string[]
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the environment, if the environment is access-controlled. */
  role: RoleEnum | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface EnvironmentCopy {
  /**
   * The environment name.
   * @maxLength 256
   */
  name: string
  /** A description of the environment.  You may find it helpful to document how this environment is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** When copying child Environments, this indicates what name to use for each Environment.  If no name is provided for a given child Environment, a "-1" will be appended to its name.  Note that this name mapping can be used for Environments of any level of depth in the parent-child hierarchy. */
  child_environment_names?: Record<string, string>
  /** If true, copy child environments recursively.  If false, only copy the specified project. */
  recursive?: boolean
}

export interface EnvironmentCreate {
  /**
   * The environment name.
   * @maxLength 256
   */
  name: string
  /** A description of the environment.  You may find it helpful to document how this environment is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * Environments can inherit from a single parent environment which provides values for parameters when specific environments do not have a value set.  Every organization has one default environment that cannot be removed.
   * @format uri
   */
  parent?: string | null
  /**
   * If parent has RBAC enabled and this field is True, RBAC will be enabled and grants from the parent are copied to this environment.  If parent has RBAC enabled and this field is False, RBAC will be enabled but only the minimal necessary grants will be assigned to the newly created environment.   Not used when RBAC is not enabled for the parent.
   * @default false
   */
  copy_rbac?: boolean
}

export interface EnvironmentUpdate {
  id: string
  /**
   * The environment name.
   * @maxLength 256
   */
  name: string
  /** A description of the environment.  You may find it helpful to document how this environment is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * Environments can inherit from a single parent environment which provides values for parameters when specific environments do not have a value set.  Every organization has one default environment that cannot be removed.
   * @format uri
   */
  parent?: string | null
  /** This is the opposite of `parent`, see that field for more details. */
  children: string[]
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the environment, if the environment is access-controlled. */
  role: RoleEnum | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export enum EventTypesEnum {
  ParameterCreated = 'parameter_created',
  ParameterUpdated = 'parameter_updated',
  ParameterDeleted = 'parameter_deleted',
  ProjectCreated = 'project_created',
  ProjectUpdated = 'project_updated',
  ProjectDeleted = 'project_deleted',
  EnvironmentCreated = 'environment_created',
  EnvironmentUpdated = 'environment_updated',
  EnvironmentDeleted = 'environment_deleted',
  ValueCreated = 'value_created',
  ValueUpdated = 'value_updated',
  ValueDeleted = 'value_deleted',
}

export interface ExpiredValue {
  parameter: string
  environment: string
  /** @format date-time */
  expires_at: string
  value_id: string
  parameter_id: string
  environment_id: string
  project_id: string
}

export interface ExpiredValuesResponse {
  results: ExpiredValue[]
}

export interface ExpiringValue {
  parameter: string
  environment: string
  /** @format date-time */
  expires_at: string
  value_id: string
  parameter_id: string
  environment_id: string
  project_id: string
  expired: boolean
}

export interface ExpiringValuesResponse {
  results: ExpiringValue[]
}

export interface FeaturesResponse {
  self_hosted: boolean
  aws_integration: boolean
  azure_integration: boolean
  github_integration: boolean
}

export interface GeneratedPasswordResponse {
  /** The generated password value */
  value: string
}

export interface GitHubIntegration {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
  name: string
  /** An optional description for the integration. */
  description?: string
  /** The status of the integration connection with the third-party provider as of the `status_last_checked_at` field.  The status is updated automatically by the server when the integration is modified. */
  status: StatusEnum
  /** If an error occurs, more details will be available in this field. */
  status_detail: string
  /**
   * The last time the status was evaluated.
   * @format date-time
   */
  status_last_checked_at: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  fqn: string
  /** The type of integration. */
  type: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  gh_installation_id: number
  gh_organization_slug: string
}

export interface GitHubIntegrationCreate {
  /** An optional description for the integration. */
  description?: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  gh_installation_id: number
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface GitHubPull {
  /** @format uri */
  url: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id: string
  /**
   * The action name.
   * @maxLength 256
   */
  name: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task: GitHubPullTask | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode: ModeEnum
}

/** Pull task for a GitHub integration. */
export interface GitHubPullTask {
  /** @format uri */
  url: string
  /**
   * The unique identifier for the task.
   * @format uuid
   */
  id: string
  /**
   * The reason why this task was triggered.
   * @maxLength 256
   */
  reason?: string | null
  /** Indicates task steps were only simulated, not actually performed. */
  dry_run?: boolean
  /** The current state of this task. */
  state?: StateEnum
  /**
   * If an error occurs early during processing, before attempting to process values, this code may be helpful in determining the problem.
   * @maxLength 256
   */
  error_code?: string | null
  /** If an error occurs early during processing, before attempting to process values, this detail may be helpful in determining the problem. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** Pull task step for a GitHub integration. */
export interface GitHubPullTaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface Grant {
  /** @format uri */
  url: string
  /**
   * A unique identifier for the grant.
   * @format uuid
   */
  id: string
  /**
   * The URI of a principal for the grant; this must reference a user or group.
   * @format uri
   */
  principal: string
  /**
   * The URI of a scope for the grant; this must reference a project or environment.
   * @format uri
   */
  scope: string
  /** The role that the principal has in the given scope. */
  role: RoleEnum
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface GrantUpdate {
  /** @format uri */
  url: string
  /**
   * A unique identifier for the grant.
   * @format uuid
   */
  id: string
  /**
   * The URI of a principal for the grant; this must reference a user or group.
   * @format uri
   */
  principal: string
  /**
   * The URI of a scope for the grant; this must reference a project or environment.
   * @format uri
   */
  scope: string
  /** The role that the principal has in the given scope. */
  role: RoleEnum
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface Group {
  /** @format uri */
  url: string
  /**
   * The unique identifier of a group.
   * @format uuid
   */
  id: string
  /**
   * The group name.
   * @maxLength 150
   */
  name: string
  /** A description of the group.  You may find it helpful to document how this group is used to assist others when they need to maintain this organization. */
  description?: string
  users: string[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface GroupAddRemove {
  /** @format uri */
  url: string
  /**
   * The unique identifier of a group.
   * @format uuid
   */
  id: string
  /** The group name. */
  name: string
  /** A description of the group.  You may find it helpful to document how this group is used to assist others when they need to maintain this organization. */
  description: string
  users: string[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export enum HistoryModelEnum {
  Parameter = 'Parameter',
  ParameterRule = 'ParameterRule',
  Value = 'Value',
}

export enum HistoryTypeEnum {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Nothing = 'nothing',
}

export interface ImportCreateRequest {
  /** Project name or identifier */
  project: string
  /** Environment name or identifier */
  environment?: string | null
  /** Text to turn into variables */
  body: string
  /** Parameter names that should be secrets */
  secrets: string[]
  /** Parameter names to be ignored */
  ignore: string[]
  /**
   * Create the project (if it does not exist)
   * @default true
   */
  add_project?: boolean
  /**
   * Create the environment (if it does not exist)
   * @default true
   */
  add_environment?: boolean
  /**
   * Create the parameters (if they do not exist)
   * @default true
   */
  add_parameters?: boolean
  /**
   * Create the environment value override (if they do not exist)
   * @default true
   */
  add_overrides?: boolean
  /**
   * Skip adding a parameter override if it is the same
   * @default true
   */
  inherit_on_same?: boolean
}

export interface ImportCreateResponse {
  /** Project parameter values after import */
  parameter: ImportParameter[]
}

/** Describes an imported parameter. */
export interface ImportParameter {
  /** Project name */
  project_name: string
  /** Project identifier */
  project_id?: string | null
  /** Environment where parameter is defined */
  environment_name: string
  /** Environment identifier where value is set */
  environment_id?: string | null
  /** Parameter name */
  parameter_name: string
  /** Parameter identifier */
  parameter_id?: string | null
  /**
   * Whether to treat the parameter as a secret
   * @default false
   */
  secret?: boolean
  /** Parameter value in the specified environment */
  value: string
  /** Parameter value identifier in the environment */
  value_id?: string | null
  /**
   * Date and time the parameter value was created
   * @format date-time
   */
  created_at: string | null
  /**
   * Date and time the parameter value was updated
   * @format date-time
   */
  modified_at: string | null
  /** Action taken on environment value for parameter */
  action: string
}

/** Describes the content available at a given location. */
export interface IntegrationNode {
  fqn: string
  node_type: NodeTypeEnum
  secret?: boolean
  name?: string
  jmespath?: string | null
  content_type?: string | null
  content_data?: string | null
  content_size?: number | null
  content_keys?: string[] | null
  venue_id?: string | null
}

export interface Invitation {
  /** @format uri */
  url: string
  /**
   * The unique identifier of an invitation.
   * @format uuid
   */
  id: string
  /**
   * The email address of the user to be invited.
   * @format email
   * @maxLength 254
   */
  email: string
  /** The role that the user will have in the organization, should the user accept. */
  role: RoleEnum
  /**
   * The user that created the invitation.
   * @format uri
   */
  inviter: string
  /** The name of the user that created the invitation. */
  inviter_name: string
  /** The current state of the invitation. */
  state: string
  /** Additional details about the state of the invitation. */
  state_detail: string
  /**
   * The resulting membership, should the user accept.
   * @format uri
   */
  membership: string | null
  /**
   * The organization that the user will become a member of, should the user accept.
   * @format uri
   */
  organization: string
}

export interface InvitationCreate {
  /**
   * The email address of the user to be invited.
   * @format email
   * @maxLength 254
   */
  email: string
  /** The role that the user will have in the organization, should the user accept. */
  role: RoleEnum
}

export interface Membership {
  /** @format uri */
  url: string
  /**
   * A unique identifier for the membership.
   * @format uuid
   */
  id: string
  /**
   * The user of the membership.
   * @format uri
   */
  user: string
  /**
   * The organization that the user is a member of.
   * @format uri
   */
  organization: string
  /** The role that the user has in the organization. */
  role: RoleEnum
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface MembershipCreate {
  /**
   * The user of the membership.
   * @format uri
   */
  user: string
  /** The role that the user has in the organization. */
  role: RoleEnum
}

export enum ModeEnum {
  Mapped = 'mapped',
  Pattern = 'pattern',
}

export enum NodeTypeEnum {
  Directory = 'directory',
  File = 'file',
  Value = 'value',
}

export enum ObjectTypeEnum {
  AwsIntegration = 'AwsIntegration',
  Environment = 'Environment',
  GitHubIntegration = 'GitHubIntegration',
  Grant = 'Grant',
  Invitation = 'Invitation',
  Membership = 'Membership',
  Organization = 'Organization',
  Parameter = 'Parameter',
  ParameterRule = 'ParameterRule',
  ParameterType = 'ParameterType',
  ParameterTypeRule = 'ParameterTypeRule',
  Project = 'Project',
  Pull = 'Pull',
  Push = 'Push',
  ServiceAccount = 'ServiceAccount',
  Tag = 'Tag',
  Task = 'Task',
  Template = 'Template',
  Value = 'Value',
}

export enum OperationEnum {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export interface Organization {
  /** @format uri */
  url: string
  /** A unique identifier for the organization. */
  id: string
  /**
   * The organization name.
   * @maxLength 256
   */
  name: string
  /**
   * A regular expression project names must match
   * @maxLength 255
   */
  project_name_pattern?: string
  /** If set, we are performing maintenance on this organization and have disabled making changes */
  maintenance: boolean
  /** Multi-factor authentication for the organization */
  mfa_enabled?: boolean
  /** The current version of this Organization */
  version: VersionEnum
  notification_config?: Record<string, any>
  /** Indicates if this Organization is the one currently targeted by the Bearer token used by the client to authorize. */
  current: boolean
  /** Your role in the organization. */
  role: RoleEnum
  /** @format date-time */
  subscription_expires_at: string | null
  subscription_features: string[]
  subscription_id: string | null
  subscription_plan_id: string | null
  subscription_plan_name: string | null
  /** If set, the organization has Copilot enabled. */
  copilot_enabled: boolean
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface OrganizationCreate {
  /**
   * The organization name.
   * @maxLength 256
   */
  name: string
  /** Multi-factor authentication for the organization */
  mfa_enabled?: boolean
}

export interface PaginatedAuditTrailList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AuditTrail[]
}

export interface PaginatedAwsIntegrationList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsIntegration[]
}

export interface PaginatedAwsPullList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPull[]
}

export interface PaginatedAwsPullTaskList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPullTask[]
}

export interface PaginatedAwsPullTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPullTaskStep[]
}

export interface PaginatedAwsPushList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPush[]
}

export interface PaginatedAwsPushTaskList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPushTask[]
}

export interface PaginatedAwsPushTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AwsPushTaskStep[]
}

export interface PaginatedAzureKeyVaultIntegrationList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultIntegration[]
}

export interface PaginatedAzureKeyVaultPullList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPull[]
}

export interface PaginatedAzureKeyVaultPullTaskList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPullTask[]
}

export interface PaginatedAzureKeyVaultPullTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPullTaskStep[]
}

export interface PaginatedAzureKeyVaultPushList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPush[]
}

export interface PaginatedAzureKeyVaultPushTaskList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPushTask[]
}

export interface PaginatedAzureKeyVaultPushTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: AzureKeyVaultPushTaskStep[]
}

export interface PaginatedEnvironmentList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Environment[]
}

export interface PaginatedGitHubIntegrationList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: GitHubIntegration[]
}

export interface PaginatedGitHubPullList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: GitHubPull[]
}

export interface PaginatedGitHubPullTaskList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: GitHubPullTask[]
}

export interface PaginatedGitHubPullTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: GitHubPullTaskStep[]
}

export interface PaginatedGrantList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Grant[]
}

export interface PaginatedGroupList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Group[]
}

export interface PaginatedIntegrationNodeList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: IntegrationNode[]
}

export interface PaginatedInvitationList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Invitation[]
}

export interface PaginatedMembershipList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Membership[]
}

export interface PaginatedOrganizationList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Organization[]
}

export interface PaginatedParameterDualityList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ParameterDuality[]
}

export interface PaginatedParameterList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Parameter[]
}

export interface PaginatedParameterRuleList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ParameterRule[]
}

export interface PaginatedParameterTypeList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ParameterType[]
}

export interface PaginatedParameterTypeRuleList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ParameterTypeRule[]
}

export interface PaginatedProjectList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Project[]
}

export interface PaginatedServiceAccountList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: ServiceAccount[]
}

export interface PaginatedTagList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Tag[]
}

export interface PaginatedTaskStepList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: TaskStep[]
}

export interface PaginatedTemplateList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Template[]
}

export interface PaginatedUserList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: User[]
}

export interface PaginatedValueList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: Value[]
}

export interface PaginatedWebHooksList {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: WebHooks[]
}

/** A single parameter inside of a project. */
export interface Parameter {
  /**
   * The parameter url.
   * @format uri
   */
  url: string
  id: string
  ledger_id: string
  /** The parameter name. */
  name: string
  description?: string
  secret: boolean
  /**
   *
   *         The type of this Parameter.  If not provided, this will default to
   *         a string for Parameters that are not overrides or to the overridden
   *         Parameter's type for Parameters that are overrides.
   *
   */
  type?: string
  /** Rules applied to this parameter. */
  rules: ParameterRule[]
  /**
   * The project url.
   * @format uri
   */
  project: string
  /** The project name that the parameter is within. */
  project_name: string
  /** Templates that reference this Parameter.  This field is not valid for history requests. */
  referencing_templates: string[]
  /** Dynamic values that reference this Parameter.  This field is not valid for history requests. */
  referencing_values: string[]
  /**
   *
   *             This dictionary has keys that correspond to environment urls, and values
   *             that correspond to the effective value for this parameter in that environment.
   *             Each parameter has an effective value in every environment based on
   *             project dependencies and environment inheritance.
   *
   *             The effective value is found by looking (within the keyed environment) up
   *             the project dependencies by parameter name.  If a value is not found, the
   *             parent environment is consulted with the same logic to locate a value.  It
   *             is possible for there to be a `null` value record for an environment, which
   *             means there is no value set; it is also possible for there to be a value record
   *             with a `value` of `null`, which means the value was explicitly set to `null`.
   *
   *             If the value's parameter does not match the enclosing parameter (holding the
   *             values array) then that value is flowing in through project dependencies.
   *             Clients must recognize this in case the user asks to modify the value; in this
   *             case the client must POST a new Value to the current parameter to override the
   *             value coming in from the project dependency.
   *
   *             If the Value.environment matches the key, then it is an explicit value set for
   *             that environment.  If they differ, the value was obtained from a parent
   *             environment (directly or indirectly).  If the value is None then no value has
   *             ever been set in any environment for this parameter within all the project
   *             dependencies.
   *
   */
  values: Record<string, Value | null>
  /**
   *
   *         Identical to values, except the dictionary is flattened to a list.
   *         Note that in this case, the environment in the Value is the environment
   *         asked for, not the environment where it was obtained.
   *
   */
  values_flat: Value[]
  /**
   * If this parameter's project depends on another project which provides a parameter of the same name, this parameter overrides the one provided by the dependee.  You can use this field to determine if there will be side-effects the user should know about when deleting a parameter.  Deleting a parameter that overrides another one due to an identical name will uncover the one from the dependee project.
   * @format uri
   */
  overrides: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  expires?: string | null
  expiration_action?: Record<string, any>
  /** If this parameter expires, the status of the last attempt to handle its expiration */
  expired_status: Record<string, any>
}

/** A single parameter inside of a project. */
export interface ParameterCopy {
  /**
   * The parameter name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter.  You may find it helpful to document how this parameter is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * The project url.
   * @format uri
   */
  project: string
}

/** A single parameter inside of a project. */
export interface ParameterCreate {
  /**
   * The parameter name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter.  You may find it helpful to document how this parameter is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** Indicates if this content is secret or not.  External values are inspected on-demand to ensure they align with the parameter's secret setting and if they do not, those external values are not allowed to be used. */
  secret?: boolean
  /**
   *
   *         The type of this Parameter.  If not provided, this will default to
   *         a string for Parameters that are not overrides or to the overridden
   *         Parameter's type for Parameters that are overrides.
   *
   */
  type?: string
  expires?: string | null
  expiration_action?: Record<string, any>
}

/**
 * Details about a parameter at two timepoints.
 *
 * If t1 or t2 is null then the parameter did not exist at that
 * timepoint.
 */
export interface ParameterDuality {
  t1: Parameter | null
  t2: Parameter | null
}

export interface ParameterExport {
  /** The exported parameter body. */
  body: string
  /** If True, the exported parameters include one or more secrets. */
  has_secret: boolean
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterRule {
  /**
   * The URL for the parameter rule.
   * @format uri
   */
  url: string
  /** @format uuid */
  id: string
  /** Deprecated. A UUID string identifying this parameter rule. */
  ledger_id: string
  /**
   * The parameter this rule is for.
   * @format uri
   */
  parameter: string
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterRuleCreate {
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
}

export enum ParameterRuleTypeEnum {
  Min = 'min',
  Max = 'max',
  MinLen = 'min_len',
  MaxLen = 'max_len',
  Regex = 'regex',
  OneOf = 'one_of',
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterRuleUpdate {
  /** @format uuid */
  id: string
  /**
   * The parameter this rule is for.
   * @format uri
   */
  parameter: string
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface ParameterTimeline {
  /** The number of records in this response. */
  count: number
  /**
   * If present, additional history can be retrieved using this timestamp in the next call for the as_of query parameter value.
   * @format date-time
   */
  next_as_of?: string
  results: ParameterTimelineEntry[]
}

/** Details about a single change. */
export interface ParameterTimelineEntry {
  history_type: HistoryTypeEnum
  /** @format date-time */
  modified_at: string | null
  modified_by?: string
  /** The affected environment(s). */
  history_environments: ParameterTimelineEntryEnvironment[]
  /** The component of the parameter that changed. */
  history_model: HistoryModelEnum
  /** The affected parameter. */
  history_parameter: ParameterTimelineEntryParameter
}

export interface ParameterTimelineEntryEnvironment {
  /**
   * A unique identifier for the environment.
   * @format uuid
   */
  environment_id: string | null
  /** The environment name. */
  name: string
  /** Indicates if the value change was direct or if it flowed into the environment. If `true` then the value was actually set directly into this environment. If `false` then the environment has no value set directly so it inherited the value from its parent. */
  override: boolean
}

export interface ParameterTimelineEntryParameter {
  /**
   * A unique identifier for the parameter.
   * @format uuid
   */
  parameter_id: string | null
  /** The parameter name. */
  name: string
}

export interface ParameterType {
  /**
   * The URL for the parameter type.
   * @format uri
   */
  url: string
  /**
   * A unique identifier for the parameter type.
   * @format uuid
   */
  id: string
  /** Deprecated. A UUID string identifying this parameter type. */
  ledger_id: string
  /**
   * The parameter type name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter type, provide documentation on how to use this type here. */
  description?: string
  /** Rules applied to this parameter. */
  rules: ParameterTypeRule[]
  /**
   * The URL for this parameter type's parent
   * @format uri
   */
  parent: string | null
  /** Name of the parent ParameterType (if any). */
  parent_name: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface ParameterTypeCreate {
  /**
   * The parameter type name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter type, provide documentation on how to use this type here. */
  description?: string
  /**
   * The URL for this parameter type's parent
   * @format uri
   */
  parent: string
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterTypeRule {
  /**
   * The URL for the project.
   * @format uri
   */
  url: string
  /** @format uuid */
  id: string
  /** Deprecated. UUID string identifying this parameter type rule. */
  ledger_id: string
  /**
   * The type this rule is for.
   * @format uri
   */
  parameter_type: string
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterTypeRuleCreate {
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface ParameterTypeRuleUpdate {
  /** @format uuid */
  id: string
  /**
   * The type this rule is for.
   * @format uri
   */
  parameter_type: string
  type: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface ParameterTypeUpdate {
  /**
   * A unique identifier for the parameter type.
   * @format uuid
   */
  id: string
  /**
   * The parameter type name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter type, provide documentation on how to use this type here. */
  description?: string
  /**
   * The URL for this parameter type's parent
   * @format uri
   */
  parent: string
  /** Rules applied to this parameter. */
  rules: ParameterTypeRule[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** A single parameter inside of a project. */
export interface ParameterUpdate {
  id: string
  /**
   * The parameter name.
   * @maxLength 256
   */
  name: string
  /** A description of the parameter.  You may find it helpful to document how this parameter is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** Indicates if this content is secret or not.  External values are inspected on-demand to ensure they align with the parameter's secret setting and if they do not, those external values are not allowed to be used. */
  secret: boolean
  /**
   *
   *         The type of this Parameter.  If not provided, this will default to
   *         a string for Parameters that are not overrides or to the overridden
   *         Parameter's type for Parameters that are overrides.
   *
   */
  type?: string
  /**
   * The project url.
   * @format uri
   */
  project: string
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  expires?: string | null
  expiration_action?: Record<string, any>
  /** If this parameter expires, the status of the last attempt to handle its expiration */
  expired_status: Record<string, any>
}

export interface PatchedAwsIntegration {
  /** @format uri */
  url?: string
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id?: string
  name?: string
  /** An optional description for the integration. */
  description?: string
  /** The status of the integration connection with the third-party provider as of the `status_last_checked_at` field.  The status is updated automatically by the server when the integration is modified. */
  status?: StatusEnum
  /** If an error occurs, more details will be available in this field. */
  status_detail?: string
  /**
   * The last time the status was evaluated.
   * @format date-time
   */
  status_last_checked_at?: string | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  fqn?: string
  /** The type of integration. */
  type?: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /**
   * The AWS Account ID.
   * @minLength 12
   * @maxLength 12
   * @pattern ^[0-9]+$
   */
  aws_account_id?: string
  /** The AWS regions to integrate with. */
  aws_enabled_regions?: AwsRegionEnum[]
  /** The AWS services to integrate with. */
  aws_enabled_services?: AwsServiceEnum[]
  /** This is a shared secret between the AWS Administrator who set up your IAM trust relationship and your CloudTruth AWS Integration.  CloudTruth will generate a random value for you to give to your AWS Administrator in order to create the necessary IAM role for proper access. */
  aws_external_id?: string
  /**
   * If present, this is the KMS Key Id that is used to push values.  This key must be accessible in the AWS account (it cannot be an ARN to a key in another AWS account).
   * @maxLength 256
   * @pattern ^[a-zA-Z0-9:/_\-]+$
   */
  aws_kms_key_id?: string | null
  /**
   * The role that CloudTruth will assume when interacting with your AWS Account through this integration.  The role is configured by your AWS Account Administrator.  If your AWS Administrator provided you with a value use it, otherwise make your own role name and give it to your AWS Administrator.
   * @minLength 2
   * @maxLength 64
   * @pattern ^[\w+=,.@\-]+$
   */
  aws_role_name?: string
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface PatchedAwsPull {
  /** @format uri */
  url?: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id?: string
  /**
   * The action name.
   * @maxLength 256
   */
  name?: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task?: AwsPullTask | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values?: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode?: ModeEnum
  /** The AWS region to use.  This region must be enabled in the integration. */
  region?: AwsRegionEnum
  /** The AWS service to use.  This service must be enabled in the integration. */
  service?: AwsServiceEnum
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource?: string | null
}

/** Update a push.  The `region` and `service` cannot be changed on an existing push. */
export interface PatchedAwsPushUpdate {
  /**
   * The action name.
   * @maxLength 256
   */
  name?: string
  /** The optional description for the action. */
  description?: string
  /** Projects that are included in the push. */
  projects?: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags?: string[]
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource?: string | null
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
  /** Include templates in the values being pushed. */
  include_templates?: boolean
}

export interface PatchedAzureKeyVaultIntegration {
  /** @format uri */
  url?: string
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id?: string
  name?: string
  /** An optional description for the integration. */
  description?: string
  /** The status of the integration connection with the third-party provider as of the `status_last_checked_at` field.  The status is updated automatically by the server when the integration is modified. */
  status?: StatusEnum
  /** If an error occurs, more details will be available in this field. */
  status_detail?: string
  /**
   * The last time the status was evaluated.
   * @format date-time
   */
  status_last_checked_at?: string | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  fqn?: string
  /** The type of integration. */
  type?: string
  /** Allow actions to write to the integration. */
  writable?: boolean
  /** A list of tags to be set on all integration resources. */
  resource_tags?: Record<string, any>
  /** The Azure Key Vault name. */
  vault_name?: string
  /**
   * The Azure Tenant ID.
   * @format uuid
   */
  tenant_id?: string
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface PatchedAzureKeyVaultPull {
  /** @format uri */
  url?: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id?: string
  /**
   * The action name.
   * @maxLength 256
   */
  name?: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task?: AzureKeyVaultPullTask | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values?: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode?: ModeEnum
  /**
   * Defines a pattern matching string that contains either mustache or regular expression syntax (with named capture groups) that locate the environment, project, and parameter name of the content you are looking for.
   *
   * If you are using mustache pattern matching, use:
   *
   *   - ``{{ environment }}`` to identify the environment name
   *   - ``{{ parameter }}`` to identify the parameter name
   *   - ``{{ project }}`` to identify the project name
   *
   * If you are using a regular expression, use Python syntax with named capture groups that locate the `environment`, `project`, and `parameter`.
   * @maxLength 1024
   */
  resource?: string | null
}

/** Update a push. */
export interface PatchedAzureKeyVaultPushUpdate {
  /**
   * The action name.
   * @maxLength 256
   */
  name?: string
  /** The optional description for the action. */
  description?: string
  /** Projects that are included in the push. */
  projects?: string[]
  /** Tags are used to select parameters by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push. */
  tags?: string[]
  /**
   * Defines a path through the integration to the location where values will be pushed.
   *
   * The following mustache-style substitutions can be used in the string:
   *
   *   - ``{{ environment }}`` to insert the environment name
   *   - ``{{ parameter }}`` to insert the parameter name
   *   - ``{{ project }}`` to insert the project name
   *   - ``{{ push }}`` to insert the push name
   *   - ``{{ tag }}`` to insert the tag name
   *
   * We recommend that you use project, environment, and parameter at a minimum to disambiguate your pushed resource identifiers.
   *
   * If you include multiple projects in the push, the `project` substitution is required.  If you include multiple tags from different environments in the push, the `environment` substitution is required.  If you include multiple tags from the same environment in the push, the `tag` substitution is required.  In all cases, the `parameter` substitution is always required.
   * @maxLength 1024
   */
  resource?: string | null
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Normally, push will check to see if it originated the values in the destination before making changes to them.  Forcing a push disables the ownership check. */
  force?: boolean
  /** Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as `local` will cause it to only process the parameters defined in the selected projects. */
  local?: boolean
  /** This setting allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage. */
  coerce_parameters?: boolean
  /** Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail. */
  include_parameters?: boolean
  /** Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail. */
  include_secrets?: boolean
}

export interface PatchedEnvironmentUpdate {
  id?: string
  /**
   * The environment name.
   * @maxLength 256
   */
  name?: string
  /** A description of the environment.  You may find it helpful to document how this environment is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * Environments can inherit from a single parent environment which provides values for parameters when specific environments do not have a value set.  Every organization has one default environment that cannot be removed.
   * @format uri
   */
  parent?: string | null
  /** This is the opposite of `parent`, see that field for more details. */
  children?: string[]
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the environment, if the environment is access-controlled. */
  role?: RoleEnum | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

/**
 * Pull actions can be configured to get configuration and secrets from
 * integrations on demand.
 */
export interface PatchedGitHubPull {
  /** @format uri */
  url?: string
  /**
   * Unique identifier for the action.
   * @format uuid
   */
  id?: string
  /**
   * The action name.
   * @maxLength 256
   */
  name?: string
  /** The optional description for the action. */
  description?: string
  /** The most recent task run for this action. */
  latest_task?: GitHubPullTask | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  /** Allow the pull to create environments.  Any automatically created environments will be children of the `default` environment.  If an environment needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_environments?: boolean
  /** Allow the pull to create projects.  If a project needs to be created but the action does not allow it, a task step will be added with a null operation, and success_detail will indicate the action did not allow it. */
  create_projects?: boolean
  /** When set to dry-run mode an action will report the changes that it would have made in task steps, however those changes are not actually performed. */
  dry_run?: boolean
  /** Values being managed by a mapped pull. */
  mapped_values?: ValueCreate[]
  /**
   * The pull mode used.
   *
   * A pattern pull uses a pattern-matching resource string with mustache-style markers to identify the project, parameter, and environment names, or with a Python regular expression that uses named capture groups that define the same three concepts.
   *
   * A mapped pull uses a specific resource and JMESpath expression to deliver a value to a specific project, parameter, and environment.  This leverages external value linkages made in the value editor, and there is one mapped pull per integration provided by the system so that you can trigger external value pull synchronizations.
   */
  mode?: ModeEnum
}

export interface PatchedGrantUpdate {
  /** @format uri */
  url?: string
  /**
   * A unique identifier for the grant.
   * @format uuid
   */
  id?: string
  /**
   * The URI of a principal for the grant; this must reference a user or group.
   * @format uri
   */
  principal?: string
  /**
   * The URI of a scope for the grant; this must reference a project or environment.
   * @format uri
   */
  scope?: string
  /** The role that the principal has in the given scope. */
  role?: RoleEnum
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedGroup {
  /** @format uri */
  url?: string
  /**
   * The unique identifier of a group.
   * @format uuid
   */
  id?: string
  /**
   * The group name.
   * @maxLength 150
   */
  name?: string
  /** A description of the group.  You may find it helpful to document how this group is used to assist others when they need to maintain this organization. */
  description?: string
  users?: string[]
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedInvitation {
  /** @format uri */
  url?: string
  /**
   * The unique identifier of an invitation.
   * @format uuid
   */
  id?: string
  /**
   * The email address of the user to be invited.
   * @format email
   * @maxLength 254
   */
  email?: string
  /** The role that the user will have in the organization, should the user accept. */
  role?: RoleEnum
  /**
   * The user that created the invitation.
   * @format uri
   */
  inviter?: string
  /** The name of the user that created the invitation. */
  inviter_name?: string
  /** The current state of the invitation. */
  state?: string
  /** Additional details about the state of the invitation. */
  state_detail?: string
  /**
   * The resulting membership, should the user accept.
   * @format uri
   */
  membership?: string | null
  /**
   * The organization that the user will become a member of, should the user accept.
   * @format uri
   */
  organization?: string
}

export interface PatchedMembership {
  /** @format uri */
  url?: string
  /**
   * A unique identifier for the membership.
   * @format uuid
   */
  id?: string
  /**
   * The user of the membership.
   * @format uri
   */
  user?: string
  /**
   * The organization that the user is a member of.
   * @format uri
   */
  organization?: string
  /** The role that the user has in the organization. */
  role?: RoleEnum
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedOrganization {
  /** @format uri */
  url?: string
  /** A unique identifier for the organization. */
  id?: string
  /**
   * The organization name.
   * @maxLength 256
   */
  name?: string
  /**
   * A regular expression project names must match
   * @maxLength 255
   */
  project_name_pattern?: string
  /** If set, we are performing maintenance on this organization and have disabled making changes */
  maintenance?: boolean
  /** Multi-factor authentication for the organization */
  mfa_enabled?: boolean
  /** The current version of this Organization */
  version?: VersionEnum
  notification_config?: Record<string, any>
  /** Indicates if this Organization is the one currently targeted by the Bearer token used by the client to authorize. */
  current?: boolean
  /** Your role in the organization. */
  role?: RoleEnum
  /** @format date-time */
  subscription_expires_at?: string | null
  subscription_features?: string[]
  subscription_id?: string | null
  subscription_plan_id?: string | null
  subscription_plan_name?: string | null
  /** If set, the organization has Copilot enabled. */
  copilot_enabled?: boolean
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface PatchedParameterRuleUpdate {
  /** @format uuid */
  id?: string
  /**
   * The parameter this rule is for.
   * @format uri
   */
  parameter?: string
  type?: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

/**
 * A type of `ModelSerializer` that uses hyperlinked relationships with compound keys instead
 * of primary key relationships.  Specifically:
 *
 * * A 'url' field is included instead of the 'id' field.
 * * Relationships to other instances are hyperlinks, instead of primary keys.
 *
 * NOTE: this only works with DRF 3.1.0 and above.
 */
export interface PatchedParameterTypeRuleUpdate {
  /** @format uuid */
  id?: string
  /**
   * The type this rule is for.
   * @format uri
   */
  parameter_type?: string
  type?: ParameterRuleTypeEnum
  /** The constraint for the rule. Provided for backwards compatibility. */
  constraint?: string
  constraints?: Record<string, any>
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedParameterTypeUpdate {
  /**
   * A unique identifier for the parameter type.
   * @format uuid
   */
  id?: string
  /**
   * The parameter type name.
   * @maxLength 256
   */
  name?: string
  /** A description of the parameter type, provide documentation on how to use this type here. */
  description?: string
  /**
   * The URL for this parameter type's parent
   * @format uri
   */
  parent?: string
  /** Rules applied to this parameter. */
  rules?: ParameterTypeRule[]
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

/** A single parameter inside of a project. */
export interface PatchedParameterUpdate {
  id?: string
  /**
   * The parameter name.
   * @maxLength 256
   */
  name?: string
  /** A description of the parameter.  You may find it helpful to document how this parameter is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** Indicates if this content is secret or not.  External values are inspected on-demand to ensure they align with the parameter's secret setting and if they do not, those external values are not allowed to be used. */
  secret?: boolean
  /**
   *
   *         The type of this Parameter.  If not provided, this will default to
   *         a string for Parameters that are not overrides or to the overridden
   *         Parameter's type for Parameters that are overrides.
   *
   */
  type?: string
  /**
   * The project url.
   * @format uri
   */
  project?: string
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  expires?: string | null
  expiration_action?: Record<string, any>
  /** If this parameter expires, the status of the last attempt to handle its expiration */
  expired_status?: Record<string, any>
}

export interface PatchedProjectUpdate {
  id?: string
  /**
   * The project name.
   * @maxLength 256
   */
  name?: string
  /** A description of the project.  You may find it helpful to document how this project is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * A regular expression parameter names must match
   * @maxLength 256
   */
  parameter_name_pattern?: string
  /**
   * Project dependencies allow projects to be used for shared configuration, for example a database used by many applications needs to advertise its port number.
   *
   * Projects can depend on another project which will add the parameters from the parent project into the current project.  All of the parameter names between the two projects must be unique.  When retrieving values or rendering templates, all of the parameters from the parent project will also be available in the current project.
   * @format uri
   */
  depends_on?: string | null
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the project, if the project is access-controlled. */
  role?: RoleEnum | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedServiceAccount {
  /** @format uri */
  url?: string
  id?: string
  /** @format uri */
  owner?: string | null
  user?: User
  /** An optional description of the process or system using the service account. */
  description?: string
  keys?: ServiceAccountAPIKey[]
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
  /**
   * The most recent date and time the service account was used.  It will be null if the service account has not been used.
   * @format date-time
   */
  last_used_at?: string | null
}

/** Details for updating a tag. */
export interface PatchedTagUpdate {
  /**
   * A unique identifier for the tag.
   * @format uuid
   */
  id?: string
  /**
   * The tag name. Tag names may contain alphanumeric, hyphen, underscore, or period characters. Tag names are case sensitive. The name cannot be modified.
   * @maxLength 64
   * @pattern ^[\w.-]+$
   */
  name?: string
  /** A description of the tag.  You may find it helpful to document how this tag is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * The point in time this tag represents.  If explicitly set to `null` then the current time will be used.
   * @format date-time
   */
  timestamp?: string | null
  /** If True, this tag cannot be modified once it is created. */
  immutable?: boolean
}

/** A parameter template in a given project, optionally instantiated against an environment. */
export interface PatchedTemplateUpdate {
  id?: string
  /**
   * The template name.
   * @maxLength 256
   */
  name?: string
  /** ('A description of the template.  You may find it helpful to document how this template is used to assist others when they need to maintain software that uses this content.',) */
  description?: string
  /** If true, the `body` field has undergone evaluation. */
  evaluated?: boolean
  /** The content of the template.  Use mustache-style templating delimiters of `{{` and `}}` to reference parameter values by name for substitution into the template result. */
  body?: string
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedUpdateUser {
  /** The threads this user is participating in with ChatGPT. */
  chatgpt_threads?: Record<string, any>
}

/**
 * Unlike other UpdateSerializers, we do not inherit from the CreateSerializer here
 * because `environment` is not a required field for updates.
 */
export interface PatchedValueUpdate {
  id?: string
  /** An external parameter leverages a CloudTruth integration to retrieve content on-demand from an external source.  When this is `false` the value is stored by CloudTruth and considered to be _internal_.  When this is `true`, the `external_fqn` field must be set. */
  external?: boolean
  /**
   * The FQN, or Fully-Qualified Name, is the path through the integration to get to the desired content.  This must be present and reference a valid integration when the value is `external`.
   * @maxLength 1024
   */
  external_fqn?: string
  /**
   * If the value is `external`, the content returned by the integration can be reduced by applying a JMESpath expression.  This is valid as long as the content is structured and of a supported format.  JMESpath expressions are supported on `json`, `yaml`, and `dotenv` content.
   * @maxLength 1024
   */
  external_filter?: string
  /**
   * This is the content to use when resolving the Value for an internal non-secret, or when storing a secret.  This field cannot be specified when creating or updating an `external` value.
   * @maxLength 65536
   */
  internal_value?: string | null
  /** If `true`, apply template substitution rules to this value.  If `false`, this value is a literal value.  Note: secrets cannot be interpolated. */
  interpolated?: boolean
  /** Indicates the value content is a secret.  Normally this is `true` when the parameter is a secret. It is possible for a parameter to be a secret with a external value that is not a secret.  It is not possible to convert a parameter from a secret to a non-secret if any of the values are external and a secret.  Clients can check this condition by leveraging this field.  It is also possible for a parameter to not be a secret but for this value to be dynamic and reference a Parameter that is a secret.  In this case, we indicate the value is a secret. */
  secret?: boolean | null
  /**
   * This is the actual content of the Value for the given parameter in the given environment.
   *
   * If you request secret masking, no secret content will be included in the result and instead a series of asterisks will be used instead for the value.
   *
   * Clients applying this value to a shell environment should set `<parameter_name>=<value>` even if `value` is the empty string.  If `value` is `null`, the client should unset that shell environment variable.
   */
  value?: string | null
  /** @format date-time */
  created_at?: string
  /** @format date-time */
  modified_at?: string | null
}

export interface PatchedWebHooks {
  /**
   * Primary key for webhook
   * @format uuid
   */
  id?: string
  /** Webhook name */
  name?: string
  /** Events that trigger this hook */
  event_types?: EventTypesEnum[]
  type?: WebHooksTypeEnum
  configuration?: Record<string, any>
  /** A unique identifier for the organization. */
  organization?: string
}

export interface Project {
  /**
   * The URL for the project.
   * @format uri
   */
  url: string
  id: string
  ledger_id: string
  /** The project name. */
  name: string
  /**
   * A regular expression parameter names must match
   * @maxLength 256
   */
  parameter_name_pattern?: string
  /** A description of the project.  You may find it helpful to document how this project is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** This is the opposite of `depends_on`, see that field for more details. */
  dependents: string[]
  /**
   * Project dependencies allow projects to be used for shared configuration, for example a database used by many applications needs to advertise its port number.
   *
   * Projects can depend on another project which will add the parameters from the parent project into the current project.  All of the parameter names between the two projects must be unique.  When retrieving values or rendering templates, all of the parameters from the parent project will also be available in the current project.
   * @format uri
   */
  depends_on: string | null
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the project, if the project is access-controlled. */
  role: RoleEnum | null
  /** Deprecated. Blank. */
  pushes: string[]
  /** Deprecated. Blank. */
  push_urls: string[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface ProjectCopy {
  /**
   * The project name.
   * @maxLength 256
   */
  name: string
  /** A description of the project.  You may find it helpful to document how this project is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /** When copying child Project, this indicates what name to use for each Project.  If no name is provided for a given child Project, a "-1" will be appended to its name.  Note that this name mapping can be used for Projects of any level of depth in the parent-child hierarchy. */
  child_project_names?: Record<string, string>
  /** If true, copy child projects recursively.  If false, only copy the specified project. */
  recursive?: boolean
  /**
   * Project dependencies allow projects to be used for shared configuration, for example a database used by many applications needs to advertise its port number.
   *
   * Projects can depend on another project which will add the parameters from the parent project into the current project.  All of the parameter names between the two projects must be unique.  When retrieving values or rendering templates, all of the parameters from the parent project will also be available in the current project.
   * @format uri
   */
  depends_on?: string | null
}

export interface ProjectCreate {
  /**
   * The project name.
   * @maxLength 256
   */
  name: string
  /** A description of the project.  You may find it helpful to document how this project is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * A regular expression parameter names must match
   * @maxLength 256
   */
  parameter_name_pattern?: string
  /**
   * Project dependencies allow projects to be used for shared configuration, for example a database used by many applications needs to advertise its port number.
   *
   * Projects can depend on another project which will add the parameters from the parent project into the current project.  All of the parameter names between the two projects must be unique.  When retrieving values or rendering templates, all of the parameters from the parent project will also be available in the current project.
   * @format uri
   */
  depends_on?: string | null
  /**
   * If depends_on is set and has RBAC enabled and this field is True, RBAC will be enabled and grants from the dependency are copied to this project.  If depends_on is set and has RBAC enabled and this field is False, RBAC will be enabled but only the minimal necessary grants will be assigned to the newly created project.   Not used when depends_on is not set or RBAC is not enabled for the dependency.
   * @default false
   */
  copy_rbac?: boolean
}

export interface ProjectUpdate {
  id: string
  /**
   * The project name.
   * @maxLength 256
   */
  name: string
  /** A description of the project.  You may find it helpful to document how this project is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * A regular expression parameter names must match
   * @maxLength 256
   */
  parameter_name_pattern?: string
  /**
   * Project dependencies allow projects to be used for shared configuration, for example a database used by many applications needs to advertise its port number.
   *
   * Projects can depend on another project which will add the parameters from the parent project into the current project.  All of the parameter names between the two projects must be unique.  When retrieving values or rendering templates, all of the parameters from the parent project will also be available in the current project.
   * @format uri
   */
  depends_on?: string | null
  /** Indicates if access control is being enforced through grants. */
  access_controlled?: boolean
  /** Your role in the project, if the project is access-controlled. */
  role: RoleEnum | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export enum RoleEnum {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  CONTRIB = 'CONTRIB',
  VIEWER = 'VIEWER',
  NO_SECRETS = 'NO_SECRETS',
}

export interface ServiceAccount {
  /** @format uri */
  url: string
  id: string
  /** @format uri */
  owner?: string | null
  user: User
  /** An optional description of the process or system using the service account. */
  description?: string
  keys: ServiceAccountAPIKey[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /**
   * The most recent date and time the service account was used.  It will be null if the service account has not been used.
   * @format date-time
   */
  last_used_at: string | null
}

export interface ServiceAccountAPIKey {
  /**
   * A free-form name for the API key. Need not be unique. 50 characters max.
   * @maxLength 50
   */
  name?: string
  prefix: string
  /** If the API key is revoked, clients cannot use it anymore. (This cannot be undone.) */
  revoked?: boolean
  has_expired: boolean
  /** @format date-time */
  created: string
  /**
   * Expires
   * Once API key expires, clients cannot use it anymore.
   * @format date-time
   */
  expiry_date?: string | null
}

export interface ServiceAccountCreateRequest {
  /**
   * The name of the process or system using the service account.
   * @maxLength 128
   */
  name: string
  /** An optional description of the process or system using the service account. */
  description?: string
  /** The role for the service acount */
  role?: string
  /**
   * The owner of the service account.
   * @maxLength 128
   */
  owner?: string
}

export interface ServiceAccountCreateResponse {
  /** @format uri */
  url: string
  id: string
  /** @format uri */
  owner?: string | null
  user: User
  /** An optional description of the process or system using the service account. */
  description?: string
  keys: ServiceAccountAPIKey[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /**
   * The most recent date and time the service account was used.  It will be null if the service account has not been used.
   * @format date-time
   */
  last_used_at: string | null
  /** The API Key to use as a Bearer token for the service account. */
  apikey: string
}

export interface ServiceAccountRekeyRequest {
  /**
   * The date and time the previous API key will expire.
   * @format date-time
   */
  expire_at?: string | null
}

export interface ServiceAccountUpdateRequest {
  /** An optional description of the process or system using the service account. */
  description?: string
  /** The role for the service acount */
  role: string
  /**
   * The owner of the service account.
   * @maxLength 128
   */
  owner?: string
}

export enum StateEnum {
  Queued = 'queued',
  Running = 'running',
  Skipped = 'skipped',
  Success = 'success',
  Failure = 'failure',
}

export enum StatusEnum {
  Unknown = 'unknown',
  Pending = 'pending',
  Checking = 'checking',
  Connected = 'connected',
  Errored = 'errored',
  Missing = 'missing',
}

/** The details of a tag. */
export interface Tag {
  /**
   * The URL for the tag.
   * @format uri
   */
  url: string
  /**
   * A unique identifier for the tag.
   * @format uuid
   */
  id: string
  /**
   * The tag name. Tag names may contain alphanumeric, hyphen, underscore, or period characters. Tag names are case sensitive. The name cannot be modified.
   * @maxLength 64
   * @pattern ^[\w.-]+$
   */
  name: string
  /** A description of the tag.  You may find it helpful to document how this tag is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * The point in time this tag represents.
   * @format date-time
   */
  timestamp: string
  /** If True, this tag cannot be modified once it is created. */
  immutable?: boolean
  /** Deprecated. Use `push_urls` instead. */
  pushes: AwsPush[]
  /** Push actions associated with the tag. */
  push_urls: string[]
  usage: TagReadUsage
}

/** Details for creating a tag. */
export interface TagCreate {
  /**
   * The tag name. Tag names may contain alphanumeric, hyphen, underscore, or period characters. Tag names are case sensitive. The name cannot be modified.
   * @maxLength 64
   * @pattern ^[\w.-]+$
   */
  name: string
  /** A description of the tag.  You may find it helpful to document how this tag is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * The point in time this tag represents. If not specified then the current time will be used.
   * @format date-time
   */
  timestamp?: string | null
  /** If True, this tag cannot be modified once it is created. */
  immutable?: boolean
}

/** The read usage details of a tag. */
export interface TagReadUsage {
  /**
   * The last time a configuration was retrieved with this tag.
   * @format date-time
   */
  last_read: string | null
  /**
   * The last user (id) to use this tag to read configuration.
   * @maxLength 256
   */
  last_read_by?: string
  /**
   * The number of times the tag has been used to read configuration.
   * @min -2147483648
   * @max 2147483647
   */
  total_reads: number
}

/** Details for updating a tag. */
export interface TagUpdate {
  /**
   * A unique identifier for the tag.
   * @format uuid
   */
  id: string
  /**
   * The tag name. Tag names may contain alphanumeric, hyphen, underscore, or period characters. Tag names are case sensitive. The name cannot be modified.
   * @maxLength 64
   * @pattern ^[\w.-]+$
   */
  name: string
  /** A description of the tag.  You may find it helpful to document how this tag is used to assist others when they need to maintain software that uses this content. */
  description?: string
  /**
   * The point in time this tag represents.  If explicitly set to `null` then the current time will be used.
   * @format date-time
   */
  timestamp?: string | null
  /** If True, this tag cannot be modified once it is created. */
  immutable?: boolean
}

/**
 * Each task detail documents the synchronization of a single
 * value through an integration.
 */
export interface TaskStep {
  /** @format uri */
  url: string
  /**
   * Unique identifier for a task step.
   * @format uuid
   */
  id: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: OperationEnum | null
  /** Indicates if the operation was successful. */
  success: boolean
  /** Additional details about the successful operation, if any. */
  success_detail?: string | null
  /**
   * The fully-qualified name (FQN) this of the value that was changed.
   * @maxLength 1024
   */
  fqn?: string | null
  /**
   * The environment affected by this step.
   * @format uri
   */
  environment: string | null
  /**
   * The environment id involved in the operation.
   * @format uuid
   */
  environment_id?: string | null
  /**
   * The environment name involved in the operation.
   * @maxLength 256
   */
  environment_name?: string | null
  /**
   * The project affected by this step.
   * @format uri
   */
  project: string | null
  /**
   * The project id involved in the operation.
   * @format uuid
   */
  project_id?: string | null
  /**
   * The project name involved in the operation.
   * @maxLength 256
   */
  project_name?: string | null
  /**
   * The parameter affected by this step.
   * @format uri
   */
  parameter: string | null
  /**
   * The parameter id involved in the operation.
   * @format uuid
   */
  parameter_id?: string | null
  /**
   * The parameter name involved in the operation.
   * @maxLength 256
   */
  parameter_name?: string | null
  /**
   * The integration-native id for the resource.
   * @maxLength 2048
   */
  venue_id?: string | null
  /**
   * The name of the item or resource as known by the integration.
   * @maxLength 1024
   */
  venue_name?: string | null
  /** The summary of this step (what it was trying to do). */
  summary?: string | null
  /**
   * An error code, if not successful.
   * @maxLength 256
   */
  error_code?: string | null
  /** Details on the error that occurred during processing. */
  error_detail?: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** A parameter template in a given project, optionally instantiated against an environment. */
export interface Template {
  /**
   * The templates this value references, if interpolated.
   * @format uri
   */
  url: string
  id: string
  ledger_id: string
  /** The template name. */
  name: string
  /** ('A description of the template.  You may find it helpful to document how this template is used to assist others when they need to maintain software that uses this content.',) */
  description?: string
  /** If true, the `body` field has undergone evaluation. */
  evaluated: boolean
  /** The content of the template.  Use mustache-style templating delimiters of `{{` and `}}` to reference parameter values by name for substitution into the template result. */
  body?: string
  /** Projects (other than this template's project) that this template references.  This field is not valid for history requests. */
  referenced_projects: string[]
  /** Parameters that this template references.  This field is not valid for history requests. */
  referenced_parameters: string[]
  /** Other templates that this template references.  This field is not valid for history requests. */
  referenced_templates: string[]
  /** Other templates that reference this template.  This field is not valid for history requests. */
  referencing_templates: string[]
  /** The dynamic values that reference this template.  This field is not valid for history requests. */
  referencing_values: string[]
  /**
   * If True, this template contains secrets.
   * @default false
   */
  has_secret: boolean
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** A parameter template in a given project, optionally instantiated against an environment. */
export interface TemplateCreate {
  /**
   * The template name.
   * @maxLength 256
   */
  name: string
  /** ('A description of the template.  You may find it helpful to document how this template is used to assist others when they need to maintain software that uses this content.',) */
  description?: string
  /** The content of the template.  Use mustache-style templating delimiters of `{{` and `}}` to reference parameter values by name for substitution into the template result. */
  body?: string
}

/** Indicates errors occurred while retrieving values to substitute into the template. */
export interface TemplateLookupError {
  detail: TemplateLookupErrorEntry[]
}

export interface TemplateLookupErrorEntry {
  /**
   * The parameter id.
   * @format uuid
   */
  parameter_id: string
  /** The parameter name. */
  parameter_name: string
  /** The error code. */
  error_code: string
  /** Details about the error. */
  error_detail: string
}

export interface TemplatePreview {
  /** The template body to instantiate on request, instantiated on response. */
  body: string
  /** If True, the instantiated template contains secrets. */
  has_secret: boolean
}

export interface TemplatePreviewCreateRequest {
  /** The template body to instantiate on request, instantiated on response. */
  body: string
}

export interface TemplateTimeline {
  /** The number of records in this response. */
  count: number
  /**
   * If present, additional history can be retrieved using this timestamp in the next call for the as_of query parameter value.
   * @format date-time
   */
  next_as_of?: string
  results: TemplateTimelineEntry[]
}

/** Details about a single change. */
export interface TemplateTimelineEntry {
  history_type: HistoryTypeEnum
  /** @format date-time */
  modified_at: string | null
  modified_by?: string
  /** The template record as it was when archived for history. */
  history_template: TemplateTimelineEntryTemplate
}

/** Helper methods for all views or serializers that expose template concepts. */
export interface TemplateTimelineEntryTemplate {
  id: string
  ledger_id: string
  /** The parameter name. */
  name: string
  description?: string
  /** The content of the template.  Use mustache-style templating delimiters of `{{` and `}}` to reference parameter values by name for substitution into the template result. */
  body?: string
}

/** A parameter template in a given project, optionally instantiated against an environment. */
export interface TemplateUpdate {
  id: string
  /**
   * The template name.
   * @maxLength 256
   */
  name: string
  /** ('A description of the template.  You may find it helpful to document how this template is used to assist others when they need to maintain software that uses this content.',) */
  description?: string
  /** If true, the `body` field has undergone evaluation. */
  evaluated: boolean
  /** The content of the template.  Use mustache-style templating delimiters of `{{` and `}}` to reference parameter values by name for substitution into the template result. */
  body?: string
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export interface UpdateUser {
  /** The threads this user is participating in with ChatGPT. */
  chatgpt_threads: Record<string, any>
}

export interface User {
  /** @format uri */
  url: string
  /**
   * The unique identifier of a user.
   * @maxLength 256
   */
  id: string
  /**
   * The type of user record.
   * @maxLength 12
   */
  type?: string
  name: string | null
  /** The user's organization name. */
  organization_name: string | null
  /** Membership identifier for user. */
  membership_id: string | null
  /** The threads this user is participating in with ChatGPT. */
  chatgpt_threads: Record<string, any>
  /** The user's role in the current organization (defined by the request authorization header). */
  role: string | null
  email: string | null
  picture_url: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

/** A value for a parameter in a given environment. */
export interface Value {
  /**
   * The value url.
   * @format uri
   */
  url: string
  id: string
  ledger_id: string
  /** The active environment this Value is viewed under */
  active_environment: string
  /** The active environment id this Value is viewed under */
  active_environment_id: string
  /** The name of the environment that this environment is viewed under */
  active_environment_name: string
  /**
   * The environment this value is set in.
   * @format uri
   */
  environment: string
  /** The environment id for this value. */
  environment_id: string
  /** The environment name for this value.  This is a convenience to avoid another query against the server to resolve the environment url into a name. */
  environment_name: string
  /**
   * The parameter this value is for.
   * @format uri
   */
  parameter: string
  /** The parameter id for this value. */
  parameter_id: string
  external?: boolean
  external_fqn?: string
  external_filter?: string | null
  /** This field is deprecated and unused. */
  external_error: string | null
  /** The most recent mapped pull status for an external value. */
  external_status: TaskStep | null
  internal_value?: string | null
  interpolated?: boolean
  /**
   * This is the actual content of the Value for the given parameter in the given environment.
   *
   * If you request secret masking, no secret content will be included in the result and instead a series of asterisks will be used instead for the value.
   *
   * Clients applying this value to a shell environment should set `<parameter_name>=<value>` even if `value` is the empty string.  If `value` is `null`, the client should unset that shell environment variable.
   */
  value: string | null
  /** If true, the `value` field has undergone template evaluation. */
  evaluated: boolean
  /** Indicates the value content is a secret.  Normally this is `true` when the parameter is a secret. It is possible for a parameter to be a secret with a external value that is not a secret.  It is not possible to convert a parameter from a secret to a non-secret if any of the values are external and a secret.  Clients can check this condition by leveraging this field.  It is also possible for a parameter to not be a secret but for this value to be dynamic and reference a Parameter that is a secret.  In this case, we indicate the value is a secret. */
  secret: boolean | null
  /** The projects this value references, if dynamic.  This field is not valid for history requests. */
  referenced_projects: string[]
  /** The parameters this value references, if dynamic.  this field is not valid for history requests. */
  referenced_parameters: string[]
  /** The templates this value references, if dynamic.  This field is not valid for history requests. */
  referenced_templates: string[]
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
  /** @format date-time */
  expires_at: string | null
}

/** A value for a parameter in a given environment. */
export interface ValueCreate {
  /**
   * The environment this value is set in.
   * @format uri
   */
  environment: string
  /** An external parameter leverages a CloudTruth integration to retrieve content on-demand from an external source.  When this is `false` the value is stored by CloudTruth and considered to be _internal_.  When this is `true`, the `external_fqn` field must be set. */
  external?: boolean
  /**
   * The FQN, or Fully-Qualified Name, is the path through the integration to get to the desired content.  This must be present and reference a valid integration when the value is `external`.
   * @maxLength 1024
   */
  external_fqn?: string
  /**
   * If the value is `external`, the content returned by the integration can be reduced by applying a JMESpath expression.  This is valid as long as the content is structured and of a supported format.  JMESpath expressions are supported on `json`, `yaml`, and `dotenv` content.
   * @maxLength 1024
   */
  external_filter?: string
  /**
   * This is the content to use when resolving the Value for an internal non-secret, or when storing a secret.  This field cannot be specified when creating or updating an `external` value.
   * @maxLength 65536
   */
  internal_value?: string | null
  /** If `true`, apply template substitution rules to this value.  If `false`, this value is a literal value.  Note: secrets cannot be interpolated. */
  interpolated?: boolean
}

/**
 * Unlike other UpdateSerializers, we do not inherit from the CreateSerializer here
 * because `environment` is not a required field for updates.
 */
export interface ValueUpdate {
  id: string
  /** An external parameter leverages a CloudTruth integration to retrieve content on-demand from an external source.  When this is `false` the value is stored by CloudTruth and considered to be _internal_.  When this is `true`, the `external_fqn` field must be set. */
  external?: boolean
  /**
   * The FQN, or Fully-Qualified Name, is the path through the integration to get to the desired content.  This must be present and reference a valid integration when the value is `external`.
   * @maxLength 1024
   */
  external_fqn?: string
  /**
   * If the value is `external`, the content returned by the integration can be reduced by applying a JMESpath expression.  This is valid as long as the content is structured and of a supported format.  JMESpath expressions are supported on `json`, `yaml`, and `dotenv` content.
   * @maxLength 1024
   */
  external_filter?: string
  /**
   * This is the content to use when resolving the Value for an internal non-secret, or when storing a secret.  This field cannot be specified when creating or updating an `external` value.
   * @maxLength 65536
   */
  internal_value?: string | null
  /** If `true`, apply template substitution rules to this value.  If `false`, this value is a literal value.  Note: secrets cannot be interpolated. */
  interpolated?: boolean
  /** Indicates the value content is a secret.  Normally this is `true` when the parameter is a secret. It is possible for a parameter to be a secret with a external value that is not a secret.  It is not possible to convert a parameter from a secret to a non-secret if any of the values are external and a secret.  Clients can check this condition by leveraging this field.  It is also possible for a parameter to not be a secret but for this value to be dynamic and reference a Parameter that is a secret.  In this case, we indicate the value is a secret. */
  secret: boolean | null
  /**
   * This is the actual content of the Value for the given parameter in the given environment.
   *
   * If you request secret masking, no secret content will be included in the result and instead a series of asterisks will be used instead for the value.
   *
   * Clients applying this value to a shell environment should set `<parameter_name>=<value>` even if `value` is the empty string.  If `value` is `null`, the client should unset that shell environment variable.
   */
  value: string | null
  /** @format date-time */
  created_at: string
  /** @format date-time */
  modified_at: string | null
}

export enum VersionEnum {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface WebHooks {
  /**
   * Primary key for webhook
   * @format uuid
   */
  id: string
  /** Webhook name */
  name: string
  /** Events that trigger this hook */
  event_types: EventTypesEnum[]
  type: WebHooksTypeEnum
  configuration?: Record<string, any>
  /** A unique identifier for the organization. */
  organization: string
}

export enum WebHooksTypeEnum {
  Slack = 'slack',
}

export interface ApiSchemaRetrieveParams {
  format?: 'json' | 'yaml'
  lang?:
    | 'af'
    | 'ar'
    | 'ar-dz'
    | 'ast'
    | 'az'
    | 'be'
    | 'bg'
    | 'bn'
    | 'br'
    | 'bs'
    | 'ca'
    | 'cs'
    | 'cy'
    | 'da'
    | 'de'
    | 'dsb'
    | 'el'
    | 'en'
    | 'en-au'
    | 'en-gb'
    | 'eo'
    | 'es'
    | 'es-ar'
    | 'es-co'
    | 'es-mx'
    | 'es-ni'
    | 'es-ve'
    | 'et'
    | 'eu'
    | 'fa'
    | 'fi'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'he'
    | 'hi'
    | 'hr'
    | 'hsb'
    | 'hu'
    | 'hy'
    | 'ia'
    | 'id'
    | 'ig'
    | 'io'
    | 'is'
    | 'it'
    | 'ja'
    | 'ka'
    | 'kab'
    | 'kk'
    | 'km'
    | 'kn'
    | 'ko'
    | 'ky'
    | 'lb'
    | 'lt'
    | 'lv'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'my'
    | 'nb'
    | 'ne'
    | 'nl'
    | 'nn'
    | 'os'
    | 'pa'
    | 'pl'
    | 'pt'
    | 'pt-br'
    | 'ro'
    | 'ru'
    | 'sk'
    | 'sl'
    | 'sq'
    | 'sr'
    | 'sr-latn'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'tk'
    | 'tr'
    | 'tt'
    | 'udm'
    | 'uk'
    | 'ur'
    | 'uz'
    | 'vi'
    | 'zh-hans'
    | 'zh-hant'
}

export interface AuditListParams {
  /** The action that was taken. */
  action?: 'create' | 'delete' | 'expired' | 'pull' | 'push' | 'update'
  /** @format date-time */
  earliest?: string
  /**
   * Returns records for the environment, associated tags, and values.
   * @format uuid
   */
  environment_id?: string
  /** @format date-time */
  latest?: string
  object_id?: string
  object_type?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /**
   * Returns records for the parameter and associated values.
   * @format uuid
   */
  parameter_id?: string
  /**
   * Returns records for the project, it's parameters, and associated values.
   * @format uuid
   */
  project_id?: string
  user_id?: string
}

export interface EnvironmentsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface EnvironmentsPushesListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format uuid */
  environmentPk: string
}

export interface EnvironmentsTagsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format date-time */
  timestamp?: string
  /** @format date-time */
  timestamp__gte?: string
  /** @format date-time */
  timestamp__lte?: string
  /** @format uuid */
  environmentPk: string
}

export interface GrantsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /**
   * Filter by principal (User, Group). Returns direct grant assignments, not indirect (user via group).
   * @format uri
   */
  principal?: string
  /** Filter by role. */
  role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
  /**
   * Filter by grant scope (Environment, Project).
   * @format uri
   */
  scope?: string
}

export interface GroupsListParams {
  name?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** Filter by user. */
  user?: string
}

export interface ImportCreateParams {
  /**
   * Mask secrets in the response
   * @default false
   */
  mask_secrets?: boolean
  /**
   * Do not create any objects
   * @default false
   */
  preview?: boolean
}

export interface IntegrationsAwsListParams {
  aws_account_id?: string
  aws_role_name?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface IntegrationsAwsPullsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format uuid */
  awsintegrationPk: string
}

export interface IntegrationsAwsPullsTasksListParams {
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The current state of this task. */
  state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
  /** @format uuid */
  awsintegrationPk: string
  /** @format uuid */
  awspullPk: string
}

export interface IntegrationsAwsPullsTasksStepsListParams {
  fqn?: string
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: 'create' | 'delete' | 'read' | 'update' | null
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  success?: boolean
  venue_id?: string
  venue_id__icontains?: string
  venue_name?: string
  venue_name__icontains?: string
  /** @format uuid */
  awsintegrationPk: string
  /** @format uuid */
  awspullPk: string
  /** @format uuid */
  awspulltaskPk: string
}

export interface IntegrationsAwsPushesListParams {
  description__icontains?: string
  /**
   * Filter results to pushes that operate on a tag in the given environment.
   * @format uri
   */
  environment?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /**
   * Filter results to pushes that operate on the given project.
   * @format uri
   */
  project?: string
  /**
   * Filter results to pushes that operate on the given tag.
   * @format uri
   */
  tag?: string
  /** @format uuid */
  awsintegrationPk: string
}

export interface IntegrationsAwsPushesTasksListParams {
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The current state of this task. */
  state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
  /** @format uuid */
  awsintegrationPk: string
  /** @format uuid */
  awspushPk: string
}

export interface IntegrationsAwsPushesTasksStepsListParams {
  fqn?: string
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: 'create' | 'delete' | 'read' | 'update' | null
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  success?: boolean
  venue_id?: string
  venue_id__icontains?: string
  venue_name?: string
  venue_name__icontains?: string
  /** @format uuid */
  awsintegrationPk: string
  /** @format uuid */
  awspushPk: string
  /** @format uuid */
  awspushtaskPk: string
}

export interface IntegrationsAwsRetrieveParams {
  /** Trigger a refresh of the integration status before returning the details. */
  refresh_status?: boolean
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface IntegrationsAwsDestroyParams {
  /**
   * (Optional) Desired behavior if the integration has in-use values.
   *
   * - `fail` will return HTTP error 409 if there are any values using the integration.
   * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
   * - `remove` will remove the all values using the integration when the integration is removed.
   */
  in_use?: 'fail' | 'leave' | 'remove'
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface IntegrationsAzureKeyVaultListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format uuid */
  tenant_id?: string
  vault_name?: string
}

export interface IntegrationsAzureKeyVaultPullsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format uuid */
  akvintegrationPk: string
}

export interface IntegrationsAzureKeyVaultPullsTasksListParams {
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The current state of this task. */
  state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
  /** @format uuid */
  akvintegrationPk: string
  /** @format uuid */
  akvpullPk: string
}

export interface IntegrationsAzureKeyVaultPullsTasksStepsListParams {
  fqn?: string
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: 'create' | 'delete' | 'read' | 'update' | null
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  success?: boolean
  venue_id?: string
  venue_id__icontains?: string
  venue_name?: string
  venue_name__icontains?: string
  /** @format uuid */
  akvintegrationPk: string
  /** @format uuid */
  akvpullPk: string
  /** @format uuid */
  akvpulltaskPk: string
}

export interface IntegrationsAzureKeyVaultPushesListParams {
  description__icontains?: string
  /**
   * Filter results to pushes that operate on a tag in the given environment.
   * @format uri
   */
  environment?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /**
   * Filter results to pushes that operate on the given project.
   * @format uri
   */
  project?: string
  /**
   * Filter results to pushes that operate on the given tag.
   * @format uri
   */
  tag?: string
  /** @format uuid */
  akvintegrationPk: string
}

export interface IntegrationsAzureKeyVaultPushesTasksListParams {
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The current state of this task. */
  state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
  /** @format uuid */
  akvintegrationPk: string
  /** @format uuid */
  akvpushPk: string
}

export interface IntegrationsAzureKeyVaultPushesTasksStepsListParams {
  fqn?: string
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: 'create' | 'delete' | 'read' | 'update' | null
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  success?: boolean
  venue_id?: string
  venue_id__icontains?: string
  venue_name?: string
  venue_name__icontains?: string
  /** @format uuid */
  akvintegrationPk: string
  /** @format uuid */
  akvpushPk: string
  /** @format uuid */
  akvpushtaskPk: string
}

export interface IntegrationsAzureKeyVaultRetrieveParams {
  /** Trigger a refresh of the integration status before returning the details. */
  refresh_status?: boolean
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface IntegrationsAzureKeyVaultDestroyParams {
  /**
   * (Optional) Desired behavior if the integration has in-use values.
   *
   * - `fail` will return HTTP error 409 if there are any values using the integration.
   * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
   * - `remove` will remove the all values using the integration when the integration is removed.
   */
  in_use?: 'fail' | 'leave' | 'remove'
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface IntegrationsExploreListParams {
  /**
   * FQN (URL-like) for third-party integration.
   * @format uri
   */
  fqn?: string
  /** JMES-path qualifier (key within file) */
  jmes?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** When paginating, this is the value that came back in the last page's next */
  page_token?: string
}

export interface IntegrationsGithubListParams {
  gh_organization_slug?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface IntegrationsGithubPullsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** @format uuid */
  githubintegrationPk: string
}

export interface IntegrationsGithubPullsTasksListParams {
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The current state of this task. */
  state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
  /** @format uuid */
  githubintegrationPk: string
  /** @format uuid */
  githubpullPk: string
}

export interface IntegrationsGithubPullsTasksStepsListParams {
  fqn?: string
  /** @format date-time */
  modified_at?: string
  /** @format date-time */
  modified_at__gte?: string
  /** @format date-time */
  modified_at__lte?: string
  /**
   * The operation performed, if any.
   *
   * When the operation is an update, there may be additional details in the success_detail field to describe the change.
   *
   * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
   */
  operation?: 'create' | 'delete' | 'read' | 'update' | null
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  success?: boolean
  venue_id?: string
  venue_id__icontains?: string
  venue_name?: string
  venue_name__icontains?: string
  /** @format uuid */
  githubintegrationPk: string
  /** @format uuid */
  githubpullPk: string
  /** @format uuid */
  githubpulltaskPk: string
}

export interface IntegrationsGithubRetrieveParams {
  /** Refresh the integration status before returning the details. */
  refresh_status?: boolean
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface IntegrationsGithubDestroyParams {
  /**
   * (Optional) Desired behavior if the integration has in-use values.
   *
   * - `fail` will return HTTP error 409 if there are any values using the integration.
   * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
   * - `remove` will remove the all values using the integration when the integration is removed.
   */
  in_use?: 'fail' | 'leave' | 'remove'
  /**
   * The unique identifier for the integration.
   * @format uuid
   */
  id: string
}

export interface InvitationsListParams {
  email?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The role that the user will have in the organization, should the user accept. */
  role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
  /** The current state of the invitation. */
  state?: 'accepted' | 'bounced' | 'pending' | 'sent'
}

export interface MembershipsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** The role that the user has in the organization. */
  role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
  /** The unique identifier of a user. */
  user?: string
}

export interface OrganizationsListParams {
  name?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface ProjectsListParams {
  description__icontains?: string
  name?: string
  name__icontains?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface ProjectsParameterExportListParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Only include parameters with names that contain the provided string. */
  contains?: string
  /** Only include parameters with names that end with the provided string. */
  endswith?: string
  /** Name or id of the environment to use to retrieve parameter values. */
  environment?: string
  /**
   * Explicitly marks parameters with export, e.g. `export FOO=bar`.
   * @default false
   */
  explicit_export?: boolean
  /**
   * Masks all secrets in the template with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** Which field to use when ordering the results. */
  ordering?: string
  /** Format to output: One of 'docker', 'dotenv', 'shell'. */
  output?: string
  /** Only include parameters with names that start with the provided string. */
  startswith?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersListParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  description__icontains?: string
  /** Specify a list of comma-separated environment names or ids (uuid) to compare values. Only returns a parameter if there is a difference. Cannot be used with `environment` or `values`. If used with `mask_secrets` then no secret parameters will be included in the result. */
  difference?: string
  /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
   * Has no effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
   * @default false
   */
  immediate_parameters?: boolean
  /**
   * If true, replaces all secrets with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  name?: string
  name__contains?: string
  name__icontains?: string
  name__iexact?: string
  name__istartswith?: string
  name__startswith?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  secret?: boolean
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
   * @default true
   */
  values?: boolean
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersPushesListParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** @format uuid */
  parameterPk: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersRulesListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  type?: 'max' | 'max_len' | 'min' | 'min_len' | 'one_of' | 'regex'
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesListParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /** A comma-separated list of field names to exclude from the response. */
  exclude?: string
  /** A comma-separated list of field names to include in the response. */
  include?: string
  /**
   * Mask secret values in responses with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesCreateParams {
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /** A comma-separated list of field names to exclude from the response. */
  exclude?: string
  /** A comma-separated list of field names to include in the response. */
  include?: string
  /**
   * Mask secret values in responses with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * A UUID string identifying this value ledger.
   * @format uuid
   */
  id: string
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesUpdateParams {
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * A unique identifier for the value.
   * @format uuid
   */
  id: string
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesPartialUpdateParams {
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * A unique identifier for the value.
   * @format uuid
   */
  id: string
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersValuesDestroyParams {
  /**
   * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
   * No effect on values that are not dynamic.
   */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
   * No effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * A unique identifier for the value.
   * @format uuid
   */
  id: string
  /**
   * The parameter id.
   * @format uuid
   */
  parameterPk: string
  /**
   * The project id.
   * @format uuid
   */
  projectPk: string
}

export interface ProjectsParametersRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
   * Has no effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
   * @default false
   */
  immediate_parameters?: boolean
  /**
   * If true, replaces all secrets with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
   * @default true
   */
  values?: boolean
  /**
   * A UUID string identifying this parameter ledger.
   * @format uuid
   */
  id: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersTimelineRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * A UUID string identifying this parameter ledger.
   * @format uuid
   */
  id: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersDualityListParams {
  /** Compare the values at `t1` and `t2` and if they are the same, omit the parameter from the result. */
  difference?: boolean
  /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
  environment?: string
  /**
   * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
   * Has no effect on values that are not interpolated.
   * @default true
   */
  evaluate?: boolean
  /**
   * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
   * @default false
   */
  immediate_parameters?: boolean
  /**
   * If true, replaces all secrets with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  name?: string
  name__contains?: string
  name__icontains?: string
  name__iexact?: string
  name__istartswith?: string
  name__startswith?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  secret?: boolean
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `t1_tag`.
   * @format date-time
   */
  t1_as_of?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `t1_as_of`. Requires `environment`. */
  t1_tag?: string
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `t2_tag`.
   * @format date-time
   */
  t2_as_of?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `t2_as_of`. Requires `environment`. */
  t2_tag?: string
  /**
   * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
   * @default true
   */
  values?: boolean
  /** @format uuid */
  projectPk: string
}

export interface ProjectsParametersTimelinesRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsTemplatePreviewCreateParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id of the environment to use to instantiate this template. If not specified then the default environment is used. */
  environment?: string
  /**
   * Masks all secrets in the template with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** ID of the template parameter being previewed.  If not specified, this is assumed to be a not-yet-created parameter. */
  template?: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsTemplatesListParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
  environment?: string
  /**
   * If `true`, evaluates the template's body.  If `false`, returns the unevaluated template body.
   * @default false
   */
  evaluate?: boolean
  /**
   * Masks all secrets in the template with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  name?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsTemplatesRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
  environment?: string
  /**
   * If `true`, evaluates the template's body.  If `false`, returns the unevaluated template body.
   * @default true
   */
  evaluate?: boolean
  /**
   * Masks all secrets in the template with `*****`.
   * @default false
   */
  mask_secrets?: boolean
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * A UUID string identifying this parameter ledger.
   * @format uuid
   */
  id: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsTemplatesTimelineRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
  environment?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /**
   * A UUID string identifying this parameter ledger.
   * @format uuid
   */
  id: string
  /** @format uuid */
  projectPk: string
}

export interface ProjectsTemplatesTimelinesRetrieveParams {
  /**
   * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
   * @format date-time
   */
  as_of?: string
  /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
  environment?: string
  /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
  tag?: string
  /** @format uuid */
  projectPk: string
}

export interface ServiceaccountsListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface TypesListParams {
  description__icontains?: string
  name__icontains?: string
  name__iexact?: string
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export interface TypesRulesListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  type?: 'max' | 'max_len' | 'min' | 'min_len' | 'one_of' | 'regex'
  /**
   * The parameter type id.
   * @format uuid
   */
  parametertypePk: string
}

export interface UsersListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
  type?: string
}

export interface UtilsGeneratePasswordCreateParams {
  /** The length of the password to generate.  Minimum of 8, maximum of 4095. */
  length: number
  /**
   * Default behavior is to fallback to /dev/urandom if we fail to get a random password from AWS Secrets Manager.  If set to 'True', we will not fallback to local password generation using /dev/urandom.  Default: False
   * @default false
   */
  require_hardware_generation?: boolean
  /**
   * The password must include lowercase letters [a-z]. Default: True.
   * @default true
   */
  require_lowercase?: boolean
  /**
   * The password must include numbers [0-9].  Default: True.
   * @default true
   */
  require_numbers?: boolean
  /**
   * The password must include spaces [ ].  Default: False.
   * @default false
   */
  require_spaces?: boolean
  /**
   * The password must include symbols [!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].  Default: False.
   * @default false
   */
  require_symbols?: boolean
  /**
   * The password must include uppercase letters [A-Z].  Default: True.
   * @default true
   */
  require_uppercase?: boolean
}

export interface WebhooksListParams {
  /** Which field to use when ordering the results. */
  ordering?: string
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  page_size?: number
}

export namespace Api {
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   * @tags api
   * @name ApiSchemaRetrieve
   * @request GET:/api/schema/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace ApiSchemaRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {
      format?: 'json' | 'yaml'
      lang?:
        | 'af'
        | 'ar'
        | 'ar-dz'
        | 'ast'
        | 'az'
        | 'be'
        | 'bg'
        | 'bn'
        | 'br'
        | 'bs'
        | 'ca'
        | 'cs'
        | 'cy'
        | 'da'
        | 'de'
        | 'dsb'
        | 'el'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'eo'
        | 'es'
        | 'es-ar'
        | 'es-co'
        | 'es-mx'
        | 'es-ni'
        | 'es-ve'
        | 'et'
        | 'eu'
        | 'fa'
        | 'fi'
        | 'fr'
        | 'fy'
        | 'ga'
        | 'gd'
        | 'gl'
        | 'he'
        | 'hi'
        | 'hr'
        | 'hsb'
        | 'hu'
        | 'hy'
        | 'ia'
        | 'id'
        | 'ig'
        | 'io'
        | 'is'
        | 'it'
        | 'ja'
        | 'ka'
        | 'kab'
        | 'kk'
        | 'km'
        | 'kn'
        | 'ko'
        | 'ky'
        | 'lb'
        | 'lt'
        | 'lv'
        | 'mk'
        | 'ml'
        | 'mn'
        | 'mr'
        | 'ms'
        | 'my'
        | 'nb'
        | 'ne'
        | 'nl'
        | 'nn'
        | 'os'
        | 'pa'
        | 'pl'
        | 'pt'
        | 'pt-br'
        | 'ro'
        | 'ru'
        | 'sk'
        | 'sl'
        | 'sq'
        | 'sr'
        | 'sr-latn'
        | 'sv'
        | 'sw'
        | 'ta'
        | 'te'
        | 'tg'
        | 'th'
        | 'tk'
        | 'tr'
        | 'tt'
        | 'udm'
        | 'uk'
        | 'ur'
        | 'uz'
        | 'vi'
        | 'zh-hans'
        | 'zh-hant'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Record<string, any>
  }
  /**
   * @description A searchable log of all the actions taken by users and service accounts within the organization.
   * @tags audit
   * @name AuditList
   * @request GET:/api/v1/audit/
   * @secure
   * @response `200` `PaginatedAuditTrailList`
   */
  export namespace AuditList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** The action that was taken. */
      action?: 'create' | 'delete' | 'expired' | 'pull' | 'push' | 'update'
      /** @format date-time */
      earliest?: string
      /**
       * Returns records for the environment, associated tags, and values.
       * @format uuid
       */
      environment_id?: string
      /** @format date-time */
      latest?: string
      object_id?: string
      object_type?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /**
       * Returns records for the parameter and associated values.
       * @format uuid
       */
      parameter_id?: string
      /**
       * Returns records for the project, it's parameters, and associated values.
       * @format uuid
       */
      project_id?: string
      user_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAuditTrailList
  }
  /**
   * @description Retrieve one record from the audit log.
   * @tags audit
   * @name AuditRetrieve
   * @request GET:/api/v1/audit/{id}/
   * @secure
   * @response `200` `AuditTrail`
   */
  export namespace AuditRetrieve {
    export type RequestParams = {
      /**
       * A unique identifier for the audit record.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AuditTrail
  }
  /**
   * @description Summary information about the organization's audit trail.
   * @tags audit
   * @name AuditSummaryRetrieve
   * @request GET:/api/v1/audit/summary/
   * @secure
   * @response `200` `AuditTrailSummary`
   */
  export namespace AuditSummaryRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AuditTrailSummary
  }
  /**
   * No description
   * @tags backup
   * @name BackupSnapshotCreate
   * @summary Get a snapshot of all Projects with parameters
   * @request POST:/api/v1/backup/snapshot/
   * @secure
   * @response `200` `BackupDataSnapshot`
   */
  export namespace BackupSnapshotCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BackupDataSnapshot
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsList
   * @request GET:/api/v1/environments/
   * @secure
   * @response `200` `PaginatedEnvironmentList`
   */
  export namespace EnvironmentsList {
    export type RequestParams = {}
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedEnvironmentList
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsCreate
   * @request POST:/api/v1/environments/
   * @secure
   * @response `201` `Environment`
   */
  export namespace EnvironmentsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EnvironmentCreate
    export type RequestHeaders = {}
    export type ResponseBody = Environment
  }
  /**
   * @description The push operations that this environment was involved in.
   * @tags environments
   * @name EnvironmentsPushesList
   * @summary List push operations.
   * @request GET:/api/v1/environments/{environment_pk}/pushes/
   * @secure
   * @response `200` `PaginatedTaskStepList`
   */
  export namespace EnvironmentsPushesList {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
    }
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedTaskStepList
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsList
   * @request GET:/api/v1/environments/{environment_pk}/tags/
   * @secure
   * @response `200` `PaginatedTagList`
   */
  export namespace EnvironmentsTagsList {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** @format date-time */
      timestamp?: string
      /** @format date-time */
      timestamp__gte?: string
      /** @format date-time */
      timestamp__lte?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedTagList
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsCreate
   * @request POST:/api/v1/environments/{environment_pk}/tags/
   * @secure
   * @response `201` `Tag`
   */
  export namespace EnvironmentsTagsCreate {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = TagCreate
    export type RequestHeaders = {}
    export type ResponseBody = Tag
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsRetrieve
   * @request GET:/api/v1/environments/{environment_pk}/tags/{id}/
   * @secure
   * @response `200` `Tag`
   */
  export namespace EnvironmentsTagsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
      /**
       * A unique identifier for the tag.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Tag
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsUpdate
   * @request PUT:/api/v1/environments/{environment_pk}/tags/{id}/
   * @secure
   * @response `200` `TagUpdate`
   */
  export namespace EnvironmentsTagsUpdate {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
      /**
       * A unique identifier for the tag.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = TagUpdate
    export type RequestHeaders = {}
    export type ResponseBody = TagUpdate
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsPartialUpdate
   * @request PATCH:/api/v1/environments/{environment_pk}/tags/{id}/
   * @secure
   * @response `200` `TagUpdate`
   */
  export namespace EnvironmentsTagsPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
      /**
       * A unique identifier for the tag.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedTagUpdate
    export type RequestHeaders = {}
    export type ResponseBody = TagUpdate
  }
  /**
   * @description Tags allow you to name stable points in time for your configuration. Any query API that accepts an `as_of` option will also accept a `tag` option however they are mutually exclusive.
   * @tags environments
   * @name EnvironmentsTagsDestroy
   * @request DELETE:/api/v1/environments/{environment_pk}/tags/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace EnvironmentsTagsDestroy {
    export type RequestParams = {
      /** @format uuid */
      environmentPk: string
      /**
       * A unique identifier for the tag.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsRetrieve
   * @request GET:/api/v1/environments/{id}/
   * @secure
   * @response `200` `Environment`
   */
  export namespace EnvironmentsRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this environment ledger.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Environment
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsUpdate
   * @request PUT:/api/v1/environments/{id}/
   * @secure
   * @response `200` `EnvironmentUpdate`
   */
  export namespace EnvironmentsUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the environment.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = EnvironmentUpdate
    export type RequestHeaders = {}
    export type ResponseBody = EnvironmentUpdate
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsPartialUpdate
   * @request PATCH:/api/v1/environments/{id}/
   * @secure
   * @response `200` `EnvironmentUpdate`
   */
  export namespace EnvironmentsPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the environment.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedEnvironmentUpdate
    export type RequestHeaders = {}
    export type ResponseBody = EnvironmentUpdate
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsDestroy
   * @request DELETE:/api/v1/environments/{id}/
   * @secure
   * @response `204` `void` Environment destroyed.
   * @response `409` `void` The environment has children and cannot be removed.
   */
  export namespace EnvironmentsDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the environment.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags environments
   * @name EnvironmentsCopyCreate
   * @request POST:/api/v1/environments/{id}/copy/
   * @secure
   * @response `201` `Environment` Environment copied
   * @response `400` `void` The Environment copy failed.
   * @response `403` `void` Permission denied.
   */
  export namespace EnvironmentsCopyCreate {
    export type RequestParams = {
      /**
       * A unique identifier for the environment.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = EnvironmentCopy
    export type RequestHeaders = {}
    export type ResponseBody = Environment
  }
  /**
   * @description Returns a dictionary of features and whether or not they are enabled
   * @tags features
   * @name FeaturesRetrieve
   * @request GET:/api/v1/features/
   * @secure
   * @response `200` `FeaturesResponse`
   */
  export namespace FeaturesRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FeaturesResponse
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsList
   * @request GET:/api/v1/grants/
   * @secure
   * @response `200` `PaginatedGrantList`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /**
       * Filter by principal (User, Group). Returns direct grant assignments, not indirect (user via group).
       * @format uri
       */
      principal?: string
      /** Filter by role. */
      role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
      /**
       * Filter by grant scope (Environment, Project).
       * @format uri
       */
      scope?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGrantList
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsCreate
   * @request POST:/api/v1/grants/
   * @secure
   * @response `201` `Grant`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = Grant
    export type RequestHeaders = {}
    export type ResponseBody = Grant
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsRetrieve
   * @request GET:/api/v1/grants/{id}/
   * @secure
   * @response `200` `Grant`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsRetrieve {
    export type RequestParams = {
      /**
       * A unique identifier for the grant.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Grant
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsUpdate
   * @request PUT:/api/v1/grants/{id}/
   * @secure
   * @response `200` `Grant`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the grant.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = GrantUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Grant
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsPartialUpdate
   * @request PATCH:/api/v1/grants/{id}/
   * @secure
   * @response `200` `Grant`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the grant.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedGrantUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Grant
  }
  /**
   * @description Grants allow you to enable access control on Environments and Projects. Grants are part of the role-based access control feature set, so if your subscription does not have support for it, these paths will return a 403 Forbidden error.
   * @tags grants
   * @name GrantsDestroy
   * @request DELETE:/api/v1/grants/{id}/
   * @secure
   * @response `204` `void` Deleted.
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the grant.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Removes grants matching the query parameters atomically. Use this technique to disable access control on a scope, or remove all grants for a user.
   * @tags grants
   * @name GrantsMultiDestroy
   * @request DELETE:/api/v1/grants/multi/
   * @secure
   * @response `200` `Grant`
   * @response `403` `void` Forbidden: see response for further details.
   */
  export namespace GrantsMultiDestroy {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Grant
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsList
   * @request GET:/api/v1/groups/
   * @secure
   * @response `200` `PaginatedGroupList`
   */
  export namespace GroupsList {
    export type RequestParams = {}
    export type RequestQuery = {
      name?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** Filter by user. */
      user?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGroupList
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsCreate
   * @request POST:/api/v1/groups/
   * @secure
   * @response `201` `Group`
   */
  export namespace GroupsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = Group
    export type RequestHeaders = {}
    export type ResponseBody = Group
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsRetrieve
   * @request GET:/api/v1/groups/{id}/
   * @secure
   * @response `200` `Group`
   */
  export namespace GroupsRetrieve {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Group
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsUpdate
   * @request PUT:/api/v1/groups/{id}/
   * @secure
   * @response `200` `Group`
   */
  export namespace GroupsUpdate {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = Group
    export type RequestHeaders = {}
    export type ResponseBody = Group
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsPartialUpdate
   * @request PATCH:/api/v1/groups/{id}/
   * @secure
   * @response `200` `Group`
   */
  export namespace GroupsPartialUpdate {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedGroup
    export type RequestHeaders = {}
    export type ResponseBody = Group
  }
  /**
   * @description Groups allow you to aggregate users for purposes of assigning grants more easily.
   * @tags groups
   * @name GroupsDestroy
   * @request DELETE:/api/v1/groups/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace GroupsDestroy {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Add a user to the group.
   * @tags groups
   * @name GroupsAddCreate
   * @request POST:/api/v1/groups/{id}/add/
   * @secure
   * @response `200` `GroupAddRemove`
   */
  export namespace GroupsAddCreate {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = GroupAddRemove
    export type RequestHeaders = {}
    export type ResponseBody = GroupAddRemove
  }
  /**
   * @description Remove a user from the group.
   * @tags groups
   * @name GroupsRemoveCreate
   * @request POST:/api/v1/groups/{id}/remove/
   * @secure
   * @response `200` `GroupAddRemove`
   */
  export namespace GroupsRemoveCreate {
    export type RequestParams = {
      /**
       * The unique identifier of a group.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = GroupAddRemove
    export type RequestHeaders = {}
    export type ResponseBody = GroupAddRemove
  }
  /**
   * @description Import parameters from the provided data.
   * @tags import
   * @name ImportCreate
   * @request POST:/api/v1/import/
   * @secure
   * @response `201` `ImportCreateResponse`
   */
  export namespace ImportCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * Mask secrets in the response
       * @default false
       */
      mask_secrets?: boolean
      /**
       * Do not create any objects
       * @default false
       */
      preview?: boolean
    }
    export type RequestBody = ImportCreateRequest
    export type RequestHeaders = {}
    export type ResponseBody = ImportCreateResponse
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsList
   * @request GET:/api/v1/integrations/aws/
   * @secure
   * @response `200` `PaginatedAwsIntegrationList`
   */
  export namespace IntegrationsAwsList {
    export type RequestParams = {}
    export type RequestQuery = {
      aws_account_id?: string
      aws_role_name?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsIntegrationList
  }
  /**
   * @description ### Description ### Establishes an AWS Integration for your CloudTruth organization. ### Pre-Conditions ### - An AWS Integration for the account and role cannot already exist. ### Post-Conditions ### - You must establish an IAM role and trust relationship based on the Role Name and the External ID.
   * @tags integrations
   * @name IntegrationsAwsCreate
   * @summary Establishes an AWS Integration.
   * @request POST:/api/v1/integrations/aws/
   * @secure
   * @response `201` `AwsIntegration`
   */
  export namespace IntegrationsAwsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AwsIntegrationCreate
    export type RequestHeaders = {}
    export type ResponseBody = AwsIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/
   * @secure
   * @response `200` `PaginatedAwsPullList`
   */
  export namespace IntegrationsAwsPullsList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPullList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsCreate
   * @request POST:/api/v1/integrations/aws/{awsintegration_pk}/pulls/
   * @secure
   * @response `201` `AwsPull`
   */
  export namespace IntegrationsAwsPullsCreate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPull
    export type RequestHeaders = {}
    export type ResponseBody = AwsPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsTasksList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{awspull_pk}/tasks/
   * @secure
   * @response `200` `PaginatedAwsPullTaskList`
   */
  export namespace IntegrationsAwsPullsTasksList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspullPk: string
    }
    export type RequestQuery = {
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The current state of this task. */
      state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPullTaskList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsTasksStepsList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{awspull_pk}/tasks/{awspulltask_pk}/steps/
   * @secure
   * @response `200` `PaginatedAwsPullTaskStepList`
   */
  export namespace IntegrationsAwsPullsTasksStepsList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspullPk: string
      /** @format uuid */
      awspulltaskPk: string
    }
    export type RequestQuery = {
      fqn?: string
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /**
       * The operation performed, if any.
       *
       * When the operation is an update, there may be additional details in the success_detail field to describe the change.
       *
       * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
       */
      operation?: 'create' | 'delete' | 'read' | 'update' | null
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      success?: boolean
      venue_id?: string
      venue_id__icontains?: string
      venue_name?: string
      venue_name__icontains?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPullTaskStepList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsTasksStepsRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{awspull_pk}/tasks/{awspulltask_pk}/steps/{id}/
   * @secure
   * @response `200` `AwsPullTaskStep`
   */
  export namespace IntegrationsAwsPullsTasksStepsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspullPk: string
      /** @format uuid */
      awspulltaskPk: string
      /**
       * Unique identifier for a task step.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPullTaskStep
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsTasksRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{awspull_pk}/tasks/{id}/
   * @secure
   * @response `200` `AwsPullTask`
   */
  export namespace IntegrationsAwsPullsTasksRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspullPk: string
      /**
       * The unique identifier for the task.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPullTask
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AwsPull`
   */
  export namespace IntegrationsAwsPullsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsUpdate
   * @request PUT:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AwsPull`
   */
  export namespace IntegrationsAwsPullsUpdate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPull
    export type RequestHeaders = {}
    export type ResponseBody = AwsPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsPartialUpdate
   * @request PATCH:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AwsPull`
   */
  export namespace IntegrationsAwsPullsPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAwsPull
    export type RequestHeaders = {}
    export type ResponseBody = AwsPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPullsDestroy
   * @request DELETE:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{id}/
   * @secure
   * @response `403` `void` Cannot destroy innate mapped pull of a data integration.
   */
  export namespace IntegrationsAwsPullsDestroy {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Enqueue a pull synchronization task for mapped external values accessible with this integration.
   * @tags integrations
   * @name IntegrationsAwsPullsSyncCreate
   * @request POST:/api/v1/integrations/aws/{awsintegration_pk}/pulls/{id}/sync/
   * @secure
   * @response `202` `void` Synchronization task enqueued.
   */
  export namespace IntegrationsAwsPullsSyncCreate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPullSyncActionRequest
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/
   * @secure
   * @response `200` `PaginatedAwsPushList`
   */
  export namespace IntegrationsAwsPushesList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      /**
       * Filter results to pushes that operate on a tag in the given environment.
       * @format uri
       */
      environment?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /**
       * Filter results to pushes that operate on the given project.
       * @format uri
       */
      project?: string
      /**
       * Filter results to pushes that operate on the given tag.
       * @format uri
       */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPushList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesCreate
   * @request POST:/api/v1/integrations/aws/{awsintegration_pk}/pushes/
   * @secure
   * @response `201` `AwsPush`
   */
  export namespace IntegrationsAwsPushesCreate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPush
    export type RequestHeaders = {}
    export type ResponseBody = AwsPush
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesTasksList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{awspush_pk}/tasks/
   * @secure
   * @response `200` `PaginatedAwsPushTaskList`
   */
  export namespace IntegrationsAwsPushesTasksList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspushPk: string
    }
    export type RequestQuery = {
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The current state of this task. */
      state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPushTaskList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesTasksStepsList
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{awspush_pk}/tasks/{awspushtask_pk}/steps/
   * @secure
   * @response `200` `PaginatedAwsPushTaskStepList`
   */
  export namespace IntegrationsAwsPushesTasksStepsList {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspushPk: string
      /** @format uuid */
      awspushtaskPk: string
    }
    export type RequestQuery = {
      fqn?: string
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /**
       * The operation performed, if any.
       *
       * When the operation is an update, there may be additional details in the success_detail field to describe the change.
       *
       * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
       */
      operation?: 'create' | 'delete' | 'read' | 'update' | null
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      success?: boolean
      venue_id?: string
      venue_id__icontains?: string
      venue_name?: string
      venue_name__icontains?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAwsPushTaskStepList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesTasksStepsRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{awspush_pk}/tasks/{awspushtask_pk}/steps/{id}/
   * @secure
   * @response `200` `AwsPushTaskStep`
   */
  export namespace IntegrationsAwsPushesTasksStepsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspushPk: string
      /** @format uuid */
      awspushtaskPk: string
      /**
       * Unique identifier for a task step.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPushTaskStep
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesTasksRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{awspush_pk}/tasks/{id}/
   * @secure
   * @response `200` `AwsPushTask`
   */
  export namespace IntegrationsAwsPushesTasksRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /** @format uuid */
      awspushPk: string
      /**
       * The unique identifier for the task.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPushTask
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesRetrieve
   * @request GET:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AwsPush`
   */
  export namespace IntegrationsAwsPushesRetrieve {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsPush
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesUpdate
   * @request PUT:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AwsPushUpdate`
   */
  export namespace IntegrationsAwsPushesUpdate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPushUpdate
    export type RequestHeaders = {}
    export type ResponseBody = AwsPushUpdate
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesPartialUpdate
   * @request PATCH:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AwsPushUpdate`
   */
  export namespace IntegrationsAwsPushesPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAwsPushUpdate
    export type RequestHeaders = {}
    export type ResponseBody = AwsPushUpdate
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPushesDestroy
   * @request DELETE:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace IntegrationsAwsPushesDestroy {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Enqueue a push synchronization task.
   * @tags integrations
   * @name IntegrationsAwsPushesSyncCreate
   * @request POST:/api/v1/integrations/aws/{awsintegration_pk}/pushes/{id}/sync/
   * @secure
   * @response `202` `void` Synchronization task enqueued.
   */
  export namespace IntegrationsAwsPushesSyncCreate {
    export type RequestParams = {
      /** @format uuid */
      awsintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsPush
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsRetrieve
   * @summary Get details of an AWS Integration.
   * @request GET:/api/v1/integrations/aws/{id}/
   * @secure
   * @response `200` `AwsIntegration`
   */
  export namespace IntegrationsAwsRetrieve {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /** Trigger a refresh of the integration status before returning the details. */
      refresh_status?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AwsIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsUpdate
   * @request PUT:/api/v1/integrations/aws/{id}/
   * @secure
   * @response `200` `AwsIntegration`
   */
  export namespace IntegrationsAwsUpdate {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsIntegration
    export type RequestHeaders = {}
    export type ResponseBody = AwsIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsPartialUpdate
   * @request PATCH:/api/v1/integrations/aws/{id}/
   * @secure
   * @response `200` `AwsIntegration`
   */
  export namespace IntegrationsAwsPartialUpdate {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAwsIntegration
    export type RequestHeaders = {}
    export type ResponseBody = AwsIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAwsDestroy
   * @summary Delete an AWS integration.
   * @request DELETE:/api/v1/integrations/aws/{id}/
   * @secure
   * @response `204` `void` Integration removed.
   * @response `409` `void` The integration is used by one (or more) Value(s) and cannot be removed.
   */
  export namespace IntegrationsAwsDestroy {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /**
       * (Optional) Desired behavior if the integration has in-use values.
       *
       * - `fail` will return HTTP error 409 if there are any values using the integration.
       * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
       * - `remove` will remove the all values using the integration when the integration is removed.
       */
      in_use?: 'fail' | 'leave' | 'remove'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Probe a region and service using a pattern matching string that can be used in pull actions.  This allows the pattern match to be checked for correctness and preview what will match during creation of the string.
   * @tags integrations
   * @name IntegrationsAwsScanCreate
   * @summary Evaluate a potential pull pattern and understand what it will match.
   * @request POST:/api/v1/integrations/aws/{id}/scan/
   * @secure
   * @response `200` `DiscoveryResult`
   */
  export namespace IntegrationsAwsScanCreate {
    export type RequestParams = {
      /** @format uuid */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AwsIntegrationScan
    export type RequestHeaders = {}
    export type ResponseBody = DiscoveryResult
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultList
   * @request GET:/api/v1/integrations/azure/key_vault/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultIntegrationList`
   */
  export namespace IntegrationsAzureKeyVaultList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** @format uuid */
      tenant_id?: string
      vault_name?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultIntegrationList
  }
  /**
   * @description ### Description ### Establishes an Azure Key Vault Integration for your CloudTruth organization. ### Pre-Conditions ### - An OAuth2 request to install the CloudTruth application in your Azure Tenant must acquire your consent.  The resulting JWT access key will contain a `tid` claim that is your Tenant ID.  Use this Tenant ID to set up your integration. ### Post-Conditions ### - You must then grant the CloudTruth service principal rights to access the key  vault as a Secrets Manager role (read and write access - purge is not required).
   * @tags integrations
   * @name IntegrationsAzureKeyVaultCreate
   * @summary Establishes an Azure Key Vault Integration.
   * @request POST:/api/v1/integrations/azure/key_vault/
   * @secure
   * @response `201` `AzureKeyVaultIntegration`
   */
  export namespace IntegrationsAzureKeyVaultCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultIntegrationCreate
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPullList`
   */
  export namespace IntegrationsAzureKeyVaultPullsList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPullList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsCreate
   * @request POST:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/
   * @secure
   * @response `201` `AzureKeyVaultPull`
   */
  export namespace IntegrationsAzureKeyVaultPullsCreate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPull
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsTasksList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{akvpull_pk}/tasks/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPullTaskList`
   */
  export namespace IntegrationsAzureKeyVaultPullsTasksList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpullPk: string
    }
    export type RequestQuery = {
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The current state of this task. */
      state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPullTaskList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsTasksStepsList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{akvpull_pk}/tasks/{akvpulltask_pk}/steps/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPullTaskStepList`
   */
  export namespace IntegrationsAzureKeyVaultPullsTasksStepsList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpullPk: string
      /** @format uuid */
      akvpulltaskPk: string
    }
    export type RequestQuery = {
      fqn?: string
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /**
       * The operation performed, if any.
       *
       * When the operation is an update, there may be additional details in the success_detail field to describe the change.
       *
       * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
       */
      operation?: 'create' | 'delete' | 'read' | 'update' | null
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      success?: boolean
      venue_id?: string
      venue_id__icontains?: string
      venue_name?: string
      venue_name__icontains?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPullTaskStepList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsTasksStepsRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{akvpull_pk}/tasks/{akvpulltask_pk}/steps/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPullTaskStep`
   */
  export namespace IntegrationsAzureKeyVaultPullsTasksStepsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpullPk: string
      /** @format uuid */
      akvpulltaskPk: string
      /**
       * Unique identifier for a task step.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPullTaskStep
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsTasksRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{akvpull_pk}/tasks/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPullTask`
   */
  export namespace IntegrationsAzureKeyVaultPullsTasksRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpullPk: string
      /**
       * The unique identifier for the task.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPullTask
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPull`
   */
  export namespace IntegrationsAzureKeyVaultPullsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsUpdate
   * @request PUT:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPull`
   */
  export namespace IntegrationsAzureKeyVaultPullsUpdate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPull
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsPartialUpdate
   * @request PATCH:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPull`
   */
  export namespace IntegrationsAzureKeyVaultPullsPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAzureKeyVaultPull
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsDestroy
   * @request DELETE:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{id}/
   * @secure
   * @response `403` `void` Cannot destroy innate mapped pull of a data integration.
   */
  export namespace IntegrationsAzureKeyVaultPullsDestroy {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Enqueue a pull synchronization task for mapped external values accessible with this integration.
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPullsSyncCreate
   * @request POST:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pulls/{id}/sync/
   * @secure
   * @response `202` `void` Synchronization task enqueued.
   */
  export namespace IntegrationsAzureKeyVaultPullsSyncCreate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPullSyncActionRequest
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPushList`
   */
  export namespace IntegrationsAzureKeyVaultPushesList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      /**
       * Filter results to pushes that operate on a tag in the given environment.
       * @format uri
       */
      environment?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /**
       * Filter results to pushes that operate on the given project.
       * @format uri
       */
      project?: string
      /**
       * Filter results to pushes that operate on the given tag.
       * @format uri
       */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPushList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesCreate
   * @request POST:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/
   * @secure
   * @response `201` `AzureKeyVaultPush`
   */
  export namespace IntegrationsAzureKeyVaultPushesCreate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPush
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPush
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesTasksList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{akvpush_pk}/tasks/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPushTaskList`
   */
  export namespace IntegrationsAzureKeyVaultPushesTasksList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpushPk: string
    }
    export type RequestQuery = {
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The current state of this task. */
      state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPushTaskList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesTasksStepsList
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{akvpush_pk}/tasks/{akvpushtask_pk}/steps/
   * @secure
   * @response `200` `PaginatedAzureKeyVaultPushTaskStepList`
   */
  export namespace IntegrationsAzureKeyVaultPushesTasksStepsList {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpushPk: string
      /** @format uuid */
      akvpushtaskPk: string
    }
    export type RequestQuery = {
      fqn?: string
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /**
       * The operation performed, if any.
       *
       * When the operation is an update, there may be additional details in the success_detail field to describe the change.
       *
       * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
       */
      operation?: 'create' | 'delete' | 'read' | 'update' | null
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      success?: boolean
      venue_id?: string
      venue_id__icontains?: string
      venue_name?: string
      venue_name__icontains?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedAzureKeyVaultPushTaskStepList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesTasksStepsRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{akvpush_pk}/tasks/{akvpushtask_pk}/steps/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPushTaskStep`
   */
  export namespace IntegrationsAzureKeyVaultPushesTasksStepsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpushPk: string
      /** @format uuid */
      akvpushtaskPk: string
      /**
       * Unique identifier for a task step.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPushTaskStep
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesTasksRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{akvpush_pk}/tasks/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPushTask`
   */
  export namespace IntegrationsAzureKeyVaultPushesTasksRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /** @format uuid */
      akvpushPk: string
      /**
       * The unique identifier for the task.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPushTask
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesRetrieve
   * @request GET:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPush`
   */
  export namespace IntegrationsAzureKeyVaultPushesRetrieve {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPush
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesUpdate
   * @request PUT:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPushUpdate`
   */
  export namespace IntegrationsAzureKeyVaultPushesUpdate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPushUpdate
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPushUpdate
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesPartialUpdate
   * @request PATCH:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{id}/
   * @secure
   * @response `200` `AzureKeyVaultPushUpdate`
   */
  export namespace IntegrationsAzureKeyVaultPushesPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAzureKeyVaultPushUpdate
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultPushUpdate
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesDestroy
   * @request DELETE:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace IntegrationsAzureKeyVaultPushesDestroy {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Enqueue a push synchronization task.
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPushesSyncCreate
   * @request POST:/api/v1/integrations/azure/key_vault/{akvintegration_pk}/pushes/{id}/sync/
   * @secure
   * @response `202` `void` Synchronization task enqueued.
   */
  export namespace IntegrationsAzureKeyVaultPushesSyncCreate {
    export type RequestParams = {
      /** @format uuid */
      akvintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultPush
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultRetrieve
   * @summary Get details of an Azure Key Vault Integration.
   * @request GET:/api/v1/integrations/azure/key_vault/{id}/
   * @secure
   * @response `200` `AzureKeyVaultIntegration`
   */
  export namespace IntegrationsAzureKeyVaultRetrieve {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /** Trigger a refresh of the integration status before returning the details. */
      refresh_status?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultUpdate
   * @request PUT:/api/v1/integrations/azure/key_vault/{id}/
   * @secure
   * @response `200` `AzureKeyVaultIntegration`
   */
  export namespace IntegrationsAzureKeyVaultUpdate {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultIntegration
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultPartialUpdate
   * @request PATCH:/api/v1/integrations/azure/key_vault/{id}/
   * @secure
   * @response `200` `AzureKeyVaultIntegration`
   */
  export namespace IntegrationsAzureKeyVaultPartialUpdate {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedAzureKeyVaultIntegration
    export type RequestHeaders = {}
    export type ResponseBody = AzureKeyVaultIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsAzureKeyVaultDestroy
   * @summary Delete an Azure Key Vault integration.
   * @request DELETE:/api/v1/integrations/azure/key_vault/{id}/
   * @secure
   * @response `204` `void` Integration removed.
   * @response `409` `void` The integration is used by one (or more) Value(s) and cannot be removed.
   */
  export namespace IntegrationsAzureKeyVaultDestroy {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /**
       * (Optional) Desired behavior if the integration has in-use values.
       *
       * - `fail` will return HTTP error 409 if there are any values using the integration.
       * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
       * - `remove` will remove the all values using the integration when the integration is removed.
       */
      in_use?: 'fail' | 'leave' | 'remove'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Probe a region and service using a pattern matching string that can be used in pull actions.  This allows the pattern match to be checked for correctness and preview what will match during creation of the string.
   * @tags integrations
   * @name IntegrationsAzureKeyVaultScanCreate
   * @summary Evaluate a potential pull pattern and understand what it will match.
   * @request POST:/api/v1/integrations/azure/key_vault/{id}/scan/
   * @secure
   * @response `200` `DiscoveryResult`
   */
  export namespace IntegrationsAzureKeyVaultScanCreate {
    export type RequestParams = {
      /** @format uuid */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = AzureKeyVaultIntegrationScan
    export type RequestHeaders = {}
    export type ResponseBody = DiscoveryResult
  }
  /**
   * @description ### Description ### Queries a third-party integration to retrieve the data specified by the FQN. You can start exploring by not specifying an 'fqn', which will return a list of FQNs for the existing third-party integrations. Third-party integrations can be configured via the Integrations section of the web application.
   * @tags integrations
   * @name IntegrationsExploreList
   * @summary Retrieve third-party integration data for the specified FQN.
   * @request GET:/api/v1/integrations/explore/
   * @secure
   * @response `200` `PaginatedIntegrationNodeList` The content at the FQN.
   * @response `400` `void` Invalid FQN requested.
   * @response `403` `void` Unable to contact the third-party integration.
   * @response `415` `void` Unsupported content type (usually this means it is binary).
   * @response `507` `void` Content exceeds internal size limit of 1MB.
   */
  export namespace IntegrationsExploreList {
    export type RequestParams = {}
    export type RequestQuery = {
      /**
       * FQN (URL-like) for third-party integration.
       * @format uri
       */
      fqn?: string
      /** JMES-path qualifier (key within file) */
      jmes?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** When paginating, this is the value that came back in the last page's next */
      page_token?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedIntegrationNodeList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubList
   * @request GET:/api/v1/integrations/github/
   * @secure
   * @response `200` `PaginatedGitHubIntegrationList`
   */
  export namespace IntegrationsGithubList {
    export type RequestParams = {}
    export type RequestQuery = {
      gh_organization_slug?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGitHubIntegrationList
  }
  /**
   * @description ### Description ### Establishes a GitHub Integration in your CloudTruth organization. ### Pre-Conditions ### - The user must be an Administrator or Owner of your organization. - A GitHub Integration with the `installation_id` cannot already exist in this organization. - The user must first install the CloudTruth GitHub Application in their GitHub organization and obtain the `installation_id` of the application in order to create the integration. ### Initiating the GitHub Application Installation ### - Go to `https://github.com/apps/GITHUB_APP_NAME/installations/new?state=<bearer_token>` - On successful installation the browser will return to `https://APP_URL/app_setup/github` (configured in ctops/bin/github*) and provide the `installation_id` in the URI. - POST to this api to verify and establish the integration.
   * @tags integrations
   * @name IntegrationsGithubCreate
   * @summary Establishes a GitHub Integration.
   * @request POST:/api/v1/integrations/github/
   * @secure
   * @response `201` `GitHubIntegration`
   */
  export namespace IntegrationsGithubCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GitHubIntegrationCreate
    export type RequestHeaders = {}
    export type ResponseBody = GitHubIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsList
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/
   * @secure
   * @response `200` `PaginatedGitHubPullList`
   */
  export namespace IntegrationsGithubPullsList {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
    }
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGitHubPullList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsTasksList
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/{githubpull_pk}/tasks/
   * @secure
   * @response `200` `PaginatedGitHubPullTaskList`
   */
  export namespace IntegrationsGithubPullsTasksList {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /** @format uuid */
      githubpullPk: string
    }
    export type RequestQuery = {
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The current state of this task. */
      state?: 'failure' | 'queued' | 'running' | 'skipped' | 'success'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGitHubPullTaskList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsTasksStepsList
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/{githubpull_pk}/tasks/{githubpulltask_pk}/steps/
   * @secure
   * @response `200` `PaginatedGitHubPullTaskStepList`
   */
  export namespace IntegrationsGithubPullsTasksStepsList {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /** @format uuid */
      githubpullPk: string
      /** @format uuid */
      githubpulltaskPk: string
    }
    export type RequestQuery = {
      fqn?: string
      /** @format date-time */
      modified_at?: string
      /** @format date-time */
      modified_at__gte?: string
      /** @format date-time */
      modified_at__lte?: string
      /**
       * The operation performed, if any.
       *
       * When the operation is an update, there may be additional details in the success_detail field to describe the change.
       *
       * When the project is filled in but the environment and parameterare not, the operation is on the project.  When the environmentis filled in but the project and parameter are not, the operationis on the environment.  When the project and parameter are filledin but the environment is not, the operation is on the parameter.When all three are filled in, the operation is on the value ofthe parameter of the project in the specified environment.
       */
      operation?: 'create' | 'delete' | 'read' | 'update' | null
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      success?: boolean
      venue_id?: string
      venue_id__icontains?: string
      venue_name?: string
      venue_name__icontains?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedGitHubPullTaskStepList
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsTasksStepsRetrieve
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/{githubpull_pk}/tasks/{githubpulltask_pk}/steps/{id}/
   * @secure
   * @response `200` `GitHubPullTaskStep`
   */
  export namespace IntegrationsGithubPullsTasksStepsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /** @format uuid */
      githubpullPk: string
      /** @format uuid */
      githubpulltaskPk: string
      /**
       * Unique identifier for a task step.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GitHubPullTaskStep
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsTasksRetrieve
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/{githubpull_pk}/tasks/{id}/
   * @secure
   * @response `200` `GitHubPullTask`
   */
  export namespace IntegrationsGithubPullsTasksRetrieve {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /** @format uuid */
      githubpullPk: string
      /**
       * The unique identifier for the task.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GitHubPullTask
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsRetrieve
   * @request GET:/api/v1/integrations/github/{githubintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `GitHubPull`
   */
  export namespace IntegrationsGithubPullsRetrieve {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GitHubPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsUpdate
   * @request PUT:/api/v1/integrations/github/{githubintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `GitHubPull`
   */
  export namespace IntegrationsGithubPullsUpdate {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = GitHubPull
    export type RequestHeaders = {}
    export type ResponseBody = GitHubPull
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubPullsPartialUpdate
   * @request PATCH:/api/v1/integrations/github/{githubintegration_pk}/pulls/{id}/
   * @secure
   * @response `200` `GitHubPull`
   */
  export namespace IntegrationsGithubPullsPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedGitHubPull
    export type RequestHeaders = {}
    export type ResponseBody = GitHubPull
  }
  /**
   * @description Enqueue a pull synchronization task for mapped external values accessible with this integration.
   * @tags integrations
   * @name IntegrationsGithubPullsSyncCreate
   * @request POST:/api/v1/integrations/github/{githubintegration_pk}/pulls/{id}/sync/
   * @secure
   * @response `202` `void` Synchronization task enqueued.
   */
  export namespace IntegrationsGithubPullsSyncCreate {
    export type RequestParams = {
      /** @format uuid */
      githubintegrationPk: string
      /**
       * Unique identifier for the action.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = GitHubPull
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubRetrieve
   * @summary Get details of a GitHub Integration.
   * @request GET:/api/v1/integrations/github/{id}/
   * @secure
   * @response `200` `GitHubIntegration`
   */
  export namespace IntegrationsGithubRetrieve {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /** Refresh the integration status before returning the details. */
      refresh_status?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GitHubIntegration
  }
  /**
   * No description
   * @tags integrations
   * @name IntegrationsGithubDestroy
   * @summary Delete a GitHub integration.
   * @request DELETE:/api/v1/integrations/github/{id}/
   * @secure
   * @response `204` `void` Integration removed.
   * @response `409` `void` The integration is used by one (or more) Value(s) and cannot be removed.
   */
  export namespace IntegrationsGithubDestroy {
    export type RequestParams = {
      /**
       * The unique identifier for the integration.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {
      /**
       * (Optional) Desired behavior if the integration has in-use values.
       *
       * - `fail` will return HTTP error 409 if there are any values using the integration.
       * - `leave` (default) will leave values in place and future queries may fail; you can control future value query behavior with the `lookup_error` query parameter on those requests.
       * - `remove` will remove the all values using the integration when the integration is removed.
       */
      in_use?: 'fail' | 'leave' | 'remove'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags invitations
   * @name InvitationsList
   * @request GET:/api/v1/invitations/
   * @secure
   * @response `200` `PaginatedInvitationList`
   */
  export namespace InvitationsList {
    export type RequestParams = {}
    export type RequestQuery = {
      email?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The role that the user will have in the organization, should the user accept. */
      role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
      /** The current state of the invitation. */
      state?: 'accepted' | 'bounced' | 'pending' | 'sent'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedInvitationList
  }
  /**
   * @description Extend an invitation for someone else to join your organization.
   * @tags invitations
   * @name InvitationsCreate
   * @summary Create an invitation.
   * @request POST:/api/v1/invitations/
   * @secure
   * @response `201` `Invitation`
   * @response `403` `void` Permission denied.  Is the invitation role more permissive than your own?
   * @response `404` `void` Bad Request.  Is there already an invitation for that email?
   */
  export namespace InvitationsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = InvitationCreate
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * No description
   * @tags invitations
   * @name InvitationsRetrieve
   * @request GET:/api/v1/invitations/{id}/
   * @secure
   * @response `200` `Invitation`
   */
  export namespace InvitationsRetrieve {
    export type RequestParams = {
      /**
       * The unique identifier of an invitation.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * No description
   * @tags invitations
   * @name InvitationsUpdate
   * @request PUT:/api/v1/invitations/{id}/
   * @secure
   * @response `200` `Invitation`
   */
  export namespace InvitationsUpdate {
    export type RequestParams = {
      /**
       * The unique identifier of an invitation.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = Invitation
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * No description
   * @tags invitations
   * @name InvitationsPartialUpdate
   * @request PATCH:/api/v1/invitations/{id}/
   * @secure
   * @response `200` `Invitation`
   */
  export namespace InvitationsPartialUpdate {
    export type RequestParams = {
      /**
       * The unique identifier of an invitation.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedInvitation
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * No description
   * @tags invitations
   * @name InvitationsDestroy
   * @request DELETE:/api/v1/invitations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace InvitationsDestroy {
    export type RequestParams = {
      /**
       * The unique identifier of an invitation.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Accept an invitation to join an organization. The email address used to log in and accept the invitation must match the email address specified by the inviting user when creating the invitation. On success the client receives the invitation record as it was updated. The client should then regenerate the JWT with the organization scope and proceed to the default landing page.
   * @tags invitations
   * @name InvitationsAcceptCreate
   * @summary Accept an invitation.
   * @request POST:/api/v1/invitations/{id}/accept/
   * @secure
   * @response `200` `Invitation` The invitation was accepted.  The client should obtain an organization scope token and proceed to the landing page.
   * @response `403` `void` Permission denied.  The accepting user's email may not match the invitation?
   * @response `404` `void` Bad Request.  The invitation does not exist or has already been accepted?
   */
  export namespace InvitationsAcceptCreate {
    export type RequestParams = {
      /**
       * The invitation ID.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * @description Re-send an invitation to the recipient.
   * @tags invitations
   * @name InvitationsResendCreate
   * @summary Resend an invitation.
   * @request POST:/api/v1/invitations/{id}/resend/
   * @secure
   * @response `200` `Invitation` The invitation state was reset to `pending`, which causes it to get sent again.  The most recent state is returned.  Clients should check the state.
   * @response `404` `void` Bad Request.  Was the invitation already accepted?
   */
  export namespace InvitationsResendCreate {
    export type RequestParams = {
      /**
       * The invitation ID.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Invitation
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsList
   * @request GET:/api/v1/memberships/
   * @secure
   * @response `200` `PaginatedMembershipList`
   */
  export namespace MembershipsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** The role that the user has in the organization. */
      role?: 'ADMIN' | 'CONTRIB' | 'NO_SECRETS' | 'OWNER' | 'VIEWER'
      /** The unique identifier of a user. */
      user?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedMembershipList
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsCreate
   * @request POST:/api/v1/memberships/
   * @secure
   * @response `201` `Membership`
   */
  export namespace MembershipsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = MembershipCreate
    export type RequestHeaders = {}
    export type ResponseBody = Membership
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsRetrieve
   * @request GET:/api/v1/memberships/{id}/
   * @secure
   * @response `200` `Membership`
   */
  export namespace MembershipsRetrieve {
    export type RequestParams = {
      /**
       * A unique identifier for the membership.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Membership
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsUpdate
   * @request PUT:/api/v1/memberships/{id}/
   * @secure
   * @response `200` `Membership`
   */
  export namespace MembershipsUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the membership.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = Membership
    export type RequestHeaders = {}
    export type ResponseBody = Membership
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsPartialUpdate
   * @request PATCH:/api/v1/memberships/{id}/
   * @secure
   * @response `200` `Membership`
   */
  export namespace MembershipsPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the membership.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedMembership
    export type RequestHeaders = {}
    export type ResponseBody = Membership
  }
  /**
   * No description
   * @tags memberships
   * @name MembershipsDestroy
   * @request DELETE:/api/v1/memberships/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace MembershipsDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the membership.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsList
   * @request GET:/api/v1/organizations/
   * @secure
   * @response `200` `PaginatedOrganizationList`
   */
  export namespace OrganizationsList {
    export type RequestParams = {}
    export type RequestQuery = {
      name?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedOrganizationList
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsCreate
   * @request POST:/api/v1/organizations/
   * @secure
   * @response `201` `Organization`
   */
  export namespace OrganizationsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrganizationCreate
    export type RequestHeaders = {}
    export type ResponseBody = Organization
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsRetrieve
   * @request GET:/api/v1/organizations/{id}/
   * @secure
   * @response `200` `Organization`
   */
  export namespace OrganizationsRetrieve {
    export type RequestParams = {
      /** A unique identifier for the organization. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Organization
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsUpdate
   * @request PUT:/api/v1/organizations/{id}/
   * @secure
   * @response `200` `Organization`
   */
  export namespace OrganizationsUpdate {
    export type RequestParams = {
      /** A unique identifier for the organization. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = Organization
    export type RequestHeaders = {}
    export type ResponseBody = Organization
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsPartialUpdate
   * @request PATCH:/api/v1/organizations/{id}/
   * @secure
   * @response `200` `Organization`
   */
  export namespace OrganizationsPartialUpdate {
    export type RequestParams = {
      /** A unique identifier for the organization. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedOrganization
    export type RequestHeaders = {}
    export type ResponseBody = Organization
  }
  /**
   * No description
   * @tags organizations
   * @name OrganizationsDestroy
   * @request DELETE:/api/v1/organizations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace OrganizationsDestroy {
    export type RequestParams = {
      /** A unique identifier for the organization. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsList
   * @request GET:/api/v1/projects/
   * @secure
   * @response `200` `PaginatedProjectList`
   */
  export namespace ProjectsList {
    export type RequestParams = {}
    export type RequestQuery = {
      description__icontains?: string
      name?: string
      name__icontains?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedProjectList
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsCreate
   * @request POST:/api/v1/projects/
   * @secure
   * @response `201` `Project`
   */
  export namespace ProjectsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProjectCreate
    export type RequestHeaders = {}
    export type ResponseBody = Project
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsRetrieve
   * @request GET:/api/v1/projects/{id}/
   * @secure
   * @response `200` `Project`
   */
  export namespace ProjectsRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this project ledger.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Project
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsUpdate
   * @request PUT:/api/v1/projects/{id}/
   * @secure
   * @response `200` `ProjectUpdate`
   */
  export namespace ProjectsUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the project.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ProjectUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ProjectUpdate
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsPartialUpdate
   * @request PATCH:/api/v1/projects/{id}/
   * @secure
   * @response `200` `ProjectUpdate`
   */
  export namespace ProjectsPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the project.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedProjectUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ProjectUpdate
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsDestroy
   * @request DELETE:/api/v1/projects/{id}/
   * @secure
   * @response `204` `void` Project destroyed.
   * @response `403` `void` Permission denied.
   * @response `409` `void` The project has dependents and cannot be removed.
   */
  export namespace ProjectsDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the project.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsCopyCreate
   * @request POST:/api/v1/projects/{id}/copy/
   * @secure
   * @response `201` `Project` Project copied
   * @response `400` `void` The Project copy failed.
   */
  export namespace ProjectsCopyCreate {
    export type RequestParams = {
      /**
       * A unique identifier for the project.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ProjectCopy
    export type RequestHeaders = {}
    export type ResponseBody = Project
  }
  /**
   * @description Exports all parameters in this project in the requested format. Parameter names and values will be coerced to the proper format (e.g. for a dotenv export, my_parameter will be capitalized to MY_PARAMETER and its value will be in a quoted string).  Note that capitalization is the only name coercion that will be performed on parameter names, names that are invalid for a given format will be omitted.
   * @tags projects
   * @name ProjectsParameterExportList
   * @request GET:/api/v1/projects/{project_pk}/parameter-export/
   * @secure
   * @response `200` `ParameterExport`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsParameterExportList {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Only include parameters with names that contain the provided string. */
      contains?: string
      /** Only include parameters with names that end with the provided string. */
      endswith?: string
      /** Name or id of the environment to use to retrieve parameter values. */
      environment?: string
      /**
       * Explicitly marks parameters with export, e.g. `export FOO=bar`.
       * @default false
       */
      explicit_export?: boolean
      /**
       * Masks all secrets in the template with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** Which field to use when ordering the results. */
      ordering?: string
      /** Format to output: One of 'docker', 'dotenv', 'shell'. */
      output?: string
      /** Only include parameters with names that start with the provided string. */
      startswith?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterExport
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersList
   * @request GET:/api/v1/projects/{project_pk}/parameters/
   * @secure
   * @response `200` `PaginatedParameterList`
   */
  export namespace ProjectsParametersList {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      description__icontains?: string
      /** Specify a list of comma-separated environment names or ids (uuid) to compare values. Only returns a parameter if there is a difference. Cannot be used with `environment` or `values`. If used with `mask_secrets` then no secret parameters will be included in the result. */
      difference?: string
      /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
       * Has no effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
      /**
       * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
       * @default false
       */
      immediate_parameters?: boolean
      /**
       * If true, replaces all secrets with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      name?: string
      name__contains?: string
      name__icontains?: string
      name__iexact?: string
      name__istartswith?: string
      name__startswith?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      secret?: boolean
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
      /**
       * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
       * @default true
       */
      values?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedParameterList
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersCreate
   * @request POST:/api/v1/projects/{project_pk}/parameters/
   * @secure
   * @response `201` `Parameter`
   */
  export namespace ProjectsParametersCreate {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterCreate
    export type RequestHeaders = {}
    export type ResponseBody = Parameter
  }
  /**
   * @description The push operations that this parameter was involved in.
   * @tags projects
   * @name ProjectsParametersPushesList
   * @summary List push operations.
   * @request GET:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/pushes/
   * @secure
   * @response `200` `PaginatedTaskStepList`
   */
  export namespace ProjectsParametersPushesList {
    export type RequestParams = {
      /** @format uuid */
      parameterPk: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedTaskStepList
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesList
   * @request GET:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/
   * @secure
   * @response `200` `PaginatedParameterRuleList`
   */
  export namespace ProjectsParametersRulesList {
    export type RequestParams = {
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      type?: 'max' | 'max_len' | 'min' | 'min_len' | 'one_of' | 'regex'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedParameterRuleList
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesCreate
   * @request POST:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/
   * @secure
   * @response `201` `ParameterRule`
   */
  export namespace ProjectsParametersRulesCreate {
    export type RequestParams = {
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterRuleCreate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterRule
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesRetrieve
   * @request GET:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterRule`
   */
  export namespace ProjectsParametersRulesRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterRule
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesUpdate
   * @request PUT:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterRuleUpdate`
   */
  export namespace ProjectsParametersRulesUpdate {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterRuleUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterRuleUpdate
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesPartialUpdate
   * @request PATCH:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterRuleUpdate`
   */
  export namespace ProjectsParametersRulesPartialUpdate {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedParameterRuleUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterRuleUpdate
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRulesDestroy
   * @request DELETE:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/rules/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace ProjectsParametersRulesDestroy {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieve previously set values of a parameter in one or all environments. To see all the _effective_ values for a parameter across every environment, use the Parameters API (see the `values` field).
   * @tags projects
   * @name ProjectsParametersValuesList
   * @summary Retrieve values.
   * @request GET:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/
   * @secure
   * @response `200` `PaginatedValueList`
   */
  export namespace ProjectsParametersValuesList {
    export type RequestParams = {
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
      /** A comma-separated list of field names to exclude from the response. */
      exclude?: string
      /** A comma-separated list of field names to include in the response. */
      include?: string
      /**
       * Mask secret values in responses with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedValueList
  }
  /**
   * @description Set the value of a parameter in an environment.
   * @tags projects
   * @name ProjectsParametersValuesCreate
   * @summary Set a value.
   * @request POST:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/
   * @secure
   * @response `201` `Value`
   */
  export namespace ProjectsParametersValuesCreate {
    export type RequestParams = {
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
    }
    export type RequestBody = ValueCreate
    export type RequestHeaders = {}
    export type ResponseBody = Value
  }
  /**
   * @description Retrieve the value of a parameter in an environment.
   * @tags projects
   * @name ProjectsParametersValuesRetrieve
   * @summary Retrieve a value.
   * @request GET:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/{id}/
   * @secure
   * @response `200` `Value`
   */
  export namespace ProjectsParametersValuesRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this value ledger.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
      /** A comma-separated list of field names to exclude from the response. */
      exclude?: string
      /** A comma-separated list of field names to include in the response. */
      include?: string
      /**
       * Mask secret values in responses with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Value
  }
  /**
   * @description Update the value of a parameter in an environment.
   * @tags projects
   * @name ProjectsParametersValuesUpdate
   * @summary Update a value.
   * @request PUT:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/{id}/
   * @secure
   * @response `200` `ValueUpdate`
   */
  export namespace ProjectsParametersValuesUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the value.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
    }
    export type RequestBody = ValueUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ValueUpdate
  }
  /**
   * @description Update the value of a parameter in an environment.
   * @tags projects
   * @name ProjectsParametersValuesPartialUpdate
   * @summary Update a value.
   * @request PATCH:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/{id}/
   * @secure
   * @response `200` `ValueUpdate`
   */
  export namespace ProjectsParametersValuesPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the value.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
    }
    export type RequestBody = PatchedValueUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ValueUpdate
  }
  /**
   * @description Destroy the value of a parameter in an environment.
   * @tags projects
   * @name ProjectsParametersValuesDestroy
   * @summary Destroy a value.
   * @request DELETE:/api/v1/projects/{project_pk}/parameters/{parameter_pk}/values/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace ProjectsParametersValuesDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the value.
       * @format uuid
       */
      id: string
      /**
       * The parameter id.
       * @format uuid
       */
      parameterPk: string
      /**
       * The project id.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * When fetching a single value, if that value is dynamic and evaluate is true, uses this environment for template evaluation.  If none is specified, uses the value's environment.  Note that the value must be active in the specified environment.
       * No effect on values that are not dynamic.
       */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter.  If `false`, returns the value's template.
       * No effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersRetrieve
   * @request GET:/api/v1/projects/{project_pk}/parameters/{id}/
   * @secure
   * @response `200` `Parameter`
   */
  export namespace ProjectsParametersRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter ledger.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
       * Has no effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
      /**
       * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
       * @default false
       */
      immediate_parameters?: boolean
      /**
       * If true, replaces all secrets with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
      /**
       * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
       * @default true
       */
      values?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Parameter
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersUpdate
   * @request PUT:/api/v1/projects/{project_pk}/parameters/{id}/
   * @secure
   * @response `200` `Parameter`
   * @response `400` `void` While checking pre-conditions, an external value was encountered that could not be resolved.
   * @response `404` `void` The given project id could not be found, or while checking pre-conditions, an external value was encountered that could not be resolved.
   * @response `415` `void` While checking pre-conditions, an external value was encountered that has an invalid content type.
   * @response `422` `void` A pre-condition to modifying the `secret` setting of the parameter failed, for example setting `secret: false` and having an external value that resolves to a value that is a secret.  In this case it would be unsafe to allow the `secret` setting to change.
   * @response `507` `void` While checking pre-conditions, an external value was encountered that was too large to process.
   */
  export namespace ProjectsParametersUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Parameter
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersPartialUpdate
   * @request PATCH:/api/v1/projects/{project_pk}/parameters/{id}/
   * @secure
   * @response `200` `Parameter`
   * @response `400` `void` While checking pre-conditions, an external value was encountered that could not be resolved.
   * @response `404` `void` The given project id could not be found, or while checking pre-conditions, an external value was encountered that could not be resolved.
   * @response `415` `void` While checking pre-conditions, an external value was encountered that has an invalid content type.
   * @response `422` `void` A pre-condition to modifying the `secret` setting of the parameter failed, for example setting `secret: false` and having an external value that resolves to a value that is a secret.  In this case it would be unsafe to allow the `secret` setting to change.
   * @response `507` `void` While checking pre-conditions, an external value was encountered that was too large to process.
   */
  export namespace ProjectsParametersPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedParameterUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Parameter
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersDestroy
   * @request DELETE:/api/v1/projects/{project_pk}/parameters/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace ProjectsParametersDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsParametersCopyCreate
   * @request POST:/api/v1/projects/{project_pk}/parameters/{id}/copy/
   * @secure
   * @response `201` `Parameter` Parameter copied
   * @response `400` `void` The parameter copy failed.
   */
  export namespace ProjectsParametersCopyCreate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /**
       * The project that the parameter is within.
       * @format uuid
       */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterCopy
    export type RequestHeaders = {}
    export type ResponseBody = Parameter
  }
  /**
   * @description Summary information about how a parameter has changed over time. The time range of historical information available depends on your subscription. Any changes to the parameter itself, including rules and values, is included.
   * @tags projects
   * @name ProjectsParametersTimelineRetrieve
   * @request GET:/api/v1/projects/{project_pk}/parameters/{id}/timeline/
   * @secure
   * @response `200` `ParameterTimeline`
   */
  export namespace ProjectsParametersTimelineRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter ledger.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTimeline
  }
  /**
   * @description Retrieve parameters at dual timepoints for comparison. Results are aligned by parameter name.  This means if a parameter is created, then deleted, then created again with the same name the two records with different parameter IDs will show up in the same result entry. If t1 is not specified then it will point to a time in the past where nothing existed.  If t2 is not specified then it is assumed to be "now".
   * @tags projects
   * @name ProjectsParametersDualityList
   * @request GET:/api/v1/projects/{project_pk}/parameters/duality/
   * @secure
   * @response `200` `PaginatedParameterDualityList`
   */
  export namespace ProjectsParametersDualityList {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /** Compare the values at `t1` and `t2` and if they are the same, omit the parameter from the result. */
      difference?: boolean
      /** Name or id (uuid) of the environment(s) to get parameter values for. Cannot be used with `values`. */
      environment?: string
      /**
       * If `true`, runs template evaluation on this parameter's values.  If `false`, returns the value's template.
       * Has no effect on values that are not interpolated.
       * @default true
       */
      evaluate?: boolean
      /**
       * If true, filter by current project parameters only. Otherwise, include both of the inherited and current project parameters.
       * @default false
       */
      immediate_parameters?: boolean
      /**
       * If true, replaces all secrets with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      name?: string
      name__contains?: string
      name__icontains?: string
      name__iexact?: string
      name__istartswith?: string
      name__startswith?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      secret?: boolean
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `t1_tag`.
       * @format date-time
       */
      t1_as_of?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `t1_as_of`. Requires `environment`. */
      t1_tag?: string
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `t2_tag`.
       * @format date-time
       */
      t2_as_of?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `t2_as_of`. Requires `environment`. */
      t2_tag?: string
      /**
       * If false, values are not returned: the `values` array will have no entries. This speeds up retrieval if value content is not needed. Cannot be used with `environment`.
       * @default true
       */
      values?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedParameterDualityList
  }
  /**
   * @description Information about how the parameters of a project have changed over time. The time range of historical information available depends on your subscription. Any changes to the project's parameters, including rules and values, is included.
   * @tags projects
   * @name ProjectsParametersTimelinesRetrieve
   * @request GET:/api/v1/projects/{project_pk}/parameters/timelines/
   * @secure
   * @response `200` `ParameterTimeline`
   */
  export namespace ProjectsParametersTimelinesRetrieve {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTimeline
  }
  /**
   * @description Endpoint for previewing a template.  Post the template content in the request body.
   * @tags projects
   * @name ProjectsTemplatePreviewCreate
   * @request POST:/api/v1/projects/{project_pk}/template-preview/
   * @secure
   * @response `200` `TemplatePreview`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatePreviewCreate {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id of the environment to use to instantiate this template. If not specified then the default environment is used. */
      environment?: string
      /**
       * Masks all secrets in the template with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
      /** ID of the template parameter being previewed.  If not specified, this is assumed to be a not-yet-created parameter. */
      template?: string
    }
    export type RequestBody = TemplatePreviewCreateRequest
    export type RequestHeaders = {}
    export type ResponseBody = TemplatePreview
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesList
   * @request GET:/api/v1/projects/{project_pk}/templates/
   * @secure
   * @response `200` `PaginatedTemplateList`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatesList {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
      environment?: string
      /**
       * If `true`, evaluates the template's body.  If `false`, returns the unevaluated template body.
       * @default false
       */
      evaluate?: boolean
      /**
       * Masks all secrets in the template with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      name?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedTemplateList
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesCreate
   * @request POST:/api/v1/projects/{project_pk}/templates/
   * @secure
   * @response `201` `Template`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatesCreate {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = TemplateCreate
    export type RequestHeaders = {}
    export type ResponseBody = Template
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesRetrieve
   * @request GET:/api/v1/projects/{project_pk}/templates/{id}/
   * @secure
   * @response `200` `Template`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatesRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter ledger.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
      environment?: string
      /**
       * If `true`, evaluates the template's body.  If `false`, returns the unevaluated template body.
       * @default true
       */
      evaluate?: boolean
      /**
       * Masks all secrets in the template with `*****`.
       * @default false
       */
      mask_secrets?: boolean
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Template
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesUpdate
   * @request PUT:/api/v1/projects/{project_pk}/templates/{id}/
   * @secure
   * @response `200` `Template`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatesUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = TemplateUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Template
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesPartialUpdate
   * @request PATCH:/api/v1/projects/{project_pk}/templates/{id}/
   * @secure
   * @response `200` `Template`
   * @response `422` `TemplateLookupError`
   */
  export namespace ProjectsTemplatesPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedTemplateUpdate
    export type RequestHeaders = {}
    export type ResponseBody = Template
  }
  /**
   * No description
   * @tags projects
   * @name ProjectsTemplatesDestroy
   * @request DELETE:/api/v1/projects/{project_pk}/templates/{id}/
   * @secure
   * @response `204` `void` Template destroyed.
   * @response `409` `void` The template is referenced by another template or value and cannot be removed.
   */
  export namespace ProjectsTemplatesDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Information about how a template has changed over time. The time range of historical information available depends on your subscription. Any changes to the template itself is included.
   * @tags projects
   * @name ProjectsTemplatesTimelineRetrieve
   * @request GET:/api/v1/projects/{project_pk}/templates/{id}/timeline/
   * @secure
   * @response `200` `TemplateTimeline`
   */
  export namespace ProjectsTemplatesTimelineRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter ledger.
       * @format uuid
       */
      id: string
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
      environment?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TemplateTimeline
  }
  /**
   * @description Information about how the templates of a project have changed over time. The time range of historical information available depends on your subscription. Any changes to the project's templates is included.
   * @tags projects
   * @name ProjectsTemplatesTimelinesRetrieve
   * @request GET:/api/v1/projects/{project_pk}/templates/timelines/
   * @secure
   * @response `200` `TemplateTimeline`
   */
  export namespace ProjectsTemplatesTimelinesRetrieve {
    export type RequestParams = {
      /** @format uuid */
      projectPk: string
    }
    export type RequestQuery = {
      /**
       * Specify a point in time to retrieve configuration from. Cannot be specified with `tag`.
       * @format date-time
       */
      as_of?: string
      /** Name or id of the environment to use to evaluate this template. If not specified then the original content is returned in the body. */
      environment?: string
      /** Specify a tag to retrieve configuration from. Cannot be specified with `as_of`. Requires `environment`. */
      tag?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TemplateTimeline
  }
  /**
   * No description
   * @tags reports
   * @name ReportsExpiredValuesRetrieve
   * @request GET:/api/v1/reports/expired-values/
   * @secure
   * @response `200` `ExpiredValuesResponse`
   */
  export namespace ReportsExpiredValuesRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpiredValuesResponse
  }
  /**
   * No description
   * @tags reports
   * @name ReportsExpiringValuesRetrieve
   * @request GET:/api/v1/reports/expiring-values/
   * @secure
   * @response `200` `ExpiringValuesResponse`
   */
  export namespace ReportsExpiringValuesRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpiringValuesResponse
  }
  /**
   * No description
   * @tags serviceaccounts
   * @name ServiceaccountsList
   * @request GET:/api/v1/serviceaccounts/
   * @secure
   * @response `200` `PaginatedServiceAccountList`
   */
  export namespace ServiceaccountsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedServiceAccountList
  }
  /**
   * @description Creates a new ServiceAccount.  A ServiceAccount is a user record intended for machine use (such as a build system).  It does not have a username/password but is instead accessed using an API key. On creation, the API key will be returned.  This key will only be shown once, is not stored on any CloudTruth system, and should be treated as a secret.  Should the key be lost, you will need to delete and recreate the ServiceAccount in order to generate a new API key.
   * @tags serviceaccounts
   * @name ServiceaccountsCreate
   * @summary Create a ServiceAccount user.
   * @request POST:/api/v1/serviceaccounts/
   * @secure
   * @response `201` `ServiceAccountCreateResponse`
   */
  export namespace ServiceaccountsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ServiceAccountCreateRequest
    export type RequestHeaders = {}
    export type ResponseBody = ServiceAccountCreateResponse
  }
  /**
   * No description
   * @tags serviceaccounts
   * @name ServiceaccountsRetrieve
   * @request GET:/api/v1/serviceaccounts/{id}/
   * @secure
   * @response `200` `ServiceAccount`
   */
  export namespace ServiceaccountsRetrieve {
    export type RequestParams = {
      /** A unique value identifying this service account. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ServiceAccount
  }
  /**
   * @description Updates a ServiceAccount.  A ServiceAccount is a user record intended for machine use (such as a build system).  It does not have a username/password but is instead accessed using an API key.
   * @tags serviceaccounts
   * @name ServiceaccountsUpdate
   * @summary Update a ServiceAccount user.
   * @request PUT:/api/v1/serviceaccounts/{id}/
   * @secure
   * @response `200` `ServiceAccountUpdateRequest`
   */
  export namespace ServiceaccountsUpdate {
    export type RequestParams = {
      /** A unique value identifying this service account. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ServiceAccountUpdateRequest
    export type RequestHeaders = {}
    export type ResponseBody = ServiceAccountUpdateRequest
  }
  /**
   * No description
   * @tags serviceaccounts
   * @name ServiceaccountsPartialUpdate
   * @request PATCH:/api/v1/serviceaccounts/{id}/
   * @secure
   * @response `200` `ServiceAccount`
   */
  export namespace ServiceaccountsPartialUpdate {
    export type RequestParams = {
      /** A unique value identifying this service account. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedServiceAccount
    export type RequestHeaders = {}
    export type ResponseBody = ServiceAccount
  }
  /**
   * No description
   * @tags serviceaccounts
   * @name ServiceaccountsDestroy
   * @request DELETE:/api/v1/serviceaccounts/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace ServiceaccountsDestroy {
    export type RequestParams = {
      /** A unique value identifying this service account. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags serviceaccounts
   * @name ServiceaccountsRekeyCreate
   * @request POST:/api/v1/serviceaccounts/{id}/rekey/
   * @secure
   * @response `200` `ServiceAccountRekeyRequest`
   */
  export namespace ServiceaccountsRekeyCreate {
    export type RequestParams = {
      /** A unique value identifying this service account. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ServiceAccountRekeyRequest
    export type RequestHeaders = {}
    export type ResponseBody = ServiceAccountRekeyRequest
  }
  /**
   * No description
   * @tags types
   * @name TypesList
   * @request GET:/api/v1/types/
   * @secure
   * @response `200` `PaginatedParameterTypeList`
   */
  export namespace TypesList {
    export type RequestParams = {}
    export type RequestQuery = {
      description__icontains?: string
      name__icontains?: string
      name__iexact?: string
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedParameterTypeList
  }
  /**
   * No description
   * @tags types
   * @name TypesCreate
   * @request POST:/api/v1/types/
   * @secure
   * @response `201` `ParameterType`
   */
  export namespace TypesCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ParameterTypeCreate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterType
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesList
   * @request GET:/api/v1/types/{parametertype_pk}/rules/
   * @secure
   * @response `200` `PaginatedParameterTypeRuleList`
   */
  export namespace TypesRulesList {
    export type RequestParams = {
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      type?: 'max' | 'max_len' | 'min' | 'min_len' | 'one_of' | 'regex'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedParameterTypeRuleList
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesCreate
   * @request POST:/api/v1/types/{parametertype_pk}/rules/
   * @secure
   * @response `201` `ParameterTypeRule`
   */
  export namespace TypesRulesCreate {
    export type RequestParams = {
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterTypeRuleCreate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeRule
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesRetrieve
   * @request GET:/api/v1/types/{parametertype_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterTypeRule`
   */
  export namespace TypesRulesRetrieve {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter type rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeRule
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesUpdate
   * @request PUT:/api/v1/types/{parametertype_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterTypeRuleUpdate`
   */
  export namespace TypesRulesUpdate {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter type rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterTypeRuleUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeRuleUpdate
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesPartialUpdate
   * @request PATCH:/api/v1/types/{parametertype_pk}/rules/{id}/
   * @secure
   * @response `200` `ParameterTypeRuleUpdate`
   */
  export namespace TypesRulesPartialUpdate {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter type rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedParameterTypeRuleUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeRuleUpdate
  }
  /**
   * No description
   * @tags types
   * @name TypesRulesDestroy
   * @request DELETE:/api/v1/types/{parametertype_pk}/rules/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace TypesRulesDestroy {
    export type RequestParams = {
      /**
       * A UUID string identifying this parameter type rule.
       * @format uuid
       */
      id: string
      /**
       * The parameter type id.
       * @format uuid
       */
      parametertypePk: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags types
   * @name TypesRetrieve
   * @request GET:/api/v1/types/{id}/
   * @secure
   * @response `200` `ParameterType`
   */
  export namespace TypesRetrieve {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter type.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ParameterType
  }
  /**
   * No description
   * @tags types
   * @name TypesUpdate
   * @request PUT:/api/v1/types/{id}/
   * @secure
   * @response `200` `ParameterTypeUpdate`
   */
  export namespace TypesUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter type.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = ParameterTypeUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeUpdate
  }
  /**
   * No description
   * @tags types
   * @name TypesPartialUpdate
   * @request PATCH:/api/v1/types/{id}/
   * @secure
   * @response `200` `ParameterTypeUpdate`
   */
  export namespace TypesPartialUpdate {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter type.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedParameterTypeUpdate
    export type RequestHeaders = {}
    export type ResponseBody = ParameterTypeUpdate
  }
  /**
   * No description
   * @tags types
   * @name TypesDestroy
   * @request DELETE:/api/v1/types/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace TypesDestroy {
    export type RequestParams = {
      /**
       * A unique identifier for the parameter type.
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * No description
   * @tags users
   * @name UsersList
   * @request GET:/api/v1/users/
   * @secure
   * @response `200` `PaginatedUserList`
   */
  export namespace UsersList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
      type?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedUserList
  }
  /**
   * No description
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/v1/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersRetrieve {
    export type RequestParams = {
      /** The unique identifier of a user. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = User
  }
  /**
   * No description
   * @tags users
   * @name UsersUpdate
   * @request PUT:/api/v1/users/{id}/
   * @secure
   * @response `200` `UpdateUser`
   */
  export namespace UsersUpdate {
    export type RequestParams = {
      /** The unique identifier of a user. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateUser
    export type RequestHeaders = {}
    export type ResponseBody = UpdateUser
  }
  /**
   * No description
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/api/v1/users/{id}/
   * @secure
   * @response `200` `UpdateUser`
   */
  export namespace UsersPartialUpdate {
    export type RequestParams = {
      /** The unique identifier of a user. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedUpdateUser
    export type RequestHeaders = {}
    export type ResponseBody = UpdateUser
  }
  /**
   * @description ### Description ### Delete the specified user.  This removes all access the User may have to any Organization. ### Pre-Conditions ### - The user cannot be the only owner of any Organization. - The bearer token must belong to the user being deleted. - All of the memberships related to the User will be deleted, so all the membership deletion pre-conditions must also be met.
   * @tags users
   * @name UsersDestroy
   * @summary Delete the specified user.
   * @request DELETE:/api/v1/users/{id}/
   * @secure
   * @response `204` `void` User deleted. The client should behave as if the user logged out.
   * @response `400` `void` Bad Request - Is the user the only owner of any organization? - Were all required fields provided?
   * @response `403` `void` Forbidden - Did the Bearer token belong to the User being deleted?
   */
  export namespace UsersDestroy {
    export type RequestParams = {
      /** The unique identifier of a user. */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Get user information about the current user.
   * @tags users
   * @name UsersCurrentRetrieve
   * @summary Current user information
   * @request GET:/api/v1/users/current/
   * @secure
   * @response `200` `User` User information
   */
  export namespace UsersCurrentRetrieve {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = User
  }
  /**
   * @description Endpoint for accessing utility functions
   * @tags utils
   * @name UtilsGeneratePasswordCreate
   * @summary Get a randomly generated password using AWS Secrets Manager, with fallback to /dev/urandom.
   * @request POST:/api/v1/utils/generate_password/
   * @secure
   * @response `201` `GeneratedPasswordResponse`
   */
  export namespace UtilsGeneratePasswordCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** The length of the password to generate.  Minimum of 8, maximum of 4095. */
      length: number
      /**
       * Default behavior is to fallback to /dev/urandom if we fail to get a random password from AWS Secrets Manager.  If set to 'True', we will not fallback to local password generation using /dev/urandom.  Default: False
       * @default false
       */
      require_hardware_generation?: boolean
      /**
       * The password must include lowercase letters [a-z]. Default: True.
       * @default true
       */
      require_lowercase?: boolean
      /**
       * The password must include numbers [0-9].  Default: True.
       * @default true
       */
      require_numbers?: boolean
      /**
       * The password must include spaces [ ].  Default: False.
       * @default false
       */
      require_spaces?: boolean
      /**
       * The password must include symbols [!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].  Default: False.
       * @default false
       */
      require_symbols?: boolean
      /**
       * The password must include uppercase letters [A-Z].  Default: True.
       * @default true
       */
      require_uppercase?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GeneratedPasswordResponse
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksList
   * @request GET:/api/v1/webhooks/
   * @secure
   * @response `200` `PaginatedWebHooksList`
   */
  export namespace WebhooksList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Which field to use when ordering the results. */
      ordering?: string
      /** A page number within the paginated result set. */
      page?: number
      /** Number of results to return per page. */
      page_size?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaginatedWebHooksList
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksCreate
   * @request POST:/api/v1/webhooks/
   * @secure
   * @response `201` `WebHooks`
   */
  export namespace WebhooksCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = WebHooks
    export type RequestHeaders = {}
    export type ResponseBody = WebHooks
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksRetrieve
   * @request GET:/api/v1/webhooks/{id}/
   * @secure
   * @response `200` `WebHooks`
   */
  export namespace WebhooksRetrieve {
    export type RequestParams = {
      /**
       * Primary key for webhook
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WebHooks
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksUpdate
   * @request PUT:/api/v1/webhooks/{id}/
   * @secure
   * @response `200` `WebHooks`
   */
  export namespace WebhooksUpdate {
    export type RequestParams = {
      /**
       * Primary key for webhook
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = WebHooks
    export type RequestHeaders = {}
    export type ResponseBody = WebHooks
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksPartialUpdate
   * @request PATCH:/api/v1/webhooks/{id}/
   * @secure
   * @response `200` `WebHooks`
   */
  export namespace WebhooksPartialUpdate {
    export type RequestParams = {
      /**
       * Primary key for webhook
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = PatchedWebHooks
    export type RequestHeaders = {}
    export type ResponseBody = WebHooks
  }
  /**
   * No description
   * @tags webhooks
   * @name WebhooksDestroy
   * @request DELETE:/api/v1/webhooks/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  export namespace WebhooksDestroy {
    export type RequestParams = {
      /**
       * Primary key for webhook
       * @format uuid
       */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
}

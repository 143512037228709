import { ActionCard, CenterLink, Flow, MarginCard, handleFlowError, ory } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { OryQueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useState } from 'react'
import { RecoveryFlow, UpdateRecoveryFlowBody } from '@ory/kratos-client'

import { AxiosError } from 'axios'
import { CardTitle } from '@ory/themes'
import styles from './Recovery.module.scss'
import { useToast } from 'hooks'

export const Recovery = () => {
  const [flow, setFlow] = useState<RecoveryFlow>()
  const [loading, setLoading] = useState(true)
  const { errorToast } = useToast()
  const router = useNavigate()
  const { getOryQuery } = useQuery()

  const returnTo = getOryQuery(OryQueryParamName.ReturnTo)
  const flowId = getOryQuery(OryQueryParamName.Flow)
  // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
  // of a user.
  // const refresh = getOryQuery(OryQueryParamName.Refresh)
  // // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
  // // to perform two-factor authentication/verification.
  // const aal = getOryQuery(OryQueryParamName.Aal)

  useEffect(() => {
    const done = () => setLoading(false)
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      done()
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getRecoveryFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch(handleFlowError(router, 'recovery', setFlow, errorToast))
        .finally(done)
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserRecoveryFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(router, 'recovery', setFlow, errorToast))
      .catch((err: AxiosError) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data)
          return
        }

        return Promise.reject(err)
      })
      .finally(done)
  }, [flowId, router, returnTo, flow, errorToast])

  const onSubmit = (values: UpdateRecoveryFlowBody) =>
    // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
    // his data when she/he reloads the page.
    new Promise((resolve) => {
      router(`/recovery?flow=${flow?.id}`)
      resolve({})
    }).then(() =>
      ory
        .updateRecoveryFlow({ flow: String(flow?.id), updateRecoveryFlowBody: values })
        .then(({ data }) => {
          // Form submission was successful, show the message to the user!
          setFlow(data)
        })
        .catch(handleFlowError(router, 'recovery', setFlow, errorToast))
        .catch((err: AxiosError) => {
          switch (err.response?.status) {
            case 400:
              // Status code 400 implies the form validation had an error
              setFlow(err.response?.data)
              return
          }

          throw err
        })
    )

  if (loading) {
    return <div className={styles.container} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.recoveryContainer}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} />
          </div>
          <h2>Forgot Your Password?</h2>
        </div>
        <MarginCard>
          <CardTitle className={styles.title}>
            Enter your email address and we will send you instructions to reset your password.
          </CardTitle>
          <Flow onSubmit={onSubmit} flow={flow} method="link" />
        </MarginCard>
        <ActionCard style={{ marginTop: '-2rem' }}>
          <Link to="/login">
            <CenterLink>Go back</CenterLink>
          </Link>
        </ActionCard>
      </div>
    </div>
  )
}

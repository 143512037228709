import { AwsAccountsList, GithubAccountsList } from 'components/Integrations'
import { OrganizationDetails, UserContainer } from 'components/OrganizationManagement'
import { Route, Routes } from 'react-router-dom'

import { ActionContainer } from 'components/Actions/ActionContainer'
import { AkvPage } from 'components/Integrations/Akv/AkvPage/AkvPage'
import { AuditLog } from 'components/AuditLog'
import { Dashboard } from 'components/Dashboard/Dashboard'
import { Environments as EnvironmentGrants } from 'components/AccessControl/Environments'
import { Environments } from 'components/Environments'
import { Expire } from 'components/Expire/Expire'
import { GroupPage } from 'components/AccessControl/Groups/GroupPage/GroupPage'
import { Groups } from 'components/AccessControl/Groups/Groups'
import { IntegrationExplorerPage } from 'components/IntegrationExplorer'
import { NotFound } from 'components/Error'
import { Projects as ProjectGrants } from 'components/AccessControl/Projects'
import { Projects } from 'components/Projects'
import React from 'react'
import { ServiceAccountList } from 'components/ServiceAccounts'
import { Types } from 'components/Types'

export enum OrganizationPaths {
  Dashboard = '/dashboard',
  Actions = '/actions/*',
  PushActions = '/actions/push',
  PullActions = '/actions/import',
  ProjectsAccessControl = '/access-control/projects',
  EnvironmentsAccessControl = '/access-control/environments',
  Groups = '/access-control/groups',
  Expiration = '/expiration',
  GroupPage = '/access-control/groups/:groupId',
  Environments = '/environments',
  UserDirectory = '/user-management',
  AuditLog = '/audit-log',
  Projects = '/projects',
  Api = '/api',
  Settings = '/settings',
  Explorer = '/integrations/explorer',
  Aws = '/integrations/aws',
  Github = '/integrations/github',
  Akv = '/integrations/akv',
  Types = '/types',
  Assistant = '/assistant',
  Catch = '/*',
}

export const orgLink = (path: OrganizationPaths): string => {
  return `/organization${path}`
}

export const templateByIdLink = (projectId: string, templateId: string): string => {
  return `/projects/${projectId}/templates?template=${templateId}`
}

export type OrganizationParams = {
  actionId?: string
  integrationId?: string
  groupId?: string
}

const organizationRoutes = [
  { path: OrganizationPaths.Dashboard, element: <Dashboard /> },
  { path: OrganizationPaths.Actions, element: <ActionContainer /> },
  { path: OrganizationPaths.EnvironmentsAccessControl, element: <EnvironmentGrants /> },
  { path: OrganizationPaths.ProjectsAccessControl, element: <ProjectGrants /> },
  { path: OrganizationPaths.Groups, element: <Groups /> },
  { path: OrganizationPaths.GroupPage, element: <GroupPage /> },
  { path: OrganizationPaths.Expiration, element: <Expire /> },
  { path: OrganizationPaths.Api, element: <ServiceAccountList /> },
  { path: OrganizationPaths.AuditLog, element: <AuditLog /> },
  { path: OrganizationPaths.Aws, element: <AwsAccountsList /> },
  { path: OrganizationPaths.Environments, element: <Environments /> },
  { path: OrganizationPaths.Explorer, element: <IntegrationExplorerPage /> },
  { path: OrganizationPaths.Github, element: <GithubAccountsList /> },
  { path: OrganizationPaths.Akv, element: <AkvPage /> },
  { path: OrganizationPaths.Projects, element: <Projects /> },
  { path: OrganizationPaths.Settings, element: <OrganizationDetails /> },
  { path: OrganizationPaths.UserDirectory, element: <UserContainer /> },
  { path: OrganizationPaths.Types, element: <Types /> },
  { path: OrganizationPaths.Catch, element: <NotFound /> },
]

export function OrganizationRoutes(): JSX.Element {
  return (
    <Routes>
      {organizationRoutes.map((routeProps, key) => (
        <Route {...routeProps} key={key} />
      ))}
    </Routes>
  )
}

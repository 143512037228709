import { Checkbox, CheckboxProps } from 'antd'

import { Item } from './Form'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import styles from './CheckboxItem.module.scss'

interface Props {
  checkboxProps?: CheckboxProps
  tooltipText?: string
  disabled?: boolean
  name: string
  text: string
  style?: React.CSSProperties
}

export function CheckboxItem(props: Props) {
  const { name, text, checkboxProps, tooltipText, disabled, style } = props

  return (
    <div className={styles.container} style={style}>
      <Item name={name} valuePropName={disabled ? undefined : 'checked'}>
        <Checkbox {...checkboxProps} disabled={disabled} checked={false}>
          <div className={styles.tooltipContainer}>
            {text}
            {tooltipText && <QuestionIconTooltip title={tooltipText} centerSpan />}
          </div>
        </Checkbox>
      </Item>
    </div>
  )
}

import React from 'react'
import styles from './EnvironmentTableTitle.module.scss'

interface Props {
  currentEnvironment: string
}

export function EnvironmentTableTitle(props: Props) {
  const { currentEnvironment } = props

  return (
    <div className={styles.tableTitleContainer}>
      <span>{`Environment: ${currentEnvironment}`}</span>
    </div>
  )
}

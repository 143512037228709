import { Form, Item, useForm } from 'components/Forms'
import { Invitation, InvitationCreate, RoleEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { Input } from 'antd'
import { LabelText } from 'components/LabelText'
import { NewInvite } from './InviteMembers'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './InviteMembersEmailForm.module.scss'
import textAreaStyles from 'components/Forms/TextArea.module.scss'
import { useAppSelector } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  setNewInvitations: (invitations: NewInvite[]) => void
  showMemberRoles: () => void
  newInvitations: NewInvite[]
}

const TEXT_AREA_ROWS = 6

export function InviteMembersEmailForm(props: Props) {
  const { newInvitations, setNewInvitations, showMemberRoles } = props
  const organization = useAppSelector(getCurrentOrganization)!
  const invitations = useAppSelector((state) => state.user.invitations)

  const pendingInvitationsMemberEmail: string[] = invitations.map((invitation: Invitation) => {
    return invitation.email
  })

  const [errorMessage, setErrorMessage] = useState<maybe<string>>(null)

  const [form] = useForm()
  const { goToOrgRoute } = useHistoryPush()

  const cancel = () => goToOrgRoute(OrganizationPaths.UserDirectory)

  const splitEmailsByComma = (emails: string) => {
    return emails
      .split(',')
      .map((item: string) => item.trim())
      .filter((item: string) => item.length > 0)
  }

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  function checkErrors(emails: string[]): nullable<string> {
    for (const email of emails) {
      if (!validateEmail(email)) {
        return `"${email}" is not a valid email address.`
      } else if (pendingInvitationsMemberEmail.includes(email)) {
        return `Invitation for "${email}" already exists.`
      } else if (email.includes(' ')) {
        return 'Use commas to seperate email addresses.'
      }
    }

    return null
  }

  const saveInvitations = () => {
    const emails = Array.from(new Set(splitEmailsByComma(form.getFieldValue('email'))))

    const error = checkErrors(emails)

    if (error) {
      setErrorMessage(error)
    } else {
      const invitations: InvitationCreate[] = emails.map((email: string) => ({
        email: email,
        role: RoleEnum.CONTRIB,
      }))

      setNewInvitations(invitations)
      showMemberRoles()
    }
  }

  return (
    <div>
      <h2>Invite team members to join {organization.name}</h2>

      <p className={styles.inviteMembersDescription}>
        Add email address below for people with whom you want to share configuration data.
      </p>

      <div className={styles.labelTextContainer}>
        <LabelText label="Invite Emails" uppercase text="Separate email addresses with a comma." />
      </div>

      <Form
        form={form}
        onFinish={saveInvitations}
        initialValues={{
          email: newInvitations?.map((invitation) => invitation.email).join(', '),
        }}
      >
        <Item
          help={errorMessage}
          validateStatus={errorMessage ? 'error' : undefined}
          name="email"
          rules={[{ required: true }]}
        >
          <Input.TextArea
            autoFocus
            className={textAreaStyles.textArea}
            rows={TEXT_AREA_ROWS}
            onChange={() => {
              if (errorMessage) {
                setErrorMessage(null)
              }
            }}
          />
        </Item>

        <div className={styles.buttonContainer}>
          <div className={styles.continueButton}>
            <ActionButton type="primary" onClick={form.submit}>
              Set User Roles
            </ActionButton>
          </div>

          <div className={styles.link} onClick={cancel}>
            Cancel
          </div>
        </div>
      </Form>
    </div>
  )
}

import { CreateGrant, DeleteGrant, UpdateGrant } from 'data/grant/actions'
import { Grant, RoleEnum } from 'gen/cloudTruthRestApi'
import React, { useMemo, useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { Select } from 'antd'
import styles from './ChangeGrantRole.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  grant?: Grant
  principal: string
  scope: string
  disabled?: boolean
}

const { Option } = Select

export function ChangeGrantRole(props: Props) {
  const { grant, principal, scope, disabled } = props

  const [selectLoading, setSelectLoading] = useState(false)

  const roleEnumArray = [
    { label: <div className={disabled ? '' : styles.noAccess}>NO ACCESS</div>, value: 'no-access' },
    {
      label: 'OWNER',
      value: RoleEnum.OWNER,
    },
    {
      label: 'ADMIN',
      value: RoleEnum.ADMIN,
    },
    {
      label: 'CONTRIBUTOR',
      value: RoleEnum.CONTRIB,
    },
    {
      label: 'VIEWER',
      value: RoleEnum.VIEWER,
    },
    {
      label: 'NOSECRETSVIEWER',
      value: RoleEnum.NO_SECRETS,
    },
  ]

  const role = useMemo(() => {
    return grant?.role || 'no-access'
  }, [grant])

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const updateGrant = (value: RoleEnum) => {
    dispatch(UpdateGrant({ ...grant, role: value })).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setSelectLoading(false)
        return
      }

      successToast('Role has successfully been updated')

      setSelectLoading(false)
    })
  }

  const createGrant = (principal: string, scope: string, value: RoleEnum) => {
    setSelectLoading(true)

    dispatch(CreateGrant({ role: value, principal, scope })).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setSelectLoading(false)
        return
      }

      successToast('Role has successfully been created')
      setSelectLoading(false)
    })
  }

  const removeGrant = (grantId: string) => {
    dispatch(DeleteGrant(grantId)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setSelectLoading(false)
        return
      }

      successToast('Role has successfully been removed')

      setSelectLoading(false)
    })
  }

  const handleSelectChange = (value: RoleEnum | 'no-access') => {
    setSelectLoading(true)

    // changing a role from an existing grant
    if (grant && value !== 'no-access') {
      updateGrant(value)
      return
    }

    // at this point there's no grant made for this user, so it needs to be created
    if (value !== 'no-access') {
      createGrant(principal, scope, value)

      // there's an existing grant and the selection is "no access", so the grant needs to be removed
    } else if (grant) {
      removeGrant(grant.id)
    }
  }

  return (
    <Select
      defaultValue={role}
      onChange={handleSelectChange}
      className={styles.select}
      loading={selectLoading}
      value={role}
      disabled={!!disabled}
      data-cy="change-grant-role"
    >
      {roleEnumArray.map((item, index) => (
        <Option value={item.value} key={index} data-cy={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  )
}

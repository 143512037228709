import { CreateParameter, CreateParameterReq, CreateRule } from 'data/parameter/actions'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import { FormAlert, FormData, useForm } from 'components/Forms'
import { Parameter, ParameterRuleCreate, ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'
import React, { useCallback, useMemo, useState } from 'react'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { AddParameterForm } from './AddParameterForm'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  visibleFromProps?: boolean
  isOverride?: boolean
  parameter?: Parameter
  noRedirect?: boolean
  closeOverrideModal?: () => void
  hide?: boolean
}

export function AddParameter(props: Props) {
  const { visibleFromProps, isOverride, parameter, closeOverrideModal, hide, noRedirect } = props
  const dispatch = useAppDispatch()
  const allEnvironments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const { errorToast, successToast } = useToast()
  const { goToParameterPage } = useHistoryPush()

  const [visible, setVisible] = useState<boolean>(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [pending, setPending] = useState<boolean>(false)
  const types = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())

  const findType = (type?: string) => {
    return types.find((t) => t.name === type)
  }

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(async () => {
    closeOverrideModal ? closeOverrideModal() : setVisible(false)
  }, [closeOverrideModal])

  const [form] = useForm()
  const [length, setLength] = useState(16)
  const [requireUppercase, setRequireUppercase] = useState(true)
  const [requireLowercase, setRequireLowercase] = useState(true)
  const [requireNumbers, setRequireNumbers] = useState(true)
  const [requireSymbols, setRequireSymbols] = useState(false)
  const [requireSpaces, setRequireSpaces] = useState(false)
  const [generateChecked, setGenerateChecked] = useState(false)
  const [expirationsDays, setExpirationDays] = useState<maybe<string>>(parameter?.expires || '30')
  const [expiresChecked, setExpiresChecked] = useState(false)

  const passwordSettings = {
    action: 'generate_password',
    length,
    require_uppercase: requireUppercase,
    require_lowercase: requireLowercase,
    require_numbers: requireNumbers,
    require_symbols: requireSymbols,
    require_spaces: requireSpaces,
  }

  const afterClose = () => {
    setError(null)
    setRequireLowercase(true)
    setRequireUppercase(true)
    setLength(16)
    setRequireNumbers(true)
    setRequireSymbols(false)
    setRequireSpaces(false)
    setGenerateChecked(false)
    setExpiresChecked(false)
    form.setFieldsValue({ parameterType: '', rules: '' })
    form.resetFields()
  }

  const onFinish = (formData: FormData) => {
    const { name, description, secret, parameterType: type, rules } = formData
    const values = () => {
      const obj: any = {}
      allEnvironments.forEach((env) => {
        obj[env.url] = null
      })

      return obj
    }

    const args = {
      name,
      description,
      secret,
      type,
      values: values(),
      isOverride,
    } as CreateParameterReq

    const expirationObject = {
      ...args,
      expires: expirationsDays && expiresChecked ? `${expirationsDays} 00:00:00` : null,
      expiration_action: generateChecked ? passwordSettings : undefined,
    }

    setPending(true)
    dispatch(CreateParameter(expirationObject)).then(
      ({ error, payload }: TypedThunk<Parameter>) => {
        if (error) {
          setPending(false)
          setError(error.message)
        }
        error
          ? errorToast(error.message)
          : isOverride
          ? successToast('Parameter successfully overridden')
          : successToast('Parameter successfully created')

        setPending(false)
        setVisible(false)
        closeModal()

        if (!error) {
          if (findType(payload?.type)?.parent_name !== 'enum') {
            if (payload.type === 'enum') {
              dispatch(
                CreateRule({
                  paramId: payload.id,
                  type: ParameterRuleTypeEnum.OneOf,
                  constraints: rules.map((value: any) => value['key']),
                })
              ).then(({ error }: CustomThunk) => {
                if (error) {
                  errorToast(error.message)
                }
              })
            } else {
              rules.forEach((rule: ParameterRuleCreate) => {
                dispatch(CreateRule({ paramId: payload.id, ...rule })).then(
                  ({ error }: CustomThunk) => {
                    if (error) {
                      errorToast(error.message)
                    }
                  }
                )
              })
            }
          }

          if (!noRedirect) {
            goToParameterPage(payload.id)
          }
        }
      }
    )
  }

  return (
    <>
      {!isOverride && !hide && (
        <ActionButton type="primary" onClick={() => setVisible(true)} customType="add" size="large">
          Add Parameter or Secret
        </ActionButton>
      )}

      <AddModal
        visible={modalVisible}
        objectName="Parameter"
        afterClose={afterClose}
        onCancel={() => closeModal()}
        onOk={form.submit}
        pending={pending}
        isOverride={isOverride}
      >
        {error && <FormAlert description={error} message="Parameter could not be created." />}
        <AddParameterForm
          setLength={setLength}
          expiresChecked={expiresChecked}
          setExpiresChecked={setExpiresChecked}
          generateChecked={generateChecked}
          setGenerateChecked={setGenerateChecked}
          setRequireUppercase={setRequireUppercase}
          setRequireLowercase={setRequireLowercase}
          setRequireNumbers={setRequireNumbers}
          setRequireSymbols={setRequireSymbols}
          setRequireSpaces={setRequireSpaces}
          setExpirationDays={setExpirationDays}
          isOverride={isOverride}
          parameter={parameter}
          form={form}
          onFinish={onFinish}
          passwordSettings={passwordSettings}
          error={error}
        />
      </AddModal>
    </>
  )
}

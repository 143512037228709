import { Alert, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { CreateInvitation } from 'data/user/actions'
import { CustomThunk } from 'data/dataUtils'
import { LabelText } from 'components/LabelText'
import { NewInvite } from './InviteMembers'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { PageLoading } from 'components/PageLoading'
import { RoleEnum } from 'gen/cloudTruthRestApi'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getRole } from 'data/membership/selectors'
import styles from './SetMemberRoles.module.scss'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  invitations: NewInvite[]
  setInvitations: (invitations: NewInvite[]) => void
  showEmailForm: () => void
}

interface RejectedInvite {
  invite: NewInvite
  error: Error
}

const REMOVE = 'REMOVE'

interface MemberRoleSelectProps {
  index: number
  defaultValue: RoleEnum | string
}

const { Option } = Select

export function SetMemberRoles(props: Props) {
  const { invitations, setInvitations, showEmailForm } = props
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { goToOrgRoute } = useHistoryPush()
  const currentUserRole = useAppSelector(getRole(null))
  const organization = useAppSelector(getCurrentOrganization)!
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (invitations.length < 1) {
      showEmailForm()
    }
  }, [invitations, showEmailForm])

  const goToUserDirectory = () => goToOrgRoute(OrganizationPaths.UserDirectory)

  const handleSelectChange = (memberRole: RoleEnum | string, index: number) => {
    if (memberRole === REMOVE) {
      const filteredInvitationList = invitations.filter((_, idx) => idx !== index)

      setInvitations(filteredInvitationList)
      return
    }

    const newInviteList = invitations
    newInviteList[index].role = memberRole as RoleEnum

    setInvitations(newInviteList)
  }

  const createInvitations = () => {
    setLoading(true)
    const rejectedArray: RejectedInvite[] = []

    const dispatchInvitations = async (): Promise<void> => {
      for (let i = 0; i < invitations.length; i++) {
        await dispatch(CreateInvitation(invitations[i])).then(({ error }: CustomThunk) => {
          if (error) {
            rejectedArray.push({ error, invite: invitations[i] })
          }
        })
      }
    }

    dispatchInvitations().then(() => {
      const populateError = () => {
        setInvitations(
          invitations.filter((invitation) =>
            rejectedArray.find((rejection) => rejection.invite.email === invitation.email)
          )
        )
        setError(true)
        setLoading(false)
      }

      rejectedArray.length < 1 ? goToUserDirectory() : populateError()
    })
  }

  const MemberRoleSelect = ({ defaultValue, index }: MemberRoleSelectProps) => {
    const { OWNER, ADMIN, CONTRIB, VIEWER } = RoleEnum

    return (
      <Select
        defaultValue={defaultValue}
        onChange={(role) => handleSelectChange(role, index)}
        className={styles.select}
        data-cy="member-role-select"
      >
        <Option disabled={currentUserRole !== OWNER} value={OWNER} data-cy="option-owner">
          {OWNER}
        </Option>
        <Option value={ADMIN} data-cy="option-admin">
          {ADMIN}
        </Option>
        <Option value={CONTRIB} data-cy="option-contributor">
          CONTRIBUTOR
        </Option>
        <Option value={VIEWER} data-cy="option-viewer">
          {VIEWER}
        </Option>
        <Option value={REMOVE} className={styles.remove} data-cy="option-remove">
          {REMOVE}
        </Option>
      </Select>
    )
  }

  if (loading) {
    return <PageLoading />
  }

  return (
    <div>
      <h2>Invite team members to join {organization.name}</h2>

      <p className={styles.setMemberRolesDescription}>
        Set user roles for the following email invitations. After an organization is created, roles
        can be changed by admins and owners. Admins can invite other users and have read/write
        permissions. Contributors have read/write permissions. Viewers have read access.
        NoSecretViewers have read access but cannot unmask secrets.
      </p>

      <div>
        {error && (
          <Alert
            showIcon
            className={styles.errorAlert}
            message={`${
              invitations.length > 1 ? 'These invitations' : 'This invitation'
            } could not be sent. Return to the email list to remove or correct the email addresses.`}
            type="error"
          />
        )}
        <div className={styles.memberRolesContainer}>
          <div>
            <LabelText
              label="emails"
              isHorizontal
              uppercase
              text={
                <div className={styles.returnToEmailList} onClick={showEmailForm}>
                  Return to email list
                </div>
              }
            />

            <div className={styles.emails}>
              {invitations?.map((item, index) => (
                <div className={styles.listItem} key={index}>
                  <span>{item.email}</span>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className={styles.roleLabel}>
              <LabelText label="roles" text={null} uppercase />
            </div>

            <div className={styles.roles}>
              {invitations?.map((item, index) => (
                <div className={styles.listItem} key={index}>
                  <MemberRoleSelect defaultValue={item.role} index={index} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <div className={styles.continueButton}>
            <ActionButton type="primary" onClick={createInvitations}>
              Set User Roles
            </ActionButton>
          </div>

          <div className={styles.link} onClick={goToUserDirectory}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}

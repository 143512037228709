import { Form, FormData, InlineLabelInput, LabeledItem, TextArea } from 'components/Forms'
import React, { useEffect } from 'react'

import { FormInstance } from 'antd'
import { User } from 'gen/cloudTruthRestApi'
import { UserSelector } from '../UserSelector'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
  userValues: User['url'][]
  setUserValues: (values: User['url'][]) => void
}

export function AddGroupForm(props: Props) {
  const { form, onFinish, userValues, setUserValues } = props

  useEffect(() => {
    return () => setUserValues([])
  }, [setUserValues])

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <InlineLabelInput autoFocus name="name" label="Name" required="Please enter a name." />
        <TextArea name="description" label="Description" />
        <LabeledItem label="Users">
          <UserSelector values={userValues} setValues={setUserValues} />
        </LabeledItem>
      </Form>
    </>
  )
}

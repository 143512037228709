//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
// import { Form, InlineLabelInput, Item, LabeledInputItem, useForm } from './Forms'
import { Checkbox, FormInstance, Input } from 'antd'
import { Form, Item, LabeledInputItem, useForm } from 'components/Forms'
import { GeneratePassword, GeneratedPasswordResponse } from 'data/utility/actions'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import CopySecretText from 'components/misc/CopySecretText'
import { Rule } from 'rc-field-form/lib/interface'
import { TypedThunk } from 'data/dataUtils'
import styles from './ParameterPasswordGenerator.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

const validateLength = (_rule: Rule, value: string): Promise<void> => {
  value = value.toString()
  const length = parseInt(value)
  if (value.match(/^\d+$/) && 8 <= length && length <= 4095) {
    return Promise.resolve()
  } else {
    return Promise.reject('Length must be an integer between 8 and 4095')
  }
}

const validateOptions = (
  form: FormInstance,
  generate: () => void,
  error: (m: string) => void
): void => {
  const a = form.getFieldsValue()
  if (!a.require_numbers && !a.require_lowercase && !a.require_uppercase && !a.require_symbols) {
    return error(
      'At least one of the following must be selected to generate a password: lowercase, uppercase, numbers, symbols'
    )
  } else {
    generate()
  }
}

interface Props {
  setLength: (length: number) => void
  setRequireUppercase: (requireUppercase: boolean) => void
  setRequireLowercase: (requireLowercase: boolean) => void
  setRequireNumbers: (requireNumbers: boolean) => void
  setRequireSymbols: (requireSymbols: boolean) => void
  setRequireSpaces: (requireSpaces: boolean) => void
  passwordSettings: {
    length: number
    require_uppercase: boolean
    require_lowercase: boolean
    require_numbers: boolean
    require_symbols: boolean
    require_spaces: boolean
  }
}

export default function ParameterPasswordGenerator(props: Props) {
  const {
    setLength,
    setRequireLowercase,
    setRequireUppercase,
    setRequireNumbers,
    setRequireSymbols,
    setRequireSpaces,

    passwordSettings,
  } = props

  const [password, setPassword] = useState<nullable<string>>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const [form] = useForm()

  const handleGenerate = () => {
    setLoading(true)
    const args = form.getFieldsValue()

    dispatch(GeneratePassword(args)).then(
      ({ error, payload }: TypedThunk<GeneratedPasswordResponse>) => {
        error ? errorToast(error.message) : setPassword(payload.value)
        setLoading(false)
      }
    )
  }

  const labelInputStyle = { display: 'flex', height: '20px', flexDirection: 'row' }
  const checkboxStyle = { display: 'flex', marginLeft: '10px', marginTop: '-9px' }

  return (
    <>
      <Form
        form={form}
        onFinish={() => validateOptions(form, handleGenerate, errorToast)}
        // initialValues={initialValues}
        initialValues={{
          length: passwordSettings.length || '16',
          require_lowercase: passwordSettings.require_lowercase || true,
          require_numbers: passwordSettings.require_numbers || true,
          require_spaces: passwordSettings.require_spaces || false,
          require_symbols: passwordSettings.require_symbols || false,
          require_uppercase: passwordSettings.require_uppercase || true,
          require_hardware_generation: false,
        }}
      >
        {password && <CopySecretText label="password" value={password} />}
        <div>
          <Item>
            <ActionButton
              disabled={loading}
              type={password ? 'default' : 'primary'}
              onClick={form.submit}
              className={password ? styles.passwordButton : styles.button}
            >
              {`${password ? 'Regenerate' : 'Generate'} Password`}
            </ActionButton>
            {password && (
              <ActionButton
                type={password ? 'primary' : 'default'}
                onClick={() => setPassword(null)}
              >
                Clear
              </ActionButton>
            )}
          </Item>
        </div>
        <LabeledInputItem
          required
          name="length"
          label={{ text: 'Length' }}
          rules={[{ validator: validateLength }]}
        >
          <Input onChange={(e) => setLength(Number(e.target.value))} />
        </LabeledInputItem>

        <LabeledInputItem
          required
          name="require_lowercase"
          label={{ text: 'Require Lowercase' }}
          style={labelInputStyle}
        >
          <Checkbox
            style={checkboxStyle}
            checked={passwordSettings.require_lowercase}
            onChange={(e) => {
              setRequireLowercase(e.target.checked),
                form.setFieldValue('require_lowercase', e.target.checked)
            }}
          />
        </LabeledInputItem>

        <LabeledInputItem
          required
          name="require_uppercase"
          label={{ text: 'Require Uppercase' }}
          style={labelInputStyle}
        >
          <Checkbox
            style={checkboxStyle}
            checked={passwordSettings.require_uppercase}
            onChange={(e) => {
              setRequireUppercase(e.target.checked),
                form.setFieldValue('require_uppercase', e.target.checked)
            }}
          />
        </LabeledInputItem>

        <LabeledInputItem
          required
          name="require_numbers"
          label={{ text: 'Require Numbers' }}
          style={labelInputStyle}
        >
          <Checkbox
            style={checkboxStyle}
            checked={passwordSettings.require_numbers}
            onChange={(e) => {
              setRequireNumbers(e.target.checked),
                form.setFieldValue('require_numbers', e.target.checked)
            }}
          />
        </LabeledInputItem>

        <LabeledInputItem
          required
          name="require_symbols"
          label={{
            text: 'Require Symbols',
            tooltipText: 'Symbols include !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
          }}
          style={labelInputStyle}
        >
          <Checkbox
            style={checkboxStyle}
            checked={passwordSettings.require_symbols}
            onChange={(e) => {
              setRequireSymbols(e.target.checked),
                form.setFieldValue('require_symbols', e.target.checked)
            }}
          />
        </LabeledInputItem>

        <LabeledInputItem
          required
          name="require_spaces"
          label={{ text: 'Require Spaces' }}
          style={labelInputStyle}
        >
          <Checkbox
            style={checkboxStyle}
            checked={passwordSettings.require_spaces}
            onChange={(e) => {
              setRequireSpaces(e.target.checked),
                form.setFieldValue('require_spaces', e.target.checked)
            }}
          />
        </LabeledInputItem>
      </Form>
    </>
  )
}

export const exportsForTesting = { validateLength, validateOptions }

import { Tooltip, TooltipProps } from 'antd'

import { QuestionIcon } from 'components/Forms/QuestionIcon'
import React from 'react'

interface Props {
  title: string | JSX.Element
  placement?: TooltipProps['placement']
  centerSpan?: boolean
  styles?: React.CSSProperties
  iconStyles?: React.CSSProperties
}

export function QuestionIconTooltip(props: Props) {
  const { title, placement, centerSpan, styles, iconStyles } = props

  return (
    <Tooltip placement={placement} color="#434343" title={title} style={styles} data-cy="tooltip">
      <span
        style={{
          display: centerSpan ? 'flex' : undefined,
          alignItems: centerSpan ? 'center' : undefined,
        }}
        data-cy="question-icon"
      >
        <QuestionIcon style={iconStyles} />
      </span>
    </Tooltip>
  )
}

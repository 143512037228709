//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { Group, User } from 'gen/cloudTruthRestApi'
import { QueryParamName, useHistoryPush, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, TableSearch } from 'components/Table'
import { TableProps, Tooltip } from 'antd'
import { displayDate, formatDateTime } from 'lib/dateHelpers'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { groupEntitySelectors, useSelectGroups } from 'data/group/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { AddGroup } from './AddGroup'
import { GetGroups } from 'data/group/actions'
import { GroupDetailsDropdown } from '../Groups/GroupPage/GroupDetailsDropdown'
import { NoRbacEnabled } from '../NoRbacEnabled'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './Groups.module.scss'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'
import { valueOrEmDash } from 'lib/valueHelpers'

type ColumnsType = TableProps<Group>['columns']

const emptyGroupsMessage = (error: boolean): string => {
  return error
    ? 'Unable to retrieve groups from the server at this time.'
    : 'You do not have any groups stored in this organization.'
}

export function Groups() {
  const [fetchError, setFetchError] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const groups = groupEntitySelectors.selectAll(useSelectGroups())
  const userEntities = userEntitySelectors.selectEntities(useSelectUsers())

  const { canAdministrate } = useAppSelector(getPolicy(null))
  const { goToGroupPage } = useHistoryPush()
  const { errorToast } = useToast()

  const dispatch = useAppDispatch()

  const ActionButton = () => {
    return canAdministrate ? <AddGroup /> : <></>
  }

  const currentOrg = useAppSelector(getCurrentOrganization)!

  const localSession = getLocalSession({ org: currentOrg.id, pageType: 'groups' })
  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const [form] = useForm()
  const [search, setSearch] = useState('')

  const filteredGroups = useCallback(() => {
    return groups.filter((group) => {
      return group.name.toLowerCase().includes(search.toLowerCase())
    })
  }, [search, groups])

  const { getQuery } = useQuery()

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrg.id,
      pageType: 'groups',
      args: { pageSize },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const formatUsers = (userUrls: User['url'][]) => {
    return userUrls
      .map((url) => {
        const userId = idFromUrl(url)

        return userEntities[userId]?.name
      })
      .join(', ')
  }

  const reloadGroups = useCallback(() => {
    setLoading(true)
    setFetchError(false)

    dispatch(GetGroups(null)).then((response: CustomThunk) => {
      const { error } = response

      if (error) {
        setFetchError(true)
        errorToast(error.message)
      }

      setLoading(false)
    })
  }, [dispatch, errorToast])

  useEffect(() => {
    reloadGroups()
  }, [reloadGroups])

  const [columns, setColumns] = useState<ColumnsType>([
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      width: storedColumnSize(columnSizes, 'name', 200),
      sorter: (a, b) => {
        const firstName = a!.name.toUpperCase()
        const secondName = b!.name.toUpperCase()

        return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
      },
      render: (value, group) => (
        <p className={styles.name} onClick={() => goToGroupPage(group.id)}>
          {value}
        </p>
      ),
    },
    {
      title: 'DESCRIPTION',
      key: 'description',
      dataIndex: 'description',
      width: storedColumnSize(columnSizes, 'description', 300),

      render: (_, group: Group) => (
        <p className={styles.ellipsis}>{valueOrEmDash(group.description)}</p>
      ),
    },
    {
      title: 'USERS',
      key: 'users',
      dataIndex: 'users',
      width: storedColumnSize(columnSizes, 'users', 400),

      render: (_, group: Group) => (
        <Tooltip title={formatUsers(group.users)}>
          <div className={styles.ellipsis}>{formatUsers(group.users)}</div>
        </Tooltip>
      ),
    },
    {
      title: 'LAST EDITED',
      key: 'modified_at',
      dataIndex: 'modified_at',
      width: storedColumnSize(columnSizes, 'modified_at', 150),
      render: (_, group: Group) => {
        if (group.modified_at !== '') {
          return (
            <Tooltip title={formatDateTime(group.modified_at)}>
              {displayDate(group.modified_at)}
            </Tooltip>
          )
        }

        return <p />
      },
    },
    {
      title: '',
      width: 65,
      render: (_, group: Group) => {
        return canAdministrate ? (
          <div className={styles.dropdown}>
            <GroupDetailsDropdown group={group} />
          </div>
        ) : null
      },
    },
  ])

  if (!currentOrg?.subscription_features.includes('rbac')) {
    return <NoRbacEnabled groups />
  }

  return (
    <div>
      <PageTitle
        title="Groups"
        buttons={
          <>
            <Reload onClick={reloadGroups} loading={loading} />
            <ActionButton />
          </>
        }
        search={
          <TableSearch
            defaultValue={search || ''}
            updateSearchTerm={setSearch}
            form={form}
            onSearch={() => setPage(1)}
            placeHolder="Search Groups"
            marginRight="-15px"
          />
        }
      />

      <Table
        columns={resizableColumns(columns!, setColumns, currentOrg.id, 'groups')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        loading={loading}
        pagination={{
          total: filteredGroups().length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : filteredGroups()}
        rowClassName={styles.row}
        rowKey={(group: Group) => group.id}
        className={styles.table}
        locale={{
          emptyText: (
            <div className={styles.emptyContainer}>
              <h4 className={styles.emptyHeader}>{emptyGroupsMessage(fetchError)}</h4>
              <p className={styles.emptyParagraph}>Create a group to store and use.</p>
            </div>
          ),
        }}
      />
    </div>
  )
}

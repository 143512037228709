import { GitHubIntegration, PaginatedIntegrationNodeList, StatusEnum } from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'

import { Alert } from 'antd'
import { GetIntegrationExplorer } from 'data/integration/actions'
import { LabelText } from 'components/LabelText'
import { TypedThunk } from 'data/dataUtils'
import styles from './GithubAccountsDetails.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  githubIntegration: GitHubIntegration
}

export function GithubAccountsDetails(props: Props) {
  const { githubIntegration } = props
  const dispatch = useAppDispatch()
  const [availableRepositoryCount, setAvailableRepositoryCount] = useState<nullable<number>>(null)
  const { errorToast } = useToast()
  const { status, gh_installation_id } = githubIntegration

  useEffect(() => {
    dispatch(GetIntegrationExplorer({ fqn: githubIntegration.fqn })).then(
      ({ error, payload }: TypedThunk<PaginatedIntegrationNodeList>) => {
        error ? errorToast(error.message) : setAvailableRepositoryCount(payload.count!)
      }
    )
  }, [dispatch, errorToast, githubIntegration.fqn])

  return (
    <div className={styles.githubDetailsContainer}>
      <LabelText
        label="CloudTruth Access"
        text={
          <ul className={styles.accessContainer}>
            <li className={styles.cloudtruthAccess}>
              <span className={styles.repositoryCount}>
                {availableRepositoryCount !== null &&
                  (availableRepositoryCount === 1
                    ? '1 Repository'
                    : `${availableRepositoryCount} Repositories`)}
              </span>
              <a href={`https://github.com/settings/installations/${gh_installation_id}`}>
                View Access
              </a>
            </li>
          </ul>
        }
      />

      {status === StatusEnum.Errored && (
        <Alert
          message="We are having trouble accessing your GitHub content."
          type="error"
          showIcon
          className={styles.alert}
        />
      )}

      {status === StatusEnum.Missing && (
        <Alert
          message="Access to this GitHub account has been revoked."
          type="error"
          showIcon
          className={styles.alert}
        />
      )}
    </div>
  )
}

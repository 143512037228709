//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, TextArea } from 'components/Forms'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'

import { FormInstance } from 'antd'
import React from 'react'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
}

export function AddTemplateForm(props: Props) {
  const { form, onFinish } = props

  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const parameters = parameterEntitySelectors.selectAll(useSelectParameters())

  const nameValidator = (_: any, value: string) => {
    if (
      value &&
      templates.find((template) => template.name.toLowerCase() === value.toLowerCase())
    ) {
      return Promise.reject('Template with this name already exists')
    } else if (
      value &&
      parameters.find((parameter) => parameter.name.toLowerCase() === value.toLowerCase())
    ) {
      return Promise.reject('Parameter with this name exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <InlineLabelInput
        autoFocus
        name="name"
        label="Template Name"
        required="Please enter a template name."
        rules={[{ validator: nameValidator }]}
      />
      <TextArea name="description" label="Description" />
    </Form>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, TextArea } from 'components/Forms'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'

import { FormInstance } from 'antd'
import React from 'react'
import { Template } from 'gen/cloudTruthRestApi'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
  template: Template
}

export function UpdateTemplateDescriptionForm(props: Props) {
  const { form, onFinish, template } = props
  const { name, description } = template

  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const parameters = parameterEntitySelectors.selectAll(useSelectParameters())

  const nameValidator = (_: any, value: string) => {
    if (
      templates.find(
        (template) =>
          template.name.toLowerCase() === value.toLowerCase() &&
          // allow the same name if it's the same template
          template.name.toLowerCase() !== name.toLowerCase()
      )
    ) {
      return Promise.reject('Template with this name already exists')
    } else if (
      parameters.find((parameter) => parameter.name.toLowerCase() === value.toLowerCase())
    ) {
      return Promise.reject('Parameter with this name exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form form={form} initialValues={{ description, name }} onFinish={onFinish}>
      <InlineLabelInput
        name="name"
        label="Name"
        required="Please enter a template name."
        rules={[{ validator: nameValidator }]}
      />
      <TextArea
        autoFocus
        name="description"
        label="Description"
        placeholder="Type description here"
      />
    </Form>
  )
}

import { DependencyList, useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import { ory } from './ory'
import { useHistoryPush } from 'router/customHooks'

// Returns a function which will log the user out
export function useLogoutHandler(deps?: DependencyList) {
  const [logoutToken, setLogoutToken] = useState<string>('')
  const { goToLoginRoute } = useHistoryPush()

  useEffect(
    () => {
      ory
        .createBrowserLogoutFlow()
        .then(({ data }) => {
          setLogoutToken(data.logout_token)
        })
        .catch((err: AxiosError) => {
          switch (err.response?.status) {
            case 401:
              // do nothing, the user is not logged in
              return
          }

          // Something else happened!
          return Promise.reject(err)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? deps : []
  )

  return () => {
    if (logoutToken) {
      ory.updateLogoutFlow({ token: logoutToken }).then(goToLoginRoute)
    }
  }
}

import { Checkbox, CheckboxProps, Input, InputProps } from 'antd'
import { NamePath, Rule } from 'rc-field-form/lib/interface'

import { LabelProps } from './Label'
import { LabeledInputItem } from './LabeledInputItem'
import React from 'react'
import styles from './InlineLabelInput.module.scss'

interface Props {
  autoFocus?: boolean
  disabled?: boolean
  inputProps?: InputProps
  label: LabelProps | string
  name: NamePath
  placeholder?: string
  required?: string | boolean
  rules?: Rule[]
  width?: string | number
  checkbox?: boolean
  checkboxProps?: CheckboxProps
  password?: boolean
  tooltipText?: string
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function InlineLabelInput(props: Props) {
  const {
    autoFocus,
    checkbox,
    checkboxProps,
    disabled,
    inputProps,
    label,
    name,
    placeholder,
    required,
    rules,
    width,
    password,
    tooltipText,
    handleInputChange,
  } = props
  const { className, ...remainingInputProps } = inputProps || {}

  const inputClassName = className ? [styles.input, className].join(' ') : styles.input

  // direction change for name with/out checkbox and corresponding styles
  // because of the way the label is rendered in LabeledInputItem, it doesn't allow for a checkbox to be rendered in the same line as the label
  // so we have to change the direction of the label and input to be able to render the checkbox in the same line as the label
  // also labeledInputItem doesn't have a className prop so we have to use inline styles
  const nameWithoutCheckbox = {
    height: '75px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }

  const nameWithCheckbox = {
    height: '30px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row ',
    lineHeight: '30px',
    marginBottom: '14px',
    paddingLeft: '8px',
  }

  return (
    <div className={styles.container}>
      <LabeledInputItem
        checkbox={checkbox}
        name={name}
        label={label}
        required={required}
        rules={rules}
        tooltipText={tooltipText}
        style={checkbox ? nameWithCheckbox : nameWithoutCheckbox}
      >
        {checkbox ? (
          <Checkbox className={styles.checkbox} disabled={disabled} {...checkboxProps} />
        ) : password ? (
          <Input.Password
            autoFocus={autoFocus}
            className={inputClassName}
            disabled={disabled}
            placeholder={placeholder}
            data-testid="input"
            style={{ width }}
            {...remainingInputProps}
          />
        ) : (
          <Input
            autoFocus={autoFocus}
            className={inputClassName}
            disabled={disabled}
            placeholder={placeholder}
            data-testid="input"
            style={{ width }}
            onChange={handleInputChange}
            {...remainingInputProps}
          />
        )}
      </LabeledInputItem>
    </div>
  )
}

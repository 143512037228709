import React, { useState } from 'react'
import {
  getCurrentOrganization,
  organizationEntitySelectors,
  useSelectOrganizations,
} from 'data/organization/selectors'
import { removeOneOrganization, selectOrganization } from 'data/organization/reducer'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteMembership } from 'data/membership/actions'
import { RootPaths } from 'router/Router'
import { User } from 'gen/cloudTruthRestApi'
import { getCurrentUser } from 'data/user/selectors'
import { removeUser } from 'data/user/reducer'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  user: User
  visible: boolean
  setVisible: (bool: boolean) => void
}

export function RemoveAccess(props: Props) {
  const { user, visible, setVisible } = props
  const [loading, setLoading] = useState(false)

  const orgIds = organizationEntitySelectors.selectIds(useSelectOrganizations())
  const { name, id: orgId } = useAppSelector(getCurrentOrganization)!
  const currentUserId = useAppSelector(getCurrentUser)!.id
  const { goHome, goToRootRoute } = useHistoryPush()
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const isSelf = currentUserId === user.id

  const handleDeleteMembershipResponse = ({ error }: CustomThunk) => {
    if (error) {
      errorToast(error.message)
    } else if (isSelf) {
      dispatch(removeOneOrganization(orgId))
      const remainingOrgIds = orgIds.filter((id) => id !== orgId)

      if (remainingOrgIds.length > 1) {
        goToRootRoute(RootPaths.Switch)
      } else if (remainingOrgIds.length == 1) {
        dispatch(selectOrganization(remainingOrgIds[0] as string))
        goHome()
      } else {
        // this automatically redirects to create new organization
        dispatch(selectOrganization(null))
      }
    } else {
      dispatch(removeUser(user.id))

      successToast(`User has been removed from ${name}`)
    }
  }

  const deleteMembership = () => {
    setLoading(true)
    dispatch(DeleteMembership(user.membership_id!)).then(handleDeleteMembershipResponse)
  }

  return (
    <>
      <DeleteConfirmModal
        noConfig
        closeModal={() => setVisible(false)}
        visible={visible}
        onDelete={deleteMembership}
        subject={isSelf ? 'organization' : 'user'}
        loading={loading}
        actionWord={isSelf ? 'leave' : 'remove'}
      />
    </>
  )
}

import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CheckCircleOutlined } from '@ant-design/icons'
import { Organization } from 'gen/cloudTruthRestApi'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import React from 'react'
import { getCurrentOrganization } from 'data/organization/selectors'
import { selectOrganization } from 'data/organization/reducer'
import styles from './SelectOrganization.module.scss'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  organization: Organization
  setLoading: (bool: boolean) => void
}

export function SelectOrganization(props: Props) {
  const { organization, setLoading } = props
  const { id, name } = organization
  const { goToOrgRoute } = useHistoryPush()
  const dispatch = useAppDispatch()
  const activeOrganization = useAppSelector(getCurrentOrganization)

  const setOrganizationAndProject = () => {
    setLoading(true)
    if (id !== activeOrganization?.id) {
      new Promise((resolve) => {
        dispatch(selectOrganization(id))

        resolve({})
      }).then(() => {
        new Promise((res) => {
          setTimeout(() => {
            goToOrgRoute(OrganizationPaths.Dashboard)

            res({})
          }, 1000)
        })
      })
    }
  }

  return (
    <div className={styles.container}>
      <h2>{name}</h2>
      {id === activeOrganization?.id ? (
        <div>
          <div className={styles.disabled}>
            Selected <CheckCircleOutlined className={styles.checked} />
          </div>
        </div>
      ) : (
        <div className={styles.select} onClick={setOrganizationAndProject}>
          Select
        </div>
      )}
    </div>
  )
}

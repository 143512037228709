import React, { useState } from 'react'

import { AwsAccountsListPanelDropdown } from './AwsAccountsListPanelDropdown'
import { AwsAccountsListStatus } from './AwsAccountsListStatus'
import { AwsIntegration } from 'gen/cloudTruthRestApi'
import { getPolicy } from 'data/membership/selectors'
import styles from './AwsAccountsListPanelExtra.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  awsIntegration: AwsIntegration
  handleChecking: (checking: boolean) => void
  handleEditClick: () => void
  isChecking: boolean
}

export function AwsAccountsListPanelExtra(props: Props) {
  const { awsIntegration, handleChecking, handleEditClick, isChecking } = props
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const [closePopover, setClosePopover] = useState(false)

  return (
    <div className={styles.extraContainer} onClick={(e) => e.stopPropagation()}>
      <AwsAccountsListStatus
        awsIntegration={awsIntegration}
        isChecking={isChecking}
        closePopover={closePopover}
        setClosePopover={setClosePopover}
      />
      {canAdministrate && (
        <AwsAccountsListPanelDropdown
          awsIntegration={awsIntegration}
          setClosePopover={setClosePopover}
          handleChecking={handleChecking}
          handleEditClick={handleEditClick}
          isChecking={isChecking}
        />
      )}
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateMembership,
  DeleteMembership,
  GetMembership,
  GetMemberships,
  UpdateMembership,
} from './actions'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { epoch, idFromUrl } from 'data/dataUtils'

import { DeleteServiceAccount } from 'data/serviceAccount/actions'
import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Membership } from 'gen/cloudTruthRestApi'

interface MembershipState {
  cacheLastChanged: typeof epoch
}

export const membershipAdapter = createEntityAdapter<Membership>({
  selectId: (mem) => mem.id,
})

const initialState = membershipAdapter.getInitialState({
  cacheLastChanged: epoch,
} as MembershipState)

export type MembershipRootState = EntityState<Membership> & MembershipState

const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    // direct actions

    setAllMembership: membershipAdapter.setAll,
    addOneMembership: membershipAdapter.addOne,
    updateOneMembership: membershipAdapter.upsertOne,
    removeOneMembership: membershipAdapter.removeOne,

    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateMembership.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      membershipAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetMemberships.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      membershipAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetMembership.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      membershipAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateMembership.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      membershipAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteMembership.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      membershipAdapter.removeOne(state, action.payload)
    })
    builder.addCase(DeleteServiceAccount.fulfilled, (state, action) => {
      const membership = Object.values(state.entities).find(
        (m) => idFromUrl(m!.user) === action.payload
      )
      if (membership) {
        state.cacheLastChanged = new Date(Date.now())
        membershipAdapter.removeOne(state, membership.id)
      }
    })
  },
})

export const {
  resetState,
  removeOneMembership,
  addOneMembership,
  updateOneMembership,
  setAllMembership,
} = membershipSlice.actions

export default membershipSlice.reducer

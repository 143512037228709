import { AwsIntegration, StatusEnum } from 'gen/cloudTruthRestApi'
import {
  CheckCircleFilled,
  CloseOutlined,
  ExclamationCircleFilled,
  WarningFilled,
} from '@ant-design/icons'
import { Popover, Spin, Typography } from 'antd'
import React, { useMemo, useState } from 'react'

import { CheckAwsIntegrationStatus } from 'data/integration/awsIntegration/actions'
import { emDash } from 'lib/valueHelpers'
import { formatLocalDateTime } from 'lib/dateHelpers'
import styles from './AwsAccountsListStatus.module.scss'
import { useAppDispatch } from 'data/hooks'

interface Props {
  awsIntegration: AwsIntegration
  isChecking: boolean
  closePopover: boolean
  setClosePopover: (bool: boolean) => void
}

export function AwsAccountsListStatus(props: Props) {
  const { awsIntegration, isChecking, setClosePopover, closePopover } = props
  const { status, status_detail, status_last_checked_at } = awsIntegration
  const [checkedIntegrationOnce, setCheckedIntegrationOnce] = useState<boolean | 'checking'>(false)
  const dispatch = useAppDispatch()

  const visible = useMemo(() => {
    if (closePopover) {
      return false
    }

    return status === StatusEnum.Errored && status_detail !== null && !isChecking
  }, [status, isChecking, closePopover, status_detail])

  // when the user performs an action that begins a check, instead of
  // polling, we force a checking until that action finishes

  const derivedAccountState =
    isChecking || checkedIntegrationOnce === 'checking' ? StatusEnum.Checking : status

  const renderParts = () => {
    switch (derivedAccountState) {
      case StatusEnum.Connected: {
        return {
          statusText: <Typography.Text>Connected</Typography.Text>,
          statusIcon: <CheckCircleFilled className={styles.successIcon} />,
        }
      }

      case StatusEnum.Pending: {
        return {
          statusText: <Typography.Text>Pending Role Setup</Typography.Text>,
          statusIcon: <WarningFilled className={styles.pendingIcon} />,
        }
      }

      case StatusEnum.Checking: {
        return {
          statusText: <Typography.Text>Checking Status</Typography.Text>,
          statusIcon: <Spin size="default" />,
        }
      }

      case StatusEnum.Errored: {
        return {
          statusText: <Typography.Text>Integration Error</Typography.Text>,
          statusIcon: <ExclamationCircleFilled className={styles.failureIcon} />,
        }
      }

      default: {
        if (!checkedIntegrationOnce) {
          setCheckedIntegrationOnce('checking')
          dispatch(CheckAwsIntegrationStatus(awsIntegration)).then(() => {
            setCheckedIntegrationOnce(true)
          })
          return {
            statusText: <Typography.Text>Checking Status</Typography.Text>,
            statusIcon: <Spin size="default" />,
          }
        }
        return {
          statusText: <Typography.Text>{`Status: ${status}`}</Typography.Text>,
          statusIcon: <ExclamationCircleFilled className={styles.failureIcon} />,
        }
      }
    }
  }

  const { statusText, statusIcon } = renderParts()

  return (
    <Popover
      placement="bottom"
      overlayClassName={styles.popover}
      content={status_detail}
      title={
        <div className={styles.detailsContainer}>
          <h4 className={styles.detailsHeader}>Details</h4>
          <CloseOutlined className={styles.pointer} onClick={() => setClosePopover(true)} />
        </div>
      }
      getPopupContainer={(trigger: any) => trigger.parentElement}
      open={visible}
    >
      <div className={styles.container}>
        <div className={styles.vertical}>
          <div className={styles.status}>{statusText}</div>
          <div className={styles.timestamp}>
            {status_last_checked_at
              ? `As of ${formatLocalDateTime(status_last_checked_at)}`
              : emDash}
          </div>
        </div>
        <div className={styles.icon}>{statusIcon}</div>
      </div>
    </Popover>
  )
}

import {
  ACCOUNT_ID_FIELD,
  AwsAccountFormBody,
  EXTERNAL_ID_FIELD,
  KMS_ID_FIELD,
  ROLE_NAME_FIELD,
} from '../AwsAccountFormBody'
import {
  AwsIntegration,
  AwsIntegrationCreate,
  AwsRegionEnum,
  AwsServiceEnum,
} from 'gen/cloudTruthRestApi'
import { Form, useForm } from 'components/Forms'
import React, { useState } from 'react'
import {
  allAwsServices,
  getEnabledServices,
  initialServiceCheckboxValues,
} from '../AwsServiceCheckboxes'

import { ActionButton } from 'components/ActionButton'
import { AwsRegionsCheckboxes } from '../AwsRegionsCheckboxes'
import { CustomThunk } from 'data/dataUtils'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import { UpdateAwsIntegration } from 'data/integration/awsIntegration/actions'
import styles from './AwsAccountsListPanelContentEditing.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  awsIntegration: AwsIntegration
  onCancel: () => void
  onSubmit: () => void
  onSuccess: () => void
}

export function AwsAccountsListPanelContentEditing(props: Props) {
  const { awsIntegration, onCancel, onSubmit } = props
  const {
    aws_account_id,
    aws_role_name,
    aws_enabled_services,
    aws_external_id,
    aws_enabled_regions,
    aws_kms_key_id,
    writable,
    resource_tags,
  } = awsIntegration

  const [loading, setLoading] = useState(false)
  const [form] = useForm()
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const [submitEnabled, setSubmitEnabled] = useState(true)
  const [showSettings, setShowSettings] = useState(false)
  const [checkedList, setCheckedList] = useState<AwsRegionEnum[]>(
    aws_enabled_regions.map((region: AwsRegionEnum) => region)
  )

  const updateAwsIntegration = () => {
    setLoading(true)
    const services: AwsServiceEnum[] = []
    allAwsServices.forEach((service) => {
      if (form.getFieldValue(`checkbox_${service}`)) {
        services.push(service)
      }
    })

    const payload = {
      ...awsIntegration,
      aws_account_id: form.getFieldValue(ACCOUNT_ID_FIELD),
      aws_enabled_regions: checkedList,
      aws_enabled_services: services,
      aws_external_id: form.getFieldValue(EXTERNAL_ID_FIELD),
      aws_kms_key_id: form.getFieldValue(KMS_ID_FIELD),
      aws_role_name: form.getFieldValue(ROLE_NAME_FIELD),
      writable: form.getFieldValue('checkbox_writable') ?? false,
      resource_tags: form.getFieldValue('resource-tags')
        ? (form.getFieldValue('resource-tags') as Record<string, string>[]).reduce(
            (acc, { key, value }) => ({ ...acc, [key]: value }),
            {}
          )
        : undefined,
    } as AwsIntegrationCreate

    dispatch(UpdateAwsIntegration(payload)).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : onSubmit()

      setLoading(false)
      onCancel() // closes the editing stuff
    })
    // we don't have subscriptions yet so this blocks on updating integration status
  }

  const onFieldsChange = () => {
    if (getEnabledServices(form).length > 0) {
      const { accountId, roleName } = form.getFieldsValue()
      setSubmitEnabled(accountId.length > 0 && roleName.length > 0)
    } else {
      setSubmitEnabled(false)
    }
  }

  return (
    <Form
      onFinish={updateAwsIntegration}
      form={form}
      initialValues={{
        ...initialServiceCheckboxValues(aws_enabled_services),
        [ACCOUNT_ID_FIELD]: aws_account_id,
        [EXTERNAL_ID_FIELD]: aws_external_id,
        [KMS_ID_FIELD]: aws_kms_key_id,
        [ROLE_NAME_FIELD]: aws_role_name,
        checkbox_writable: writable,
        ['resource-tags']: resource_tags
          ? Object.keys(resource_tags).map((key) => ({ key, value: resource_tags[key] }))
          : undefined,
      }}
      formProps={{ onFieldsChange }}
    >
      <AwsAccountFormBody
        resourceTags={
          resource_tags
            ? Object.keys(resource_tags).map((key) => ({ key, value: resource_tags[key] }))
            : undefined
        }
      />

      <div className={styles.awsRegionContainer}>
        <h3>Region Preference</h3>
        <p className={styles.regionDescription}>
          By default, United States regions will be displayed when selecting a external value. Use
          the Region Preference settings to specify which regions will be displayed when using
          applicable services.
        </p>
        <ShowHideButton
          label="region settings"
          showSettings={showSettings}
          toggleShowSettings={() => setShowSettings((prev) => !prev)}
        />
        {showSettings && (
          <AwsRegionsCheckboxes checkedList={checkedList} setCheckedList={setCheckedList} />
        )}
      </div>

      <div className={styles.footer}>
        <ActionButton onClick={onCancel}>Cancel</ActionButton>
        <ActionButton
          loading={loading}
          type="primary"
          htmlType="submit"
          disabled={!submitEnabled || checkedList.length < 1}
        >
          Save
        </ActionButton>
      </div>
    </Form>
  )
}

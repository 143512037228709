import { Divider } from 'antd'
import React from 'react'
import styles from './AccountDetails.module.scss'

export function AccountDetails() {
  return (
    <div className={styles.container}>
      <h2>Account Details</h2>
      <Divider className={styles.divider} />
      <div className={styles.content}>
        <div className={styles.accountDetailsContainer}>
          <h3>Need assistance with your account?</h3>
          <span>
            Please contact <a href="mailto:support@cloudtruth.com">support@cloudtruth.com</a>
          </span>
        </div>
      </div>
    </div>
  )
}

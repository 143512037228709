//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Environment, Tag } from 'gen/cloudTruthRestApi'
import { EnvironmentRootState, environmentAdapter } from './reducer'

import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { idFromUrl } from 'data/dataUtils'
import { useSelectState } from '../hooks'

export const selectEnvironment = (state: RootState): EnvironmentRootState => state.environment

export const getCurrentEnvironment = createSelector(
  selectEnvironment,
  (state): nullable<Environment> => {
    return state.current ? state.entities[state.current]! : null
  }
)

export const getBaseEnvironment = createSelector(selectEnvironment, (state): Environment => {
  const entities = state.entities
  return Object.values(entities).find((env) => !env!.parent)!
})

export const getAncestorEnvironments = (env: Environment): ((S: any) => Environment[]) =>
  createSelector(selectEnvironment, (state): Environment[] => {
    const ancestors: Environment[] = []
    let foundAncestor: nullable<Environment> = env
    while (foundAncestor) {
      if (foundAncestor.parent) {
        foundAncestor = state.entities[idFromUrl(foundAncestor.parent)] || null
        if (foundAncestor) {
          ancestors.push(foundAncestor)
        }
      }
    }
    return ancestors
  })

export const getEnvironmentTags = (id: nullable<string>): ((S: any) => Tag[] | undefined) =>
  createSelector(selectEnvironment, (state): Tag[] => {
    const envId = id || state.current
    return envId ? state.tags[envId] : []
  })

export const getTags = (): ((S: any) => Record<string, Tag[]> | undefined) =>
  createSelector(selectEnvironment, (state): Record<string, Tag[]> => {
    return state.tags
  })

export const environmentEntitySelectors = environmentAdapter.getSelectors()
export const useSelectEnvironments = (): EnvironmentRootState => useSelectState().environment

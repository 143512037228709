import { LabelText } from 'components/LabelText'
import React from 'react'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './DetailsDate.module.scss'

interface Props {
  updatedAt: nullable<string>
  createdAt: string
}

export function DetailsDate(props: Props) {
  const { updatedAt, createdAt } = props

  return (
    <div className={styles.container}>
      <LabelText isHorizontal text={formatDateTime(createdAt)} label="Date Created:" />
      <LabelText isHorizontal text={formatDateTime(updatedAt)} label="Last Updated:" />
    </div>
  )
}

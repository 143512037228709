import { Checkbox, CheckboxProps, RadioChangeEvent } from 'antd'
import React, { ReactNode, useState } from 'react'
import { RegionObject, formatRegionInputArray } from './util'

import { AwsRegionEnum } from 'gen/cloudTruthRestApi'
import { Label } from 'components/Forms'
import styles from './AwsRegionsCheckboxes.module.scss'

const CheckboxGroup = Checkbox.Group

interface Props {
  checkedList: AwsRegionEnum[]
  setCheckedList: React.Dispatch<React.SetStateAction<AwsRegionEnum[]>>
}

interface BoxProps {
  regionObj: RegionObject
  handleCheckAllChange: (e: RadioChangeEvent, regions: AwsRegionEnum[], cb: () => void) => void
  handleChange: (
    list: CheckboxProps['value'],
    regions: AwsRegionEnum[],
    cb: (bool: boolean) => void
  ) => void
  checkedList: AwsRegionEnum[]
}

const RegionChecboxGroup = (props: BoxProps) => {
  const { regionObj, handleCheckAllChange, handleChange, checkedList } = props

  const regions = regionObj.regions.map((region) => region.value)

  const initialCheckAll = () => {
    const regionCountInCheckedList = checkedList.filter((region) => regions.includes(region)).length
    return regionCountInCheckedList === regions.length
  }

  const [checkAll, setCheckAll] = useState(initialCheckAll())

  return (
    <div className={styles.checkboxContainer}>
      <Label text={regionObj.regionLabel} />
      {regions.length > 1 && (
        <Checkbox
          onChange={(e) => handleCheckAllChange(e, regions, () => setCheckAll(!checkAll))}
          checked={checkAll}
        >
          All {regionObj.regionLabel} Regions
        </Checkbox>
      )}

      <CheckboxGroup
        className={styles.checkboxGroup}
        options={regionObj.regions}
        value={checkedList}
        onChange={(list) => handleChange(list, regions, (bool) => setCheckAll(bool))}
      />
    </div>
  )
}

export function AwsRegionsCheckboxes(props: Props) {
  const { checkedList, setCheckedList } = props

  const handleCheckAllChange = (e: RadioChangeEvent, regions: AwsRegionEnum[], cb: () => void) => {
    setCheckedList((previousState) => {
      return e.target.checked
        ? [...previousState, ...regions]
        : previousState.filter((i) => !regions.includes(i))
    })
    cb()
  }

  const handleChange = (
    list: CheckboxProps['value'],
    regions: AwsRegionEnum[],
    cb: (bool: boolean) => void
  ) => {
    const checkedValues = list as AwsRegionEnum[]
    const filteredRegions = regions.filter((region) => !list.includes(region))

    setCheckedList((previousState) =>
      Array.from(new Set([...previousState, ...checkedValues])).filter(
        (region) => !filteredRegions.includes(region)
      )
    )
    cb(filteredRegions.length === 0)
  }

  const renderCheckBoxes = (): ReactNode[] => {
    return formatRegionInputArray.map((regionObj, index) => {
      return (
        <RegionChecboxGroup
          handleChange={handleChange}
          handleCheckAllChange={handleCheckAllChange}
          checkedList={checkedList}
          regionObj={regionObj}
          key={index}
        />
      )
    })
  }

  return <div className={styles.container}>{renderCheckBoxes()}</div>
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { GitHubPull, PaginatedGitHubPullList } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ActionIds } from '../utils'
import { MappedPull } from '../awsImport/actions'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface UpdatePull {
  pull: Partial<GitHubPull>
  githubIntegrationId: string
}

interface AddPull {
  pull: Partial<GitHubPull>
  githubIntegrationId: string
}

interface GetTaskSteps extends ActionIds {
  taskId: string
}

export const GetGithubPulls = createAsyncThunk<PaginatedGitHubPullList, string, ThunkConfig>(
  'githubPull/getPulls',
  async (githubIntegrationId, thunkApi) =>
    await handleFetch(Verbs.Get, `integrations/github/${githubIntegrationId}/pulls`, null, thunkApi)
)

export const GetGithubPull = createAsyncThunk<GitHubPull, ActionIds, ThunkConfig>(
  'githubPull/getPull',
  async (getPull, thunkApi) => {
    const { integrationId, actionId } = getPull
    return await handleFetch(
      Verbs.Get,
      `integrations/github/${integrationId}/pulls/${actionId}`,
      null,
      thunkApi
    )
  }
)

export const GetGithubPullTasks = createAsyncThunk<GitHubPull, ActionIds, ThunkConfig>(
  'githubPull/getPullTasks',
  async (getPull, thunkApi) => {
    const { integrationId, actionId } = getPull
    return await handleFetch(
      Verbs.Get,
      `integrations/github/${integrationId}/pulls/${actionId}/tasks`,
      null,
      thunkApi
    )
  }
)

export const GetGithubPullTaskSteps = createAsyncThunk<GitHubPull, GetTaskSteps, ThunkConfig>(
  'githubPull/getPullTaskSteps',
  async (getSteps, thunkApi) => {
    const { integrationId, actionId, taskId } = getSteps
    return await handleFetch(
      Verbs.Get,
      `integrations/github/${integrationId}/pulls/${actionId}/tasks/${taskId}/steps`,
      null,
      thunkApi
    )
  }
)

export const CreateGithubPull = createAsyncThunk<GitHubPull, AddPull, ThunkConfig>(
  'githubPull/createPull',
  async (addObj, thunkApi) => {
    const { pull, githubIntegrationId } = addObj

    return await handleFetch(
      Verbs.Post,
      `integrations/github/${githubIntegrationId}/pulls`,
      pull,
      thunkApi
    )
  }
)

export const UpdateGithubPull = createAsyncThunk<GitHubPull, UpdatePull, ThunkConfig>(
  'githubPull/updatePull',
  async (updateObj, thunkApi) => {
    const { pull, githubIntegrationId } = updateObj

    return await handleFetch(
      Verbs.Put,
      `integrations/github/${githubIntegrationId}/pulls/${pull.id}`,
      pull,
      thunkApi
    )
  }
)

export const SyncGithubPull = createAsyncThunk<GitHubPull, ActionIds, ThunkConfig>(
  'githubPull/syncPull',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/github/${integrationId}/pulls/${actionId}/sync`,
      null,
      thunkApi
    )
  }
)

export const SyncMappedGithubVaultPull = createAsyncThunk<GitHubPull, MappedPull, ThunkConfig>(
  'githubPull/mappedSyncPull',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/github/${integrationId}/pulls/${actionId}/sync`,
      { dry_run: syncObj.dry_run },
      thunkApi
    )
  }
)

export const DeleteGithubPull = createAsyncThunk<string, ActionIds, ThunkConfig>(
  'githubPull/deletePull',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Delete,
      `integrations/github/${req.integrationId}/pulls/${req.actionId}`,
      null,
      thunkApi
    )
)

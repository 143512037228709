import React, { PropsWithChildren, createContext } from 'react'

import { message } from 'antd'

type MessageType = 'success' | 'info' | 'warning' | 'error'

export interface ContextValue {
  successToast: (message: string) => void
  infoToast: (message: string) => void
  warningToast: (message: string) => void
  errorToast: (message: string) => void
}

const messageArray: MessageType[] = ['success', 'info', 'warning', 'error']

function valueObject(): ContextValue {
  message.config({ duration: 7.5, top: 60 })

  const obj = {
    successToast: () => null,
    infoToast: () => null,
    warningToast: () => null,
    errorToast: () => null,
  }

  messageArray.forEach((i) => Object.assign(obj, { [`${i}Toast`]: (m: string) => message[i](m) }))

  return obj
}

export const ToastContext = createContext<ContextValue>(valueObject())

export const ToastProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { children } = props

  return <ToastContext.Provider value={valueObject()}>{children}</ToastContext.Provider>
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { DocLink } from 'components/DocLink'
import { LabeledInputItem } from 'components/Forms'
import React from 'react'

interface Props {
  onChange?: (e: CheckboxChangeEvent) => void
  disabled?: boolean
  secret?: boolean
  checked?: boolean
}

const SECRET_LABEL_PROPS = {
  text: 'Secret Parameter',
  docLink: (
    <DocLink
      href="https://docs.cloudtruth.com/configuration-management/parameters/managing-parameters#secrets"
      text="Learn more about secrets"
    />
  ),
}

export function SecretCheckbox(props: Props) {
  const { onChange, disabled, secret } = props

  return (
    <>
      <LabeledInputItem name="secret" label={SECRET_LABEL_PROPS}>
        <Checkbox
          onChange={onChange}
          disabled={disabled}
          defaultChecked={secret}
          data-cy="secret-checkbox"
        >
          Selecting is a one-time action and cannot be undone.
        </Checkbox>
      </LabeledInputItem>
    </>
  )
}

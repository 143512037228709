//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateGithubIntegration,
  DeleteGithubIntegration,
  GetGithubIntegration,
  GetGithubIntegrations,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { GitHubIntegration } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface GithubIntegrationState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const githubIntegrationAdapter = createEntityAdapter<GitHubIntegration>({
  selectId: (int) => int.id,
})

const initialState = githubIntegrationAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
} as GithubIntegrationState)

export type GithubRootState = EntityState<GitHubIntegration> & GithubIntegrationState

const githubIntegrationSlice = createSlice({
  name: 'githubIntegration',
  initialState,
  reducers: {
    // direct actions
    selectGithubIntegration(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllGithubIntegration: githubIntegrationAdapter.setAll,
    addOneGithubIntegration: githubIntegrationAdapter.addOne,
    updateOneGithubIntegration: githubIntegrationAdapter.upsertOne,
    removeOneGithubIntegration: githubIntegrationAdapter.removeOne,

    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateGithubIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubIntegrationAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetGithubIntegrations.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubIntegrationAdapter.setAll(state, action.payload.results!)
      state.current = null
    })
    builder.addCase(GetGithubIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubIntegrationAdapter.addOne(state, action.payload)
    })
    builder.addCase(DeleteGithubIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubIntegrationAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const {
  resetState,
  selectGithubIntegration,
  setAllGithubIntegration,
  addOneGithubIntegration,
  updateOneGithubIntegration,
  removeOneGithubIntegration,
} = githubIntegrationSlice.actions

export default githubIntegrationSlice.reducer

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Avatar, Select } from 'antd'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { KeyOutlined } from '@ant-design/icons'
import React from 'react'
import { User } from 'gen/cloudTruthRestApi'
import { UserAvatar } from 'components/UserAvatar'
import { makeTitleCase } from 'lib/valueHelpers'
import styles from './UserSelector.module.scss'

const { Option } = Select

interface Props {
  values: User['url'][]
  setValues: (values: User['url'][]) => void
  disabled?: boolean
}

export function UserSelector(props: Props) {
  const { values, setValues, disabled } = props

  const users = userEntitySelectors.selectAll(useSelectUsers())

  const handleChange = (values: User['url'][]) => {
    setValues(values)
  }

  const userOptions = () => {
    return users.map((user) => {
      return (
        <Option value={user.url} label={user.name} key={user.id}>
          <div>
            <span className={styles.avatar}>
              {user.type === 'service' && <Avatar icon={<KeyOutlined rotate={180} />} />}
              {user.type === 'interactive' && <UserAvatar pictureUrl={user.picture_url} />}
            </span>

            <span>
              {user.name} ({makeTitleCase(user.role === 'CONTRIB' ? 'contributor' : user.role!)})
            </span>
          </div>
        </Option>
      )
    })
  }

  return (
    <Select
      showSearch={false}
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="select users"
      value={values}
      disabled={!!disabled}
      onChange={handleChange}
      optionLabelProp="label"
      data-cy="user-selector"
    >
      {userOptions().map((i) => i)}
    </Select>
  )
}

import { Project } from 'gen/cloudTruthRestApi'

interface FormatMessage {
  projectHash: Record<string, Record<'parameters' | 'templates', string[]>>
  projects: Project[]

  body: maybe<string>
  error: string
}

export const formatMessage = ({ error, body, projectHash, projects }: FormatMessage) => {
  return `
Fix the template with error:
Error: ${error}
Body: "${body}"

Parameter and Template data per project:
// these are the templates and parameters that are referenced in the project
// the current project templates and parameters should be looking for interpolations that start with cloudtruth.parameters.<param_name> or cloudtruth.templates.<template_name>
// templates and parameters can have the same name in different projects, parameter and template names are unique within a project
${JSON.stringify(projectHash, null, 2)}

// these are all possible projects
Projects: ${projects.map((project) => `"${project.name}"`).join(', ')}

Return the following JSON fields:
- newBody: string | null
  // Return a new body if there is a syntax error. The syntax is Liquid with CloudTruth access. If no syntax error, return null.

- parameters: interface P {name: string, project: string | null}[]
  // If the template references parameters that are missing, return them in the format {name: string, project: string | null}. Example: {{cloudtruth.projects.abc.parameters.parameter2}} should return [{name: "parameter2", project: "abc"}]. If none are missing, return an empty array.

- templates: interface T {name: string, project: string | null}[]
  // If the template references templates that are missing, return them in the format {name: string, project: string | null}. Example: {{cloudtruth.projects.project1.templates.test_template}} should return [{name: "test_template", project: "project1"}]. If none are missing, return an empty array.

- projects: string[]
  // If the template references projects that are missing, return them as an array of strings. If none are missing, return an empty array.

  analyze the cloudtruth access carfully if the template or parameters is referencing a cross project then account for it in missing or not missing projects
`
}

const entryPattern = /\{\{([^}]*)/g

function extractEntries(text: string) {
  let matches
  const entries = []
  while ((matches = entryPattern.exec(text)) !== null) {
    entries.push(matches[1].trim())
  }
  return entries
}

function extractNames(entry: string) {
  // Regex patterns to match both dot and bracket notations
  const dotNotationPattern = /cloudtruth\.projects\.([^.]+)\.(templates|parameters)\.([^.]+)/
  const bracketNotationPattern =
    /cloudtruth\.projects\["([^"]+)"\]\.(templates|parameters)\["([^"]+)"\]/

  let match = entry.match(dotNotationPattern)
  if (match) {
    return { project: match[1], type: match[2].slice(0, -1), name: match[3] }
  }

  match = entry.match(bracketNotationPattern)
  if (match) {
    return { project: match[1], type: match[2].slice(0, -1), name: match[3] }
  }

  return null
}

export function extractCrossProjectBody(inputString: string) {
  // Extracting entries from the input string
  const entries = extractEntries(inputString)

  // Extracting names from entries
  const extractedNames = entries.map((entry) => extractNames(entry)).filter(Boolean)

  return extractedNames
}

import { FileBody } from './FileBody'
import { FormInstance } from 'antd'
import { IntegrationNode } from 'gen/cloudTruthRestApi'
import { JMESPathDetails } from './JMESPathDetails'
import React from 'react'

interface Props {
  integrationFile: IntegrationNode
  form: FormInstance
  error: maybe<string>
  initialJmesPath?: nullable<string>
  showSecret?: boolean
  secret?: nullable<boolean>
}

export function FileDetails(props: Props) {
  const { integrationFile, form, error, initialJmesPath, showSecret, secret } = props

  return (
    <>
      <JMESPathDetails
        integrationFile={integrationFile}
        form={form}
        error={error}
        initialJmesPath={initialJmesPath}
      />

      <FileBody integrationFile={integrationFile} showSecret={showSecret} secret={secret} />
    </>
  )
}

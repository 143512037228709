import React, { PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Auth0Provider } from '@auth0/auth0-react'
import { trackEvent } from 'lib/analyticsHelpers'

const Auth0ProviderWithHistory = (props: PropsWithChildren<unknown>) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { children } = props

  // fix me: we no longer have graphql endpoint so this will need to change.
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const onRedirectCallback = (appState: any) => {
    trackEvent('Session', 'Started')
    // Not using route checked "useHistoryPush" because route is unknown
    navigate(appState?.returnTo || pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI ?? ''}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory

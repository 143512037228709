//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { FormData, useForm } from 'components/Forms'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { CreateTag } from 'data/environment/actions'
import { CustomThunk } from 'data/dataUtils'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { TagCreate } from 'gen/cloudTruthRestApi'
import { TagForm } from './TagForm'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  dontChangeCurrent?: boolean
  visibleFromProps?: boolean
  onClose?: () => void
  hide?: boolean
  redirect?: boolean
}

export function AddTag(props: Props) {
  const { dontChangeCurrent, hide, visibleFromProps, onClose, redirect } = props
  const [timestamp, setTimestamp] = useState<nullable<Dayjs>>(dayjs())
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()
  const { goToOrgRoute } = useHistoryPush()

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(() => {
    onClose ? onClose() : setVisible(false)
  }, [onClose])

  // reset time on form open
  useEffect(() => {
    if (visible) {
      setLoading(false)
      setTimestamp(dayjs())
    }
  }, [visible])

  const onFinish = (formData: FormData) => {
    const { name, description, environment, immutable } = formData

    const tag = {
      name,
      description,
      immutable,
      timestamp: timestamp || dayjs(),
    } as unknown as TagCreate

    setLoading(true)
    dispatch(CreateTag({ envId: environment, tag })).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
      } else {
        successToast('Tag successfully created')
        closeModal()
        if (redirect) {
          goToOrgRoute(OrganizationPaths.Environments)
        }

        // This component will unmount in the push action page
        !dontChangeCurrent && setVisible(false)
      }

      setLoading(false)
    })
  }

  return (
    <>
      {!hide && (
        <ActionButton customType="add" size="large" onClick={() => setVisible(true)}>
          Create Tag
        </ActionButton>
      )}

      <AddModal
        pending={loading}
        visible={modalVisible}
        objectName="Tag"
        afterClose={() => form.resetFields()}
        onCancel={closeModal}
        onOk={() => form.submit()}
      >
        <TagForm
          dontChangeCurrent={dontChangeCurrent}
          form={form}
          onFinish={onFinish}
          timestamp={timestamp}
          setTimestamp={setTimestamp}
        />
      </AddModal>
    </>
  )
}

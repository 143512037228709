//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionButton } from 'components/ActionButton'
import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import styles from './Reload.module.scss'

interface Props {
  reload: () => any
  loading: boolean
  listLength: number
}

export function Reload(props: Props) {
  const { reload, listLength, loading } = props

  if (listLength < 1) {
    return null
  }

  return (
    <>
      <ActionButton disabled={loading} onClick={loading ? () => null : reload}>
        <ReloadOutlined className={loading ? styles.disabled : styles.reload} />
      </ActionButton>
    </>
  )
}

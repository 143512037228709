//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import akvIntegration from './integration/akvIntegration/reducer'
import akvPull from './actions/akvImport/reducer'
import akvPush from './actions/akvPush/reducer'
import awsIntegration from './integration/awsIntegration/reducer'
import awsPull from './actions/awsImport/reducer'
import awsPush from './actions/awsPush/reducer'
import { combineReducers } from '@reduxjs/toolkit'
import environment from './environment/reducer'
import expire from './expire/reducer'
import feature from './feature/reducer'
import githubIntegration from './integration/githubIntegration/reducer'
import githubPull from './actions/githubImport/reducer'
import grant from './grant/reducer'
import group from './group/reducer'
import membership from './membership/reducer'
import organization from './organization/reducer'
import parameter from './parameter/reducer'
import parameterType from './parameterType/reducer'
import project from './project/reducer'
import serviceAccount from './serviceAccount/reducer'
import session from './session/reducer'
import template from './template/reducer'
import user from './user/reducer'

export default combineReducers({
  githubIntegration,
  grant,
  group,
  awsIntegration,
  serviceAccount,
  parameterType,
  organization,
  environment,
  membership,
  parameter,
  template,
  session,
  project,
  user,
  awsPull,
  akvPull,
  githubPull,
  awsPush,
  akvPush,
  akvIntegration,
  feature,
  expire,
})

import { IntegrationNode } from 'gen/cloudTruthRestApi'
import { mimeType } from './valueHelpers'
import { search } from 'jmespath'
import yaml from 'js-yaml'

export type SearchResults = {
  result?: string
  parseError?: string
}

export const jmesPathSearch = (node: IntegrationNode, jmesPath: maybe<string>): SearchResults => {
  try {
    if (node.content_data && jmesPath && node.name) {
      const mime = node.content_type || mimeType(node.name)

      if (mime.includes('json')) {
        const result = search(JSON.parse(node.content_data), jmesPath)
        if (result) {
          return { result: JSON.stringify(result) }
        }
      } else if (mime.includes('yaml')) {
        const result = search(yaml.load(node.content_data), jmesPath)
        if (result) {
          return { result: JSON.stringify(result) }
        }
      } else if (mime.includes('dotenv')) {
        // if this is updated, please update the backend as well
        // reference: ctcaas/parameters/models/integrations/data/data_types.py line 97
        const re = new RegExp(`${jmesPath}=([^\n]*)`)
        const result = node.content_data.match(re)?.[1]
        if (result) {
          return { result }
        }
      } else {
        return { parseError: 'This file type cannot be parsed by the browser.' }
      }
    }
  } catch (e: any) {
    if (e.name === 'ParserError' || e.message.match(/Expecting/)) {
      //example: .foo
      //example: {]
      return { parseError: e.message }
    }
    if (e.name === 'TypeError' && e.message === 'node is undefined') {
      // example: foo.
      return { parseError: 'Cannot parse JMESPath selector' }
    }
    return { parseError: e.message }
  }

  return {}
}

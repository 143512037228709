import { AzureKeyVaultIntegration, StatusEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { DeleteAkv } from './DeleteAkv/DeleteAkv'
import { DeleteAzureKeyVaultIntegration } from 'data/integration/akvIntegration/actions'
import DeleteIntegrationOptionsModal from 'components/Modals/DeleteIntegrationOptionsModal'
import { EditAkv } from './EditAkv'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

enum MenuItemKeys {
  EditAkv = 'manageAccessTokens',
  DeleteAkv = 'deleteToken',
  CheckStatus = 'checkStatus',
}

interface Props {
  akv: AzureKeyVaultIntegration
  checkIntegration: () => void
}

export function DropdownMenu(props: Props) {
  const { akv, checkIntegration } = props
  const [editVisible, setEditVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteOptionsVisible, setDeleteOptionsVisible] = useState(false)

  const { canContribute, canAdministrate } = useAppSelector(getPolicy(null))
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()

  const forceDeleteIntegration = (option: 'leave' | 'remove'): void => {
    setLoading(true)
    dispatch(DeleteAzureKeyVaultIntegration({ id: akv.id, in_use: option })).then(
      ({ error }: CustomThunk) => {
        error ? errorToast(error.message) : successToast('Azure integration successfully deleted.')

        setLoading(false)
        setDeleteOptionsVisible(false)
      }
    )
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteAkv: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.EditAkv: {
        setEditVisible(true)
        break
      }
      case MenuItemKeys.CheckStatus: {
        checkIntegration()
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canContribute, [
      {
        key: MenuItemKeys.CheckStatus,
        label: 'Check Status',
        disabled: akv.status === StatusEnum.Checking,
      },
      { key: MenuItemKeys.EditAkv, label: 'Edit' },
    ]),

    ...conditionalArrayItem(canAdministrate, [
      { key: MenuItemKeys.DeleteAkv, label: 'Delete', danger: true },
    ]),
  ]

  return (
    <div style={{ marginLeft: '12px' }}>
      <EditAkv integration={akv} visible={editVisible} setVisible={setEditVisible} />
      <DeleteAkv
        loading={loading}
        setLoading={setLoading}
        setVisible={setDeleteVisible}
        visible={deleteVisible}
        integration={akv}
        setDeleteOptionsVisible={setDeleteOptionsVisible}
      />
      <DeleteIntegrationOptionsModal
        closeModal={() => setDeleteOptionsVisible(false)}
        visible={deleteOptionsVisible}
        onDelete={forceDeleteIntegration}
        loading={loading}
      />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </div>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormInstance, Input } from 'antd'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import styles from './TableSearch.module.scss'

const SEARCH_FIELD = 'search'

interface Props {
  defaultValue: string
  updateSearchTerm: (name: string) => void
  form: FormInstance
  setPage?: React.Dispatch<React.SetStateAction<{ pageNumber: number; project: string }>>
  placeHolder?: string
  marginLeft?: string
  marginRight?: string
  onSearch?: () => void
}

export function TableSearch(props: Props) {
  const {
    defaultValue,
    updateSearchTerm,
    form,
    setPage,
    placeHolder,
    marginLeft,
    marginRight,
    onSearch,
  } = props

  const [liveSearchValue, setLiveSearchValue] = useState('')

  const { setQuery } = useQuery()

  return (
    <div style={{ marginLeft, marginRight }}>
      <Form form={form} layout="inline" initialValues={{ [SEARCH_FIELD]: defaultValue }}>
        <Form.Item name={SEARCH_FIELD}>
          <Input
            className={styles.search}
            placeholder={placeHolder || 'Search Parameters'}
            data-cy="search-input"
            suffix={
              <SearchOutlined
                data-testid="search-icon"
                data-cy="search-icon"
                className={styles.searchIcon}
                onClick={() => {
                  updateSearchTerm(liveSearchValue)
                  setPage && setPage((prev) => ({ ...prev, pageNumber: 1 }))
                  setQuery(QueryParamName.Page, '1')
                  onSearch && onSearch()
                }}
              />
            }
            onChange={(e) => {
              if (!e.target.value) {
                updateSearchTerm('')
              }
              setLiveSearchValue(e.target.value)
            }}
            onPressEnter={() => {
              updateSearchTerm(liveSearchValue)
              setPage && setPage((prev) => ({ ...prev, pageNumber: 1 }))
              setQuery(QueryParamName.Page, '1')
            }}
            allowClear
          />
        </Form.Item>
      </Form>
    </div>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { QuestionIconTooltip } from './QuestionIconTooltip'
import React from 'react'
import styles from './TableTitle.module.scss'

interface Props {
  title: string | JSX.Element
  actionButton?: JSX.Element
  tooltip?: string | JSX.Element
  search?: React.ReactElement
}

export function TableTitle(props: Props) {
  const { actionButton, title, tooltip, search } = props

  return (
    <div className={styles.container}>
      <div data-testid="title-table" className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {tooltip && <QuestionIconTooltip title={tooltip} centerSpan />}
        {search && <div>{search}</div>}
      </div>

      {actionButton}
    </div>
  )
}

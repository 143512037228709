//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionEnum, AsyncJob, getAsyncJobs } from '../utils'
import { PullRootState, akvPullAdapter } from './reducer'

import { GetAzureKeyVaultPull } from './actions'
import { RootState } from 'data/store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectAkvPull = (state: RootState) => state.akvPull

export const getCurrentPull = createSelector(selectAkvPull, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getRunningAzureKeyVaultPulls = createSelector(selectAkvPull, (state): AsyncJob[] => {
  return getAsyncJobs(state).map((job) => ({
    ...job,
    type: ActionEnum.AzureKeyVaultPull,
    action: GetAzureKeyVaultPull,
  }))
})

export const akvPullEntitySelectors = akvPullAdapter.getSelectors()
export const useSelectAkvPull = (): PullRootState => useSelectState().akvPull

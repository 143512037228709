//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Message } from './reducer'
import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { ctJwtNamespace } from 'lib/jwtHelpers'

const selectSession = (state: RootState) => state.session

export const getActiveSession = createSelector(selectSession, (state): boolean => {
  return !!state.jwt
})

export const getIntercomHash = createSelector(selectSession, (state): string | undefined => {
  return state.claims?.[ctJwtNamespace]?.intercom_hash
})

export const getSessionErrors = createSelector(selectSession, (state): Record<string, Message> => {
  return state.sessionErrors
})

export const getSubscribeToAsyncPulls = createSelector(selectSession, (state): nullable<Date> => {
  return state.subscribeToAsyncPulls
})

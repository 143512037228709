//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { BaseParameterTypes, validateRuleOptions } from 'lib/validators'
import { Button, FormInstance, Input, Select, Space } from 'antd'
import {
  Form,
  FormData,
  InlineLabelInput,
  Item,
  LabeledInputItem,
  List,
  TextArea,
} from 'components/Forms'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useState } from 'react'

import { ParameterRuleType } from 'data/parameter/actions'
import { ParameterType } from 'gen/cloudTruthRestApi'
import RuleTile from './Rules/RuleTile'

interface Props {
  rules: ParameterRuleType[]
  setRules: React.Dispatch<ParameterRuleType[]>
  form: FormInstance
  onFinish: (formData: FormData) => void
  type?: ParameterType
  customName?: string
}

export default function CustomTypeForm(props: Props) {
  const { type, customName, rules, setRules, form, onFinish } = props

  const [baseParent, setBaseParent] = useState<BaseParameterTypes>(
    (type?.parent_name as BaseParameterTypes) || BaseParameterTypes.String
  )

  const fieldVariants = useMemo(() => {
    return (
      (typeof type?.rules[0] &&
        (type?.rules[0]?.constraints as any)
          ?.map((constr: any) => ({ key: constr }))
          .map((rule: ParameterRuleType, index: number) => ({
            key: index,
            name: index,
            ...rule,
          }))) ||
      []
    )
  }, [type?.rules])

  useEffect(() => {
    form.setFieldsValue({ parentName: baseParent })

    const validRules: ParameterRuleType[] = []
    let foundInvalidRule = false
    rules.forEach((rule) => {
      if (validateRuleOptions(baseParent as BaseParameterTypes, rule.type)) {
        validRules.push(rule)
      } else {
        foundInvalidRule = true
      }
    })

    if (foundInvalidRule) {
      setRules(validRules)
    }
  }, [form, baseParent, rules, setRules])

  const initialValues = type
    ? {
        name: type.name,
        description: type.description,
        parenName: type.parent_name,
        enumRules: (rules[0]?.constraints as any)?.map((constr: any) => ({ key: constr })),
      }
    : {
        name: customName || '',
        description: '',
        parentName: BaseParameterTypes.String,
      }

  return (
    <>
      <Form form={form} initialValues={initialValues} onFinish={onFinish}>
        <InlineLabelInput autoFocus name="name" label="Type Name" required="Please enter a name." />
        <TextArea name="description" label="Description" />
        <LabeledInputItem required name="parentName" label="Base Type">
          <Select placeholder="Base Type" onChange={(option) => setBaseParent(option)}>
            {Object.values(BaseParameterTypes).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </LabeledInputItem>
        {baseParent === 'enum' ? (
          <List
            name="enumRules"
            initialValue={rules.length > 0 ? (rules[0]?.constraints as any) : []}
          >
            {(fields = fieldVariants, { remove, add }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[{ required: true, message: 'Missing key' }]}
                    >
                      <Input placeholder="Enum Name" />
                    </Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Enum
                  </Button>
                </Item>
              </>
            )}
          </List>
        ) : (
          <>
            {rules.map((rule) => {
              return (
                <RuleTile
                  isOverride={false}
                  key={rule.id}
                  id={rule.id}
                  parameterType={baseParent}
                  setRules={setRules}
                  rules={rules}
                  rule={rule}
                />
              )
            })}
            <RuleTile
              isOverride={false}
              key="new-custom"
              id="new-custom"
              parameterType={baseParent}
              setRules={setRules}
              rules={rules}
            />
          </>
        )}
      </Form>
    </>
  )
}

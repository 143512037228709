import dayjs from 'dayjs'
import { emDash } from './valueHelpers'

export const formatDate = (dateString: maybe<string>): string => {
  if (!dateString) {
    return emDash
  }

  const d = new Date(dateString)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

  return `${ye}-${mo}-${da}`
}

export const displayDate = (dateString: nullable<string>) => {
  const currentDate = dayjs()
  const inputDate = dayjs(dateString)
  const isToday = inputDate.isSame(currentDate, 'day')
  const sameYear = inputDate.isSame(currentDate, 'year')

  if (isToday && sameYear) {
    return inputDate.format('h:mm A')
  }
  if (sameYear) {
    return inputDate.format('MMM D')
  }
  return inputDate.format('M/D/YY')
}

export const formatDateTime = (dateTimeString: maybe<string>): string => {
  if (!dateTimeString) {
    return emDash
  }

  return dayjs(dateTimeString).format('YYYY-MM-DD h:mm A')
}

export const formatLocalDateTime = (dateTimeString: string): string => {
  const getLanguage = () =>
    (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
    navigator.language

  return new Intl.DateTimeFormat(getLanguage(), {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: true,
  }).format(new Date(dateTimeString))
}

export function subscriptionDaysLeft(subscriptionExpiresAt: maybe<string>): number {
  const now = new Date()
  const expires = subscriptionExpiresAt ? new Date(subscriptionExpiresAt) : null
  const millisecondsPerDay = 1000 * 60 * 60 * 24
  const daysLeft =
    (expires && Math.ceil((expires.getTime() - now.getTime()) / millisecondsPerDay)) || 999
  return daysLeft
}

export const isoDateFormat = 'YYYY-MM-DD'
export const isoTimeFormat = 'HH:mm:ss'
export const isoDateTimeFormat = `${isoDateFormat} ${isoTimeFormat}`

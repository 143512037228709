import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import { WarningIconTooltip } from 'components/WarningIconTooltip'
import styles from './Label.module.scss'

export type LabelProps = {
  docLink?: JSX.Element
  optionText?: boolean
  text: string
  tooltipText?: string
  upperCase?: boolean
  warningText?: string
}

export function Label(props: LabelProps) {
  const { docLink, text, tooltipText, optionText, warningText, upperCase = true } = props

  return (
    <div className={styles.label}>
      {upperCase ? text.toUpperCase() : text}
      {optionText && <span className={styles.optional}> (optional)</span>}
      {tooltipText && <QuestionIconTooltip placement="bottomLeft" title={tooltipText} />}
      {warningText && <WarningIconTooltip placement="bottomLeft" title={warningText} />}
      {docLink}
    </div>
  )
}

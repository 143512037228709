//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint  no-useless-escape: 0 */

import {
  ExcludeCompleters,
  basicCompleters as basic,
  buildEnvironmentCompleters,
  buildProjectCompleters,
  buildProjectResourceCompleters,
  buildResourceCompleters,
  buildSnippets,
  templateFilters,
} from 'components/Editor/autocompleters'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Ace } from 'ace-builds'
import AceEditor from 'react-ace'
import { GetParameterNames } from 'data/parameter/actions'
import { GetTemplateNames } from 'data/template/actions'
import { Spin } from 'antd'
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools'

// These must be imported after AceEditor
// import-sort-ignore
import 'ace-builds/src-noconflict/theme-tomorrow'
import './resourcePatternAceDefinitions'
import './templateAceDefinitions'
import './pythonRegexAceDefinitions'
import './editor.scss'

const filterRegex = /(?:\|)\s*(\w+)\s*(:\s*(\w+|\[.*\]|\{.*\}))?/g
const liquidRegex = /(?:{{|{%)[^}]*}}$/
const snippetRegex = /{%\s*([\s\S]*?)\s*%}/g
const parameterRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*parameters(?:\.\s*|\['))(?<param>[^\[\].\s'"]*)(?:[\s'"]+\]?)?\s*}}/
const projectsRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*projects(?:\.\s*|\['))(?<project>[^\[\].\s'"]*)(?:[\s'"]+\]?)?\s*}}/
const templatesRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*templates(?:\.\s*|\['))(?<template>[^\[\].\s'"]*)(?:[\s'"]+\]?)?\s*}}/
const environmentRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*environment(?:\.\s*|\['))(?<environment>[^\[\].\s'"]*)(?:[\s'"]+\]?)?\s*}}/
const projectNameRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*projects(?:\.\s*|\[')(?<project>[^.\[\]'"\s]+)(?:\.\s*|\['))(?<property>[^.\[\]'"\s]*)\s*['"]?(?:[\s'"]+\]?)?\s*}}/
const projectTemplateRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*projects(?:\.\s*|\[')(?<project>[^.\[\]'"\s]+)(?:\.\s*|\[')templates(?:\.\s*|\['))(?<template>[^.\[\]'"\s]*)\s*['"]?(?:[\s'"]+\]?)?\s*}}/
const projectParameterRegex =
  /{{\s*cloudtruth(?:\[[^\]]*\])?(?:\.\s*projects(?:\.\s*|\[')(?<project>[^.\[\]'"\s]+)(?:\.\s*|\[')parameters(?:\.\s*|\['))(?<parameter>[^.\[\]'"\s]*)\s*['"]?(?:[\s'"]+\]?)?\s*}}/

interface Props {
  body: maybe<string>
  className?: string
  readOnly?: boolean
  wrapText?: boolean
  onChange?: (newBody: string) => void
  exclude?: ExcludeCompleters
  includeOnly?: string[]
  mode?: string
  showGutter?: boolean
  highlightActiveLine?: boolean
  loadParams?: boolean
  loadTemps?: boolean
}

export default function Editor(props: Props) {
  const {
    body,
    className,
    exclude,
    includeOnly,
    onChange,
    readOnly,
    wrapText,
    showGutter,
    highlightActiveLine,
    mode = 'template',
    loadParams,
    loadTemps,
  } = props

  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const currentProject = useAppSelector(getCurrentProject)
  const dispatch = useAppDispatch()
  const templateCache = !!currentProject?.cachedTemplates
  const parameterCache = !!currentProject?.cachedParameters

  const [loadParameters, setLoadParameters] = useState<nullable<string>>(
    loadParams ? currentProject!.id : null
  )
  const [loadTemplates, setLoadTemplates] = useState<nullable<string>>(
    loadTemps ? currentProject!.id : null
  )

  const [loading, setLoading] = useState(
    (loadParams && !parameterCache) || (loadTemps && !templateCache)
  )

  useEffect(() => {
    const paramNames = new Promise((res) => {
      if (loadParameters && !parameterCache) {
        setLoading(true)
        dispatch(GetParameterNames(loadParameters)).then(() => res({}))
      }
      res({})
    })

    const tempNames = new Promise((res) => {
      if (loadTemplates && !templateCache) {
        setLoading(true)
        dispatch(GetTemplateNames(loadTemplates)).then(() => res({}))
      }
    })

    Promise.all([paramNames, tempNames]).then(() => {
      setLoading(false)
    })
  }, [dispatch, loadParameters, loadTemplates, templateCache, parameterCache])

  const basicCompleters = useCallback((): Ace.Completion[] => {
    return basic(currentProject, exclude, includeOnly)
  }, [exclude, includeOnly, currentProject])

  const projectParameterCompleters = useCallback(
    (match: RegExpMatchArray) => {
      return buildProjectResourceCompleters(
        match,
        projects,
        'parameters',
        setLoadParameters,
        match[1]
      )
    },
    [projects]
  )

  const projectTemplateCompleters = useCallback(
    (match: RegExpMatchArray) => {
      return buildProjectResourceCompleters(
        match,
        projects,
        'templates',
        setLoadTemplates,
        match[1]
      )
    },
    [projects]
  )

  const templateCompleters = useCallback(
    (match: RegExpMatchArray) => {
      return buildResourceCompleters(match, projects, 'templates', currentProject)
    },
    [projects, currentProject]
  )

  const projectCompleters = useCallback(
    (match: RegExpMatchArray) => {
      return buildResourceCompleters(match, projects, 'project', currentProject)
    },
    [projects, currentProject]
  )

  const parameterCompleters = useCallback(
    (match: RegExpMatchArray) => {
      return buildResourceCompleters(match, projects, 'parameters', currentProject)
    },
    [projects, currentProject]
  )
  const environmentCompleters = useCallback((match: RegExpMatchArray) => {
    return buildEnvironmentCompleters(match)
  }, [])

  const combinedClasses = useMemo((): string => {
    const classes: string[] = []
    if (className) {
      classes.push(className)
    }
    if (readOnly) {
      classes.push('readonly')
    }
    return classes.join(' ')
  }, [className, readOnly])

  useEffect(() => {
    if (!readOnly) {
      const completer: Ace.Completer = {
        identifierRegexps: [/[a-zA-Z_0-9\.\$\-\u00A2-\uFFFF]|[\b]/],
        getCompletions: (
          _editor: Ace.Editor,
          session: Ace.EditSession,
          pos: Ace.Point,
          _prefix: string,
          callback: Ace.CompleterCallback
        ): void => {
          const precursor = session.getTextRange({
            start: { ...pos, column: 0 },
            end: { ...pos, column: Number.MAX_VALUE },
          } as Ace.Range)

          if (mode === 'template' && precursor.match(filterRegex)) {
            callback(null, templateFilters)
          } else if (precursor.match(projectTemplateRegex)) {
            callback(null, projectTemplateCompleters(precursor.match(projectTemplateRegex)!))
          } else if (precursor.match(projectParameterRegex)) {
            callback(null, projectParameterCompleters(precursor.match(projectParameterRegex)!))
          } else if (precursor.match(projectNameRegex)) {
            callback(null, buildProjectCompleters(precursor.match(projectNameRegex)!))
          } else if (precursor.match(environmentRegex)) {
            callback(null, environmentCompleters(precursor.match(environmentRegex)!))
          } else if (precursor.match(projectsRegex)) {
            callback(null, projectCompleters(precursor.match(projectsRegex)!))
          } else if (precursor.match(templatesRegex)) {
            callback(null, templateCompleters(precursor.match(templatesRegex)!))
          } else if (precursor.match(parameterRegex)) {
            callback(null, parameterCompleters(precursor.match(parameterRegex)!))
          } else if (precursor.match(liquidRegex)) {
            callback(null, basicCompleters())
          } else if (snippetRegex) {
            callback(null, buildSnippets())
          }
        },
      }

      setCompleters([completer])
    }
  }, [
    projectParameterCompleters,
    projectTemplateCompleters,
    basicCompleters,
    parameterCompleters,
    projectCompleters,
    templateCompleters,
    readOnly,
    mode,
    body,
    environmentCompleters,
  ])

  if (loading) {
    return <Spin className="loading" size="small" />
  }

  return (
    <AceEditor
      mode={mode}
      theme="tomorrow" // changing the theme requires changes in editor.scss to '.ace_<theme>'
      className={combinedClasses}
      enableSnippets={!readOnly && mode === 'template'}
      enableBasicAutocompletion={!readOnly}
      enableLiveAutocompletion={!readOnly}
      onChange={onChange}
      value={loading ? '' : body || ''}
      editorProps={{ $blockScrolling: true }}
      setOptions={{ enableMultiselect: true }}
      style={{ width: '100%', height: '100%' }}
      wrapEnabled={!!wrapText}
      readOnly={!!readOnly}
      showGutter={showGutter}
      highlightActiveLine={highlightActiveLine}
    />
  )
}

export const exportsForTesting = {
  filterRegex,
  liquidRegex,
  projectParameterRegex,
  projectTemplateRegex,
  projectsRegex,
}

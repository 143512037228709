import { ServiceAccount, ServiceAccountAPIKey } from 'gen/cloudTruthRestApi'

import { HourGlassTooltip } from 'components/HourGlassTooltip'
import React from 'react'
import { ResizableTitle } from 'components/Table/ResizableTitle'
import { Table } from 'components/Table'
import { TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { formatDateTime } from 'lib/dateHelpers'
import { makeTitleCase } from 'lib/valueHelpers'
import styles from './ServiceAccountList.module.scss'

interface Props {
  keys: ServiceAccount['keys']
}

export function ServiceAccountKeysTable(props: Props) {
  const { keys } = props

  const color = (key: ServiceAccountAPIKey) => {
    const days = dayjs(key.expiry_date).diff(dayjs(), 'day')
    const hours = days === 0 ? dayjs(key.expiry_date).diff(dayjs(), 'hour') : null
    const minutes =
      hours === 0 && days === 0 ? dayjs(key.expiry_date).diff(dayjs(), 'minute') : null

    const seconds =
      minutes === 0 && days === 0 && hours === 0
        ? dayjs(key.expiry_date).diff(dayjs(), 'second')
        : null

    if (seconds) {
      return seconds < 1 ? 'red' : '#faad14'
    }

    if (minutes) {
      return minutes < 1 ? 'red' : '#faad14'
    }

    if (hours) {
      return hours < 1 ? 'red' : '#faad14'
    }

    return days! < 1 ? 'red' : days! <= 5 ? '#faad14' : ''
  }

  const toolTip = (key: ServiceAccountAPIKey) => {
    const days = dayjs(key.expiry_date).diff(dayjs(), 'day')
    const hours = days === 0 ? dayjs(key.expiry_date).diff(dayjs(), 'hour') : null
    const minutes =
      hours === 0 && days === 0 ? dayjs(key.expiry_date).diff(dayjs(), 'minute') : null

    const seconds =
      minutes === 0 && days === 0 && hours === 0
        ? dayjs(key.expiry_date).diff(dayjs(), 'second')
        : null

    if (seconds) {
      return seconds < 1
        ? `Warning: This value expired ${Math.abs(seconds)} second${
            Math.abs(seconds) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${seconds} second${seconds > 1 ? 's' : ''}`
    }

    if (minutes) {
      return minutes < 1
        ? `Warning: This value expired ${Math.abs(minutes)} minute${
            Math.abs(minutes) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${minutes} minute${minutes > 1 ? 's' : ''}`
    }

    if (hours) {
      return hours < 1
        ? `Warning: This value expired ${Math.abs(hours)} hour${
            Math.abs(hours) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${hours} hour${hours > 1 ? 's' : ''}`
    }

    return days < 0
      ? `Warning: This value expired ${Math.abs(days)} day${Math.abs(days) > 1 ? 's' : ''} ago!`
      : days && days > 0 && days && days <= 5
      ? `Warning: This value is set to expire in ${days} day${days > 1 ? 's' : ''}`
      : `This value is set to expire in ${days} days.`
  }

  const columns: TableColumnsType<ServiceAccountAPIKey> = [
    {
      title: 'PREFIX',
      key: 'prefix',
      ellipsis: true,
      dataIndex: 'prefix',

      render: (_, key) => (
        <div className={styles.keyNameContainer}>
          <span className={styles.keysIcon}>
            {key.expiry_date && (
              <HourGlassTooltip centerSpan title={toolTip(key)} color={color(key)} />
            )}
          </span>
          <span>{key.prefix}</span>
        </div>
      ),
    },
    {
      title: 'REVOKED',
      key: 'revoked',
      dataIndex: 'revoked',
      ellipsis: true,

      render: (_, key) => <>{makeTitleCase(String(!!key.revoked))}</>,
    },
    {
      title: 'CREATED AT',
      key: 'created',
      dataIndex: 'created',
      ellipsis: true,

      render: (_, key) => <>{formatDateTime(key.created)}</>,
    },
    {
      title: 'EXPIRES AT',
      key: 'created',
      dataIndex: 'created',
      ellipsis: true,

      render: (_, key) => <>{formatDateTime(key.expiry_date)}</>,
    },
  ]

  return (
    <div className={styles.container}>
      <Table
        scroll={{ x: columns?.length > 6 ? 1300 : undefined }}
        columns={columns}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        caption={<h3>Keys</h3>}
        tableLayout="fixed"
        dataSource={keys}
        rowClassName={styles.row}
        rowKey={(key: ServiceAccountAPIKey) => key.prefix}
        locale={{
          emptyText: (
            <div>
              <h4 className={styles.emptyHeader}>
                You don't have any API tokens stored in CloudTruth
              </h4>
              <p className={styles.emptyParagraph}>Create an API token to store and use.</p>
            </div>
          ),
        }}
      />
    </div>
  )
}

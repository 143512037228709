//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { ParameterRuleType } from 'data/parameter/actions'
import { ParameterTypeRule } from 'gen/cloudTruthRestApi'

export type GenericRuleType = ParameterRuleType | ParameterTypeRule

interface RuleUpdates {
  createRules: GenericRuleType[]
  updateRules: GenericRuleType[]
  deleteRules: GenericRuleType[]
}

export const buildRuleUpdates = (
  initialRules: GenericRuleType[],
  newRules: GenericRuleType[]
): RuleUpdates => {
  const createRules: ParameterRuleType[] = [],
    updateRules: ParameterRuleType[] = [],
    deleteRules: ParameterRuleType[] = [],
    newRuleIds: string[] = []
  // eslint-disable-next-line no-prototype-builtins
  newRules = newRules.filter((rule) => rule.hasOwnProperty('id'))

  newRules.forEach((rule: ParameterRuleType) => {
    if (rule.id.startsWith('new-')) {
      const existingType = initialRules.find((r) => r.type === rule.type)
      if (existingType) {
        newRuleIds.push(existingType.id)
        updateRules.push({ ...existingType, constraint: rule.constraint })
      } else {
        createRules.push(rule)
      }
    } else {
      newRuleIds.push(rule.id)

      const existingRule = initialRules.find((r) => r.id === rule.id)!

      if (existingRule.constraint !== rule.constraint) {
        updateRules.push(rule)
      } else if (existingRule.type !== rule.type) {
        deleteRules.push(rule)
        createRules.push(rule)
      }
    }
  })

  initialRules.forEach((rule) => {
    if (!newRuleIds.includes(rule.id)) {
      deleteRules.push(rule)
    }
  })

  return { createRules, updateRules, deleteRules }
}

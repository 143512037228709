import { Label, LabelProps } from './Label'
import { NamePath, Rule } from 'rc-field-form/lib/interface'
import React, { PropsWithChildren } from 'react'

import { Item } from './Form'
import styles from './LabeledInputItem.module.scss'

interface Props {
  name: NamePath
  label: LabelProps | string
  nocolon?: boolean
  required?: string | boolean
  rules?: Rule[]
  style?: Record<string, any>
  checkbox?: boolean
  autofocus?: boolean
  tooltipText?: string
  warningText?: string
}

export function LabeledInputItem(props: PropsWithChildren<Props>) {
  const {
    checkbox,
    children,
    name,
    label,
    nocolon,
    required,
    rules,
    style,
    autofocus,
    tooltipText,
    warningText,
  } = props

  const labelProps = (value: LabelProps | string): LabelProps => {
    const addl = nocolon ? '' : ':'
    return typeof value === 'string'
      ? { text: `${value}${addl}`, optionText: checkbox ? false : !required }
      : { optionText: checkbox ? false : !required, ...value, text: `${value.text}${addl}` }
  }

  const buildRules = (): Rule[] => {
    const newRules = rules || []

    if (!required) {
      return newRules
    }

    newRules.push(
      typeof required === 'string'
        ? { required: true, message: <div className={styles.requiredMessage}>{required}</div> }
        : { required: true }
    )

    return newRules
  }

  const itemProps: any = {
    name: name,
    rules: buildRules(),
    label: <Label {...labelProps(label)} tooltipText={tooltipText} warningText={warningText} />,
    style: style,
    className: checkbox ? [styles.item, styles.checkbox].join(' ') : styles.item,
    autoFocus: autofocus,
  }

  if (checkbox) {
    itemProps['valuePropName'] = 'checked'
  }

  return <Item {...itemProps}>{children}</Item>
}

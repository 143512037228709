import React, { useState } from 'react'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CopyParameter } from '../CopyParameter/CopyParameter'
import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteParameter } from 'data/parameter/actions'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { NoDeleteNoticeModal } from '../Modals'
import { UpdateParameterSettings } from '../UpdateParameterSettings'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getCurrentProject } from 'data/project/selectors'
import { getPolicy } from 'data/membership/selectors'
import { useProjectParams } from 'router/customHooks'
import { useToast } from 'hooks'

const EDIT_PARAMETER_SETTINGS = 'editParameterSettings'
const COPY_PARAMETER = 'copyParameter'
const DELETE_PARAMETER = 'deleteParameter'

interface Props {
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>
}

export function ParameterDetailsDropdown(props: Props) {
  const { setIsDeleting } = props
  const paramId = useProjectParams().parameterId!
  const parameter = parameterEntitySelectors.selectById(useSelectParameters(), paramId)!
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const currentProject = useAppSelector(getCurrentProject)
  const dispatch = useAppDispatch()
  const [copyVisible, setCopyVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settingsModalVisible, setSettingsModalVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const isOverride = !!parameter?.overrides && currentProject?.name === parameter.project_name

  const deleteParameter = () => {
    setLoading(true)
    setIsDeleting(true)

    const handleError = (error: string) => {
      errorToast(error)
      setDeleteVisible(false)
    }

    dispatch(DeleteParameter(parameter.id)).then(({ error }: CustomThunk) => {
      error ? handleError(error.message) : successToast('Parameter successfully deleted')
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case EDIT_PARAMETER_SETTINGS: {
        setSettingsModalVisible(true)
        break
      }

      case COPY_PARAMETER: {
        setCopyVisible(true)
        break
      }

      case DELETE_PARAMETER: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canAdministrate, [{ key: COPY_PARAMETER, label: 'Copy Parameter' }]),
    ...conditionalArrayItem(canContribute, [
      { key: EDIT_PARAMETER_SETTINGS, label: 'Edit Parameter Settings' },
      {
        key: DELETE_PARAMETER,
        label: isOverride ? 'Remove Parameter Override' : 'Delete Parameter',
        danger: true,
      },
    ]),
  ]

  return (
    <div>
      <NoDeleteNoticeModal
        visible={errorMessage !== ''}
        closeModal={() => setErrorMessage('')}
        errorMessage={errorMessage}
      />

      <UpdateParameterSettings
        paramId={paramId}
        visible={settingsModalVisible}
        closeModal={() => setSettingsModalVisible(false)}
      />

      <CopyParameter
        visibleFromProps={copyVisible}
        parameter={parameter}
        closeOverrideModal={() => setCopyVisible(false)}
      />

      <DeleteConfirmModal
        loading={loading}
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deleteParameter}
        subject={`"${parameter.name}"`}
        isOverride={isOverride}
        removePronoun
      />

      <MoreIconDropdown color="black" menu={{ items, onClick }} />
    </div>
  )
}

import {
  NameColumn,
  Table,
  ValueColumn,
  defaultOrder,
  emptyParametersMessage,
} from 'components/Table'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'

import { Parameter } from 'gen/cloudTruthRestApi'
import { TableProps } from 'antd'
import { getParameterCount } from 'data/parameter/selectors'
import { idFromUrl } from 'data/dataUtils'
import styles from './CompareTableTitle.module.scss'
import { useAppSelector } from 'data/hooks'

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

interface Props {
  loading: boolean
  checkDiff: boolean
  searchTerm: string
  fetchError: boolean
  parameters: Parameter[]
  environmentSelectValues: string[]
  maskSecret: boolean

  setOrdering: (order?: string) => void
  setPage: React.Dispatch<React.SetStateAction<{ pageNumber: number; project: string }>>
  getPaginatedParameters: () => void
  setPageSize: (pageSize: number) => void
  setLocalPageSize: (pageSize: number) => void
  pageSize: number
  page: { pageNumber: number; project: string }
}

export function CompareTable(props: Props) {
  const {
    checkDiff,
    environmentSelectValues,
    fetchError,
    loading,
    parameters,
    searchTerm,
    setPage,
    setOrdering,
    pageSize,
    setPageSize,
    setLocalPageSize,
    maskSecret,
  } = props

  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const { getQuery, setQuery, removeQuery } = useQuery()
  const parameterCount = useAppSelector(getParameterCount)
  const project = useAppSelector(getCurrentProject)!
  const sortBy = getQuery(QueryParamName.SortBy)

  const pageNumberFromParams = () => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : undefined
  }

  const onTableChange: TableProps<Parameter>['onChange'] = (_, __, sorter) => {
    const { order, columnKey } = (sorter as any) || {
      order: null,
      columnKey: null,
    }

    if (order) {
      order === 'descend' ? setOrdering(`-${columnKey}`) : setOrdering(`${columnKey}`)
      setQuery(QueryParamName.SortBy, decodeURIComponent(`${columnKey}+${order}`))
    } else {
      setOrdering(undefined)
      removeQuery(QueryParamName.SortBy)
    }
  }

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setQuery(QueryParamName.Page, pageNumber.toString())
    setQuery(QueryParamName.PageSize, pageSize.toString())
    setPage((prev) => ({ ...prev, pageNumber: pageNumber }))
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const initialColumns: TableProps<Parameter>['columns'] = useMemo(() => {
    return [
      {
        title: 'NAME',
        key: 'name',
        ellipsis: true,
        width: 200,
        defaultSortOrder: defaultOrder(sortBy, 'name'),
        sorter: (a, b) => {
          const firstName = a!.name.toUpperCase()
          const secondName = b!.name.toUpperCase()

          return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
        },
        render: (_value, parameter: Parameter) => (
          <NameColumn
            inheritedFrom={
              project.url === parameter.project
                ? undefined
                : projectEntities[idFromUrl(parameter.project)]!.name
            }
            parameter={parameter}
          />
        ),
      },
    ]
  }, [sortBy, project.url, projectEntities])

  const extendedColumns = useMemo(() => {
    if (environmentSelectValues.length > 0) {
      const compareValueColumns: TableProps<Parameter>['columns'] = environmentSelectValues.map(
        (url, key) => {
          const { name } = environmentEntities[idFromUrl(url)]!
          return {
            title: `ENVIRONMENT VALUE: ${name}`,

            width: 200,
            dataIndex: 'values',
            render: (_value, parameter: Parameter) => {
              return (
                <ValueColumn
                  maskSecrets={maskSecret}
                  inherited={project.url !== parameter.project}
                  key={key}
                  parameter={parameter}
                  envUrl={url}
                />
              )
            },
          }
        }
      )

      const keyNameColumn = initialColumns[0]
      keyNameColumn.fixed = 'left'

      return [initialColumns[0], ...compareValueColumns]
    } else {
      return initialColumns
    }
  }, [initialColumns, environmentSelectValues, environmentEntities, project.url, maskSecret])

  const [columns, setColumns] = useState(initialColumns)

  useEffect(() => {
    extendedColumns && setColumns(extendedColumns)
  }, [extendedColumns])

  return (
    <div>
      <Table
        caption={
          <div className={styles.tableTitleContainer}>
            <h3>{`Project: ${project.name}`}</h3>
          </div>
        }
        scroll={{ x: columns!.length > 6 ? 1300 : undefined }}
        columns={resizableColumns(columns, setColumns)}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        onChange={onTableChange}
        pagination={{
          total: parameterCount,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: pageNumberFromParams() ? pageNumberFromParams() : 1,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : parameters}
        rowKey={(parameter: Parameter) => parameter.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div>
                  <h4>
                    {checkDiff
                      ? 'There are no differences in this project between the selected environments.'
                      : emptyParametersMessage(searchTerm, [], fetchError)}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { AwsPush, AzureKeyVaultPush } from 'gen/cloudTruthRestApi'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import { DeleteAwsPush, SyncAwsPush } from 'data/actions/awsPush/actions'
import { DeleteAzureKeyVaultPush, SyncAzureKeyVaultPush } from 'data/actions/akvPush/actions'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionViews } from 'components/Actions/ActionContainer'
import { DeleteConfirmModal } from 'components/Modals'
import { EditPush } from '../EditPush'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { pushIds } from 'components/Actions/utils'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

const DELETE_PUSH = 'deletePush'
const UPDATE_PUSH = 'updatePush'
const SYNC_PUSH = 'syncPush'

interface Props {
  refreshPush: () => void
  color?: string
  push: AwsPush | AzureKeyVaultPush
  redirect?: boolean
  actionType: ActionPushEnum
}

export function PushDetailsDropdown(props: Props) {
  const { refreshPush, color, push, redirect, actionType } = props
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { integrationId, actionId } = pushIds(push.url, actionType)

  const { goToActionsRoute } = useHistoryPush()

  const deletePush = () => {
    setLoading(true)

    const handleError = (error: string) => {
      errorToast(error)
      setDeleteVisible(false)
    }

    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(DeleteAwsPush({ integrationId: integrationId!, actionId: actionId! }))
          .then(({ error }: CustomThunk) => {
            if (error) {
              handleError(error.message)
              return
            }
          })

          .then(() => {
            successToast('Action successfully deleted')

            if (redirect) {
              goToActionsRoute(ActionViews.AkvPush)
            }
          })
        break

      case ActionEnum.AzureKeyVaultPush:
        dispatch(DeleteAzureKeyVaultPush({ integrationId: integrationId!, actionId: actionId! }))
          .then(({ error }: CustomThunk) => {
            if (error) {
              handleError(error.message)
              return
            }
          })

          .then(() => {
            successToast('Action successfully deleted')

            if (redirect) {
              goToActionsRoute(ActionViews.Push)
            }
          })
        break

      default:
        break
    }
  }

  const syncPush = () => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(SyncAwsPush({ integrationId: integrationId!, actionId: actionId! })).then(
          ({ error }: TypedThunk<string>) => {
            if (error) {
              errorToast(error.message)
              return
            }
            successToast('Sync started - check tasks for status.')
            refreshPush()
          }
        )

        break

      case ActionEnum.AzureKeyVaultPush:
        dispatch(
          SyncAzureKeyVaultPush({ integrationId: integrationId!, actionId: actionId! })
        ).then(({ error }: TypedThunk<string>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          successToast('Sync started - check tasks for status.')
          refreshPush()
        })
        break

      default:
        break
    }
  }

  const menuClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case UPDATE_PUSH: {
        setEditVisible(true)
        break
      }

      case DELETE_PUSH: {
        setDeleteVisible(true)
        break
      }
      case SYNC_PUSH: {
        syncPush()
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canContribute, [
      {
        key: UPDATE_PUSH,
        label: 'Edit',
      },
      {
        key: SYNC_PUSH,
        label: 'Sync',
      },
      {
        key: DELETE_PUSH,
        label: 'Delete',
        danger: true,
      },
    ]),
  ]

  return (
    <div>
      <EditPush
        visible={editVisible}
        push={push!}
        onClose={() => setEditVisible(false)}
        actionType={actionType}
      />
      <DeleteConfirmModal
        loading={loading}
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deletePush}
        removePronoun={true}
        subject={`"${push.name}"`}
        isPush={true}
      />
      <MoreIconDropdown menu={{ items, onClick: menuClick }} color={color} />
    </div>
  )
}

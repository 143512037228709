import {
  CheckAzureKeyVaultIntegrationStatus,
  GetAzureKeyVaultIntegration,
} from 'data/integration/akvIntegration/actions'
import { Checkbox, List, Typography } from 'antd'
import React, { useCallback, useState } from 'react'

import { AkvStatus } from './AkvStatus/AkvStatus'
import { AzureKeyVaultIntegration } from 'gen/cloudTruthRestApi'
import { DropdownMenu } from './AkvDropdown'
import styles from './AkvPage.module.scss'
import { useAppDispatch } from 'data/hooks'

interface Props {
  integration: AzureKeyVaultIntegration
}

export function AkvListItem(props: Props) {
  const { integration } = props
  const [checking, setChecking] = useState(false)
  const [closePopover, setClosePopover] = useState(false)

  const dispatch = useAppDispatch()

  const checkIntegration = useCallback(() => {
    setClosePopover(false)
    setChecking(true)
    if (integration.status === 'checking') {
      dispatch(GetAzureKeyVaultIntegration(integration.id)).then(() => {
        setChecking(false)
      })
    } else {
      dispatch(CheckAzureKeyVaultIntegrationStatus(integration)).then(() => {
        setChecking(false)
      })
    }
  }, [integration, dispatch, setClosePopover])

  return (
    <List.Item
      extra={
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <AkvStatus
            akvIntegration={integration}
            checkIntegration={checkIntegration}
            checking={checking}
            setChecking={setChecking}
            closePopover={closePopover}
            setClosePopover={setClosePopover}
          />
          <DropdownMenu akv={integration} checkIntegration={checkIntegration} />
        </div>
      }
    >
      <List.Item.Meta
        title={`${integration.vault_name} - ${integration.tenant_id}`}
        description={
          <Typography.Paragraph>
            {integration.description}
            <br />
            <br />
            <div className={styles.detailsContainer}>
              <Checkbox disabled checked={integration.writable} className={styles.writable}>
                Writable
              </Checkbox>

              {integration.resource_tags && Object.values(integration.resource_tags).length > 0 && (
                <div className={styles.resourceTagContainer}>
                  <div className={styles.resourceTitle}>Resource Tags</div>
                  <table className={styles.table}>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                    </tr>

                    {Object.entries(integration.resource_tags).map(([key, value], index) => (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </div>
          </Typography.Paragraph>
        }
      />
    </List.Item>
  )
}

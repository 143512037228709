//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AzureKeyVaultIntegrationRootState, akvIntegrationAdapter } from './reducer'

import { RootState } from 'data/store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectAvkIntegration = (state: RootState) => state.awsIntegration

export const getCurrentAwsIntegration = createSelector(selectAvkIntegration, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const akvIntegrationEntitySelectors = akvIntegrationAdapter.getSelectors()
export const useSelectAkvIntegrations = (): AzureKeyVaultIntegrationRootState =>
  useSelectState().akvIntegration

import { CustomThunk, idFromUrl } from 'data/dataUtils'
import React, { useEffect, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useHistoryPush, useProjectParams } from 'router/customHooks'

import { BackToList } from 'components/BackToList'
import { ChangeHistory } from './ChangeHistory'
import { EnvironmentValueDetails } from './EnvironmentValueDetails'
import { GetParameter } from 'data/parameter/actions'
import { PageLoading } from 'components/PageLoading'
import { ParameterDetails } from './ParameterDetails'
import { ParameterEnvironmentsTree } from './ParameterEnvironmentsTree'
import { Tabs } from 'antd'
import { TemplateList } from './TemplateList'
import { getCurrentProject } from 'data/project/selectors'
import styles from './ParameterDetailsPage.module.scss'
import { useLocation } from 'react-router-dom'
import { useToast } from 'hooks'

const TEMPLATE_LIST_KEY = 'templateList'
const HISTORY_KEY = 'history'
const ENVIRONMENT_OVERRIDES_KEY = 'environmentOverrides'

export function ParameterDetailsPage() {
  const paramId = useProjectParams().parameterId!
  const parameter = parameterEntitySelectors.selectById(useSelectParameters(), paramId)!
  const currentProject = useAppSelector(getCurrentProject)!
  const subscribeToAsyncPulls = useAppSelector((state) => state.session.subscribeToAsyncPulls)

  const baseEnv = useAppSelector(getBaseEnvironment)
  const { id: projectId } = useAppSelector(getCurrentProject)!
  const currentEnvironment = useAppSelector(getCurrentEnvironment)!
  const hasChangedProjects = parameter?.url.match(/projects\/(.*)\/parameters/)![1] !== projectId
  const { goToParameterRoute } = useHistoryPush()
  const dispatch = useAppDispatch()
  const { hash } = useLocation()
  const { errorToast } = useToast()

  const [showSecretValues, setShowSecretValues] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [tab, setTab] = useState(hash.substring(1) || ENVIRONMENT_OVERRIDES_KEY)

  const [selectedEnvironment, setSelectedEnvironment] = useState(currentEnvironment.url)

  const [showSecretsLoading, setShowSecretsLoading] = useState(false)

  const selectEnv = useSelectEnvironments()
  const environments = environmentEntitySelectors.selectAll(selectEnv)!

  const [onSelectLoading, setOnSelectLoading] = useState(false)

  const environment = useMemo(() => {
    return idFromUrl(selectedEnvironment)
  }, [selectedEnvironment])

  const envArgument = environments.length > 10 ? environment : undefined

  // this state holds the "show evaluated" state for each environment value
  const [showEvaluated, setShowEvaluated] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (subscribeToAsyncPulls) {
      dispatch(
        GetParameter({
          paramId,
          projectId,
          maskSecret: !showSecretValues,
          environment: envArgument,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToAsyncPulls])

  useEffect(() => {
    // Checking for parameter allows us to use cached parameter if it's available
    if (parameter && hasChangedProjects) {
      goToParameterRoute(null)
    }

    if (isDeleting) {
      return
    }

    dispatch(
      GetParameter({ paramId, projectId, maskSecret: !showSecretValues, environment: envArgument })
    ).then(() => {
      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChangedProjects])

  useEffect(() => {
    if (!loading && !parameter) {
      goToParameterRoute(projectId)
    }
  }, [loading, goToParameterRoute, parameter, projectId])

  if (!loading && !parameter) {
    return null
  }

  const toggleSecrets = () => {
    setShowSecretsLoading(true)

    const done = () => {
      setShowSecretValues((prev) => !prev)
      setShowSecretsLoading(false)
    }

    const toggleSecretsByParameter = () => {
      dispatch(
        GetParameter({
          paramId: parameter.id,
          projectId: currentProject.id,
          maskSecret: showSecretValues,
          evaluate: Object.values(parameter.values).some((v) => v?.secret && v.interpolated),
        })
      ).then(({ _, error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
        }

        done()
      })
    }

    toggleSecretsByParameter()
  }

  const overrideCount = () => {
    if (parameter) {
      return Object.entries(parameter.values).filter(([env, value]) => {
        return env === value?.environment && env !== baseEnv.url
      })?.length
    }

    return 0
  }

  const onLinkClick = () => goToParameterRoute(null)

  const overridesTab = (
    <div className={styles.environmentOverridesTab}>
      <ParameterEnvironmentsTree
        parameter={parameter}
        selectedEnvironment={selectedEnvironment}
        setSelectedEnvironment={setSelectedEnvironment}
        setShowSecretValues={toggleSecrets}
        showSecretValues={showSecretValues}
        showSecretLoading={showSecretsLoading}
        setShowEvaluated={setShowEvaluated}
        showEvaluated={showEvaluated}
        onSelect={(environment: string) => {
          setOnSelectLoading(true)
          dispatch(
            GetParameter({
              paramId,
              projectId,
              maskSecret: !showSecretValues,
              environment: idFromUrl(environment),
            })
          ).then(() => {
            setOnSelectLoading(false)
          })
        }}
      />

      <EnvironmentValueDetails
        envUrl={selectedEnvironment}
        onSelectLoading={onSelectLoading}
        resetShow={() => setShowSecretValues(false)}
        overrideCount={overrideCount()}
        showSecretValues={showSecretValues}
        toggleSecrets={toggleSecrets}
        showEvaluated={showEvaluated[selectedEnvironment]}
      />
    </div>
  )

  const templateListTab = <TemplateList parameter={parameter} />

  const historyTab = <ChangeHistory paramId={parameter?.id} projectId={projectId} />

  const items = [
    { key: ENVIRONMENT_OVERRIDES_KEY, label: 'Environment Overrides', children: overridesTab },
    {
      key: TEMPLATE_LIST_KEY,
      label: 'Template List',
      children: templateListTab,
    },
    {
      key: HISTORY_KEY,
      label: <span data-cy="history-tab">History</span>,
      children: historyTab,
    },
  ]

  return (
    <div>
      {loading || hasChangedProjects ? (
        <PageLoading />
      ) : (
        <div className={styles.container}>
          <BackToList onLinkClick={onLinkClick} text="Parameter List" />
          <ParameterDetails setIsDeleting={setIsDeleting} />
          <Tabs
            activeKey={tab}
            onChange={(key) => {
              setTab(key)
              window.location.hash = key
            }}
            items={items}
          />
        </div>
      )}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { SearchResults, jmesPathSearch } from 'lib/jmesPathHelpers'

import { FormInstance } from 'antd'
import { IntegrationNode } from 'gen/cloudTruthRestApi'
import { JMESPathSelector } from './JMESPathSelector'
import { JMES_PATH_FIELD } from '../ReferencedParameter'
import { ReferencedValue } from './ReferencedValue'

interface Props {
  integrationFile: IntegrationNode
  form: FormInstance
  error: maybe<string>
  initialJmesPath?: nullable<string>
}

export function JMESPathDetails(props: Props) {
  const { integrationFile, form, error, initialJmesPath } = props

  const [jmesResult, setJmesResult] = useState<nullable<SearchResults>>(null)

  useEffect(() => {
    if (integrationFile.content_keys) {
      const jmesPath = form.getFieldValue(JMES_PATH_FIELD)

      const initialJmesResult = error
        ? { parseError: error }
        : jmesPath
        ? jmesPathSearch(integrationFile, jmesPath)
        : null

      setJmesResult(initialJmesResult)

      return () => form.setFieldsValue({ [JMES_PATH_FIELD]: '' })
    }
  }, [form, error, integrationFile])

  if (!integrationFile.content_keys) {
    return null
  }

  const onUpdate = () => {
    const jmesPath = form.getFieldValue(JMES_PATH_FIELD)
    setJmesResult(jmesPathSearch(integrationFile, jmesPath))
  }

  return (
    <>
      <JMESPathSelector
        form={form}
        onUpdate={onUpdate}
        hasError={!!jmesResult?.parseError}
        initialJmesPath={initialJmesPath}
      />
      <ReferencedValue searchResults={jmesResult} />
    </>
  )
}

import decode from 'jwt-decode'

export const ctJwtNamespace = 'https://cloudtruth.com/'

export interface DecodedToken {
  exp?: number
  sub: string
  [key: string]: any
}

export function getTokenExpirationDate(jwt: JWT): nullable<Date> {
  if (!jwt) {
    return null
  }

  const decoded: DecodedToken = decode(jwt)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp)
  return date
}

export function isTokenExpired(jwt: JWT): boolean {
  const date = getTokenExpirationDate(jwt)
  const offsetSeconds = 0

  if (date === null) {
    return false
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
}

export function decodeToken(jwt: JWT): nullable<DecodedToken> {
  return jwt ? decode<DecodedToken>(jwt) : null
}

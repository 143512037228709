import React, { useCallback, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { conditionalArrayItem, makeTitleCase } from 'lib/valueHelpers'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { GetInvitations } from 'data/user/actions'
import { Invitation } from 'gen/cloudTruthRestApi'
import { InvitationDropdown } from './InvitationDropdown'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { Table } from 'components/Table'
import { TableColumnsType } from 'antd'
import { emailSorter } from 'lib/orderBy'
import { getPendingInvitations } from 'data/user/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './InvitationsTable.module.scss'
import { useToast } from 'hooks'

export function InvitationsTable() {
  const pendingInvitations = useAppSelector(getPendingInvitations)
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const { errorToast } = useToast()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const EmptyInvitationsListMessage = () => (
    <div className={styles.emptyInvitationsContainer}>
      <h3>There are no pending invitations to this organization.</h3>

      <p>Invite users to share configuration data with your team.</p>
    </div>
  )

  const getInvitations = useCallback(() => {
    setLoading(true)

    dispatch(GetInvitations(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  const [columns, setColumns] = useState<TableColumnsType<Invitation>>([
    {
      title: 'EMAIL',
      key: 'email',
      ellipsis: true,
      dataIndex: 'email',
      sorter: emailSorter,

      width: 400,
      render: (_, invite) => <div>{invite.email}</div>,
    },
    {
      title: 'SCOPE',
      key: 'scope',
      dataIndex: 'scope',
      ellipsis: true,
      width: 250,
      render: (_, invite) => (
        <>
          {invite.role === 'CONTRIB'
            ? 'Contributor'
            : invite.role == 'NO_SECRETS'
            ? 'NoSecretsViewer'
            : makeTitleCase(invite.role || '')}
        </>
      ),
    },
    {
      title: 'STATE',
      key: 'state',
      ellipsis: true,
      width: 250,
      dataIndex: 'state',
      render: (_, invite) => <div>{invite.state}</div>,
    },

    ...conditionalArrayItem(canAdministrate, [
      {
        title: '',
        width: 100,
        render: (_: any, invite: Invitation) => <InvitationDropdown invitation={invite} />,
      },
    ]),
  ])

  return (
    <div className={styles.container}>
      <PageTitle
        title="Invitations"
        description="Any outstanding User invitations will be shown here. Once a User accepts the invitation the account will move to the Users list. You can resend or cancel a User's invitation from here."
        buttons={<Reload onClick={getInvitations} loading={loading} />}
      />
      <div>
        <Table
          loading={loading}
          columns={resizableColumns(columns, setColumns)}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          pagination={{
            pageSize: 10,
          }}
          tableLayout="fixed"
          dataSource={pendingInvitations}
          rowClassName={styles.row}
          rowKey={(invite: Invitation) => invite.id}
          locale={{ emptyText: EmptyInvitationsListMessage }}
        />
      </div>
    </div>
  )
}

import { authService } from 'lib/authService'
import { useAuth0 } from '@auth0/auth0-react'

export function Unauthorized() {
  const authContext = useAuth0()

  authService.logout(authContext)

  return null
}

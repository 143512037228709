import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteInvitation } from 'data/user/actions'
import { Invitation } from 'gen/cloudTruthRestApi'
import React from 'react'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  invitation: Invitation
  visible: boolean
  setVisible: (bool: boolean) => void
}

export function DeleteInvitationComponent(props: Props) {
  const { invitation, visible, setVisible } = props
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const deleteInvitation = () => {
    dispatch(DeleteInvitation(invitation.id)).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Invitation successfully deleted')
      setVisible(false)
    })
  }

  return (
    <>
      <DeleteConfirmModal
        visible={visible}
        closeModal={() => setVisible(false)}
        subject="invitation"
        onDelete={deleteInvitation}
      />
    </>
  )
}

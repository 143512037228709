//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AzureKeyVaultIntegrationScan,
  AzureKeyVaultPull,
  PaginatedAzureKeyVaultPullList,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ActionIds } from '../utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface UpdatePull {
  pull: Partial<AzureKeyVaultPull>
  akvIntegrationId: string
}

interface AddPull {
  pull: Partial<AzureKeyVaultPull>
  akvIntegrationId: string
}

interface GetTaskSteps extends ActionIds {
  taskId: string
}

interface MappedPull extends ActionIds {
  dry_run: boolean
}

interface Scan extends AzureKeyVaultIntegrationScan {
  integrationId: string
}

export const GetAzureKeyVaultPulls = createAsyncThunk<
  PaginatedAzureKeyVaultPullList,
  string,
  ThunkConfig
>(
  'akvPull/getPulls',
  async (akvIntegrationId, thunkApi) =>
    await handleFetch(
      Verbs.Get,
      `integrations/azure/key_vault/${akvIntegrationId}/pulls`,
      null,
      thunkApi
    )
)

export const GetAzureKeyVaultPull = createAsyncThunk<AzureKeyVaultPull, ActionIds, ThunkConfig>(
  'akvPull/getPull',
  async (getPull, thunkApi) => {
    const { integrationId, actionId } = getPull
    return await handleFetch(
      Verbs.Get,
      `integrations/azure/key_vault/${integrationId}/pulls/${actionId}`,
      null,
      thunkApi
    )
  }
)

export const GetAzureKeyVaultPullTasks = createAsyncThunk<
  AzureKeyVaultPull,
  ActionIds,
  ThunkConfig
>('akvPull/getPullTasks', async (getPull, thunkApi) => {
  const { integrationId, actionId } = getPull
  return await handleFetch(
    Verbs.Get,
    `integrations/azure/key_vault/${integrationId}/pulls/${actionId}/tasks`,
    null,
    thunkApi
  )
})

export const GetAzureKeyVaultPullTaskSteps = createAsyncThunk<
  AzureKeyVaultPull,
  GetTaskSteps,
  ThunkConfig
>('akvPull/getPullTaskSteps', async (getSteps, thunkApi) => {
  const { integrationId, actionId, taskId } = getSteps
  return await handleFetch(
    Verbs.Get,
    `integrations/azure/key_vault/${integrationId}/pulls/${actionId}/tasks/${taskId}/steps`,
    null,
    thunkApi
  )
})

export const CreateAzureKeyVaultPull = createAsyncThunk<AzureKeyVaultPull, AddPull, ThunkConfig>(
  'akvPull/createPull',
  async (addObj, thunkApi) => {
    const { pull, akvIntegrationId } = addObj

    return await handleFetch(
      Verbs.Post,
      `integrations/azure/key_vault/${akvIntegrationId}/pulls`,
      pull,
      thunkApi
    )
  }
)

export const UpdateAzureKeyVaultPull = createAsyncThunk<AzureKeyVaultPull, UpdatePull, ThunkConfig>(
  'akvPull/updatePull',
  async (updateObj, thunkApi) => {
    const { pull, akvIntegrationId } = updateObj

    return await handleFetch(
      Verbs.Put,
      `integrations/azure/key_vault/${akvIntegrationId}/pulls/${pull.id}`,
      pull,
      thunkApi
    )
  }
)

export const SyncAzureKeyVaultPull = createAsyncThunk<AzureKeyVaultPull, ActionIds, ThunkConfig>(
  'akvPull/syncPull',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/azure/key_vault/${integrationId}/pulls/${actionId}/sync`,
      null,
      thunkApi
    )
  }
)

export const ScanAzureKeyVaultPull = createAsyncThunk<AzureKeyVaultPull, Scan, ThunkConfig>(
  'akvPull/scanPull',
  async (scan, thunkApi) => {
    const { integrationId, resource } = scan

    return await handleFetch(
      Verbs.Post,
      `integrations/azure/key_vault/${integrationId}/scan`,
      { resource },
      thunkApi
    )
  }
)

export const SyncMappedAzureKeyVaultPull = createAsyncThunk<
  AzureKeyVaultPull,
  MappedPull,
  ThunkConfig
>('akvPull/syncPull', async (syncObj, thunkApi) => {
  const { actionId, integrationId } = syncObj

  return await handleFetch(
    Verbs.Post,
    `integrations/azure/key_vault/${integrationId}/pulls/${actionId}/sync`,
    { dry_run: syncObj.dry_run },
    thunkApi
  )
})

export const DeleteAzureKeyVaultPull = createAsyncThunk<string, ActionIds, ThunkConfig>(
  'akvPull/deletePull',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Delete,
      `integrations/azure/key_vault/${req.integrationId}/pulls/${req.actionId}`,
      null,
      thunkApi
    )
)

import { Flow, MarginCard, handleFlowError, ory } from '..'
import React, { useEffect, useState } from 'react'
import { UpdateVerificationFlowBody, VerificationFlow } from '@ory/kratos-client'

import { AxiosError } from 'axios'
import { CardTitle } from '@ory/themes'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'hooks'

export function EmailVerification() {
  const [flow, setFlow] = useState<VerificationFlow>()
  const [loading, setLoading] = useState(true)
  const router = useNavigate()
  const { errorToast } = useToast()

  useEffect(() => {
    ory
      .createBrowserVerificationFlow()
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(router, 'verification', setFlow, errorToast))
      .finally(() => setLoading(false))
  }, [errorToast, router])

  const onSubmit = (values: UpdateVerificationFlowBody) =>
    // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
    // his data when she/he reloads the page.
    new Promise((resolve) => {
      router(`/verification?flow=${flow?.id}`)
      resolve({})
    }).then(() =>
      ory
        .updateVerificationFlow({ flow: String(flow?.id), updateVerificationFlowBody: values })
        .then(() => {
          // If we ended up here, it means we are successfully signed up!
          //
          // You can do cool stuff here, like having access to the identity which just signed up:

          if (flow?.return_to) {
            window.location.href = flow?.return_to
            return
          }
          router('/')

          // For now however we just want to redirect home!
        })
        .catch(handleFlowError(router, 'verification', setFlow, errorToast))
        .catch((err: AxiosError) => {
          // If the previous handler did not catch the error it's most likely a form validation error
          if (err.response?.status === 400) {
            // Yup, it is!
            setFlow(err.response?.data)
            return
          }

          return Promise.reject(err)
        })
    )

  if (loading) {
    return null
  }

  return (
    <MarginCard>
      <CardTitle>Verify email</CardTitle>
      <Flow flow={flow} onSubmit={onSubmit} method="link" />
    </MarginCard>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { Dictionary } from '@reduxjs/toolkit/dist/entities'
import { Project } from 'gen/cloudTruthRestApi'
import { TreeSelectProps } from 'antd'
import { idFromUrl } from 'data/dataUtils'

export const buildProjectTree = (
  projectEntities: Dictionary<Project>,
  cyData?: string
): TreeSelectProps['treeData'] => {
  const projects = Object.values(projectEntities)

  const childProjectFromUrl = (url: string) => {
    const id = idFromUrl(url)

    return projectEntities[id]
  }

  const assembleChildren = (parent: Project): TreeSelectProps['treeData'] => {
    return projects
      .filter((p) => p!.depends_on === parent.url)
      .map((p) => ({
        title: childProjectFromUrl(p!.url)!.name,
        value: childProjectFromUrl(p!.url)!.id,
        key: childProjectFromUrl(p!.url)!.id,
        children: assembleChildren(p!),
      }))
  }

  return projects
    .filter((project) => project!.depends_on === null)
    .map((project) => {
      return {
        title: project!.name,
        value: project!.id,
        key: childProjectFromUrl(project!.url)!.id,
        expanded: true,
        children: assembleChildren(project!),
        ['data-cy']: `${cyData}-treenode-${project!.name}`,
      }
    })
}

import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Environment } from 'gen/cloudTruthRestApi'
import { TreeSelect } from 'antd'
import { selectEnvironment } from 'data/environment/reducer'
import styles from './EnvironmentSelector.module.scss'

const { TreeNode } = TreeSelect

interface Props {
  // the default behavior is to change the current environment on select
  // in some case we don't want this behavior, turn this to true to void the
  // current environment change
  dontChangeCurrent?: boolean
  onEnvChange?: (parentId: string) => void
  callback?: (envId?: string) => void
}

interface TreeNodeObj {
  value: string
  title: string
  children: TreeNodeObj[]
}

const assembleTree = (env: Environment, allEnvironments: Environment[]): TreeNodeObj => {
  return {
    value: env.id,
    title: env.name,
    children: allEnvironments
      .filter((e) => e.parent === env.url)
      .map((childEnv) => assembleTree(childEnv, allEnvironments)),
  }
}

function renderTree(treeNode: TreeNodeObj) {
  const { title, value, children } = treeNode

  return (
    <TreeNode
      key={value}
      id={title}
      title={<div data-cy={`option-${title}`}>{title}</div>}
      value={value}
    >
      {children.map(renderTree)}
    </TreeNode>
  )
}

export function EnvironmentSelector(props: Props) {
  const { dontChangeCurrent, onEnvChange, callback } = props
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const baseEnvironment = useAppSelector(getBaseEnvironment)
  const currentEnvironmentId = useAppSelector(getCurrentEnvironment)?.id
  const [envId, setEnvId] = useState(currentEnvironmentId)
  const [mounted, setMounted] = useState<boolean>(false)

  const value = useMemo(() => {
    return dontChangeCurrent && onEnvChange ? envId : currentEnvironmentId
  }, [envId, currentEnvironmentId, dontChangeCurrent, onEnvChange])

  const dispatch = useAppDispatch()
  const { setQuery } = useQuery()

  useEffect(() => {
    // setQuery should really be in the onChange function, but it's here to prevent a console
    // warning while running tests. It appears to be a bug in react-router (#7460).
    if (!dontChangeCurrent && envId && mounted && value) {
      setQuery(QueryParamName.EnvId, value)
    } else if (!mounted) {
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onChange = (environmentId: string) => {
    if (callback) {
      callback(environmentId)
    }
    if (dontChangeCurrent) {
      if (onEnvChange) {
        onEnvChange(environmentId)
        setEnvId(environmentId)
      }
      return
    }

    dispatch(selectEnvironment(environmentId))
  }

  if (!currentEnvironmentId || !baseEnvironment) {
    return (
      <div className={styles.container}>
        <TreeSelect style={{ width: '100%' }} data-cy="envSelect" />
      </div>
    )
  }

  const envTree = assembleTree(baseEnvironment, environments)

  return (
    <div className={styles.container}>
      <TreeSelect
        style={{ width: '100%' }}
        treeDefaultExpandAll={true}
        value={value}
        onChange={onChange}
        allowClear={false}
        data-cy="envSelect"
        showSearch
        treeNodeFilterProp="id"
      >
        {renderTree(envTree)}
      </TreeSelect>
    </div>
  )
}

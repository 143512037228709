export interface Options {
  currentOnly?: boolean
  pageSize?: number
  expandedTree?: { [key: string]: boolean }
  expandedNav?: boolean
  columnSizes?: [{ key: string; width: number }]
  disableEvaluatedModal?: boolean
}

export interface Pages {
  parameter: Options
  compare: Options
  history: Options
  types: Options
  projects: Options
  serviceAccount: Options
  sideNav: Options
  auditLog: Options
  tags: Options
  imports: Options
  pushes: Options
  parameterTimeline: Options
}

// The Session is a hash index by organization id
export interface LocalSession {
  [key: string]: Pages
}

export interface SetLocalSessionArgs {
  org: string
  pageType:
    | 'parameter'
    | 'compare'
    | 'history'
    | 'types'
    | 'projects'
    | 'serviceAccount'
    | 'sideNav'
    | 'user'
    | 'auditLog'
    | 'groups'
    | 'tags'
    | 'imports'
    | 'pushes'
    | 'parameterTimeline'
    | 'expire'
  args: Options
}

interface GetLocalSessionArgs {
  org: string
  pageType:
    | 'parameter'
    | 'compare'
    | 'history'
    | 'types'
    | 'projects'
    | 'serviceAccount'
    | 'sideNav'
    | 'user'
    | 'auditLog'
    | 'groups'
    | 'tags'
    | 'imports'
    | 'pushes'
    | 'parameterTimeline'
    | 'expire'
}

export const setLocalSession = ({ org, pageType, args }: SetLocalSessionArgs) => {
  let localSession = JSON.parse(localStorage.getItem('localSession') || '{}')

  if (localSession[org]) {
    localSession[org][pageType] = { ...localSession[org][pageType], ...args }
  } else {
    localSession = {
      ...localSession,
      [org]: {
        [pageType]: args,
      },
    }
  }

  localStorage.setItem('localSession', JSON.stringify(localSession))
}

export const getLocalSession = ({
  org,
  pageType,
}: GetLocalSessionArgs): nullable<Options | Options> => {
  const localSession = JSON.parse(localStorage.getItem('localSession') || '{}')

  if (!localSession[org]) {
    return null
  } else {
    return localSession[org][pageType] || null
  }
}

/// takes the column sizes from local storage and returns the size for a given column
export const storedColumnSize = (
  columnSizes: Array<{ key: string; width: number }> | undefined,
  searchKey: string,
  defaultSize: number
) => {
  if (columnSizes === undefined) {
    return defaultSize
  }
  const column = columnSizes.find((column) => column.key === searchKey)
  return column && column.width !== undefined ? column.width : defaultSize
}

export const setLocalColumnSizes = (
  org: string,
  pageType: SetLocalSessionArgs['pageType'],
  columns: any
) =>
  setLocalSession({
    org,
    pageType,
    args: { columnSizes: columns },
  })

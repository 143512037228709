import React, { useState } from 'react'

import { AwsAccountsListPanelContent } from './AwsAccountsListPanel/AwsAccountsListPanelContent'
import { AwsAccountsListPanelContentEditing } from './AwsAccountsListPanel/AwsAccountsListPanelContentEditing'
import { AwsAccountsListPanelExtra } from './AwsAccountsListPanel/AwsAccountsListPanelExtra'
import { AwsIntegration } from 'gen/cloudTruthRestApi'
import { List } from 'antd'
import styles from './AwsAccountsList.module.scss'

interface Props {
  integration: AwsIntegration
}

export function AwsAccountListItem(props: Props) {
  const { integration } = props
  const [checking, setChecking] = useState(false)
  const [editing, setEditing] = useState(false)

  const cancelEdit = () => {
    setEditing(false)
    setChecking(false)
  }

  return (
    <List.Item
      extra={
        <AwsAccountsListPanelExtra
          awsIntegration={integration}
          handleChecking={(checking: boolean) => setChecking(checking)}
          handleEditClick={() => setEditing(true)}
          isChecking={checking}
        />
      }
    >
      <List.Item.Meta title={<div className={styles.title}>{integration.name}</div>} />
      {editing ? (
        <AwsAccountsListPanelContentEditing
          awsIntegration={integration}
          onCancel={cancelEdit}
          onSubmit={() => setChecking(true)}
          onSuccess={cancelEdit}
        />
      ) : (
        <AwsAccountsListPanelContent awsIntegration={integration} />
      )}
    </List.Item>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AppDispatch, RootState } from './store'
import {
  IntegrationsExploreListParams,
  Project,
  ServiceAccountCreateResponse,
  TemplateLookupError,
  UtilsGeneratePasswordCreateParams,
} from 'gen/cloudTruthRestApi'

import { AsyncThunkPayloadCreatorReturnValue } from '@reduxjs/toolkit'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { Dayjs } from 'dayjs'
import { LoggerUtils } from 'lib/loggerUtils'
// import { RootState } from 'react-redux'
import { ValueQuery } from './parameter/actions'

export const epoch = new Date(Date.UTC(0, 0))

export enum Verbs {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
}

export interface ThunkConfig {
  dispatch: AppDispatch
  state: RootState
}

// TODO (mwellman17 Jun 23, 2021): make this better
export interface CustomThunk extends Record<string, any> {
  error?: Error
  payload?: ServiceAccountCreateResponse | Record<string, any> | unknown | any
}

export interface TypedThunk<T> extends Record<string, any> {
  error?: Error
  payload: T
}

export interface CustomError extends Response {
  payload?: Record<string, string[] | any> | string | TemplateLookupError
}

export const handleFetch = (
  verb: Verbs,
  route: string,
  body: nullable<Record<string, any>>,
  thunkApi: AsyncThunkPayloadCreatorReturnValue<RootState | any, ThunkConfig>,
  query?: nullable<string>
): Promise<any> => {
  LoggerUtils.setRequestId(thunkApi.requestId)

  return fetch(encodeURI(`${process.env.REACT_APP_CTCAAS_ENDPOINT}${route}/${query || ''}`), {
    method: verb,
    body: body ? JSON.stringify(body) : null,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${thunkApi.getState().session.jwt}`,
    },
  })
    .then((response) => {
      // 500 errors have no body, so we use a generic message
      // Other errors should have a body so we attempt to parse a message from the response
      if (response.status === 500) {
        throw { statusText: 'Internal Server Error. Our support team has been notified.' }
      } else if (response.status >= 400) {
        return response.json().then((error) => {
          throw { statusText: response.statusText, payload: error }
        })
      } else if (response.status === 204 || response.status === 202) {
        return idFromUrl(route)
      }

      return response.json()
    })
    .then((data) => data)
    .catch((error: CustomError) => {
      const errorMessage = new Error(buildError(error))

      LoggerUtils.captureException(errorMessage)
      return Promise.reject(errorMessage)
    })
}

const buildError = (error: CustomError): string => {
  // Errors in the 400 range usually have helpful feedback.
  // They could be in the detail, or they could be a list.
  // Return the first message, or use a generic message if one doesn't exist.
  // FIXME: we need one type of error response for all 400s, this is out of hand.
  if (typeof error.payload === 'string' && error.payload) {
    // example: 400 POST /serviceaccounts "Access token with this name already exists"
    return error.payload
  } else if (typeof error.payload === 'object' && error.payload?.detail) {
    if (typeof error.payload.detail === 'string') {
      // example: 400 on GET /integrations/explore "No integration available for `github://..."
      return error.payload.detail
    } else {
      // example: 422 POST /template-preview "No integration available for `github://..."
      return error.payload.detail[0].error_detail
    }
  } else if (Array.isArray(error.payload)) {
    // example: 400 POST /template-preview "Template references parameter(s) that do not exist: {'foo'}"
    return error.payload[0]
  } else if (Object.values(error.payload || {})?.[0]?.[0]) {
    // example: 400 POST /organizations "Organization with this name already exists"
    return Object.values(error.payload || {})?.[0]?.[0]
  }

  // example: 500 internal server error
  return `Failed to fetch: ${error.statusText || error.status}`
}

export const idFromUrl = (url: string): string => {
  // ids can be UUIDs or auth0 ids which need to be decoded
  return decodeURI(url.match(/\/([^/]+)\/?$/)![1])
}

export const projectIdFromUrl = (url: string): string => {
  // project ids can be found in object urls for templates and parameters only
  return url.match(/projects\/(.*)\/(?:templates|parameters)/)![1]
}

export const environmentIdFromUrl = (url: string): string => {
  // environment ids can be found in object urls for tags only
  return url.match(/environments\/(.*)\/(?:tags)/)![1]
}

export const orgIdFromInviteUrl = (url: string): string => {
  // environment ids can be found in object urls for tags only
  return url.match(/organization\/(.*)\/(?:invitations)/)![1]
}

export const integrationIdFromUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/(?:aws|github|key_vault)\/(.*)\/(?:pushes|pulls)/)![1]
}

export const integrationIdFromAwsPushUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/aws\/(.*)\/(?:pushes)/)![1]
}
export const integrationIdFromAwsPullUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/aws\/(.*)\/(?:pulls)/)![1]
}

export const integrationIdFromAkvPushUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/key_vault\/(.*)\/(?:pushes)/)![1]
}
export const integrationIdFromAkvPullUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/key_vault\/(.*)\/(?:pulls)/)![1]
}

export const typeIdFromRuleUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/types\/(.*)\/(?:rules)/)![1]
}

export const integrationIdFromGithubPullUrl = (url: string): string => {
  // integration ids can be found in object urls for actions only
  return url.match(/github\/(.*)\/(?:pulls)/)![1]
}

export const isUuid = (string: string | undefined): boolean => {
  return !!string && !!string.match(/^\w{8}-(?:\w{4}-){3}\w{12}$/)
}

export const buildQuery = (
  object:
    | IntegrationsExploreListParams
    | ValueQuery
    | UtilsGeneratePasswordCreateParams
    | Record<string, string | Dayjs>
): string => {
  const list: string[] = []
  Object.entries(object).forEach(([key, value]) => {
    if (value || value === false) {
      list.push(`${key}=${value}`)
    }
  })
  return `?${list.join('&')}`
}

export const projectId = (
  getState: BaseThunkAPI<RootState, Project>['getState']
): nullable<string> => {
  const {
    project: { current: projectId },
  } = getState()
  return projectId
}

export const envIdFromParams = (): nullable<string> =>
  new URLSearchParams(window.location.search).get('envId')

export const exportsForTesting = {
  buildError,
}

export const projectIdFromAnyUrl = (url: string): string => {
  const regex = /projects\/([^/]+)/
  const match = regex.exec(url)!
  return match[1]
}

import { DatePicker, FormInstance, FormRule } from 'antd'
//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import {
  Form,
  FormData,
  InlineLabelInput,
  LabeledInputItem,
  LabeledItem,
  TextArea,
} from 'components/Forms'
import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { isoDateTimeFormat, isoTimeFormat } from 'lib/dateHelpers'

import { EnvironmentSelector } from 'components/EnvironmentSelector'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import { Tag } from 'gen/cloudTruthRestApi'
import { disabledTime } from 'components/Forms/TimestampSelector'
import { getCurrentEnvironment } from 'data/environment/selectors'
import styles from './EnvironmentTags.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  tag?: Tag
  editing?: boolean
  form: FormInstance
  dontChangeCurrent?: boolean
  onFinish: (formData: FormData) => void
  timestamp: nullable<Dayjs>
  setTimestamp: (time: nullable<Dayjs>) => void
}

const nameValidator = (rule: FormRule, value: string) => {
  if (value.length > 64) {
    return Promise.reject('Tag name can be no more than 64 characters.')
  } else if (!value.match(/^[\w-.]*$/)) {
    return Promise.reject(
      'Valid tag names have only alphanumerics, hyphens, underscores, and periods.'
    )
  }
  return Promise.resolve()
}

export function TagForm(props: Props) {
  const { form, onFinish, timestamp, setTimestamp, tag, dontChangeCurrent, editing } = props
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)

  const environment = useAppSelector(getCurrentEnvironment)!

  const onEnvChange = (envId?: string) => form.setFieldsValue({ environment: envId })

  useEffect(() => {
    form?.setFieldsValue({ environment: environment.id })
  }, [environment, form])

  return (
    <Form form={form} onFinish={onFinish} initialValues={{ environment: environment.id }}>
      <InlineLabelInput
        autoFocus={!tag}
        disabled={!!tag}
        name="name"
        label="Tag Name"
        required="Please enter a tag name."
        rules={[{ validator: nameValidator }]}
      />

      <div style={{ marginBottom: '24px' }}>
        <InlineLabelInput
          checkbox
          label="Immutable"
          name="immutable"
          tooltipText="Select immutable to prevent this tag from being updated to reflect recent changes."
        />
      </div>

      {!editing && (
        <LabeledInputItem name="environment" label={{ text: 'Environment' }} required>
          <EnvironmentSelector
            callback={onEnvChange}
            onEnvChange={onEnvChange}
            dontChangeCurrent={dontChangeCurrent}
          />
        </LabeledInputItem>
      )}

      <TextArea autoFocus={!!tag} name="description" label="Description" />
      <div className={styles.datepicker}>
        <LabeledItem
          label={{
            text: 'Timestamp',
            tooltipText: tag
              ? 'Warning: Changing the timestamp may affect all projects and configurations that reference this tag. Go to the History tab for a comparison.'
              : 'Current time is selected by default, or use the date picker to select a different time.',
          }}
        />
        <div className={styles.showMore}>
          <ShowHideButton
            showSettings={showDatePicker}
            toggleShowSettings={() => setShowDatePicker(!showDatePicker)}
            label="date picker"
          />
        </div>
      </div>
      {showDatePicker && (
        <DatePicker
          disabledDate={(current) => current && current.isAfter(dayjs(), 'day')}
          disabledTime={(current) => disabledTime(current)}
          use12Hours
          format={isoDateTimeFormat}
          showTime={{ defaultValue: dayjs(isoTimeFormat), use12Hours: false }}
          onChange={(time) => setTimestamp(time)}
          value={timestamp}
          onSelect={(time) => setTimestamp(time)}
          allowClear={false}
        />
      )}
    </Form>
  )
}

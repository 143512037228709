import { Alert } from 'antd'
import React from 'react'
import styles from './TemplatePreviewDisplay.module.scss'

interface Props {
  errors: nullable<string>
}

export function RenderAlert(props: Props) {
  const { errors } = props

  return errors ? (
    <Alert message={`Error in template`} type="error" showIcon className={styles.errorAlert} />
  ) : (
    <Alert message="The template is valid." type="success" showIcon className={styles.alert} />
  )
}

import React, { ChangeEvent } from 'react'

import { Input } from 'antd'
import { LabelProps } from './Label'
import { LabeledInputItem } from './LabeledInputItem'
import { NamePath } from 'rc-field-form/lib/interface'
import styles from './TextArea.module.scss'

interface Props {
  name: NamePath
  label: LabelProps | string
  placeholder?: string
  rows?: number
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  hasError?: boolean
  value?: string
  autoFocus?: boolean
  required?: boolean
  disabled?: boolean
}

export function TextArea(props: Props) {
  const {
    name,
    label,
    placeholder,
    rows,
    onChange,
    hasError,
    autoFocus,
    required,
    disabled,
    ...restProps
  } = props

  const className = hasError ? [styles.textArea, styles.error].join(' ') : styles.textArea

  return (
    <LabeledInputItem name={name} label={label} required={required}>
      <Input.TextArea
        rows={rows || 3}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        data-testid="textarea"
        disabled={disabled}
        {...restProps}
      />
    </LabeledInputItem>
  )
}

import Editor from 'components/Editor/Editor'
import React from 'react'
import { Template } from 'gen/cloudTruthRestApi'
import styles from './TemplateBodyDisplay.module.scss'

interface Props {
  wrapping: boolean
  template: Template
  loading: boolean
  currentBody?: nullable<string>
}

export function TemplateBodyDisplay(props: Props) {
  const { wrapping, template, currentBody, loading } = props

  const { body } = template

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Editor readOnly body={loading ? currentBody : body} wrapText={wrapping} />
      </div>
    </div>
  )
}

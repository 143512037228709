//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useMemo } from 'react'

import { ParameterTimelineEntry } from 'gen/cloudTruthRestApi'
import { Timeline } from 'antd'
import { formatDateTime } from 'lib/dateHelpers'
import { makeTitleCase } from 'lib/valueHelpers'

interface Props {
  timeline: ParameterTimelineEntry[]
}

export function ChangeHistoryTimeline({ timeline }: Props) {
  const timelinePreview = useMemo(() => {
    return timeline.slice(0, 10)
  }, [timeline])

  const timelineItem = timelinePreview.map((item, key) => {
    const { history_type, history_model, modified_by, modified_at } = item
    return (
      <span key={key}>
        <Timeline.Item>
          {makeTitleCase(history_type)} {history_model} {' by '}
          {modified_by} {'at'} {formatDateTime(modified_at)}
        </Timeline.Item>
      </span>
    )
  })
  return (
    <>
      <Timeline>{timelineItem}</Timeline>
    </>
  )
}

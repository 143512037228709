//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  PaginatedTemplateList,
  PatchedTemplateUpdate,
  ProjectsTemplatePreviewCreateParams,
  Template,
  TemplateCreate,
  TemplatePreview,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch, projectId } from 'data/dataUtils'

import { Dayjs } from 'dayjs'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface EvaluateTemplate {
  templateId?: string
  projectId: string
  envId: string
  maskSecrets: boolean
}

interface GetTemplateObj {
  projectId: string
  templateId: string
}

interface CreateTemplate extends TemplateCreate {
  project?: string
  dontUpdateCurrent?: boolean
  dontUpdateCache?: boolean
}

interface PreviewTemplate extends EvaluateTemplate {
  body: string
  updateHasSecrets?: boolean
}

interface VersionedTemplatesType {
  projectId: string
  params: { as_of: Dayjs | string; name?: string }
}

// GetTemplates clears the existing cache and sets the cached template id
export const GetTemplates = createAsyncThunk<PaginatedTemplateList, string, ThunkConfig>(
  'template/getTemplates',
  async (id, thunkApi) => {
    return await handleFetch(
      Verbs.Get,
      `projects/${id}/templates`,
      null,
      thunkApi,
      buildQuery({ evaluate: false })
    )
  }
)

export const GetTemplateNames = createAsyncThunk<PaginatedTemplateList, string, ThunkConfig>(
  'template/getTemplateNames',
  async (id, thunkApi) => {
    return await handleFetch(
      Verbs.Get,
      `projects/${id}/templates`,
      null,
      thunkApi,
      buildQuery({ evaluate: false })
    )
  }
)

export const GetTemplate = createAsyncThunk<Template, GetTemplateObj, ThunkConfig>(
  'template/getTemplate',
  async (template, thunkApi) => {
    const { projectId, templateId } = template
    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/templates/${templateId}`,
      null,
      thunkApi
    )
  }
)

export const PreviewTemplate = createAsyncThunk<TemplatePreview, PreviewTemplate, ThunkConfig>(
  'template/previewTemplate',
  async (obj, thunkApi) => {
    const { projectId, body, envId, maskSecrets, templateId, updateHasSecrets } = obj
    const query = {
      environment: envId,
      mask_secrets: maskSecrets,
      template: templateId,
      updateHasSecrets,
    } as Partial<ProjectsTemplatePreviewCreateParams>

    return await handleFetch(
      Verbs.Post,
      `projects/${projectId}/template-preview`,
      { body },
      thunkApi,
      buildQuery(query)
    )
  }
)

export const GetEvaluatedTemplate = createAsyncThunk<Template, EvaluateTemplate, ThunkConfig>(
  'template/evaluateTemplate',
  async (obj, thunkApi) => {
    const { projectId, templateId, envId, maskSecrets } = obj

    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/templates/${templateId}`,
      null,
      thunkApi,
      `?environment=${envId}&mask_secrets=${maskSecrets}`
    )
  }
)

export const CreateTemplate = createAsyncThunk<Template, CreateTemplate, ThunkConfig>(
  'template/createTemplate',
  async (template, thunkApi) => {
    const pId = template.project ? template.project : projectId(thunkApi.getState)
    return await handleFetch(Verbs.Post, `projects/${pId}/templates`, template, thunkApi)
  }
)

export const UpdateTemplate = createAsyncThunk<Template, PatchedTemplateUpdate, ThunkConfig>(
  'template/updateTemplate',
  async (template, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Put,
      `projects/${pId}/templates/${template.id}`,
      template,
      thunkApi
    )
  }
)

export const DeleteTemplate = createAsyncThunk<string, string, ThunkConfig>(
  'template/deleteTemplate',
  async (id, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(Verbs.Delete, `projects/${pId}/templates/${id}`, null, thunkApi)
  }
)

export const GetVersionedTemplates = createAsyncThunk<
  PaginatedTemplateList,
  VersionedTemplatesType,
  ThunkConfig
>('template/getVersionedTemplates', async (getObj, thunkApi) => {
  const { projectId, params } = getObj

  return await handleFetch(
    Verbs.Get,
    `projects/${projectId}/templates`,
    null,
    thunkApi,
    buildQuery({ ...params, evaluate: false })
  )
})

import { FormAlert, FormData, useForm } from 'components/Forms'
import { Group, User } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { AddGroupForm } from './AddGroupForm'
import { AddModal } from 'components/Modals'
import { CreateGroup } from 'data/group/actions'
import { TypedThunk } from 'data/dataUtils'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

export function AddGroup() {
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const [visible, setVisible] = useState<boolean>(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [pending, setPending] = useState<boolean>(false)
  const [userValues, setUserValues] = useState<User['url'][]>([])

  const [form] = useForm()

  const afterClose = () => {
    setError(null)
    form.resetFields()
  }

  const onFinish = (formData: FormData) => {
    const { name, description } = formData

    setPending(true)
    dispatch(CreateGroup({ name, description, users: userValues })).then(
      ({ error }: TypedThunk<Group>) => {
        error ? errorToast(error.message) : successToast('Group successfully created')

        setPending(false)
        setVisible(false)
      }
    )
  }

  return (
    <>
      <ActionButton customType="add" size="large" onClick={() => setVisible(true)}>
        Create Group
      </ActionButton>

      <AddModal
        visible={visible}
        objectName="Group"
        afterClose={afterClose}
        onCancel={() => setVisible(false)}
        onOk={form.submit}
        pending={pending}
      >
        {error && <FormAlert description={error} message="Group could not be created." />}
        <AddGroupForm
          form={form}
          onFinish={onFinish}
          userValues={userValues}
          setUserValues={setUserValues}
        />
      </AddModal>
    </>
  )
}

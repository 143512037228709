//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsIntegrationScan, AwsPull, PaginatedAwsPullList } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ActionIds } from '../utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface UpdatePull {
  pull: Partial<AwsPull>
  awsIntegrationId: string
}

interface AddPull {
  pull: Partial<AwsPull>
  awsIntegrationId: string
}

interface GetTaskSteps extends ActionIds {
  taskId: string
}

export interface MappedPull extends ActionIds {
  dry_run: boolean
}

interface Scan extends AwsIntegrationScan {
  integrationId: string
}

export const GetAwsPulls = createAsyncThunk<PaginatedAwsPullList, string, ThunkConfig>(
  'awsPull/getPulls',
  async (awsIntegrationId, thunkApi) =>
    await handleFetch(Verbs.Get, `integrations/aws/${awsIntegrationId}/pulls`, null, thunkApi)
)

export const GetAwsPull = createAsyncThunk<AwsPull, ActionIds, ThunkConfig>(
  'awsPull/getPull',
  async (getPull, thunkApi) => {
    const { integrationId, actionId } = getPull
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pulls/${actionId}`,
      null,
      thunkApi
    )
  }
)

export const GetAwsPullTasks = createAsyncThunk<AwsPull, ActionIds, ThunkConfig>(
  'awsPull/getPullTasks',
  async (getPull, thunkApi) => {
    const { integrationId, actionId } = getPull
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pulls/${actionId}/tasks`,
      null,
      thunkApi
    )
  }
)

export const GetAwsPullTaskSteps = createAsyncThunk<AwsPull, GetTaskSteps, ThunkConfig>(
  'awsPull/getPullTaskSteps',
  async (getSteps, thunkApi) => {
    const { integrationId, actionId, taskId } = getSteps
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pulls/${actionId}/tasks/${taskId}/steps`,
      null,
      thunkApi
    )
  }
)

export const CreateAwsPull = createAsyncThunk<AwsPull, AddPull, ThunkConfig>(
  'awsPull/createPull',
  async (addObj, thunkApi) => {
    const { pull, awsIntegrationId } = addObj

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${awsIntegrationId}/pulls`,
      pull,
      thunkApi
    )
  }
)

export const UpdateAwsPull = createAsyncThunk<AwsPull, UpdatePull, ThunkConfig>(
  'awsPull/updatePull',
  async (updateObj, thunkApi) => {
    const { pull, awsIntegrationId } = updateObj

    return await handleFetch(
      Verbs.Put,
      `integrations/aws/${awsIntegrationId}/pulls/${pull.id}`,
      pull,
      thunkApi
    )
  }
)

export const SyncAwsPull = createAsyncThunk<AwsPull, ActionIds, ThunkConfig>(
  'awsPull/syncPull',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${integrationId}/pulls/${actionId}/sync`,
      null,
      thunkApi
    )
  }
)

export const ScanAwsPull = createAsyncThunk<AwsPull, Scan, ThunkConfig>(
  'awsPull/scanPull',
  async (scan, thunkApi) => {
    const { integrationId, region, resource, service } = scan

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${integrationId}/scan`,
      { region, resource, service },
      thunkApi
    )
  }
)

export const SyncMappedAwsPull = createAsyncThunk<AwsPull, MappedPull, ThunkConfig>(
  'awsPull/syncPull',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${integrationId}/pulls/${actionId}/sync`,
      { dry_run: syncObj.dry_run },
      thunkApi
    )
  }
)

export const DeleteAwsPull = createAsyncThunk<string, ActionIds, ThunkConfig>(
  'awsPull/deletePull',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Delete,
      `integrations/aws/${req.integrationId}/pulls/${req.actionId}`,
      null,
      thunkApi
    )
)

import { Divider, Spin } from 'antd'
import React, { useMemo } from 'react'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import {
  getValueByEnv,
  parameterEntitySelectors,
  useSelectParameters,
} from 'data/parameter/selectors'
import { idFromUrl, projectIdFromUrl } from 'data/dataUtils'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { validateString, valueOrEmDash } from 'lib/valueHelpers'

import { LabelText } from 'components/LabelText'
import { ProviderIcon } from 'components/ProviderIcon'
import { UpdateValue } from '../UpdateValue'
import { ValueError } from 'components/Table'
import { getPolicy } from 'data/membership/selectors'
import { showSecretValueOrAstricks } from 'lib/secretsHelpers'
import styles from './EnvironmentValueDetails.module.scss'
import { useAppSelector } from 'data/hooks'
import { useProjectParams } from 'router/customHooks'

interface Props {
  resetShow: () => void
  overrideCount: number
  showSecretValues: boolean
  toggleSecrets: VoidFunction
  envUrl: string
  showEvaluated: boolean
  onSelectLoading: boolean
}
interface DetailsSectionProps {
  label: string
  text: maybe<string> | JSX.Element
  displayBlank?: boolean
  isSecret?: boolean
  cyData?: string
}

function DetailsSection(props: DetailsSectionProps) {
  const { label, isSecret, text, displayBlank, cyData } = props

  if (!text && !displayBlank) {
    return null
  }

  let attrs = null
  if (isSecret) {
    attrs = {
      className: styles.detailsSection,
      'data-private': 'redact',
    }
  } else {
    attrs = {
      className: styles.detailsSection,
    }
  }

  return (
    <div {...attrs} data-cy={cyData}>
      <LabelText label={label} text={text} />
    </div>
  )
}

export function EnvironmentValueDetails(props: Props) {
  const {
    overrideCount,
    showSecretValues,

    resetShow,
    envUrl,
    showEvaluated,
    onSelectLoading,
  } = props

  const paramId = useProjectParams().parameterId!
  const environmentEntitites = environmentEntitySelectors.selectEntities(useSelectEnvironments())
  const envName = environmentEntitites[idFromUrl(envUrl)]!.name
  const projects = projectEntitySelectors.selectEntities(useSelectProjects())
  const environmentValue = useAppSelector(getValueByEnv(paramId, envUrl))!
  const {
    environment,
    external_filter: externalFilter,
    external_fqn: externalFqn,
    parameter: paramUrl,
  } = environmentValue || {}

  const value = environmentValue?.interpolated
    ? showEvaluated
      ? environmentValue?.value
      : environmentValue?.internal_value
    : environmentValue?.value

  const { secret } = parameterEntitySelectors.selectById(useSelectParameters(), paramId) || {}
  const { canContribute } = useAppSelector(getPolicy(null))

  // this value will need to be updated once available in api
  // const referenceLocation = integrationFile?.fqn
  const hasValue = typeof value === 'string' || !!externalFqn
  const valueSourceName = useMemo((): maybe<string> => {
    const addEnvName = () => {
      if (!environment) {
        return null
      } else {
        return environmentEntitites[idFromUrl(environment)]?.name
      }
    }

    if (paramUrl && idFromUrl(paramUrl) !== paramId) {
      return `${projects[projectIdFromUrl(paramUrl)]?.name}: ${addEnvName()}`
    } else {
      return addEnvName()
    }
  }, [environment, environmentEntitites, paramId, paramUrl, projects])

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.environmentNameContainer} data-cy={`env-details-header-${envName}`}>
          <span className={styles.environmentName}>{envName}</span>
          <ProviderIcon
            isInterpolated={environmentValue?.interpolated}
            fqn={environmentValue?.external_fqn}
          />
        </div>

        {canContribute && !onSelectLoading && (
          <UpdateValue
            envUrl={envUrl}
            showSecret={showSecretValues}
            resetShow={resetShow}
            hasValue={hasValue}
            hasOverride={overrideCount > 0}
            externalError={environmentValue?.external_error}
          />
        )}
      </div>
      <Divider type="horizontal" className={styles.divider} />
      {onSelectLoading ? (
        <Spin data-testid="loading" />
      ) : environmentValue ? (
        <>
          <DetailsSection label="Value Source" text={valueOrEmDash(valueSourceName)} />
          <DetailsSection label="Reference Location" text={externalFqn} />
          <DetailsSection label="JMESPath Selector" text={externalFilter} />
          <div className={styles.envValue}>
            <DetailsSection
              cyData={`details-${value}`}
              label="Environment Value"
              isSecret={secret}
              text={
                environmentValue?.external_error ? (
                  <ValueError error={environmentValue?.external_error} />
                ) : (
                  showSecretValueOrAstricks(secret, showSecretValues, validateString(value))
                )
              }
              displayBlank={true}
            />
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>There are no values for this environment</p>
        </div>
      )}
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CheckAwsIntegrationStatus,
  CreateAwsIntegration,
  DeleteAwsIntegration,
  GetAwsIntegration,
  GetAwsIntegrations,
  UpdateAwsIntegration,
} from './actions'
import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AwsIntegration } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface AwsIntegrationState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const awsIntegrationAdapter = createEntityAdapter<AwsIntegration>({
  selectId: (int) => int.id,
})

const initialState = awsIntegrationAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
} as AwsIntegrationState)

export type AwsIntegrationRootState = EntityState<AwsIntegration> & AwsIntegrationState

const awsIntegrationSlice = createSlice({
  name: 'awsIntegration',
  initialState,
  reducers: {
    // direct actions
    selectAwsIntegration(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllAwsIntegration: awsIntegrationAdapter.setAll,
    addOneAwsIntegration: awsIntegrationAdapter.addOne,
    updateOneAwsIntegration: awsIntegrationAdapter.upsertOne,
    removeOneAwsIntegration: awsIntegrationAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAwsIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetAwsIntegrations.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.setAll(state, action.payload.results!)
      state.current = null
    })
    builder.addCase(GetAwsIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(UpdateAwsIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAwsIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
    builder.addCase(CheckAwsIntegrationStatus.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsIntegrationAdapter.upsertOne(state, action.payload)
    })
  },
})

export const {
  resetState,
  selectAwsIntegration,
  setAllAwsIntegration,
  addOneAwsIntegration,
  updateOneAwsIntegration,
  removeOneAwsIntegration,
} = awsIntegrationSlice.actions

export default awsIntegrationSlice.reducer

import { Alert, List } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AddAkv } from './AddAkv/AddAkv'
import { AkvListItem } from './AkvListItem'
import { CustomThunk } from 'data/dataUtils'
import { GetAzureKeyVaultIntegrations } from 'data/integration/akvIntegration/actions'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import decode from 'jwt-decode'
import { getPolicy } from 'data/membership/selectors'
import styles from './AkvPage.module.scss'
import { useForm } from 'components/Forms'
import { useLocation } from 'react-router-dom'
import { useToast } from 'hooks'
import { v4 as uuidv4 } from 'uuid'

export const url = (href: string) =>
  `${href.split('organization/')[0]}organization/integrations/akv`

export function AkvPage() {
  const [error, setError] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [form] = useForm()
  const akvIntegrations = akvIntegrationEntitySelectors.selectAll(useSelectAkvIntegrations())
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const location = useLocation()
  const hash = location.hash

  useEffect(() => {
    setPageLoading(true)
    dispatch(GetAzureKeyVaultIntegrations(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }

      setPageLoading(false)
    })
  }, [dispatch, errorToast])

  const getAzureKeyVaultIntegrations = useCallback(() => {
    setPageLoading(true)
    dispatch(GetAzureKeyVaultIntegrations(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }

      setPageLoading(false)
    })
  }, [dispatch, errorToast])

  const tenant: maybe<string> = useMemo(() => {
    if (!hash) {
      return null
    }

    const params = new URLSearchParams(hash.substring(1))
    const token = params.get('access_token')

    if (token) {
      // if the account is not an azure account, it will pass back a token but with an invalid signature
      try {
        const decodedToken: any = decode(token)

        return decodedToken?.error_description
          ? setError(decodeURIComponent(decodedToken?.error_description.split('+').join(' ')))
          : decodedToken.tid
      } catch (e) {
        setError('Invalid access token. Make sure you are using a valid Microsoft Azure account.')
      }
    }
  }, [hash])

  const handleClick = () => {
    const nonce = uuidv4()
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
      process.env.REACT_APP_AZURE_CLIENT_ID
    }&response_type=token&redirect_uri=${url(
      window.location.href
    )}&scope=openid&state=12345&nonce=${nonce}&prompt=select_account`
  }

  const onCancel = () => {
    window.location.hash = ''
  }

  return (
    <div className={styles.container}>
      <PageTitle
        title="Azure"
        description='To grant CloudTruth access to an Azure account, click "Connect Azure Account" and
      complete the Azure identity and consent forms to register our application in your account.
      After that you will need to add permissions for the CloudTruth application to access the
      Key Vault using the Azure console to complete the integration setup.'
        buttons={
          <>
            <Reload onClick={getAzureKeyVaultIntegrations} loading={pageLoading} />{' '}
            {canAdministrate && (
              <ActionButton onClick={handleClick} customType="add" size="large">
                Connect Azure Account
              </ActionButton>
            )}
          </>
        }
      />

      {error && (
        <Alert
          message="Error"
          className={styles.error}
          description={error}
          type="error"
          showIcon
          closable
        />
      )}
      {tenant && <AddAkv tenant={tenant} form={form} onCancel={onCancel} />}

      <List
        pagination={{ pageSize: 8 }}
        className={styles.list}
        bordered
        dataSource={akvIntegrations}
        loading={pageLoading}
        itemLayout="vertical"
        renderItem={(integration) => {
          return <AkvListItem integration={integration} />
        }}
      />
    </div>
  )
}

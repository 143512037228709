//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AuditListParams,
  AuditTrail,
  AuditTrailSummary,
  PaginatedAuditTrailList,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetAuditSummary = createAsyncThunk<AuditTrailSummary, null, ThunkConfig>(
  'audit/summary',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'audit/summary', null, thunkApi)
)

export const GetAuditDetail = createAsyncThunk<AuditTrail, string, ThunkConfig>(
  'audit/detail',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `audit/${id}`, null, thunkApi)
)

export const SearchAuditLog = createAsyncThunk<
  PaginatedAuditTrailList,
  AuditListParams,
  ThunkConfig
>(
  'audit/search',
  async (params, thunkApi) =>
    await handleFetch(Verbs.Get, 'audit', null, thunkApi, buildQuery(params))
)

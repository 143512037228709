import { Empty, Skeleton } from 'antd'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useMemo } from 'react'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { FileOutlined } from '@ant-design/icons'
import { Template } from 'gen/cloudTruthRestApi'
import { projectIdFromUrl } from 'data/dataUtils'
import { rightArrow } from 'lib/valueHelpers'
import { selectTemplate } from 'data/template/reducer'
import styles from './TemplateSelector.module.scss'
import { useLocalTemplateBody } from 'hooks'

interface Props {
  selectedTemplate: nullable<Template>
  setLoading: (bool: boolean) => void
  search: nullable<string>
  searchCheckbox: 'template' | 'project'
  loading: boolean
  reloading: boolean
}

export function TemplateSelector(props: Props) {
  const { selectedTemplate, setLoading, search, searchCheckbox, loading, reloading } = props
  const { id: projectId } = useAppSelector(getCurrentProject)!
  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const { setQuery } = useQuery()
  const dispatch = useAppDispatch()

  const filteredTemplates = useCallback(() => {
    return templates.filter((template) => {
      if (search) {
        if (searchCheckbox === 'template') {
          return template.name.toLowerCase().includes(search.toLowerCase())
        } else {
          const projectId = projectIdFromUrl(template.url)
          const projectName = projectEntities[projectId]!.name.toLowerCase()

          return projectName.includes(search.toLowerCase())
        }
      } else {
        return true
      }
    })
  }, [searchCheckbox, search, templates, projectEntities])

  const emptyText = () => {
    if (filteredTemplates().length < 1 && templates.length > 1) {
      return 'No Matches'
    } else {
      return 'No Template Data'
    }
  }

  const Entry = (props: { template: Template; currentProjectId: string }) => {
    const { template, currentProjectId: projectId } = props
    const selected = selectedTemplate?.id === template.id
    const localTemplateBody = useLocalTemplateBody(template.name, projectId)
    const derivedProjectId = projectIdFromUrl(template.url)
    const derivedProjectName = projectEntities[derivedProjectId]!.name
    const readOnly = derivedProjectId !== projectId

    const draft = useMemo(() => {
      if (localTemplateBody) {
        return localTemplateBody !== template.body ? true : false
      }

      return false
    }, [localTemplateBody, template.body])

    return (
      <div
        className={selected ? styles.selectedEntryContainer : styles.entryContainer}
        onClick={() => {
          new Promise((resolve) => {
            setLoading(true)
            dispatch(selectTemplate(template.id))
            setQuery(QueryParamName.Template, template.id)
            resolve({})
          }).then(() => {
            setLoading(false)
          })
        }}
      >
        <div className={selected ? styles.selectedContent : styles.content}>
          {readOnly ? (
            <span>
              <b className={styles.title}>{template.name}</b>
              <span className={styles.title}>{`(project: ${derivedProjectName})`}</span>
            </span>
          ) : (
            <b className={styles.title} data-testid={selected ? `selected_${template.name}` : ''}>
              {template.name}
            </b>
          )}
        </div>

        <div className={styles.rightContainer}>
          {draft && !readOnly && (
            <span className={styles.draftContainer}>
              <span className={selected ? styles.selectedDraft : styles.draft}>Draft</span>
              <FileOutlined className={selected ? styles.selectedDraftIcon : styles.draftIcon} />
            </span>
          )}
          <span className={selected ? styles.selectedArrow : styles.arrow}>{rightArrow}</span>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className={styles.skeletonContainer}>
        <Skeleton.Input active className={styles.skeletonSearch} />
        <Skeleton.Button active />
        <Skeleton paragraph={{ rows: 10 }} active />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.selectList}>
        {reloading ? (
          <Skeleton paragraph={{ rows: templates.length || 10 }} active />
        ) : filteredTemplates().length > 0 ? (
          filteredTemplates().map((template, key) => (
            <Entry template={template} key={key} currentProjectId={projectId} />
          ))
        ) : (
          <div className={styles.empty}>{<Empty description={emptyText()} />}</div>
        )}
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { useAppSelector } from 'data/hooks'

export function ManageGithubAccess() {
  const [loading, setLoading] = useState(false)
  const token = useAppSelector((state) => state.session.jwt)

  const handleClick = () => {
    setLoading(true)
    window.location.href = `${process.env.REACT_APP_GITHUB_INSTALLATION}/installations/new?state=${token}`
  }

  return (
    <ActionButton customType="add" size="large" loading={loading} onClick={handleClick}>
      Manage GitHub Access
    </ActionButton>
  )
}

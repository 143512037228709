//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { CreateAwsPull, DeleteAwsPull, GetAwsPull, GetAwsPulls, UpdateAwsPull } from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AwsPull } from 'gen/cloudTruthRestApi'
import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { epoch } from 'data/dataUtils'

interface PullState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedIntegrations: string[]
}

export const awsPullAdapter = createEntityAdapter<AwsPull>({
  selectId: (int) => int.id,
})

const initialState = awsPullAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  cachedIntegrations: [],
} as PullState)

export type PullRootState = EntityState<AwsPull> & PullState

const awsPullSlice = createSlice({
  name: 'awsPull',
  initialState,
  reducers: {
    // direct actions
    selectAwsPull(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllAwsPull(state, action: PayloadAction<AwsPull[]>) {
      awsPullAdapter.setAll(state, action.payload)
    },

    addOneAwsPull: awsPullAdapter.addOne,
    updateOneAwsPull: awsPullAdapter.upsertOne,
    removeOneAwsPull: awsPullAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAwsPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPullAdapter.addOne(state, action.payload)
    })

    builder.addCase(GetAwsPulls.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      if (!state.cachedIntegrations.includes(action.meta.arg)) {
        state.cachedIntegrations.push(action.meta.arg)
      }
      awsPullAdapter.setMany(state, action.payload.results!)
    })
    builder.addCase(GetAwsPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPullAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateAwsPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPullAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAwsPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPullAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const {
  resetState,
  selectAwsPull,
  setAllAwsPull,
  addOneAwsPull,
  updateOneAwsPull,
  removeOneAwsPull,
} = awsPullSlice.actions

export default awsPullSlice.reducer

import React, { useMemo } from 'react'

import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { Thread } from 'components/GPT/types'
import dayjs from 'dayjs'
import styles from './ThreadList.module.scss'
import { useViewportHeight } from 'hooks'

interface Props {
  threads: nullable<Thread[]>
  selectedThread: nullable<Thread>
  createThreadLoading: boolean
  disabled: boolean
  createTitleLoading: boolean
  deleteThreadDisabled: nullable<string>
  setSelectedThread: (threadId: nullable<Thread>) => void
  createThread: () => void
  deleteThread: (thread: Thread) => void
  error: maybe<string>
  setError: (message: maybe<string>) => void
}

enum Items {
  DeleteThread = 'deleteThread',
}

export function ThreadList(props: Props) {
  const {
    threads,
    selectedThread,
    setSelectedThread,
    disabled,
    deleteThread,
    deleteThreadDisabled,
    createTitleLoading,
    error,
    setError,
  } = props

  const { viewportHeight } = useViewportHeight()

  const todayThreads = useMemo(() => {
    return threads?.filter((thread) => {
      const currentDate = dayjs().startOf('day')
      const inputDate = dayjs(thread.timestamp).startOf('day')
      return inputDate.isSame(currentDate, 'day')
    })
  }, [threads])

  const weekThreads = useMemo(() => {
    return threads?.filter((thread) => {
      const currentDate = dayjs().startOf('day')
      const inputDate = dayjs(thread.timestamp).startOf('day')
      const startOfLast7Days = currentDate.subtract(6, 'day')
      return (
        inputDate.isBefore(currentDate) &&
        (inputDate.isSame(startOfLast7Days, 'day') || inputDate.isAfter(startOfLast7Days))
      )
    })
  }, [threads])

  const monthThreads = useMemo(
    () =>
      threads?.filter((thread) => {
        const currentDate = dayjs().startOf('day')
        const inputDate = dayjs(thread.timestamp).startOf('day')
        const startOfLast30Days = currentDate.subtract(30, 'day')
        const startOfLast7Days = currentDate.subtract(6, 'day')
        return (
          inputDate.isBefore(startOfLast7Days) &&
          (inputDate.isSame(startOfLast30Days, 'day') || inputDate.isAfter(startOfLast30Days))
        )
      }),
    [threads]
  )

  const Entry = (props: { thread: Thread }) => {
    const { thread } = props
    const [menuOpen, setMenuOpen] = React.useState(false)

    const selected = selectedThread?.id === thread.id

    const items = [{ key: Items.DeleteThread, label: 'Delete Thread', danger: true }]

    const onClick: MenuClickEventHandler = ({ key, domEvent }) => {
      domEvent.stopPropagation()

      switch (key) {
        case Items.DeleteThread: {
          deleteThread(thread!)
          break
        }

        default: {
          throw new Error(`Unknown menu item: ${key}`)
        }
      }
    }

    return (
      <div
        className={`${selected ? styles.selectedEntryContainer : styles.entryContainer} ${
          disabled ? styles.disabled : ''
        }`}
        onClick={() => {
          if (disabled) {
            return
          }

          if (error) {
            setError(null)
          }

          setSelectedThread(thread)
        }}
      >
        <div className={selected ? styles.selectedContent : styles.content}>
          <span className={styles.title} data-testid={selected ? `selected_${thread.id}` : ''}>
            {thread?.title ? thread?.title : 'New Message'}
          </span>
        </div>

        {!disabled && deleteThreadDisabled !== thread.id && !createTitleLoading && (
          <div className={menuOpen || selected ? '' : styles.rightContainer}>
            <MoreIconDropdown
              menu={{ items, onClick }}
              onOpenChange={(open) => setMenuOpen(open)}
              horizontal
              color="#AAA"
              containerStyle={{ border: 'none', height: '10px' }}
              iconStyles={{ fontSize: '16px' }}
              trigger={['hover']}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.container} style={{ height: `${viewportHeight - 170}px` }}>
      <div className={styles.selectList}>
        {todayThreads && todayThreads?.length > 0 && (
          <div className={styles.todayContainer}>
            <span className={styles.dateTitle}>Today</span>
            {todayThreads
              ?.sort((a, b) => {
                // Convert ISO string to date and then get the time in milliseconds
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
              })
              .map((thread) => {
                return <Entry thread={thread} key={thread.id} />
              })}
          </div>
        )}

        {weekThreads && weekThreads?.length > 0 && (
          <div className={styles.todayContainer}>
            <span className={styles.dateTitle}>Previous 7 Days</span>
            {weekThreads
              ?.sort((a, b) => {
                // Convert ISO string to date and then get the time in milliseconds
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
              })
              .map((thread) => {
                return <Entry thread={thread} key={thread.id} />
              })}
          </div>
        )}

        {monthThreads && monthThreads?.length > 0 && (
          <div className={styles.todayContainer}>
            <span className={styles.dateTitle}>Previous 30 days</span>
            {monthThreads
              ?.sort((a, b) => {
                // Convert ISO string to date and then get the time in milliseconds
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
              })
              .map((thread) => {
                return <Entry thread={thread} key={thread.id} />
              })}
          </div>
        )}
      </div>
    </div>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Avatar, List, Tabs } from 'antd'
import { GetGroup, UpdateGroup } from 'data/group/actions'
import { Group, RoleEnum } from 'gen/cloudTruthRestApi'
import React, { useEffect, useMemo, useState } from 'react'
import { TypedThunk, idFromUrl } from 'data/dataUtils'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { getCurrentUser, useSelectUsers, userEntitySelectors } from 'data/user/selectors'
import { getEnvironmentGroupGrants, getProjectGroupGrants } from 'data/grant/selectors'
import { groupEntitySelectors, useSelectGroups } from 'data/group/selectors'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useHistoryPush, useOrganizationParams } from 'router/customHooks'

import { BackToList } from 'components/BackToList'
import { ChangeGrantRole } from 'components/AccessControl/ChangeGrantRole'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { GetGrants } from 'data/grant/actions'
import { GroupDetails } from './GroupDetails'
import { KeyOutlined } from '@ant-design/icons'
import { Label } from 'components/Forms'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { PageLoading } from 'components/PageLoading'
import { User } from '@sentry/react'
import { UserAvatar } from 'components/UserAvatar'
import { UserSelector } from '../UserSelector'
import { formatRole } from 'components/AccessControl/utils'
import { getPolicy } from 'data/membership/selectors'
import styles from './GroupPage.module.scss'
import { useLocation } from 'react-router-dom'
import { useToast } from 'hooks'

enum TabKeyEnum {
  UserKey = 'users',
  EnvironmentsKey = 'environmentGrants',
  ProjectsKey = 'projectGrants',
}

const { UserKey, EnvironmentsKey, ProjectsKey } = TabKeyEnum

export function GroupPage() {
  const groupId = useOrganizationParams().groupId!
  const group = groupEntitySelectors.selectById(useSelectGroups(), groupId)!
  const { goToOrgRoute } = useHistoryPush()
  const userEntities = userEntitySelectors.selectEntities(useSelectUsers())
  const projectGrants = useAppSelector(getProjectGroupGrants(group?.url))
  const environmentGrants = useAppSelector(getEnvironmentGroupGrants(group?.url))
  const currentUser = useAppSelector(getCurrentUser)
  const { hash } = useLocation()
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const users = useMemo(() => {
    return group
      ? group.users
          .map((url) => userEntities[idFromUrl(url)]!)
          .sort((user1, user2) => {
            if (user1.id === currentUser?.id) {
              return -1
            } else if (user2.id === currentUser?.id) {
              return 1
            } else {
              return 0
            }
          })
      : []
  }, [group, userEntities, currentUser])

  const userValues = useMemo(() => {
    return users.map(({ url }) => url)
  }, [users])

  const [loading, setLoading] = useState(true)
  const [updateConfirmVisible, setUpdateConfirmVisible] = useState(false)
  const [updateConfirmCallback, setUpdateConfirmCallback] = useState<() => void>(() => null)
  const [tabs, setTabs] = useState(hash.substring(1) || UserKey)
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())

  const getProjectNameByUrl = (url: string) => {
    const projectId = idFromUrl(url)

    return projectEntities[projectId]?.name
  }

  const getEnvironmentNameByUrl = (url: string) => {
    const environmentId = idFromUrl(url)

    return environmentEntities[environmentId]?.name
  }

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  useEffect(() => {
    const getGroup = new Promise((resolve) => {
      return dispatch(GetGroup(groupId)).then(() => {
        setLoading(false)
        resolve({})
      })
    })

    const getGrants = new Promise((resolve) => {
      return dispatch(GetGrants(null)).then(() => {
        setLoading(false)
        resolve({})
      })
    })

    Promise.all([getGroup, getGrants])
  }, [groupId, dispatch])

  useEffect(() => {
    if (!loading && !group) {
      goToOrgRoute(OrganizationPaths.Groups)
    }
  }, [loading, group, goToOrgRoute])

  const updateGroup = (values: User['url'][]) => {
    dispatch(UpdateGroup({ ...group, users: values })).then(({ error }: TypedThunk<Group>) => {
      if (error) {
        errorToast(error.message)

        return
      }

      successToast('Group successfully updated')

      setUpdateConfirmVisible(false)
    })
  }

  const onChange = (values: User['url'][]) => {
    if (values.length + 1 > users.length) {
      updateGroup(values)
    } else {
      setUpdateConfirmCallback(() => () => updateGroup(values))
      setUpdateConfirmVisible(true)
    }
  }

  if (!loading && !group) {
    return null
  }

  if (loading) {
    return <PageLoading />
  }

  const usersTab = (
    <>
      {' '}
      <Label text="Modify Users" />
      <div className={styles.select}>
        <UserSelector values={userValues} setValues={onChange} disabled={!canAdministrate} />
      </div>
      <br />
      <List
        className={styles.userList}
        dataSource={users}
        pagination={{ pageSize: 5 }}
        renderItem={(user) => (
          <List.Item>
            {user.type === 'service' ? (
              <List.Item.Meta
                className={styles.meta}
                avatar={<Avatar icon={<KeyOutlined rotate={180} />} />}
                title={`${user.name}${user.id === currentUser?.id ? ' (You)' : ''} `}
                description={formatRole(user.role as RoleEnum)}
              />
            ) : (
              <List.Item.Meta
                className={styles.meta}
                avatar={<UserAvatar pictureUrl={user.picture_url} />}
                title={`${user.name}${user.id === currentUser?.id ? ' (You)' : ''} `}
                description={formatRole(user.role as RoleEnum)}
              />
            )}
          </List.Item>
        )}
      />
    </>
  )

  const projectsTab = (
    <List
      pagination={{ pageSize: 8 }}
      className={styles.projectGrantList}
      dataSource={projectGrants}
      renderItem={(grant) => (
        <List.Item
          actions={[
            <ChangeGrantRole
              grant={grant}
              principal={group.url}
              scope={grant.scope}
              disabled={!canAdministrate}
              key="changeRole1"
            />,
          ]}
        >
          <List.Item.Meta className={styles.meta} title={getProjectNameByUrl(grant.scope)} />
        </List.Item>
      )}
    />
  )

  const environmentsTab = (
    <List
      pagination={{ pageSize: 8 }}
      className={styles.projectGrantList}
      dataSource={environmentGrants}
      renderItem={(grant) => (
        <List.Item
          actions={[
            <ChangeGrantRole
              grant={grant}
              principal={group.url}
              scope={grant.scope}
              disabled={!canAdministrate}
              key="changeRole2"
            />,
          ]}
        >
          <List.Item.Meta className={styles.meta} title={getEnvironmentNameByUrl(grant.scope)} />
        </List.Item>
      )}
    />
  )

  const items = [
    { key: UserKey, label: 'Users', children: usersTab },
    { key: ProjectsKey, label: 'Project Grants', children: projectsTab },
    { key: EnvironmentsKey, label: 'Environment Grants', children: environmentsTab },
  ]

  return (
    <div className={styles.container}>
      <div id="confirm">
        <ConfirmModal
          visible={updateConfirmVisible}
          onOk={updateConfirmCallback}
          onCancel={() => setUpdateConfirmVisible(false)}
          subject="Are you sure you want to remove this user from the group?"
        />
      </div>
      <BackToList onLinkClick={() => goToOrgRoute(OrganizationPaths.Groups)} text="Group List" />
      <GroupDetails group={group} />

      <Tabs
        activeKey={tabs}
        onChange={(key) => {
          setTabs(key)
          window.location.hash = key
        }}
        items={items}
      />
    </div>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
/* eslint no-useless-escape: 0 */

import { Ace } from 'ace-builds'
import { ProjectDetails } from 'data/project/reducer'
import { templateSnippets } from './templateSnippets'
import { templateUtils } from './utils'

export interface ExcludeCompleters {
  parameters?: string[]
  templates?: string[]
}

const bracketRegex = /\{\{\s*[\w\.]+\s*\[\s*(['"])\w+\1\s*\]\s*\}\}/

export const liquefyName = (value: string): string => {
  if (!value.match(bracketRegex)) {
    return value
  } else {
    return `["${value}"]`
  }
}

export const liquefyParameterName = (value: string): string => {
  if (!value.match(bracketRegex)) {
    return `cloudtruth.parameters.${value}`
  } else {
    return `cloudtruth.parameters["${value}"]`
  }
}

export const liquefyTemplateName = (value: string): string => {
  if (!value.match(bracketRegex)) {
    return `cloudtruth.templates.${value}`
  } else {
    return `cloudtruth.templates["${value}"]`
  }
}

export const liquefyProjectName = (value: string): string => {
  if (!value.match(bracketRegex)) {
    return `cloudtruth.projects.${value}`
  } else {
    return `cloudtruth.projects["${value}"]`
  }
}

export const basicCompleters = (
  currentProject: maybe<ProjectDetails>,
  exclude: maybe<ExcludeCompleters>,
  includeOnly: maybe<string[]>
): Ace.Completion[] => {
  const completions: Ace.Completion[] = []

  if (!currentProject) {
    return []
  }

  if (includeOnly) {
    includeOnly.forEach((snippet) => {
      completions.push({
        score: 0,
        value: liquefyParameterName(snippet),
        caption: snippet,
      })
    })
  } else {
    currentProject?.parameters?.forEach((parameter) => {
      if (!exclude?.parameters?.includes(parameter.name)) {
        completions.push({
          score: 0,
          value: parameter.name,
          caption: parameter.name,
          meta: parameter.description,
        })
      }
    })
  }

  // build implicit cloudtruth vars
  ;(['projects', 'environment', 'project', 'parameters', 'templates', 'self'] as string[]).forEach(
    (value) => {
      completions.push({
        score: 0,
        value: `cloudtruth.${value}`,
        caption: `cloudtruth.${value}`,
      })
    }
  )

  return completions
}

export const buildSnippets = () => {
  return templateSnippets.map((snippet) => {
    return {
      score: 0,
      value: snippet.snippet,
      caption: snippet.keyword,
      meta: snippet.description,
      completer: {
        insertMatch: (editor: Ace.Editor, data: Ace.Completion) => {
          templateUtils.insertSnippet(editor, data, snippet)
        },
      },
    }
  }) as Ace.Completion[]
}

export const buildProjectCompleters = (match: RegExpMatchArray): Ace.Completion[] => {
  const projName = match[1]

  return [
    {
      score: 0,
      value: `cloudtruth.projects.${projName}.parameters`,
    },
    {
      score: 0,
      value: `cloudtruth.projects.${projName}.templates`,
    },
  ]
}

export const buildEnvironmentCompleters = (_: RegExpMatchArray): Ace.Completion[] => {
  return [
    {
      score: 0,
      value: `cloudtruth.environment.children`,
    },
  ]
}

export const buildProjectResourceCompleters = (
  match: RegExpMatchArray,
  projects: ProjectDetails[],
  type: string,
  requestResources: (projectId: string) => void,
  projectName: string
): Ace.Completion[] => {
  if (!projectName || projects.length < 1) {
    return []
  }

  const project = projects.find((proj) => proj.name === projectName)
  const brackets = match[0].match(bracketRegex)

  if (project) {
    const resources = type === 'parameters' ? project.parameters : project.templates

    if (resources) {
      return resources.map((obj) => ({
        score: 0,
        name: obj.name,
        value: brackets
          ? obj.name
          : `cloudtruth.projects.${projectName}.${
              type === 'parameters' ? 'parameters' : 'templates'
            }.${obj.name}`,
      }))
    } else {
      requestResources(project.id)
      return []
    }
  } else {
    return []
  }
}

export const buildResourceCompleters = (
  match: RegExpMatchArray,
  projects: ProjectDetails[],
  type: 'parameters' | 'project' | 'templates',
  currentProject: maybe<ProjectDetails>
): Ace.Completion[] => {
  const brackets = match[0].match(bracketRegex)

  if (projects.length < 1) {
    return []
  }

  if (type === 'project') {
    return projects.map((project) => ({
      score: 0,
      value: brackets ? project.name : `cloudtruth.projects.${project.name}`,
    }))
  } else {
    const project = projects.find((proj) => proj.name === currentProject?.name)

    if (!project || !currentProject) {
      return []
    }

    if (type === 'parameters' && currentProject.parameters) {
      return currentProject.parameters.map((parameter) => ({
        score: 0,
        value: brackets ? project.name : `cloudtruth.parameters.${parameter.name}`,
      }))
    }

    if (type === 'templates' && currentProject.templates) {
      return currentProject.templates.map((template) => ({
        score: 0,
        value: brackets ? project.name : `cloudtruth.templates.${template.name}`,
      }))
    }
  }

  return []
}

const cloudtruthFilters: Ace.Completion[] = [
  {
    score: 0,
    value: 'dns_safe',
    meta: 'Ensures the value is safe for use as a DNS name or Kubernetes resources name.',
  },
  {
    score: 0,
    value: 'env_safe',
    meta: 'Ensures the value is safe for setting as shell environment variable.',
  },
  {
    score: 0,
    value: 'key_safe',
    meta: 'Ensures the value is safe for use as a key inside the Kubernetes ConfigMap/Secret  data hash.',
  },
  { score: 0, value: 'indent:', meta: 'Indents each line in the value by count spaces.' },
  {
    score: 0,
    value: 'nindent:',
    meta: 'Adds a leading newline, then indents each line in the value by count spaces.',
  },
  { score: 0, value: 'parse_yaml', meta: 'Parses a YAML string into a structured representation.' },
  { score: 0, value: 'to_yaml', meta: 'Converts an object to a YAML representation.' },
  { score: 0, value: 'parse_json', meta: 'Parses a JSON string into a structured representation.' },
  { score: 0, value: 'to_json', meta: 'Converts an object to a JSON representation.' },
  { score: 0, value: 'encode64', meta: 'Base64 encodes a value.' },
  { score: 0, value: 'decode64', meta: 'Base64 decodes a value.' },
  { score: 0, value: 'sha256', meta: 'Takes the sha256 digest of a value.' },
  { score: 0, value: 'merge:', meta: 'Merges the other hash value into this one.' },
  {
    score: 0,
    value: 're_replace:',
    meta: 'Regular expression replaces pattern pat in the value with replacement repl, with optional flags "i" for ignore case, "m" for multiline and "x" for extended.',
  },
  {
    score: 0,
    value: 're_contains:',
    meta: 'Returns a boolean indicating whether the pattern pat is found in the value, with optional flags as re_replace.',
  },
  {
    score: 0,
    value: 'where_not',
    meta: 'Creates an array including only the objects without a given property value, or any falsey value by default.',
  },
]

export const templateFilters: Ace.Completion[] = [
  'abs',
  'append',
  'at_least',
  'at_most',
  'capitalize',
  'ceil',
  'compact',
  'concat',
  'date',
  'default',
  'divided_by',
  'downcase',
  'escape',
  'escape_once',
  'first',
  'floor',
  'join',
  'last',
  'lstrip',
  'map',
  'minus',
  'modulo',
  'newline_to_br',
  'plus',
  'prepend',
  'remove',
  'remove_first',
  'replace',
  'replace_first',
  'reverse',
  'round',
  'rstrip',
  'size',
  'slice',
  'sort',
  'sort_natural',
  'split',
  'strip',
  'strip_html',
  'strip_newlines',
  'times',
  'truncate',
  'truncatewords',
  'uniq',
  'upcase',
  'url_decode',
  'url_encode',
  'where',
]
  .map((value) => ({ score: 0, value }))
  .concat(cloudtruthFilters)

import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { FormData, useForm } from 'components/Forms'
import React, { useState } from 'react'
import { membershipEntitySelectors, useSelectMemberships } from 'data/membership/selectors'

import { ChangeRoleForm } from './ChangeRoleForm'
import { EditModal } from 'components/Modals/EditModal'
import { GetUser } from 'data/user/actions'
import { UpdateMembership } from 'data/membership/actions'
import { User } from 'gen/cloudTruthRestApi'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  user: User
  visible: boolean
  setVisible: (bool: boolean) => void
}

export function ChangeMemberRole(props: Props) {
  const { user, setVisible, visible } = props

  const membership = membershipEntitySelectors.selectEntities(useSelectMemberships())[
    user.membership_id!
  ]

  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const onFinish = (data: FormData) => {
    setLoading(true)
    const role = data.role

    dispatch(UpdateMembership({ ...membership, role })).then(({ error, payload }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
      } else {
        dispatch(GetUser({ id: idFromUrl(payload.user), dontUpdateCurrent: true })).then(() => {
          successToast('User role successfully updated'), setVisible(false), setLoading(false)
        })
      }
    })
  }

  return (
    <>
      <EditModal
        visible={visible}
        objectName="User"
        okText={'Update'}
        onCancel={() => {
          setVisible(false), form.resetFields()
        }}
        onOk={form.submit}
        pending={loading}
      >
        <ChangeRoleForm form={form} loading={loading} user={user} onFinish={onFinish} />
      </EditModal>
    </>
  )
}

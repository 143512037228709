//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Checkbox, FormRule, Input } from 'antd'
import { Form, Item, LabeledInputItem, useForm } from '../Forms'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { CreateOrganization } from 'data/organization/actions'
import { CustomThunk } from 'data/dataUtils'
import { OrganizationCreate } from 'gen/cloudTruthRestApi'
import { RootPaths } from 'router/Router'
import { authService } from 'lib/authService'
import styles from './Organizations.module.scss'
import { termsOfServiceUrl } from 'lib/valueHelpers'
import { useAppDispatch } from 'data/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistoryPush } from 'router/customHooks'

//
// This page handles the case if a user has no organization yet,
// or if they have an early access organization without a subscription yet.
//

export function Organizations() {
  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [form] = useForm()
  const auth0Context = useAuth0()
  const dispatch = useAppDispatch()
  const { goToRootRoute } = useHistoryPush()

  const createOrg = () => {
    setError(null)
    setDisabled(true) // disables button to eliminate double-click
    const org: OrganizationCreate = {
      name: form.getFieldValue('name'),
    }
    dispatch(CreateOrganization(org)).then(({ error }: CustomThunk) => {
      if (error) {
        setError(error.message)
        setDisabled(false)
      } else {
        goToRootRoute(RootPaths.App)
      }
    })
  }

  function onTosCheckboxChange(e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) }
  }) {
    setChecked(e.target.checked)
  }

  const tosValidation = (_rule: FormRule, _value: any) => {
    if (checked) {
      return Promise.resolve()
    }
    return Promise.reject('Accept the CloudTruth Terms of Service')
  }

  const TOS = () => {
    return (
      <>
        Click the checkbox below to indicate you have
        <br />
        reviewed the{' '}
        <a href={termsOfServiceUrl} target="_blank" rel="noreferrer">
          CloudTruth Terms of Service
        </a>
        .
        <div className={styles.tos}>
          <Item name="tos" rules={[{ validator: tosValidation }]}>
            <Checkbox checked={checked} onChange={onTosCheckboxChange} data-cy="checkbox">
              I accept the Terms of Service.
            </Checkbox>
          </Item>
        </div>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <div className={styles.logo} data-cy="logo" />
      </div>
      <div className={styles.welcome}>Welcome to CloudTruth!</div>
      <div className={styles.noOrgMsg}>
        Create a new organization to begin using Community Edition.
        <br />
        No payment information is necessary to get started.
        <br />
        Community Edition is FREE forever!
      </div>
      <div className={styles.noOrgMsg}>
        <Form form={form} onFinish={createOrg}>
          <div className={styles.noOrgMsg}>
            <LabeledInputItem
              name="name"
              label="Organization Name"
              required="Enter an organization name."
            >
              <Input />
            </LabeledInputItem>
            {error && <p className={styles.error}>{error}</p>}
          </div>
          <TOS />
          <div className={styles.createButton}>
            <ActionButton type="primary" onClick={form.submit} disabled={disabled}>
              Create Organization
            </ActionButton>
          </div>
          <div className={styles.link} onClick={() => authService.logout(auth0Context)}>
            Sign Out
          </div>
        </Form>
      </div>
    </div>
  )
}

import { Divider, Empty, Radio, RadioChangeEvent, Spin } from 'antd'
import { ParameterTimeline, ParameterTimelineEntry } from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'

import { ChangeHistoryTable } from './ChangeHistoryTable'
import { ChangeHistoryTimeline } from './ChangeHistoryTimeline'
import { GetParameterTimeline } from 'data/parameter/actions'
import { PageLoading } from 'components/PageLoading'
import { TypedThunk } from 'data/dataUtils'
import styles from './ChangeHistory.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  paramId: string
  projectId: string
}

export function ChangeHistory(props: Props) {
  const { paramId, projectId } = props
  const [value, setValue] = useState('timeline')
  const [timeline, setTimeline] = useState<ParameterTimelineEntry[]>([])
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  useEffect(() => {
    if (window.location.hash === '#history') {
      dispatch(
        GetParameterTimeline({ projectId: projectId, paramId: paramId, maskSecret: false })
      ).then(({ error, payload }: TypedThunk<ParameterTimeline>) => {
        error
          ? errorToast(error.message)
          : setTimeline(payload?.results as ParameterTimelineEntry[])
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorToast, paramId, projectId, window.location.hash])

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  if (loading) {
    return <PageLoading />
  }

  const tableTimelineSwitch = (
    <Radio.Group className={styles.radio} value={value} onChange={onChange} buttonStyle="solid">
      <Radio.Button value={'timeline'}>Timeline</Radio.Button>
      <Radio.Button value={'table'}>Table</Radio.Button>
    </Radio.Group>
  )

  return (
    <div className={styles.container}>
      <div className={styles.timelineContainer}>
        {value === 'timeline' ? (
          <>
            <div className={styles.headerContainer}>
              <h2>Timeline</h2>
              {tableTimelineSwitch}
            </div>
            <Divider className={styles.divider} />
            {loading ? (
              <div className={styles.spinContainer}>
                <Spin />
              </div>
            ) : timeline.length === 0 ? (
              <Empty
                className={styles.emptyContainer}
                description="There is no recent history for this parameter."
              />
            ) : (
              <ChangeHistoryTimeline timeline={timeline} />
            )}
          </>
        ) : (
          <>
            <div className={styles.headerContainer}>
              <h2>Change History</h2>
              {tableTimelineSwitch}
            </div>
            <Divider className={styles.divider} />
            {loading ? (
              <div className={styles.spinContainer}>
                <Spin />
              </div>
            ) : (
              <ChangeHistoryTable timeline={timeline} loading={loading} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

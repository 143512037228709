//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionButton } from 'components/ActionButton'
import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import styles from './Reload.module.scss'

interface Props {
  onClick: () => any
  loading: boolean
  marginLeft?: string
  marginRight?: string
}

export function Reload(props: Props) {
  const { onClick, loading, marginLeft, marginRight } = props

  return (
    <ActionButton
      disabled={loading}
      onClick={onClick}
      style={{ marginLeft, marginRight }}
      dataCy="reload-button"
    >
      <ReloadOutlined className={loading ? styles.disabled : styles.reload} data-cy="reload-icon" />
    </ActionButton>
  )
}

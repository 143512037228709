//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPullTask, AwsPushTask, StateEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Switch, TableProps, Tooltip } from 'antd'

import { ActionEnum } from 'data/actions/utils'
import { DisconnectOutlined } from '@ant-design/icons/lib'
import { Reload } from '../ActionsReload/Reload'
import { StepTable } from './StepTable'
import { Table } from 'components/Table'
import { TableTitle } from 'components/TableTitle'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './TaskTable.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

type Task = AwsPushTask | AwsPullTask

interface Props {
  tasks: Task[]
  loading: boolean
  refreshPush: () => void
  actionType: ActionEnum
}

const PAGE_SIZE = 14

export function TaskTable(props: Props) {
  const { loading, tasks, refreshPush, actionType } = props
  const [onlyChangesChecked, setOnlyChangesChecked] = useState(false)

  const expandedRowRender = (task: Task) => (
    <StepTable taskId={task.id} actionType={actionType} onlyChanges={onlyChangesChecked} />
  )

  const [columns, setColumns] = useState<TableProps<Task>['columns']>([
    {
      key: 'dry_run',
      width: 30,
      render: (_value, task: Task) =>
        task.dry_run ? (
          <Tooltip placement={'bottom'} color="#434343" title={'Dry Run'}>
            <DisconnectOutlined />
          </Tooltip>
        ) : null,
    },
    {
      title: 'Reason',
      key: 'reason',
      ellipsis: true,
      width: 200,
      render: (_value, task: Task) => <div className={styles.valueEllipses}>{task.reason}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      ellipsis: true,
      width: 150,
      render: (_value, task: Task) => <div className={styles.valueEllipses}>{task.state}</div>,
    },
    {
      title: 'Error Code',
      key: 'errorCode',
      ellipsis: true,
      width: 150,
      render: (_value, task: Task) => (
        <div className={styles.valueEllipses}>{valueOrEmDash(task.error_code)}</div>
      ),
    },
    {
      title: 'Error Detail',
      key: 'errorDetail',
      ellipsis: true,
      width: 350,
      render: (_value, task: Task) => (
        <div className={styles.valueEllipses}>
          {!task.error_detail && task.state === StateEnum.Failure
            ? 'Expand row to see error details'
            : valueOrEmDash(task.error_detail)}
        </div>
      ),
    },
    {
      title: 'Time',
      key: 'time',
      ellipsis: true,
      width: 200,
      render: (_value, task: Task) => (
        <div className={styles.valueEllipses}>{formatDateTime(task.modified_at)}</div>
      ),
    },
  ])
  const reload = () => {
    refreshPush()
  }

  return (
    <div className={styles.container}>
      <Table
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        expandable={{ expandedRowRender }}
        title={() => (
          <div className={styles.hasChangesContainer}>
            <div className={styles.titleContainer}>
              <TableTitle title="Tasks" />{' '}
              <Reload loading={loading} reload={reload} listLength={tasks.length} />
            </div>

            <span>
              Only Changes{' '}
              <Switch
                checked={onlyChangesChecked}
                onChange={(bool) => setOnlyChangesChecked(bool)}
              />
            </span>
          </div>
        )}
        columns={!loading ? resizableColumns(columns!, setColumns) : undefined}
        pagination={{
          total: tasks.length,
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : tasks}
        rowKey={(push: AwsPushTask) => push.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>There are no tasks for this action</h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  )
}

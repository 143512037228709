//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { CreateGroup, DeleteGroup, GetGroup, GetGroups, UpdateGroup } from './actions'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Group } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface GroupState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const groupAdapter = createEntityAdapter<Group>({
  selectId: (org) => org.id,
})

const initialState = groupAdapter.getInitialState({
  cacheLastChanged: epoch,
} as GroupState)

export type GroupRootState = EntityState<Group> & GroupState

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setAllGroup: groupAdapter.setAll,
    addOneGroup: groupAdapter.addOne,
    updateOneGroup: groupAdapter.upsertOne,
    removeOneGroup: groupAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateGroup.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      groupAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetGroups.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())

      groupAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetGroup.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      groupAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateGroup.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      groupAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteGroup.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      groupAdapter.removeOne(state, action.payload)
    })
  },
})

export const { resetState, setAllGroup, updateOneGroup, removeOneGroup, addOneGroup } =
  groupSlice.actions

export default groupSlice.reducer

import React, { useState } from 'react'
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'

import { Input } from 'antd'
import styles from './ScanDisplayFilters.module.scss'

interface Props {
  venueSort: 'asc' | 'desc'
  setVenueSort: (sort: 'asc' | 'desc') => void
  setVenueSearch: (string: string) => void
}

export function ScanDisplayFilters(props: Props) {
  const { venueSort, setVenueSearch, setVenueSort } = props

  const onSearch = (value: string) => setVenueSearch(value)
  const handleSort = () => setVenueSort(venueSort === 'asc' ? 'desc' : 'asc')

  const [liveSearchValue, setLiveSearchValue] = useState('')

  return (
    <div className={styles.container}>
      <Input
        placeholder="search by venue name"
        data-testid="search"
        onChange={(e) => {
          if (!e.target.value) {
            onSearch('')
          }
          setLiveSearchValue(e.target.value)
        }}
        className={styles.search}
        suffix={
          <SearchOutlined
            style={{ cursor: 'pointer' }}
            data-testid="submit-search"
            onClick={() => {
              onSearch(liveSearchValue)
            }}
          />
        }
        onPressEnter={() => onSearch(liveSearchValue)}
        allowClear
      />
      {venueSort === 'desc' ? (
        <SortAscendingOutlined onClick={handleSort} className={styles.sort} />
      ) : (
        <SortDescendingOutlined onClick={handleSort} className={styles.sort} />
      )}
    </div>
  )
}

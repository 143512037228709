//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  PaginatedServiceAccountList,
  ServiceAccount,
  ServiceAccountCreateRequest,
  ServiceAccountCreateResponse,
  ServiceAccountRekeyRequest,
  ServiceAccountUpdateRequest,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

interface Update {
  id: string
  request: ServiceAccountUpdateRequest
}

interface ReKey {
  id: string
  request: ServiceAccountRekeyRequest
}

export const GetServiceAccounts = createAsyncThunk<PaginatedServiceAccountList, null, ThunkConfig>(
  'serviceAccount/getServiceAccounts',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'serviceaccounts', null, thunkApi)
)

export const GetServiceAccount = createAsyncThunk<ServiceAccount, string, ThunkConfig>(
  'serviceAccount/getServiceAccount',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `serviceaccounts/${id}`, null, thunkApi)
)

export const CreateServiceAccount = createAsyncThunk<
  ServiceAccountCreateResponse,
  ServiceAccountCreateRequest,
  ThunkConfig
>(
  'serviceAccount/createServiceAccount',
  async (serviceAccount, thunkApi) =>
    await handleFetch(Verbs.Post, 'serviceaccounts', serviceAccount, thunkApi)
)

export const ServiceAccountReKey = createAsyncThunk<any, ReKey, ThunkConfig>(
  'serviceAccount/ServiceAccountReKey',
  async (data, thunkApi) =>
    await handleFetch(Verbs.Post, `serviceaccounts/${data.id}/rekey`, data.request, thunkApi)
)

export const UpdateServiceAccount = createAsyncThunk<ServiceAccount, Update, ThunkConfig>(
  'serviceAccount/updateServiceAccount',
  async (data, thunkApi) =>
    await handleFetch(Verbs.Put, `serviceaccounts/${data.id}`, data.request, thunkApi)
)

export const DeleteServiceAccount = createAsyncThunk<string, string, ThunkConfig>(
  'serviceAccount/deleteServiceAccount',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `serviceaccounts/${id}`, null, thunkApi)
)

import { Form, TextArea } from 'components/Forms'

import { ActionButton } from 'components/ActionButton'
import { DocLink } from 'components/DocLink'
import { FormInstance } from 'antd'
import { JMES_PATH_FIELD } from '../ReferencedParameter'
import React from 'react'
import styles from './JMESPathSelector.module.scss'

interface Props {
  onUpdate: () => void
  hasError?: boolean
  form: FormInstance
  initialJmesPath?: nullable<string>
}

export function JMESPathSelector(props: Props) {
  const { onUpdate, hasError, form, initialJmesPath } = props

  return (
    <div className={styles.jmespathSelectorContainer}>
      <div className={styles.textArea}>
        <Form form={form} initialValues={{ [JMES_PATH_FIELD]: initialJmesPath || '' }}>
          <TextArea
            name={JMES_PATH_FIELD}
            rows={1}
            label={{
              text: 'jmespath selector',
              tooltipText:
                'Once you have selected a supported file type, you can type a JMESPath expression to select a reference value within the file. If no JMESPath is specified or the file type is not supported, the entire contents of a valid file choice will be used as the reference value.',
              docLink: (
                <>
                  <DocLink href="https://jmespath.org/" text="JMESPath Guide" />/
                  <DocLink
                    href="https://docs.cloudtruth.com/configuration-management/parameters/external-values#jmespath-file-queries"
                    text="Docs"
                  />
                </>
              ),
            }}
            placeholder="Select a value by typing a JMESPath expression"
            hasError={hasError}
            autoFocus={true}
          />
        </Form>
      </div>

      <ActionButton onClick={onUpdate}>See Value</ActionButton>
    </div>
  )
}

import React, { useMemo, useState } from 'react'

import { Tooltip } from 'antd'
import styles from './ProviderIcon.module.scss'

interface Props {
  fqn?: string
  isInterpolated?: boolean
}

export function ProviderIcon(props: Props) {
  const { fqn, isInterpolated } = props

  const [providerTooltip, setProviderTooltip] = useState<nullable<string>>(null)

  const className = useMemo(() => {
    if (fqn?.includes('github')) {
      if (isInterpolated) {
        setProviderTooltip('GitHub interpolated value')
        return styles.githubInterpolated
      } else {
        setProviderTooltip('GitHub value')
        return styles.github
      }
    } else if (fqn?.includes('aws')) {
      if (isInterpolated) {
        setProviderTooltip('AWS interpolated value')
        return styles.awsInterpolated
      } else {
        setProviderTooltip('AWS value')
        return styles.aws
      }
    } else if (isInterpolated) {
      setProviderTooltip('Interpolated value')
      return styles.interpolated
    } else if (fqn?.includes('akv')) {
      setProviderTooltip('AKV value')
      return styles.akv
    } else {
      return styles.internal
    }
  }, [fqn, isInterpolated])

  return (
    <>
      {providerTooltip ? (
        <Tooltip title={providerTooltip}>
          <div className={className} />
        </Tooltip>
      ) : (
        <div className={className} />
      )}
    </>
  )
}

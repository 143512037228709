import { Details, DetailsDate } from 'components/Details'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'

import { LabelText } from 'components/LabelText'
import { ParameterDetailsDropdown } from './ParameterDetailsDropdown'
import { ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'
import { RULE_TYPE_LABELS } from 'components/Types/Rules/RuleTile'
import React from 'react'
import { Typography } from 'antd'
import { emDash } from 'lib/valueHelpers'
import { getParameterRules } from 'data/parameterType/selectors'
import { getPolicy } from 'data/membership/selectors'
import { projectIdFromUrl } from 'data/dataUtils'
import styles from './ParameterDetails.module.scss'
import { useAppSelector } from 'data/hooks'
import { useProjectParams } from 'router/customHooks'

interface Props {
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>
}

export function ParameterDetails(props: Props) {
  const { setIsDeleting } = props
  const paramId = useProjectParams().parameterId!
  const { canContribute } = useAppSelector(getPolicy(null))
  const parameter = parameterEntitySelectors.selectById(useSelectParameters(), paramId)
  const rules = useAppSelector(getParameterRules(parameter))
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const currentProject = useAppSelector(getCurrentProject)

  const overrides = () => {
    const override =
      parameter?.overrides && currentProject?.name === parameter.project_name
        ? projectEntities[projectIdFromUrl(parameter.overrides)]?.name
        : null

    return override ? (
      <Typography.Text ellipsis className={styles.ellipsis}>
        {override}
      </Typography.Text>
    ) : (
      emDash
    )
  }

  if (!parameter) {
    return null
  }

  const { name, description, created_at, modified_at, expires } = parameter

  const days = expires === '0' ? 0 : (expires !== null && parseInt(expires || '')) || null
  const expiresText = `${days} day${days! > 1 ? 's' : ''}`

  const dropdownMenu = canContribute ? (
    <ParameterDetailsDropdown setIsDeleting={setIsDeleting} />
  ) : undefined

  return (
    <Details
      name={name || ''}
      dropdownMenu={dropdownMenu}
      description={description}
      styles={{ marginBottom: '24px' }}
      headerStyles={{ alignItems: 'flex-start' }}
    >
      <div className={styles.container}>
        <div className={styles.dateContainer}>
          <DetailsDate updatedAt={modified_at} createdAt={created_at} />
          <LabelText
            isHorizontal
            text={parameter.project_name === currentProject?.name ? emDash : parameter.project_name}
            label="Inherited From"
          />
          {expires !== null && (
            <div className={styles.expireContainer}>
              <LabelText isHorizontal text={expiresText} label="Expiration Time" />
            </div>
          )}
        </div>

        <div className={styles.rulesContainer}>
          <LabelText isHorizontal text={parameter.type} label="Type:" />
          {rules.length > 0 ? (
            rules.map((rule) => (
              <LabelText
                key={rule.id}
                isHorizontal
                text={
                  rule.type === ParameterRuleTypeEnum.OneOf
                    ? rule?.constraints?.join(', ')
                    : rule.constraint
                }
                label={
                  rule.type === ParameterRuleTypeEnum.OneOf
                    ? 'Enum'
                    : `${RULE_TYPE_LABELS[rule.type]}:`
                }
              />
            ))
          ) : (
            <LabelText isHorizontal text={emDash} label="Rules:" />
          )}
          <LabelText isHorizontal text={overrides()} label="Overrides" />
        </div>
      </div>
    </Details>
  )
}

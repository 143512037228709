//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useEffect, useMemo, useState } from 'react'
import { Dayjs } from 'dayjs'
import { diff as DiffEditor } from 'react-ace'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import { formatDateTime } from 'lib/dateHelpers'
import { localStorageHelpers } from 'lib/localStorageHelpers'
import styles from './TemplateComparison.module.scss'
import { useHistoryPush } from 'router/customHooks'

// These must be imported after AceEditor
// import-sort-ignore
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'components/Editor/templateAceDefinitions'
import 'components/Editor/editor.scss'

interface Props {
  name: string
  timestamp: nullable<Dayjs>
  currentBody?: string
  timeBody?: string
  id?: string
  tagName?: nullable<string>
}

export default function TemplateComparison(props: Props) {
  const { name, tagName, timestamp, currentBody, timeBody, id } = props
  const [showDiff, setShowDiff] = useState<boolean>(false)
  const { goToTemplateRoute } = useHistoryPush()

  const wrapText = useMemo(() => {
    return localStorageHelpers.getEditorSettings().wrapping ?? false
  }, [])

  const highlight = useMemo<boolean>(() => {
    return !!(currentBody && timeBody)
  }, [currentBody, timeBody])

  useEffect(() => {
    setShowDiff(false)
  }, [timestamp])

  const renderInfo = (): JSX.Element => {
    return !timestamp ? (
      <div className={styles.noDifference}>Select a time to view differences</div>
    ) : currentBody === timeBody ? (
      <div className={styles.noDifference}>No differences to display</div>
    ) : (
      <ShowHideButton
        showSettings={showDiff}
        toggleShowSettings={() => setShowDiff(!showDiff)}
        label="differences"
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          onClick={() => id && goToTemplateRoute(null, `?template=${id}`)}
          className={[styles.name, styles.link].join(' ')}
        >
          {name}
        </div>
        <div className={styles.infoContainer}>{renderInfo()}</div>
      </div>
      {showDiff && timestamp && (
        <>
          <div className={styles.diffHeader}>
            <p>Current</p>
            <p>{tagName || formatDateTime(timestamp!.toISOString())}</p>
          </div>
          <DiffEditor
            className={highlight ? 'diff-editor highlight' : 'diff-editor'}
            value={[currentBody ?? '*CTM*template deleted', timeBody ?? '*CTM*no record']}
            mode="template"
            theme="tomorrow"
            wrapEnabled={wrapText}
            readOnly={true}
            style={{ width: '100%' }}
          />
        </>
      )}
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AwsIntegrationRootState,
  awsIntegrationAdapter,
} from 'data/integration/awsIntegration/reducer'

import { RootState } from 'data/store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectAwsIntegration = (state: RootState) => state.awsIntegration

export const getCurrentAwsIntegration = createSelector(selectAwsIntegration, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const awsIntegrationEntitySelectors = awsIntegrationAdapter.getSelectors()
export const useSelectAwsIntegrations = (): AwsIntegrationRootState =>
  useSelectState().awsIntegration

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AppDispatch, RootState } from './store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

// Use throughout app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): any => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useSelectState = (): RootState => {
  return useAppSelector((state) => state)
}

import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteGithubIntegration } from 'data/integration/githubIntegration/actions'
import DeleteIntegrationOptionsModal from 'components/Modals/DeleteIntegrationOptionsModal'
import { GitHubIntegration } from 'gen/cloudTruthRestApi'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

enum MenuItemKeys {
  DeleteGithubIntegration = 'deleteGithubIntegration',
}

interface Props {
  githubIntegration: GitHubIntegration
}

export function GithubAccountsDropdownMenu(props: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false)
  const [deleteOptionsVisible, setDeleteOptionsVisible] = useState<boolean>(false)
  const { githubIntegration } = props
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const onDelete = () => {
    setLoading(true)
    dispatch(DeleteGithubIntegration({ id: githubIntegration.id, in_use: 'fail' })).then(
      ({ error }: CustomThunk) => {
        setLoading(false)
        setDeleteVisible(false)

        if (error?.message.includes('in use')) {
          setDeleteOptionsVisible(true)
        } else {
          error ? errorToast(error.message) : successToast('Integration successfully deleted')
        }
      }
    )
  }

  const forceDeleteIntegration = (option: 'leave' | 'remove'): void => {
    setLoading(true)

    dispatch(DeleteGithubIntegration({ id: githubIntegration.id, in_use: option })).then(
      ({ error }: CustomThunk) => {
        error ? errorToast(error.message) : successToast('Integration successfully deleted')
        setLoading(false)
        setDeleteOptionsVisible(false)
      }
    )
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteGithubIntegration: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    {
      key: MenuItemKeys.DeleteGithubIntegration,
      label: 'Delete',
      danger: true,
    },
  ]

  return (
    <>
      <DeleteConfirmModal
        closeModal={() => setDeleteVisible(false)}
        visible={deleteVisible}
        onDelete={onDelete}
        removePronoun={true}
        subject={`"${githubIntegration.name}"`}
      />
      <DeleteIntegrationOptionsModal
        closeModal={() => setDeleteOptionsVisible(false)}
        visible={deleteOptionsVisible}
        onDelete={forceDeleteIntegration}
        loading={loading}
      />

      <div>
        <MoreIconDropdown menu={{ items, onClick }} color="black" />
      </div>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useState } from 'react'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { CustomTypeModal } from './CustomTypeModal'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteParameterType } from 'data/parameterType/actions'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

enum MenuItemKeys {
  DeleteType = 'deleteType',
  EditType = 'editTypeDescription',
}

interface Props {
  id: string
}

export function TypeDropdownMenu(props: Props) {
  const { id } = props

  const type = parameterTypeEntitySelectors.selectById(useSelectParameterTypes(), id)!
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const [deleteVisible, setDeleteVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const [loading, setLoading] = useState(false)

  const deleteType = () => {
    setLoading(true)
    dispatch(DeleteParameterType(id)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setDeleteVisible(false)
      } else {
        successToast('Type successfully deleted')
      }
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteType: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.EditType: {
        setEditVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    { key: MenuItemKeys.EditType, label: 'Edit Type' },
    {
      key: MenuItemKeys.DeleteType,
      label: 'Delete Type',
      danger: true,
      disabled: !canAdministrate,
    },
  ]

  return (
    <>
      <DeleteConfirmModal
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deleteType}
        subject={`"${type.name}"`}
        loading={loading}
        removePronoun={true}
      />
      <CustomTypeModal visible={editVisible} setVisible={setEditVisible} type={type} />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </>
  )
}

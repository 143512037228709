import { LoggerUtils } from 'lib/loggerUtils'
import React from 'react'
import { Result } from 'antd'
import { authService } from 'lib/authService'
import { getCurrentUser } from 'data/user/selectors'
import { useAppSelector } from 'data/hooks'

interface Props {
  clear?: boolean
}

export default function ServerError({ clear }: Props) {
  const user = useAppSelector(getCurrentUser)

  LoggerUtils.captureMessage('An error occurred while loading resources', user?.id)

  if (clear) {
    authService.clearBrowserCache()
  }

  return (
    <Result
      status="500"
      title="Internal Server Error"
      subTitle="CloudTruth encountered a problem with your request. Our team has been alerted and will fix the problem as quickly as possible."
    />
  )
}

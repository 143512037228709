//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateOrganization,
  DeleteOrganization,
  GetOrganization,
  GetOrganizations,
  UpdateOrganization,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Organization } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'
import { localStorageHelpers } from 'lib/localStorageHelpers'

interface OrganizationState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const organizationAdapter = createEntityAdapter<Organization>({
  selectId: (org) => org.id,
})

const initialState = organizationAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: localStorageHelpers.getActiveOrgId(),
} as OrganizationState)

export type OrganizationRootState = EntityState<Organization> & OrganizationState

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // direct actions
    selectOrganization(state, action: PayloadAction<nullable<string>>) {
      setCurrentOrg(state, action.payload)
    },

    setAllOrganization: organizationAdapter.setAll,
    addOneOrganization: organizationAdapter.addOne,
    updateOneOrganization: organizationAdapter.upsertOne,
    removeOneOrganization: organizationAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateOrganization.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      organizationAdapter.addOne(state, action.payload)
      setCurrentOrg(state, action.payload.id)
    })
    builder.addCase(GetOrganizations.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const orgs = action.payload.results!
      organizationAdapter.setAll(state, orgs)
      if (orgs.length === 1) {
        setCurrentOrg(state, orgs[0].id)
      }
      if (state.current && !state.ids.includes(state.current)) {
        state.current = null
        localStorageHelpers.clearActiveIds()
      }
    })
    builder.addCase(GetOrganization.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      organizationAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateOrganization.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      organizationAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteOrganization.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      organizationAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

const setCurrentOrg = (state: OrganizationState, id: nullable<string>) => {
  state.current = id
  localStorageHelpers.setActiveOrgId(id)
}

export const {
  resetState,
  selectOrganization,
  setAllOrganization,
  updateOneOrganization,
  removeOneOrganization,
  addOneOrganization,
} = organizationSlice.actions

export default organizationSlice.reducer

import React, { PropsWithChildren } from 'react'

import { Link } from 'react-router-dom'
import styles from './DrawerTile.module.scss'

interface Props {
  title: string
  link?: Array<{
    linkText: string
    to: string
    policy?: boolean
  }>
  actionButton?: JSX.Element
}

export function DrawerTile(props: PropsWithChildren<Props>) {
  const { title, link, actionButton, children } = props
  return (
    <div className={styles.tile}>
      <div className={styles.tileHeader}>
        <h3>{title}</h3>
        <span>
          {link &&
            link
              .map((link) => (
                <span key={link.to}>
                  {typeof link.policy === 'boolean' ? (
                    <>
                      {link.policy && (
                        <Link key={link.to} to={link.to} className={styles.headerLink}>
                          {link.linkText}
                        </Link>
                      )}
                    </>
                  ) : (
                    <Link key={link.to} to={link.to} className={styles.headerLink}>
                      {link.linkText}
                    </Link>
                  )}
                </span>
              ))
              .reduce((prev, curr): any => [prev, ' | ', curr])}
          {actionButton}
        </span>
      </div>
      <div className={styles.tileComponentContainer}>{children}</div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionsHash, useHistoryPush, useOrganizationParams } from 'router/customHooks'
import { AwsPull, AwsPullTask, AzureKeyVaultPull, GitHubPull } from 'gen/cloudTruthRestApi'
import { GetAwsPull, GetAwsPullTasks } from 'data/actions/awsImport/actions'
import { GetAzureKeyVaultPull, GetAzureKeyVaultPullTasks } from 'data/actions/akvImport/actions'
import { GetGithubPull, GetGithubPullTasks } from 'data/actions/githubImport/actions'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { akvPullEntitySelectors, useSelectAkvPull } from 'data/actions/akvImport/selectors'
import { awsPullEntitySelectors, useSelectPull } from 'data/actions/awsImport/selectors'
import { githubPullEntitySelectors, useSelectGithubPull } from 'data/actions/githubImport/selectors'

import { ActionEnum } from 'data/actions/utils'
import { ActionViews } from 'components/Actions/ActionContainer'
import { BackToList } from 'components/BackToList'
import { CustomThunk } from 'data/dataUtils'
import { ImportDetails } from './ImportDetails'
import { PageLoading } from 'components/PageLoading'
import { TaskTable } from 'components/Actions/ActionsStepTable/TaskTable'
import { useAppDispatch } from 'data/hooks'

export type PullType = AwsPull | AzureKeyVaultPull | GitHubPull

interface Props {
  actionType: ActionEnum
}

export function ImportDetailsPage(props: Props) {
  const { actionType } = props

  const { actionId, integrationId } = useOrganizationParams()!
  const awsPull = awsPullEntitySelectors.selectById(useSelectPull(), actionId!)
  const akvPull = akvPullEntitySelectors.selectById(useSelectAkvPull(), actionId!)
  const githubPull = githubPullEntitySelectors.selectById(useSelectGithubPull(), actionId!)

  const pull = useMemo(() => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return awsPull

      case ActionEnum.AzureKeyVaultPull:
        return akvPull

      case ActionEnum.GithubPull:
        return githubPull

      default:
        return null
    }
  }, [actionType, awsPull, akvPull, githubPull])

  const [loading, setLoading] = useState(!pull)
  const [pullTasks, setPullTasks] = useState<AwsPullTask[]>([])

  const dispatch = useAppDispatch()
  const { goToActionsRoute } = useHistoryPush()

  const goToActions = useCallback(
    (actionType: ActionEnum) => {
      switch (actionType) {
        case ActionEnum.AwsPull: {
          goToActionsRoute(ActionViews.Import, ActionsHash.AWS)
          break
        }

        case ActionEnum.GithubPull: {
          goToActionsRoute(ActionViews.Import, ActionsHash.GITHUB)
          break
        }

        case ActionEnum.AzureKeyVaultPull: {
          goToActionsRoute(ActionViews.Import, ActionsHash.AKV)
          break
        }

        default:
          break
      }
    },
    [goToActionsRoute]
  )

  useEffect(() => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(GetAwsPullTasks({ integrationId: integrationId!, actionId: actionId! })).then(
          ({ error, payload }: CustomThunk) => {
            !error && setPullTasks(payload.results)
          }
        )
        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(
          GetAzureKeyVaultPullTasks({ integrationId: integrationId!, actionId: actionId! })
        ).then(({ error, payload }: CustomThunk) => {
          !error && setPullTasks(payload.results)
        })
        break

      case ActionEnum.GithubPull:
        dispatch(GetGithubPullTasks({ integrationId: integrationId!, actionId: actionId! })).then(
          ({ error, payload }: CustomThunk) => {
            !error && setPullTasks(payload.results)
          }
        )

        break

      default:
        break
    }
  }, [actionId, dispatch, integrationId, pull?.latest_task, actionType])

  const loadPull = useCallback(() => {
    setLoading(true)

    switch (actionType) {
      case ActionEnum.AwsPull:
        return dispatch(GetAwsPull({ integrationId: integrationId!, actionId: actionId! })).then(
          () => setLoading(false)
        )

      case ActionEnum.AzureKeyVaultPull:
        return dispatch(
          GetAzureKeyVaultPull({ integrationId: integrationId!, actionId: actionId! })
        ).then(() => setLoading(false))

      case ActionEnum.GithubPull:
        return dispatch(GetGithubPull({ integrationId: integrationId!, actionId: actionId! })).then(
          () => setLoading(false)
        )

      default:
        break
    }
  }, [actionId, dispatch, integrationId, actionType])

  useLayoutEffect(() => {
    if (!pull) {
      loadPull().then(() => setLoading(false))
    }
  }, [loadPull, pull])

  useLayoutEffect(() => {
    if (!loading && !pull) {
      goToActions(actionType)
    }
  }, [goToActions, loading, pull, actionType])

  if (!loading && !pull) {
    return null
  }

  if (loading || !pull) {
    return <PageLoading />
  }

  return (
    <div>
      <BackToList onLinkClick={() => goToActions(actionType)} text="Import List" />
      <ImportDetails refreshPull={loadPull} pull={pull} actionType={actionType} />
      <TaskTable
        tasks={pullTasks}
        loading={loading}
        refreshPush={loadPull}
        actionType={ActionEnum.AwsPull}
      />
    </div>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, LabeledInputItem, TextArea } from 'components/Forms'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'

import { CheckboxItem } from 'components/Forms/CheckboxItem'
import { EnvironmentSelector } from 'components/EnvironmentSelector'
import { FormInstance } from 'antd'
import React from 'react'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
}

export function AddEnvironmentForm(props: Props) {
  const { form, onFinish } = props
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())

  const parentEnvTooltip =
    'An environment will be created as a child of the existing environment and will inherit its parameter values from the parent.'

  const changeEnv = (parentId: string): void => {
    form.setFieldsValue({ parentId })
  }

  const nameValidator = (_: any, value: string) => {
    if (value && environments.find((env) => env.name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('Environment with this name already exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={{}}>
      <InlineLabelInput
        autoFocus
        name="name"
        label="Environment Name"
        placeholder="New environment name"
        required="Please enter an environment name."
        rules={[{ validator: nameValidator }]}
      />

      <LabeledInputItem
        name="parentId"
        label={{ text: 'Parent Environment' }}
        tooltipText={parentEnvTooltip}
      >
        <EnvironmentSelector dontChangeCurrent onEnvChange={changeEnv} />
      </LabeledInputItem>

      <CheckboxItem
        name="copy_rbac"
        text="Copy RBAC From Parent"
        style={{ marginBottom: '24px' }}
      />

      <TextArea name="description" label="Description" placeholder="Type description here" />
    </Form>
  )
}

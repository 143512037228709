//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { Dictionary } from '@reduxjs/toolkit/dist/entities'
import { Environment } from 'gen/cloudTruthRestApi'
import { TreeSelectProps } from 'antd'
import { idFromUrl } from 'data/dataUtils'

export const buildEnvironmentTree = (
  environmentEntities: Dictionary<Environment>
): TreeSelectProps['treeData'] => {
  const environments = Object.values(environmentEntities)

  const childEnvironmentFromUrl = (url: string) => {
    const id = idFromUrl(url)

    return environmentEntities[id]
  }

  const assembleChildren = (parent: Environment): TreeSelectProps['treeData'] => {
    return environments
      .filter((p) => p!.parent === parent.url)
      .map((p) => ({
        title: childEnvironmentFromUrl(p!.url)!.name,
        value: childEnvironmentFromUrl(p!.url)!.id,
        key: childEnvironmentFromUrl(p!.url)!.id,
        children: assembleChildren(p!),
      }))
  }

  return environments
    .filter((environment) => environment!.parent === null)
    .map((environment) => {
      return {
        title: environment!.name,
        value: environment!.id,
        key: environment!.id,
        expanded: true,
        children: assembleChildren(environment!),
      }
    })
}

import {
  createParameters,
  createProject,
  createTemplates,
  getCurrentProject,
  getParameters,
  getProjects,
  getTemplates,
  updateParameterDescriptions,
} from './functions'

import { AppDispatch } from 'data/store'
import { FileUpload } from './Gpt'
import { OpenAI } from 'openai'
import { Thread } from 'components/GPT/types'
import { UpdateUser } from 'data/user/actions'
import { User } from 'gen/cloudTruthRestApi'
import fix from 'json-fixer'

export enum Models {
  GPT3 = 'gpt-3.5-turbo-0125',
  GPT4 = 'gpt-4o',
}

interface Assistant {
  model: Models
  orgId: string
  orgName: string
}

interface Attachments {
  files: FileUpload[]
  images: FileUpload[]
}

interface CreateAssistantThread {
  user: User
  dispatch: AppDispatch
}

interface DeleteAssistantThread {
  user: User
  selectedThread: Thread
  dispatch: AppDispatch
}

const vectorStore = {
  md: 'vs_qKyZvDrf51q1zy8fYGbGDxrN',
  json: 'vs_ispOmD001mdmqRsLEbM1hCl1',
}

const copilotAssistants = {
  green: 'asst_D1F6VfQ2Yj8n0f1SaRZCyFZl',
  blue: 'asst_j7cVd1ygNvnhEih2LbQnp8ni',
}

const templateAssistants = {
  green: 'asst_1jIOJ10lDFP7pzFfOYnmepNW',
  blue: '',
}

export const currentCopilotAssistant = copilotAssistants.green
export const currentTemplateAssistant = templateAssistants.green

export const fileCache = {
  md: {
    object: 'file',
    id: 'file-K5O5RPGviPbVrCJlMhYPfSVv',
    purpose: 'assistants',
    filename: 'cloudtruth-docs.md',
    bytes: 370033,
    created_at: 1713954859,
    status: 'processed',
    status_details: null,
  },
  json: {
    object: 'file',
    id: 'file-OkDfyXD2wD2WEJh1AXncWErW',
    purpose: 'assistants',
    filename: 'output.json',
    bytes: 285676,
    created_at: 1714570813,
    status: 'processed',
    status_details: null,
  },
}

export const modelInstructions =
  'You are a assistant for the CloudTruth platform. When asked about how the CloudTruth platform works, you will always try to reference cloudtruth-docs.md without hallucinations. You are an expert in platform configuration and DevOps related technologies. When asked to create a template, always use liquid formatting, and reference cloudtruth-docs.md to understand how you can use cloudtruth parameters in them. When asked to make a template out of parameters or cloudtruth parameters, use the get_parameters function call to extract information for the ai agent to call an external api. Never reference previous parameter names given to you, always re-request. You also call the get_parameters call when users need insight about their own cloudtruth parameters.'

export const modelInstructionsV2 = `### **CloudTruth Assistant Instructions**

  #### **General Guidelines**
  1. **File Search for Instructions**:
     - **Action**: Always perform a file search for the relevant instructions or documentation attached to the assistant before proceeding with any task related to the CloudTruth platform.
     - **Purpose**: This ensures that you are using the most accurate and up-to-date information.
  2. **Avoid Assumptions**:
     - **Action**: Do not make assumptions about syntax or commands.
     - **Purpose**: Always verify against the provided instructions.
  3. **Explicit References**:
     - **Action**: Explicitly reference the instructions provided in the search results.
     - **Purpose**: Ensure that you are following the correct syntax and guidelines.

  #### **Template Creation Instructions**
  - **Liquid Formatting**: Always use liquid formatting.
  - **Syntax for Accessing CloudTruth Information**:
    - cloudtruth.self - access name of the current template
    - cloudtruth.project - access name of the current project
    - cloudtruth.environment - access name of the current environment
    - cloudtruth.environment.children - returns a list of the current environment's children
    - cloudtruth.templates.<name> - references a template from the current project
    - cloudtruth.parameters - references all parameters in a (name, value) array
    - cloudtruth.templates - references all templates in a (name, value) array
    - cloudtruth.parameters.<name> - references a parameter from the current project
    - cloudtruth.projects.<project_name>.templates.<name> - references a template from the specified project
    - cloudtruth.projects.<project_name>.parameters.<name> - references a parameter from the specified project

  #### **Common Mistakes to Avoid**
  - **Incorrect Syntax**: Do not use ct.parameter.<name>.
  - **Correct Syntax**: Always use cloudtruth.parameters.<name>.
  - **Liquid Tags**: Do not use raw and endraw tags in the template body.

  #### **Functions Usage Rules**
  - **get_parameters**:
    - **When to Use**: Call when users need insight about their own CloudTruth parameters.
    - **Important**: Never reference previous parameter names given to you; always re-request.
  - **create_parameters**:
    - **When to Use**: Call when the user asks to create parameters.
    - **Follow-up Question**: Don't ask for the project. The project doesn't matter.
    - **Secrets and Descriptions**: Not required. If a user says no, just continue with creating the parameters with no extra dialogue.
    - **User Configuration**: If the user provides a configuration with a description or secret, include it without asking a follow-up question.
    - **Important**: Never reference previous information given to you; always re-request.`

const currentDocFile = fileCache['json']
const currentVectorStore = vectorStore['json']

export const updateAssistant = async (openai: OpenAI, obj: Assistant) => {
  const { model } = obj

  return await openai.beta.assistants.update(currentCopilotAssistant, {
    instructions: modelInstructionsV2,
    name: `CoPilot Chat`,
    tools: [
      { type: 'file_search' },
      { type: 'function', function: getParameters },
      { type: 'function', function: createParameters },
      { type: 'function', function: getTemplates },
      { type: 'function', function: createTemplates },
      { type: 'function', function: updateParameterDescriptions },
      { type: 'function', function: getProjects },
      { type: 'function', function: createProject },
      { type: 'function', function: getCurrentProject },
    ],
    model,
    temperature: 0.2,
    top_p: 0.1,
    tool_resources: { file_search: { vector_store_ids: [currentVectorStore] } },
  })
}

export const createAssistant = async (openai: OpenAI, obj: Assistant) => {
  const { model, orgId, orgName } = obj

  return await openai.beta.assistants.create({
    instructions: modelInstructionsV2,
    name: `CloudTruth Assistant - ${orgName}`,
    tools: [
      { type: 'file_search' },
      { type: 'function', function: getParameters },
      { type: 'function', function: createParameters },
      { type: 'function', function: getTemplates },
      { type: 'function', function: createTemplates },
      { type: 'function', function: updateParameterDescriptions },
      { type: 'function', function: getProjects },
      { type: 'function', function: createProject },
      { type: 'function', function: getCurrentProject },
    ],
    model,
    temperature: 0.2,
    top_p: 0.1,
    metadata: {
      orgId,
    },

    tool_resources: { file_search: { vector_store_ids: [currentVectorStore] } },
  })
}

export const createVectorStore = async (openai: OpenAI) => {
  await openai.beta.vectorStores.create({
    name: 'CloudTruth Docs',
    file_ids: [currentDocFile.id],
    expires_after: {
      anchor: 'last_active_at',
      days: 365,
    },
  })
}

export const listAssistants = async (openai: OpenAI) => {
  return await openai.beta.assistants.list()
}

export const getAssistant = async (openai: OpenAI) => {
  return await openai.beta.assistants.retrieve(currentCopilotAssistant)
}

export const createAssistantThread = async (
  openai: OpenAI,
  obj: CreateAssistantThread,
  dontUpdate?: boolean
) => {
  const { dispatch, user } = obj
  const { chatgpt_threads } = user

  const emptyThread = await openai.beta.threads.create()
  const newThread = { id: emptyThread.id, timestamp: new Date().toISOString(), title: '' }
  const threads = {
    ...chatgpt_threads,
    [emptyThread.id]: newThread,
  }
  if (!dontUpdate) {
    await dispatch(
      UpdateUser({
        chatgpt_threads: threads,
        id: user.id,
      })
    )
  }

  return newThread
}

export const deleteAssistantThread = async (openai: OpenAI, obj: DeleteAssistantThread) => {
  const { user, selectedThread, dispatch } = obj
  const { chatgpt_threads } = user

  const threads = { ...chatgpt_threads }

  try {
    // Uncomment this line if you need to actually call the OpenAI API to delete the thread
    // await openai.beta.threads.del(selectedThread.id);

    // Deleting the thread from local state
    delete threads[selectedThread.id]

    // Dispatching the update to the user's state
    dispatch(
      UpdateUser({
        id: user.id,
        chatgpt_threads: threads || {},
      })
    )
  } catch (error) {
    console.error('Error deleting thread:', error)
  }
}

export const listThreads = async (openai: OpenAI, threadIds: string[]) => {
  const threads = threadIds.map(async (threadId) => {
    return openai.beta.threads.retrieve(threadId)
  })

  return Promise.all(threads)
}

export const handleUpload = async (file: any, openai: OpenAI) => {
  try {
    const openAiFile = await openai.files.create({
      file,
      purpose: 'assistants',
    })

    return { ...openAiFile, originalFile: file }
  } catch (error) {
    return { filename: file.name, error: 'Invalid file format' }
  }
}

export const listMessages = async (openai: OpenAI, thread: string) => {
  return await openai.beta.threads.messages.list(thread)
}

export const createMessage = async (
  openai: OpenAI,
  thread: string,
  content: string,
  attachments?: Attachments
) => {
  const { images: imageArrary, files } = attachments || { images: [], files: [] }
  const images = imageArrary!.map((attachment) => ({
    type: 'image_file' as any,
    image_file: { file_id: attachment.id },
  }))

  const fileAttachments = files.map((file) => ({
    file_id: file.id,
    tools: [{ type: 'file_search' as any }],
  }))

  const contentMessage = () => {
    if (files.length > 0) {
      return (
        content +
        '\n\n ' +
        `[analyze ${files
          .map((file, index) =>
            files.length > 1 && index === files.length - 1 ? `and ${file.filename}` : file.filename
          )
          .join(files.length > 1 ? ', ' : '')} file${
          files.length > 1 ? 's' : ''
        } before proceeding with run steps]`
      )
    } else {
      return content
    }
  }

  return await openai.beta.threads.messages.create(thread, {
    role: 'user',
    content: [
      {
        type: 'text',
        text: contentMessage(),
      },
      ...images,
    ],
    attachments: fileAttachments,
  })
}

export function fixAndParseJSON(jsonString: string): any {
  // Step 2: Repair the JSON string
  try {
    const { data } = fix(jsonString)

    return JSON.parse(data as string)
  } catch (repairError) {
    console.error('Failed to repair JSON:', repairError)
    return null
  }
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { UtilsGeneratePasswordCreateParams } from 'gen/cloudTruthRestApi'
import { createAsyncThunk } from '@reduxjs/toolkit'

// TODO (mwellman17 Dec 29, 2021): This should be autogenerated from the rest api
export interface GeneratedPasswordResponse {
  value: string
}

export const GeneratePassword = createAsyncThunk<
  GeneratedPasswordResponse,
  UtilsGeneratePasswordCreateParams,
  ThunkConfig
>('utils/generatePassword', async (query, thunkApi) => {
  return await handleFetch(Verbs.Post, 'utils/generate_password', null, thunkApi, buildQuery(query))
})

import { Button, Result } from 'antd'

import { Link } from 'react-router-dom'
import { LoggerUtils } from 'lib/loggerUtils'
import React from 'react'
import { RootPaths } from 'router/Router'
import { authService } from 'lib/authService'
import { getCurrentUser } from 'data/user/selectors'
import { useAppSelector } from 'data/hooks'

interface Props {
  clear?: boolean
  login?: () => void
  message?: string
}

export function Forbidden(props: Props) {
  const { clear, login, message } = props
  const user = useAppSelector(getCurrentUser)

  LoggerUtils.captureMessage(message || 'Forbidden', user?.id)

  if (clear) {
    authService.clearBrowserCache()
  }

  return (
    <Result
      status="403"
      title="Forbidden"
      data-cy="forbidden"
      subTitle={message ? message : 'You do not have permissions to access the requested resource.'}
      extra={
        login && (
          <Link to={RootPaths.App} onClick={login}>
            <Button type="primary">Back Home</Button>
          </Link>
        )
      }
    />
  )
}

import { GetEnvironments, UpdateEnvironment } from 'data/environment/actions'
import React, { useCallback, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ComponentLoading } from 'components/PageLoading'
import { CustomThunk } from 'data/dataUtils'
import { NoRbacEnabled } from '../NoRbacEnabled'
import { PageTitle } from 'components/PageTitle'
import { Permissions } from '../Permissions'
import { Reload } from 'components/Reload'
import { Tree } from 'antd'
import { buildEnvironmentTree } from 'components/Environments/utils'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './Environments.module.scss'
import { useToast } from 'hooks'

export function Environments() {
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())
  const currentEnvironment = useAppSelector(getCurrentEnvironment)
  const currentOrg = useAppSelector(getCurrentOrganization)
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const { errorToast } = useToast()

  const getEnvironments = useCallback(() => {
    setLoading(true)
    dispatch(GetEnvironments(null)).then(({ error }: CustomThunk) => {
      if (error) {
        setLoading(false)
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState<string>(currentEnvironment!.id)

  const selectedEnvironment = useMemo(() => {
    return selectedEnvironmentId ? environmentEntities[selectedEnvironmentId] : null
  }, [selectedEnvironmentId, environmentEntities])

  const onSelect = useCallback(
    (envId: maybe<string>) => {
      if (envId) {
        setSelectedEnvironmentId(environmentEntities[envId]!.id)
      }
    },
    [setSelectedEnvironmentId, environmentEntities]
  )

  const updateAccessControl = (bool: boolean) =>
    dispatch(UpdateEnvironment({ ...selectedEnvironment!, access_controlled: bool }))

  const environmentTree = useMemo(() => {
    return buildEnvironmentTree(environmentEntities)
  }, [environmentEntities])

  if (!currentOrg?.subscription_features.includes('rbac')) {
    return <NoRbacEnabled />
  }

  return (
    <>
      <PageTitle
        title="Environment Access Control"
        buttons={<Reload onClick={getEnvironments} loading={loading} />}
        description="You can enable Access Control to restrict which users are allowed to access an
          environment. When you enable access control you become the owner of that environment, at
          which point other users cannot see the environment unless you grant them access. When you
          disable access control, all of the granted roles for that environment are removed, and it
          becomes accessible to all organization members again."
      />
      {loading ? (
        <ComponentLoading />
      ) : (
        <div className={styles.container}>
          <Tree
            defaultExpandAll
            selectedKeys={[selectedEnvironmentId]}
            className={styles.tree}
            showLine={true && { showLeafIcon: false }}
            treeData={environmentTree}
            onSelect={(keys) => onSelect(keys[0]?.toString())}
          />
          {!selectedEnvironment ? (
            <div className={styles.selectedEnvironment}>Select an Environment</div>
          ) : (
            <Permissions
              scope={selectedEnvironment.url}
              accessName={selectedEnvironment.name}
              updateAccessControl={updateAccessControl}
              accessControlled={selectedEnvironment.access_controlled}
            />
          )}
        </div>
      )}
    </>
  )
}

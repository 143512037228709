import * as Sentry from '@sentry/react'

import LogRocket from 'logrocket'

// Note that LogRocket being enabled or disabled is controlled in logrocketHelpers

export class LoggerUtils {
  static captureException(exception: string | Error): void {
    Sentry.captureException(exception)

    if (typeof exception === 'string') {
      LogRocket.captureMessage(exception)
    } else {
      LogRocket.captureException(exception)
    }

    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
      console.error(exception)
    }
  }

  static captureMessage(message: string, userId: option<string>): void {
    this.setUserInfo(userId)
    Sentry.captureMessage(message)

    LogRocket.captureMessage(message)

    if (process.env.NODE_ENV !== 'production') {
      console.debug(message)
    }
  }

  static setRequestId(requestId: string): void {
    Sentry.configureScope((scope) => {
      scope.setTag('request_id', requestId)
    })
  }

  static setTag(tagName: string, tagValue: string): void {
    Sentry.setTag(tagName, tagValue)
  }

  static setUserInfo(userId: maybe<string>): void {
    if (userId) {
      Sentry.configureScope((scope) => scope.setUser({ id: userId }))
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }

  static linkLogRocketAndThirdParties = (): void => {
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL)
      })
      if (window.Intercom) {
        window.Intercom('trackEvent', 'LogRocket', { sessionURL: sessionURL })
      }
    })
  }
}

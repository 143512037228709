import { Divider } from 'antd'
import React from 'react'
import styles from './Footer.module.scss'
import { termsOfServiceUrl } from 'lib/valueHelpers'

const now = new Date()

export function handleShowIntercom() {
  if (window.Intercom) {
    window.Intercom('show')
  }
}

export const Footer = () => (
  <div className={styles.container}>
    <div className={styles.pageLayout}>
      <div className={styles.logoWrapper}>
        <a href="https://www.cloudtruth.com/" target="_blank" rel="noreferrer">
          <div className={styles.logo} />
        </a>
      </div>
      <div className={styles.links}>
        <a className={styles.link} href={termsOfServiceUrl} target="_blank" rel="noreferrer">
          Terms
        </a>
        <Divider className={styles.divider} type="vertical" />
        <a
          className={styles.link}
          href="https://docs.cloudtruth.com/"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </a>
        <Divider className={styles.divider} type="vertical" />
        <a className={styles.link} href="#" onClick={handleShowIntercom}>
          Contact
        </a>
      </div>
      <div className={styles.legal}>&copy;&nbsp;CloudTruth&nbsp;{now.getFullYear()}</div>
    </div>
  </div>
)

import { FormData, useForm } from 'components/Forms'
import React, { useCallback, useMemo, useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { AddTemplateForm } from './AddTemplateForm'
import { CreateTemplate } from 'data/template/actions'
import { CustomThunk } from 'data/dataUtils'
import { TemplateCreate } from 'gen/cloudTruthRestApi'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  setSelectedTemplate?: (templateId: string) => void
  visibleFromProps?: boolean
  onClose?: () => void
  hide?: boolean
  redirect?: boolean
}

export function AddTemplate(props: Props) {
  const { setSelectedTemplate, visibleFromProps, onClose, hide, redirect } = props
  const [form] = useForm()

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const { goToTemplateRoute } = useHistoryPush()

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(() => {
    onClose ? onClose() : setVisible(false)
  }, [onClose])

  const onFinish = (formData: FormData) => {
    const { name, description } = formData as TemplateCreate

    setLoading(true)
    // Note: right now body is required, but it is not possible to add a body to the template on UI
    // for now we pass an empty string but this type will be changed to optional in the future.
    dispatch(CreateTemplate({ name, description, body: '' })).then(
      ({ error, payload }: CustomThunk) => {
        error ? errorToast(error.message) : successToast('Template successfully created')

        setLoading(false)
        setVisible(false)
        closeModal()

        if (redirect) {
          goToTemplateRoute(null)
        }

        if (payload && setSelectedTemplate) {
          setSelectedTemplate(payload.id)
        }
      }
    )
  }

  return (
    <>
      {!hide && (
        <ActionButton
          customType="add"
          size="large"
          style={{ marginLeft: '0px' }}
          onClick={() => setVisible(true)}
        >
          Create New Template
        </ActionButton>
      )}

      <AddModal
        pending={loading}
        visible={modalVisible}
        objectName="Template"
        afterClose={() => form.resetFields()}
        onCancel={closeModal}
        onOk={() => form.submit()}
      >
        <AddTemplateForm form={form} onFinish={onFinish} />
      </AddModal>
    </>
  )
}

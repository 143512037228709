import React, { useEffect, useState } from 'react'

import { ProjectParams } from 'router/ProjectRoutes'
import { UpdateValueModal } from './UpdateValueModal'
import { getCurrentEnvironment } from 'data/environment/selectors'
import styles from './UpdateValue.module.scss'
import { useAppSelector } from 'data/hooks'
import { useParams } from 'react-router-dom'

interface Props {
  hasValue: boolean
  hasOverride: boolean
  externalError?: nullable<string>
  resetShow: () => void
  showSecret: boolean
  envUrl?: string
}

export function UpdateValue(props: Props) {
  const { hasValue, hasOverride, externalError, resetShow, showSecret, envUrl } = props
  const { url } = useAppSelector(getCurrentEnvironment)!
  const params = useParams<ProjectParams>()
  const { parameterId } = params

  const [visible, setVisible] = useState(!hasValue && !hasOverride && !externalError)
  const [initialParam, setInitialParam] = useState(parameterId)

  const onEditValueClick = () => {
    setVisible(true)
  }

  // if the user changes parameter details pages, we need to reset the visible state
  // because the component will not unmount
  // usually this would not be done with useEffect, but because of the global create
  // we cannot change the visible state with the create parameter callback
  useEffect(() => {
    if (parameterId !== initialParam) {
      setVisible(!hasValue && !hasOverride && !externalError)
      setInitialParam(parameterId)
    }
  }, [parameterId, hasValue, hasOverride, externalError, initialParam])

  return (
    <div className={styles.container}>
      {visible ? (
        <UpdateValueModal
          resetShow={resetShow}
          showSecret={showSecret}
          parameterId={parameterId!}
          environmentValueUrl={envUrl || url}
          hasValue={hasValue}
          setVisible={setVisible}
        />
      ) : null}
      <div onClick={onEditValueClick} className={styles.link}>
        Edit Value
      </div>
    </div>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { Environment } from 'gen/cloudTruthRestApi'
import { FormInstance } from 'antd'
import { LabelText } from 'components/LabelText'
import React from 'react'
import styles from 'components/Actions/Push/PreviewAddPush.module.scss'

interface Props {
  form: FormInstance
  environment: Environment
}

export function CopyEnvironmentPreview(props: Props) {
  const { form, environment } = props
  const description = form.getFieldValue('description')
  const name = form.getFieldValue('name')
  const recursive = form.getFieldValue('recursive') || false

  return (
    <div className={styles.container}>
      <LabelText text={environment?.name} label="Environment To Copy" />
      <br />
      <LabelText text={name} label="Name" />
      <br />
      <LabelText text={recursive ? 'Yes' : 'No'} label="Copy Dependents" />
      <br />
      {description?.length > 0 && (
        <>
          <LabelText text={description} label="Description" />
          <br />
        </>
      )}
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AuditTrail } from 'gen/cloudTruthRestApi'
import { CustomThunk } from 'data/dataUtils'
import { GetAwsIntegrations } from 'data/integration/awsIntegration/actions'
import { GetAwsPulls } from 'data/actions/awsImport/actions'
import { GetAwsPushes } from 'data/actions/awsPush/actions'
import { GetAzureKeyVaultIntegrations } from 'data/integration/akvIntegration/actions'
import { GetAzureKeyVaultPulls } from 'data/actions/akvImport/actions'
import { GetAzureKeyVaultPushes } from 'data/actions/akvPush/actions'
import { GetEnvironments } from 'data/environment/actions'
import { GetGithubIntegrations } from 'data/integration/githubIntegration/actions'
import { GetParameterNames } from 'data/parameter/actions'
import { GetProjects } from 'data/project/actions'
import { GetTemplateNames } from 'data/template/actions'
import { GetUsers } from 'data/user/actions'
import { ReloadOutlined } from '@ant-design/icons'
import { getCurrentProject } from 'data/project/selectors'
import { useToast } from 'hooks'

export enum TileName {
  Actions = 'actions',
  Recent = 'recent',
  Environment = 'environment',
  Integrations = 'integrations',
  Projects = 'projects',
  Users = 'users',
  Messages = 'messages',
}

interface Props {
  setLoading: (bool: boolean) => void
  name: TileName
  setActivity?: React.Dispatch<React.SetStateAction<AuditTrail[]>>
  getRecent?: () => void
}

export function ReloadTile(props: Props) {
  const { name, setLoading, getRecent } = props
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const [reload, setReload] = useState(false)
  const currentProject = useAppSelector(getCurrentProject)!

  const getProjects = useCallback(() => {
    setReload(true)
    setLoading(true)
    dispatch(GetProjects(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
        setReload(false)
      } else {
        const temp = new Promise((resolve) => {
          dispatch(GetTemplateNames(currentProject.id)).then(() => {
            resolve({})
          })
        })
        const param = new Promise((resolve) => {
          dispatch(GetParameterNames(currentProject.id)).then(() => {
            resolve({})
          })
        })

        Promise.all([temp, param]).then(() => {
          setLoading(false)
          setReload(false)
        })
      }
    })
  }, [dispatch, errorToast, setLoading, currentProject?.id])

  const getEnvironments = useCallback(() => {
    setReload(true)
    setLoading(true)
    dispatch(GetEnvironments(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
      setReload(false)
    })
  }, [dispatch, errorToast, setLoading])

  const getUsers = useCallback(() => {
    setReload(true)
    setLoading(true)
    dispatch(GetUsers(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
      setReload(false)
    })
  }, [dispatch, errorToast, setLoading])

  const getIntegrations = useCallback(() => {
    setLoading(true)
    setReload(true)

    dispatch(GetAzureKeyVaultIntegrations(null))
      .then(({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
        }
      })
      .then(
        dispatch(GetAwsIntegrations(null)).then(({ error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
          }
        })
      )
      .then(
        dispatch(GetGithubIntegrations(null)).then(({ error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
          }
        })
      )
      .then(() => {
        setLoading(false)
        setReload(false)
      })
  }, [dispatch, errorToast, setLoading])

  const awsIntegrations = awsIntegrationEntitySelectors.selectIds(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectIds(useSelectAkvIntegrations())

  const reloadActions = useCallback(() => {
    setReload(true)
    setLoading(true)

    const awsPulls = awsIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAwsPulls(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const awsPushes = awsIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAwsPushes(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const akvPulls = akvIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAzureKeyVaultPulls(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const akvPushes = akvIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAzureKeyVaultPushes(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    Promise.all([...akvPushes, ...akvPulls, ...awsPulls, ...awsPushes]).then(() => {
      setLoading(false)
      setReload(false)
    })
  }, [awsIntegrations, dispatch, akvIntegrations, setLoading])

  const reloadTile = (name: TileName) => {
    switch (name) {
      case TileName.Projects: {
        getProjects()
        break
      }
      case TileName.Environment: {
        getEnvironments()
        break
      }
      case TileName.Recent: {
        getRecent && getRecent()
        break
      }
      case TileName.Users: {
        getUsers()
        break
      }
      case TileName.Integrations: {
        getIntegrations()
        break
      }
      case TileName.Actions: {
        reloadActions()
        break
      }
      default: {
        throw new Error(`Unknown menu item: ${name}`)
      }
    }
  }

  return (
    <ActionButton
      onClick={() => {
        reloadTile(name)
      }}
      disabled={reload ? true : false}
    >
      <ReloadOutlined data-cy={name + '-reload'} />
    </ActionButton>
  )
}

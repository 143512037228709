import { Form, InlineLabelInput } from 'components/Forms'
import { Organization, PatchedOrganization } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { CustomThunk } from 'data/dataUtils'
import { FormInstance } from 'antd'
import { UpdateOrganization } from 'data/organization/actions'
import styles from './OrganizationDetails.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface ProjectPatternFormProps {
  form: FormInstance
  organization: Organization
}

export function EditProjectPatternForm(props: ProjectPatternFormProps) {
  const { form, organization } = props
  const project_name_pattern = organization.project_name_pattern
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  const onFinish = (formData: PatchedOrganization) => {
    const { project_name_pattern } = formData
    setLoading(true)
    dispatch(
      UpdateOrganization({
        ...organization,
        project_name_pattern: project_name_pattern,
      })
    ).then(({ error }: CustomThunk) => {
      if (error) {
        setLoading(false)
        errorToast(error.message)
        return
      }
      setEditing(false)
      setLoading(false)
      successToast('Organization successfully updated')
    })
  }

  return (
    <div className={styles.namePatternContainer}>
      <Form form={form} onFinish={onFinish} initialValues={{ project_name_pattern }}>
        <InlineLabelInput
          name="project_name_pattern"
          label="Global Project Name Pattern"
          tooltipText="Set a project naming convention with a regex pattern. Use this to enforce consistent project names for your organization."
          disabled={!editing}
        />
      </Form>
      {editing ? (
        <>
          <ActionButton
            onClick={() => {
              form.resetFields(), setEditing(false)
            }}
            disabled={loading}
          >
            Cancel
          </ActionButton>
          <ActionButton onClick={() => form.submit()} type="primary" disabled={loading}>
            Save
          </ActionButton>
        </>
      ) : (
        <ActionButton onClick={() => setEditing(true)} disabled={loading} data-cy="edit-button">
          Edit
        </ActionButton>
      )}
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Middleware, configureStore } from '@reduxjs/toolkit'

import { LoggerUtils } from 'lib/loggerUtils'
import { lrMiddleware } from 'lib/logrocketHelpers'
import rootReducer from './rootReducer'

const sentryMiddleware: Middleware<any, RootState> = (_) => (next) => (action) => {
  LoggerUtils.setTag('action_type', action.type)
  return next(action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(lrMiddleware, sentryMiddleware),
  // enable devTools in staging and local dev
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: process.env.REACT_APP_ATMOS_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default store

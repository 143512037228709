import { Divider, Radio } from 'antd'
//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { ParameterHistory } from './ParameterHistory'
import TemplateHistoryTable from './TemplateHistoryTable'
import styles from './HistoryContainer.module.scss'
import { useHistoryPush } from 'router/customHooks'

export enum HistoryViews {
  Parameter = '/parameter',
  Template = '/template',
}

export function HistoryContainer() {
  const { goToHistoryRoute } = useHistoryPush()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!pathname.match(`(?:${Object.values(HistoryViews).join('|')})`)) {
      goToHistoryRoute(null, HistoryViews.Parameter)
    }
  }, [goToHistoryRoute, pathname])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>History</div>

        <Radio.Group
          onChange={(e) => goToHistoryRoute(null, e.target.value)}
          value={pathname.includes('parameter') ? HistoryViews.Parameter : HistoryViews.Template}
          buttonStyle="solid"
        >
          <Radio.Button value={HistoryViews.Parameter}>Parameters</Radio.Button>
          <Radio.Button value={HistoryViews.Template}>Templates</Radio.Button>
        </Radio.Group>
      </div>
      <Divider className={styles.divider} />
      <Routes>
        <Route path={HistoryViews.Parameter} element={<ParameterHistory />} />
        <Route path={HistoryViews.Template} element={<TemplateHistoryTable />} />
      </Routes>
    </>
  )
}

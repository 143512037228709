import { CopyProjectParameter, CopyProjectParameterReq } from 'data/parameter/actions'
import React, { useCallback, useMemo, useState } from 'react'
import { TypedThunk, idFromUrl } from 'data/dataUtils'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CopyModal } from 'components/Modals/CopyModal'
import { CopyParameterForm } from './CopyParameterForm'
import { ModalStepsFooter } from 'components/Actions/ActionsForm/ModalStepsFooter'
import { Parameter } from 'gen/cloudTruthRestApi'
import { selectProject } from 'data/project/reducer'
import { useForm } from 'components/Forms'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  visibleFromProps?: boolean
  isOverride?: boolean
  parameter: Parameter
  closeOverrideModal?: () => void
}

export function CopyParameter(props: Props) {
  const { parameter, visibleFromProps, closeOverrideModal } = props

  const currentProject = useAppSelector(getCurrentProject)!

  const dispatch = useAppDispatch()
  const { goToParameterPage } = useHistoryPush()
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const { errorToast, successToast } = useToast()

  const [visible, setVisible] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [steps, setSteps] = useState<number>(0)

  const [form] = useForm()

  // eslint-disable-next-line
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
    setSteps(0)
  }

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(() => {
    closeOverrideModal ? (closeOverrideModal(), onCancel()) : onCancel()
  }, [closeOverrideModal, onCancel])

  const onOk = () => {
    form.submit()
    if (steps === 2) {
      form.submit()
    }
    if (steps < 1) {
      form.validateFields().then(() => setSteps((steps) => (steps += 2)))
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 2))
    }
  }

  const handleSubmit = () => {
    setPending(true)

    // form values
    const description = form.getFieldValue('description') || `Copy of ${parameter.name}`
    const parent = form.getFieldValue('parent')
    const name = form.getFieldValue('name')

    const dependsOn = projects.find((project) => project.name === parent)?.url || currentProject.url

    const args = {
      name: name,
      description: description,
      project: dependsOn,
    }

    dispatch(
      CopyProjectParameter({
        id: parameter.id,
        params: args,
      } as CopyProjectParameterReq)
    ).then(({ error, payload }: TypedThunk<Parameter>) => {
      if (error) {
        errorToast(error.message)
      } else {
        successToast('Parameter successfully created')
        onCancel()
        setPending(false)
        closeModal()
        new Promise((resolve) => {
          if (dependsOn !== currentProject.url) {
            dispatch(selectProject(idFromUrl(dependsOn)))
          }

          resolve({})
        }).then(() => {
          new Promise((resolve) => {
            goToParameterPage(payload.id)
            resolve({})
          })
        })
      }
      setPending(false)
    })
  }

  return (
    <>
      <CopyModal
        visible={modalVisible}
        objectName={`Parameter: ${parameter?.name}`}
        footer={
          <ModalStepsFooter
            objectName="Parameter"
            onCancel={() => closeModal()}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={pending}
            isCopy={true}
          />
        }
        pending={pending}
      >
        <CopyParameterForm
          form={form}
          steps={steps}
          handleSubmit={handleSubmit}
          parameter={parameter}
        />
      </CopyModal>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { displayStatus, formatAwsPushService } from 'components/Actions/utils'
import { idFromUrl, integrationIdFromAkvPushUrl, integrationIdFromAwsPushUrl } from 'data/dataUtils'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { AwsPushWithTags } from 'data/actions/awsPush/selectors'
import { AzureKeyVaultPushWithTags } from 'data/actions/akvPush/selectors'
import { Details } from 'components/Details'
import { Label } from 'components/Forms'
import { LabelText } from 'components/LabelText'
import { PushDetailsDropdown } from './PushDetailsDropdown'
import React from 'react'
import { displayLastPush } from '../PushTable'
import { getPolicy } from 'data/membership/selectors'
import styles from './PushDetails.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  push: AwsPushWithTags | AzureKeyVaultPushWithTags
  refreshPush: () => void
  actionType: ActionPushEnum
}

export function PushDetails(props: Props) {
  const { push, refreshPush, actionType } = props
  const {
    description,
    tagDisplayNames,
    url,
    latest_task,
    projects,
    resource,
    dry_run,
    force,
    local,
  } = push

  const awsPush = push as AwsPushWithTags
  const awsEntities = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvEntities = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const projectEntites = projectEntitySelectors.selectEntities(useSelectProjects())
  const { canContribute } = useAppSelector(getPolicy(null))

  const dropdownMenu = canContribute ? (
    <PushDetailsDropdown
      push={push!}
      refreshPush={refreshPush}
      redirect
      actionType={actionType}
      color="black"
    />
  ) : undefined

  const renderName = (push: AwsPushWithTags | AzureKeyVaultPushWithTags): string => {
    return push.dry_run ? `${push.name} (Dry Run)` : push.name
  }

  const displayIntegration = (url: string) => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return awsEntities[integrationIdFromAwsPushUrl(url)]?.name

      case ActionEnum.AzureKeyVaultPush:
        return akvEntities[integrationIdFromAkvPushUrl(url)]?.name

      default:
        return null
    }
  }

  return (
    <Details
      name={renderName(push)}
      dropdownMenu={dropdownMenu}
      description={description}
      headerStyles={{ alignItems: 'flex-start' }}
    >
      <div className={styles.column}>
        <LabelText isHorizontal text={resource} label="Resource Pattern:" />
      </div>

      <div className={styles.flexRow}>
        <div className={styles.column}>
          <LabelText isHorizontal text={displayIntegration(url)} label="Integration:" />
          {actionType === ActionEnum.AwsPush && (
            <>
              <LabelText
                isHorizontal
                text={formatAwsPushService(awsPush.service)}
                label="Service:"
              />
              <LabelText isHorizontal text={awsPush.region} label="Region:" />
            </>
          )}
        </div>

        <div className={styles.column}>
          <Label text="Tags" upperCase={false} />
          <div className={styles.subGroup}>
            {tagDisplayNames.map((tag, key) => {
              return <span key={key}>{tag}</span>
            })}
          </div>

          <Label text="Projects" upperCase={false} />
          <div className={styles.subGroup}>
            {projects.map((url, key) => {
              return <span key={key}>{projectEntites[idFromUrl(url)]?.name}</span>
            })}
          </div>
        </div>

        <div className={styles.column}>
          <LabelText isHorizontal text={dry_run ? 'True' : 'False'} label="Dry Run:" />
          <LabelText isHorizontal text={force ? 'True' : 'False'} label="Force:" />
          <LabelText isHorizontal text={local ? 'True' : 'False'} label="Local:" />
        </div>

        <div className={styles.column}>
          <LabelText
            isHorizontal
            text={displayLastPush(latest_task?.modified_at)}
            label="Last Push Time:"
          />
          <LabelText isHorizontal text={displayStatus(latest_task?.state)} label="Status:" />
        </div>
      </div>
    </Details>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { AwsPush, AwsServiceEnum, AzureKeyVaultPush, Tag } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { EditModal } from 'components/Modals/EditModal'
import { ModalStepsFooter } from '../ActionsForm/ModalStepsFooter'
import { PushForm } from './PushForm'
import { TypedThunk } from 'data/dataUtils'
import { UpdateAwsPush } from 'data/actions/awsPush/actions'
import { UpdateAzureKeyVaultPush } from 'data/actions/akvPush/actions'
import { getTags } from 'data/environment/selectors'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

type TagHash = {
  [key: string]: Tag
}

interface Props {
  push: AwsPush | AzureKeyVaultPush
  visible: boolean
  onClose: () => void
  actionType: ActionPushEnum
}

export function EditPush(props: Props) {
  const { push, visible, onClose, actionType } = props
  const [loading, setLoading] = useState(false)
  const [steps, setSteps] = useState(0)
  const [formError, setFormError] = useState<maybe<string>>(null)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()
  const tags = useAppSelector(getTags())!
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const onOk = () => {
    const formCoercionCheck =
      form.getFieldValue('service') &&
      form.getFieldValue('integration') &&
      (!!form.getFieldValue('include_parameters') || !!form.getFieldValue('include_templates')) &&
      form.getFieldValue('service')[0] === AwsServiceEnum.Secretsmanager &&
      !form.getFieldValue('coerce_parameters')

    if (steps === 0 && actionType === ActionEnum.AwsPush && formCoercionCheck) {
      setFormError(
        "Enable 'coerce' for parameter types, template types, and Secrets Manager integrations."
      )
      return
    } else {
      form.submit()

      if (steps === 2) {
        form.submit()
      }
      if (steps < 2) {
        form.validateFields().then(() => setSteps((steps) => (steps += 1)))
      }
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 1))
    }
  }

  const onCancel = () => {
    onClose()
    setSteps(0)
    form.resetFields()
  }

  const allTagsHash = () => {
    const hash: TagHash = {}

    Object.values(tags)
      .flatMap((tags) => tags)
      .forEach((tag) => {
        hash[tag.id] = tag
      })

    return hash
  }

  const handleSubmit = () => {
    setLoading(true)
    // Values aren't coming in with FormData, so they have to be fetched manually
    // It might have something to do with form components mounting and unmounting
    // due to steps
    const tags = form.getFieldValue('tags')
    const projects = form.getFieldValue('projects')
    const integrationId = form.getFieldValue('integration')
    const region = form.getFieldValue('region') ? form.getFieldValue('region')[0] : null
    const service = form.getFieldValue('service') ? form.getFieldValue('service')[0] : null
    const resource = form.getFieldValue('resource')
    const name = form.getFieldValue('name')
    const dry_run = form.getFieldValue('dry_run')
    const force = form.getFieldValue('force')
    const local = form.getFieldValue('local')
    const description = form.getFieldValue('description')
    const coerce_parameters = form.getFieldValue('coerce_parameters')
    const include_parameters = form.getFieldValue('include_parameters')
    const include_secrets = form.getFieldValue('include_secrets')
    const include_templates = form.getFieldValue('include_templates')
    const projectUrls = projects.map((projectId: string) => projectEntities[projectId]!.url)
    const tagUrls = tags.map((tag: string) => allTagsHash()[tag].url)
    // This leaves out service and region to make it reusable
    const pushObj = {
      tags: tagUrls,
      projects: projectUrls,
      resource,
      description,
      name,
      dry_run,
      force,
      local,
      coerce_parameters,
      include_parameters,
      include_secrets,
      include_templates,
      id: push.id,
    }

    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(
          UpdateAwsPush({ push: { ...pushObj, region, service }, awsIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPush>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
            onCancel()
          }

          setLoading(false)
        })

        break

      case ActionEnum.AzureKeyVaultPush:
        return dispatch(
          UpdateAzureKeyVaultPush({ push: pushObj, akvIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPush>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
            onCancel()
          }

          setLoading(false)
        })

      default:
        break
    }
  }

  return (
    <>
      <EditModal
        pending={loading}
        visible={visible}
        objectName="Push"
        afterClose={() => form.resetFields()}
        onCancel={onCancel}
        onOk={() => form.submit()}
        footer={
          <ModalStepsFooter
            objectName="Push"
            editing
            onCancel={onCancel}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={loading}
          />
        }
      >
        <PushForm
          formError={formError}
          setFormError={setFormError}
          form={form}
          handleSubmit={handleSubmit}
          push={push}
          steps={steps}
          editing
          actionType={actionType}
        />
      </EditModal>
    </>
  )
}

import { Parameter } from 'gen/cloudTruthRestApi'
import React from 'react'
import { Typography } from 'antd'
import { getCurrentProject } from 'data/project/selectors'
import styles from './ParameterTable.module.scss'
import { useAppSelector } from 'data/hooks'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  parameter: Parameter
}

export function InheritedFromColumn(props: Props) {
  const { parameter } = props
  const currentProject = useAppSelector(getCurrentProject)

  const inheritedFrom = parameter.project !== currentProject?.url ? parameter.project_name : null

  return (
    <Typography.Text ellipsis className={styles.ellipsis}>
      {valueOrEmDash(inheritedFrom)}
    </Typography.Text>
  )
}

import React from 'react'
import styles from './DocLink.module.scss'

interface Props {
  href: string
  text: string
}

export function DocLink(props: Props) {
  const { href, text } = props

  return (
    <a className={styles.link} href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )
}

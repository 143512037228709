import { Checkbox } from '@ory/themes'
import { NodeInputProps } from 'components/Ory/types'
import React from 'react'
import { getNodeLabel } from '@ory/integrations/ui'

export function NodeInputCheckbox({ node, attributes, setValue, disabled }: NodeInputProps) {
  return (
    <>
      <Checkbox
        name={attributes.name}
        defaultChecked={attributes.value === true}
        onChange={(e) => setValue(e.target.checked)}
        disabled={attributes.disabled || disabled}
        label={getNodeLabel(node as any)}
        state={node.messages.find(({ type }: any) => type === 'error') ? 'error' : undefined}
        subtitle={node.messages.map(({ text }: any) => text).join('\n')}
      />
    </>
  )
}

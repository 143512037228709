import {
  CheckAwsIntegrationStatus,
  DeleteAwsIntegration,
  GetAwsIntegration,
} from 'data/integration/awsIntegration/actions'
import { CustomThunk, integrationIdFromAwsPullUrl } from 'data/dataUtils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { awsPullEntitySelectors, useSelectPull } from 'data/actions/awsImport/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AwsIntegration } from 'gen/cloudTruthRestApi'
import { DeleteConfirmModal } from 'components/Modals'
import DeleteIntegrationOptionsModal from 'components/Modals/DeleteIntegrationOptionsModal'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { removeOneAwsPull } from 'data/actions/awsImport/reducer'
import { useToast } from 'hooks'

const CHECK_ACCOUNT = 'checkAccount'
const EDIT_ACCOUNT = 'editAccount'
const REMOVE_ACCOUNT = 'removeAccount'

interface Props {
  awsIntegration: AwsIntegration
  handleChecking: (checking: boolean) => void
  handleEditClick: () => void
  isChecking: boolean
  setClosePopover: (bool: boolean) => void
}

export function AwsAccountsListPanelDropdown(props: Props) {
  const { awsIntegration, handleChecking, handleEditClick, isChecking, setClosePopover } = props
  const { canAdministrate, canContribute } = useAppSelector(getPolicy(null))

  const [loading, setLoading] = useState<boolean>(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [deleteOptionsVisible, setDeleteOptionsVisible] = useState(false)
  const awsMappedPulls = awsPullEntitySelectors
    .selectAll(useSelectPull())
    .filter((pull) => pull.mode === 'mapped')

  const { errorToast, successToast } = useToast()
  const didCheckOnce = useRef(false)
  const dispatch = useAppDispatch()

  const checkIntegration = useCallback(() => {
    handleChecking(true)
    setClosePopover(false)
    if (awsIntegration.status === 'checking') {
      dispatch(GetAwsIntegration(awsIntegration.id)).then(() => {
        handleChecking(false)
      })
    } else {
      dispatch(CheckAwsIntegrationStatus(awsIntegration)).then(() => {
        handleChecking(false)
      })
    }
  }, [awsIntegration, dispatch, handleChecking, setClosePopover])

  const deleteAwsIntegration = () => {
    setLoading(true)

    dispatch(DeleteAwsIntegration({ id: awsIntegration.id })).then(({ error }: CustomThunk) => {
      setLoading(false)
      setDeleteVisible(false)

      if (error?.message.includes('in use')) {
        setDeleteOptionsVisible(true)
      } else {
        if (error) {
          errorToast(error.message)
          return
        }

        const mappedValue = awsMappedPulls.find(
          (pull) => integrationIdFromAwsPullUrl(pull.url) === awsIntegration.id
        )

        if (mappedValue) {
          dispatch(removeOneAwsPull(mappedValue.id))
        }

        successToast('Integration successfully deleted')
      }
    })
  }

  const forceDeleteIntegration = (option: 'leave' | 'remove'): void => {
    setLoading(true)

    dispatch(DeleteAwsIntegration({ id: awsIntegration.id, in_use: option })).then(
      ({ error }: CustomThunk) => {
        error ? errorToast(error.message) : successToast('Integration successfully deleted')
        setLoading(false)
        setDeleteOptionsVisible(false)
      }
    )
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case CHECK_ACCOUNT: {
        checkIntegration()
        break
      }

      case EDIT_ACCOUNT: {
        handleEditClick()
        break
      }

      case REMOVE_ACCOUNT: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canContribute, [
      {
        key: CHECK_ACCOUNT,
        label: 'Check Status',
        disabled: isChecking,
      },

      {
        key: EDIT_ACCOUNT,
        label: 'Edit',
        disabled: isChecking,
      },
    ]),

    ...conditionalArrayItem(canAdministrate, [
      {
        key: REMOVE_ACCOUNT,
        label: 'Delete',
        disabled: isChecking,
        danger: true,
      },
    ]),
  ]

  useEffect(() => {
    const now = new Date().getTime()
    const previous = awsIntegration.status_last_checked_at
      ? new Date(awsIntegration.status_last_checked_at).getTime()
      : null

    const statusTimer = setInterval(() => {
      if (awsIntegration.status === 'checking') {
        checkIntegration()
      }
    }, 3000)

    // didCheckOnce ensures that we do not double-check due to refreshes
    if (!previous || (!didCheckOnce.current && now - previous > 1000 * 60 * 60 * 12)) {
      didCheckOnce.current = true
      checkIntegration()
    }

    if (awsIntegration.status !== 'checking') {
      clearTimeout(statusTimer)
    }

    return () => clearTimeout(statusTimer)
  }, [awsIntegration, checkIntegration, didCheckOnce])

  return (
    <>
      <DeleteConfirmModal
        closeModal={() => setDeleteVisible(false)}
        visible={deleteVisible}
        onDelete={deleteAwsIntegration}
        subject={`"${awsIntegration.aws_role_name}"`}
        removePronoun={true}
        loading={loading}
      />
      <DeleteIntegrationOptionsModal
        closeModal={() => setDeleteOptionsVisible(false)}
        visible={deleteOptionsVisible}
        onDelete={forceDeleteIntegration}
        loading={loading}
      />
      <div style={{ marginLeft: '18px' }}>
        <MoreIconDropdown menu={{ items, onClick }} color="black" />
      </div>
    </>
  )
}

import { ActionButton } from '../ActionButton'
import { Modal } from 'antd'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons/lib'
import styles from './DeleteConfirmModal.module.scss'

interface Props {
  closeModal: () => void
  visible: boolean
  onDelete: (option: 'leave' | 'remove') => void
  loading: boolean
}

export default function DeleteIntegrationOptionsModal(props: Props) {
  const { visible, closeModal, onDelete, loading } = props

  return (
    <Modal footer={null} open={visible} closable={false} width="550px" destroyOnClose>
      <div className={styles.container}>
        <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
        <h2>The integration is in use by one or more values.</h2>
        <p>You can still delete the integration by choosing one of the following options:</p>
        <p>
          <strong>Leave: </strong>leave values in place and future queries may fail
        </p>
        <p>
          <strong>Remove: </strong>remove all the values using the integration
        </p>
        <div className={styles.buttonContainer}>
          <ActionButton onClick={closeModal}>Cancel</ActionButton>
          <ActionButton type="primary" danger onClick={() => onDelete('leave')} loading={loading}>
            Leave
          </ActionButton>
          <ActionButton type="primary" danger onClick={() => onDelete('remove')} loading={loading}>
            Remove
          </ActionButton>
        </div>
      </div>
    </Modal>
  )
}

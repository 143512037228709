import {
  Button,
  Card,
  H3,
  LinkButton,
  TextInput,
  typographyH2Styles,
  typographyLinkStyles,
} from '@ory/themes'

import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

export const MarginCard = styled(Card)`
  margin-top: 70px;
  margin-bottom: 18px;
  border: none;
`

export const ActionCard = styled(Card)`
  border: none;
  & a {
    &:hover,
    &,
    &:active,
    &:focus,
    &:visited {
      color: #154e9d; !important;
    }
  }

`

export const CenterLink = styled.a`
  ${typographyH2Styles};
  ${typographyLinkStyles};
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  &:hover {
    color: #1f6cbf !important;
  }
`

export const TextLeftButton = styled(LinkButton)`
  box-sizing: border-box;
  color: black !important;
  & .linkButton {
    box-sizing: border-box;
  }

  & a {
    &:hover,
    &,
    &:active,
    &:focus,
    &:visited {
      text-align: left;
    }
  }
`

export const ButtonStyled = styled(Button)`
  & button {
    height: 50px;
    background-color: #154e9d !important;
    box-shadow: 0.25px 0.25px 0.25px 0.25px silver;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }
  & button:hover {
    background-color: #1f6cbf !important;
  }
`

export const ButtonStyledGithub = styled(Button)`
  & button {
    height: 50px;
    background-color: #333 !important;
    box-shadow: 0.25px 0.25px 0.25px 0.25px silver;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }
  & button:hover {
    filter: brightness(150%) !important;
  }
`

export const ButtonStyledGoogle = styled(Button)`
  & button {
    height: 50px;
    background-color: #4285f4 !important;
    box-shadow: 0.25px 0.25px 0.25px 0.25px silver;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }
  & button:hover {
    filter: brightness(125%) !important;
  }
`

export const ButtonStyledMicrosoft = styled(Button)`
  & button {
    height: 50px;
    background-color: #737373 !important;
    box-shadow: 0.25px 0.25px 0.25px 0.25px silver;
    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }
  & button:hover {
    filter: brightness(125%) !important;
  }
`

export const TextInputStyled = styled(TextInput)`
  div {
    margin: 0 auto;
  }
  & input {
    height: 50px;
    &:hover,
    &:focus {
      border: 1px solid #1f6cbf !important;
    }
  }
`

export const H3Styled = styled(H3)`
  font-weight: 600;
  padding-bottom: 0.5rem;
`

export interface DocsButtonProps {
  title: string
  href?: string
  onClick?: () => void
  testid: string
  disabled?: boolean
  unresponsive?: boolean
}

export const DocsButton = ({
  title,
  href,
  onClick,
  testid,
  disabled,
  unresponsive,
}: DocsButtonProps) => (
  <div className={cn('col-xs-4', { 'col-md-12': !unresponsive })}>
    <div className="box">
      <TextLeftButton onClick={onClick} disabled={disabled} data-testid={testid} href={href}>
        {title}
      </TextLeftButton>
    </div>
  </div>
)

import React, { useState } from 'react'
import { SaWithToken, TokenValue } from './ServiceAccountList'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { CreateServiceAccount } from 'data/serviceAccount/actions'
import { CustomThunk } from 'data/dataUtils'
import { ServiceAccountForm } from './ServiceAccountForm'
import { getCurrentUser } from 'data/user/selectors'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  setToken: (token: nullable<TokenValue>) => void
  populateTokenInTable: (payload: SaWithToken) => void
  loading: boolean
  setLoading: (bool: boolean) => void
}

export function AddServiceAccount(props: Props) {
  const { setToken, populateTokenInTable, loading, setLoading } = props
  const [visible, setVisible] = useState(false)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()
  const currentUser = useAppSelector(getCurrentUser)

  const createServiceAccount = () => {
    const { name, description, role, owner } = form.getFieldsValue()

    setLoading(true)
    dispatch(CreateServiceAccount({ name, description, role, owner }))
      .then(({ error, payload }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setLoading(false)
        } else if (payload) {
          successToast('API token successfully created')

          setToken({
            id: payload.id,
            tokenValue: payload.apikey,
          })

          populateTokenInTable(payload)
        }
      })
      .then(() => {
        setVisible(false)
        setLoading(false)
        form.resetFields()
      })
  }

  return (
    <>
      <ActionButton
        size="large"
        customType="add"
        onClick={() => setVisible(true)}
        disabled={loading}
      >
        Create Token
      </ActionButton>

      <AddModal
        visible={visible}
        objectName="API Token"
        okText="Generate Token"
        onCancel={() => {
          setVisible(false)
          form.resetFields()
        }}
        onOk={form.submit}
        pending={loading}
      >
        <ServiceAccountForm
          form={form}
          onCancel={() => setVisible(false)}
          onFinish={createServiceAccount}
          loading={loading}
          serviceAccount={{ owner: currentUser?.url }}
        />
      </AddModal>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionEnum, AsyncJob, getAsyncJobs } from '../utils'
import { PullRootState, awsPullAdapter } from './reducer'

import { AwsPull } from 'gen/cloudTruthRestApi'
import { GetAwsPull } from './actions'
import { RootState } from 'data/store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { integrationIdFromAwsPullUrl } from 'data/dataUtils'
import { useSelectState } from 'data/hooks'

const selectAwsPull = (state: RootState) => state.awsPull

export const getCurrentPull = createSelector(selectAwsPull, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getIntegrationPulls = (integrationId: maybe<string>): Selector<RootState, AwsPull[]> =>
  createSelector(selectAwsPull, (state): AwsPull[] => {
    return (Object.values(state.entities) as AwsPull[])
      .filter((pull) => !integrationId || integrationId === integrationIdFromAwsPullUrl(pull.url))
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
  })

export const getRunningAwsPulls = createSelector(selectAwsPull, (state): AsyncJob[] => {
  return getAsyncJobs(state).map((job) => ({
    ...job,
    type: ActionEnum.AwsPull,
    action: GetAwsPull,
  }))
})

export const awsPullEntitySelectors = awsPullAdapter.getSelectors()
export const useSelectPull = (): PullRootState => useSelectState().awsPull

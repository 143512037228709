import { Button, Checkbox, Tooltip } from 'antd'
import { CustomThunk, projectIdFromUrl } from 'data/dataUtils'
import { GetTemplates, PreviewTemplate, UpdateTemplate } from 'data/template/actions'
import { Parameter, Template } from 'gen/cloudTruthRestApi'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { getLocalTemplateBody, removeLocalTemplateBody } from '../utils'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { EnvironmentSelector } from 'components/EnvironmentSelector'
import { FileOutlined } from '@ant-design/icons'
import { LabelText } from 'components/LabelText'
import { LabeledItem } from 'components/Forms'
import { RenderAlert } from './RenderAlert'
import { TemplateBodyDisplay } from './TemplateBodyDisplay'
import { TemplateBodyForm } from './TemplateBodyForm'
import { TemplateFix } from 'components/GPT/TemplateErrorAsst/TemplateFix'
import { TemplatePreviewDisplay } from './TemplatePreviewDisplay'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { getCurrentEnvironment } from 'data/environment/selectors'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import { localStorageHelpers } from 'lib/localStorageHelpers'
import styles from './TemplateViewPageBody.module.scss'
import { updateTemplateEvlauatedCache } from 'data/template/reducer'
import { useToast } from 'hooks'

interface Props {
  template: Template
}

export function TemplateViewPageBody(props: Props) {
  const { template } = props
  const { id: projectId, parameters } = useAppSelector(getCurrentProject)!
  const { body, name } = template

  const templates = templateEntitySelectors.selectAll(useSelectTemplates())

  const [editing, setEditing] = useState(
    !body || body === '' || typeof getLocalTemplateBody(name, projectId) === 'string'
  )
  const [wrapping, setWrapping] = useState<boolean>(
    localStorageHelpers.getEditorSettings().wrapping ?? true
  )

  const [showPreview, setShowPreview] = useState(false)
  const [currentBody, setCurrentBody] = useState<maybe<string>>(null)
  const [originalBody, setOriginalBody] = useState<maybe<string>>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [fixLoading, setFixLoading] = useState(false)
  const [clearEditConfirm, setClearEditConfirm] = useState<boolean>(false)

  const { successToast, errorToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const readOnly = projectIdFromUrl(template.url) !== projectId
  const currentEnvironment = useAppSelector(getCurrentEnvironment)!
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const templateCache = useAppSelector((state) => state.template.cachedProject)
  const templateEvaluatedCache = useAppSelector((state) => state.template.cachedEvaluatedTemplates)
  const updateShowPreview = (bool: boolean) => setShowPreview(bool)
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const currentOrg = useAppSelector(getCurrentOrganization)
  const showCopilot = currentOrg?.copilot_enabled

  const disabled = useMemo(() => {
    return loading || fixLoading
  }, [loading, fixLoading])

  const templateBody = useMemo(() => {
    if (fixLoading) {
      return 'Fixing template...'
    }
    if (currentBody !== null) {
      return currentBody
    }

    return getLocalTemplateBody(name, projectId) || body
  }, [currentBody, name, body, projectId, fixLoading])

  const templateB = useMemo(() => {
    if (currentBody !== null) {
      return currentBody
    }

    return getLocalTemplateBody(name, projectId) || body
  }, [currentBody, name, body, projectId])

  const evaluatedTemplate = useMemo(() => {
    if (template?.id && templateEvaluatedCache) {
      const evaluatedBody = templateEvaluatedCache[template.id]?.evaluatedBody
      const evaluatedValue = templateEvaluatedCache[template.id]?.evaluatedValue
      const evaluatedEnvironment = templateEvaluatedCache[template.id]?.evaluatedEnvironment

      if (templateBody === evaluatedBody && currentEnvironment.id === evaluatedEnvironment) {
        return evaluatedValue
      }
    }
  }, [templateEvaluatedCache, template, templateBody, currentEnvironment])

  const [showSecret, setShowSecret] = useState(!!evaluatedTemplate && evaluatedTemplate !== '*****')

  useEffect(() => {
    if (templateCache !== projectId) {
      setLoading(true)

      dispatch(GetTemplates(projectId)).then(({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setLoading(false)
          return
        }

        setLoading(false)
        return
      })
    }
  }, [dispatch, errorToast, projectId, templateCache, templates.length])

  // this ensures that when you create a new template, it will automatically go into edit mode
  const prevTemplateLength = useRef(templates.length)

  useEffect(() => {
    if (templates.length !== prevTemplateLength.current) {
      setEditing(true)
      prevTemplateLength.current = templates.length
    }
  }, [templates.length, setEditing])

  useEffect(() => {
    localStorageHelpers.setEditorSettings({ wrapping })
  }, [wrapping])

  const previewTemplate = (maskSecret?: boolean, newBody?: string) => {
    setError(null)
    updateShowPreview(false)

    setLoading(true)

    const previewBody = readOnly ? body || '' : templateBody || ''

    return dispatch(
      PreviewTemplate({
        envId: currentEnvironment.id,
        body: newBody || previewBody,
        projectId,
        maskSecrets: typeof maskSecret === 'boolean' ? maskSecret : !showSecret,
        templateId: template.id,
        updateHasSecrets: true,
      })
    ).then(({ payload, error }: CustomThunk) => {
      if (error) {
        setError(error.message)
        setLoading(false)
        return
      }

      dispatch(
        updateTemplateEvlauatedCache({
          templateId: template.id,
          evaluatedBody: newBody || previewBody,
          evaluatedValue: payload.body,
          evaluatedEnvironment: currentEnvironment.id,
        })
      )

      setLoading(false)
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    // currently name is required in api, in the future it should be optional
    dispatch(UpdateTemplate({ id: template.id, body: templateBody || '', name })).then(
      ({ error }: CustomThunk) => {
        setLoading(false)
        if (error) {
          errorToast(error.message)
        } else {
          successToast('Template successfully updated')
          removeLocalTemplateBody(name)
          setEditing(false)
          setCurrentBody(null)
        }
      }
    )
  }

  const handleCancel = () => {
    if (body !== templateBody) {
      setClearEditConfirm(true)
    } else {
      removeLocalTemplateBody(name)
      setEditing(false)
    }
  }

  // reset to original body and clear edits
  const clearEdits = () => {
    setCurrentBody(body)
    removeLocalTemplateBody(name)
    setEditing(false)
    setClearEditConfirm(false)
  }

  const ActionsHeader = () => {
    return (
      <div className={styles.actionHeader}>
        <div className={styles.left}>
          <div className={styles.environmentSelector}>
            <span className={styles.previewLabel}>Preview Template For:</span>
            <EnvironmentSelector />
          </div>

          {evaluatedTemplate && !loading && template.has_secret && (
            <div className={styles.toggleSecrets}>
              <ToggleSecrets
                handleToggleClick={() => {
                  setShowSecret(!showSecret)
                  previewTemplate(showSecret)
                }}
                showSecretValues={showSecret}
              />
            </div>
          )}
        </div>

        <span className={styles.alertContainer}>
          <div className={styles.renderAlert}>
            {(typeof evaluatedTemplate === 'string' || error) && <RenderAlert errors={error} />}
          </div>

          {!editing && (
            <div className={styles.displayPreviewButton}>
              <ActionButton
                onClick={() => previewTemplate()}
                disabled={disabled}
                data-cy="previewButton-display"
              >
                Preview
              </ActionButton>
            </div>
          )}

          {editing && templateBody !== body && !readOnly && (
            <span className={styles.draft}>
              Draft <FileOutlined />
            </span>
          )}
        </span>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <ConfirmModal
        onCancel={() => setClearEditConfirm(false)}
        visible={clearEditConfirm}
        onOk={clearEdits}
        subject="Are you sure you want to cancel editing?"
        body="Unsaved changes will be lost."
        cancelText="Keep Editing"
        confirmText="Confirm Cancel"
      />
      <div className={styles.detailsHeader}>
        <div className={styles.detailsContainer}>
          <span className={styles.projectLabel}>
            <LabelText
              label="Project:"
              isHorizontal
              text={projectEntities[projectIdFromUrl(template.url)]!.name}
            />
          </span>
        </div>

        {!readOnly && canContribute && (
          <div className={styles.right}>
            {editing ? (
              <>
                <LabeledItem label="wrap text" marginBottom="0">
                  <Checkbox
                    disabled={disabled}
                    checked={wrapping}
                    onChange={() => setWrapping(!wrapping)}
                    className={styles.wrapping}
                  />
                </LabeledItem>

                <span className={styles.cancel}>
                  <ActionButton onClick={handleCancel} disabled={disabled}>
                    Cancel
                  </ActionButton>
                </span>

                <span className={styles.preview}>
                  <ActionButton
                    onClick={() => previewTemplate()}
                    disabled={disabled}
                    data-cy="previewButton"
                  >
                    Preview
                  </ActionButton>
                </span>

                {showCopilot && (
                  <span className={styles.templateFix}>
                    <Tooltip title="AI Template Assistant" placement="top">
                      <TemplateFix
                        template={template}
                        loading={fixLoading}
                        setLoading={setFixLoading}
                        disabled={disabled}
                        parameters={parameters as Parameter[]}
                        templates={templates}
                        projects={projects}
                        error={error || ''}
                        body={templateB}
                        setBody={setCurrentBody}
                        setOriginalBody={setOriginalBody}
                        preview={previewTemplate}
                      />
                    </Tooltip>
                  </span>
                )}
                {originalBody && (
                  <Button
                    className={styles.undoButton}
                    disabled={disabled}
                    onClick={() => {
                      const original = originalBody
                      setCurrentBody(original)
                      setOriginalBody(null)
                    }}
                  >
                    Undo
                  </Button>
                )}

                <ActionButton type="primary" onClick={handleSubmit} disabled={disabled}>
                  Submit
                </ActionButton>
              </>
            ) : (
              <ActionButton
                onClick={() => setEditing(true)}
                type="primary"
                data-cy="editTemplate"
                className={styles.editTemplate}
              >
                Edit Template
              </ActionButton>
            )}
          </div>
        )}
      </div>

      {editing && !readOnly && !loading && !fixLoading ? (
        <TemplateBodyForm
          currentBody={templateBody}
          updateShowPreview={updateShowPreview}
          setCurrentBody={setCurrentBody}
          setOriginalBody={setOriginalBody}
          wrapping={wrapping}
          template={template}
        />
      ) : (
        <TemplateBodyDisplay
          wrapping={wrapping}
          template={template}
          loading={loading || fixLoading}
          currentBody={templateBody}
        />
      )}
      <ActionsHeader />
      <TemplatePreviewDisplay
        loading={loading}
        error={error}
        evaluatedTemplate={evaluatedTemplate}
        currentBody={templateBody}
        updateShowPreview={updateShowPreview}
        showPreview={showPreview}
        editing={editing}
        wrapping={wrapping}
      />
    </div>
  )
}

import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import React from 'react'
import styles from './GlobalCreate.module.scss'

interface Props {
  title: string
  onClick: () => void
  openParameter: () => void
  openTypes: () => void
  openTemplates: () => void
  openEnvironments: () => void
  openTags: () => void
  openProjects: () => void
}

export const GlobalCreate = (props: Props) => {
  const {
    openParameter,
    openTags,
    openTemplates,
    openTypes,
    openEnvironments,
    openProjects,
    title,
    onClick,
  } = props

  const items = [
    {
      label: 'Create Project',
      key: 'project',
      onClick: openProjects,
    },

    {
      label: 'Create Parameter',
      key: 'parameter',
      onClick: openParameter,
    },
    {
      label: 'Create Template',
      key: 'template',
      onClick: openTemplates,
    },
    {
      label: 'Create  Environment',
      key: 'environment',
      onClick: openEnvironments,
    },
    {
      label: 'Create Type',
      key: 'types',
      onClick: openTypes,
    },

    {
      label: 'Create Tag',
      key: 'tag',
      onClick: openTags,
    },
  ]
  return (
    <div className={styles.container}>
      <Dropdown.Button
        menu={{ items }}
        type="default"
        size="large"
        style={{ width: 'inherit', marginRight: '20px' }}
        trigger={['click']}
        icon={<CaretDownOutlined style={{ fontSize: '12px' }} />}
        onClick={onClick}
        overlayStyle={{ width: '175px' }}
      >
        {title}
      </Dropdown.Button>
    </div>
  )
}

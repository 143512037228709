import { Environment, PatchedEnvironmentUpdate } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { EditModal } from 'components/Modals/EditModal'
import { UpdateEnvironment } from 'data/environment/actions'
import { UpdateEnvironmentDescriptionForm } from './UpdateEnvironmentDescriptionForm'
import { useAppDispatch } from 'data/hooks'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  environment: Environment
  closeModal: () => void
  visible: boolean
}

export function UpdateEnvironmentDescription(props: Props) {
  const { environment, closeModal, visible } = props

  const [pending, setPending] = useState<boolean>(false)

  const [form] = useForm()
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const onFinish = () => {
    setPending(true)

    const payload = {
      id: environment.id,
      description: form.getFieldValue('description'),
      name: form.getFieldValue('name'),
    } as PatchedEnvironmentUpdate

    dispatch(UpdateEnvironment({ ...environment, ...payload })).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Environment successfully updated')
      closeModal()
      setPending(false)
    })
  }

  const onCancel = () => {
    form.resetFields()
    closeModal()
  }

  return (
    <>
      <EditModal
        visible={visible}
        onCancel={onCancel}
        onOk={form.submit}
        pending={pending}
        objectName="environment"
      >
        <UpdateEnvironmentDescriptionForm
          form={form}
          onFinish={onFinish}
          environment={environment}
        />
      </EditModal>
    </>
  )
}

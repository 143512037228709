import React, { memo, useEffect, useRef } from 'react'

import { FileOutlined } from '@ant-design/icons'
import { FileUpload } from './Gpt'
import styles from './FileTags.module.scss'

interface FileTagProps {
  file: FileUpload
  deleteFile: (fileId: string) => void
}

const FileTag: React.FC<FileTagProps> = ({ file, deleteFile }) => {
  const imageExtensions = ['gif', 'jpeg', 'jpg', 'png', 'webp']
  const fileExtension = file.filename.split('.').pop()?.toLowerCase() || ''
  const isImage = imageExtensions.includes(fileExtension)
  const imageUrlRef = useRef<string | null>(null)

  if (!imageUrlRef.current && isImage && file.originalFile) {
    imageUrlRef.current = URL.createObjectURL(file.originalFile)
  }

  useEffect(() => {
    return () => {
      if (imageUrlRef.current) {
        URL.revokeObjectURL(imageUrlRef.current)
      }
    }
  }, [])

  if (isImage && file.originalFile && imageUrlRef.current) {
    return (
      <div className={styles.imageContainer}>
        <span style={{ backgroundImage: `url(${imageUrlRef.current})` }} className={styles.image} />
        <button className={styles.closeButton} onClick={() => deleteFile(file.id)}>
          ×
        </button>
      </div>
    )
  }

  return (
    <div className={styles.fileTagContainer}>
      <div className={styles.fileIcon}>
        <FileOutlined style={{ fontSize: '24px', color: '#fff' }} />
      </div>
      <div className={styles.fileDetails}>
        <div className={styles.fileName}>{file.filename}</div>
        <div className={styles.fileMeta}>File</div>
      </div>
      <button className={styles.closeButton} onClick={() => deleteFile(file.id)}>
        ×
      </button>
    </div>
  )
}

interface FileTagsProps {
  files: FileUpload[]
  deleteFile: (fileId: string) => void
}

const FileTags: React.FC<FileTagsProps> = ({ files, deleteFile }) => {
  return (
    <div className={styles.container}>
      {files.map((file) => (
        <FileTag key={file.id} file={file} deleteFile={deleteFile} />
      ))}
    </div>
  )
}

export default memo(FileTags, (prevProps, nextProps) => {
  return prevProps.files === nextProps.files && prevProps.deleteFile === nextProps.deleteFile
})

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  Invitation,
  InvitationCreate,
  PaginatedInvitationList,
  PaginatedUserList,
  PatchedInvitation,
  User,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

interface GetUserReq {
  id: string
  dontUpdateCurrent?: boolean
}

interface UpdateUserObj {
  chatgpt_threads: Record<string, any>
  id: string
}

export const GetUsers = createAsyncThunk<PaginatedUserList, null, ThunkConfig>(
  'user/getUsers',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'users', null, thunkApi)
)

export const GetUser = createAsyncThunk<User, GetUserReq, ThunkConfig>(
  'user/getUser',
  async (req, thunkApi) => await handleFetch(Verbs.Get, `users/${req.id}`, null, thunkApi)
)
export const UpdateUser = createAsyncThunk<User, UpdateUserObj, ThunkConfig>(
  'user/putUser',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Put,
      `users/${req.id}`,
      { chatgpt_threads: req.chatgpt_threads },
      thunkApi
    )
)

export const GetInvitations = createAsyncThunk<PaginatedInvitationList, null, ThunkConfig>(
  'user/getInvitations',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'invitations', null, thunkApi)
)

export const CreateInvitation = createAsyncThunk<Invitation, InvitationCreate, ThunkConfig>(
  'user/createInvitation',
  async (invitation, thunkApi) => await handleFetch(Verbs.Post, 'invitations', invitation, thunkApi)
)

export const UpdateInvitation = createAsyncThunk<Invitation, PatchedInvitation, ThunkConfig>(
  'user/updateInvitation',
  async (invitation, thunkApi) =>
    await handleFetch(Verbs.Put, `invitations/${invitation.id}`, invitation, thunkApi)
)

export const DeleteInvitation = createAsyncThunk<string, string, ThunkConfig>(
  'user/deleteInvitation',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `invitations/${id}`, null, thunkApi)
)

export const AcceptInvitation = createAsyncThunk<Invitation, string, ThunkConfig>(
  'user/acceptInvitation',
  async (id, thunkApi) => await handleFetch(Verbs.Post, `invitations/${id}/accept`, {}, thunkApi)
)

export const ResendInvitation = createAsyncThunk<Invitation, string, ThunkConfig>(
  'user/resendInvitation',
  async (id, thunkApi) => await handleFetch(Verbs.Post, `invitations/${id}/resend`, {}, thunkApi)
)

import React, { PropsWithChildren } from 'react'

import { Modal } from 'antd'

interface Props {
  visible: boolean
  objectName?: string
  afterClose?: () => void
  onCancel?: () => void
  onOk?: () => void
  pending?: boolean
  okText?: string
  footer?: JSX.Element
  width?: string
  isOverride?: boolean
  name: string
}

export const PreviewModal = (props: PropsWithChildren<Props>) => {
  const { visible, children, afterClose, onCancel, onOk, width, name } = props

  return (
    <Modal
      open={visible}
      title={<h3>{name}</h3>}
      afterClose={afterClose}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
      width={width}
    >
      {children}
    </Modal>
  )
}

import { Ace } from 'ace-builds'
import { Snippet } from './templateSnippets'

const SAFE_TOKENS = ['text', 'paren.rparen', 'punctuation.operator', 'operator', 'variable']

const getWrapped = (selection: Ace.Range, selected: string, open: string, close: string) => {
  const rowDiff = selection.end.row - selection.start.row
  return {
    text: open + selected + close,
    selection: [
      0,
      selection.start.column + open.length,
      rowDiff,
      selection.end.column + (rowDiff ? 0 : open.length),
    ],
  }
}

const matchTokenType = (token: string, types: string[]) => {
  return types.indexOf(token) > -1
}

const isSafeInsertion = (
  TokenIterator: any,
  editor: Ace.Editor,
  session: Ace.EditSession | any
) => {
  const cursor = editor.getCursorPosition()
  const iterator = new TokenIterator(session, cursor.row, cursor.column)
  // Don't insert in the middle of a keyword/identifier/lexical
  if (!matchTokenType(iterator.getCurrentToken()?.type || 'text', SAFE_TOKENS)) {
    // Look ahead in case we're at the end of a token
    const iterator2 = new TokenIterator(session, cursor.row, cursor.column + 1)
    if (!matchTokenType(iterator2.getCurrentToken()?.type || 'text', SAFE_TOKENS)) {
      return false
    }
  }
  return true
}

class TemplateUtils {
  checkRight = (editor: Ace.Editor, session: Ace.EditSession | any, text: string) => {
    const cursor = editor.getCursorPosition()
    const line = session.doc.getLine(cursor.row)
    const rightChar = line.substring(cursor.column, cursor.column + 1)

    return rightChar === text
  }

  checkLeft = (editor: Ace.Editor, session: Ace.EditSession | any, text: string) => {
    const selection = editor.getSelectionRange()
    const cursor = editor.getCursorPosition()
    const line = session.doc.getLine(cursor.row)
    const leftChar = line.substring(selection.start.column, selection.start.column - 1)

    return leftChar === text
  }

  handleOpen = (
    TokenIterator: any,
    editor: Ace.Editor,
    session: Ace.EditSession | any,
    open: string,
    close: string,
    lookRight: maybe<string> = null
  ) => {
    const selection = editor.getSelectionRange()
    const selected = session.doc.getTextRange(selection)

    if (isSafeInsertion(TokenIterator, editor, session)) {
      if (selected !== '' && editor.getWrapBehavioursEnabled()) {
        return getWrapped(selection, selected, open, close)
      } else if (!lookRight || this.checkRight(editor, session, lookRight)) {
        return {
          text: open + close,
          selection: [open.length, open.length],
        }
      }
    }
  }

  handleClose = (editor: Ace.Editor, session: Ace.EditSession | any, text: string) => {
    if (this.checkRight(editor, session, text)) {
      const cursor = editor.getCursorPosition()
      const matching = session.$findOpeningBracket(text, {
        column: cursor.column + 1,
        row: cursor.row,
      })

      if (matching !== null) {
        return {
          text: '',
          selection: [1, 1],
        }
      }
    }
  }

  handleDeletion = (
    session: Ace.EditSession | any,
    range: Ace.Range,
    open: string,
    close: string
  ) => {
    const selected = session.doc.getTextRange(range)

    if (!range.isMultiLine() && selected === open) {
      const line = session.doc.getLine(range.start.row)
      const rightChar = line.substring(range.end.column, range.end.column + close.length)
      if (rightChar === close) {
        range.end.column += close.length
        return range
      }
    }
  }

  insertSnippet = (editor: Ace.Editor, data: Ace.Completion, snippet: Snippet) => {
    // removes caption
    editor.removeWordLeft()

    // ensure new lines line up with the first line by adding left whitespace
    const startPos = editor.getCursorPosition()
    const value = data.value.replaceAll('\n', `\n${new Array(startPos.column + 1).join(' ')}`)

    // insert snippet
    editor.insert(value)

    if (snippet.cursor) {
      // find and select the placeholder text
      const endPos = editor.getCursorPosition()
      editor.findAll(snippet.cursor, {
        range: { start: startPos, end: endPos } as Ace.Range,
      })
    }
  }
}

export const templateUtils = new TemplateUtils()

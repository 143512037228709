/* eslint local-rules/no-nested-relative-parents: 0 */

import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { createAssistantThread, deleteAssistantThread, getAssistant } from './utils'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { Button } from 'antd'
import { Dragger } from './Dragger'
import { Messages } from './/Messages'
import OpenAI from 'openai'
import { PageLoading } from 'components/PageLoading'
import { Thread } from './types'
import { ThreadList } from './ThreadList'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getCurrentUser } from 'data/user/selectors'
import styles from './Gpt.module.scss'
import { useViewportHeight } from 'hooks'

export enum PromptType {
  Precanned = 'Precanned',
  Custom = 'Custom',
}

const openai_url = process.env.REACT_APP_OPENAI_URL

export interface FileUpload extends OpenAI.Files.FileObject {
  originalFile: any
}

interface Props {
  gptType: number
  assistants: nullable<OpenAI.Beta.Assistant[]>
  setAssistants: (assistants: nullable<OpenAI.Beta.Assistant[]>) => void
  setOpen: (bool: boolean) => void
}

export function Gpt(props: Props) {
  const { gptType, assistants, setAssistants, setOpen } = props

  const [assistantsLoading, setAssistantsLoading] = useState(true)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [selectThreadDisabled, setSelectThreadDisabled] = useState(false)
  const [lineCount, setLineCount] = useState(1)
  const [createThreadLoading, setCreateThreadLoading] = useState(false)
  const [selectedThread, setSelectedThread] = useState<nullable<Thread>>(null)
  const [messages, setMessages] = useState<nullable<OpenAI.Beta.Threads.Messages.Message[]>>(null)
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [promptType, _] = useState<PromptType>(PromptType.Custom)

  const [createMessageLoading, setCreateMessageLoading] = useState(false)
  const [deleteThreadDisabled, setDeleteThreadDisabled] = useState<nullable<string>>(null)
  const [createTitleLoading, setCreateTitleLoading] = useState(false)
  const [messageFiles, setMessageFiles] = useState<any>(null)
  const [error, setError] = useState<maybe<string>>(null)
  const { viewportHeight } = useViewportHeight()
  const token = useAppSelector((state) => state.session.jwt)
  const currentUser = useAppSelector(getCurrentUser)

  const threads = useMemo(() => {
    return Object.values(currentUser?.chatgpt_threads || {})
  }, [currentUser?.chatgpt_threads])

  const currentOrg = useAppSelector(getCurrentOrganization)
  const dispatch = useAppDispatch()

  const openai = useMemo(() => {
    return new OpenAI({
      apiKey: 'abc123',
      dangerouslyAllowBrowser: true,
      baseURL: openai_url,
      defaultHeaders: {
        [`X-Authorization`]: `Api-Key ${token}`,
      },
    })
  }, [token])

  useEffect(() => {
    const getAssistantsWithLoading = async () => {
      setAssistantsLoading(true)

      const getAssistannts = new Promise((resolve) => {
        getAssistant(openai)
          .then((response) => {
            setAssistants([response])
          })
          .catch((_) => {
            setAssistants(null)
            setAssistantsLoading(false)
          })
          .then(() => setAssistantsLoading(false))

        resolve({})
      })

      return await getAssistannts
    }

    getAssistantsWithLoading()
  }, [currentOrg, setAssistants, openai])

  const createThread = async () => {
    setSelectThreadDisabled(true)
    setCreateThreadLoading(true)

    if (error) {
      setError(null)
    }

    const thread = await createAssistantThread(openai, {
      user: currentUser!,
      dispatch,
    })

    setSelectedThread(thread)

    setSelectThreadDisabled(false)
    setCreateThreadLoading(false)
    return thread
  }

  const deleteThread = async (thread: Thread) => {
    setDeleteLoading(true)

    const setThread = async () => {
      new Promise((resolve) => {
        setSelectedThread(null)
        setMessages(null)
        resolve({})
      })
    }

    if (thread.id === selectedThread?.id) {
      if (error) {
        setError(null)
      }

      await setThread()
    }

    await deleteAssistantThread(openai, {
      user: currentUser!,
      dispatch,
      selectedThread: thread!,
    })

    setDeleteLoading(false)
  }

  if (!assistants && assistantsLoading) {
    return (
      <div className={styles.container}>
        <PageLoading />
      </div>
    )
  } else if (!assistants) {
    return (
      <div className={styles.container}>
        <div className={styles.precannedMessageContainer}>
          <img data-testid={`black-logo`} src={require('styles/images/black-logo.png')} />
          <span className={styles.errorText}>
            <p>Unable to load the Copilot AI. Please try again later.</p>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container} style={{ height: `${viewportHeight - 7}px ` }}>
      <Dragger openai={openai} files={messageFiles} setFiles={setMessageFiles}>
        <div className={styles.controlButtons}>
          <div className={styles.actionsContainer}>
            <div className={styles.buttonContainer}>
              <img
                data-testid={`copilot-logo`}
                src={require('styles/images/copilot_logo.png')}
                className={styles.logo}
                // alt={node.meta.label?.text}
              />
              <ActionButton
                onClick={createThread}
                loading={createThreadLoading}
                disabled={
                  selectThreadDisabled ||
                  deleteLoading ||
                  createMessageLoading ||
                  createTitleLoading
                }
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                style={{ marginBottom: '0px', marginLeft: '30px' }}
              />
            </div>
          </div>

          {/* <Radio.Group value={promptType} onChange={(e) => setPromptType(e.target.value)}>
            <Radio.Button value={PromptType.Precanned}>Precanned</Radio.Button>
            <Radio.Button value={PromptType.Custom}>Custom</Radio.Button>
          </Radio.Group> */}
          <Button
            icon={<CloseOutlined style={{ color: 'white' }} />}
            style={{ background: 'black', border: 'none', margin: '12px' }}
            className={styles.closeButton}
            shape="circle"
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>

        <div className={styles.gptContainer} style={{ height: `${viewportHeight - 170}px` }}>
          <ThreadList
            deleteThread={deleteThread}
            threads={threads}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            createThread={createThread}
            createThreadLoading={createThreadLoading}
            disabled={selectThreadDisabled || deleteLoading || createMessageLoading}
            deleteThreadDisabled={deleteThreadDisabled}
            createTitleLoading={createTitleLoading}
            error={error}
            setError={setError}
          />

          <Messages
            messages={messages}
            setMessages={setMessages}
            promptType={promptType}
            selectedThread={selectedThread}
            assistant={assistants[gptType]}
            hasThreads={!!threads && threads.length > 0}
            lineCount={lineCount}
            setLineCount={setLineCount}
            createMessageLoading={createMessageLoading}
            setCreateMessageLoading={setCreateMessageLoading}
            openai={openai}
            createThread={createThread}
            setDeleteThreadDisabled={setDeleteThreadDisabled}
            setCreateTitleLoading={setCreateTitleLoading}
            files={messageFiles}
            setFiles={setMessageFiles}
            error={error}
            setError={setError}
          />
        </div>
      </Dragger>
    </div>
  )
}

import { Link, useLocation } from 'react-router-dom'

import { LockOutlined } from '@ant-design/icons'
import { Parameter } from 'gen/cloudTruthRestApi'
import React from 'react'
import { Tooltip } from 'antd'
import { getCurrentProject } from 'data/project/selectors'
import styles from './Table.module.scss'
import { useAppSelector } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  parameter: Parameter
  inheritedFrom?: string
  disabled?: boolean
}

export function NameColumn(props: Props) {
  const { parameter, inheritedFrom, disabled } = props
  const { id, name, secret } = parameter
  const { goToParameterPage } = useHistoryPush()
  const search = useLocation().search
  const currentProject = useAppSelector(getCurrentProject)!

  if (disabled) {
    return (
      <p
        className={
          secret
            ? [styles.keyNameWithIcon, styles.disabled].join(' ')
            : [styles.keyName, styles.disabled].join(' ')
        }
        onClick={() => goToParameterPage(id)}
      >
        {name}
      </p>
    )
  }

  return (
    <div className={styles.keyNameContainer}>
      <div className={styles.leftIconContainer}>
        {secret && <LockOutlined className={styles.lockOutlined} disabled={!!inheritedFrom} />}
      </div>

      {inheritedFrom ? (
        <Tooltip title={`inherited from ${inheritedFrom}`}>
          <div className={secret ? styles.nameContainerIcon : styles.nameContainer}>
            {/* <ParameterPreviewDetails parameter={parameter} /> */}
            <p
              className={
                secret
                  ? [styles.keyNameWithIcon, styles.disabled].join(' ')
                  : [styles.keyName, styles.disabled].join(' ')
              }
            >
              {name}
            </p>
          </div>
        </Tooltip>
      ) : (
        <div className={secret ? styles.nameContainerIcon : styles.nameContainer}>
          {/* <ParameterPreviewDetails parameter={parameter} /> */}
          <Link
            to={`/projects/${currentProject.id}/parameters/${id}` + search}
            className={secret ? styles.keyNameWithIcon : styles.keyName}
          >
            {name}
          </Link>
        </div>
      )}
    </div>
  )
}

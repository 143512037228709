//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Modal } from './Modal'
import { authService } from 'lib/authService'
import { getCurrentOrganization } from 'data/organization/selectors'
import { setJwt } from 'data/session/reducer'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  visible: boolean
  setVisible: React.Dispatch<boolean>
}

const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  return `${minutes ? `${minutes} minute${minutes > 1 ? 's' : ''} and ` : ''}${seconds} second${
    seconds === 1 ? '' : 's'
  }`
}

export function SessionWarningModal(props: Props) {
  const { visible, setVisible } = props
  const activeOrg = useAppSelector(getCurrentOrganization)
  const dispatch = useAppDispatch()
  const auth0Context = useAuth0()

  const [timeRemaining, setTimeRemaining] = useState<number>(300)
  const intervalRef = useRef<ReturnType<typeof setTimeout>>()
  const timeRef = useRef<number>(300)

  const refreshToken = () => {
    authService.getJwt(auth0Context, activeOrg?.id || null, (jwt: JWT): void =>
      dispatch(setJwt(jwt))
    )
    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      timeRef.current = 300
      setTimeRemaining(300)

      intervalRef.current = setInterval(() => {
        if (timeRef.current > 0) {
          timeRef.current = timeRef.current - 1
          setTimeRemaining(timeRef.current)
        }
      }, 1000)

      return () => intervalRef.current && clearInterval(intervalRef.current)
    } else {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [visible])

  return (
    <Modal
      title="Your session is about to expire"
      visible={visible}
      onOk={refreshToken}
      okText="Keep Working"
      onCancel={() => authService.logout(auth0Context)}
      cancelText="Sign Out"
    >
      <p>
        You will be automatically signed out in <b>{formatTime(timeRemaining)}</b>.
      </p>
      <p>Select Keep Working to extend your session.</p>
    </Modal>
  )
}

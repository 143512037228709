import { Checkbox, DatePicker } from 'antd'
import { Form, LabeledInputItem, useForm } from 'components/Forms'
import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { Modal } from 'components/Modals'
import { isoDateTimeFormat } from 'lib/dateHelpers'

export interface Props {
  visible: boolean
  handleSubmit: (expires_at?: nullable<string>) => void
  onCancel: () => void
  loading: boolean
}

export function ReKeyModal(props: Props) {
  const { visible, handleSubmit, onCancel, loading } = props
  const [form] = useForm()
  const [expiresChecked, setExpiresChecked] = useState(false)
  const [date, setDate] = useState<nullable<Dayjs>>()

  const handleDateChange = (val: nullable<Dayjs>): void => {
    setDate(val)
  }

  return (
    <div>
      <Modal
        visible={visible}
        title={`Regenerate Token`}
        onCancel={onCancel}
        onOk={form.submit}
        pending={loading}
        okText={`Regenerate`}
        afterClose={onCancel}
      >
        <Form
          form={form}
          onFinish={() => {
            handleSubmit(expiresChecked ? (date as any) : undefined), onCancel()
          }}
        >
          <LabeledInputItem
            name="expiration"
            label={{
              text: 'Old Token Expiration',
            }}
            tooltipText="The selected timestamp indicates when the current token will be replaced with a new one for continued access. If the checkbox isn't selected, the old token will be deleted immediately."
          >
            <Checkbox
              checked={expiresChecked}
              onChange={(e) => setExpiresChecked(e.target.checked)}
            />
          </LabeledInputItem>

          {expiresChecked && (
            <DatePicker
              disabledDate={(current) => current && current.isBefore(dayjs(), 'day')}
              use12Hours
              format={isoDateTimeFormat}
              showTime={{ defaultValue: dayjs(), use12Hours: false }}
              onChange={handleDateChange}
              value={date}
              onSelect={handleDateChange}
            />
          )}
        </Form>
      </Modal>
    </div>
  )
}

import { Form, FormInstance, Input } from 'antd'
import React, { useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import styles from './TableSearch.module.scss'

const SEARCH_FIELD = 'search'

interface Props {
  defaultValue: string
  updateSearchTerm: (name: string) => void
  form: FormInstance
  placeholder?: string
}

export function TableSearch(props: Props) {
  const { defaultValue, updateSearchTerm, form, placeholder } = props
  const [liveSearchValue, setLiveSearchValue] = useState('')

  return (
    <div>
      <Form form={form} layout="inline" initialValues={{ [SEARCH_FIELD]: defaultValue }}>
        {/* <Form.Item label="SEARCH PUSH BY PROJECT:" name={SEARCH_FIELD}> */}
        <Input
          className={styles.search}
          aria-label="table-search"
          onChange={(e) => {
            if (!e.target.value) {
              updateSearchTerm('')
            }
            setLiveSearchValue(e.target.value)
          }}
          data-testid="search"
          suffix={
            <SearchOutlined
              style={{ cursor: 'pointer' }}
              data-testid="submit-search"
              onClick={() => updateSearchTerm(liveSearchValue)}
            />
          }
          onPressEnter={() => updateSearchTerm(liveSearchValue)}
          allowClear
          placeholder={placeholder ? placeholder : ''}
        />
        {/* </Form.Item> */}
      </Form>
    </div>
  )
}

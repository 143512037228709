import React, { PropsWithChildren } from 'react'

import { EyeFilled } from '@ant-design/icons'
import { PreviewModal } from './PreviewModal'
import { Spin } from 'antd'
import styles from './PreviewDetails.module.scss'

interface Props {
  name: string
  iconMargin?: string
  modalWidth?: string
  onClick: () => void
  visible: boolean
  setVisible: (bool: boolean) => void
  loading?: boolean
}

export function PreviewDetails(props: PropsWithChildren<Props>) {
  const { name, children, iconMargin, modalWidth, onClick, visible, setVisible, loading } = props

  return (
    <div>
      {loading ? (
        <Spin className={styles.spin} size="small" />
      ) : (
        <EyeFilled
          style={{ margin: iconMargin, color: 'rgb(101, 103, 110)' }}
          onClick={onClick}
          data-cy="preview-details-icon"
        />
      )}

      <PreviewModal
        visible={visible}
        onCancel={() => setVisible(false)}
        name={name}
        width={modalWidth}
      >
        {children}
      </PreviewModal>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionButton } from 'components/ActionButton'
import React from 'react'
import styles from './ModalStepsFooter.module.scss'

interface Props {
  steps: number
  objectName: string
  onCancel: () => void
  onPrevious: () => void
  onOk: () => void
  pending: boolean
  editing?: boolean
  onDryRun?: () => void
  isCopy?: boolean
  visibleFromProps?: boolean
}

type OkayText = {
  [key: number]: string
}

export function ModalStepsFooter(props: Props) {
  const {
    onCancel,
    onPrevious,
    onOk,
    steps,
    pending,
    editing,
    objectName,
    onDryRun,
    isCopy,
    visibleFromProps,
  } = props

  const okText: OkayText = {
    0: 'Next',
    1: 'Preview',
    2: editing
      ? `Update ${objectName}`
      : isCopy || visibleFromProps
      ? `Copy ${objectName}`
      : `Create ${objectName}`,
  }

  return (
    <div className={styles.container}>
      <ActionButton onClick={onCancel} disabled={pending}>
        Cancel
      </ActionButton>

      <div className={styles.rightButtons}>
        {steps > 0 && (
          <ActionButton onClick={onPrevious} disabled={pending}>
            Previous
          </ActionButton>
        )}
        {onDryRun && steps === 2 && (
          <ActionButton onClick={onDryRun} type="primary" loading={pending}>
            Dry Run
          </ActionButton>
        )}
        <ActionButton onClick={onOk} type="primary" loading={pending}>
          {okText[steps]}
        </ActionButton>
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { ActionEnum } from 'data/actions/utils'
import { ActionsHash } from 'router/customHooks'
import { PageTitle } from 'components/PageTitle'
import { PushTable } from './PushTable'
import { Tabs } from 'antd'
import styles from './PushTable.module.scss'
import { useLocation } from 'react-router-dom'

const AWS = 'aws'
const AKV = 'azure-key-vault'

export function PushContainer() {
  const { hash } = useLocation()
  const [tab, setTab] = useState(hash.substring(1) || AWS)

  const Description = () => {
    switch (hash) {
      case ActionsHash.AWS:
        return (
          <>
            <p className={styles.description}>
              To use an AWS push action, you need: <br /> An integration with write permission and
              supported services (AWS, SSM, Secrets Manager) enabled. <br /> An environment tag,
              which is a snapshot of your parameter values.
            </p>
          </>
        )

      case ActionsHash.AKV:
        return (
          <>
            <p className={styles.description}>
              To use an Azure push action, you need: <br /> An integration with write permission
              enabled. <br /> An environment tag, which is a snapshot of your parameter values.
            </p>
          </>
        )

      default:
        return (
          <>
            <p className={styles.description}>
              To use an AWS push action, you need: <br /> An integration with write permission and
              supported services (AWS, SSM, Secrets Manager) enabled. <br /> An environment tag,
              which is a snapshot of your parameter values.
            </p>
          </>
        )
    }
  }

  const awsTab = (
    <>
      {' '}
      <Description />
      <PushTable actionType={ActionEnum.AwsPush} />
    </>
  )

  const akvTab = (
    <>
      {' '}
      <Description />
      <PushTable actionType={ActionEnum.AzureKeyVaultPush} />
    </>
  )

  const items = [
    {
      key: AWS,
      label: (
        <div className={styles.tabContainer}>
          <div className={styles.logoWrapper}>
            <div className={styles.logoAWS} />
          </div>
          <h3>AWS</h3>
        </div>
      ),
      children: awsTab,
    },
    {
      key: AKV,
      label: (
        <div className={styles.tabContainer}>
          <div className={styles.logoWrapper}>
            <div className={styles.logoAKV} />
          </div>
          <h3>Azure</h3>
        </div>
      ),
      children: akvTab,
    },
  ]

  return (
    <>
      <PageTitle
        title="Push Actions"
        description="Push actions allow you to send parameters to an integration."
      />
      <Tabs
        className={styles.tabs}
        activeKey={tab}
        items={items}
        onChange={(key) => {
          setTab(key)
          window.location.hash = key
        }}
      />
    </>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Avatar, List, Spin, Switch, Tabs, Tooltip } from 'antd'
import { KeyOutlined, TeamOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getCurrentUser, getServiceUsers } from 'data/user/selectors'
import { groupEntitySelectors, useSelectGroups } from 'data/group/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ChangeGrantRole } from './ChangeGrantRole'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { CustomThunk } from 'data/dataUtils'
import { GetGrants } from 'data/grant/actions'
import { GetGroups } from 'data/group/actions'
import { RoleEnum } from 'gen/cloudTruthRestApi'
import { UserGrantList } from './UserGrantList'
import { getGrantByScope } from 'data/grant/selectors'
import { getPolicy } from 'data/membership/selectors'
import { grantHash } from './utils'
import { removeOneGrant } from 'data/grant/reducer'
import styles from './Permissions.module.scss'
import { useLocation } from 'react-router-dom'
import { useToast } from 'hooks'

const PAGE_SIZE = 10
const USER_KEY = 'users'
const GROUPS_KEY = 'groups'
const SERVICE_KEY = 'service-accounts'

interface Props {
  scope: string
  accessName: string
  accessControlled?: boolean
  updateAccessControl: (bool: boolean) => void
}

export function Permissions(props: Props) {
  const { scope, accessName, updateAccessControl, accessControlled } = props

  const scopeGrants = useAppSelector(getGrantByScope(scope))
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const { successToast, errorToast } = useToast()
  const { hash } = useLocation()

  const [loading, setLoading] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [tab, setTab] = useState(hash.substring(1) || USER_KEY)
  const groups = groupEntitySelectors.selectAll(useSelectGroups())
  const serviceAccounts = useAppSelector(getServiceUsers)
  const currentUser = useAppSelector(getCurrentUser)

  // hash that indexes grants by principle id
  const scopedGrantHash = useMemo(() => {
    return grantHash(scopeGrants)
  }, [scopeGrants])

  const canChangeRole = () => {
    const { ADMIN, OWNER } = RoleEnum

    const canAdministrateGrant = (role?: RoleEnum) =>
      !role ? false : role === OWNER || role === ADMIN

    const hasGroupAdminRights = groups
      .filter((group) => group.users.includes(currentUser!.url))
      .some((group) => canAdministrateGrant(scopedGrantHash[group.id]?.role))

    const hasUserAdminRights = canAdministrateGrant(scopedGrantHash[currentUser!.id]?.role)

    if (canAdministrate || hasUserAdminRights || hasGroupAdminRights) {
      return true
    }

    return false
  }

  useEffect(() => {
    setLoading(true)

    const getGrants = new Promise((resolve) => {
      dispatch(GetGrants(null))
      resolve({})
    })

    const getGroups = new Promise((resolve) => {
      dispatch(GetGroups(null)).then(() => {
        setLoading(false)
      })
      resolve({})
    })

    Promise.all([getGrants, getGroups])
  }, [setLoading, dispatch])

  const addUserGrant = async () => {
    setLoading(true)

    await updateAccessControl(true)
    dispatch(GetGrants(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
        return
      }
    })

    setLoading(false)
    successToast('Access Control turned on')
  }

  // when access control turns off all grants need to be removed
  const removeAllUserGrants = useCallback(async () => {
    setLoading(true)
    await updateAccessControl(false)
    scopeGrants.forEach((scope) => dispatch(removeOneGrant(scope.id)))
    setLoading(false)
    setConfirmVisible(false)
    successToast('Access Control turned off')
  }, [scopeGrants, dispatch, successToast, updateAccessControl])

  const handleChange = (checked: boolean) => {
    checked ? addUserGrant() : setConfirmVisible(true)
  }

  if (loading) {
    return (
      <div className={styles.spinContainer}>
        <Spin />
      </div>
    )
  }

  const usersTab = (
    <UserGrantList scope={scope} pageSize={10} disableChangeRole={!canChangeRole()} />
  )

  const serviceAccountTab = (
    <List
      pagination={{ pageSize: PAGE_SIZE }}
      className={styles.list}
      dataSource={serviceAccounts}
      renderItem={(serviceAccount) => (
        <List.Item
          actions={[
            <ChangeGrantRole
              grant={scopedGrantHash[serviceAccount.id]}
              principal={serviceAccount.url}
              disabled={!canChangeRole()}
              scope={scope}
              key="change2"
            />,
          ]}
        >
          <List.Item.Meta
            className={styles.meta}
            avatar={<Avatar icon={<KeyOutlined rotate={180} />} />}
            title={`${serviceAccount.name}`}
          />
        </List.Item>
      )}
    />
  )

  const groupsTab = (
    <List
      pagination={{ pageSize: PAGE_SIZE }}
      className={styles.list}
      dataSource={groups}
      renderItem={(group) => (
        <List.Item
          actions={[
            <ChangeGrantRole
              grant={scopedGrantHash[group.id]}
              principal={group.url}
              disabled={!canChangeRole()}
              scope={scope}
              key="change1"
            />,
          ]}
        >
          <List.Item.Meta
            className={styles.meta}
            avatar={<Avatar icon={<TeamOutlined />} shape="square" />}
            title={`${group.name}`}
          />
        </List.Item>
      )}
    />
  )

  const items = [
    { key: USER_KEY, label: 'Users', children: usersTab },
    { key: SERVICE_KEY, label: 'Service Accounts', children: serviceAccountTab },
    { key: GROUPS_KEY, label: 'Groups', children: groupsTab },
  ]

  return (
    <div>
      <ConfirmModal
        body={`If you remove access control from ${accessName}, all granted roles for it will be deleted, and it will become accessible to all organization members.  Are you sure you want to do this?`}
        onOk={removeAllUserGrants}
        onCancel={() => setConfirmVisible(false)}
        visible={confirmVisible}
      />

      <div className={styles.controlContainer}>
        <h3>Access Control for {accessName}</h3>
        {accessName === 'default' && scope.includes('environment') ? (
          <Tooltip title="Access Control cannot be enabled for the default environment.">
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={!!accessControlled}
              disabled={true}
            />
          </Tooltip>
        ) : (
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(checked) => handleChange(checked)}
            checked={!!accessControlled}
            disabled={!canAdministrate}
          />
        )}
      </div>

      {accessControlled && (
        <div className={styles.permissions}>
          <Tabs
            activeKey={tab}
            onChange={(key) => {
              setTab(key)
              window.location.hash = key
            }}
            items={items}
          />
        </div>
      )}
    </div>
  )
}

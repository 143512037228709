import { Details, DetailsDate } from 'components/Details'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'

import { Divider } from 'antd'
import { Environment } from 'gen/cloudTruthRestApi'
import { EnvironmentDetailsDropdown } from './EnvironmentDetailsDropdown'
import { EnvironmentTags } from './Tags/EnvironmentTags'
import { LabelText } from 'components/LabelText'
import React from 'react'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import { idFromUrl } from 'data/dataUtils'
import styles from './EnvironmentDetails.module.scss'
import { useAppSelector } from 'data/hooks'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  environment: Environment
  getEnvironments: () => void
}

export function EnvironmentDetails(props: Props) {
  const { environment, getEnvironments } = props
  const { name, description, parent, modified_at, created_at } = environment
  const environmentSelect = useSelectEnvironments()
  const environments = environmentEntitySelectors.selectAll(environmentSelect)
  const { canContribute } = useAppSelector(getPolicy(null))

  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const parentName = parent
    ? environmentEntitySelectors.selectById(environmentSelect, idFromUrl(parent))?.name
    : null

  const childrenNames = environments
    .filter((n: Environment) => n.parent === environment.url)
    .map((n: Environment) => n.name)
    .reduce((acc: nullable<string>, name: string) => (acc ? `${acc}, ${name}` : name), null)

  const dropdownMenu = canContribute ? (
    <EnvironmentDetailsDropdown environment={environment} getEnvironments={getEnvironments} />
  ) : undefined

  return (
    <div className={styles.container}>
      <Details
        name={name}
        dropdownMenu={dropdownMenu}
        description={description}
        headerStyles={{ alignItems: 'flex-start' }}
      >
        <div className={styles.detailsContainer}>
          <div className={styles.contentContainer}>
            <DetailsDate updatedAt={modified_at} createdAt={created_at} />
            <div className={styles.labelContainer}>
              <LabelText isHorizontal text={valueOrEmDash(parentName)} label="Parent:" />
              <LabelText
                isHorizontal
                maxWidth="300px"
                text={valueOrEmDash(childrenNames)}
                label="Children:"
              />
            </div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <EnvironmentTags currentOrganization={currentOrganization} />
      </Details>
    </div>
  )
}

import { IntegrationNode, PaginatedIntegrationNodeList } from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'

import { GetIntegrationExplorer } from 'data/integration/actions'
import { ServiceTreeEntry } from './ServiceTreeEntry'
import { TypedThunk } from 'data/dataUtils'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  onSelect: (fqn: string) => void
  pending: boolean
  selectedFqn: nullable<string>
  showExternalSecretValues: boolean
}

export function RootServiceTree(props: Props) {
  const { onSelect, pending, selectedFqn, showExternalSecretValues } = props
  const [entries, setEntries] = useState<IntegrationNode[]>([])
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  useEffect(() => {
    dispatch(GetIntegrationExplorer({})).then(
      ({ error, payload }: TypedThunk<PaginatedIntegrationNodeList>) => {
        error ? errorToast(error.message) : setEntries(payload.results!)
      }
    )
  }, [dispatch, errorToast])

  return (
    <>
      {entries.map((entry: IntegrationNode) => (
        <ServiceTreeEntry
          selectedFqn={selectedFqn}
          showExternalSecretValues={showExternalSecretValues}
          key={entry.fqn}
          integrationTree={entry}
          onSelect={onSelect}
          pending={pending && entry.fqn === selectedFqn}
        />
      ))}
    </>
  )
}

import { FormData, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { EditModal } from 'components/Modals/EditModal'
import { Template } from 'gen/cloudTruthRestApi'
import { UpdateTemplate } from 'data/template/actions'
import { UpdateTemplateDescriptionForm } from './UpdateTemplateDescriptionForm'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  closeModal: () => void
  visible: boolean
  template: Template
}

export function UpdateTemplateDescription(props: Props) {
  const { closeModal, visible, template } = props

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const [pending, setPending] = useState<boolean>(false)
  const [form] = useForm()

  const onFinish = (formData: FormData) => {
    const formObj = { description: formData.description, id: template.id, name: formData.name }

    setPending(true)
    dispatch(UpdateTemplate(formObj)).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Template successfully updated')

      setPending(false)
      closeModal()
    })
  }

  return (
    <EditModal
      visible={visible}
      onCancel={closeModal}
      onOk={form.submit}
      pending={pending}
      objectName="template"
    >
      <UpdateTemplateDescriptionForm form={form} onFinish={onFinish} template={template} />
    </EditModal>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Layouts } from 'react-grid-layout'

const ACTIVE_ORGANIZATION = 'activeOrganization'
const ACTIVE_PROJECT = 'activeProject'
const EDITOR_SETTINGS = 'editorSettings'
const DASHBOARD_SETTINGS = 'dashboardSettings'

interface EditorSettings {
  wrapping?: boolean
}

export class LocalStorageHelpers {
  setActiveOrgId = (id: nullable<string>): void => {
    if (id && id !== this.getActiveOrgId()) {
      localStorage.setItem(ACTIVE_ORGANIZATION, id)
    } else if (!id) {
      localStorage.removeItem(ACTIVE_ORGANIZATION)
    }
  }

  getActiveOrgId = (): nullable<string> => {
    return localStorage.getItem(ACTIVE_ORGANIZATION)
  }

  setActiveProjectId = (id: nullable<string>): void => {
    if (id && id !== this.getActiveOrgId()) {
      localStorage.setItem(ACTIVE_PROJECT, id)
    } else if (!id) {
      localStorage.removeItem(ACTIVE_PROJECT)
    }
  }

  getActiveProjectId = (): nullable<string> => {
    return localStorage.getItem(ACTIVE_PROJECT)
  }

  clearActiveIds = (): void => {
    localStorage.removeItem(ACTIVE_PROJECT)
    localStorage.removeItem(ACTIVE_ORGANIZATION)
  }

  setEditorSettings = (settings: EditorSettings): void => {
    const editorSettings = this.getEditorSettings()
    localStorage.setItem(EDITOR_SETTINGS, JSON.stringify({ ...editorSettings, ...settings }))
  }

  getEditorSettings = (): EditorSettings => {
    return JSON.parse(localStorage.getItem(EDITOR_SETTINGS) || '{}') as EditorSettings
  }

  setUserDashboardSettings = (userId: string, settings: Layouts): void => {
    const newSettings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS) || '{}')
    newSettings[userId] = settings

    localStorage.setItem(DASHBOARD_SETTINGS, JSON.stringify(newSettings))
  }

  getUserDashboardSettings = (userId: string): Layouts => {
    const settings = JSON.parse(localStorage.getItem(DASHBOARD_SETTINGS) || '{}')

    return settings[userId] || {}
  }
}

export const localStorageHelpers = new LocalStorageHelpers()

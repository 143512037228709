import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteTemplate } from 'data/template/actions'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { Template } from 'gen/cloudTruthRestApi'
import { UpdateTemplateDescription } from '../UpdateTemplateDescription'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

const EDIT_DESCRIPTION = 'editDescription'
const DELETE_TEMPLATE = 'deleteTemplate'

interface Props {
  template: Template
  onDelete: () => void
}

export function TemplateCardDropdown(props: Props) {
  const { template, onDelete } = props

  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()

  const [deleteVisible, setDeleteVisible] = useState(false)
  const [descriptionVisible, setDescriptionVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteTemplate = () => {
    setLoading(true)

    dispatch(DeleteTemplate(template.id)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
      } else {
        onDelete()
        successToast('Template successfully deleted')
      }
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case EDIT_DESCRIPTION: {
        setDescriptionVisible(true)
        break
      }

      case DELETE_TEMPLATE: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canContribute, [
      { key: EDIT_DESCRIPTION, label: 'Edit' },
      { key: DELETE_TEMPLATE, label: 'Delete', danger: true },
    ]),
  ]

  //   <DeleteConfirmModal
  //   closeModal={() => setDeleteVisible(false)}
  //   visible={deleteVisible}
  //   onDelete={deleteEnvironment}
  //   subject={`"${environment.name}"`}
  //   loading={loading}
  //   removePronoun
  // />

  return (
    <div>
      <DeleteConfirmModal
        visible={deleteVisible}
        loading={loading}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deleteTemplate}
        subject={`"${template.name}"`}
        removePronoun
      />
      <UpdateTemplateDescription
        template={template}
        visible={descriptionVisible}
        closeModal={() => setDescriptionVisible(false)}
      />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </div>
  )
}

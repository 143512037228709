// fix me: this type was changed to any because it's no longer available in the api. It will need to be replaced once available.
type AwsIntegrationEnum = any

import LogRocket from 'logrocket'

type TrackedObject =
  | 'Environment'
  | 'Integration'
  | 'Override'
  | 'Parameter'
  | 'Project'
  | 'Session'
  | 'Template'

export type TrackedAction = 'Created' | 'Deleted' | 'Ended' | 'Started' | 'Updated'

type TrackedPayload = IntegrationPayload | OverridePayload // | LinkPayload | PaginationPayload

type IntegrationType = 'AWS'
type IntegrationService = AwsIntegrationEnum
interface IntegrationPayload {
  type: IntegrationType
  services?: readonly IntegrationService[]
}

/* UNUSED
type LinkType = 'external page' | 'internal page' | 'internal event' | 'toggle'
interface LinkPayload {
  link_description?: string // Human readable description to help understand it from the analytics dashboard (e.g., historical data that can't easily be matched up with code)
  link_destination?: string // URL, if internal, or human description of destination, if external. This should not be used for completely unique URLs, as that floods the analytics DB with data not easily stratified
  link_text: string // The link's literal text, if internal, or a description enclosed by "<" and ">", if external (e.g., "<commit sha>").
  link_type?: LinkType // Very coarse indication as to what the string represents.
}
*/

type OverrideType = 'external' | 'internal'
interface OverridePayload {
  type: OverrideType
}

/* UNUSED
interface PaginationPayload {
  source_page: number
  destination_page: number
  page_size: number

  has_page_numbers: boolean // Can the user skip to a specific page or is it simply "previous"/"next" pagination?
  infinite_scroll: boolean // Is pagination driven by scroll events or by explicitly clicking an HTML element?
}
*/

export function trackEvent(
  object: TrackedObject,
  action: TrackedAction,
  payload?: TrackedPayload
): void {
  // normalize to match how the server makes past_tense_verb-noun event names
  const event = `${action}-${object}`.toLowerCase()

  // We only record the Session telemetry here because the server is recording
  // telemetry on API calls, so if we do it here we will be duplicating the
  // telemetry and this would lead to inaccurate event counts.
  if (object === 'Session') {
    window.analytics?.track(event, payload)
  }

  // LogRocket does not accept info/payload and is not integrated into segment
  LogRocket.track(event)
}

/* UNUSED
export function trackLinkClick(object: TrackedObject, properties: LinkPayload): void {
  trackEvent(object, 'Link Clicked', properties)
}

export function paginationPayload(
  sourcePage: number,
  destinationPage: number,
  pageSize: number,
  infiniteScroll: boolean,
  hasPageNumbers: boolean
): PaginationPayload {
  return {
    destination_page: destinationPage,
    has_page_numbers: hasPageNumbers,
    infinite_scroll: infiniteScroll,
    page_size: pageSize,
    source_page: sourcePage,
  }
}
*/

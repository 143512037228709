import { Auth0ContextInterface, RedirectLoginOptions } from '@auth0/auth0-react/dist/auth0-context'

import { EventEmitter } from 'events'
import { LoggerUtils } from './loggerUtils'
import { isTokenExpired } from 'lib/jwtHelpers'

export const LOGOUT_REDIRECT_URL =
  process.env.REACT_APP_AUTH0_REDIRECT_URI || 'https://cloudtruth.com/'
export const LOGOUT_EVENT = 'logout_event'
export const LOGOUT_STARTED_EVENT = 'logout_started_event'
export const TOKEN_KEY = 'access_token'

interface Subscription {
  stop: () => void
}

export class AuthService extends EventEmitter {
  logout = (context: Auth0ContextInterface): void => {
    // Allow listeners to be notified that the user is logging out. This is most useful for event tracking and
    // resource cleanup.
    this.emit(LOGOUT_STARTED_EVENT)

    // The order of logout steps is important:

    // 1. Delete the cached JWT from localstorage. This is extremely unlikely to fail. It will not
    // be repopulated until the app initiates an Auth0 login. We do not notify the app that the token
    // has changed because that would initiate an app-wide re-render. Since the page will be redirected
    // momentarily, all that re-render will accomplish is confusing the user with a mess of changes that
    // will be immediately discarded.
    this.clearBrowserCache()

    // 2. Tell Auth0 to log the user out if logged in. It's possible the user is logged in by cached
    // JWT, but not logged in according to Auth0. Logging out with Auth0 will reset state and if
    // the cached JWT still exists, the user will be logged back in immediately.
    const localAuthOnly = !context.isAuthenticated
    if (!localAuthOnly) {
      context.logout({ returnTo: LOGOUT_REDIRECT_URL })
    }

    // 3. Tell the app that we're in the process of logging out. Logging out without Auth0 isn't
    // instantaneous and the app implicitly logs users in. So, if the cached JWT is missing, the app
    // will drop the user into an implicit login flow, which is not what we want. Auth0 will redirect
    // the user upon successful logout.
    this.emit(LOGOUT_EVENT, localAuthOnly)
  }

  login = (
    context: Auth0ContextInterface,
    returnTo: maybe<string> = null,
    orgId?: string
  ): void => {
    const options = {
      appState: { targetUrl: window.location.pathname, returnTo, orgId },
    } as RedirectLoginOptions
    if (returnTo) {
      options['prompt'] = 'login' // only force login on invitation workflow
    }

    const asyncLogin = async () => {
      await context.loginWithRedirect(options)
    }

    asyncLogin()
  }

  signup = (context: Auth0ContextInterface, returnTo: nullable<string>, orgId?: string): void => {
    const asyncSignup = async () => {
      await context.loginWithRedirect({
        screen_hint: 'signup',
        prompt: 'login',
        appState: { returnTo: returnTo },
        orgId,
      })
    }

    asyncSignup()
  }

  setToken = (jwt: JWT): void => {
    if (jwt && jwt !== this.getToken()) {
      localStorage.setItem(TOKEN_KEY, jwt)
    }
  }

  getToken = (): JWT => {
    // Retrieves the user token from local storage
    const jwt = localStorage.getItem(TOKEN_KEY)
    if (jwt) {
      if (isTokenExpired(jwt)) {
        this.clearBrowserCache()
        return null
      } else {
        return jwt
      }
    } else {
      return null
    }
  }

  clearBrowserCache = (): void => {
    localStorage.removeItem(TOKEN_KEY)

    // we want activeOrganization, activeProject, and dashboardSettings to persist on logout
    // localStorageHelpers.clearActiveIds()

    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const name = cookie.match(/(\S+)=/)?.[1] || cookie
      this.removeCookie(name)
    }
  }

  removeCookie = (cookie: string): void => {
    document.cookie = cookie + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }

  private subscribe = (event: string, handler: (...args: any[]) => void): Subscription => {
    this.on(event, handler)

    return {
      stop: () => this.removeListener(event, handler),
    }
  }

  subscribeToLogoutStarted = (handler: () => void): Subscription => {
    return this.subscribe(LOGOUT_STARTED_EVENT, handler)
  }

  subscribeToLogout = (handler: (localAuthOnly: boolean) => void): Subscription => {
    return this.subscribe(LOGOUT_EVENT, handler)
  }

  getJwt = (
    auth0Context: Auth0ContextInterface,
    activeOrgId: nullable<string>,
    handleDispatch: (jwt: JWT) => void
  ): void => {
    const { setToken, clearBrowserCache } = this

    auth0Context
      .getAccessTokenSilently({
        ignoreCache: true,
        organizationId: activeOrgId,
      })
      .then((jwt) => {
        if (jwt) {
          setToken(jwt)
          handleDispatch(jwt)
        } else {
          clearBrowserCache()
          handleDispatch(null)
        }
      })
      .catch(() => {
        // getAccessTokenSilently requires access to the Auth0 cookie. https://auth0.github.io/auth0-react/interfaces/auth0_context.auth0contextinterface.html#getaccesstokensilently
        // Staging and Production: Same-site cookies must be enabled by the user. It is uncommon to have all cookies disabled.
        // Local Development: Third party cookies must be enabled. By default, Safari, Chrome Incognito, and iOS browsers disable third party cookies.
        LoggerUtils.captureException(
          'auth0Context.getAccessTokenSilently failed likely due to a problem with browser cookies. Same-site cookies are required in production and staging. Third party cookies are required in local development.'
        )
        alert(
          "An error occurred during authentication, most likely because the Auth0 cookie could not be accessed. Please ensure cookies are enabled in your browser's settings before continuing."
        )
      })
  }
}

export const authService = new AuthService()

import React, { useState } from 'react'
import {
  getCurrentOrganization,
  organizationEntitySelectors,
  useSelectOrganizations,
} from 'data/organization/selectors'

import { ActionButton } from '../ActionButton'
import { List } from 'antd'
import { PlusOutlined } from '@ant-design/icons/lib'
import { RootPaths } from 'router/Router'
import { SelectOrganization } from './SelectOrganization'
import styles from './SwitchOrganizations.module.scss'
import { useAppSelector } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'

export function SwitchOrganizations() {
  const organizations = organizationEntitySelectors.selectAll(useSelectOrganizations())
  const currentOrganization = useAppSelector(getCurrentOrganization)
  const [loading, setLoading] = useState(false)

  const { goToRootRoute } = useHistoryPush()

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Select an organization</h2>

      <p className={styles.description}>
        Select an existing organization to launch or create a new organization.
      </p>

      <div className={styles.button}>
        <ActionButton
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => goToRootRoute(RootPaths.Organization)}
        >
          Create Organization
        </ActionButton>
      </div>

      <List
        pagination={{ pageSize: 6 }}
        bordered={false}
        dataSource={organizations.sort((a) => {
          if (a.id === currentOrganization?.id) {
            return -1
          } else {
            return 0
          }
        })}
        loading={loading}
        renderItem={(org) => {
          return (
            <List.Item>
              <SelectOrganization organization={org} key={org.id} setLoading={setLoading} />
            </List.Item>
          )
        }}
      />
    </div>
  )
}

//
// Copyright (C) 2020 CloudTruth, Inc.
// All Rights Reserved
//

import {
  getCurrentOrganization,
  organizationEntitySelectors,
  useSelectOrganizations,
} from 'data/organization/selectors'

import { OrganizationPaths } from 'router/OrganizationRoutes'
// import { PageLoading } from 'components/PageLoading'
import { RootPaths } from 'router/Router'
import { useAppSelector } from 'data/hooks'
import { useEffect } from 'react'
import { useHistoryPush } from 'router/customHooks'

// This component handles redirect logic only
export function Home(): nullable<JSX.Element> {
  const { goToRootRoute, goToOrgRoute } = useHistoryPush()
  const currentOrg = useAppSelector(getCurrentOrganization)
  const orgList = organizationEntitySelectors.selectAll(useSelectOrganizations())

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!Boolean(process.env.REACT_APP_AUTH_2)) {
      if (!currentOrg) {
        orgList.length === 0
          ? goToRootRoute(RootPaths.Organization)
          : goToRootRoute(RootPaths.Switch)
      } else {
        goToOrgRoute(OrganizationPaths.Dashboard)
      }
    }
  }, [currentOrg, orgList, goToOrgRoute, goToRootRoute])

  return null
}

import React, { useCallback, useState } from 'react'

import { ComponentLoading } from 'components/PageLoading'
import { GetIntegrationExplorer } from 'data/integration/actions'
import { IntegrationExplorerComponent } from 'components/Parameters/UpdateValue/ReferencedParameter/IntegrationExplorer'
import { PageTitle } from 'components/PageTitle'
import { PaginatedIntegrationNodeList } from 'gen/cloudTruthRestApi'
import { Reload } from 'components/Reload'
import { TypedThunk } from 'data/dataUtils'
import styles from './IntegrationExplorerPage.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

export function IntegrationExplorerPage() {
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const getIntegrationExplorer = useCallback(() => {
    setLoading(true)
    dispatch(GetIntegrationExplorer({})).then(
      ({ error }: TypedThunk<PaginatedIntegrationNodeList>) => {
        if (error) {
          errorToast(error.message)
        }
        setLoading(false)
      }
    )
  }, [dispatch, errorToast, setLoading])

  return (
    <div className={styles.container}>
      <PageTitle
        title="Integration Explorer"
        description="Verify the data and accounts you have granted CloudTruth access to. This data can be used
        for external values within parameters."
        buttons={
          <div className={styles.buttonContainer}>
            <Reload onClick={getIntegrationExplorer} loading={loading} />
          </div>
        }
      />
      {loading ? (
        <ComponentLoading />
      ) : (
        <div className={styles.explorerContainer}>
          <IntegrationExplorerComponent environmentValue={null} showExternalSecretValues={true} />
        </div>
      )}
    </div>
  )
}

import { Configuration, FrontendApi, IdentityApi } from '@ory/kratos-client'

const basePath = process.env.REACT_APP_ORY_URL || 'http://localhost:4433'
const adminBasePath = 'http://localhost:4435'

export const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  })
)
export const oryAdmin = new IdentityApi(
  new Configuration({
    basePath: adminBasePath,
    baseOptions: {
      withCredentials: true,
    },
  })
)

export const corsProxy = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, InlineLabelInput, Item, LabeledInputItem, useForm } from '../Forms'
import { FormInstance, Input } from 'antd'
import { GeneratePassword, GeneratedPasswordResponse } from 'data/utility/actions'
import React, { useState } from 'react'

import { ActionButton } from '../ActionButton'
import CopySecretText from '../misc/CopySecretText'
import { Rule } from 'rc-field-form/lib/interface'
import { ShowHideButton } from '../misc/ShowHideButton'
import { TypedThunk } from 'data/dataUtils'
import styles from './PasswordGenerator.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

const validateLength = (_rule: Rule, value: string): Promise<void> => {
  const length = parseInt(value)

  if (value.match(/^\d+$/) && 8 <= length && length <= 4095) {
    return Promise.resolve()
  } else {
    return Promise.reject('Length must be an integer between 8 and 4095')
  }
}

const validateOptions = (
  form: FormInstance,
  generate: () => void,
  error: (m: string) => void
): void => {
  const a = form.getFieldsValue()
  if (!a.require_numbers && !a.require_lowercase && !a.require_uppercase && !a.require_symbols) {
    return error(
      'At least one of the following must be selected to generate a password: lowercase, uppercase, numbers, symbols'
    )
  } else {
    generate()
  }
}

export default function PasswordGenerator() {
  const [showGenerator, setShowGenerator] = useState<boolean>(false)
  const [password, setPassword] = useState<nullable<string>>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const [form] = useForm()

  const handleGenerate = () => {
    setLoading(true)
    const args = form.getFieldsValue()

    dispatch(GeneratePassword(args)).then(
      ({ error, payload }: TypedThunk<GeneratedPasswordResponse>) => {
        error ? errorToast(error.message) : setPassword(payload.value)
        setLoading(false)
      }
    )
  }

  return (
    <>
      <ShowHideButton
        showSettings={showGenerator}
        toggleShowSettings={() => setShowGenerator(!showGenerator)}
        label="Password Generator"
      />
      {showGenerator && (
        <Form
          form={form}
          onFinish={() => validateOptions(form, handleGenerate, errorToast)}
          initialValues={{
            length: '16',
            require_lowercase: true,
            require_numbers: true,
            require_spaces: false,
            require_symbols: false,
            require_uppercase: true,
            require_hardware_generation: false,
          }}
        >
          {password && <CopySecretText label="password" value={password} />}
          <div className={styles.generateButtons}>
            <Item>
              <ActionButton
                disabled={loading}
                type={password ? 'default' : 'primary'}
                onClick={form.submit}
              >
                {`${password ? 'Regenerate' : 'Generate'} Password`}
              </ActionButton>
              {password && (
                <ActionButton
                  type={password ? 'primary' : 'default'}
                  onClick={() => setPassword(null)}
                >
                  Clear
                </ActionButton>
              )}
            </Item>
          </div>
          <LabeledInputItem
            required
            name="length"
            label={{ text: 'Length' }}
            rules={[{ validator: validateLength }]}
          >
            <Input />
          </LabeledInputItem>
          <InlineLabelInput
            required
            checkbox
            name="require_lowercase"
            label={{ text: 'Require Lowercase' }}
          />
          <InlineLabelInput
            required
            checkbox
            name="require_uppercase"
            label={{ text: 'Require Uppercase' }}
          />
          <InlineLabelInput
            required
            checkbox
            name="require_numbers"
            label={{ text: 'Require Numbers' }}
          />
          <InlineLabelInput
            required
            checkbox
            name="require_symbols"
            label={{
              text: 'Require Symbols',
              tooltipText: 'Symbols include !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
            }}
          />
          <InlineLabelInput
            required
            checkbox
            name="require_spaces"
            label={{ text: 'Require Spaces' }}
          />
          <InlineLabelInput
            required
            checkbox
            name="require_hardware_generation"
            label={{
              text: 'Require Hardware Generation',
              tooltipText:
                'Default behavior is to fallback to /dev/urandom if we fail to get a random password from AWS Secrets Manager. If checked, we will not fallback to local password generation using /dev/urandom.',
            }}
          />
        </Form>
      )}
    </>
  )
}

export const exportsForTesting = { validateLength, validateOptions }

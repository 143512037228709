import { Alert, Progress, Spin } from 'antd'
import {
  CreateParameter,
  CreateValue,
  GetParameterNames,
  UpdateParameter,
} from 'data/parameter/actions'
import { CreateProject, GetProjects } from 'data/project/actions'
import { CreateTemplate, GetTemplateNames } from 'data/template/actions'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import { FileUpload, PromptType } from './Gpt'
import { FunctionToolCall, FunctionToolCallDelta } from 'openai/resources/beta/threads/runs/steps'
import { Parameter, Project, Template } from 'gen/cloudTruthRestApi'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createMessage, listMessages } from './utils'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useToast, useViewportHeight } from 'hooks'

import { AssistantStream } from 'openai/lib/AssistantStream'
import FileDragOverlay from './FileDragOverLay'
import { LoadingOutlined } from '@ant-design/icons'
import MessageDisplay from './MessageDisplay'
import OpenAI from 'openai'
import { OpenAIError } from 'openai/error'
import ScrollableFeed from 'react-scrollable-feed'
import { TextContentBlock } from 'openai/resources/beta/threads/messages'
import { Textbox } from './Textbox'
import { Thread } from 'components/GPT/types'
import { UpdateUser } from 'data/user/actions'
import { getCurrentUser } from 'data/user/selectors'
import { makeTitleCase } from 'lib/valueHelpers'
import styles from './Messages.module.scss'

interface Props {
  selectedThread: nullable<Thread>
  assistant: OpenAI.Beta.Assistant
  messages: nullable<OpenAI.Beta.Threads.Messages.Message[]>
  setMessages: React.Dispatch<
    React.SetStateAction<nullable<OpenAI.Beta.Threads.Messages.Message[]>>
  >
  promptType: PromptType
  hasThreads: boolean
  createMessageLoading: boolean
  setCreateMessageLoading: (loading: boolean) => void
  lineCount: number
  setLineCount: (height: number) => void
  openai: OpenAI
  files: FileUpload[]
  setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>
  setCreateTitleLoading: (loading: boolean) => void
  setDeleteThreadDisabled: (thread: nullable<string>) => void
  error: maybe<string>
  setError: (error: maybe<string>) => void
  createThread: () => Promise<{
    id: string
    timestamp: string
    title: string
  }>
}

interface Report {
  status: 'success' | 'error'
  message: string
}

interface CreateParameterLoading {
  total: number
  completed: number
}

interface ParameterToolCall {
  callCurrentProject: boolean
  project?: string
}

interface ParameterToolCall {
  name: string
  secret?: boolean
  description?: string
  defaultValue?: string
  useCreatedProject?: boolean
}

interface TemplateToolCall {
  name: string
  body: string
  description?: string
  useCreatedProject?: boolean
}

interface ProjectToolCall {
  name: string
  description?: string
  depends_on?: string
}

interface CreateParameterToolCall {
  parameters: ParameterToolCall[]
  project?: string
}

interface CreateTemplateToolCall {
  parameters: TemplateToolCall[]
  project?: string
}

interface SubmitObject {
  tool_call_id: string
  output: string
}

const precannedPrompts = [
  'Learn more about CloudTruth',
  'Create a config file for a service ',
  'Parameterize a config file ',
  'List my parameters',
]

export function Messages(props: Props) {
  const {
    selectedThread,
    assistant,
    promptType,
    messages,
    setMessages,
    lineCount,
    setLineCount,
    createMessageLoading,
    setCreateMessageLoading,
    setDeleteThreadDisabled,
    setCreateTitleLoading,
    createThread,
    openai,
    files,
    setFiles,
    error,
    setError,
  } = props

  const [stream, setStream] = useState<nullable<AssistantStream>>(null)
  const [createdProjs, setCreatedProjs] = useState<any[]>([])

  const [textDelta, setTextDelta] = useState<maybe<string>>(null)
  const [textboxText, setTextboxText] = useState('')
  const [selectedPrecannedItem, setSelectedPrecannedItem] = useState<string>('')

  const [getMessageLoading, setGetMessageLoading] = useState(true)
  const [getParametersLoading, setGetParametersLoading] = useState(false)
  const [createParametersLoading, setCreateParametersLoading] =
    useState<nullable<CreateParameterLoading>>(null)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const { errorToast } = useToast()
  const dispatch = useAppDispatch()
  const currentProject = useAppSelector(getCurrentProject)
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const allEnvironments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const allProjects = projectEntitySelectors.selectAll(useSelectProjects())
  const currentUser = useAppSelector(getCurrentUser)
  const defaultEnvironment = allEnvironments.find((env) => env.name === 'default')!
  const { viewportHeight } = useViewportHeight()

  const runRef = useRef<string | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [runCreated, setRunCreated] = useState<string | null>(null)

  const populateTextAreaWithFocus = (text: string) => {
    setTextboxText(text)
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus()
      }
    }, 0)
  }

  const deleteFile = (fileId: string) => {
    setFiles((prev) => prev?.filter((file) => file.id !== fileId))
  }

  const separateFiles = (fileArray: FileUpload[]) => {
    const images: FileUpload[] = []
    const files: FileUpload[] = []

    fileArray?.forEach((file) => {
      if (file.originalFile.type.startsWith('image/')) {
        images.push(file)
      } else {
        files.push(file)
      }
    })

    return { images, files }
  }

  const resetMessageState = () => {
    setTextDelta(null)

    textRef.current = ''
    setFiles([])
    setRunCreated(null)

    runRef.current = null
    toolCallRef.current = ''
    setCreateMessageLoading(false)
  }

  const findProjectByName = useCallback(
    (name: maybe<string>, createdProjects?: any[]) => {
      if (!name) {
        return
      }

      const projectsFromCache = projects.find(
        (project) => project.name.toLowerCase() === name.toLowerCase()
      )
      const projectsFromCreated = createdProjects?.find(
        (project) => project.name.toLowerCase() === name.toLowerCase()
      )

      return projectsFromCache || projectsFromCreated
    },
    [projects]
  )

  const getParameters = useCallback(
    (toolCall: ParameterToolCall) => {
      const projectId = toolCall?.callCurrentProject
        ? currentProject?.id
        : findProjectByName(toolCall?.project)?.id

      if (!projectId) {
        return new Promise((resolve) => {
          resolve('This project does not exist')
        })
      }

      return dispatch(GetParameterNames(projectId)).then(({ payload, error }: CustomThunk) => {
        if (error) {
          return error.message
        }

        return payload.results.map((parameter: Parameter) => ({ name: parameter.name }))
      })
    },
    [currentProject, dispatch, findProjectByName]
  )

  const getProjects = useCallback(() => {
    return dispatch(GetProjects(null)).then(({ payload, error }: CustomThunk) => {
      if (error) {
        return error.message
      }

      return payload.results.map((project: Project) => ({ name: project.name }))
    })
  }, [dispatch])

  const getCurrentProjectFunc = useCallback(() => {
    if (!currentProject) {
      return { report: 'No current project. Most likely the organization has no projects.' }
    } else {
      return { report: currentProject.name }
    }
  }, [currentProject])

  const getTemplates = useCallback(
    (toolCall: ParameterToolCall) => {
      const projectId = toolCall?.callCurrentProject
        ? currentProject?.id
        : findProjectByName(toolCall?.project)?.id

      if (!projectId) {
        return new Promise((resolve) => {
          resolve('This project does not exist')
        })
      }

      return dispatch(GetTemplateNames(projectId)).then(({ payload, error }: CustomThunk) => {
        if (error) {
          return error.message
        }

        return payload.results.map((template: Template) => ({ name: template.name }))
      })
    },
    [currentProject, dispatch, findProjectByName]
  )

  const createParameters = useCallback(
    async (toolCall: CreateParameterToolCall, projects: any[]) => {
      const projectId = toolCall.project
        ? findProjectByName(toolCall.project, projects)?.id
        : currentProject?.id
      const parameterPromises = []
      const dontUpdateCache = () => {
        if (currentProject && toolCall.project) {
          return currentProject.name !== toolCall.project
        } else {
          return false
        }
      }

      if (!projectId) {
        return { report: "There's no project in the organization" }
      }

      const { payload }: CustomThunk = await dispatch(GetParameterNames(projectId))

      const parameters = toolCall.parameters.filter((parameter) => {
        // Return true only if no parameter with the same name exists in payload.results
        return !payload.results.find((param: Parameter) => param.name === parameter.name)
      })
      if (toolCall.parameters.length > 0 && parameters.length === 0) {
        return { report: 'Parameters already exist' }
      } else if (parameters.length === 0) {
        return { report: 'No paraemters found' }
      }

      let report: Record<string, Report> = {}

      const values = () => {
        const obj: any = {}
        allEnvironments.forEach((env) => {
          obj[env.url] = null
        })

        return obj
      }

      const setDone = new Promise((resolve) => {
        setCreateParametersLoading(null)
        resolve({})
      })

      const setTotal = new Promise((resolve) => {
        setCreateParametersLoading({ total: parameters.length, completed: 0 })
        resolve({})
      })

      await setTotal

      for (const param of parameters) {
        parameterPromises.push(
          dispatch(
            CreateParameter({
              project: projectId,
              isOverride: dontUpdateCache(),
              values: values(),
              name: param.name,
              secret: !!param?.secret,
              description: param?.description ? param.description : '',
            })
          ).then(async ({ payload, error }: TypedThunk<Parameter>) => {
            if (error) {
              report = { ...report, [param.name]: { status: 'error', message: error.message } }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: parameters.length, completed: 1 }
              )
            } else {
              if (param.defaultValue) {
                dispatch(
                  CreateValue({
                    paramId: payload.id,
                    envUrl: defaultEnvironment.url,
                    environments: allEnvironments,
                    environment: defaultEnvironment.id,
                    internal_value: param.defaultValue,
                  })
                )
              }

              report = {
                ...report,
                [param.name]: { status: 'success', message: `${param.name} successfully created ` },
              }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: parameters.length, completed: 1 }
              )
            }
          })
        )
      }

      await Promise.all(parameterPromises).then(() => setCreateParametersLoading(null))

      await setDone

      return { report }
    },

    [currentProject, dispatch, allEnvironments, defaultEnvironment, findProjectByName]
  )

  const createProject = useCallback(
    async (toolCall: ProjectToolCall) => {
      const hasProject = allProjects.find((project) => project.name === toolCall.name)
      const dependsOnProject = allProjects.find((project) => project.name === toolCall.depends_on)

      if (hasProject) {
        return { report: 'Project already exists' }
      }

      return await dispatch(
        CreateProject({
          name: toolCall.name,
          description: toolCall.description,
          depends_on: dependsOnProject ? dependsOnProject.url : undefined,
        })
      ).then(({ error, payload }: TypedThunk<Project>) => {
        if (error) {
          return { report: { status: 'error', message: error.message } }
        } else {
          return {
            report: {
              status: 'success',
              message: `${toolCall.name} successfully created`,
              name: toolCall.name,
            },
            createdProject: payload,
          }
        }
      })
    },

    [dispatch, allProjects]
  )

  const createTemplates = useCallback(
    async (toolCall: CreateTemplateToolCall, projects: any[]) => {
      const projectId = toolCall.project
        ? findProjectByName(toolCall.project, projects)?.id
        : currentProject?.id

      const templatePromises = []

      if (!projectId) {
        return { report: "There's no project in the organization" }
      }

      const { payload }: CustomThunk = await dispatch(GetTemplateNames(projectId))

      const templates = toolCall.parameters.filter((template) => {
        // Return true only if no parameter with the same name exists in payload.results
        return !payload.results.find((temp: Template) => temp.name === template.name)
      })
      if (toolCall.parameters.length > 0 && templates.length === 0) {
        return { report: 'Templates already exist' }
      } else if (templates.length === 0) {
        return { report: 'No templates found' }
      }

      let report: Record<string, Report> = {}

      const setDone = new Promise((resolve) => {
        setCreateParametersLoading(null)
        resolve({})
      })

      const setTotal = new Promise((resolve) => {
        setCreateParametersLoading({ total: templates.length, completed: 0 })
        resolve({})
      })

      await setTotal

      for (const temp of templates) {
        templatePromises.push(
          dispatch(
            CreateTemplate({
              project: projectId,

              name: temp.name,
              body: temp.body,
              description: temp?.description ? temp.description : '',
            })
          ).then(async ({ error }: TypedThunk<Parameter>) => {
            if (error) {
              report = { ...report, [temp.name]: { status: 'error', message: error.message } }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: templates.length, completed: 1 }
              )
            } else {
              report = {
                ...report,
                [temp.name]: { status: 'success', message: `${temp.name} successfully created ` },
              }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: templates.length, completed: 1 }
              )
            }
          })
        )
      }

      await Promise.all(templatePromises).then(() => setCreateParametersLoading(null))

      await setDone

      return { report }
    },

    [currentProject, dispatch, findProjectByName]
  )

  const updateParameterDescriptions = useCallback(
    async (toolCall: CreateParameterToolCall) => {
      const projectId = currentProject?.id
      const parameterPromises = []

      if (!projectId) {
        return { report: "There's no project in the organization" }
      }

      const { payload }: CustomThunk = await dispatch(GetParameterNames(projectId))

      const parameters = payload.results.filter((parameter: Parameter) => {
        return toolCall.parameters.find((param) => param.name === parameter.name)
      })

      if (parameters.length === 0) {
        return { report: 'No paraemters found' }
      }

      let report: Record<string, Report> = {}

      const setDone = new Promise((resolve) => {
        setCreateParametersLoading(null)
        resolve({})
      })

      const setTotal = new Promise((resolve) => {
        setCreateParametersLoading({ total: parameters.length, completed: 0 })
        resolve({})
      })

      await setTotal

      for (const param of parameters) {
        parameterPromises.push(
          dispatch(
            UpdateParameter({
              ...param,
              description: toolCall.parameters.find((p) => p.name === param.name)?.description,
            })
          ).then(({ error }: TypedThunk<Parameter>) => {
            if (error) {
              report = { ...report, [param.name]: { status: 'error', message: error.message } }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: parameters.length, completed: 1 }
              )
            } else {
              report = {
                ...report,
                [param.name]: { status: 'success', message: `${param.name} successfully updated ` },
              }
              setCreateParametersLoading((prev) =>
                prev
                  ? { ...prev, completed: prev.completed + 1 }
                  : { total: parameters.length, completed: 1 }
              )
            }
          })
        )
      }

      await Promise.all(parameterPromises).then(() => setCreateParametersLoading(null))

      await setDone

      return { report }
    },

    [currentProject, dispatch]
  )

  const toolCallHash: Record<any, any> = useMemo(
    () => ({
      get_parameters: (toolCall: ParameterToolCall) => getParameters(toolCall),
      get_templates: (toolCall: ParameterToolCall) => getTemplates(toolCall),
      create_parameters: (toolCall: CreateParameterToolCall, createdParameters: any) =>
        createParameters(toolCall, createdParameters),
      create_templates: (toolCall: CreateTemplateToolCall, createdParameters: any) =>
        createTemplates(toolCall, createdParameters),
      update_parameter_descriptions: (toolCall: CreateParameterToolCall) =>
        updateParameterDescriptions(toolCall),
      create_project: (toolCall: ProjectToolCall) => createProject(toolCall),
      get_projects: () => getProjects(),
      get_current_project: () => getCurrentProjectFunc(),
    }),
    [
      getParameters,
      createParameters,
      updateParameterDescriptions,
      createTemplates,
      getTemplates,
      createProject,
      getProjects,
      getCurrentProjectFunc,
    ]
  )

  const containerRef = useRef<HTMLDivElement>(null)

  const createThreadTitle = useCallback(async () => {
    setDeleteThreadDisabled(selectedThread!.id)

    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: 'system',
          content:
            'You are a title creating assistant. Always make a short title from the messages array, use max 5 words, and make it a string with no quotes. The part of the message that includes analyzing files are auto generated, find a better title than analyzing files or messages.',
        },
        {
          role: 'user',
          content: `Create a title to a thread based off the following messages: ${JSON.stringify(
            messages
          )}`,
        },
      ],
      model: 'gpt-4o',
    })

    const title = completion.choices[0].message.content

    dispatch(
      UpdateUser({
        id: currentUser!.id,
        chatgpt_threads: {
          ...currentUser!.chatgpt_threads,
          [selectedThread!.id]: {
            ...currentUser!.chatgpt_threads![selectedThread!.id],
            title,
          },
        },
      })
    ).then(() => {
      setDeleteThreadDisabled(null)
    })
  }, [messages, selectedThread, openai, currentUser, dispatch, setDeleteThreadDisabled])

  const textRef = useRef('')
  const toolCallRef = useRef('')

  const memoizedMessages = React.useMemo(() => {
    if (!messages) {
      return []
    } else if (!textDelta) {
      return messages
    } else {
      const newMessages = messages!
      const prevContent = newMessages[newMessages.length - 1].content[0] as TextContentBlock

      newMessages[newMessages.length - 1] = {
        ...newMessages[newMessages.length - 1],
        content: [
          {
            ...prevContent,
            text: {
              ...prevContent?.text,
              value: textDelta,
            },
          },
        ],
      }

      return messages
    }
  }, [messages, textDelta])

  const onPrecannedSubmit = async (messageText: string) => {
    setCreateMessageLoading(true)
    const message = await createMessage(
      openai,
      selectedThread!.id,
      messageText,
      separateFiles(files)
    )

    setMessages((prev) => (prev ? [...prev, message] : [message]))

    const stream = await openai.beta.threads.runs.stream(selectedThread!.id, {
      assistant_id: assistant.id,
      instructions: files
        ? 'file search the following files: ' + `[${files.map((file) => file.filename).join(', ')}]`
        : '',
    })

    setStream(stream)
  }

  const onSubmit = useCallback(
    async (text: string) => {
      setCreateMessageLoading(true)

      const thread = selectedThread || (await createThread())

      try {
        const message = await createMessage(openai, thread.id, text, separateFiles(files))

        setMessages((prev) => (prev ? [...prev, message] : [message]))

        const stream = await openai.beta.threads.runs.stream(thread.id, {
          assistant_id: assistant.id,
          instructions: files
            ? 'file search the following files that are attachments to the message when you start the run steps. File searching files attached to messages should always come first. If there are images and files you should scan them both: ' +
              `[${files.map((file) => file.filename).join(', ')}]`
            : '',
        })

        setStream(stream)
      } catch (error) {
        const err = error as any
        if (err.error.message) {
          setError('Error creating message' + ' ' + err.error.message)
        } else {
          setError('error creating message')
        }

        resetMessageState()
        return
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedThread,
      assistant,
      setCreateMessageLoading,
      openai,
      currentUser?.chatgpt_threads,
      createThread,
      setMessages,
    ]
  )

  useEffect(() => {
    const createdProjects: any[] = [...createdProjs]

    if (stream) {
      let run: any
      stream
        .on('connect', () => setCreateMessageLoading(true))
        .on('messageCreated', (messageDelta) => {
          setMessages((prev) => (prev ? [...prev, messageDelta] : [messageDelta]))
        })

        .on('textDelta', (textDelta) => {
          textRef.current += textDelta.value
          setTextDelta(textRef.current)
        })

        .on('runStepCreated', (runStep) => {
          runRef.current = runStep.run_id
          run = runStep.run_id
          setRunCreated(run)
        })
        .on('toolCallCreated', (toolCall) => {
          const call = toolCall as FunctionToolCall

          if (call?.function?.name === 'get_parameters') {
            setGetParametersLoading(true)
          }
        })
        .on('event', (event) => {
          if (event.event === 'thread.run.requires_action') {
            const toolCalls = event.data.required_action?.submit_tool_outputs?.tool_calls

            if (toolCalls && toolCalls.length > 0) {
              setDisabledSubmit(true)
              const outputs: SubmitObject[] = []
              setRunCreated(run)

              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (res: any) => {
                for (const toolCall of toolCalls) {
                  const call = toolCall as FunctionToolCall

                  if (toolCallHash[call?.function?.name] && toolCall.function) {
                    const projects = createdProjects

                    let toolCallParameters

                    try {
                      toolCallParameters = JSON.parse(call.function.arguments)
                      // Proceed with using toolCallParameters
                    } catch (error) {
                      setError('Error parsing JSON:' + ' ' + error)
                      toolCallParameters = {}
                      resetMessageState()
                    }

                    const response = await toolCallHash[call?.function?.name](
                      toolCallParameters,
                      projects
                    )

                    if (response && response.createdProject) {
                      createdProjects.push(response.createdProject)
                      setCreatedProjs((prev) => [...prev, response.createdProject])
                    }

                    const submitObject = {
                      tool_call_id: call.id,
                      output: JSON.stringify(response),
                    }

                    outputs.push(submitObject)
                  }
                }

                res({})
              }).then(() => {
                if (selectedThread && run) {
                  const getStream = async () => {
                    const stream = await openai.beta.threads.runs.submitToolOutputsStream(
                      selectedThread?.id,
                      run,
                      { tool_outputs: outputs }
                    )
                    toolCallRef.current = ''
                    setGetParametersLoading(false)
                    setStream(stream)
                    setDisabledSubmit(false)
                  }
                  getStream()
                }
              })
            }
          }
        })

        .on('end', async () => {
          resetMessageState()

          if (!currentUser?.chatgpt_threads![selectedThread!.id].title) {
            setCreateTitleLoading(true)
            await createThreadTitle()
            setCreateTitleLoading(false)
          }
        })

        .on('toolCallDelta', (toolDelta) => {
          const delta = toolDelta as FunctionToolCallDelta

          toolCallRef.current += delta.function?.arguments
        })
        .on('error', (error) => {
          setError(error.message)
          resetMessageState()

          if (!toolCallRef.current) {
            runRef.current = null
            toolCallRef.current = ''
            setCreateMessageLoading(false)
          }
        })
        .on('error', (error: OpenAIError) => {
          runRef.current = null
          toolCallRef.current = ''
          setCreateMessageLoading(false)

          errorToast(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream])

  // Scroll to bottom on new messages
  useEffect(() => {
    const container = containerRef.current

    if (container) {
      container.scrollTop = container.scrollHeight
    }
  }, [textDelta, messages]) // This effect triggers whenever the 'message' content updates

  useEffect(() => {
    if (selectedThread) {
      setGetMessageLoading(true)

      listMessages(openai, selectedThread.id).then((response) => {
        setMessages(response?.data.reverse())
        setGetMessageLoading(false)
      })
    } else {
      setMessages(null)
      setGetMessageLoading(false)
    }
  }, [selectedThread, openai, setMessages])

  const cancelRun = () => {
    if (runRef.current && selectedThread) {
      openai.beta.threads.runs.cancel(selectedThread?.id, runRef.current)

      runRef.current = null
      setCreateMessageLoading(false)
      setGetParametersLoading(false)
      setCreateParametersLoading(null)
    }
  }

  function calculateProgress(completed: number, total: number) {
    const maxBars = total > 25 ? 25 : total
    // Calculate the actual fraction of promises completed
    const fractionCompleted = completed / total
    // Scale it to the maximum number of bars
    const scaledProgress = fractionCompleted * maxBars
    // Cap the scaled progress at maxBars and convert to percentage
    const cappedProgress = (Math.min(scaledProgress, maxBars) / maxBars) * 100
    // round to the nearest 10, so the user isn't looking at random percentages
    return Math.round(cappedProgress / 10) * 10
  }

  const calcHeight = useMemo(() => {
    if (promptType === PromptType.Precanned) {
      return selectedPrecannedItem ? '60%' : '70%'
    }
    const getLineCount = () => {
      if (lineCount <= 1) {
        return 1.9
      } else {
        return lineCount <= 10 ? lineCount : 10
      }
    }

    const lCount = getLineCount()

    const messageAreaHeight = viewportHeight - 170
    const textboxHeight = 19.4 * lCount + 24
    const fileHieght = 70
    const footerHeight = 34

    const calc = messageAreaHeight - textboxHeight - footerHeight

    return files ? `${calc - fileHieght}px` : `${calc}px`
  }, [promptType, selectedPrecannedItem, lineCount, files, viewportHeight])

  return (
    <div className={styles.container} style={{ height: `${viewportHeight - 170}px` }}>
      {getMessageLoading ? (
        <div className={styles.spinContainer}>
          <Spin />
        </div>
      ) : (
        <div
          className={
            selectedThread && memoizedMessages && memoizedMessages.length > 0
              ? styles.messageContainer
              : styles.precannedMessageContainer
          }
          style={{
            height: calcHeight,
          }}
        >
          {(selectedThread && memoizedMessages && memoizedMessages.length > 0) ||
          createMessageLoading ? (
            <ScrollableFeed className={styles.scrollableContainer}>
              {memoizedMessages?.map((message, index) => {
                const content = message.content[0] as TextContentBlock
                return (
                  <div key={index} className={styles.message}>
                    <div className={styles.role}>
                      {message.role === 'assistant'
                        ? 'CloudTruth Assistant'
                        : makeTitleCase(message.role)}
                    </div>
                    <div className={styles.text}>
                      <MessageDisplay message={content?.text?.value} />
                    </div>
                  </div>
                )
              })}
              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: '10px', marginTop: '10px', width: '60%' }}
                />
              )}

              {getParametersLoading && (
                <div className={styles.toolLoading}>
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16, marginRight: '8px' }} spin />
                    }
                  />

                  <span className={styles.toolLoadingText}>Getting parameters</span>
                </div>
              )}

              {createParametersLoading && (
                <div className={styles.toolLoading} style={{ marginBottom: '8px' }}>
                  <Progress
                    percent={calculateProgress(
                      createParametersLoading.completed,
                      createParametersLoading.total || 25
                    )}
                    steps={createParametersLoading.total <= 25 ? createParametersLoading.total : 25}
                  />
                </div>
              )}
            </ScrollableFeed>
          ) : (
            // precan
            <div className={styles.precannedContainer}>
              <img
                data-testid={`black-logo`}
                src={require('styles/images/black-logo.png')}
                className={styles.blackLogo}
              />
              <span className={styles.emptyDescriptionText}>
                The CloudTruth Copilot can help you with:
              </span>
              <div className={styles.precannedBoxContainer}>
                {precannedPrompts.map((text, index) => {
                  return (
                    <div
                      className={styles.precannedBox}
                      onClick={() => populateTextAreaWithFocus(text)}
                      key={index}
                    >
                      <span className={styles.precannedText}>{text}</span>
                    </div>
                  )
                })}
              </div>
              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: '10px', marginTop: '10px', width: '60%' }}
                />
              )}
            </div>
          )}
        </div>
      )}

      <FileDragOverlay />

      <Textbox
        textref={textareaRef}
        selectedPrecannedItem={selectedPrecannedItem}
        setSelectedPrecannedItem={setSelectedPrecannedItem}
        createLoading={createMessageLoading}
        getLoading={getMessageLoading}
        lineCount={lineCount}
        setLineCount={setLineCount}
        onSubmit={onSubmit}
        precannedSubmit={onPrecannedSubmit}
        cancelRun={cancelRun}
        run={!!runRef.current || !!runCreated}
        promptType={promptType}
        getParameterLoading={getParametersLoading}
        openai={openai}
        files={files}
        setFiles={setFiles}
        deleteFile={deleteFile}
        setError={setError}
        error={error}
        text={textboxText}
        setText={setTextboxText}
        disable={disabledSubmit}
      />

      {/* <ActionButton onClick={createVectorStore}>Vector Store</ActionButton> */}
    </div>
  )
}

import React, { PropsWithChildren } from 'react'

import { Modal as AntModal } from 'antd'
// import actionButtonStyles from 'components/ActionButton.module.scss'
import styles from './Modal.module.scss'

interface Props {
  afterClose?: () => void
  footer?: nullable<JSX.Element>
  onCancel?: () => void
  onOk?: () => void
  okText?: string
  cancelText?: string
  pending?: boolean
  title?: string
  visible: boolean
  width?: string | number
  minHeight?: string | number
  destroyOnClose?: boolean
}

export const Modal = (props: PropsWithChildren<Props>) => {
  const {
    visible,
    children,
    title,
    afterClose,
    onCancel,
    onOk,
    pending,
    footer,
    width,
    okText,
    cancelText,
    minHeight,
    ...modalProps
  } = props

  return (
    <AntModal
      width={width}
      style={{ minHeight: minHeight }}
      maskClosable={false}
      open={visible}
      title={title?.toUpperCase()}
      className={styles.modal}
      closable={false}
      afterClose={afterClose}
      onCancel={onCancel}
      onOk={onOk}
      destroyOnClose
      cancelText={cancelText || 'Cancel'}
      okButtonProps={{
        htmlType: 'submit',
        disabled: pending,
        datatype: 'cypress-modal-button',
      }}
      cancelButtonProps={{
        disabled: pending,
      }}
      okText={okText ? okText : 'Save'}
      footer={footer}
      {...modalProps}
    >
      {children}
    </AntModal>
  )
}

export const ModalWithoutFooter = (props: PropsWithChildren<Props>) => {
  return Modal({ footer: null, ...props })
}

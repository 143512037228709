import { IntegrationNode, NodeTypeEnum } from 'gen/cloudTruthRestApi'

import { FileTree } from './FileTree'
import { IntegrationTree } from '../IntegrationExplorer'
import React from 'react'
import { RootServiceTree } from './RootServiceTree'
import { ServiceTree } from './ServiceTree'
import styles from './Location.module.scss'

interface Props {
  integrationTree: nullable<IntegrationTree>
  externalError?: nullable<string>
  integrationFile: nullable<IntegrationNode>
  onSelect: (fqn: nullable<string>) => void
  hasSelectedFile: () => void
  pending: boolean
  setPending: (pending: boolean) => void
  selectedFqn: nullable<string>
  showExternalSecretValues: boolean
}

export function Location(props: Props) {
  const {
    onSelect,
    integrationTree,
    integrationFile,
    pending,
    setPending,
    selectedFqn,
    hasSelectedFile,
    showExternalSecretValues,
    externalError,
  } = props

  const currentTree = () => {
    if (!integrationTree) {
      return (
        <RootServiceTree
          showExternalSecretValues={showExternalSecretValues}
          onSelect={onSelect}
          pending={pending}
          selectedFqn={selectedFqn}
        />
      )
    }

    if (
      integrationTree.entries.find(
        (node: IntegrationNode) => node.node_type === NodeTypeEnum.Directory
      )
    ) {
      return (
        <FileTree
          externalError={externalError}
          showExternalSecretValues={showExternalSecretValues}
          integrationTree={integrationTree}
          integrationFile={integrationFile}
          onSelect={onSelect}
          hasSelectedFile={hasSelectedFile}
          setPending={setPending}
          selectedFqn={selectedFqn}
        />
      )
    }

    return (
      <ServiceTree
        showExternalSecretValues={showExternalSecretValues}
        integrationTree={integrationTree}
        onSelect={onSelect}
        pending={pending}
        selectedFqn={selectedFqn}
      />
    )
  }

  const PreviousLink = () => {
    if (integrationTree) {
      const onClick = () => onSelect(null)
      return (
        <div className={styles.prevLink} onClick={onClick}>
          {'<'} Top
        </div>
      )
    }
    return null
  }

  return (
    <div className={styles.locationContainer}>
      <div className={styles.heading}>
        <div className={styles.locationLabel}>LOCATION</div>
        <PreviousLink />
      </div>
      <div className={styles.location}>{currentTree()}</div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { ActionEnum } from 'data/actions/utils'
import { ImportContainer } from './Import/ImportContainer'
import { ImportDetailsPage } from './Import/ImportDetails/ImportDetailsPage'
import { PushContainer } from './Push/PushContainer'
import { PushDetailsPage } from './Push/PushDetails/PushDetailsPage'
import { useHistoryPush } from 'router/customHooks'

export enum ActionViews {
  Push = '/push',
  Import = '/import',
  AkvImport = '/import#akv',
  AkvPush = '/push#akv',
}

export function ActionContainer() {
  const { goToActionsRoute } = useHistoryPush()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!pathname.match(`(?:${Object.values(ActionViews).join('|')})`)) {
      goToActionsRoute(ActionViews.Push)
    }
  }, [goToActionsRoute, pathname])

  return (
    <>
      <Routes>
        <Route path={ActionViews.Push} element={<PushContainer />} />
        <Route
          path={`${ActionViews.Push}/aws/:integrationId/:actionId`}
          element={<PushDetailsPage actionType={ActionEnum.AwsPush} />}
        />
        <Route
          path={`${ActionViews.Push}/akv/:integrationId/:actionId`}
          element={<PushDetailsPage actionType={ActionEnum.AzureKeyVaultPush} />}
        />
        <Route path={ActionViews.Import} element={<ImportContainer />} />
        <Route
          path={`${ActionViews.Import}/aws/:integrationId/:actionId`}
          element={<ImportDetailsPage actionType={ActionEnum.AwsPull} />}
        />
        <Route
          path={`${ActionViews.Import}/akv/:integrationId/:actionId`}
          element={<ImportDetailsPage actionType={ActionEnum.AzureKeyVaultPull} />}
        />
        <Route
          path={`${ActionViews.Import}/github/:integrationId/:actionId`}
          element={<ImportDetailsPage actionType={ActionEnum.GithubPull} />}
        />
      </Routes>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Button, Input, Switch, Typography } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { DeleteProject, GetProjects, UpdateProject } from 'data/project/actions'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SortableTree, { TreeItem, toggleExpandedForAll } from 'react-sortable-tree'
import { getLocalSession, setLocalSession } from 'lib/sessionPersistance'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { CopyProject } from './CopyProject'
import { DeleteConfirmModal } from 'components/Modals'
import { EditProject } from './EditProject'
import { LabelText } from 'components/LabelText'
import { PageLoading } from 'components/PageLoading'
import { PageTitle } from 'components/PageTitle'
import { Project } from 'gen/cloudTruthRestApi'
import { ProjectPreviewDetails } from './ProjectPreview/ProjectPreviewDetails'
import { Reload } from 'components/Reload'
import { getCurrentOrganization } from 'data/organization/selectors'

import { getPolicy } from 'data/membership/selectors'

import styles from './Projects.module.scss'
import { useForm } from 'components/Forms'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'hooks'

// import-sort-ignore
import 'react-sortable-tree/style.css'

interface DetailsNode {
  visible: boolean
  project: nullable<Project>
  loading: boolean
  node: nullable<Node>
}

interface DeleteNode {
  visible: boolean
  nodeName: nullable<string>
  loading: boolean
  path: nullable<(string | number)[]>
}
interface UpdateNode {
  loading: boolean
  path: nullable<(string | number)[]>
  project: nullable<Project>
  node: nullable<Node>
  visible: boolean
}

interface CopyNode {
  loading: boolean
  path: nullable<(string | number)[]>
  project: nullable<Project>
  node: nullable<Node>
  visible: boolean
}
interface ConfirmNode {
  loading: boolean
  node: nullable<Node>
  nextNode: nullable<Node>
  prevPath: (string | number)[]
  visible: boolean
}

interface MoveNodeArgs {
  node: nullable<Node>
  treeIndex: nullable<number>
  path: nullable<(string | number)[]>
  nextParentNode: nullable<Node>
}

interface Node extends TreeItem {
  projectName?: string
}

const initialDetailsNode = {
  loading: false,
  project: null,
  node: null,
  visible: false,
}

const initialDeleteNode = { visible: false, nodeName: null, loading: false, path: null }

const initialUpdateNode = {
  loading: false,
  path: null,
  project: null,
  node: null,
  visible: false,
}

const initialMoveNodeArgs = {
  node: null,
  treeIndex: null,
  path: null,
  nextParentNode: null,
}

const initialConfirmNode: ConfirmNode = {
  loading: false,
  node: null,
  nextNode: null,
  visible: false,
  prevPath: [],
}

export function Projects() {
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const [detailsNode, setDetailsNode] = useState<DetailsNode>(initialDetailsNode)
  const [deleteNode, setDeleteNode] = useState<DeleteNode>(initialDeleteNode)
  const [updateNode, setUpdateNode] = useState<UpdateNode>(initialUpdateNode)
  const [copyNode, setCopyNode] = useState<CopyNode>(initialUpdateNode)
  const [confirmNode, setConfirmNode] = useState<ConfirmNode>(initialConfirmNode)
  const [liveSearchValue, setLiveSearchValue] = useState('')
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const [search, setSearch] = useState('')
  const [form] = useForm()

  const [searchFocusIndex, setSearchFocusIndex] = useState(0)
  const [searchFoundCount, setSearchFoundCount] = useState(0)

  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'projects' })!
  const expandedTree = localSession?.expandedTree

  // checks if every node in tree is expanded
  const isExpanded = (tree: Node[]) => {
    function flattenTree(nodes: any) {
      let flatArray: any = []

      for (const node of nodes) {
        if (node.children.length > 0) {
          flatArray.push(node)
        }

        if (node.children.length > 0) {
          flatArray = flatArray.concat(flattenTree(node.children))
        }
      }

      return flatArray
    }

    const childNodes = flattenTree(tree)

    if (childNodes.length < 1) {
      return false
    } else {
      return childNodes.every((node: any) => node.expanded)
    }
  }

  const navigate = useNavigate()

  const childProjectFromUrl = useCallback(
    (url: string) => {
      const id = idFromUrl(url)

      return projectEntities[id]
    },
    [projectEntities]
  )

  const assembleChildren = useCallback(
    (parentUrl: string): Node[] => {
      return projects
        .filter((p) => p.depends_on === parentUrl)
        .map((p) => ({
          title: (
            <span data-cy={`tree-item-${childProjectFromUrl(p.url)!.id}`}>
              {childProjectFromUrl(p.url)!.name}
            </span>
          ),
          key: childProjectFromUrl(p.url)!.id,
          subtitle: (
            <Typography.Text ellipsis className={styles.subtitle}>
              {childProjectFromUrl(p.url)!.description}
            </Typography.Text>
          ),
          expanded: expandedTree ? expandedTree[childProjectFromUrl(p.url)!.name] : false,
          children: assembleChildren(p.url),
          projectName: childProjectFromUrl(p.url)!.name,
        }))
    },
    [childProjectFromUrl, projects, expandedTree]
  )

  const projectTree = useCallback(
    (projects: Project[]) => {
      return projects
        .filter((project) => project.depends_on === null)
        .map((project) => {
          return {
            title: <span data-cy={`tree-item-${project.id}`}>{project.name}</span>,
            key: project.id,
            subtitle: (
              <Typography.Text className={styles.subtitle} ellipsis>
                {project.description}
              </Typography.Text>
            ),
            expanded: expandedTree ? expandedTree[project.name] : false,
            children: assembleChildren(project.url),
            projectName: project.name,
          }
        })
    }, // eslint-disable-next-line
    [assembleChildren, projects]
  )

  const [tree, setTree] = useState<Node[]>(projectTree(projects))
  const [prevTree, setPrevTree] = useState<Node[]>(projectTree(projects))
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { successToast, errorToast } = useToast()

  const expanded = useMemo(() => {
    return isExpanded(tree)
  }, [tree])

  const getProjects = useCallback(() => {
    setLoading(true)
    dispatch(GetProjects(null)).then(({ error, payload }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setTree(prevTree)
        setLoading(false)
      }

      setTree(projectTree(payload.results))
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading, prevTree, projectTree])

  // This useEffect manages create, update, and deleting from the project tree that depends any changes to projects from state
  useEffect(() => {
    setTree((prev) => {
      // all of this logic below is to maintain the order of the tree data on create, update, and delete.
      // Otherwise it will change to the order of the projects array in state.
      const orderHash: any = {}
      const finalHash: any = {}
      const orderHashFn = (projects: maybe<Node[]>) => {
        if (projects) {
          projects.forEach((project: any) => {
            orderHash[project.key as string] = project.projectName
            orderHashFn(project.children as Node[])
          })
        }

        Object.keys(orderHash).forEach((projectId, index) => (finalHash[projectId] = index))
        return finalHash
      }
      const orderedProjects = orderHashFn(prev)
      const sortedProjects = projects.sort((a, b) => {
        // if it's not in the ordered list, that means a project just got create and we will sort to the beginning for the array.
        if (!orderedProjects[a.id]) {
          return -1
        }
        return orderedProjects[a.id] - orderedProjects[b.id]
      })

      return projectTree(sortedProjects)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects])

  const EmptyProjectList = () => {
    return (
      <div className={styles.emptyContainer}>
        <h4 className={styles.emptyText}>You do not have any projects stored in CloudTruth.</h4>
        <p className={styles.emptyText}>Add a project to store in CloudTruth.</p>
      </div>
    )
  }

  const handleConfirmOpen = (
    nextParentNode: nullable<Node>,
    node: Node,
    prevPath: (string | number)[]
  ) => {
    if (node && !nextParentNode) {
      const childProject = projects.find((project) => node.projectName === project.name)
      // guard if the project is already at the top level (node is getting rearranged)
      if (childProject?.depends_on !== null) {
        setConfirmNode({ ...confirmNode, visible: true, nextNode: nextParentNode, node, prevPath })
      }
    }

    if (node && nextParentNode) {
      const childProject = projects.find((project) => node.projectName === project.name)
      const parentProject = projects.find(
        (project) => project.name === nextParentNode.projectName
      )?.url
      // guard if the projects parent isn't changing (node is getting rearranged)
      if (childProject?.depends_on !== parentProject) {
        setConfirmNode({ ...confirmNode, visible: true, nextNode: nextParentNode, node, prevPath })
      }
    }
  }

  const confirmSubject = (confirmState: ConfirmNode) => {
    if (!confirmState.nextNode || confirmState.prevPath?.length > 1 || false) {
      return 'Are you sure you want to remove this project dependency?'
    }

    return 'Adding a project dependency will inherit parameters and secrets from the parent project'
  }

  const confirmBody = (confirmState: ConfirmNode) => {
    if (!confirmState.nextNode || confirmState.prevPath?.length > 1 || false) {
      return 'Removing a project dependency will remove all inherited parameters and secrets from this project'
    }

    return null
  }

  const handleChange = (nextParentNode: nullable<Node>, node: Node) => {
    // if there is no parent node the project needs to be put back to the top level
    if (node && !nextParentNode) {
      const childProject = projects.find((project) => node.projectName === project.name)
      // guard if the project is already at the top level (node is getting rearranged)
      if (childProject?.depends_on !== null) {
        dispatch(UpdateProject({ ...childProject, depends_on: null })).then(
          ({ error }: CustomThunk) => {
            if (error) {
              errorToast(error.message)
              setTree(prevTree)
              return
            }
            successToast('Project has been updated')
          }
        )
      }
    }
    // add the project under the parent
    if (node && nextParentNode) {
      const childProject = projects.find((project) => node.projectName === project.name)
      const parentProject = projects.find(
        (project) => project.name === nextParentNode.projectName
      )?.url
      // guard if the projects parent isn't changing (node is getting rearranged)
      if (childProject?.depends_on !== parentProject) {
        // this update is handling the depends on only
        dispatch(UpdateProject({ ...childProject, depends_on: parentProject })).then(
          ({ error }: CustomThunk) => {
            if (error) {
              errorToast(error.message)
              // if there's an error it means that the project has conflicting dependencies, and the node needs to get moved back
              setTree(prevTree)
              return
            }
            successToast('Project has been updated')
          }
        )
      }
    }
    setConfirmNode(initialConfirmNode)
  }

  const handleDelete = (name: nullable<string>) => {
    setDeleteNode({
      ...deleteNode,
      loading: true,
    })

    const project = projects.find((project) => name === project.name)

    dispatch(DeleteProject(project!.id))
      .then(({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          return
        }
        successToast('Project successfully deleted')
      })
      .then(() => {
        setDeleteNode(initialDeleteNode)
      })
      .then(() => {
        // take project out of storage on delete
        const newHash = expandedTree
        if (newHash) {
          delete newHash[project?.name ?? '']
          setLocalExpandedTree(newHash)
        }
      })
  }

  const searchMethod = (data: any) => {
    const { node, searchQuery } = data
    if (!searchQuery) {
      return false
    }
    const titleMatches =
      node.projectName && node.projectName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    return !!titleMatches
  }

  // moves you to one of the search items
  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1
    )
  }

  const selectNextMatch = () => {
    setSearchFocusIndex(searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0)
  }

  // takes tree and creates an object of projects and their expanded state
  const hash: any = {}
  const expandedHash = (projects: maybe<Node[]>) => {
    if (projects) {
      projects.forEach((project) => {
        if (project.children && project.children.length > 0) {
          hash[project.projectName as string] = project.expanded
          expandedHash(project.children as Node[])
        }
      })
    }
    return hash
  }

  // check if projects with children exist
  const projectsWithChildren = tree.some((t) => t.children && t.children.length > 0)

  // expand switch logic and update local storage on switch click
  const expand = (expanded: boolean) => {
    new Promise((res) => {
      setLocalExpandedTree(
        expandedHash(
          toggleExpandedForAll({
            treeData: tree,
            expanded,
          })
        )
      )
      res({})
    }).then(() => {
      setTree((prev) => {
        return toggleExpandedForAll({
          treeData: prev!,
          expanded,
        })
      })
    })
  }

  // sets expanded tree state to local storage
  const setLocalExpandedTree = (hash: any) => {
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'projects',
      args: { expandedTree: hash },
    })
  }

  const [moveNodeArgs, setMoveNodeArgs] = useState<MoveNodeArgs>(initialMoveNodeArgs)
  const projectBeforeChange = projects.find(
    (project) => project.name === moveNodeArgs?.node?.projectName
  )
  const parentBeforeChange = projects.find(
    (project) => project.url === projectBeforeChange?.depends_on
  )?.name

  const ChangeDetails = () => {
    return (
      <div className={styles.changeDetails}>
        <LabelText
          label="Project:"
          text={moveNodeArgs.node && moveNodeArgs.node.projectName}
          uppercase
          isHorizontal
        />
        <LabelText
          label="Current Parent Project:"
          text={parentBeforeChange || 'none'}
          uppercase
          isHorizontal
        />
        <LabelText
          label="New Parent Project:"
          text={moveNodeArgs.nextParentNode?.projectName || 'none'}
          uppercase
          isHorizontal
        />
      </div>
    )
  }

  return (
    <div className={styles.treeContainer}>
      <DeleteConfirmModal
        loading={deleteNode.loading}
        visible={deleteNode.visible}
        closeModal={() => setDeleteNode(initialDeleteNode)}
        onDelete={() => handleDelete(deleteNode.nodeName)}
        subject={deleteNode.nodeName ? `"${deleteNode.nodeName}"` : 'project'}
        removePronoun={!!deleteNode.nodeName}
      />

      <ConfirmModal
        onOk={() => handleChange(confirmNode.nextNode, confirmNode.node!)}
        onCancel={() => {
          setConfirmNode(initialConfirmNode)
          setTree(prevTree)
        }}
        visible={confirmNode.visible}
        subject={confirmSubject(confirmNode)}
        body={confirmBody(confirmNode)}
        changeDetails={<ChangeDetails />}
      />

      {canAdministrate && (
        <CopyProject
          project={copyNode.project as Project}
          visibleFromProps={copyNode.visible}
          closeModal={() => setCopyNode(initialUpdateNode)}
        />
      )}

      <EditProject
        form={form}
        project={updateNode.project as Project}
        visible={updateNode.visible}
        closeModal={() => setUpdateNode(initialUpdateNode)}
      />
      <PageTitle
        title="Projects"
        description="Projects are isolated sets of parameters and templates that interact with all
      Environments. Drag and drop projects to rearrange their inheritance. Projects without
      dependent projects can also be copied."
        link={
          <div className={styles.linkContainer}>
            <a
              className={styles.link}
              href="https://docs.cloudtruth.com/configuration-management/projects"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about projects
            </a>
            <a
              onClick={() => navigate('/organization/settings')}
              className={styles.projectNameLink}
            >
              Set Project Name Pattern
            </a>
          </div>
        }
        search={
          <div className={styles.searchContainer}>
            <Input
              onChange={(e) => {
                if (!e.target.value) {
                  setSearch('')
                }
                setLiveSearchValue(e.target.value)
              }}
              suffix={
                <SearchOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSearch(liveSearchValue)}
                />
              }
              onPressEnter={() => setSearch(liveSearchValue)}
              defaultValue={search || ''}
              className={styles.search}
              allowClear
              placeholder="Search Projects"
            />
            <Reload onClick={getProjects} loading={loading} marginRight="14px" />
            {searchFoundCount > 0 ? (
              <>
                <Button disabled={!searchFoundCount} onClick={selectPrevMatch}>
                  <LeftOutlined />
                </Button>
                <span className={styles.searchCount}>
                  {searchFocusIndex + 1} / {searchFoundCount}
                </span>
                <Button disabled={!searchFoundCount} onClick={selectNextMatch}>
                  <RightOutlined />
                </Button>
              </>
            ) : (
              ''
            )}
          </div>
        }
      />

      <div className={styles.content}>
        {projects.length > 0 && projectsWithChildren && (
          <div className={styles.expandButtonContainer}>
            Expand
            <Switch
              data-testid="expandSwitch"
              data-cy={`project-expand-${expanded}`}
              checked={expanded}
              onChange={(checked: boolean) => {
                return checked ? expand(true) : expand(false)
              }}
            />
          </div>
        )}

        {loading ? (
          <PageLoading />
        ) : projects.length > 0 ? (
          <div>
            <SortableTree
              searchMethod={searchMethod}
              onlyExpandSearchedNodes={false}
              searchFocusOffset={searchFocusIndex}
              searchQuery={search}
              searchFinishCallback={(matches) => {
                setSearchFoundCount(matches.length)
                setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0)
              }}
              treeData={tree}
              onMoveNode={({ nextParentNode, node, prevPath, path, treeIndex }) => {
                setMoveNodeArgs({ node, treeIndex, path, nextParentNode })
                handleConfirmOpen(nextParentNode, node, prevPath)
              }}
              generateNodeProps={({ node, path }) => ({
                buttons: [
                  <ProjectPreviewDetails
                    key="info"
                    project={detailsNode.project as Project}
                    closeModal={() => setDetailsNode(initialDetailsNode)}
                    onClick={() => {
                      const project = projects.find(
                        (project) => (node as Node).projectName === project.name
                      )

                      if (project) {
                        setDetailsNode({ ...detailsNode, project, node, visible: true })
                      }
                    }}
                  />,
                  <EditOutlined
                    key="edit"
                    className={styles.editOutline}
                    data-cy={`${(node as Node).projectName}-edit`}
                    onClick={() => {
                      const project = projects.find(
                        (project) => (node as Node).projectName === project.name
                      )

                      if (project) {
                        form.setFieldsValue({
                          name: project.name,
                          description: project.description,
                          parameter_name_pattern: project.parameter_name_pattern,
                        })

                        setUpdateNode({ ...updateNode, project, path, node, visible: true })
                      }
                    }}
                  />,

                  <CopyOutlined
                    key="copy"
                    data-cy={`${(node as Node).projectName}-copy`}
                    className={styles.editOutline}
                    onClick={() => {
                      const project = projects.find(
                        (project) => (node as Node).projectName === project.name
                      )

                      if (project) {
                        form.setFieldsValue({
                          name: project.name,
                          description: project.description,
                        })
                        setCopyNode({ ...updateNode, project, path, node, visible: true })
                      }
                    }}
                  />,

                  <DeleteOutlined
                    key="delete"
                    data-cy={`${projects
                      .find((project) => (node as Node).projectName === project.name)
                      ?.name?.trim()}-delete`}
                    className={styles.delete}
                    onClick={() => {
                      setDeleteNode({
                        ...deleteNode,
                        visible: true,
                        nodeName: (node as Node).projectName as string,
                        path,
                      })
                    }}
                  />,
                ],
              })}
              onChange={(treeData: Node[]) => {
                setPrevTree(tree)
                new Promise((res) => {
                  setTree(treeData)
                  res({})
                }).then(() => {
                  setLocalExpandedTree(expandedHash(treeData))
                })
              }}
              isVirtualized={false}
            />
          </div>
        ) : (
          <EmptyProjectList />
        )}
      </div>
    </div>
  )
}

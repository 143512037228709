//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { ActionsHash, useHistoryPush, useOrganizationParams } from 'router/customHooks'
import { AwsPushTask, AzureKeyVaultPushTask } from 'gen/cloudTruthRestApi'
import { GetAwsPush, GetAwsPushTasks } from 'data/actions/awsPush/actions'
import { GetAzureKeyVaultPush, GetAzureKeyVaultPushTasks } from 'data/actions/akvPush/actions'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionViews } from 'components/Actions/ActionContainer'
import { BackToList } from 'components/BackToList'
import { CustomThunk } from 'data/dataUtils'
import { GetTags } from 'data/environment/actions'
import { PageLoading } from 'components/PageLoading'
import { PushDetails } from './PushDetails'
import { TaskTable } from 'components/Actions/ActionsStepTable/TaskTable'
import { getAwsPushWithTagNames } from 'data/actions/awsPush/selectors'
import { getAzureKeyVaultPushWithTagNames } from 'data/actions/akvPush/selectors'

interface Props {
  actionType: ActionPushEnum
}

export function PushDetailsPage(props: Props) {
  const { actionType } = props
  const { actionId, integrationId } = useOrganizationParams()!
  const awsPush = useAppSelector(getAwsPushWithTagNames(actionId!))
  const akvPush = useAppSelector(getAzureKeyVaultPushWithTagNames(actionId!))
  const push = useMemo(() => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return awsPush

      case ActionEnum.AzureKeyVaultPush:
        return akvPush

      default:
        return null
    }
  }, [actionType, awsPush, akvPush])

  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())

  const [loading, setLoading] = useState(!push)
  const [pushTasks, setPushTasks] = useState<(AwsPushTask | AzureKeyVaultPushTask)[]>([])

  const { goToActionsRoute } = useHistoryPush()
  const dispatch = useAppDispatch()

  const goToActions = useCallback(
    (actionType: ActionEnum) => {
      switch (actionType) {
        case ActionEnum.AwsPush: {
          goToActionsRoute(ActionViews.Push, ActionsHash.AWS)
          break
        }

        case ActionEnum.AzureKeyVaultPush: {
          goToActionsRoute(ActionViews.Push, ActionsHash.AKV)
          break
        }

        default:
          break
      }
    },
    [goToActionsRoute]
  )

  useEffect(() => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(GetAwsPushTasks({ integrationId: integrationId!, actionId: actionId! })).then(
          ({ error, payload }: CustomThunk) => {
            !error && setPushTasks(payload.results)
          }
        )
        break

      case ActionEnum.AzureKeyVaultPush:
        dispatch(
          GetAzureKeyVaultPushTasks({ integrationId: integrationId!, actionId: actionId! })
        ).then(({ error, payload }: CustomThunk) => {
          !error && setPushTasks(payload.results)
        })
        break

      default:
        break
    }
  }, [actionId, dispatch, integrationId, push?.latest_task, actionType])

  const loadPush = useCallback(() => {
    setLoading(true)

    switch (actionType) {
      case ActionEnum.AwsPush:
        return dispatch(GetAwsPush({ integrationId: integrationId!, actionId: actionId! })).then(
          () => setLoading(false)
        )

      case ActionEnum.AzureKeyVaultPush:
        return dispatch(
          GetAzureKeyVaultPush({ integrationId: integrationId!, actionId: actionId! })
        ).then(() => setLoading(false))

      default:
        return null
    }
  }, [actionId, dispatch, integrationId, actionType])

  useLayoutEffect(() => {
    if (!push) {
      const promises = environments.map((env) => {
        return new Promise((resolve) =>
          dispatch(GetTags(env.id)).then(() => {
            resolve({})
          })
        )
      })

      Promise.all(promises).then(() => loadPush().then(() => setLoading(false)))
    }
  }, [actionId, dispatch, environments, integrationId, loadPush, push])

  useLayoutEffect(() => {
    if (!loading && !push) {
      goToActions(actionType)
    }
  }, [goToActions, loading, push, actionType])

  if (!loading && !push) {
    return null
  }

  if (loading || !push) {
    return <PageLoading />
  }

  return (
    <div>
      <BackToList onLinkClick={() => goToActions(actionType)} text="Push List" />
      <PushDetails push={push} refreshPush={loadPush} actionType={actionType} />
      <TaskTable
        actionType={actionType}
        tasks={pushTasks}
        loading={loading}
        refreshPush={loadPush}
      />
    </div>
  )
}

import { Form, FormData, LabeledInputItem, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { AddModal } from 'components/Modals'
import { IdentityWithSessions } from 'components/Ory/Admin/ListIdentities/ListIdentities'
import { Input } from 'antd'
import { createIdentity } from 'components/Ory'
import { oryAdmin } from 'components/Ory/ory'
import { useToast } from 'hooks'

// import { useToast } from 'hooks'

// import { useAppDispatch, useAppSelector } from 'data/hooks'

interface Props {
  visible: boolean
  setVisible: (bool: boolean) => void
  setIdentities: React.Dispatch<React.SetStateAction<option<IdentityWithSessions[]>>>
}

export function AddIdentity(props: Props) {
  const { visible, setVisible, setIdentities } = props
  const [pending, setPending] = useState<boolean>(false)
  const { errorToast, successToast } = useToast()

  const [form] = useForm()
  // const dispatch = useAppDispatch()
  // const { errorToast, successToast } = useToast()

  const onFinish = (formValues: FormData) => {
    setPending(true)
    const { first, last, email, password } = formValues
    const traits = { name: { first, last }, email }

    createIdentity(oryAdmin, traits, password)
      .then(({ data }) => {
        successToast('Ory user successfully created.')
        setIdentities((prev) => (prev ? [...prev, { ...data } as IdentityWithSessions] : undefined))
      })
      .catch(errorToast)
      .then(() => {
        setPending(false)
        onCancel()
      })
  }

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <AddModal
        visible={visible}
        objectName="User"
        afterClose={onCancel}
        onCancel={onCancel}
        onOk={() => form.submit()}
        pending={pending}
      >
        <Form form={form} onFinish={onFinish}>
          <LabeledInputItem
            autofocus
            name="first"
            label="First Name"
            required="Please enter first name."
          >
            <Input />
          </LabeledInputItem>

          <LabeledInputItem name="last" label="Last Name" required="Please enter last name.">
            <Input />
          </LabeledInputItem>

          <LabeledInputItem name="email" label="Email" required="Please enter email.">
            <Input />
          </LabeledInputItem>

          <LabeledInputItem name="password" label="Password" required="Please enter password.">
            <Input.Password />
          </LabeledInputItem>
        </Form>
      </AddModal>
    </>
  )
}

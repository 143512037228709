import { Checkbox, Radio } from 'antd'

import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import styles from './ValueTypeButton.module.scss'

interface Props {
  isInterpolated: boolean
  isExternal: boolean
  isSecret: boolean
  setIsInterpolated: (bool: boolean) => void
  setIsExternal: (bool: boolean) => void
}

export function ValueTypeButton(props: Props) {
  const { isInterpolated, isExternal, isSecret, setIsInterpolated, setIsExternal } = props

  const tooltipText = (): string => {
    if (isSecret) {
      return 'Secret parameters cannot be dynamically interpolated.'
    } else if (isExternal) {
      return 'Dynamic option for external values cannot be changed here, but can be changed using the CLI.'
    } else {
      return 'Setting this value to dynamic will allow interpolation of other parameters and templates stored in CloudTruth.'
    }
  }

  return (
    <div className={styles.container}>
      <label className={styles.label}>SOURCE</label>
      <Radio.Group
        onChange={(e) => setIsExternal(e.target.value)}
        defaultValue={isExternal}
        buttonStyle="solid"
      >
        <Radio.Button value={false}>Internal</Radio.Button>
        <Radio.Button value={true}>External</Radio.Button>
      </Radio.Group>
      <Checkbox
        className={styles.dynamicCheckbox}
        disabled={isSecret || isExternal}
        checked={isInterpolated}
        onChange={(e) => setIsInterpolated(e.target.checked)}
        data-cy="dynamic-checkbox"
      >
        DYNAMIC
        <QuestionIconTooltip title={tooltipText()} />
      </Checkbox>
    </div>
  )
}

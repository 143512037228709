import { displayDate, formatDateTime } from 'lib/dateHelpers'

import { Parameter } from 'gen/cloudTruthRestApi'
import React from 'react'
import { Tooltip } from 'antd'

export function UpdatedAtColumn({ parameter }: { parameter: Parameter }) {
  if (parameter.modified_at !== '') {
    return (
      <Tooltip title={formatDateTime(parameter.modified_at)}>
        {displayDate(parameter.modified_at)}
      </Tooltip>
    )
  }

  return <p />
}

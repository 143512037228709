//
// Copyright (C) 2020 CloudTruth, Inc.
// All Rights Reserved
//

import { AuditTrail, PaginatedAuditTrailList } from 'gen/cloudTruthRestApi'
import { OrganizationPaths, orgLink } from 'router/OrganizationRoutes'
import React, { useCallback, useState } from 'react'
import { ReloadTile, TileName } from 'components/Dashboard/ReloadTile'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Actions } from 'components/Drawer/Actions'
import { Drawer as AntDrawer } from 'antd'
import { DrawerTile } from './DrawerTile'
import { Messages } from 'components/Drawer/Messages/Messages'
import { RecentActivity } from 'components/Drawer/RecentActivity/RecentActivity'
import { SearchAuditLog } from 'data/misc/actions'
import { TypedThunk } from 'data/dataUtils'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

export function Drawer(props: Props) {
  const { open, setOpen } = props
  const [recentLoading, setRecentLoading] = useState(false)
  const [activity, setActivity] = useState<AuditTrail[]>([])
  const [actionsLoading, setActionsLoading] = useState(false)
  const [hasActions, setHasActions] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const getRecent = useCallback(() => {
    setRecentLoading(true)
    dispatch(SearchAuditLog({ page_size: 10 })).then(
      ({ error, payload }: TypedThunk<PaginatedAuditTrailList>) => {
        error ? errorToast(error.message) : setActivity(payload.results || [])
        setRecentLoading(false)
      }
    )
  }, [dispatch, setRecentLoading, setActivity, errorToast])

  return (
    <AntDrawer open={open} placement="right" onClose={() => setOpen(false)} size="large">
      <DrawerTile title="Messages">
        <Messages />
      </DrawerTile>

      {canAdministrate && (
        <DrawerTile
          title="Recent Activity"
          link={[
            {
              linkText: 'View Audit Log',
              to: orgLink(OrganizationPaths.AuditLog),
            },
          ]}
          actionButton={
            <ReloadTile
              setLoading={setRecentLoading}
              name={TileName.Recent}
              setActivity={setActivity}
              getRecent={getRecent}
            />
          }
        >
          <RecentActivity loading={recentLoading} activity={activity!} getRecent={getRecent} />
        </DrawerTile>
      )}

      {canAdministrate && (
        <DrawerTile
          title="Actions"
          link={[
            { linkText: 'View Pushes', to: orgLink(OrganizationPaths.PushActions) },
            { linkText: 'View Imports', to: orgLink(OrganizationPaths.PullActions) },
          ]}
          actionButton={
            hasActions ? (
              <ReloadTile setLoading={setActionsLoading} name={TileName.Actions} />
            ) : (
              <></>
            )
          }
        >
          <Actions
            loading={actionsLoading}
            setLoading={setActionsLoading}
            setHasActions={setHasActions}
          />
        </DrawerTile>
      )}
    </AntDrawer>
  )
}

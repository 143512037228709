//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionEnum, AsyncJob, getAsyncJobs } from '../utils'
import { AwsPush, Environment, Tag } from 'gen/cloudTruthRestApi'
import { PushRootState, awsPushAdapter } from './reducer'
import { environmentIdFromUrl, idFromUrl, integrationIdFromAwsPushUrl } from 'data/dataUtils'

import { Dictionary } from '@reduxjs/toolkit/dist/entities'
import { GetAwsPush } from './actions'
import { RootState } from 'data/store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectPush = (state: RootState) => state.awsPush
const selectState = (state: RootState) => state

export interface AwsPushWithTags extends AwsPush {
  tagDisplayNames: string[]
}

export const displayTags = (
  tagUrls: string[],
  allTags: Record<string, Tag[]>,
  environments: Dictionary<Environment>
): string[] => {
  return tagUrls
    .map((tagUrl) => {
      const environmentId = environmentIdFromUrl(tagUrl)
      const tagId = idFromUrl(tagUrl)

      const envTags = allTags![environmentId]

      return `${environments[environmentId]?.name}: ${
        envTags?.find((tag) => tag.id === tagId)?.name
      }`
    })
    .sort((first, second) => {
      return first.localeCompare(second)
    })
}

const buildPushWithTag = (
  push: AwsPush,
  tags: Record<string, Tag[]>,
  environments: Dictionary<Environment>
): AwsPushWithTags => {
  return {
    ...push,
    tagDisplayNames: displayTags(push.tags, tags, environments),
  }
}

export const getCurrentPush = createSelector(selectPush, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getIntegrationPushes = (
  integrationId: maybe<string>
): Selector<RootState, AwsPush[]> =>
  createSelector(selectPush, (state): AwsPush[] => {
    return (Object.values(state.entities) as AwsPush[])
      .filter((push) => !integrationId || integrationId === integrationIdFromAwsPushUrl(push.url))
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
  })

export const getAwsPushesWithTagNames = (
  awsIntegrationId: maybe<string>
): Selector<RootState, AwsPushWithTags[]> =>
  createSelector(selectState, (state): AwsPushWithTags[] => {
    const tags = state.environment.tags
    const environments = state.environment.entities

    return (Object.values(state.awsPush.entities) as AwsPush[])
      .filter(
        (push) => !awsIntegrationId || awsIntegrationId === integrationIdFromAwsPushUrl(push!.url)
      )
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      .map((push) => {
        return buildPushWithTag(push, tags, environments)
      })
  })

export const getAwsPushWithTagNames = (
  pushId: string
): Selector<RootState, nullable<AwsPushWithTags>> =>
  createSelector(selectState, (state): nullable<AwsPushWithTags> => {
    const tags = state.environment.tags
    const environments = state.environment.entities
    const push = state.awsPush.entities[pushId]

    return push ? buildPushWithTag(push, tags, environments) : null
  })

export const getRunningPushes = createSelector(selectPush, (state): AsyncJob[] => {
  return getAsyncJobs(state).map((job) => ({
    ...job,
    type: ActionEnum.AwsPush,
    action: GetAwsPush,
  }))
})

export const pushEntitySelectors = awsPushAdapter.getSelectors()
export const useSelectPush = (): PushRootState => useSelectState().awsPush

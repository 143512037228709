//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { Resizable, ResizableProps, ResizeCallbackData } from 'react-resizable'
import { SetLocalSessionArgs, setLocalColumnSizes } from 'lib/sessionPersistance'

import React from 'react'
import { TableColumnType } from 'antd'
import styles from './ResizableTitle.module.scss'

const Resize = Resizable as any

const handleResize =
  (
    index: number,
    columns: TableColumnType<any>[],
    setColumns: React.Dispatch<TableColumnType<any>[]>,
    org?: string,
    pageType?: SetLocalSessionArgs['pageType']
  ) =>
  (event: React.SyntheticEvent<Element, Event>, { size }: ResizeCallbackData): void => {
    event.stopPropagation() // prevents sorter from triggering on resize
    const nextColumns = [...columns]
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    }
    setColumns(nextColumns)
    if (org && pageType) {
      setLocalColumnSizes(org, pageType, nextColumns)
    }
  }

export const resizableColumns = (
  columns: TableColumnType<any>[],
  setColumns: React.Dispatch<TableColumnType<any>[]>,
  org?: string,
  pageType?: SetLocalSessionArgs['pageType']
): TableColumnType<any>[] => {
  return columns.map((column, idx) => ({
    ...column,
    onHeaderCell: (col: TableColumnType<any>) => ({
      width: col.width,
      onResize: handleResize(idx, columns, setColumns, org, pageType) as any,
    }),
  }))
}

export function ResizableTitle(props: ResizableProps) {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...(restProps as any)} />
  }

  return (
    <Resize
      className={styles.reactResizable}
      width={width}
      height={0}
      handle={
        <span
          className={styles.reactResizableHandle}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...(restProps as any)} />
    </Resize>
  )
}

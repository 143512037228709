import { ActionButton } from 'components/ActionButton'
import React from 'react'
import { Tooltip } from 'antd'
import styles from './HeaderLink.module.scss'

interface Props {
  href?: string
  icon?: JSX.Element
  tooltip?: string
  text?: string
  onClick?: () => void
}

export function HeaderLink(props: Props) {
  const { text, href, icon, tooltip, onClick } = props

  if (!text) {
    return (
      <Tooltip title={tooltip}>
        <div data-cy={`${tooltip}-tooltip`}>
          <a
            href={href}
            target="_blank"
            className={styles.anchor}
            rel="noreferrer"
            onClick={onClick}
          >
            <ActionButton
              className={`${styles.button} ${styles.iconButton}`}
              icon={icon}
              style={{ background: '#161e2d' }}
              data-cy={`${text || tooltip}-button`}
            />
          </a>
        </div>
      </Tooltip>
    )
  }

  return (
    <div>
      <a href={href} target="_blank" className={styles.anchor} rel="noreferrer" onClick={onClick}>
        <ActionButton className={styles.button} icon={icon} style={{ background: '#161e2d' }}>
          <span className={styles.text}> {text}</span>
        </ActionButton>
      </a>
    </div>
  )
}

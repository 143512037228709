//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { EntityId, createSelector } from '@reduxjs/toolkit'
import { Parameter, Value } from 'gen/cloudTruthRestApi'
import { ParameterRootState, parameterAdapter } from 'data/parameter/reducer'

import { RootState } from '../store'
import { Selector } from 'react-redux'
import { idFromUrl } from 'data/dataUtils'
import { useSelectState } from '../hooks'

const rootState = (state: RootState) => state
const selectParameter = (state: RootState) => state.parameter

export interface ParentParameters {
  name: string
  parameters: Parameter[]
}

export const getCurrentParameter = createSelector(selectParameter, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getParameterCount = createSelector(selectParameter, (state) => {
  return state.count
})

export const getValueByEnv = (
  parameterId: EntityId,
  environmentUrl: EntityId
): Selector<RootState, Value> =>
  createSelector(selectParameter, (state): Value => {
    return state.entities[parameterId]!.values[environmentUrl]!
  })

export const parameterEntitySelectors = parameterAdapter.getSelectors()
export const useSelectParameters = (): ParameterRootState => useSelectState().parameter

const findSecrets = (values: (Value | null)[], state: RootState): boolean => {
  const parametersById = state.parameter.entities
  const templatesById = state.template.entities

  for (let i = 0; i < values.length; i++) {
    const value = values[i]
    if (value) {
      const { referenced_parameters: parameters, referenced_templates: templates } = value

      // No good way to determine if external interpolated values contain secrets
      if (value.secret || (value.external && value.interpolated)) {
        return true
      }

      for (let j = 0; j < parameters.length; j++) {
        if (parametersById[idFromUrl(parameters[j])]?.secret) {
          return true
        }
      }

      for (let k = 0; k < templates.length; k++) {
        if (templatesById[idFromUrl(templates[k])]?.has_secret) {
          return true
        }
      }
    }
  }

  return false
}

export const getProjectParameters = (projectUrl: string): Selector<RootState, Parameter[]> =>
  createSelector(selectParameter, (state): Parameter[] => {
    const parameters = Object.values(state.entities).filter(
      (param) => param!.project === projectUrl
    )
    return parameters as Parameter[]
  })

export const getParentParameters = (
  projectUrl: string
): Selector<RootState, Record<string, ParentParameters>> =>
  createSelector(selectParameter, (state): Record<string, ParentParameters> => {
    const result: Record<string, ParentParameters> = {}

    Object.values(state.entities).forEach((parameter) => {
      if (parameter && parameter.project !== projectUrl) {
        if (Object.prototype.hasOwnProperty.call(result, parameter.project)) {
          result[parameter.project].parameters.push(parameter)
        } else {
          result[parameter.project] = { name: parameter.project_name, parameters: [parameter] }
        }
      }
    })

    return result
  })

export const getInterpolatedSecretStatus = (
  values: (Value | null)[]
): Selector<RootState, boolean> =>
  createSelector(rootState, (state: RootState): boolean => findSecrets(values, state))

export const exportsForTesting = {
  findSecrets,
}

import React from 'react'
import { Spin } from 'antd'
import styles from './EditorSpin.module.scss'

export function EditorSpin() {
  return (
    <div className={styles.spinContainer}>
      <Spin size="large" />
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import React, { useEffect } from 'react'

import { CreateGithubIntegration } from 'data/integration/githubIntegration/actions'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { PageLoading } from 'components/PageLoading'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useLocation } from 'react-router-dom'

export function GithubCallback() {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { goToOrgRoute } = useHistoryPush()

  useEffect(() => {
    const installationId = parseInt(search.match(/installation_id=(\d+)/)?.[1] ?? '0')

    dispatch(CreateGithubIntegration({ gh_installation_id: installationId })).then(() => {
      goToOrgRoute(OrganizationPaths.Github)
    })
  })

  return <PageLoading />
}

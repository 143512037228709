//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AwsIntegration,
  AwsIntegrationCreate,
  IntegrationsAwsDestroyParams,
  PaginatedAwsIntegrationList,
  PatchedAwsIntegration,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetAwsIntegrations = createAsyncThunk<PaginatedAwsIntegrationList, null, ThunkConfig>(
  'awsIntegration/getAwsIntegrations',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'integrations/aws', null, thunkApi)
)

export const GetAwsIntegration = createAsyncThunk<AwsIntegration, string, ThunkConfig>(
  'awsIntegration/getAwsIntegration',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `integrations/aws/${id}`, null, thunkApi)
)

export const CreateAwsIntegration = createAsyncThunk<
  AwsIntegration,
  AwsIntegrationCreate,
  ThunkConfig
>(
  'awsIntegration/createAwsIntegration',
  async (awsIntegration, thunkApi) =>
    await handleFetch(Verbs.Post, 'integrations/aws', awsIntegration, thunkApi)
)

export const UpdateAwsIntegration = createAsyncThunk<
  AwsIntegration,
  PatchedAwsIntegration,
  ThunkConfig
>(
  'awsIntegration/updateAwsIntegration',
  async (awsIntegration, thunkApi) =>
    await handleFetch(Verbs.Put, `integrations/aws/${awsIntegration.id}`, awsIntegration, thunkApi)
)

export const DeleteAwsIntegration = createAsyncThunk<
  string,
  IntegrationsAwsDestroyParams,
  ThunkConfig
>('awsIntegration/deleteAwsIntegration', async (args, thunkApi) => {
  const query = { in_use: args.in_use || 'fail' }
  return await handleFetch(
    Verbs.Delete,
    `integrations/aws/${args.id}`,
    null,
    thunkApi,
    buildQuery(query)
  )
})

export const CheckAwsIntegrationStatus = createAsyncThunk<
  AwsIntegration,
  PatchedAwsIntegration,
  ThunkConfig
>('awsIntegration/checkAwsIntegrationStatus', async (awsIntegration, thunkApi) => {
  // This is a hack to force a 'status_last_checked_at' update. It will be replaced by a proper route
  const query = { refresh_status: 'true' }
  return await handleFetch(
    Verbs.Get,
    `integrations/aws/${awsIntegration.id}`,
    null,
    thunkApi,
    buildQuery(query)
  )
})

import {
  IntegrationNode,
  NodeTypeEnum,
  PaginatedIntegrationNodeList,
  Value,
} from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'

import { EditorSpin } from './EditorSpin'
import { FileBody } from './FileDetails/FileBody'
import { FileDetails } from './FileDetails'
import { FileSelector } from './FileSelector'
import { FormInstance } from 'antd'
import { GetIntegrationExplorer } from 'data/integration/actions'
import { Location } from './Location'
import { TypedThunk } from 'data/dataUtils'
import styles from './IntegrationExplorer.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

export interface IntegrationTree {
  fqn: string
  entries: IntegrationNode[]
}

interface Props {
  environmentValue: nullable<Value>
  setPending?: (pending: boolean) => void
  error?: nullable<string>
  form?: FormInstance
  showExternalSecretValues: boolean
  showSecret?: boolean
  secret?: boolean
  setIntegrationNodeFqn?: (id: maybe<string>) => void
}

export function IntegrationExplorerComponent(props: Props) {
  const {
    environmentValue,
    setPending,
    error,
    form,
    setIntegrationNodeFqn,
    showExternalSecretValues,
    showSecret,
    secret,
  } = props

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const currentIntegrationFile = environmentValue?.external_fqn
  const initialJmesPath = environmentValue?.external_filter

  const [selectedFqn, setSelectedFqn] = useState<nullable<string>>(currentIntegrationFile || null)

  const [integrationTree, setIntegrationTree] = useState<nullable<IntegrationTree>>(null)
  const [integrationNode, setIntegrationNode] = useState<nullable<IntegrationNode>>(null)

  const [hasSelectedFile, setHasSelectedFile] = useState<boolean>(!!environmentValue?.external_fqn)
  const [integrationNodeLoading, setIntegrationNodeLoading] = useState(true)

  const fileSelected: boolean = integrationNode?.node_type === NodeTypeEnum.File

  /*
   * If an IntegrationFile is already selected but there is no IntegrationTree, use the ancestors of the file
   * to set the current root of the file tree
   */
  useEffect(() => {
    if (integrationNode?.node_type === NodeTypeEnum.File && !integrationTree) {
      const parentFqn = integrationNode.fqn.match(/((github|aws|akv):\/\/[^/]+\/)/)![1]
      const fqn = parentFqn.includes('akv') ? `${parentFqn}secrets/` : parentFqn

      dispatch(GetIntegrationExplorer({ fqn })).then(
        ({ error, payload }: TypedThunk<PaginatedIntegrationNodeList>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          setIntegrationTree({ fqn: selectedFqn!, entries: payload.results! })
        }
      )
    }
  }, [dispatch, errorToast, integrationNode, integrationTree, selectedFqn])

  /*
   * Every time an IntegrationNode is selected (selectedFqn) fetch that node from the server.
   */
  useEffect(() => {
    if (selectedFqn) {
      dispatch(GetIntegrationExplorer({ fqn: selectedFqn })).then(
        ({ error, payload }: TypedThunk<PaginatedIntegrationNodeList>) => {
          setIntegrationNodeLoading(false)

          if (error) {
            errorToast(error.message)
            if (setPending) {
              setPending(false)
            }
            setHasSelectedFile(false)

            return
          }
          const { results } = payload

          // if the integration at the requested fqn no longer exists
          if (results!.length === 0) {
            errorToast('The integration file or directory cannot be found.')

            if (setPending) {
              setPending(false)
            }
            setHasSelectedFile(false)

            return
          }

          if (selectedFqn === results![0].fqn) {
            // The user has selected a file. Allow submit to save the file selection

            if (setPending) {
              setPending(false)
            }
          } else {
            // if the selected node not the selected file, it must be a part of the integration tree.
            // keep track of the selected tree node and disable submitting
            setIntegrationTree({ fqn: selectedFqn, entries: results! })

            if (setPending) {
              setPending(true)
            }
          }

          // regardless of the node type, set the currently selected node
          setIntegrationNode(results![0])

          if (setIntegrationNodeFqn) {
            setIntegrationNodeFqn(results![0].fqn)
          }
        }
      )
    } else {
      //the selection was removed, reset the tree and node selections
      setIntegrationTree(null)
      setIntegrationNode(null)
      setHasSelectedFile(false)
      setIntegrationNodeLoading(false)

      if (setPending) {
        setPending(false)
      }

      if (setIntegrationNodeFqn) {
        setIntegrationNodeFqn(null)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFqn])

  const handleSelection = (fqn: nullable<string>) => {
    if (fqn !== selectedFqn) {
      setIntegrationNodeLoading(true)
      setSelectedFqn(fqn)
    }
  }

  const renderFileContent = () => {
    if (!integrationNodeLoading && integrationNode && fileSelected) {
      return (
        <>
          {form ? (
            <FileDetails
              showSecret={showSecret}
              integrationFile={integrationNode}
              form={form}
              error={error}
              initialJmesPath={initialJmesPath}
              secret={secret}
            />
          ) : (
            <FileBody integrationFile={integrationNode} showSecret={showSecret} secret={secret} />
          )}
        </>
      )
    }

    return hasSelectedFile && integrationNodeLoading ? (
      <EditorSpin />
    ) : (
      <div className={styles.selectFile}>Select a value to continue.</div>
    )
  }

  const integrationFile = fileSelected ? integrationNode : null

  return (
    <>
      <Location
        externalError={environmentValue?.external_error}
        showExternalSecretValues={showExternalSecretValues}
        integrationTree={integrationTree || null}
        integrationFile={integrationFile}
        onSelect={handleSelection}
        pending={integrationNodeLoading}
        setPending={setIntegrationNodeLoading}
        selectedFqn={selectedFqn}
        hasSelectedFile={() => setHasSelectedFile(true)}
      />
      <div className={styles.content}>
        <FileSelector integrationNode={integrationNode} />
        {renderFileContent()}
      </div>
    </>
  )
}

import { DatePicker, Select } from 'antd'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { getCurrentEnvironment, getEnvironmentTags } from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { GetTags } from 'data/environment/actions'
import { Tag } from 'gen/cloudTruthRestApi'
import { isoDateTimeFormat } from 'lib/dateHelpers'
import styles from './TimestampSelector.module.scss'

interface Props {
  setTimestamp: React.Dispatch<React.SetStateAction<nullable<Dayjs>>>
  timestamp?: nullable<Dayjs>
  setTagName?: React.Dispatch<React.SetStateAction<nullable<string>>>
  handleSubmit?: (timestamp: Dayjs | string, callback: () => void) => void
  loading: boolean
  setPage?: React.Dispatch<React.SetStateAction<{ pageNumber: number; project: string }>>
  setSelectedTimestamp?: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const disabledTime = (date: nullable<Dayjs>) => {
  const hours = Array.from(Array(24).keys())
  const minutes = Array.from(Array(60).keys())
  const seconds = Array.from(Array(60).keys())

  const pastDate = (date: Dayjs, granularity: dayjs.OpUnitType) =>
    date.isBefore(dayjs(), granularity as any)

  return {
    disabledHours: () => {
      if (date && pastDate(date, 'day')) {
        return []
      }
      return hours.filter((i) => i > dayjs().hour())
    },
    disabledMinutes: (hour: number) => {
      if (date && pastDate(date, 'hour')) {
        return []
      }

      if (hour > dayjs().hour()) {
        return minutes
      } else if (hour === dayjs().hour()) {
        return minutes.filter((i) => i > dayjs().minute())
      } else {
        return []
      }
    },
    disabledSeconds: (hour: number, minutes: number) => {
      if (date && pastDate(date, 'minute')) {
        return []
      }

      if (minutes > dayjs().minute()) {
        return seconds
      } else if (minutes === dayjs().minute()) {
        return seconds.filter((i) => i > dayjs().second())
      } else {
        return []
      }
    },
  }
}

export function TimestampSelector(props: Props) {
  const {
    loading,
    setTimestamp,
    setTagName,
    handleSubmit,
    timestamp: timeStampFromProps,
    setPage,
    setSelectedTimestamp,
  } = props
  const dispatch = useAppDispatch()
  const environment = useAppSelector(getCurrentEnvironment)
  const tags = useAppSelector(getEnvironmentTags(environment?.id || null))

  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState<nullable<Dayjs>>(null)
  const [tag, setTag] = useState<Tag | undefined>(undefined)

  const timestamp = value?.toISOString() || tag?.timestamp

  const time = timeStampFromProps?.toISOString()

  const { setQuery } = useQuery()

  useEffect(() => {
    if (environment && !tags) {
      dispatch(GetTags(environment!.id))
    }
  }, [dispatch, environment, tags])

  useEffect(() => {
    setTag(undefined)
  }, [environment])

  const handleDateChange = (val: nullable<Dayjs>): void => {
    setValue(val)
    setTag(undefined)
    if (val === null) {
      setSelectedTimestamp && setSelectedTimestamp(undefined)
    }
  }

  const handleTagChange = (val: Tag | undefined): void => {
    setTag(val)
    if (val === undefined) {
      setSelectedTimestamp && setSelectedTimestamp(undefined)
    }

    setValue(null)
  }

  const handleTimestampSubmit = () => {
    const callback = () => {
      setTimestamp(value || dayjs(tag?.timestamp))
      setPage && setPage((prev) => ({ ...prev, pageNumber: 1 })), setQuery(QueryParamName.Page, '1')
      if (tag) {
        setTagName && setTagName(tag.name)
      }
    }

    handleSubmit ? handleSubmit(timestamp!, callback) : callback()
    setSelectedTimestamp &&
      setSelectedTimestamp(
        tag !== undefined ? tag?.name : dayjs(timestamp).format(isoDateTimeFormat) || undefined
      )
  }

  return (
    <div>
      <Select
        className={styles.select}
        allowClear
        onClear={() => handleTagChange(undefined)}
        placeholder="Select environment tag"
        value={tag?.id}
        onSelect={(id: string) => tags && handleTagChange(tags.find((x) => x.id === id))}
        notFoundContent={'No tags'}
      >
        {(tags || []).map((tag) => (
          <Select.Option key={tag.id} value={tag.id}>
            {tag.name}
          </Select.Option>
        ))}
      </Select>
      <DatePicker
        disabledDate={(current) => current && current.isAfter(dayjs(), 'day')}
        disabledTime={(current) => disabledTime(current)}
        use12Hours
        format={isoDateTimeFormat}
        showTime={{ defaultValue: dayjs(), use12Hours: false }}
        onChange={handleDateChange}
        value={value}
        onSelect={handleDateChange}
        onOpenChange={setIsOpen}
      />

      <ActionButton
        type="primary"
        onClick={handleTimestampSubmit}
        disabled={(timestamp && time === timestamp) || (!tag && (!value || isOpen))}
        loading={loading}
      >
        Apply
      </ActionButton>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateAzureKeyVaultPull,
  DeleteAzureKeyVaultPull,
  GetAzureKeyVaultPull,
  GetAzureKeyVaultPulls,
  UpdateAzureKeyVaultPull,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AzureKeyVaultPull } from 'gen/cloudTruthRestApi'
import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { epoch } from 'data/dataUtils'

interface PullState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedIntegrations: string[]
}

export const akvPullAdapter = createEntityAdapter<AzureKeyVaultPull>({
  selectId: (int) => int.id,
})

const initialState = akvPullAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  cachedIntegrations: [],
} as PullState)

export type PullRootState = EntityState<AzureKeyVaultPull> & PullState

const akvPullSlice = createSlice({
  name: 'akvPull',
  initialState,
  reducers: {
    // direct actions
    selectAzureKeyVaultPull(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllAzureKeyVaultPull(state, action: PayloadAction<AzureKeyVaultPull[]>) {
      akvPullAdapter.setAll(state, action.payload)
    },

    addOneAzureKeyVaultPull: akvPullAdapter.addOne,
    updateOneAzureKeyVaultPull: akvPullAdapter.upsertOne,
    removeOneAzureKeyVaultPull: akvPullAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAzureKeyVaultPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPullAdapter.addOne(state, action.payload)
    })

    builder.addCase(GetAzureKeyVaultPulls.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      if (!state.cachedIntegrations.includes(action.meta.arg)) {
        state.cachedIntegrations.push(action.meta.arg)
      }
      akvPullAdapter.setMany(state, action.payload.results!)
    })
    builder.addCase(GetAzureKeyVaultPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPullAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateAzureKeyVaultPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPullAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAzureKeyVaultPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPullAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const {
  resetState,
  selectAzureKeyVaultPull,
  setAllAzureKeyVaultPull,
  addOneAzureKeyVaultPull,
  updateOneAzureKeyVaultPull,
  removeOneAzureKeyVaultPull,
} = akvPullSlice.actions

export default akvPullSlice.reducer

import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { ActionButton } from 'components/ActionButton'
import { ActionEnum } from 'data/actions/utils'
import { AddImport } from './AddImport'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import styles from './ImportTableButtons.module.scss'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  actionType: ActionEnum
}

export function ImportTableButtons(props: Props) {
  const { actionType } = props
  const awsIntegrations = awsIntegrationEntitySelectors.selectAll(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectAll(useSelectAkvIntegrations())
  const { goToAkvRoute, goToAwsRoute } = useHistoryPush()

  const hasIntegrations = () => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return awsIntegrations.some((integration) => {
          return integration.aws_enabled_services.length > 0
        })

      case ActionEnum.AzureKeyVaultPull:
        return akvIntegrations.length > 0

      default:
        break
    }
  }

  const AwsDependencyButtons = () => {
    return (
      <div>
        <ActionButton customType="add" size="large" onClick={goToAwsRoute}>
          Connect AWS Account
        </ActionButton>{' '}
        <QuestionIconTooltip title="An AWS integration is required to add an action. SSM needs to be enabled. Create an AWS integration before adding an action." />
      </div>
    )
  }

  const AkvDependencyButtons = () => {
    return (
      <div>
        <ActionButton customType="add" size="large" onClick={goToAkvRoute}>
          Connect Azure Account
        </ActionButton>
        <QuestionIconTooltip title="An Azure integration is required to add an action. Create an Azure integration before adding an action." />
      </div>
    )
  }

  const DependencyButtons = () => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return <AwsDependencyButtons />

      case ActionEnum.AzureKeyVaultPull:
        return <AkvDependencyButtons />

      default:
        return <></>
    }
  }

  const ButtonGroup = () => {
    return <div className={styles.buttonGroup}>{!hasIntegrations() && <DependencyButtons />}</div>
  }

  return !hasIntegrations() ? <ButtonGroup /> : <AddImport actionType={actionType} />
}

import { Avatar } from 'antd'
import React from 'react'
import { UserOutlined } from '@ant-design/icons'

interface Props {
  pictureUrl?: nullable<string>
}

export function UserAvatar(props: Props) {
  const { pictureUrl } = props

  return (
    <Avatar
      shape="square"
      icon={pictureUrl ? undefined : <UserOutlined />}
      src={pictureUrl ? pictureUrl : undefined}
    />
  )
}

import React, { useState } from 'react'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AddParameter } from '../AddParameter'
import { CopyParameter } from '../CopyParameter/CopyParameter'
import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { DeleteParameter } from 'data/parameter/actions'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { NoDeleteNoticeModal } from '../Modals'
import { Parameter } from 'gen/cloudTruthRestApi'
import { UpdateParameterSettings } from '../UpdateParameterSettings'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getCurrentProject } from 'data/project/selectors'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

const EDIT_PARAMETER_SETTINGS = 'editParameterSettings'
const COPY_PARAMETER = 'copyParameter'
const DELETE_PARAMETER = 'deleteParameter'
const OVERRIDE_PARAMETER = 'overrideParameter'

interface Props {
  paramId: Parameter['id']
  maskSecrets: boolean
  disabled?: boolean
  getParameters: () => void
}

export function ParameterRowDropdown(props: Props) {
  const { paramId, maskSecrets, disabled, getParameters } = props
  const parameter = parameterEntitySelectors.selectById(useSelectParameters(), paramId)!
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settingsModalVisible, setSettingsModalVisible] = useState(false)
  const [overrideModalVisible, setOverrideModalVisible] = useState(false)
  const [copyModalVisible, setCopyModalVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const currentProject = useAppSelector(getCurrentProject)!
  const isInherited = parameter.project !== currentProject?.url
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const isOverride = !!parameter?.overrides && currentProject?.name === parameter.project_name

  const successMessage = !isOverride
    ? 'Parameter successfully deleted'
    : 'Override successfully deleted'

  const deleteParameter = () => {
    setLoading(true)

    const handleError = (error: string) => {
      errorToast(error)
      setDeleteVisible(false)
    }

    dispatch(DeleteParameter(parameter.id)).then(({ error }: CustomThunk) => {
      error ? handleError(error.message) : successToast(successMessage)
      // after removing an override, getParameters() to refresh the table with changed parameter
      if (isOverride && !error) {
        getParameters()
      }
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case EDIT_PARAMETER_SETTINGS: {
        setSettingsModalVisible(true)
        break
      }

      case COPY_PARAMETER: {
        setCopyModalVisible(true)
        break
      }

      case DELETE_PARAMETER: {
        setDeleteVisible(true)
        break
      }

      case OVERRIDE_PARAMETER: {
        setOverrideModalVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canAdministrate, [
      { key: COPY_PARAMETER, label: 'Copy Parameter', disabled },
    ]),
    ...conditionalArrayItem(canContribute, [
      { key: EDIT_PARAMETER_SETTINGS, label: 'Edit Parameter Settings', disabled },
      {
        key: DELETE_PARAMETER,
        label: isOverride ? 'Remove Parameter Override' : 'Delete Parameter',
        disabled,
        danger: !disabled ? true : false,
      },

      ...conditionalArrayItem(isInherited, [
        { key: OVERRIDE_PARAMETER, label: 'Override Parameter', disabled: !isInherited },
      ]),
    ]),
  ]

  return (
    <div>
      <NoDeleteNoticeModal
        visible={errorMessage !== ''}
        closeModal={() => setErrorMessage('')}
        errorMessage={errorMessage}
      />
      <UpdateParameterSettings
        paramId={paramId}
        visible={settingsModalVisible}
        closeModal={() => setSettingsModalVisible(false)}
        maskSecrets={maskSecrets}
      />
      <DeleteConfirmModal
        loading={loading}
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deleteParameter}
        subject={`"${parameter.name}"`}
        isOverride={isOverride}
        removePronoun
      />
      <CopyParameter
        visibleFromProps={copyModalVisible}
        parameter={parameter}
        closeOverrideModal={() => setCopyModalVisible(false)}
      />
      <AddParameter
        visibleFromProps={overrideModalVisible}
        isOverride={true}
        closeOverrideModal={() => setOverrideModalVisible(false)}
        parameter={parameter}
      />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Message, markErrorAsRead } from 'data/session/reducer'
import React, { useState } from 'react'

import { Divider } from 'antd'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './Messages.module.scss'
import { useAppDispatch } from 'data/hooks'

interface Props {
  message: Message
}

export function MessageTile(props: Props) {
  const { message } = props
  const [expanded, setExpanded] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const handleOpen = () => {
    setExpanded(true)
    if (!message.read) {
      dispatch(markErrorAsRead(message.id))
    }
  }

  return (
    <>
      <div className={styles.messageTile}>
        {expanded ? (
          <div>
            <p onClick={() => setExpanded(false)}>
              <UpOutlined />
              {message.type}
            </p>
            <p>{message.name}</p>
            <p>{formatDateTime(message.timestamp.toISOString())}</p>
            <p>{message.description}</p>
          </div>
        ) : (
          <div
            className={message.read ? styles.oldMessage : styles.newMessage}
            onClick={handleOpen}
          >
            <DownOutlined />
            {`${message.type}: ${message.description}`}
          </div>
        )}
      </div>
      <Divider className={styles.divider} />
    </>
  )
}

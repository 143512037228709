import {
  ACCOUNT_ID_FIELD,
  AwsAccountFormBody,
  EXTERNAL_ID_FIELD,
  KMS_ID_FIELD,
  ROLE_NAME_FIELD,
} from './AwsAccountFormBody'
import { AwsIntegrationCreate, AwsRegionEnum, AwsServiceEnum } from 'gen/cloudTruthRestApi'
import { Form, useForm } from 'components/Forms'
import React, { useState } from 'react'
import { RegionLocations, checkRegion, regionToDisplayName } from './util'
import {
  allAwsServices,
  getEnabledServices,
  initialServiceCheckboxValues,
} from './AwsServiceCheckboxes'

import { ActionButton } from 'components/ActionButton'
import { AwsRegionsCheckboxes } from './AwsRegionsCheckboxes'
import { Card } from 'antd'
import { CreateAwsIntegration } from 'data/integration/awsIntegration/actions'
import { CustomThunk } from 'data/dataUtils'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import styles from './AddAwsAccount.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  loading?: boolean
}

export function AddAwsAccount(props: Props) {
  const { onCancel, onSuccess } = props
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const usaList = (Object.keys(regionToDisplayName) as AwsRegionEnum[]).filter(
    (region) => checkRegion([region]) === RegionLocations.UnitedStates
  )

  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [checkedList, setCheckedList] = useState(usaList)
  const [loading, setLoading] = useState(false)

  const [form] = useForm()

  const createAwsIntegration = () => {
    const services: AwsServiceEnum[] = []
    allAwsServices.forEach((service) => {
      if (form.getFieldValue(`checkbox_${service}`)) {
        services.push(service)
      }
    })

    const payload = {
      aws_account_id: form.getFieldValue(ACCOUNT_ID_FIELD),
      aws_enabled_regions: checkedList,
      aws_enabled_services: services,
      aws_kms_key_id: form.getFieldValue(KMS_ID_FIELD) || undefined,
      aws_role_name: form.getFieldValue(ROLE_NAME_FIELD),
      aws_external_id: form.getFieldValue(EXTERNAL_ID_FIELD) || undefined,
      resource_tags: form.getFieldValue('resource-tags')
        ? (form.getFieldValue('resource-tags') as Record<string, string>[]).reduce(
            (acc, { key, value }) => ({ ...acc, [key]: value }),
            {}
          )
        : undefined,
      writable: form.getFieldValue('checkbox_writable') ?? false,
    } as AwsIntegrationCreate

    dispatch(CreateAwsIntegration(payload)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
      } else {
        onSuccess()
      }
    })
  }

  return (
    <Form
      form={form}
      initialValues={{ ...initialServiceCheckboxValues([]) }}
      onFinish={createAwsIntegration}
      formProps={{
        onFieldsChange: () => {
          if (getEnabledServices(form).length > 0) {
            setSubmitEnabled(form.isFieldsTouched([ACCOUNT_ID_FIELD, ROLE_NAME_FIELD], true))
          } else {
            setSubmitEnabled(false)
          }
        },
      }}
    >
      <Card className={styles.container}>
        <AwsAccountFormBody />
        <div className={styles.awsRegionContainer}>
          <h3>Region Preference</h3>
          <p className={styles.regionDescription}>
            By default, United States regions will be displayed when selecting a external value. Use
            the Region Preference settings to specify which regions will be displayed when using
            applicable services.
          </p>
          <ShowHideButton
            label="region settings"
            showSettings={showSettings}
            toggleShowSettings={() => setShowSettings((prev) => !prev)}
          />
          {showSettings && (
            <AwsRegionsCheckboxes checkedList={checkedList} setCheckedList={setCheckedList} />
          )}
        </div>

        <div className={styles.footer}>
          <ActionButton onClick={onCancel}>Cancel</ActionButton>
          <ActionButton
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!submitEnabled || checkedList.length < 1}
          >
            Save
          </ActionButton>
        </div>
      </Card>
    </Form>
  )
}

import React, { PropsWithChildren } from 'react'

import { useFeatureGate } from '../hooks'

export function FeatureGate(props: PropsWithChildren<unknown>) {
  const { children } = props
  const { featuresGated } = useFeatureGate()

  return featuresGated ? null : <>{children}</>
}

import {
  CreateValue,
  CreateValueReq,
  DeleteValue,
  GetInheritedParameter,
  PatchValueReq,
  UpdateValue,
} from 'data/parameter/actions'
import { CustomThunk, TypedThunk, idFromUrl, projectIdFromAnyUrl } from 'data/dataUtils'
import { Parameter, Value } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { IntegrationExplorerComponent } from './IntegrationExplorer'
import { UpdateModalFooter } from '../UpdateModalFooter'
import { getCurrentProject } from 'data/project/selectors'
import styles from './ReferencedParameter.module.scss'
import { updateValue } from 'data/parameter/reducer'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

export const JMES_PATH_FIELD = 'jmesPath'

interface Props {
  environmentValue: nullable<Value>
  parameter: Parameter
  showDeleteOverride?: boolean
  setVisible: (v: boolean) => void

  hasValue: boolean
  environmentValueUrl: string
  showSecret?: boolean
  resetShow?: () => void
  setValueDropdownLoading?: (bool: boolean) => void

  isInterpolated: boolean
}

export function ReferencedParameter(props: Props) {
  const {
    parameter,
    environmentValue,
    showDeleteOverride,
    setVisible,
    environmentValueUrl,
    showSecret: show,
    setValueDropdownLoading,
    isInterpolated,
  } = props
  const [form] = useForm()
  const currentIntegrationFileFqn = environmentValue?.external_fqn

  const [pending, setPending] = useState(true)
  const [integrationNodeFqn, setIntegrationNodeFqn] =
    useState<maybe<string>>(currentIntegrationFileFqn)
  const [error] = useState<nullable<string>>(null)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const currentProject = useAppSelector(getCurrentProject)!

  const onDelete = () => {
    if (!environmentValue?.id) {
      errorToast('Unable to delete override')
      return
    }

    setPending(true)

    dispatch(
      DeleteValue({
        valueId: environmentValue.id,
        paramId: parameter.id,
        envUrl: environmentValueUrl,
        environments,
        dontUpdateCache: !!(
          parameter.overrides && projectIdFromAnyUrl(environmentValue.url) === currentProject!.id
        ),
      })
    ).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        successToast('Override successfully deleted')

        if (
          parameter.overrides &&
          projectIdFromAnyUrl(environmentValue.url) === currentProject!.id
        ) {
          dispatch(
            GetInheritedParameter({
              paramId: parameter.id,
              projectId: projectIdFromAnyUrl(parameter.project),
              maskSecret: !show,
              environment: idFromUrl(environmentValueUrl),
            })
          )
            .then(({ payload, error }: TypedThunk<Parameter>) => {
              if (error) {
                errorToast('Unabled to retrieve value')
              } else {
                dispatch(
                  updateValue({
                    value: payload.values_flat[0],
                    parameterId: parameter.id,
                    valueEnvUrl: environmentValueUrl,
                  })
                )
              }
            })
            .then(() => {
              setPending(false)
              setVisible(false)
            })
        } else {
          setVisible(false)
        }
      }
      setPending(false)
    })
  }

  const hideModal = () => setVisible(false)

  const onSave = () => {
    setPending(true)

    if (setValueDropdownLoading) {
      setValueDropdownLoading(true)
    }

    const buildUpdatePayload = () => {
      return {
        paramId: parameter.id,
        valueId: environmentValue!.id,
        maskSecrets: !show,
        envUrl: environmentValueUrl,
        environments,
        value: {
          external: true,
          external_filter: form.getFieldValue(JMES_PATH_FIELD),
          external_fqn: integrationNodeFqn!,
          interpolated: isInterpolated,
        },
      } as PatchValueReq
    }

    const buildCreatePayload = () => {
      return {
        paramId: parameter.id,
        environment: idFromUrl(environmentValueUrl),
        external: true,
        external_filter: form.getFieldValue(JMES_PATH_FIELD),
        external_fqn: integrationNodeFqn!,
        interpolated: isInterpolated,
        envUrl: environmentValueUrl,
        environments,
      } as CreateValueReq
    }

    dispatch(
      environmentValue?.environment === environmentValueUrl &&
        environmentValue.parameter === parameter.url
        ? UpdateValue(buildUpdatePayload())
        : CreateValue(buildCreatePayload())
    ).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        successToast('Value saved')
        setVisible(false)
      }
      if (setValueDropdownLoading) {
        setValueDropdownLoading(false)
      }

      setPending(false)
    })
  }

  return (
    <div className={styles.contentContainer}>
      <IntegrationExplorerComponent
        secret={parameter.secret}
        showSecret={show}
        showExternalSecretValues={!!parameter.secret}
        environmentValue={environmentValue}
        setIntegrationNodeFqn={setIntegrationNodeFqn}
        error={error}
        setPending={setPending}
        form={form}
      />
      <UpdateModalFooter
        onCancel={hideModal}
        onSave={onSave}
        onDelete={onDelete}
        showDeleteOverride={showDeleteOverride}
        pending={pending}
        loading={pending}
      />
    </div>
  )
}

import { Radio, RadioProps } from 'antd'

import { LabelProps } from './Label'
import { LabeledInputItem } from './LabeledInputItem'
import React from 'react'

interface Props {
  label: LabelProps | string
  name: string
  onChange?: RadioProps['onChange']
  options: { value: boolean | string | number; label: string }[]
  disabled?: boolean
}

export function RadioButtons(props: Props) {
  const { options, name, label, onChange, disabled } = props

  return (
    <LabeledInputItem name={name} label={label} required={true}>
      <Radio.Group options={options} onChange={onChange} disabled={disabled} />
    </LabeledInputItem>
  )
}

import { ParameterType, ServiceAccount, User } from 'gen/cloudTruthRestApi'

import { TableColumnType } from 'antd'

// fix me: this type will need to be updated
type OrderByEnum = any

export class OrderBy<RecordType> {
  readonly column: TableColumnType<RecordType>
  readonly direction: OrderByEnum

  constructor(column: TableColumnType<RecordType>, direction: OrderByEnum) {
    this.column = column
    this.direction = direction
  }

  static create<RecordType>(
    column: TableColumnType<RecordType>,
    antSortDirection: maybe<any>
  ): maybe<OrderBy<RecordType>> {
    if (antSortDirection) {
      if (antSortDirection === 'ascend') {
        return new OrderBy(column, 'ASC')
      } else {
        return new OrderBy(column, 'DESC')
      }
    } else {
      return undefined
    }
  }

  static arrayEquals<RecordType>(
    first: OrderBy<RecordType>[],
    second: OrderBy<RecordType>[]
  ): boolean {
    if (first === second) {
      return true
    }

    if (first.length !== second.length) {
      return false
    }

    for (let i = 0; i < first.length; i++) {
      if (!OrderBy.equals(first[i], second[i])) {
        return false
      }
    }

    return true
  }

  static equals<RecordType>(first: OrderBy<RecordType>, second: OrderBy<RecordType>): boolean {
    if (first === second) {
      return true
    }

    if (first.column === second.column && first.direction === second.direction) {
      return true
    }

    return false
  }

  static fromAntSorter<RecordType>(sorter: any): OrderBy<RecordType>[] {
    const sortOptions = Array.isArray(sorter) ? sorter : [sorter]

    return sortOptions.reduce<OrderBy<RecordType>[]>((accumulator, currentValue) => {
      if (currentValue.column) {
        const newOrderBy = OrderBy.create(currentValue.column, currentValue.order)

        if (newOrderBy) {
          return accumulator.concat(newOrderBy as any)
        }
      }

      return accumulator
    }, [])
  }

  // This method takes the decoded query param from the `querystring` module (e.g., what `match.location.query` returns).
  // It does not work on a raw query string.
  static fromQueryParam<RecordType>(params: option<string | string[]>): OrderBy<RecordType>[] {
    if (params) {
      const paramArray = Array.isArray(params) ? params : [params]

      const decoded = paramArray.map((sort) => {
        const direction: OrderByEnum = sort.startsWith('-') ? 'DESC' : 'ASC'
        const fieldName = direction === 'DESC' ? sort.substr(1) : sort

        return new OrderBy<RecordType>({ dataIndex: fieldName }, direction)
      })

      return decoded.filter((orderBy) => orderBy)
    }

    return []
  }

  toAntDirection(): any {
    switch (this.direction) {
      case 'ASC':
        return 'ascend'
      case 'DESC':
        return 'descend'
      default:
        throw new Error(`Unexpected sort order: '${this.direction}'`)
    }
  }

  toQueryParam(): string {
    const prefix = this.direction === 'DESC' ? '-' : ''
    return `${prefix}${this.column.dataIndex}`
  }
}

export const typeSorter = (a: ParameterType, b: ParameterType): number => {
  if (!!a.parent === !!b.parent) {
    return a.name.localeCompare(b.name)
  } else if (a.parent) {
    return 1
  } else {
    return -1
  }
}

export const saNameSorter = (a: ServiceAccount, b: ServiceAccount): number => {
  return a.user.name!.localeCompare(b.user.name!)
}

export const nameSorter = (a: any, b: any): number => {
  return a.name.localeCompare(b.name)
}

export const emailSorter = (a: any, b: any): number => {
  return a.email.localeCompare(b.email)
}

export const renderDisplayName = (member: User): string => {
  const { email, name, type } = member
  if (type === 'service') {
    return `API: ${name}`
  } else {
    return email || ''
  }
}

import { Parameter, ParameterRule, ParameterTypeRule } from 'gen/cloudTruthRestApi'
//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { ParameterTypeRootState, parameterTypeAdapter } from './reducer'

import { RootState } from '../store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from '../hooks'

const selectParameterType = (state: RootState) => state.parameterType

export const getParameterRules = (
  parameter: maybe<Parameter>
): Selector<RootState, Array<ParameterRule | ParameterTypeRule>> =>
  createSelector(selectParameterType, (state): Array<ParameterRule | ParameterTypeRule> => {
    if (parameter) {
      const parameterType = Object.values(state.entities).find(
        (type) => type!.name === parameter.type
      )
      return parameterType?.parent ? parameterType.rules : parameter.rules
    } else {
      return []
    }
  })

export const getParameterBaseType = (
  typeName: maybe<string>
): Selector<RootState, nullable<string>> =>
  createSelector(selectParameterType, (state): nullable<string> => {
    if (typeName) {
      return (
        Object.values(state.entities).find((type) => type!.name === typeName)?.parent_name ||
        typeName
      )
    } else {
      return null
    }
  })

export const parameterTypeEntitySelectors = parameterTypeAdapter.getSelectors()
export const useSelectParameterTypes = (): ParameterTypeRootState => useSelectState().parameterType

//
// Copyright (C) 2020 CloudTruth, Inc.
// All Rights Reserved
//

import { Route, Routes } from 'react-router-dom'

import { AccountDetails } from 'components/AccountDetails'
import { Home } from './Home'
import { NotFound } from 'components/Error'
import { OrganizationLayout } from 'components/Layout'
import { OrganizationRoutes } from 'router/OrganizationRoutes'
import { ProjectRoutes } from 'router/ProjectRoutes'
import React from 'react'
import { RootPaths } from 'router/Router'
import { SwitchOrganizations } from 'components/SwitchOrganizations'

export function App(): JSX.Element {
  return (
    <OrganizationLayout>
      <Routes>
        <Route path={RootPaths.App} element={<Home />} />
        <Route path={RootPaths.Account} element={<AccountDetails />} />
        <Route path={`${RootPaths.Organization}/*`} element={<OrganizationRoutes />} />
        <Route path={`${RootPaths.Projects}/*`} element={<ProjectRoutes />} />
        <Route path={`${RootPaths.App}*`} element={<NotFound />} />
        <Route path={RootPaths.Switch} element={<SwitchOrganizations />} />
      </Routes>
    </OrganizationLayout>
  )
}

import { LockOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './ToggleSecrets.module.scss'

interface Props {
  handleToggleClick: () => void
  showSecretValues: boolean
  disabled?: boolean
  cypress?: string
}

export function ToggleSecrets(props: Props) {
  const { handleToggleClick, showSecretValues, cypress, disabled } = props

  const handleClick = () => {
    if (disabled) {
      return
    }
    handleToggleClick()
  }

  const cypressTestId = cypress ? `${cypress}_secretToggle` : 'secretToggle'

  const classNames = disabled ? styles.disabledShowSecrets : styles.showSecrets

  return (
    <>
      {!showSecretValues ? (
        <span onClick={handleClick} className={classNames} data-cy={cypressTestId}>
          <LockOutlined className={styles.lockOutlined} />
          Show Secrets
        </span>
      ) : (
        <span onClick={handleClick} className={classNames} data-cy={cypressTestId}>
          <LockOutlined className={styles.lockOutlined} />
          Hide Secrets
        </span>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CompareContainer } from 'components/CompareTable'
import { HistoryContainer } from 'components/HistoryTable'
import { NotFound } from 'components/Error'
import { PageLoading } from 'components/PageLoading'
import { ParameterDetailsPage } from 'components/Parameters/ParameterDetails/ParameterDetailsPage'
import { ParameterTable } from 'components/Parameters/ParameterTable'
import { TemplateViewPage } from 'components/Templates/TemplateViewPage/TemplateViewPage'
import { selectProject } from 'data/project/reducer'

enum ProjectPaths {
  TemplatePage = '/templates/:templateId',
  Templates = '/templates',
  ParametersPage = '/parameters/:parameterId',
  Parameters = '/parameters',
  Compare = '/compare',
  History = '/history/*',
  Catch = '/*',
}

export type ProjectParams = {
  id: string
  templateId?: string
  parameterId?: string
}

const projectRoutes = [
  { path: ProjectPaths.ParametersPage, element: <ParameterDetailsPage /> },
  { path: ProjectPaths.Parameters, element: <ParameterTable /> },
  { path: ProjectPaths.Compare, element: <CompareContainer /> },
  { path: ProjectPaths.History, element: <HistoryContainer /> },
  { path: ProjectPaths.Templates, element: <TemplateViewPage /> },
  { path: ProjectPaths.Catch, element: <NotFound /> },
]

export function ProjectRoutes(): JSX.Element {
  const currentId = useAppSelector((state) => state.project.current)
  const projectIds = projectEntitySelectors.selectIds(useSelectProjects())
  const [valid, setValid] = useState<nullable<boolean>>(null)
  const params = useParams<ProjectParams>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // ensure project id in URL is valid and within scope
    const validProject = params.id ? projectIds.includes(params.id) : false

    if (validProject) {
      if (currentId !== params.id) {
        dispatch(selectProject(params.id!))
      }
      setValid(true)
    } else {
      setValid(false)
    }
    // ComponentDidMount functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (valid) {
    return (
      <Routes>
        {projectRoutes.map((routeProps, key) => (
          <Route {...routeProps} key={key} />
        ))}
      </Routes>
    )
  } else if (valid === false) {
    return <NotFound />
  } else {
    return <PageLoading />
  }
}

import React, { PropsWithChildren } from 'react'

import { Modal } from './Modal'

interface Props {
  visible: boolean
  objectName?: string
  afterClose?: () => void
  onCancel?: () => void
  onOk?: () => void
  pending?: boolean
  okText?: string
  footer?: JSX.Element
  width?: string
  isOverride?: boolean
}

export const AddModal = (props: PropsWithChildren<Props>) => {
  const {
    visible,
    children,
    objectName,
    afterClose,
    onCancel,
    onOk,
    pending,
    okText,
    footer,
    width,
    isOverride,
  } = props

  return (
    <Modal
      visible={visible}
      title={isOverride ? `OVERRIDE ${objectName}` : `CREATE NEW ${objectName}`}
      afterClose={afterClose}
      onCancel={onCancel}
      onOk={onOk}
      pending={pending}
      okText={
        isOverride
          ? `Override ${objectName}`
          : okText || (objectName ? `Create ${objectName}` : 'Save')
      }
      footer={footer}
      width={width}
    >
      {children}
    </Modal>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPull, AzureKeyVaultPull, GitHubPull } from 'gen/cloudTruthRestApi'
import { AwsPushWithTags, getAwsPushesWithTagNames } from 'data/actions/awsPush/selectors'
import {
  AzureKeyVaultPushWithTags,
  getAzureKeyVaultPushesWithTagNames,
} from 'data/actions/akvPush/selectors'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import { akvPullEntitySelectors, useSelectAkvPull } from 'data/actions/akvImport/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { awsPullEntitySelectors, useSelectPull } from 'data/actions/awsImport/selectors'
import { githubPullEntitySelectors, useSelectGithubPull } from 'data/actions/githubImport/selectors'
import { pullIds, pushIds } from 'components/Actions/utils'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionEnum } from 'data/actions/utils'
import { GetAwsPulls } from 'data/actions/awsImport/actions'
import { GetAwsPushes } from 'data/actions/awsPush/actions'
import { GetAzureKeyVaultPulls } from 'data/actions/akvImport/actions'
import { GetAzureKeyVaultPushes } from 'data/actions/akvPush/actions'
import { Spin } from 'antd'
import { emDash } from 'lib/valueHelpers'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './Actions.module.scss'
import { useHistoryPush } from 'router/customHooks'

type ActionList = Array<
  AwsPull | AwsPushWithTags | AzureKeyVaultPushWithTags | AzureKeyVaultPull | GitHubPull
>

interface Props {
  setLoading: (bool: boolean) => void
  setHasActions: (bool: boolean) => void
  loading: boolean
}

export function Actions(props: Props) {
  const { setLoading, loading, setHasActions } = props
  const awsIntegrations = awsIntegrationEntitySelectors.selectIds(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectIds(useSelectAkvIntegrations())
  const awsPushes = useAppSelector(getAwsPushesWithTagNames(null))
  const akvPushes = useAppSelector(getAzureKeyVaultPushesWithTagNames())
  const awsPulls = awsPullEntitySelectors.selectAll(useSelectPull())
  const akvPulls = akvPullEntitySelectors.selectAll(useSelectAkvPull())
  const githubPulls = githubPullEntitySelectors.selectAll(useSelectGithubPull())
  const {
    goToAwsPullActionPage,
    goToAwsPushActionPage,
    goToAkvPullActionPage,
    goToGithubPullActionPage,
    goToAkvPushActionPage,
  } = useHistoryPush()
  const dispatch = useAppDispatch()

  const getActionType = (url: string): ActionEnum => {
    if (url.includes('aws')) {
      return url.includes('push') ? ActionEnum.AwsPush : ActionEnum.AwsPull
    }

    if (url.includes('key_vault')) {
      return url.includes('push') ? ActionEnum.AzureKeyVaultPush : ActionEnum.AzureKeyVaultPull
    }

    if (url.includes('github')) {
      return ActionEnum.GithubPull
    }

    return ActionEnum.AwsPull
  }

  const goToActionsPage = (url: string) => {
    const actionType = getActionType(url)

    switch (actionType) {
      case ActionEnum.AwsPull: {
        const { integrationId, actionId } = pullIds(url, actionType)
        goToAwsPullActionPage(integrationId, actionId)
        break
      }

      case ActionEnum.GithubPull: {
        const { integrationId, actionId } = pullIds(url, actionType)
        goToGithubPullActionPage(integrationId, actionId)
        break
      }

      case ActionEnum.AzureKeyVaultPull: {
        const { integrationId, actionId } = pullIds(url, actionType)
        goToAkvPullActionPage(integrationId, actionId)
        break
      }

      case ActionEnum.AwsPush: {
        const { integrationId, actionId } = pushIds(url, actionType)
        goToAwsPushActionPage(integrationId, actionId)
        break
      }

      case ActionEnum.AzureKeyVaultPush: {
        const { integrationId, actionId } = pushIds(url, actionType)
        goToAkvPushActionPage(integrationId, actionId)
        break
      }

      default:
        break
    }
  }

  const actions: ActionList = useMemo(() => {
    return [...awsPushes, ...akvPushes, ...akvPulls, ...awsPulls, ...githubPulls]
      .sort((a, b) => {
        if (
          !b.latest_task?.modified_at ||
          !a.latest_task?.modified_at ||
          !b.modified_at ||
          !a.modified_at
        ) {
          return -1
        }

        return (
          Date.parse(b.latest_task?.modified_at || b.modified_at) -
          Date.parse(a.latest_task?.modified_at || a.modified_at)
        )
      })
      .slice(0, 10)
  }, [awsPushes, akvPulls, awsPulls, akvPushes, githubPulls])

  const reloadActions = useCallback(() => {
    setLoading(true)

    const awsPulls = awsIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAwsPulls(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const awsPushes = awsIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAwsPushes(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const akvPulls = akvIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAzureKeyVaultPulls(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    const akvPushes = akvIntegrations.map(
      (id) =>
        new Promise((resolve) =>
          dispatch(GetAzureKeyVaultPushes(id.toString())).then(() => {
            resolve({})
          })
        )
    )

    Promise.all([...akvPushes, ...akvPulls, ...awsPulls, ...awsPushes]).then(() => {
      setLoading(false)
    })
  }, [awsIntegrations, dispatch, akvIntegrations, setLoading])

  useEffect(() => {
    if (actions.length > 0) {
      setHasActions(true)
    }
    reloadActions()
  }, [reloadActions, actions.length, setHasActions])

  if (loading) {
    return (
      <div className={styles.spinContainer}>
        <Spin data-testid="loading" />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {actions.length > 0 ? (
        actions.map((action) => {
          if (Object.prototype.hasOwnProperty.call(action, 'tags')) {
            const pushAction = action as AwsPushWithTags
            return (
              <div key={pushAction.id}>
                {'Push '}
                <span className={styles.link} onClick={() => goToActionsPage(action.url)}>
                  {pushAction.name}
                </span>
                {` at ${formatDateTime(
                  pushAction.latest_task?.modified_at || pushAction.modified_at
                )} from tags: `}
                <i>{pushAction.tagDisplayNames.join(', ')}</i>
                {` ${emDash} Status: `}
                <b>{pushAction.latest_task?.state ? pushAction.latest_task.state : 'N/A'}</b>
              </div>
            )
          } else {
            const pullAction = action as AwsPull
            return (
              <div key={pullAction.id}>
                {'Import '}
                <span className={styles.link} onClick={() => goToActionsPage(action.url)}>
                  {pullAction.name}
                </span>
                {` at ${formatDateTime(
                  pullAction.latest_task?.modified_at || pullAction.modified_at
                )} ${emDash} Status: `}
                <b>{pullAction.latest_task?.state ? pullAction.latest_task.state : 'N/A'}</b>
              </div>
            )
          }
        })
      ) : (
        <p className={styles.noActions}>No actions to display</p>
      )}
    </div>
  )
}

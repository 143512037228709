import { CustomThunk, environmentIdFromUrl } from 'data/dataUtils'
import { EnvironmentTagUpdate, UpdateTag } from 'data/environment/actions'
//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { FormData, useForm } from 'components/Forms'
import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import { EditModal } from 'components/Modals/EditModal'
import { Tag } from 'gen/cloudTruthRestApi'
import { TagForm } from './TagForm'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  tag: Tag
  visible: boolean
  onClose: () => void
}

export function EditTag(props: Props) {
  const { tag, visible, onClose } = props
  const [timestamp, setTimestamp] = useState<nullable<Dayjs>>(dayjs(tag.timestamp))
  const [loading, setLoading] = useState(false)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()

  // reset time on form open
  useEffect(() => {
    if (visible) {
      setTimestamp(dayjs(tag.timestamp))
      form.setFieldsValue({ name: tag.name, description: tag.description })
    }
  }, [form, tag, visible])

  const onFinish = (formData: FormData) => {
    const { description, immutable } = formData

    const args = {
      envId: environmentIdFromUrl(tag.url),
      id: tag.id,
      name: tag.name,
      description,
      timestamp,
      usage: tag.usage,
      immutable,
    } as unknown as EnvironmentTagUpdate

    setLoading(true)
    dispatch(UpdateTag(args)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        successToast('Tag successfully updated')
        onClose()
      }
      setLoading(false)
    })
  }

  return (
    <>
      <EditModal
        pending={loading}
        visible={visible}
        objectName="Tag"
        afterClose={() => form.resetFields()}
        onCancel={onClose}
        onOk={() => form.submit()}
      >
        <TagForm
          editing
          tag={tag}
          form={form}
          onFinish={onFinish}
          timestamp={timestamp}
          setTimestamp={setTimestamp}
        />
      </EditModal>
    </>
  )
}

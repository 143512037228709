import { ActionButton } from '../ActionButton'
import { Modal } from './Modal'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons/lib'
import styles from './DeleteConfirmModal.module.scss'

interface Props {
  closeModal: () => void
  visible: boolean
  onDelete: () => void
  subject: string
  plural?: boolean
  loading?: boolean
  noConfig?: boolean
  actionWord?: string
  isOverride?: boolean
  isPush?: boolean
  removePronoun?: boolean
}

export function DeleteConfirmModal(props: Props) {
  const {
    actionWord,
    visible,
    closeModal,
    onDelete,
    subject,
    plural,
    loading,
    noConfig,
    isOverride,
    isPush,
    removePronoun,
  } = props

  const checkPlural = (plural: boolean = false, isOverride: boolean = false) =>
    removePronoun && !isOverride ? '' : plural ? 'all' : 'this'

  return (
    <Modal footer={null} visible={visible} width="550px">
      <div className={styles.container}>
        <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
        {isOverride && (
          <>
            <h2>Are you sure you want to remove {checkPlural(plural, true)} override?</h2>
            <p>
              You are attempting to delete an overriding parameter, which will uncover a same-named
              parameter in a parent project that may have different values. It will not affect any
              existing tags.
            </p>
          </>
        )}

        {!isOverride && (
          <h2>
            Are you sure you want to {actionWord || 'delete'} <br /> {checkPlural(plural)} {subject}
            ?
          </h2>
        )}
        {!noConfig && (
          <p>
            This may impact configurations using {checkPlural(plural)} {subject}.
          </p>
        )}

        {isPush && (
          <p>
            Deleting a push action will remove all synced items <br />
            (secret and parameter values) from the push destination! <br />
            The CloudTruth-side will not be affected.
          </p>
        )}

        <div className={styles.buttonContainer}>
          <ActionButton onClick={closeModal}>Cancel</ActionButton>
          <ActionButton
            type="primary"
            danger
            onClick={onDelete}
            loading={loading}
            data-cy={`delete-${subject}`}
          >
            {isOverride ? 'Yes, remove' : `Yes, ${actionWord || 'delete'}`}
          </ActionButton>
        </div>
      </div>
    </Modal>
  )
}

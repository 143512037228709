//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, AsyncJob, terminalStates } from 'data/actions/utils'
import { AwsPull, AwsPush, GitHubPull } from 'gen/cloudTruthRestApi'
import {
  CreateMessage,
  ErrorType,
  addSessionError,
  cancelAsyncPullSubscription,
  setAsyncPullUpdated,
} from 'data/session/reducer'
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { GetEnvironments } from 'data/environment/actions'
import { GetProjects } from 'data/project/actions'
import { LoggerUtils } from 'lib/loggerUtils'
import { TypedThunk } from 'data/dataUtils'
import { authService } from 'lib/authService'
import { getCurrentUser } from 'data/user/selectors'
import { getIntercomHash } from 'data/session/selectors'
import { getRunningPushes as getRunningAkvPushes } from 'data/actions/akvPush/selectors'
import { getRunningAwsPulls } from 'data/actions/awsImport/selectors'
import { getRunningPushes as getRunningAwsPushes } from 'data/actions/awsPush/selectors'
import { getRunningAzureKeyVaultPulls } from 'data/actions/akvImport/selectors'
import { getRunningGithubPulls } from 'data/actions/githubImport/selectors'
import seo from 'lib/seo'
import { trackEvent } from 'lib/analyticsHelpers'
import { useLocation } from 'react-router-dom'
import { useToast } from 'hooks'

export function AppInstrumentation(props: PropsWithChildren<any>) {
  const { children } = props

  /*
    Subscribe to async action updates
    Fetch all of the following actions that are in a non-terminal state,
      - AWS Pushes
      - AWS Pulls
      - GitHub Pulls

    For Pulls, Fetch additional resources that may have been created
    The following resources are not fetched on demand, so we want to go get them here:
      - Projects
      - Environments

    Update the async pull subscription in redux for on demand resources
    Any component that fetches parameters on demand should get a useEffect that listens
    for async pull updates. These actions include:
      - GetParameter
      - GetParameters
      - GetNoValueParameters

    After 10 retries mark the action as stale and remove it from the loop

    Ensure the interval timer and async pull updates are canceled when actions complete
  */
  const runningAkvPushes = useAppSelector(getRunningAkvPushes)
  const runningAkvPulls = useAppSelector(getRunningAzureKeyVaultPulls)
  const runningAwsPushes = useAppSelector(getRunningAwsPushes)
  const runningAwsPulls = useAppSelector(getRunningAwsPulls)
  const runningGithubPulls = useAppSelector(getRunningGithubPulls)

  const [staleJobs, setStaleJobs] = useState<string[]>([])

  const asyncJobTimer = useRef<ReturnType<typeof setInterval>>()
  const counter = useRef<Record<string, number>>({})
  const dispatch = useAppDispatch()
  const { infoToast } = useToast()

  const runningJobs: AsyncJob[] = useMemo(() => {
    return runningAwsPushes
      .concat(runningAwsPulls, runningGithubPulls, runningAkvPushes, runningAkvPulls)
      .filter((action) => !staleJobs.includes(action.actionId))
  }, [
    staleJobs,
    runningAwsPushes,
    runningAwsPulls,
    runningAkvPushes,
    runningGithubPulls,
    runningAkvPulls,
  ])

  // Remove stale jobs if they have completed elsewhere (such as manually)
  useEffect(() => {
    staleJobs.forEach((job, idx) => {
      if (
        !runningAwsPushes
          .concat(runningAwsPulls, runningGithubPulls, runningAkvPushes, runningAkvPulls)
          .find((x) => x.actionId === job)
      ) {
        const newStaleJobs = [...staleJobs]
        newStaleJobs.splice(idx, 1)
        setStaleJobs(newStaleJobs)
      }
    })
  }, [
    runningAwsPushes,
    runningAwsPulls,
    runningGithubPulls,
    staleJobs,
    runningAkvPushes,
    runningAkvPulls,
  ])

  useEffect(() => {
    if (runningJobs.length > 0) {
      // ensure we only have one interval at a time
      asyncJobTimer.current && clearInterval(asyncJobTimer.current)

      // start new interval timer
      asyncJobTimer.current = setInterval(() => {
        runningJobs.forEach((job) => {
          const { action, actionId, integrationId, name, type } = job

          // max of 10 retries per action
          counter.current[actionId] = (counter.current[actionId] || 0) + 1
          if (counter.current[actionId] <= 10) {
            dispatch(action({ integrationId, actionId })).then(
              (response: TypedThunk<AwsPush | AwsPull | GitHubPull>) => {
                // update resources for Pull actions
                if (type !== ActionEnum.AwsPush) {
                  const resources = [GetProjects, GetEnvironments]

                  dispatch(setAsyncPullUpdated())
                  Promise.all(resources.map((resource) => dispatch(resource(null))))
                }

                // notify user when an action completes
                if (terminalStates.includes(response.payload.latest_task?.state)) {
                  // TODO (mwellman17 Jan 11, 2022): Add error detail when the action has them
                  infoToast(`${response.payload.name} has completed`)
                }
              }
            )
          } else {
            // After 10 retries, mark action as stale and create error message for message center
            const message: CreateMessage = {
              id: actionId,
              type: ErrorType.StaleAction,
              description:
                'An action has timed out and has been marked stale. Its status can still be checked manually by clicking the reload icon.',
              name: name,
            }
            dispatch(addSessionError(message))
            setStaleJobs([...staleJobs, actionId])
          }
        })
      }, 5000)
    } else if (asyncJobTimer.current) {
      // Cancel timer, counter, and subscription when all actions complete
      dispatch(cancelAsyncPullSubscription())
      clearInterval(asyncJobTimer.current)
      asyncJobTimer.current = undefined
      counter.current = {}
    }

    return () => asyncJobTimer.current && clearInterval(asyncJobTimer.current)
  }, [dispatch, infoToast, runningJobs, staleJobs])

  // Sentry's context must be set up at the start of every render because Sentry clears it after a
  // request is finished. As such, we can't just set the context when the JWT changes, for instance.
  const user = useAppSelector(getCurrentUser)
  const intercomHash = useAppSelector(getIntercomHash)
  const { pathname } = useLocation()

  // Prevent re-renders of the same page from reporting as new page views. Page refreshes will still
  // get reported as new visits because the `lastMonitoredPage` ref will be reset upon app refresh.
  // We just want to avoid React's rendering lifecycle of components from filling up the event log
  // with actions not explicitly taken by the user.
  const lastMonitoredPage = useRef<maybe<string>>()

  if (lastMonitoredPage.current !== pathname) {
    lastMonitoredPage.current = pathname
    window.analytics.page(pathname)
  }

  useEffect(() => {
    LoggerUtils.setUserInfo(user?.id)
    LoggerUtils.linkLogRocketAndThirdParties()
  }, [user])

  useEffect(() => {
    // Segment recommends making an identify call "upon loading any pages that are accessible by a logged in user":
    // https://segment.com/docs/connections/spec/best-practices-identify/#when-and-how-often-to-call-identify
    //
    // While this may seem aggressive, we have run into cases where the identify call is made before a 3rd party
    // service finishes initializing. Without making another identify call, that user session looks like it belongs
    // to an anonymous user. We don't have a good way to verify when all 3rd party services have been updated with the
    // user information, so in keeping with Segment's guidelines, we err on sending extra requests that will no-op.
    if (user) {
      window.analytics.identify(
        user.id,
        {
          avatar: user.picture_url,
          email: user.email,
          name: user.name,
        },
        {
          integrations: {
            Intercom: {
              user_hash: intercomHash,
            },
          },
        }
      )
    }
  }, [intercomHash, user])

  useEffect(() => {
    const subscription = authService.subscribeToLogoutStarted(() => {
      trackEvent('Session', 'Ended')
    })

    return function cleanup() {
      subscription.stop()
    }
  }, [])

  // This useEffect sets the document title
  useEffect(() => {
    seo({ path: pathname })
  }, [pathname])

  return <>{children}</>
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Tooltip, TooltipProps } from 'antd'

import { HourglassOutlined } from '@ant-design/icons'
import React from 'react'

interface Props {
  title: string | JSX.Element
  placement?: TooltipProps['placement']
  centerSpan?: boolean
  color?: string
}

export function HourGlassTooltip(props: Props) {
  const { title, placement, centerSpan, color } = props

  return (
    <Tooltip placement={placement} color="#434343" title={title}>
      <span
        style={{
          display: centerSpan ? 'flex' : undefined,
          alignItems: centerSpan ? 'center' : undefined,
        }}
      >
        <HourglassOutlined style={{ color }} />
      </span>
    </Tooltip>
  )
}

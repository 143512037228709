//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

interface GroupCreate {
  name: string
  description: string
  users: User['url'][]
}

import { Group, PaginatedGroupList, PatchedGroup, User } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetGroups = createAsyncThunk<PaginatedGroupList, null, ThunkConfig>(
  'group/getGroups',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'groups', null, thunkApi)
)

export const GetGroup = createAsyncThunk<Group, string, ThunkConfig>(
  'group/getGroup',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `groups/${id}`, null, thunkApi)
)

export const CreateGroup = createAsyncThunk<Group, GroupCreate, ThunkConfig>(
  'group/createGroup',
  async (group, thunkApi) => {
    return await handleFetch(Verbs.Post, 'groups', group, thunkApi)
  }
)

export const UpdateGroup = createAsyncThunk<Group, PatchedGroup, ThunkConfig>(
  'group/updateGroup',
  async (group, thunkApi) => await handleFetch(Verbs.Put, `groups/${group.id}`, group, thunkApi)
)

export const DeleteGroup = createAsyncThunk<string, string, ThunkConfig>(
  'group/deleteGroup',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `groups/${id}`, null, thunkApi)
)
export const DeleteAllGroups = createAsyncThunk<string, string, ThunkConfig>(
  'group/deleteAllGroups',
  async (scope, thunkApi) =>
    await handleFetch(Verbs.Delete, `groups/multi`, null, thunkApi, buildQuery({ scope }))
)

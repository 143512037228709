import { DeleteOutlined, MailOutlined } from '@ant-design/icons'
import { Dropdown, Menu, MenuProps } from 'antd'
import React, { PropsWithChildren, useState } from 'react'
import { createRecoveryLink, deleteIdentity } from './utils'

import { DeleteConfirmModal } from 'components/Modals'
import { EditEmail } from './CrudModels/EditEmail/EditEmail'
import { EditName } from './CrudModels/EditName/EditName'
import { IdentityWithSessions } from './ListIdentities/ListIdentities'
// import { getPolicy } from 'data/membership/selectors'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { oryAdmin } from '../ory'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

// import { useAppDispatch, useAppSelector } from 'data/hooks'

interface Props {
  identity: IdentityWithSessions
  updateIdentity: (identity: IdentityWithSessions) => void
  filterIdentities?: (identity: IdentityWithSessions['id']) => void
  setRecoveryLink?: (link: string) => void
}

enum AdminDropdown {
  RecoveryLink = 'recoverLink',
  Email = 'email',
  Name = 'name',
  Delete = 'delete',
}

export function AdminActionsDetailsDropdown(props: PropsWithChildren<Props>) {
  const { identity, updateIdentity, children, filterIdentities, setRecoveryLink } = props
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [nameVisible, setNameVisible] = useState(false)
  const [emailVisible, setEmailVisible] = useState(false)

  const { goToOryAdminRoute } = useHistoryPush()
  const { successToast, errorToast } = useToast()

  const copyRecoverLink = () => {
    createRecoveryLink(oryAdmin, identity.id)
      .then(({ data: { recovery_link: link } }) => {
        navigator.clipboard.writeText(link)
        successToast('Copied to Clipboard')

        if (setRecoveryLink) {
          setRecoveryLink(link)
        }
      })
      .catch((error) => errorToast(error))
  }

  const menuClick: MenuClickEventHandler = ({ key }) => {
    const { Delete, Email, RecoveryLink, Name } = AdminDropdown
    switch (key) {
      case Name: {
        setNameVisible(true)
        break
      }

      case Email: {
        setEmailVisible(true)
        break
      }

      case RecoveryLink: {
        copyRecoverLink()
        break
      }

      case Delete: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items: MenuProps['items'] = [
    { key: AdminDropdown.RecoveryLink, label: 'Create Recovery Link', icon: <MailOutlined /> },
    { key: AdminDropdown.Email, label: 'Change Email' },
    { key: AdminDropdown.Name, label: 'Change Name' },
    { key: AdminDropdown.Delete, label: 'Delete', danger: true, icon: <DeleteOutlined /> },
  ]

  const onDelete = () => {
    deleteIdentity(oryAdmin, identity.id)
      .then(() => {
        filterIdentities ? filterIdentities(identity.id) : goToOryAdminRoute()
        successToast('Ory user successfully deleted.')
      })
      .then(() => setDeleteVisible(false))
      .catch((err) => errorToast(`${err}`))
  }

  return (
    <>
      <DeleteConfirmModal
        closeModal={() => setDeleteVisible(false)}
        visible={deleteVisible}
        onDelete={onDelete}
        subject="user"
      />

      <EditEmail
        visible={emailVisible}
        setVisible={setEmailVisible}
        identity={identity}
        updateState={updateIdentity}
      />

      <EditName
        visible={nameVisible}
        setVisible={setNameVisible}
        identity={identity}
        updateState={updateIdentity}
      />

      <Dropdown
        overlay={<Menu selectable={false} onClick={menuClick} items={items} />}
        trigger={['click']}
      >
        {children}
      </Dropdown>
    </>
  )
}

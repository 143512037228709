import { Input, Radio, Skeleton } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { clearTemplateEvlauatedCache, selectTemplate } from 'data/template/reducer'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AddTemplate } from '../AddTemplate'
import { Details } from 'components/Details'
import { GetParameterNames } from 'data/parameter/actions'
import { GetTemplates } from 'data/template/actions'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import { Reload } from 'components/Reload'
import { SearchOutlined } from '@ant-design/icons'
import { TemplateCardDropdown } from './TemplateCardDropdown'
import { TemplateSelector } from '../TemplateSelector'
import { TemplateViewPageBody } from './TemplateViewPageBody'
import { getPolicy } from 'data/membership/selectors'
import { projectIdFromUrl } from 'data/dataUtils'
import styles from './TemplateViewPage.module.scss'

export function TemplateViewPage() {
  const { id: projectId, name: projectName } = useAppSelector(getCurrentProject)!
  const templateCache = useAppSelector((state) => state.template.cachedProject)
  const currentTemplate = useAppSelector((state) => state.template.current)

  const subscribeToAsyncPulls = useAppSelector((state) => state.session.subscribeToAsyncPulls)
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const [loading, setLoading] = useState(true)
  const [templateVisible, setTemplateVisible] = useState(false)

  const [search, setSearch] = useState<nullable<string>>(null)
  const [liveSearchValue, setLiveSearchValue] = useState(search)
  const [searchCheckbox, setSearchCheckbox] = useState<'template' | 'project'>('template')
  const [bodyLoading, setBodyLoading] = useState(false)
  const [reloading, setReloading] = useState(false)

  const templateEntities = templateEntitySelectors.selectEntities(useSelectTemplates())
  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const dispatch = useAppDispatch()
  const { canContribute } = useAppSelector(getPolicy(null))

  const template = useMemo(() => {
    if (!currentTemplate) {
      return null
    }

    return templateEntities[currentTemplate]!
  }, [currentTemplate, templateEntities])

  const getTemplates = useCallback(() => {
    setReloading(true)
    const getParameterNames = new Promise((resolve) => {
      dispatch(GetParameterNames(projectId)).then(() => resolve({}))
    })
    const getTemplates = new Promise((resolve) => {
      dispatch(GetTemplates(projectId)).then(() => resolve({}))
    })
    Promise.all([getParameterNames, getTemplates]).then(() => {
      setReloading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  const inheritedProjectName = useMemo(() => {
    if (template) {
      const derivedProjectId = projectIdFromUrl(template.url)
      const derivedProjectName = projectEntities[derivedProjectId]!.name
      const readOnly = derivedProjectId !== projectId

      return readOnly ? derivedProjectName : projectName
    } else {
      return null
    }
  }, [template, projectEntities, projectId, projectName])

  useEffect(() => {
    if (!loading && !templates.some(({ id }) => id === currentTemplate)) {
      dispatch(selectTemplate(null))
    }
  }, [templates, currentTemplate, loading, dispatch])

  useEffect(() => {
    if (subscribeToAsyncPulls) {
      dispatch(GetParameterNames(projectId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToAsyncPulls])

  useEffect(() => {
    setLoading(true)
    // Get the parameters for the current project if they're not already in the cache.

    const getParameterNames = new Promise((resolve) => {
      dispatch(GetParameterNames(projectId)).then(() => resolve({}))
    })

    const getTemplates = new Promise((resolve) => {
      templateCache !== projectId
        ? dispatch(GetTemplates(projectId)).then(() => resolve({}))
        : resolve({})
    })

    Promise.all([getParameterNames, getTemplates]).then(() => {
      setLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  // clear preview cache on unmount
  useEffect(() => () => dispatch(clearTemplateEvlauatedCache()), [dispatch])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.searchContainer}>
          <span className={styles.filterBy}>Filter by:</span>
          <Radio.Group
            onChange={(e) => setSearchCheckbox(e.target.value)}
            value={searchCheckbox}
            className={styles.radio}
          >
            <Radio value="template">Template</Radio>
            <Radio value="project">Project</Radio>
          </Radio.Group>
          <Input
            onChange={(e) => {
              if (!e.target.value) {
                setSearch('')
              }

              setLiveSearchValue(e.target.value)
            }}
            className={styles.search}
            placeholder="Search Templates"
            suffix={
              <SearchOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => setSearch(liveSearchValue)}
              />
            }
            onPressEnter={() => setSearch(liveSearchValue)}
            allowClear
          />

          <Reload onClick={getTemplates} loading={reloading} />
        </div>
        <div className={styles.templateListLinkContainer}>
          <a
            href="https://docs.cloudtruth.com/configuration-management/using-basic-templates/sample-templates"
            target="_blank"
            rel="noreferrer"
            className={styles.templateListLink}
          >
            Template Library
          </a>
          <QuestionIconTooltip
            title="Use a quick start template for common formats: JSON, YAML, ConfigMap, TF_Var, Java Properties, Shell ENV and more."
            iconStyles={{ fontSize: '15px', height: '20px', width: '20px', lineHeight: '20px' }}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <AddTemplate
          visibleFromProps={templateVisible}
          onClose={() => setTemplateVisible(false)}
          hide
        />
        <div className={styles.container}>
          <TemplateSelector
            search={search}
            loading={loading}
            searchCheckbox={searchCheckbox}
            reloading={reloading}
            selectedTemplate={template}
            setLoading={setBodyLoading}
          />

          {bodyLoading || loading ? (
            <div className={styles.bodyLoading}>
              <Skeleton paragraph={{ rows: 20 }} active />
            </div>
          ) : template ? (
            <Details
              name={
                inheritedProjectName !== projectName
                  ? `${template.name} (inherited from ${inheritedProjectName})`
                  : template.name
              }
              description={template.description}
              dropdownMenu={
                template && canContribute && projectIdFromUrl(template.url) === projectId ? (
                  <TemplateCardDropdown
                    template={template}
                    onDelete={() => dispatch(selectTemplate(null))}
                  />
                ) : undefined
              }
            >
              <div className={styles.bottomContent}>
                <TemplateViewPageBody template={template} />
              </div>
            </Details>
          ) : (
            <div className={styles.noTemplateSelected}>
              {templates.length < 1 ? (
                canContribute ? (
                  <div className={styles.createTemplate} onClick={() => setTemplateVisible(true)}>
                    Create a template
                  </div>
                ) : (
                  'Create a template'
                )
              ) : (
                'Select a template'
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

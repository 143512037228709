import { Form, FormData, InlineLabelInput, Item } from 'components/Forms'
import { FormInstance, Radio, Space } from 'antd'
import { RoleEnum, User } from 'gen/cloudTruthRestApi'

import { LabelText } from 'components/LabelText'
import React from 'react'
import { getPolicy } from 'data/membership/selectors'
import { useAppSelector } from 'data/hooks'

interface Props {
  form: FormInstance
  loading: boolean
  user: User
  onFinish: (formData: FormData) => void
}

export const NAME = 'name'
export const ROLE = 'role'

export function ChangeRoleForm(props: Props) {
  const { user, form, loading, onFinish } = props
  const { canAdministrate, hasOwnership, canContribute } = useAppSelector(getPolicy(null))

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        [NAME]: user?.name,
        [ROLE]: user?.role || RoleEnum.VIEWER,
      }}
    >
      <InlineLabelInput disabled={true} name={NAME} label="Name" required />

      <div>
        <LabelText
          label="User Permissions"
          uppercase
          text={
            <p>
              Define{' '}
              <a
                href="https://docs.cloudtruth.com/organization-management/user-directory#role-permissions"
                target="_blank"
                rel="noreferrer"
              >
                access permissions
              </a>{' '}
              for this user. These permissions will apply across your organization.
            </p>
          }
        />
        <div>
          <Item name={ROLE}>
            <Radio.Group disabled={loading}>
              <Space direction="vertical">
                <Radio value={RoleEnum.NO_SECRETS} data-cy="radio-option-nosecrets">
                  NoSecretsViewer
                </Radio>
                <Radio value={RoleEnum.VIEWER} data-cy="radio-option-viewer">
                  Viewer
                </Radio>
                <Radio
                  value={RoleEnum.CONTRIB}
                  disabled={!canContribute}
                  data-cy="radio-option-contributor"
                >
                  Contributor
                </Radio>
                <Radio
                  value={RoleEnum.ADMIN}
                  disabled={!canAdministrate}
                  data-cy="radio-option-admin"
                >
                  Admin
                </Radio>
                <Radio value={RoleEnum.OWNER} disabled={!hasOwnership} data-cy="radio-option-owner">
                  Owner
                </Radio>
              </Space>
            </Radio.Group>
          </Item>
        </div>
      </div>
    </Form>
  )
}

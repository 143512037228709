import { FormAlert, FormData, useForm } from 'components/Forms'
import React, { useCallback, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AddEnvironmentForm } from './AddEnvironmentForm'
import { AddModal } from 'components/Modals'
import { CreateEnvironment } from 'data/environment/actions'
import { CustomThunk } from 'data/dataUtils'
import { EnvironmentCreate } from 'gen/cloudTruthRestApi'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  visibleFromProps?: boolean
  onClose?: () => void
  hide?: boolean
  redirect?: boolean
}

export function AddEnvironment(props: Props) {
  const { visibleFromProps, onClose, hide, redirect } = props
  const currentEnvironment = useAppSelector(getCurrentEnvironment)
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())

  const [visible, setVisible] = useState<boolean>(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [pending, setPending] = useState<boolean>(false)

  const [form] = useForm()
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const { goToOrgRoute } = useHistoryPush()

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(() => {
    onClose ? onClose() : setVisible(false)
  }, [onClose])

  const onFinish = (formData: FormData) => {
    setPending(true)

    const parent = formData.parentId
      ? environmentEntities[formData.parentId]!.url
      : currentEnvironment!.url

    const newEnvironment = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      ['copy_rbac']: form.getFieldValue('copy_rbac'),
      parent,
    } as EnvironmentCreate

    dispatch(CreateEnvironment(newEnvironment)).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Environment successfully created')
      setVisible(false)
      setPending(false)
      closeModal()

      if (redirect) {
        goToOrgRoute(OrganizationPaths.Environments)
      }
    })
  }

  const onCancel = () => {
    closeModal()
    form.resetFields()
  }

  const onButtonClick = () => {
    const parentId = currentEnvironment!.id

    form.resetFields()
    form.setFieldsValue({ parentId })
    setVisible(true)
  }

  const afterClose = () => {
    setError(null)
    form.resetFields()
  }

  return (
    <>
      {!hide && (
        <ActionButton customType="add" size="large" onClick={onButtonClick}>
          Add Environment
        </ActionButton>
      )}

      <AddModal
        visible={modalVisible}
        objectName="Environment"
        afterClose={afterClose}
        onCancel={onCancel}
        onOk={() => form.submit()}
        pending={pending}
      >
        {error && <FormAlert message="Environment could not be created." description={error} />}
        <AddEnvironmentForm form={form} onFinish={onFinish} />
      </AddModal>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistoryPush, useRootParams } from 'router/customHooks'

import { ActionButton } from 'components/ActionButton'
import { AdminActionsDetailsDropdown } from 'components/Ory/Admin/AdminActionsDropdown'
import { BackToList } from './BackToList'
import { DownOutlined } from '@ant-design/icons'
import { IdentityWithSessions } from '../ListIdentities'
import { OrganizationLayout } from 'components/Layout'
import { PageLoading } from 'components/PageLoading'
import { UserDetailsCard } from './UserDetailsCard'
import { oryAdmin } from 'components/Ory/ory'
import styles from './UserDetails.module.scss'

export function UserDetails() {
  const identityId = useRootParams()?.identityId
  const [identity, setIdentity] = useState<IdentityWithSessions>()
  const { goToOryAdminRoute } = useHistoryPush()

  useEffect(() => {
    !identityId
      ? goToOryAdminRoute()
      : oryAdmin.getIdentity({ id: identityId }).then(({ data: identityObj }) => {
          oryAdmin.listIdentitySessions({ id: identityId }).then(({ data: sessions }) => {
            setIdentity({ ...identityObj, sessions } as IdentityWithSessions)
          })
        })
  }, [goToOryAdminRoute, identityId])

  if (!identity) {
    return <PageLoading />
  }

  return (
    <OrganizationLayout>
      <div className={styles.container}>
        <div className={styles.detailsContainer}>
          <div className={styles.headerContainer}>
            <BackToList onLinkClick={goToOryAdminRoute} text="Back to Users" />
            <div className={styles.headerWrapper}>
              <div className={styles.nameContainer}>
                <h1>{`${identity?.traits.name.first} ${identity?.traits.name.last}`}</h1>
                <span className={styles.userIdContainer}>
                  user_id: <span className={styles.userId}>{identity?.id}</span>
                </span>
              </div>
              <AdminActionsDetailsDropdown
                identity={identity}
                updateIdentity={(identity) =>
                  setIdentity((prev) => ({ ...identity, sessions: prev?.sessions || [] }))
                }
              >
                <ActionButton type="primary" icon={<DownOutlined className={styles.downArrow} />}>
                  Actions
                </ActionButton>
              </AdminActionsDetailsDropdown>
            </div>
          </div>
          <UserDetailsCard
            identity={identity}
            updateIdentity={(identity) =>
              setIdentity((prev) => ({ ...identity, sessions: prev?.sessions || [] }))
            }
          />
        </div>
      </div>
    </OrganizationLayout>
  )
}

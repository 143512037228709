//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPull, AzureKeyVaultPull, ModeEnum } from 'gen/cloudTruthRestApi'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import {
  DeleteAwsPull,
  SyncAwsPull,
  SyncMappedAwsPull,
  UpdateAwsPull,
} from 'data/actions/awsImport/actions'
import {
  DeleteAzureKeyVaultPull,
  SyncAzureKeyVaultPull,
  SyncMappedAzureKeyVaultPull,
  UpdateAzureKeyVaultPull,
} from 'data/actions/akvImport/actions'
import React, { useState } from 'react'
import {
  SyncGithubPull,
  SyncMappedGithubVaultPull,
  UpdateGithubPull,
} from 'data/actions/githubImport/actions'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionEnum } from 'data/actions/utils'
import { ActionViews } from 'components/Actions/ActionContainer'
import { DeleteConfirmModal } from 'components/Modals'
import { EditImport } from '../EditImport'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { PullType } from './ImportDetailsPage'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { pullIds } from 'components/Actions/utils'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

const DELETE_PULL = 'deletePull'
const UPDATE_PULL = 'updatePull'
const RUN_SYNC = 'runSync'
const DRY_RUN_SYNC = 'dryRunSync'

interface Props {
  refreshPull: () => void
  color?: string
  pull: PullType
  redirect?: boolean
  actionType: ActionEnum
}

export function ImportDetailsDropdown(props: Props) {
  const { refreshPull, color, pull, redirect, actionType } = props
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { integrationId, actionId: importId } = pullIds(pull.url, actionType)

  const { goToActionsRoute } = useHistoryPush()

  const isMapped = pull.mode === ModeEnum.Mapped

  const deletePull = () => {
    setLoading(true)

    const handleError = (error: string) => {
      errorToast(error)
      setDeleteVisible(false)
    }

    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(DeleteAwsPull({ integrationId: integrationId!, actionId: importId! }))
          .then(({ error }: CustomThunk) => {
            if (error) {
              handleError(error.message)
              return
            }
          })

          .then(() => {
            successToast('Import successfully deleted.')

            if (redirect) {
              goToActionsRoute(ActionViews.Import)
            }
          })
        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(DeleteAzureKeyVaultPull({ integrationId: integrationId!, actionId: importId! }))
          .then(({ error }: CustomThunk) => {
            if (error) {
              handleError(error.message)
              return
            }
          })

          .then(() => {
            successToast('Import successfully deleted.')

            if (redirect) {
              goToActionsRoute(ActionViews.Import)
            }
          })
        break

      default:
        handleError('Cannot delete import.')
        break
    }
  }

  const syncPull = () => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(SyncAwsPull({ integrationId: integrationId!, actionId: importId! })).then(
          ({ error }: TypedThunk<string>) => {
            if (error) {
              errorToast(error.message)
              return
            }
            successToast('Sync started - check tasks for status.')
            refreshPull()
          }
        )
        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(
          SyncAzureKeyVaultPull({ integrationId: integrationId!, actionId: importId! })
        ).then(({ error }: TypedThunk<string>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          successToast('Sync started - check tasks for status.')
          refreshPull()
        })
        break

      case ActionEnum.GithubPull:
        dispatch(SyncGithubPull({ integrationId: integrationId!, actionId: importId! })).then(
          ({ error }: TypedThunk<string>) => {
            if (error) {
              errorToast(error.message)
              return
            }
            successToast('Sync started - check tasks for status.')
            refreshPull()
          }
        )

        break

      default:
        break
    }
  }

  const syncMappedPull = (dry_run: boolean): void => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(
          SyncMappedAwsPull({ integrationId: integrationId!, actionId: importId!, dry_run })
        ).then(({ error }: TypedThunk<string>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          successToast('Sync started - check tasks for status.')
          refreshPull()
        })
        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(
          SyncMappedAzureKeyVaultPull({
            integrationId: integrationId!,
            actionId: importId!,
            dry_run,
          })
        ).then(({ error }: TypedThunk<string>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          successToast('Sync started - check tasks for status.')
          refreshPull()
        })
        break

      case ActionEnum.GithubPull:
        dispatch(
          SyncMappedGithubVaultPull({ integrationId: integrationId!, actionId: importId!, dry_run })
        ).then(({ error }: TypedThunk<string>) => {
          if (error) {
            errorToast(error.message)
            return
          }
          successToast('Sync started - check tasks for status.')
          refreshPull()
        })

        break

      default:
        break
    }
  }

  const updatePull = (dryRun: boolean) => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(
          UpdateAwsPull({ pull: { ...pull, dry_run: dryRun }, awsIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPull>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
          }

          setLoading(false)
        })

        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(
          UpdateAzureKeyVaultPull({
            pull: { ...pull, dry_run: dryRun },
            akvIntegrationId: integrationId,
          })
        ).then(({ error }: TypedThunk<AwsPull>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
          }

          setLoading(false)
        })

        break

      case ActionEnum.GithubPull:
        dispatch(
          UpdateGithubPull({
            pull: { ...pull, dry_run: dryRun },
            githubIntegrationId: integrationId,
          })
        ).then(({ error }: TypedThunk<AwsPull>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
          }

          setLoading(false)
        })

        break

      default:
        break
    }
  }

  const menuClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case UPDATE_PULL: {
        setEditVisible(true)
        break
      }

      case DELETE_PULL: {
        setDeleteVisible(true)
        break
      }

      case RUN_SYNC: {
        if (isMapped) {
          syncMappedPull(false)
        } else {
          if (pull.dry_run) {
            updatePull(false)
          } else {
            syncPull()
          }
        }
        break
      }
      case DRY_RUN_SYNC: {
        if (isMapped) {
          syncMappedPull(true)
        } else {
          if (pull.dry_run) {
            syncPull()
          } else {
            updatePull(true)
          }
        }
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canContribute && !isMapped, [
      {
        key: UPDATE_PULL,
        label: 'Edit',
      },
    ]),

    ...conditionalArrayItem(canContribute, [
      {
        key: RUN_SYNC,
        label: 'Sync (Run)',
      },
      {
        key: DRY_RUN_SYNC,
        label: 'Sync (Dry Run)',
      },

      ...conditionalArrayItem(canContribute && !isMapped, [
        {
          key: DELETE_PULL,
          label: 'Delete',
          danger: true,
        },
      ]),
    ]),
  ]

  return (
    <div>
      <EditImport
        visible={editVisible}
        pull={pull! as AwsPull | AzureKeyVaultPull}
        onClose={() => setEditVisible(false)}
        actionType={actionType}
      />
      <DeleteConfirmModal
        loading={loading}
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deletePull}
        removePronoun={true}
        subject={`"${pull.name}"`}
      />
      <MoreIconDropdown menu={{ items, onClick: menuClick }} color={color} />
    </div>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, TableSearch } from 'components/Table'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AddCustomTypeButton } from './AddCustomTypeButton'
import { CustomThunk } from 'data/dataUtils'
import { GetParameterTypes } from 'data/parameterType/actions'
import { PageTitle } from 'components/PageTitle'
import { ParameterType } from 'gen/cloudTruthRestApi'
import { Reload } from 'components/Reload'
import { TableColumnsType } from 'antd'
import { TypeDropdownMenu } from './TypeDropdownMenu'
import { TypePreviewDetails } from './TypesPreviewDetails'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './Types.module.scss'
import { typeSorter } from 'lib/orderBy'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

export function TypesTable() {
  const { canContribute } = useAppSelector(getPolicy(null))
  const allTypes = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())
  const types = allTypes.filter((type) => type.parent)
  const baseTypes = allTypes.filter((type) => type.parent === null)

  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'types' })
  const { getQuery } = useQuery()
  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const [form] = useForm()
  const [search, setSearch] = useState('')

  const filteredTypes = useCallback(() => {
    return types.filter((type) => {
      return type.name.toLowerCase().includes(search.toLowerCase())
    })
  }, [search, types])

  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const getParameterTypes = useCallback(() => {
    setLoading(true)
    dispatch(GetParameterTypes(null)).then(({ error }: CustomThunk) => {
      if (error) {
        setLoading(false)
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  const [columns, setColumns] = useState<TableColumnsType<ParameterType>>([
    {
      title: 'TYPE NAME',
      key: 'name',
      ellipsis: true,
      dataIndex: 'name',
      sorter: typeSorter,
      defaultSortOrder: null,
      width: storedColumnSize(columnSizes, 'name', 200),
      render: (_, type: ParameterType) => (
        <div className={styles.nameContainer}>
          <TypePreviewDetails type={type} />
          <p className={styles.name}>{type.name}</p>
        </div>
      ),
    },
    {
      title: 'DESCRIPTION',
      key: 'description',
      dataIndex: 'description',
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'description', 500),
      render: (_, type: ParameterType) => <p>{type.description}</p>,
    },
    {
      title: 'BASE TYPE',
      key: 'base',
      ellipsis: true,
      dataIndex: 'parent_name',
      width: storedColumnSize(columnSizes, 'base', 150),
      render: (_, type: ParameterType) => <p>{type.parent_name}</p>,
    },
    {
      title: '',
      key: 'actions',
      width: storedColumnSize(columnSizes, 'actions', 100),
      render: (_, type: ParameterType) =>
        type.parent && canContribute ? <TypeDropdownMenu key={type.id} id={type.id} /> : null,
    },
  ])

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'types',
      args: { pageSize },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const BaseTypeDescription = () => {
    return (
      <div className={styles.baseTypeContainer}>
        {baseTypes.map((type) => (
          <p key={type.id}>
            <span className={styles.baseTypeName}>{type.name}:</span> {type.description}
          </p>
        ))}
      </div>
    )
  }

  return (
    <div>
      <PageTitle
        title="Types"
        description="Create custom types to optionally enforce parameter and secret value conventions.
      Types are globally available and referenced in the parameter detail screen."
        link={
          <a
            className={styles.descriptionLink}
            href="https://docs.cloudtruth.com/configuration-management/parameters/rules-and-type"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Types
          </a>
        }
        search={
          <TableSearch
            defaultValue={search || ''}
            updateSearchTerm={setSearch}
            form={form}
            onSearch={() => setPage(1)}
            placeHolder="Search Types"
          />
        }
        buttons={
          <>
            <Reload onClick={getParameterTypes} loading={loading} marginLeft="0px" />
            {canContribute && <AddCustomTypeButton button />}
          </>
        }
      />
      <BaseTypeDescription />

      <Table
        loading={loading}
        columns={resizableColumns(columns, setColumns, currentOrganization.id, 'types')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: filteredTypes().length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={filteredTypes()}
        rowClassName={styles.row}
        rowKey={(type: ParameterType) => type.id}
        className={styles.table}
        locale={{
          emptyText: (
            <div>
              <h4 className={styles.emptyHeader}>You don't have any types stored in CloudTruth</h4>
              <p className={styles.emptyParagraph}>Create a type to store and use.</p>
            </div>
          ),
        }}
      />
    </div>
  )
}

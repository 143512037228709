//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsIntegration, ModeEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { ActionEnum } from 'data/actions/utils'
import { ActionViews } from '../Actions/ActionContainer'
import { ImportTable } from 'components/Actions/Import/ImportTable'
import { PushTable } from '../Actions/Push/PushTable'
import { Radio } from 'antd'
import { ShowHideButton } from '../misc/ShowHideButton'
import styles from './ActionList.module.scss'

interface Props {
  awsIntegration: AwsIntegration
}

export function AwsActionList(props: Props) {
  const { awsIntegration } = props
  const [showActions, setShowActions] = useState<boolean>(false)
  const [actionView, setActionView] = useState<ActionViews>(ActionViews.Push)

  return (
    <div>
      <div className={styles.showContainer}>
        <div className={styles.show}>
          <ShowHideButton
            showSettings={showActions}
            toggleShowSettings={() => setShowActions(!showActions)}
            label={'actions'}
          />
        </div>
      </div>
      {showActions && (
        <div>
          <Radio.Group
            onChange={(e) => setActionView(e.target.value)}
            value={actionView}
            buttonStyle="solid"
            className={styles.radioGroup}
          >
            <Radio.Button value={ActionViews.Push}>Push</Radio.Button>
            <Radio.Button value={ActionViews.Import}>Import</Radio.Button>
          </Radio.Group>
          {actionView === ActionViews.Push && (
            <PushTable actionType={ActionEnum.AwsPush} awsIntegrationId={awsIntegration.id} />
          )}
          {actionView === ActionViews.Import && (
            <ImportTable
              awsIntegrationId={awsIntegration.id}
              actionType={ActionEnum.AwsPull}
              mode={ModeEnum.Pattern}
            />
          )}
        </div>
      )}
    </div>
  )
}

import { ParameterRuleTypeEnum, ParameterType } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { DetailsDescription } from 'components/Details'
import { Divider } from 'antd'
import Editor from 'components/Editor/Editor'
import { LabelText } from 'components/LabelText'
import { PreviewDetails } from 'components/PreviewDetails/PreviewDetails'
import { RULE_TYPE_LABELS } from 'components/Types/Rules/RuleTile'
import styles from './TypesPreviewDetails.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  type: ParameterType
}

export function TypePreviewDetails(props: Props) {
  const { type } = props
  const { name, description, rules, parent_name } = type
  const filteredRules = rules.filter((x) => x.type !== 'regex')
  const regex = rules.filter((x) => x.type === 'regex')
  const rulesReordered = [...filteredRules, ...regex]

  const [visible, setVisible] = useState(false)

  return (
    <PreviewDetails
      name={name}
      modalWidth="600px"
      iconMargin="6.5px 0px 0px 4px"
      visible={visible}
      setVisible={setVisible}
      onClick={() => setVisible(true)}
    >
      <div className={styles.modalContainer}>
        <DetailsDescription description={description} />
        <LabelText label="Base Type:" text={valueOrEmDash(parent_name)} isHorizontal />
        {rules.length > 0 ? (
          <div className={styles.rulesHeader}>
            <Divider>
              <h3>Rules</h3>
            </Divider>
          </div>
        ) : (
          ''
        )}

        {rulesReordered.length > 0
          ? rulesReordered.map((rule) => (
              <div key={rule.id}>
                {rule.type !== 'regex' ? (
                  <LabelText
                    key={rule.id}
                    isHorizontal
                    text={
                      rule.type === ParameterRuleTypeEnum.OneOf
                        ? (rule?.constraints || []).join(', ')
                        : rule.constraint
                    }
                    label={
                      rule.type === ParameterRuleTypeEnum.OneOf
                        ? 'Enum'
                        : `${RULE_TYPE_LABELS[rule.type]}:`
                    }
                  />
                ) : (
                  <div className={styles.regexContainer}>
                    <LabelText label={`${RULE_TYPE_LABELS[rule.type]}:`} text="" />
                    <div className={styles.regex}>
                      <Editor
                        className={styles.editor}
                        body={rule.constraint}
                        readOnly={true}
                        wrapText={true}
                        showGutter={false}
                        highlightActiveLine={false}
                        mode={'python-regex'}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))
          : ''}
      </div>
    </PreviewDetails>
  )
}

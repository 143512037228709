//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import {
  BaseParameterTypes,
  validateInteger,
  validateRegex,
  validateRuleOptions,
} from 'lib/validators'
import { CheckCircleOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons/lib'
import { Form, Item } from 'components/Forms/Form'
import { FormRule, Input, Select } from 'antd'
import { LabeledInputItem, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { ParameterRuleType } from 'data/parameter/actions'
import { ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'
import styles from './RulesTile.module.scss'
import { v4 as uuidV4 } from 'uuid'

interface Props {
  id: string
  parameterType: BaseParameterTypes
  rule?: ParameterRuleType
  rules: ParameterRuleType[]
  setRules: React.Dispatch<ParameterRuleType[]>
  isOverride: boolean
}

export const RULE_TYPE_LABELS: Partial<Record<ParameterRuleTypeEnum, string>> = {
  [ParameterRuleTypeEnum.Max]: 'Maximum',
  [ParameterRuleTypeEnum.MaxLen]: 'Maximum Length',
  [ParameterRuleTypeEnum.Min]: 'Minimum',
  [ParameterRuleTypeEnum.MinLen]: 'Minimum Length',
  [ParameterRuleTypeEnum.Regex]: 'Regular Expression',
}

const validateOpposite = (
  value: string,
  rules: ParameterRuleType[],
  opposite: ParameterRuleTypeEnum
): Promise<string | void> => {
  const oppositeRule = rules.find((rule) => rule.type === opposite)
  if (oppositeRule && oppositeRule.constraint) {
    if ([ParameterRuleTypeEnum.Min, ParameterRuleTypeEnum.MinLen].includes(opposite)) {
      if (!oppositeRule.constraint || parseInt(value) < parseInt(oppositeRule.constraint)) {
        return Promise.reject('Maximum cannot be less than minimum')
      }
    } else {
      if (!oppositeRule.constraint || parseInt(value) > parseInt(oppositeRule.constraint)) {
        return Promise.reject('Minimum cannot be more than maximum')
      }
    }
  }
  return Promise.resolve()
}

const saveRule = (
  type: ParameterRuleTypeEnum,
  constraint: maybe<string>,
  id: string,
  rules: ParameterRuleType[],
  setRules: React.Dispatch<ParameterRuleType[]>
): void => {
  const newRules = [...rules]
  const index = newRules.findIndex((rule) => rule.id === id)

  if (index === -1) {
    if (constraint) {
      newRules.push({ type, constraint, id })
    }
  } else {
    if (constraint) {
      newRules.splice(index, 1, { type, constraint, id })
    } else {
      newRules.splice(index, 1)
    }
  }

  setRules(newRules)
}

const RuleTile = (props: Props) => {
  const { id, parameterType, rule, rules, setRules, isOverride } = props
  const [type, setType] = useState<maybe<ParameterRuleTypeEnum>>(rule?.type)
  const [constraint, setConstraint] = useState<maybe<string>>(rule?.constraint)
  const [form] = useForm()

  const validateConstraint = (rule: FormRule, value: string) => {
    if (type === ParameterRuleTypeEnum.Regex) {
      if (validateRegex(value)) {
        return Promise.resolve()
      } else {
        return Promise.reject('Please enter a valid regular expression')
      }
    } else if (type) {
      if (validateInteger(value)) {
        switch (type) {
          case ParameterRuleTypeEnum.Max:
            return validateOpposite(value, rules, ParameterRuleTypeEnum.Min)

          case ParameterRuleTypeEnum.Min:
            return validateOpposite(value, rules, ParameterRuleTypeEnum.Max)

          case ParameterRuleTypeEnum.MaxLen:
            return validateOpposite(value, rules, ParameterRuleTypeEnum.MinLen)

          case ParameterRuleTypeEnum.MinLen:
            return validateOpposite(value, rules, ParameterRuleTypeEnum.MaxLen)

          default:
            return Promise.resolve()
        }
      } else {
        if (!value) {
          return Promise.resolve()
        }
        return Promise.reject(
          `${(type && RULE_TYPE_LABELS[type]) || 'Constraint'} must be an integer`
        )
      }
    } else {
      return Promise.resolve()
    }
  }

  const disableOption = (option: ParameterRuleTypeEnum): boolean => {
    if (rule?.type !== option && rules.find((rule) => rule.type === option)) {
      return true
    }

    return !validateRuleOptions(parameterType, option)
  }

  const disableDelete = !rule && !form.isFieldsTouched()

  const handleSave = (): void => {
    const id = rule ? rule.id : `new-${uuidV4()}`
    saveRule(type!, constraint, id, rules, setRules)
    if (!rule) {
      reset()
    }
  }

  const handleDelete = (): void => {
    if (rule) {
      saveRule(type!, null, id, rules, setRules)
    } else {
      reset()
    }
  }

  const reset = (): void => {
    setType(null)
    setConstraint(null)
    form.setFieldsValue({ [`${id}-type`]: undefined, [`${id}-constraint`]: undefined })
  }

  const renderSaveButton = () => {
    if (rules.find((rule) => rule.type === type && rule.constraint === constraint)) {
      return (
        <ActionButton disabled>
          <CheckCircleOutlined className={styles.saved} />
        </ActionButton>
      )
    } else {
      return (
        <ActionButton onClick={form.submit} disabled={isOverride}>
          <PlusOutlined className={styles.add} data-cy="save-rule" />
        </ActionButton>
      )
    }
  }

  return (
    <Form
      form={form}
      initialValues={{ [`${id}-type`]: type, [`${id}-constraint`]: constraint }}
      onFinish={handleSave}
    >
      <Input.Group compact className={styles.row}>
        <LabeledInputItem
          required="Type is required"
          name={`${id}-type`}
          label="Rule Type"
          style={{ width: '38%' }}
        >
          <Select
            placeholder="Rule Type"
            data-cy="rule-type-selector"
            onChange={(value: ParameterRuleTypeEnum) => setType(value)}
            onBlur={constraint ? form.submit : () => null}
            disabled={isOverride}
          >
            {Object.values(ParameterRuleTypeEnum).map((item) => (
              <Select.Option key={item} value={item} disabled={disableOption(item)}>
                {RULE_TYPE_LABELS[item]}
              </Select.Option>
            ))}
          </Select>
        </LabeledInputItem>
        <LabeledInputItem
          required="Constraint is required."
          name={`${id}-constraint`}
          label="Constraint"
          rules={[{ validator: validateConstraint }]}
          style={{ width: '38%' }}
        >
          <Input
            onChange={(e) => setConstraint(e.target.value)}
            placeholder="Constraint"
            onBlur={type ? form.submit : () => null}
            disabled={isOverride}
            data-cy="constraint-input"
          />
        </LabeledInputItem>
        <Item className={styles.actions}>
          {renderSaveButton()}
          <ActionButton disabled={disableDelete || isOverride} onClick={handleDelete}>
            <CloseOutlined className={disableDelete ? '' : styles.delete} />
          </ActionButton>
        </Item>
      </Input.Group>
    </Form>
  )
}

export default RuleTile

export const exportsForTesting = {
  validateOpposite,
  saveRule,
}

import { ButtonStyled, ButtonStyledGithub, ButtonStyledGoogle, ButtonStyledMicrosoft } from '..'

import { Divider } from 'antd'
import { NodeInputProps } from 'components/Ory/types'
import React from 'react'
import { getNodeLabel } from '@ory/integrations/ui'
import { makeTitleCase } from 'lib/valueHelpers'
import styles from './oryCustom.module.scss'
import { useLocation } from 'react-router-dom'

export function NodeInputSubmit(props: NodeInputProps) {
  const { node, attributes, setValue, disabled, dispatchSubmit } = props

  const { pathname } = useLocation()

  if (attributes.value === 'github') {
    return (
      <div className={styles.container}>
        <span className={styles.iconStylingGithub}></span>
        <ButtonStyledGithub
          name={attributes.name}
          onClick={(e: React.MouseEvent) => {
            const provider: option<string> = node.group === 'oidc' ? attributes.value : undefined
            // On click, we set this value, and once set, dispatch the submission!
            setValue(attributes.value).then(() => dispatchSubmit(e, provider))
          }}
          type={attributes.type as 'submit'}
          value={attributes.value || ''}
          disabled={attributes.disabled || disabled}
        >
          {makeTitleCase(getNodeLabel(node as any))}
        </ButtonStyledGithub>
      </div>
    )
  }

  if (attributes.value === 'google') {
    return (
      <div className={styles.container}>
        <span className={styles.iconStylingGoogle}></span>
        <ButtonStyledGoogle
          name={attributes.name}
          onClick={(e: React.MouseEvent) => {
            const provider: option<string> = node.group === 'oidc' ? attributes.value : undefined
            // On click, we set this value, and once set, dispatch the submission!
            setValue(attributes.value).then(() => dispatchSubmit(e, provider))
          }}
          type={attributes.type as 'submit'}
          value={attributes.value || ''}
          disabled={attributes.disabled || disabled}
        >
          {makeTitleCase(getNodeLabel(node as any))}
        </ButtonStyledGoogle>
      </div>
    )
  }

  if (attributes.value === 'microsoft') {
    return (
      <>
        <div className={styles.microsoftContainer}>
          <span className={styles.iconStylingMicrosoft}></span>
          <ButtonStyledMicrosoft
            name={attributes.name}
            onClick={(e: React.MouseEvent) => {
              const provider: option<string> = node.group === 'oidc' ? attributes.value : undefined
              // On click, we set this value, and once set, dispatch the submission!
              setValue(attributes.value).then(() => dispatchSubmit(e, provider))
            }}
            type={attributes.type as 'submit'}
            value={attributes.value || ''}
            disabled={attributes.disabled || disabled}
          >
            {makeTitleCase(getNodeLabel(node as any))}
          </ButtonStyledMicrosoft>
        </div>
        {
          /* renders the OR Divider on login and registration but not the settings page */
          pathname === '/login' || pathname === '/registration' ? (
            <span className={styles.dividerContainer}>
              <Divider className={styles.divider}>OR</Divider>
            </span>
          ) : (
            ''
          )
        }
      </>
    )
  }

  return (
    <>
      <ButtonStyled
        name={attributes.name}
        onClick={(e: React.MouseEvent) => {
          const provider: option<string> = node.group === 'oidc' ? attributes.value : undefined
          // On click, we set this value, and once set, dispatch the submission!
          setValue(attributes.value).then(() => dispatchSubmit(e, provider))
        }}
        type={attributes.type as 'submit'}
        value={attributes.value || ''}
        disabled={attributes.disabled || disabled}
      >
        {makeTitleCase(getNodeLabel(node as any))}
      </ButtonStyled>
    </>
  )
}

import { Empty, Tree } from 'antd'
import { GetProjects, UpdateProject } from 'data/project/actions'
import React, { useCallback, useMemo, useState } from 'react'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ComponentLoading } from 'components/PageLoading'
import { CustomThunk } from 'data/dataUtils'
import { NoRbacEnabled } from '../NoRbacEnabled'
import { PageTitle } from 'components/PageTitle'
import { Permissions } from '../Permissions'
import { Reload } from 'components/Reload'
import { buildProjectTree } from 'components/Projects/utils'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './Projects.module.scss'
import { useToast } from 'hooks'

export function Projects() {
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const currentProject = useAppSelector(getCurrentProject)
  const currentOrg = useAppSelector(getCurrentOrganization)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { errorToast } = useToast()

  const getProjects = useCallback(() => {
    setLoading(true)
    dispatch(GetProjects(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  const [selectedProjectId, setSelectedProjectId] = useState<maybe<string>>(currentProject?.id)

  const selectedProject = useMemo(() => {
    return selectedProjectId ? projectEntities[selectedProjectId] : null
  }, [selectedProjectId, projectEntities])

  const [deselect, setDeselect] = useState(false)

  const onSelect = useCallback(
    (envId: maybe<string>) => {
      if (envId) {
        setDeselect(false)
        setSelectedProjectId(projectEntities[envId]?.id || null)
      } else {
        setDeselect(true)
      }
    },
    [setSelectedProjectId, setDeselect, projectEntities]
  )

  const updateAccessControl = (bool: boolean) =>
    dispatch(UpdateProject({ ...selectedProject!, access_controlled: bool }))

  const projectTree = useMemo(() => {
    return buildProjectTree(projectEntities)!
  }, [projectEntities])

  if (!currentOrg?.subscription_features.includes('rbac')) {
    return <NoRbacEnabled />
  }

  return (
    <>
      <PageTitle
        title="Project Access Control"
        description="You can enable Access Control to restrict which users are allowed to access a project.
      When you enable access control you become the owner of that project, at which point other
      users cannot see the project unless you grant them access. When you disable access
      control, all of the granted roles for that project are removed, and it becomes accessible
      to all organization members again."
        buttons={<Reload onClick={getProjects} loading={loading} />}
      />
      {loading ? (
        <ComponentLoading />
      ) : (
        <div className={styles.container}>
          {projectTree.length > 0 ? (
            <>
              <Tree
                defaultExpandAll
                defaultSelectedKeys={[currentProject!.id]}
                className={styles.tree}
                showLine={true && { showLeafIcon: false }}
                treeData={projectTree}
                onSelect={(keys) => onSelect(keys[0]?.toString())}
              />

              {!selectedProject || deselect ? (
                <div className={styles.selectproject}>Select a project</div>
              ) : (
                <Permissions
                  scope={selectedProject.url}
                  accessName={selectedProject.name}
                  updateAccessControl={updateAccessControl}
                  accessControlled={selectedProject.access_controlled}
                />
              )}
            </>
          ) : (
            <Empty className={styles.tree} description="No Projects" />
          )}
        </div>
      )}
    </>
  )
}

import { Label } from 'components/Forms'
import React from 'react'
import { SearchResults } from 'lib/jmesPathHelpers'
import styles from './ReferencedValue.module.scss'

interface Props {
  searchResults: nullable<SearchResults>
}

export function ReferencedValue(props: Props) {
  const { searchResults } = props
  const error = searchResults?.parseError
  const value = searchResults?.result

  const className = error
    ? [styles.referencedValue, styles.error].join(' ')
    : styles.referencedValue

  const getValue = (value: maybe<string>) => {
    if (value) {
      return value[0] === '"' && value[value.length - 1] === '"' ? value.slice(1, -1) : value
    }
  }

  const valueText = () => {
    if (!searchResults) {
      return 'If no JMESPath Selector is input, the entire contents of the selected file will be used as the reference value.'
    }
    return error || getValue(value) || 'No match.'
  }

  return (
    <div>
      <Label text="referenced value" />
      <div data-private="redact" className={className}>
        {valueText()}
      </div>
    </div>
  )
}

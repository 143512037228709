import 'rsuite/dist/rsuite.min.css'
import 'index.css'

import * as Sentry from '@sentry/react'
import * as serviceWorker from 'serviceWorker'

import { FeatureGateProvider, isStagingOrProduction } from 'contexts/FeatureGateContext'

import { AntTheme } from 'components/AntTheme'
import Auth0ProviderWithHistory from 'components/Auth0ProviderWithHistory'
import { AuthGate } from 'components/AuthGate'
import { BrowserRouter } from 'react-router-dom'
import { Integrations } from '@sentry/tracing'
import { ThemeProvider as OryTheme } from 'styled-components'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RewriteFrames } from '@sentry/integrations'
import { Router } from 'router/Router'
import SentryServerError from 'components/Error/SentryServerError'
import { ToastProvider } from 'contexts/ToastContext'
import { ViewHeightProvider } from 'contexts/ViewportHeight'
import { calculateTracesSampleRateValue } from 'lib/sentryHelpers'
import { initializeLogRocket } from 'lib/logrocketHelpers'
import store from 'data/store'
import { theme } from '@ory/themes'
import whyDidYouRender from '@welldone-software/why-did-you-render'

// prettier-ignore
(global as unknown as NodeJS.Global).__rootDir__ = __dirname || process.cwd()

if (process.env.REACT_APP_DEBUG_RERENDERS === 'true') {
  whyDidYouRender(React, {
    include: [/.*(Auth|Provider|Router).*/],
    trackAllPureComponents: false,
    trackHooks: true,
  })
}

initializeLogRocket()

Sentry.init({
  // if REACT_APP_SENTRY_DSN is empty, sentry will disable itself
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ATMOS_ENV,
  integrations: [
    new Integrations.BrowserTracing(),
    new RewriteFrames({ root: (global as unknown as NodeJS.Global).__rootDir__ }),
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  tracesSampleRate: calculateTracesSampleRateValue(isStagingOrProduction),
  ignoreErrors: ['AbortError', 'Auth0UnauthorizedError', 'ResizeObserver loop limit exceeded'],
})

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <Sentry.ErrorBoundary fallback={SentryServerError}>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <AuthGate>
            <ToastProvider>
              <FeatureGateProvider>
                <AntTheme>
                  <ViewHeightProvider>
                    <OryTheme theme={theme}>
                      <Router />
                    </OryTheme>
                  </ViewHeightProvider>
                </AntTheme>
              </FeatureGateProvider>
            </ToastProvider>
          </AuthGate>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </Sentry.ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

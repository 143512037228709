import React from 'react'
import { WarningTwoTone } from '@ant-design/icons'
import styles from './WarningIcon.module.scss'

export function WarningIcon() {
  return (
    <div className={styles.container}>
      <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
    </div>
  )
}

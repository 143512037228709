import { BaseParameterTypes, booleans, validateBoolean, validateInteger } from 'lib/validators'
import {
  CreateValue,
  DeleteValue,
  GetInheritedParameter,
  GetValue,
  UpdateValue,
} from 'data/parameter/actions'
import { CustomThunk, TypedThunk, idFromUrl, projectIdFromAnyUrl } from 'data/dataUtils'
import { Form, Item, Label, useForm } from 'components/Forms'
import { FormRule, Input, Spin, Tooltip } from 'antd'
import { Parameter, ParameterRuleTypeEnum, Value } from 'gen/cloudTruthRestApi'
import React, { useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import {
  getParameterBaseType,
  getParameterRules,
  parameterTypeEntitySelectors,
  useSelectParameterTypes,
} from 'data/parameterType/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import Editor from 'components/Editor/Editor'
import { EnumValueSelector } from './EnumValueSelector'
import PasswordGenerator from 'components/Utility/PasswordGenerator'
import { PreviewTemplate } from 'data/template/actions'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { UpdateModalFooter } from './UpdateModalFooter'
import { getCurrentProject } from 'data/project/selectors'
import styles from './UpdateValue.module.scss'
import { updateValue } from 'data/parameter/reducer'
import { useToast } from 'hooks'

const PARAMETER_VALUE_FIELD = 'value'

const { TextArea } = Input

interface Props {
  environmentValue: nullable<Value>
  parameter: Parameter
  showDeleteOverride?: boolean
  setVisible: (v: boolean) => void
  hasValue: boolean
  environmentValueUrl: string
  showSecret: boolean
  resetShow?: () => void
  setValueDropdownLoading?: (bool: boolean) => void
  onUpdateCallback?: (value: string, oldValue: string) => void
  isInterpolated: boolean
}

export function UpdateValueForm(props: Props) {
  const {
    showDeleteOverride,
    setVisible,
    environmentValue,
    hasValue,
    environmentValueUrl,
    parameter,
    showSecret: show,
    setValueDropdownLoading,
    isInterpolated,
    onUpdateCallback,
  } = props
  const paramId = parameter.id
  const { secret } = parameter
  const rules = useAppSelector(getParameterRules(parameter))
  const type = useAppSelector(getParameterBaseType(parameter.type))
  const { value = null, internal_value: internalValue, id: valueId } = environmentValue || {}
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const currentEnvironment = useAppSelector(getBaseEnvironment)
  const currentProject = useAppSelector(getCurrentProject)
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const types = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())

  const [valueText, setValueText] = useState<string>(internalValue || '')
  const [evaluatedText, setEvaluatedText] = useState<string>('')
  const [showEvaluated, setShowEvaluated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [pending, setPending] = useState(false)
  const [showSecretLoading, setShowSecretLoading] = useState(false)
  const [showSecret, setShowSecret] = useState(isInterpolated ? true : show || false)
  const [showToggle, setShowToggle] = useState(false)
  const [form] = useForm()

  const hideSecret = (showSecret: boolean) => (!hasValue ? false : secret && !showSecret)

  const isEnumType = useMemo(() => {
    const type = types.find((t) => t.name === parameter.type)
    return type?.name === 'enum' || type?.parent_name === 'enum'
  }, [types, parameter])

  const hasSecret = useMemo(() => {
    if (!showEvaluated && isInterpolated) {
      return false
    } else if (showToggle) {
      return true
    } else if (secret && hasValue) {
      return true
    } else {
      return Object.values(parameter.values).some((value) => value?.secret)
    }
  }, [showToggle, secret, hasValue, parameter, showEvaluated, isInterpolated])

  const valueActionCallback = ({ payload, error }: CustomThunk) => {
    if (error) {
      errorToast(error.message)
    } else {
      successToast('Value saved')

      if (onUpdateCallback) {
        onUpdateCallback(payload.id, valueId || '')
      }
    }
  }

  const validateValue = (rule: FormRule, value: string) => {
    switch (type) {
      case BaseParameterTypes.Integer:
        if (!validateInteger(value)) {
          return Promise.reject('Value must be an integer')
        }
        break

      case BaseParameterTypes.Boolean:
        if (!validateBoolean(value)) {
          return Promise.reject(`Value must be a valid boolean, one of: ${booleans.join(', ')}`)
        }
        break

      default:
        break
    }

    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i]
      if (rule.constraint) {
        switch (rule.type) {
          case ParameterRuleTypeEnum.Min:
            if (parseInt(value) < parseInt(rule.constraint)) {
              return Promise.reject(`Value cannot be less than ${rule.constraint}`)
            }
            break

          case ParameterRuleTypeEnum.Max:
            if (parseInt(value) > parseInt(rule.constraint)) {
              return Promise.reject(`Value cannot be greater than ${rule.constraint}`)
            }
            break

          case ParameterRuleTypeEnum.MinLen:
            if (value.length < parseInt(rule.constraint)) {
              return Promise.reject(`Value cannot be shorter than ${rule.constraint} characters`)
            }
            break

          case ParameterRuleTypeEnum.MaxLen:
            if (value.length > parseInt(rule.constraint)) {
              return Promise.reject(`Value cannot be longer than ${rule.constraint} characters`)
            }
            break

          case ParameterRuleTypeEnum.Regex:
            if (!value.match(new RegExp(`^${rule.constraint}$`))) {
              return Promise.reject(`Value must match ${rule.constraint}`)
            }
            break

          default:
            break
        }
      }
    }
    return Promise.resolve()
  }

  const createInternalValue = (internalValue: string) =>
    dispatch(
      CreateValue({
        paramId,
        internal_value: internalValue,
        environment: idFromUrl(environmentValueUrl),
        external: false,
        interpolated: isInterpolated,
        maskSecrets: !show,
        envUrl: environmentValueUrl,
        environments: environments,
      })
    ).then(valueActionCallback)

  const updateInternalValue = (internalValue: string) => {
    if (!valueId) {
      errorToast('Unable to update value')
      return
    }

    return dispatch(
      UpdateValue({
        environments,
        paramId,
        valueId,
        maskSecrets: !show,
        envUrl: environmentValueUrl,
        value: {
          internal_value: internalValue,
          external: false,
          external_filter: '',
          external_fqn: '',
          interpolated: isInterpolated,
        },
      })
    ).then(valueActionCallback)
  }

  const onUpdate = () => {
    if (setValueDropdownLoading) {
      setValueDropdownLoading(true)
    }
    const internalValue = isInterpolated ? valueText : form.getFieldValue('value')
    setPending(true)

    const setValue = () => {
      return environmentValue?.environment === environmentValueUrl &&
        environmentValue.parameter === parameter.url
        ? updateInternalValue(internalValue)
        : createInternalValue(internalValue)
    }

    setValue().then(() => {
      setPending(false)
      setVisible(false)

      if (setValueDropdownLoading) {
        setValueDropdownLoading(false)
      }
    })
  }

  const onDelete = () => {
    if (!environmentValue?.id) {
      errorToast('Unable to delete override')
      return
    }

    dispatch(
      DeleteValue({
        valueId: environmentValue.id,
        paramId,
        envUrl: environmentValueUrl,
        environments,
        dontUpdateCache: !!(
          parameter.overrides && projectIdFromAnyUrl(environmentValue.url) === currentProject!.id
        ),
      })
    ).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Override successfully deleted')

      if (parameter.overrides && projectIdFromAnyUrl(environmentValue.url) === currentProject!.id) {
        dispatch(
          GetInheritedParameter({
            paramId: parameter.id,
            projectId: projectIdFromAnyUrl(parameter.project),
            maskSecret: !showSecret,
            environment: idFromUrl(environmentValueUrl),
          })
        )
          .then(({ payload, error }: TypedThunk<Parameter>) => {
            if (error) {
              errorToast('Unabled to retrieve value')
            } else {
              dispatch(
                updateValue({
                  value: payload.values_flat[0],
                  parameterId: parameter.id,
                  valueEnvUrl: environmentValueUrl,
                })
              )
            }
          })
          .then(() => {
            setPending(false)
            setVisible(false)
          })
      } else {
        setPending(false)
        setVisible(false)
      }
    })
  }

  const handleSecretToggle = () => {
    setShowSecretLoading(true)
    if (environmentValue?.interpolated || (!environmentValue?.id && showEvaluated)) {
      dispatch(
        PreviewTemplate({
          envId: idFromUrl(environmentValueUrl),
          body: valueText || '',
          projectId: environmentValue?.url
            ? projectIdFromAnyUrl(environmentValue.url)
            : currentProject!.id,
          maskSecrets: showSecret,
          templateId: environmentValue?.parameter_id ? environmentValue.parameter_id : parameter.id,
        })
      ).then(({ payload, error }: CustomThunk) => {
        error ? setEvaluatedText(`*CTE*${error.message}`) : setEvaluatedText(payload.body)
        setLoading(false)

        if (payload.has_secret) {
          setShowToggle(true)
        }
        setShowSecretLoading(false)
        setShowSecret((prev) => !prev)
      })
    } else {
      // use value api for static secrets
      if (!valueId) {
        errorToast('Unable to update value')
        return
      }

      setShowSecretLoading(true)

      dispatch(
        GetValue({
          paramId: environmentValue!.parameter_id,
          projectId: projectIdFromAnyUrl(environmentValue!.url),
          maskSecret: showSecret,
          environment: idFromUrl(environmentValueUrl),
          valueId,
        })
      ).then(({ payload, error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setShowSecretLoading(false)
          return
        }
        form.setFieldsValue({ value: payload.value })
        setValueText(payload.value)
        setShowSecret((prev) => !prev)
        setShowSecretLoading(false)
      })
    }
  }

  const fetchInterpolatedValue = () => {
    // if (showEvaluated) {
    //   setShowEvaluated((prev) => !prev)
    //   return
    // }

    setLoading(true)

    // on create form there is no env value
    if (!environmentValue?.id) {
      dispatch(
        PreviewTemplate({
          envId: currentEnvironment.id,
          body: valueText || '',
          projectId: currentProject!.id,
          maskSecrets: showSecret,
          templateId: parameter.id,
        })
      ).then(({ payload, error }: CustomThunk) => {
        error ? setEvaluatedText(`*CTE*${error.message}`) : setEvaluatedText(payload.body)
        setLoading(false)
        setShowSecret((prev) => !prev)
        setShowEvaluated((prev) => !prev)
        if (payload.has_secret && !showEvaluated) {
          setShowToggle(true)
        }
      })

      return
    }

    // use ids from the env value on edit
    dispatch(
      PreviewTemplate({
        envId: idFromUrl(environmentValueUrl),
        body: valueText || '',
        projectId: projectIdFromAnyUrl(environmentValue!.url),
        maskSecrets: showSecret,
        templateId: environmentValue!.parameter_id,
      })
    ).then(({ payload, error }: CustomThunk) => {
      error ? setEvaluatedText(`*CTE*${error.message}`) : setEvaluatedText(payload.body)
      setLoading(false)
      setShowEvaluated((prev) => !prev)
      setShowSecret((prev) => !prev)
      if (payload.has_secret) {
        setShowToggle(true)
      }
    })
  }

  const onEditorChange = (newBody: string): void => {
    if (!showEvaluated) {
      setValueText(newBody)
      form.setFieldsValue({ value: newBody })
    }
  }

  const EnvironmentValueLabel = () => {
    return (
      <div
        className={styles.labelContainer}
        style={isEnumType ? { marginRight: '50px' } : undefined}
      >
        <Label text="Environment Value" />
        {hasSecret && (
          <>
            {showSecretLoading ? (
              <Spin size="small" className={styles.spin} />
            ) : (
              <ToggleSecrets
                showSecretValues={(() => {
                  if (evaluatedText === '*****') {
                    return false
                  } else if (!environmentValue?.interpolated) {
                    return showSecret
                  } else if (value === '*****') {
                    return false
                  } else {
                    return showSecret
                  }
                })()}
                handleToggleClick={handleSecretToggle}
                cypress="updateValue"
              />
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div>
      <Form form={form} initialValues={{ value }} onFinish={onUpdate}>
        <EnvironmentValueLabel />
        {isEnumType ? (
          <EnumValueSelector
            valueText={valueText}
            secret={hideSecret(showSecret)}
            parameter={parameter}
            onChange={(text: string) => {
              if (!hideSecret(showSecret) && text) {
                setValueText(text)
                form.setFieldsValue({ value: text })
              }
            }}
            value={environmentValue?.value || ''}
          />
        ) : (
          <>
            {isInterpolated ? (
              <div className={styles.editorContainer}>
                {loading ? (
                  <Spin size="small" className={styles.editorSpin} />
                ) : (
                  <Editor
                    body={showEvaluated ? evaluatedText || '' : valueText}
                    onChange={onEditorChange}
                    exclude={{ parameters: [parameter.name] }}
                    readOnly={showEvaluated}
                    loadParams={isInterpolated}
                    loadTemps={isInterpolated}
                  />
                )}
              </div>
            ) : (
              <Tooltip
                title={`${
                  secret && !showSecret && hasValue ? 'Click Show Secrets To Edit Value' : ''
                }`}
                placement="bottom"
                overlayStyle={{ display: showSecret ? 'none' : '' }}
              >
                <Item name={PARAMETER_VALUE_FIELD} rules={[{ validator: validateValue }]}>
                  <TextArea
                    autoFocus
                    disabled={hideSecret(showSecret)}
                    onChange={(e) => {
                      setValueText(e.target.value)
                    }}
                    spellCheck="false"
                    data-private="redact"
                    data-cy="value-textarea"
                    className={!hideSecret(showSecret) ? styles.textArea : styles.secretTextArea}
                    rows={hideSecret(showSecret) ? 12 : 5}
                  />
                </Item>
              </Tooltip>
            )}
          </>
        )}
      </Form>
      {!isEnumType && <PasswordGenerator />}

      <UpdateModalFooter
        isEnumType={isEnumType}
        disabled={hideSecret(showSecret)}
        onCancel={() => setVisible(false)}
        onSave={form.submit}
        onDelete={onDelete}
        showDeleteOverride={showDeleteOverride}
        pending={pending}
        evaluatedRaw={fetchInterpolatedValue}
        loading={loading}
        evaluated={showEvaluated}
        interpolated={isInterpolated}
      />
    </div>
  )
}

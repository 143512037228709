//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import React, { useMemo } from 'react'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { ActionButton } from 'components/ActionButton'
import { AddPush } from './AddPush'
import { AddTag } from 'components/Environments/Tags/AddTag'
import { AwsServiceEnum } from 'gen/cloudTruthRestApi'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import { getTags } from 'data/environment/selectors'
import styles from './PushActionButtons.module.scss'
import { useAppSelector } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  actionType: ActionPushEnum
}

export function PushActionButtons(props: Props) {
  const { actionType } = props

  const tags = useAppSelector(getTags())
  const awsIntegrations = awsIntegrationEntitySelectors.selectAll(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectAll(useSelectAkvIntegrations())

  const { goToAwsRoute, goToAkvRoute } = useHistoryPush()

  const allTagsList = useMemo(() => {
    if (tags) {
      return Object.values(tags).flatMap((tags) => tags)
    } else {
      return []
    }
  }, [tags])

  const hasIntegrations = () => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return (
          awsIntegrations.filter((integration) => {
            return (
              integration.aws_enabled_services.includes(AwsServiceEnum.Secretsmanager) ||
              integration.aws_enabled_services.includes(AwsServiceEnum.Ssm)
            )
          }).length > 0
        )

      case ActionEnum.AzureKeyVaultPush:
        return akvIntegrations.length > 0

      default:
        break
    }
  }

  const hasTags = allTagsList.length > 0

  const TagButton = () => {
    return (
      <div>
        <AddTag dontChangeCurrent />{' '}
        <QuestionIconTooltip title="Tags are required to add an action. Create a tag before adding an action." />
      </div>
    )
  }

  const IntegrationButton = () => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return (
          <div>
            <ActionButton customType="add" size="large" onClick={goToAwsRoute}>
              Connect AWS Account
            </ActionButton>{' '}
            <QuestionIconTooltip title="An AWS integration is required to add an action. Either Secrets Manager or SSM need to be enabled, and the integration must have write access. Create an AWS integration before adding an action." />
          </div>
        )

      case ActionEnum.AzureKeyVaultPush:
        return (
          <div>
            <ActionButton customType="add" size="large" onClick={goToAkvRoute}>
              Connect Azure Account
            </ActionButton>{' '}
            <QuestionIconTooltip title="An Azure integration is required to add an action. Create an Azure integration before adding an action." />
          </div>
        )

      default:
        return <></>
    }
  }

  const ButtonGroup = () => {
    return (
      <div className={styles.buttonGroup}>
        {!hasIntegrations() && <IntegrationButton />}

        {!hasTags && <TagButton />}
      </div>
    )
  }

  return !hasIntegrations() || !hasTags ? <ButtonGroup /> : <AddPush actionType={actionType} />
}

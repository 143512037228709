import React from 'react'
import styles from './QuestionIcon.module.scss'

interface Props {
  style?: React.CSSProperties
}

export function QuestionIcon(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.circle} style={props.style}>
        ?
      </div>
    </div>
  )
}

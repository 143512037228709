import { Button, Result } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { LoggerUtils } from 'lib/loggerUtils'
import React from 'react'
import { getCurrentUser } from 'data/user/selectors'
import { useAppSelector } from 'data/hooks'

export const NotFound = () => {
  const { pathname } = useLocation()
  const user = useAppSelector(getCurrentUser)

  LoggerUtils.captureMessage(`Missing page: ${pathname}`, user?.id)

  return (
    <Result
      status="404"
      title="Page Not Found"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  )
}

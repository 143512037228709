import { AwsIntegrationScan, DiscoveryResult } from 'gen/cloudTruthRestApi'
import { FormInstance, Radio, Tooltip } from 'antd'

import { ActionButton } from 'components/ActionButton'
import { ActionEnum } from 'data/actions/utils'
import React from 'react'
import { ScanAwsPull } from 'data/actions/awsImport/actions'
import { ScanAzureKeyVaultPull } from 'data/actions/akvImport/actions'
import { TypedThunk } from 'data/dataUtils'
import styles from './ImportResourceButtons.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

export type RadioValue = 'regex' | 'mustache'

interface Scan extends AwsIntegrationScan {
  integrationId: string
}

interface Props {
  value: RadioValue
  setValue: (value: RadioValue) => void
  setErrorMessage: (message: nullable<string>) => void
  scanRequestObj: Scan
  setScan: (scan?: DiscoveryResult) => void
  displayingScan: boolean
  setScanLoading: (bool: boolean) => void
  scanLoading: boolean
  resetResource: () => void
  resource: string
  form: FormInstance
  actionType: ActionEnum
}

export function ImportResourceButtons(props: Props) {
  const {
    value,
    setValue,
    scanRequestObj,
    setScan,
    displayingScan,
    setScanLoading,
    scanLoading,
    resetResource,
    resource,
    setErrorMessage,
    form,
    actionType,
  } = props

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const handleTest = async () => {
    setScanLoading(true)
    setErrorMessage(null)

    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(ScanAwsPull(scanRequestObj)).then(async (res: TypedThunk<DiscoveryResult>) => {
          const { error, payload } = res
          if (error) {
            errorToast('Error scanning resource')
            setErrorMessage(error.message)
            setScanLoading(false)
            await form.validateFields()
          } else {
            setScan(payload)
            setScanLoading(false)
          }
        })

        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(ScanAzureKeyVaultPull(scanRequestObj)).then(
          async ({ payload, error }: TypedThunk<DiscoveryResult>) => {
            if (error) {
              errorToast('Error scanning resource')
              setErrorMessage(error.message)
              setScanLoading(false)
              await form.validateFields()
              return
            }

            setScan(payload)
            setScanLoading(false)
          }
        )

        break

      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <Radio.Group
        onChange={(v) => {
          setValue(v.target.value)
        }}
        value={value}
        buttonStyle="solid"
        className={styles.radio}
        disabled={displayingScan || scanLoading}
      >
        <Radio.Button value="regex">Regex</Radio.Button>
        <Radio.Button value="mustache">Mustache</Radio.Button>
      </Radio.Group>

      <div className={styles.resetContainer}>
        <>
          {!displayingScan && !scanLoading && (
            <Tooltip title="Replace the resource matcher expression with the original value.">
              <span className={styles.resetResource} onClick={resetResource}>
                Reset
              </span>
            </Tooltip>
          )}
        </>

        {displayingScan ? (
          <ActionButton loading={scanLoading} onClick={() => setScan(undefined)}>
            Cancel
          </ActionButton>
        ) : (
          <>
            {resource.length > 0 && (
              <ActionButton loading={scanLoading} onClick={handleTest}>
                Test
              </ActionButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { EditModal } from 'components/Modals/EditModal'
import { ServiceAccount } from 'gen/cloudTruthRestApi'
import { ServiceAccountForm } from './ServiceAccountForm'
import { TokenValue } from './ServiceAccountList'
import { TypedThunk } from 'data/dataUtils'
import { UpdateServiceAccount } from 'data/serviceAccount/actions'
import { useAppDispatch } from 'data/hooks'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  closeForm: () => void
  setToken?: (token: nullable<TokenValue>) => void
  serviceAccount: ServiceAccount
  visible: boolean
}

export function EditServiceAccount(props: Props) {
  const { closeForm, serviceAccount, visible } = props
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  const updateServiceAccount = async () => {
    const { description, role, owner } = form.getFieldsValue()
    setLoading(true)

    dispatch(UpdateServiceAccount({ id: serviceAccount.id, request: { description, role, owner } }))
      .then(({ error }: TypedThunk<ServiceAccount>) => {
        error ? errorToast(error.message) : successToast('API token successfully updated')
      })
      .then(() => {
        setLoading(false)
        closeForm()
      })
  }

  return (
    <EditModal
      visible={visible}
      objectName="API Token"
      okText={'Update'}
      onCancel={closeForm}
      onOk={form.submit}
      pending={loading}
    >
      <ServiceAccountForm
        form={form}
        loading={loading}
        onCancel={closeForm}
        onFinish={updateServiceAccount}
        serviceAccount={serviceAccount}
      />
    </EditModal>
  )
}

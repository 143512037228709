//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useState } from 'react'

import { ActionButton } from '../ActionButton'
import { CustomTypeModal } from './CustomTypeModal'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  customName?: string
  button?: boolean
  afterCreate?: (option: string) => void
}

export function AddCustomTypeButton(props: Props) {
  const { button, customName, afterCreate } = props
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <>
      {button ? (
        <ActionButton customType="add" size="large" onClick={() => setVisible(true)}>
          Create New Type
        </ActionButton>
      ) : (
        <a
          style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <PlusOutlined /> Add Custom Type
        </a>
      )}
      <CustomTypeModal
        visible={visible}
        setVisible={setVisible}
        customName={customName}
        afterCreate={afterCreate}
      />
    </>
  )
}

import React, { useState } from 'react'

import { ActionEnum } from 'data/actions/utils'
import { ImportTable } from './ImportTable'
import { ModeEnum } from 'gen/cloudTruthRestApi'
import { PageTitle } from 'components/PageTitle'
import { Tabs } from 'antd'
import styles from './ImportTable.module.scss'
import { useLocation } from 'react-router-dom'

const AWS = 'aws'
const AKV = 'azure-key-vault'
const GITHUB = 'github'

export function ImportContainer() {
  const { hash } = useLocation()
  const [tab, setTab] = useState(hash.substring(1) || AWS)

  const awsTab = (
    <>
      <ImportTable actionType={ActionEnum.AwsPull} />
      <ImportTable actionType={ActionEnum.AwsPull} mode={ModeEnum.Mapped} />
    </>
  )

  const akvTab = (
    <>
      <ImportTable actionType={ActionEnum.AzureKeyVaultPull} />
      <ImportTable actionType={ActionEnum.AzureKeyVaultPull} mode={ModeEnum.Mapped} />
    </>
  )

  const githubTab = <ImportTable actionType={ActionEnum.GithubPull} mode={ModeEnum.Mapped} />

  const items = [
    {
      key: AWS,
      label: (
        <div className={styles.tabContainer}>
          <div className={styles.logoWrapper}>
            <div className={styles.logoAWS} />
          </div>
          <h3>AWS</h3>
        </div>
      ),
      children: awsTab,
    },
    {
      key: AKV,
      label: (
        <div className={styles.tabContainer}>
          <div className={styles.logoWrapper}>
            <div className={styles.logoAKV} />
          </div>
          <h3>Azure</h3>
        </div>
      ),
      children: akvTab,
    },
    {
      key: GITHUB,
      label: (
        <div className={styles.tabContainer}>
          <div className={styles.logoWrapper}>
            <div className={styles.logoGITHUB} />
          </div>
          <h3>Github</h3>
        </div>
      ),
      children: githubTab,
    },
  ]

  return (
    <>
      <PageTitle
        title="Import Actions"
        description="Imports allow you to pull in existing parameters from an integration."
      />
      <Tabs
        className={styles.tabs}
        activeKey={tab}
        onChange={(key) => {
          setTab(key)
          window.location.hash = key
        }}
        items={items}
      />
    </>
  )
}

import { CustomThunk, idFromUrl, projectIdFromAnyUrl } from 'data/dataUtils'
import { DeleteValue, GetParameter, GetValue } from 'data/parameter/actions'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Environment, Parameter, Value } from 'gen/cloudTruthRestApi'
import React, { useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Dropdown } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { UpdateValueModal } from '../Parameters/UpdateValue/UpdateValueModal'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getCurrentProject } from 'data/project/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './ValueColumnDropdown.module.scss'
import { updateValue } from 'data/parameter/reducer'
import { useToast } from 'hooks'

enum ValueActions {
  ShowSecret = 'showSecret',
  EvaluateRaw = 'evaluateRaw',
  EditValue = 'editValue',
  DeleteValue = 'deleteValue',
}

interface Props {
  parameter: Parameter
  envValue: nullable<Value>
  environmentUrl: string
  showEvaluated: boolean
  inherited: boolean
  setShowEvaluated: React.Dispatch<React.SetStateAction<boolean>>

  onRemoveCallback?: (value: string) => void
  updateCurrentValue?: (
    payload: Value,
    parameter: Parameter,
    currentEnvironment: Environment
  ) => void

  cyData?: string
}

export function ValueColumnDropdown(props: Props) {
  const {
    parameter,
    envValue,
    environmentUrl,
    updateCurrentValue,
    showEvaluated,
    setShowEvaluated,

    onRemoveCallback,
    inherited,
    cyData,
  } = props

  const [editVisible, setEditVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const baseEnv = useAppSelector(getBaseEnvironment)

  const { id: projectId } = useAppSelector(getCurrentProject)!
  const envProjectId = () => (envValue?.url ? projectIdFromAnyUrl(envValue.url) : projectId)
  const currentEnvironment = useAppSelector(getCurrentEnvironment)

  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())

  const mask = useMemo(() => {
    return envValue?.value === '*****'
  }, [envValue])

  const overrideCount = () => {
    if (parameter) {
      return Object.entries(parameter.values).filter(([env, value]) => {
        return env === value?.environment && env !== baseEnv.url
      })?.length
    }

    return 0
  }

  const hasValue =
    typeof envValue?.value === 'string' || !!envValue?.external_fqn || overrideCount() > 0

  const disableDelete =
    !hasValue ||
    envValue?.environment === baseEnv.url ||
    false ||
    envValue?.environment !== environmentUrl ||
    envProjectId() !== projectId

  const showSecretValue = React.useCallback(() => {
    if (envValue?.id) {
      setLoading(true)
      dispatch(
        GetValue({
          paramId: envValue.parameter_id,
          projectId: projectIdFromAnyUrl(envValue.url),
          maskSecret: !mask,
          valueId: envValue.id,
          evaluate: true,
          environment: currentEnvironment!.id,
        })
      )
        .then(({ payload, error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
            setLoading(false)
            return
          }
          if (updateCurrentValue) {
            updateCurrentValue(payload, parameter, currentEnvironment!)
          }

          dispatch(
            updateValue({ value: payload, parameterId: parameter.id, valueEnvUrl: environmentUrl })
          )
        })
        .then(() => {
          setLoading(false)
        })
    }
  }, [
    dispatch,
    environmentUrl,
    mask,
    currentEnvironment,
    parameter,
    updateCurrentValue,
    errorToast,
    envValue,
  ])

  const removeOverride = () => {
    if (envValue) {
      setLoading(true)
      dispatch(
        DeleteValue({
          valueId: envValue.id,
          paramId: parameter.id,
          envUrl: environmentUrl,
          environments,
          dontUpdateCache: !!(
            parameter.overrides && projectIdFromAnyUrl(envValue.url) === projectId
          ),
        })
      )
        .then(({ error }: CustomThunk) => {
          error ? errorToast(error.message) : successToast('Override successfully deleted')
          if (onRemoveCallback) {
            onRemoveCallback(envValue.id)
            setShowEvaluated(false)
          }
          dispatch(
            GetParameter({
              paramId: parameter.id,
              maskSecret: mask,
              projectId,
              environment: idFromUrl(environmentUrl),
            })
          )
        })
        .then(() => setLoading(false))
    }
  }

  const evaluateRaw = () => {
    if (envValue?.id) {
      setLoading(true)

      dispatch(
        GetValue({
          paramId: envValue.parameter_id,
          projectId: projectIdFromAnyUrl(envValue.url),
          maskSecret: !mask,
          valueId: envValue.id,
          evaluate: !showEvaluated,
          environment: currentEnvironment!.id,
        })
      )
        .then(({ payload, error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
            setLoading(false)
            return
          } else {
            if (updateCurrentValue) {
              updateCurrentValue(payload, parameter, currentEnvironment!)
            } else {
              dispatch(
                updateValue({
                  value: payload,
                  parameterId: parameter.id,
                  valueEnvUrl: environmentUrl,
                })
              )
            }
          }
        })
        .then(() => {
          setLoading(false)
          setShowEvaluated((prev) => !prev)
        })
    }
  }

  const closeEditModal = () => {
    setEditVisible(false)
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case ValueActions.EditValue: {
        setEditVisible(true)
        break
      }

      case ValueActions.DeleteValue: {
        removeOverride()
        break
      }

      case ValueActions.ShowSecret: {
        showSecretValue()
        break
      }

      case ValueActions.EvaluateRaw: {
        evaluateRaw()
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(
      Object.values(parameter.values).some((value) => value?.secret),
      [
        {
          key: ValueActions.ShowSecret,
          label: <span data-cy={`${cyData}-secret-toggle`}>{mask ? 'Show' : 'Hide'}</span>,
        },
      ]
    ),
    ...conditionalArrayItem(!!envValue?.interpolated, [
      {
        key: ValueActions.EvaluateRaw,
        label: (
          <span data-cy={`${cyData}-evaluate`}> {!showEvaluated ? 'Evaluate' : 'Show Raw'}</span>
        ),
        disabled: !hasValue,
      },
    ]),

    ...conditionalArrayItem(!inherited, [
      { key: ValueActions.EditValue, label: hasValue ? 'Edit' : 'Create' },
      {
        key: ValueActions.DeleteValue,
        label: 'Remove Environment Override',
        danger: !disableDelete,
        disabled: disableDelete,
      },
    ]),
  ]

  return (
    <>
      {canContribute && !envValue?.external_error && (
        <>
          {editVisible && (
            <UpdateValueModal
              hideRemoveOverride
              setValueDropdownLoading={setLoading}
              setVisible={closeEditModal}
              hasValue={hasValue}
              environmentValueUrl={environmentUrl}
              parameterId={parameter.id}
              showSecret={!mask}
            />
          )}
          <Dropdown
            menu={{ items, onClick }}
            disabled={loading || items.length === 0}
            trigger={['click']}
          >
            {loading ? (
              <LoadingOutlined className={styles.icon} />
            ) : (
              <DownOutlined
                className={items.length !== 0 ? styles.icon : styles.hideIcon}
                data-cy="valueDropdown"
                data-testid={cyData}
              />
            )}
          </Dropdown>
        </>
      )}
    </>
  )
}

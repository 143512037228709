import { AuditTrail } from 'gen/cloudTruthRestApi'
import Mime from 'mime'

export const curvedDownArrowToLeft = '\u2937'
export const emDash = '\u2014'
export const rightArrow = '\u2192'
export const termsOfServiceUrl = 'https://www.cloudtruth.com/terms-of-service/'

export function valueOrEmDash(value: maybe<string>): string {
  return value || emDash
}

export function truncate(s: string, len: number): string {
  return s.length <= len ? s : s.slice(0, len - 2) + '\u2026'
}

// add acronyms that require capitalization to the capture group, separated by a |
const acronyms = /^(api)$/

export const makeTitleCase = (string: string): string => {
  return string
    .toLowerCase()
    .replace('-', ' ')
    .replace(acronyms, (x) => x.toUpperCase())
    .replace(/(?:^|\s)([a-z])/g, (x) => x.toUpperCase())
}

export const mimeType = (path: string | undefined): string => {
  const mimeType = Mime.getType(path || '')

  if (!mimeType && path?.startsWith('.env')) {
    return 'dotenv'
  }
  if (!mimeType && path?.endsWith('.tfstate')) {
    return 'application/json, json'
  }
  return mimeType ? `${mimeType}, ${mimeType.split('/')[1]}` : ''
}

export const validateString = (value: unknown): nullable<string> => {
  if (typeof value !== 'string' && !value) {
    return null
  }
  return typeof value !== 'string' ? JSON.stringify(value) : value
}

export const actionTense = (action: AuditTrail['action']): string => {
  switch (action) {
    case 'pull':
      return 'imported'

    case 'push':
      return 'pushed'

    default:
      return `${action}d`
  }
}

export const conditionalArrayItem = (policy: boolean, items: any[]) => {
  return policy ? [...items] : []
}

import React, { PropsWithChildren, useMemo } from 'react'
import { Upload, UploadProps } from 'antd'

import { FileUpload } from './Gpt'
import OpenAI from 'openai'
import { handleUpload } from './utils'

const { Dragger: AntDragger } = Upload

interface Props {
  openai: OpenAI
  files: nullable<FileUpload[]>
  setFiles: React.Dispatch<React.SetStateAction<nullable<FileUpload[]>>>
}

export function Dragger(props: PropsWithChildren<Props>) {
  const { children, openai, setFiles } = props

  const draggerProps: UploadProps = useMemo(
    () => ({
      name: 'file',
      multiple: true,
      customRequest: async ({ file }) => {
        const fileResponse = await handleUpload(file, openai)
        setFiles((prev: any) => (prev ? [...prev, fileResponse] : [fileResponse]))
      },
      openFileDialogOnClick: false,

      itemRender: () => {
        return null
      },
    }),
    [openai, setFiles]
  )

  return (
    <AntDragger
      {...draggerProps}
      directory
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        background: 'none',
        textAlign: 'inherit',
        cursor: 'default !important',
      }}
    >
      {children}
    </AntDragger>
  )
}

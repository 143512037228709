//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AuditListParams,
  AuditTrail,
  AuditTrailSummary,
  PaginatedAuditTrailList,
} from 'gen/cloudTruthRestApi'
import { DatePicker, FormInstance, Input, Select, Table, TableProps } from 'antd'
import { Form, LabeledInputItem, useForm } from 'components/Forms'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { formatDateTime, isoDateFormat } from 'lib/dateHelpers'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { ActionButton } from 'components/ActionButton'
import { SearchAuditLog } from 'data/misc/actions'
import { TableTitle } from 'components/TableTitle'
import { TypedThunk } from 'data/dataUtils'
import dayjs from 'dayjs'
import { getCurrentOrganization } from 'data/organization/selectors'
import { renderDisplayName } from 'lib/orderBy'
import styles from './AuditLogTable.module.scss'
import { useToast } from 'hooks'

interface Props {
  summary: AuditTrailSummary
}

interface AuditLogPackage {
  count: number
  name: string
  results: AuditTrail[]
}

const ACTIONS = ['create', 'update', 'delete', 'push']

const OBJECTS = [
  'AwsIntegration',
  'Environment',
  'GitHubIntegration',
  'Membership',
  'Organization',
  'Parameter',
  'Project',
  'Push',
  'ServiceAccount',
  'Tag',
  'Template',
  'User',
]

const handleFileDownload = (
  results: AuditTrail[],
  name: string,
  toast: (m: string) => void,
  setDownloading?: (v: boolean) => void
): void => {
  const log: AuditLogPackage = { name, count: results.length, results }

  const blob = new Blob([JSON.stringify(log, null, 2)], { type: 'json' })

  const file = document.createElement('a'),
    url = URL.createObjectURL(blob)
  file.href = url
  file.download = `${name.replace(' ', '_')}_audit_log.json`
  document.body.appendChild(file)
  file.click()
  setTimeout(() => {
    document.body.removeChild(file)
    window.URL.revokeObjectURL(url)
    toast('Download complete!')
    if (setDownloading) {
      setDownloading(false)
    }
  }, 0)
}

const resetFields = (form: FormInstance, summary: AuditTrailSummary) => {
  form.resetFields()
  form.setFieldsValue({ dateRange: [dayjs(summary.earliest), dayjs(dayjs())] })
}

export function AuditLogTable({ summary }: Props) {
  const [response, setResponse] = useState<PaginatedAuditTrailList>({})
  const [downloading, setDownloading] = useState<boolean>(false)
  const [searching, setSearching] = useState<nullable<boolean>>(null)
  const users = userEntitySelectors.selectAll(useSelectUsers())
  const { name } = useAppSelector(getCurrentOrganization)!
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()

  useEffect(() => {
    resetFields(form, summary)
  }, [form, summary])

  const currentOrganization = useAppSelector(getCurrentOrganization)!

  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'auditLog' })

  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const { getQuery, setQuery } = useQuery()

  const [page_number, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }
    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const buildPayload = (
    page: number = page_number,
    page_size: number = pageSize
  ): AuditListParams => {
    const { dateRange, action, object_type, user_id } = form.getFieldsValue()

    return {
      action,
      earliest: dateRange?.[0]?.startOf('day')?.toISOString(),
      latest: dateRange?.[1]?.endOf('day')?.toISOString(),
      object_type,
      page,
      page_size,
      user_id,
    }
  }

  const handleDownloadClick = () => {
    if (response.count! > 1000) {
      errorToast(
        'Exceeded max file size. Try filtering the log, or contact support for the full organization log.'
      )
    } else {
      setDownloading(true)
      dispatch(SearchAuditLog(buildPayload(undefined, response.count)))
      handleFileDownload(response.results!, name, successToast, setDownloading)
    }
  }

  const handleSearch = (): void => {
    dispatchSearch(1)
    setPage(1)
  }

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'auditLog',
      args: { pageSize },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    dispatchSearch(pageNumber, pageSize)
    setQuery(QueryParamName.Page, pageNumber.toString())
    setQuery(QueryParamName.PageSize, pageSize.toString())
    setPage(pageNumber)
    setPageSize(pageSize)

    setLocalPageSize(pageSize)
  }

  const dispatchSearch = (
    pageNumber: number | undefined = undefined,
    pageSize: number | undefined = undefined
  ): void => {
    setSearching(true)

    dispatch(SearchAuditLog(buildPayload(pageNumber, pageSize))).then(
      ({ error, payload }: TypedThunk<PaginatedAuditTrailList>) => {
        error ? errorToast(error.message) : setResponse(payload)
        setSearching(false)
      }
    )
  }

  const [columns, setColumns] = useState<TableProps<AuditTrail>['columns']>([
    {
      title: 'ACTION',
      key: 'action',
      ellipsis: true,
      align: 'left',
      width: storedColumnSize(columnSizes, 'action', 150),
      dataIndex: 'action',
    },
    {
      title: 'TYPE',
      key: 'object_type',
      ellipsis: true,
      align: 'left',
      width: storedColumnSize(columnSizes, 'object_type', 200),
      dataIndex: 'object_type',
    },
    {
      title: 'NAME',
      key: 'object_name',
      ellipsis: true,
      align: 'left',
      width: storedColumnSize(columnSizes, 'object_name', 250),
      dataIndex: 'object_name',
    },
    {
      title: 'USER',
      key: 'user',
      ellipsis: true,
      align: 'left',
      dataIndex: 'user',
      width: storedColumnSize(columnSizes, 'user', 350),
      render: (_value, log: AuditTrail) => renderDisplayName(log.user),
    },
    {
      title: 'TIME',
      key: 'timestamp',
      ellipsis: true,
      align: 'left',
      width: storedColumnSize(columnSizes, 'timestamp', 150),
      dataIndex: 'timestamp',
      render: (_value, log: AuditTrail) => <p>{formatDateTime(log.timestamp)}</p>,
    },
  ])

  return (
    <div>
      <Form form={form} onFinish={handleSearch}>
        <Input.Group compact className={styles.searchForm}>
          <LabeledInputItem name="action" label="Action" style={{ width: 200 }}>
            <Select allowClear placeholder="Any">
              {ACTIONS.map((action) => (
                <Select.Option key={action} value={action}>
                  {action}
                </Select.Option>
              ))}
            </Select>
          </LabeledInputItem>
          <LabeledInputItem name="object_type" label="Object Type" style={{ width: 200 }}>
            <Select allowClear placeholder="Any">
              {OBJECTS.map((obj) => (
                <Select.Option key={obj} value={obj}>
                  {obj}
                </Select.Option>
              ))}
            </Select>
          </LabeledInputItem>
          <LabeledInputItem name="user_id" label="User" style={{ width: 200 }}>
            <Select allowClear placeholder="Any">
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {renderDisplayName(user)}
                </Select.Option>
              ))}
            </Select>
          </LabeledInputItem>
          <LabeledInputItem name="dateRange" label="Date Range">
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && (current < dayjs(summary.earliest) || current > dayjs())
              }}
              format={isoDateFormat}
              allowClear
            />
          </LabeledInputItem>
          <ActionButton onClick={() => resetFields(form, summary)} style={{ marginTop: '30px' }}>
            Reset
          </ActionButton>
          <ActionButton
            type="primary"
            disabled={!!searching}
            onClick={form.submit}
            style={{ marginTop: '30px' }}
            data-cy="search-button"
          >
            Search
          </ActionButton>

          <ActionButton
            disabled={!response.count || response.count === 0}
            type="default"
            style={{ marginTop: '30px' }}
            loading={downloading}
            onClick={handleDownloadClick}
          >
            Download JSON
          </ActionButton>
        </Input.Group>
      </Form>

      <Table
        title={() => <TableTitle title="Search Results" />}
        columns={resizableColumns(columns!, setColumns, currentOrganization.id, 'auditLog')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: response.count,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page_number,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={searching ? undefined : response.results}
        rowClassName={styles.row}
        rowKey={(log: AuditTrail) => log.id}
        className={styles.table}
        loading={!!searching}
        locale={{
          emptyText: (
            <>
              {!searching && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>
                    {searching === null
                      ? 'Use the form above to search and display the audit log'
                      : 'No logs found with the selected filters'}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  GitHubIntegration,
  GitHubIntegrationCreate,
  IntegrationsGithubDestroyParams,
  PaginatedGitHubIntegrationList,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetGithubIntegrations = createAsyncThunk<
  PaginatedGitHubIntegrationList,
  null,
  ThunkConfig
>(
  'githubIntegration/getGithubIntegrations',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'integrations/github', null, thunkApi)
)

export const GetGithubIntegration = createAsyncThunk<GitHubIntegration, string, ThunkConfig>(
  'githubIntegration/getGithubIntegration',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `integrations/github/${id}`, null, thunkApi)
)

export const CreateGithubIntegration = createAsyncThunk<
  GitHubIntegration,
  GitHubIntegrationCreate,
  ThunkConfig
>(
  'githubIntegration/createGithubIntegration',
  async (githubIntegration, thunkApi) =>
    await handleFetch(Verbs.Post, 'integrations/github', githubIntegration, thunkApi)
)

export const DeleteGithubIntegration = createAsyncThunk<
  string,
  IntegrationsGithubDestroyParams,
  ThunkConfig
>('githubIntegration/deleteGithubIntegration', async (args, thunkApi) => {
  const query = { in_use: args.in_use || 'fail' }
  return await handleFetch(
    Verbs.Delete,
    `integrations/github/${args.id}`,
    null,
    thunkApi,
    buildQuery(query)
  )
})

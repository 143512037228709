//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateServiceAccount,
  DeleteServiceAccount,
  GetServiceAccount,
  GetServiceAccounts,
  ServiceAccountReKey,
  UpdateServiceAccount,
} from './actions'
import { EntityId, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { ServiceAccount } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface ServiceAccountState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const serviceAccountAdapter = createEntityAdapter<ServiceAccount>({
  selectId: (serviceAccount) => serviceAccount.id,
})

const initialState = serviceAccountAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
} as ServiceAccountState)

export type ServiceAccountRootState = EntityState<ServiceAccount> & ServiceAccountState

const serviceAccountSlice = createSlice({
  name: 'serviceAccount',
  initialState,
  reducers: {
    // direct actions
    selectServiceAccount(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    removeServiceAccount(state, action: PayloadAction<EntityId>) {
      serviceAccountAdapter.removeOne(state, action.payload)
    },
    setAllServiceAccount: serviceAccountAdapter.setAll,
    addOneServiceAccount: serviceAccountAdapter.addOne,
    updateOneServiceAccount: serviceAccountAdapter.upsertOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateServiceAccount.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      // remove the apikey
      // eslint-disable-next-line unused-imports/no-unused-vars-ts
      const { apikey, ...serviceAccount } = action.payload
      serviceAccountAdapter.addOne(state, serviceAccount)
    })
    builder.addCase(GetServiceAccounts.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      serviceAccountAdapter.setAll(state, action.payload.results!)
      state.current = null
    })
    builder.addCase(GetServiceAccount.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      serviceAccountAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateServiceAccount.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      serviceAccountAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(ServiceAccountReKey.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      serviceAccountAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteServiceAccount.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      serviceAccountAdapter.removeOne(state, action.payload)
    })
  },
})

export const {
  resetState,
  selectServiceAccount,
  removeServiceAccount,
  setAllServiceAccount,
  addOneServiceAccount,
  updateOneServiceAccount,
} = serviceAccountSlice.actions

export default serviceAccountSlice.reducer

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'

import { ParameterTimelineEntry } from 'gen/cloudTruthRestApi'
import { Table } from 'components/Table'
import { TableProps } from 'antd'
import { formatDateTime } from 'lib/dateHelpers'
import { getCurrentOrganization } from 'data/organization/selectors'
import { useAppSelector } from 'data/hooks'

interface Props {
  timeline: ParameterTimelineEntry[]
  loading: boolean
}

export function ChangeHistoryTable({ timeline, loading }: Props) {
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({
    org: currentOrganization.id,
    pageType: 'parameterTimeline',
  })
  const localPageSize = localSession?.pageSize

  const columnSizes = localSession?.columnSizes

  const { getQuery } = useQuery()

  const [columns, setColumns] = useState<TableProps<ParameterTimelineEntry>['columns']>([
    {
      title: 'ACTION',
      key: 'action',
      ellipsis: true,
      align: 'left',
      dataIndex: 'action',
      width: storedColumnSize(columnSizes, 'action', 200),
      render: (_, timeline) => timeline.history_type,
    },
    {
      title: 'TYPE',
      key: 'object_type',
      ellipsis: true,
      align: 'left',
      width: storedColumnSize(columnSizes, 'object_type', 200),
      dataIndex: 'history_model',
    },
    {
      title: 'USER',
      key: 'user',
      ellipsis: true,
      align: 'left',
      dataIndex: 'modified_by',
      width: storedColumnSize(columnSizes, 'user', 300),
    },
    {
      title: 'TIME',
      key: 'timestamp',
      ellipsis: true,
      align: 'left',
      dataIndex: 'timestamp',
      width: storedColumnSize(columnSizes, 'timestamp', 200),
      render: (_, timeline) => <p>{formatDateTime(timeline.modified_at)}</p>,
    },
  ])

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'parameterTimeline',
      args: { pageSize },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  return (
    <>
      <Table
        columns={resizableColumns(
          columns!,
          setColumns,
          currentOrganization.id,
          'parameterTimeline'
        )}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: timeline.length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : timeline}
        rowKey={(timeline: ParameterTimelineEntry) => timeline.modified_at!}
        loading={loading}
        locale={{
          emptyText: loading,
        }}
      />
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  Membership,
  MembershipCreate,
  PaginatedMembershipList,
  PatchedMembership,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetMemberships = createAsyncThunk<PaginatedMembershipList, null, ThunkConfig>(
  'membership/getMemberships',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'memberships', null, thunkApi)
)

export const GetMembership = createAsyncThunk<Membership, string, ThunkConfig>(
  'membership/getMembership',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `memberships/${id}`, null, thunkApi)
)

export const CreateMembership = createAsyncThunk<Membership, MembershipCreate, ThunkConfig>(
  'membership/createMembership',
  async (membership, thunkApi) => await handleFetch(Verbs.Post, 'memberships', membership, thunkApi)
)

export const UpdateMembership = createAsyncThunk<Membership, PatchedMembership, ThunkConfig>(
  'membership/updateMembership',
  async (membership, thunkApi) =>
    await handleFetch(Verbs.Put, `memberships/${membership.id}`, membership, thunkApi)
)

export const DeleteMembership = createAsyncThunk<string, string, ThunkConfig>(
  'membership/deleteMembership',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `memberships/${id}`, null, thunkApi)
)

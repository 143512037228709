import { Form, FormData, LabeledInputItem, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { EditModal } from 'components/Modals/EditModal'
import { IdentityWithSessions } from 'components/Ory/Admin/ListIdentities/ListIdentities'
import { Input } from 'antd'
import { oryAdmin } from 'components/Ory/ory'
import { updateIdentityName } from 'components/Ory'
import { useToast } from 'hooks'

interface Props {
  visible: boolean
  setVisible: (bool: boolean) => void
  updateState: (identity: IdentityWithSessions) => void
  identity: IdentityWithSessions
}

export function EditName(props: Props) {
  const { visible, setVisible, updateState, identity } = props
  const [pending, setPending] = useState<boolean>(false)
  const [form] = useForm()

  const { errorToast, successToast } = useToast()

  const onFinish = (formValues: FormData) => {
    setPending(true)
    const { first, last } = formValues

    updateIdentityName(oryAdmin, identity, { first, last })
      .then(({ data }) => {
        successToast('Name successfully updated.')
        updateState(data as IdentityWithSessions)
      })
      .catch(errorToast)
      .then(() => {
        setPending(false)
        onCancel()
      })
  }

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <EditModal
        visible={visible}
        objectName="User"
        afterClose={onCancel}
        onCancel={onCancel}
        onOk={() => form.submit()}
        pending={pending}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            first: identity.traits.name.first,
            last: identity.traits.name.last,
          }}
        >
          <LabeledInputItem
            autofocus
            name="first"
            label="First Name"
            required="Please enter first name."
          >
            <Input />
          </LabeledInputItem>

          <LabeledInputItem name="last" label="Last Name" required="Please enter last name.">
            <Input />
          </LabeledInputItem>
        </Form>
      </EditModal>
    </>
  )
}

import React, { PropsWithChildren } from 'react'

import { Modal } from './Modal'

interface Props {
  visible: boolean
  objectName: string
  afterClose?: () => void
  onCancel?: () => void
  onOk?: () => void
  pending?: boolean
  footer?: JSX.Element
  width?: string
  okText?: string
}

export const EditModal = (props: PropsWithChildren<Props>) => {
  const {
    visible,
    children,
    objectName,
    afterClose,
    onCancel,
    onOk,
    pending,
    footer,
    width,
    okText,
  } = props

  return (
    <Modal
      width={width}
      visible={visible}
      title={`EDIT ${objectName}`}
      afterClose={afterClose}
      onCancel={onCancel}
      onOk={onOk}
      pending={pending}
      footer={footer}
      okText={okText}
    >
      {children}
    </Modal>
  )
}

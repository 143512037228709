import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { ActionEnum } from 'data/actions/utils'
import { FormInstance } from 'antd'
import { LabelText } from 'components/LabelText'
import React from 'react'
import { Tag } from 'gen/cloudTruthRestApi'
import { formatAwsPushService } from '../utils'
import { getTags } from 'data/environment/selectors'
import styles from './PreviewAddPush.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  form: FormInstance
  actionType: ActionEnum
}

type TagHash = {
  [key: string]: Tag
}

export function PreviewAddPush(props: Props) {
  const { form, actionType } = props
  const tags = useAppSelector(getTags())!
  const awsIntegrations = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const projects = projectEntitySelectors.selectEntities(useSelectProjects())

  const allTagsHash = () => {
    const hash: TagHash = {}

    Object.values(tags)
      .flatMap((tags) => tags)
      .forEach((tag) => {
        hash[tag.id] = tag
      })

    return hash
  }

  const displayTags = (tags: string[]) => {
    return tags.map((tag) => allTagsHash()[tag].name).join(', ')
  }

  const integrationName = () => {
    const integrationId = form.getFieldValue('integration')

    switch (actionType) {
      case ActionEnum.AwsPush:
        return awsIntegrations[integrationId]!.name

      case ActionEnum.AzureKeyVaultPush:
        return akvIntegrations[integrationId]!.name

      default:
        break
    }
  }

  const displayIncludes = () => {
    const coerceParameters = form.getFieldValue('coerce_parameters')
    const includeParameters = form.getFieldValue('include_parameters')
    const includeSecrets = form.getFieldValue('include_secrets')
    const includeTemplates = form.getFieldValue('include_templates')

    let result = ''
    if (includeParameters != includeSecrets) {
      result += 'Only '
    }

    if (includeParameters) {
      result += 'Parameters'
      if (coerceParameters) {
        result += ' (Coerced)'
      }
    }
    if (includeSecrets) {
      if (includeParameters) {
        result += ' and '
      }
      result += 'Secrets'
    }
    if (includeTemplates) {
      result += ' with Templates'
    }
    return result
  }

  const displayProjectNames = () => {
    const projectIds = form.getFieldValue('projects')
    return projectIds.map((projectId: string) => projects[projectId]?.name).join(', ')
  }

  return (
    <div className={styles.container}>
      <div className={styles.horizontalContainer}>
        <LabelText text={form.getFieldValue('name')} label="Name" />
      </div>
      <br />
      <div className={styles.horizontalContainer}>
        <LabelText text={form.getFieldValue('dry_run') ? 'True' : 'False'} label="Dry Run" />
        <LabelText text={form.getFieldValue('force') ? 'True' : 'False'} label="Force" />
        <LabelText text={form.getFieldValue('local') ? 'True' : 'False'} label="Local" />
      </div>
      <br />
      <div className={styles.horizontalContainer}>
        <LabelText text={displayProjectNames()} label="Projects" />
        <LabelText text={displayIncludes()} label="Pushing" />
      </div>
      <br />
      <LabelText text={displayTags(form.getFieldValue('tags'))} label="Tags" />
      <br />
      <LabelText text={integrationName()} label="Integration" />
      <br />
      {actionType === ActionEnum.AwsPush && (
        <div className={styles.labelContainer}>
          <LabelText
            text={formatAwsPushService(form.getFieldValue('service')[0])}
            label="Service"
          />
          <LabelText text={form.getFieldValue('region')[0]} label="Region" />
        </div>
      )}

      <br />
      <LabelText text={form.getFieldValue('resource')} label="Resource Name" />
    </div>
  )
}

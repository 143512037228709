import React, { useState } from 'react'

import { InviteMembersEmailForm } from './InviteMembersEmailForm'
import { RoleEnum } from 'gen/cloudTruthRestApi'
import { SetMemberRoles } from './SetMemberRoles'
import { SimpleLayout } from 'components/Layout'

export interface NewInvite {
  role: RoleEnum
  email: string
}

export function InviteMembers() {
  const [invitations, setInvitations] = useState<NewInvite[]>([])
  const [showMemberRoles, setShowMemberRoles] = useState(false)

  return (
    <SimpleLayout centerContent>
      {showMemberRoles ? (
        <SetMemberRoles
          invitations={invitations}
          setInvitations={setInvitations}
          showEmailForm={() => setShowMemberRoles(false)}
        />
      ) : (
        <InviteMembersEmailForm
          setNewInvitations={setInvitations}
          showMemberRoles={() => setShowMemberRoles(true)}
          newInvitations={invitations}
        />
      )}
    </SimpleLayout>
  )
}

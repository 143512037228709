import { Details, DetailsDate } from 'components/Details'
import { Group, User } from 'gen/cloudTruthRestApi'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { GroupDetailsDropdown } from './GroupDetailsDropdown'
import { LabelText } from 'components/LabelText'
import React from 'react'
import { Typography } from 'antd'
import { getPolicy } from 'data/membership/selectors'
import { idFromUrl } from 'data/dataUtils'
import styles from './GroupDetails.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  group: Group
}

export function GroupDetails(props: Props) {
  const { group } = props
  const { name, description, users, modified_at, created_at } = group
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const userEntities = userEntitySelectors.selectEntities(useSelectUsers())

  const formatUsers = (userUrls: User['url'][]) => {
    return userUrls
      .map((url) => {
        const userId = idFromUrl(url)

        return userEntities[userId]?.name
      })
      .join(', ')
  }

  const dropdownMenu = canAdministrate ? <GroupDetailsDropdown group={group} /> : undefined

  return (
    <Details
      name={name || ''}
      dropdownMenu={dropdownMenu}
      description={description}
      styles={{ marginBottom: '24px' }}
      headerStyles={{ alignItems: 'flex-start' }}
    >
      <div className={styles.container}>
        <div className={styles.bottomContent}>
          <DetailsDate updatedAt={modified_at} createdAt={created_at} />

          <LabelText
            text={
              <div className={styles.paragraphContainer}>
                <Typography.Paragraph className={styles.paragraph} ellipsis={{ rows: 8 }}>
                  {formatUsers(users)}
                </Typography.Paragraph>
              </div>
            }
            label="Users"
          />
        </div>
      </div>
    </Details>
  )
}

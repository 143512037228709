import { Alert } from 'antd'
import React from 'react'
import styles from './FormAlert.module.scss'

interface Props {
  description: string
  message: string
}

export function FormAlert(props: Props) {
  const { message, description } = props
  return (
    <Alert
      message={message}
      description={description}
      type="error"
      showIcon
      className={styles.alert}
    />
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CopyProjectParameter,
  CreateParameter,
  CreateRule,
  CreateValue,
  DeleteParameter,
  DeleteRule,
  DeleteValue,
  GetPaginatedParameters,
  GetParameter,
  GetParameters,
  GetValues,
  UpdateParameter,
  UpdateRule,
  UpdateValue,
} from './actions'
import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Parameter, Value } from 'gen/cloudTruthRestApi'
import { epoch, idFromUrl } from 'data/dataUtils'

interface ParameterState {
  cacheLastChanged: typeof epoch
  cachedProject: nullable<string>
  current: nullable<string>
  count?: number
}

export interface UpdateValueObj {
  parameterId: string
  valueEnvUrl: string
  value: Value
}

export type ParameterRootState = EntityState<Parameter> & ParameterState

export const parameterAdapter = createEntityAdapter<Parameter>({
  selectId: (param) => param.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const initialState = parameterAdapter.getInitialState({
  cachedProject: null, // the project ID for the current cache of parameters
  cacheLastChanged: epoch,
  current: null,
  count: 0,
} as ParameterState)

const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    // direct actions
    selectParameter(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    updateValue(state, action: PayloadAction<UpdateValueObj>) {
      const { value, valueEnvUrl, parameterId } = action.payload
      if (value) {
        const environmentValue = state.entities[parameterId]?.values[valueEnvUrl]

        if (environmentValue) {
          Object.assign(environmentValue, value)
        }
      } else {
        const parameterValues = state.entities[parameterId]?.values
        if (parameterValues) {
          parameterValues[valueEnvUrl] = null
        }
      }
    },
    addValue(state, action: PayloadAction<UpdateValueObj>) {
      const { value, valueEnvUrl, parameterId } = action.payload
      if (value) {
        const environmentValue = state.entities[parameterId]?.values[valueEnvUrl]

        if (environmentValue) {
          Object.assign(environmentValue, value)
        }
      } else {
        const parameterValues = state.entities[parameterId]?.values
        if (parameterValues) {
          parameterValues[valueEnvUrl] = null
        }
      }
    },

    setAllParameter: parameterAdapter.setAll,
    addOneParameter: parameterAdapter.addOne,
    updateOneParameter: parameterAdapter.upsertOne,
    removeOneParameter: parameterAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateParameter.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      action.payload.values = action.meta.arg.values
      const override = action.meta.arg.isOverride
      if (override || action?.meta?.arg?.project) {
        return
      }

      parameterAdapter.setOne(state, action.payload)
    })
    builder.addCase(GetParameters.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(CreateValue.fulfilled, (state, action) => {
      const parameter = state.entities[action.meta.arg.paramId]!
      const environments = action.meta.arg.environments
      const env = action.meta.arg.envUrl
      const dependentValueArray: any = (parent: string) =>
        environments
          .filter((environment) => parent === environment.parent)
          .flatMap((env) => [env.url, ...dependentValueArray(env.url)])
          .filter(
            (environment) =>
              parameter.values[environment]?.environment === parameter.values[env]?.environment
          )

      const dependentValueObj = () => {
        const obj: Record<string, Value> = {}
        dependentValueArray(env).forEach((env: any) => {
          obj[env] = action.payload
        })
        return obj
      }

      const values = parameter?.values
        ? { ...parameter.values, ...dependentValueObj(), [env]: action.payload }
        : { [env]: action.payload }

      const newParam = { ...parameter, values }

      state.cacheLastChanged = new Date(Date.now())

      parameterAdapter.setOne(state, newParam)
    })

    builder.addCase(GetValues.fulfilled, (state, action) => {
      const parameter = state.entities[action.meta.arg.paramId]!
      const env = action.meta.arg.envUrl

      const values = parameter?.values
        ? { ...parameter?.values, [env]: action.payload.results![0] }
        : { [env]: action.payload.results![0] }

      const newParam = { ...parameter, values }

      state.cacheLastChanged = new Date(Date.now())
      parameterAdapter.setOne(state, newParam)
    })

    builder.addCase(UpdateValue.fulfilled, (state, action) => {
      const parameter = state.entities[action.meta.arg.paramId]!
      const environments = action.meta.arg.environments
      const env = action.meta.arg.envUrl
      const dependentValueArray: any = (parent: string) =>
        environments
          .filter((environment) => parent === environment.parent)
          .flatMap((env) => [env.url, ...dependentValueArray(env.url)])
          .filter(
            (environment) =>
              parameter.values[environment]?.environment === parameter.values[env]?.environment
          )

      const dependentValueObj = () => {
        const obj: Record<string, Value> = {}
        dependentValueArray(env).forEach((env: any) => {
          obj[env] = action.payload
        })
        return obj
      }

      const values = parameter?.values
        ? { ...parameter.values, ...dependentValueObj(), [env]: action.payload }
        : { [env]: action.payload }

      const newParam = { ...parameter, values }

      state.cacheLastChanged = new Date(Date.now())

      parameterAdapter.setOne(state, newParam)
    })

    builder.addCase(DeleteValue.fulfilled, (state, action) => {
      if (action.meta.arg.dontUpdateCache) {
        return
      }

      const parameter = state.entities[action.meta.arg.paramId]!
      const environments = action.meta.arg.environments
      const env = action.meta.arg.envUrl
      const environmentDependsOn = environments.find(
        (environment) => environment.url === env
      )?.parent
      if (environmentDependsOn) {
        const newValue = { ...parameter.values[environmentDependsOn] } as Value
        const dependentValueArray: any = (parent: string) =>
          environments
            .filter((environment) => parent === environment.parent)
            .flatMap((env) => [env.url, ...dependentValueArray(env.url)])
            .filter(
              (environment) =>
                parameter.values[environment]?.environment === parameter.values[env]?.environment
            )

        const dependentValueObj = () => {
          const obj: Record<string, Value> = {}
          dependentValueArray(env).forEach((env: any) => {
            obj[env] = newValue
          })
          return obj
        }

        const values = parameter?.values
          ? { ...parameter.values, ...dependentValueObj(), [env]: newValue }
          : { [env]: newValue }

        const newParam = { ...parameter, values }

        state.cacheLastChanged = new Date(Date.now())

        parameterAdapter.setOne(state, newParam)
      }
    })

    builder.addCase(GetPaginatedParameters.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      state.count = action.payload.count

      parameterAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetParameters.rejected, (state, _) => {
      // If an issue occurs when listing parameters we want to clear the cache to avoid confusing data between projects
      state.cacheLastChanged = new Date(Date.now())
      parameterAdapter.removeAll(state)
    })
    builder.addCase(GetParameter.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())

      const oldParameterValues = state.entities[action.payload.id]?.values

      const newParameter = {
        ...action.payload,
        values: { ...oldParameterValues, ...action.payload.values },
      }

      parameterAdapter.setOne(state, newParameter)
    })
    builder.addCase(UpdateParameter.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const oldParameter = state.entities[action.payload.id]
      const oldValueFields = {
        values: oldParameter?.values,
        values_flat: oldParameter?.values_flat,
        referencing_values: oldParameter?.referencing_values,
      }

      const newParameter = { ...action.payload, ...oldValueFields }

      parameterAdapter.upsertOne(state, newParameter as Parameter)
    })
    builder.addCase(CopyProjectParameter.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterAdapter.addOne(state, action.payload)
    })
    builder.addCase(DeleteParameter.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
    builder.addCase(CreateRule.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const parameter = state.entities[idFromUrl(action.payload.parameter)]!
      parameter.rules.push(action.payload)
    })
    builder.addCase(UpdateRule.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const parameter = state.entities[idFromUrl(action.payload.parameter)]!
      const index = parameter.rules.findIndex((rule) => rule.id === action.payload.id)
      parameter.rules.splice(index, 1, action.payload)
    })
    builder.addCase(DeleteRule.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const parameter = state.entities[action.meta.arg.paramId]!
      const index = parameter.rules.findIndex((rule) => rule.id === action.payload)
      parameter.rules.splice(index, 1)
    })
  },
})

export const {
  resetState,
  selectParameter,
  updateValue,
  setAllParameter,
  addOneParameter,
  updateOneParameter,
  removeOneParameter,
} = parameterSlice.actions

export default parameterSlice.reducer

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { Invitation, PaginatedOrganizationList } from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'
import { TypedThunk, idFromUrl } from 'data/dataUtils'

import { AcceptInvitation } from 'data/user/actions'
import { Forbidden } from 'components/Error/Forbidden'
import { GetOrganizations } from 'data/organization/actions'
import { PageLoading } from 'components/PageLoading'
import { selectOrganization } from 'data/organization/reducer'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useParams } from 'react-router-dom'

type InvitationParams = {
  id: string
}

export function InviteAccept() {
  const [error, setError] = useState<nullable<string>>(null)
  const params = useParams<InvitationParams>()
  const dispatch = useAppDispatch()
  const { id } = params
  const { goHome } = useHistoryPush()

  useEffect(() => {
    dispatch(AcceptInvitation(id!)).then(({ error, payload }: TypedThunk<Invitation>) => {
      if (error) {
        setError(error.message)
      } else {
        const orgId = idFromUrl(payload.organization)

        dispatch(GetOrganizations(null)).then(
          ({ error, payload }: TypedThunk<PaginatedOrganizationList>) => {
            if (error) {
              setError(error.message)
            } else if (payload.count! > 1) {
              dispatch(selectOrganization(orgId))
              goHome()
            } else {
              goHome()
            }
          }
        )
      }
    })
  }, [dispatch, goHome, id])

  return error ? (
    <Forbidden
      clear
      message={`This invitation is no longer valid. Please request a new one from your organization administrator. Details: ${error}`}
    />
  ) : (
    <PageLoading />
  )
}

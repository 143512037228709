import { Label, LabelProps } from './Label'
import React, { PropsWithChildren } from 'react'

import { Item } from './Form'

interface Props {
  label: LabelProps | string
  marginBottom?: string
  marginTop?: string
  name?: string
  required?: boolean
}

export function LabeledItem(props: PropsWithChildren<Props>) {
  const { label, children, marginBottom, name, required, marginTop } = props

  const labelProps = (value: LabelProps | string): LabelProps => {
    return typeof value === 'string' ? { text: value } : { ...value }
  }

  return (
    <Item
      label={<Label {...labelProps(label)} />}
      style={{ marginBottom, marginTop }}
      name={name}
      required={required}
    >
      {children}
    </Item>
  )
}

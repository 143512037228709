//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, TextArea } from 'components/Forms'
import React, { useEffect } from 'react'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'

import { Environment } from 'gen/cloudTruthRestApi'
import { FormInstance } from 'antd'

interface Props {
  environment: Environment
  form: FormInstance
  onFinish: (formData: FormData) => void
}

export function UpdateEnvironmentDescriptionForm(props: Props) {
  const { form, onFinish, environment } = props
  const { description, name } = environment
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())

  useEffect(() => {
    /**
     * When the environment changes, the form needs to
     * reset so the initial values prop updates.
     */
    form.resetFields()
  }, [environment, form])

  const nameValidator = (_: any, value: string) => {
    if (
      value &&
      environments.find(
        (env) =>
          env.name.toLowerCase() === value.toLowerCase() &&
          // allow the same name if it's the same environment
          env.name.toLowerCase() !== name.toLowerCase()
      )
    ) {
      return Promise.reject('Environment with this name already exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form form={form} initialValues={{ description, name }} onFinish={onFinish}>
      <InlineLabelInput
        name="name"
        label="Environment Name"
        required="Please enter an environment name."
        rules={[{ validator: nameValidator }]}
      />
      <TextArea
        autoFocus
        name="description"
        label="Description"
        placeholder="Type description here"
      />
    </Form>
  )
}

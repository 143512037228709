import React, { PropsWithChildren } from 'react'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { RootPaths } from 'router/Router'
import { SideNav } from 'components/SideNav/SideNav'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './OrganizationLayout.module.scss'
import { useAppSelector } from 'data/hooks'
import { useLocation } from 'react-router-dom'

export function OrganizationLayout(props: PropsWithChildren<unknown>) {
  const { children } = props
  const organization = useAppSelector(getCurrentOrganization)
  const isSwitchRoute = useLocation().pathname === RootPaths.Switch

  const hideNav = isSwitchRoute && !organization

  return (
    <div className={styles.container}>
      {!hideNav && <Header organization={organization} />}
      <div className={styles.pageLayout}>
        {!hideNav && (
          <div className={styles.sideNav}>
            <SideNav />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  )
}

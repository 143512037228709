import { TemplateRootState, templateAdapter } from 'data/template/reducer'

// //
// // Copyright (C) 2021 CloudTruth, Inc.
// // All Rights Reserved
// //
//
import { RootState } from '../store'
import { Selector } from 'react-redux'
import { Template } from 'gen/cloudTruthRestApi'
import { createSelector } from '@reduxjs/toolkit'
import { projectIdFromUrl } from 'data/dataUtils'
import { useSelectState } from '../hooks'

const selectTemplate = (state: RootState) => state.template

export interface ParentTemplates {
  id: string
  templates: Template[]
}

export const getCurrentTemplate = createSelector(selectTemplate, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getProjectTemplates = (projectId: string): Selector<RootState, Template[]> =>
  createSelector(selectTemplate, (state): Template[] => {
    const templates = Object.values(state.entities).filter(
      (temp) => projectIdFromUrl(temp!.url) === projectId
    )
    return templates as Template[]
  })

export const getParentTemplates = (
  projectId: string
): Selector<RootState, Record<string, Template[]>> =>
  createSelector(selectTemplate, (state): Record<string, Template[]> => {
    const result: Record<string, Template[]> = {}

    Object.values(state.entities).forEach((template) => {
      if (template && projectIdFromUrl(template.url) !== projectId) {
        const projectIdFromTemplate = projectIdFromUrl(template.url)

        if (Object.prototype.hasOwnProperty.call(result, projectIdFromTemplate)) {
          result[projectIdFromTemplate].push(template)
        } else {
          result[projectIdFromTemplate] = [template]
        }
      }
    })

    return result
  })

export const templateEntitySelectors = templateAdapter.getSelectors()
export const useSelectTemplates = (): TemplateRootState => useSelectState().template

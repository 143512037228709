import { DetailsDate, DetailsDescription } from 'components/Details'
import React, { useState } from 'react'
import { emDash, valueOrEmDash } from 'lib/valueHelpers'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { LabelText } from 'components/LabelText'
import { PreviewDetails } from 'components/PreviewDetails/PreviewDetails'
import { Project } from 'gen/cloudTruthRestApi'
import { idFromUrl } from 'data/dataUtils'
import styles from './ProjectPreviewDetails.module.scss'

interface Props {
  project: Project
  closeModal: () => void
  onClick: () => void
}

export function ProjectPreviewDetails(props: Props) {
  const { project, onClick } = props

  const [visible, setVisible] = useState(false)
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const getProjectNameByUrl = (url: string) => {
    const projectId = idFromUrl(url)

    return projectEntities[projectId]?.name
  }

  const childrenNames = projects
    .filter((n: Project) => n.depends_on === project?.url)
    .map((n: Project) => n.name)
    .reduce((acc: nullable<string>, name: string) => (acc ? `${acc}, ${name}` : name), null)

  return (
    <PreviewDetails
      name={project?.name}
      modalWidth="600px"
      iconMargin="0px 8px 0px 0px"
      visible={visible}
      setVisible={setVisible}
      onClick={() => {
        setVisible(true), onClick()
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.container}>
          <div className={styles.detailsItemContainer}>
            <DetailsDescription description={project?.description || null} />
          </div>

          <div className={styles.detailsItemContainer}>
            <DetailsDate updatedAt={project?.modified_at} createdAt={project?.created_at} />
          </div>

          <div className={styles.detailsItemContainer}>
            <LabelText
              isHorizontal
              text={project?.parameter_name_pattern || 'none'}
              label="Parameter Naming Pattern"
            />
          </div>
          <div className={styles.detailsItemContainer}>
            <LabelText
              isHorizontal
              text={project?.depends_on ? getProjectNameByUrl(project?.depends_on) : emDash}
              label="Parent:"
            />

            <LabelText
              isHorizontal
              maxWidth="300px"
              text={valueOrEmDash(childrenNames)}
              label="Children:"
            />
          </div>
        </div>
      </div>
    </PreviewDetails>
  )
}

import React, { useCallback, useState } from 'react'
import {
  githubIntegrationEntitySelectors,
  useSelectGithubIntegrations,
} from 'data/integration/githubIntegration/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { GetGithubIntegrations } from 'data/integration/githubIntegration/actions'
import { GithubAccountsDetails } from './GithubAccountsDetails'
import { GithubAccountsDropdownMenu } from './GithubAccountsDropdownMenu'
import { List } from 'antd'
import { ManageGithubAccess } from './ManageGithubAccess'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { getPolicy } from 'data/membership/selectors'
import styles from './GithubAccountsList.module.scss'
import { useToast } from 'hooks'

export function GithubAccountsList() {
  const githubIntegrations = githubIntegrationEntitySelectors.selectAll(
    useSelectGithubIntegrations()
  )
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const getGithubIntegrations = useCallback(() => {
    setLoading(true)
    dispatch(GetGithubIntegrations(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  return (
    <div className={styles.container}>
      <PageTitle
        title="GitHub"
        description="To add, remove, or edit access to a GitHub account, click the “Manage GitHub Access”
      button."
        buttons={
          <>
            <Reload onClick={getGithubIntegrations} loading={loading} />{' '}
            {canAdministrate && <ManageGithubAccess />}
          </>
        }
      />

      <div className={styles.accountList}>
        <List
          pagination={{ pageSize: 8 }}
          className={styles.list}
          bordered
          dataSource={githubIntegrations}
          loading={loading}
          itemLayout="vertical"
          renderItem={(integration) => {
            return (
              <List.Item
                extra={
                  <>
                    {canAdministrate && (
                      <GithubAccountsDropdownMenu githubIntegration={integration} />
                    )}
                  </>
                }
              >
                <List.Item.Meta title={<div className={styles.title}>{integration.name}</div>} />

                <GithubAccountsDetails githubIntegration={integration} />
              </List.Item>
            )
          }}
        />
      </div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Environment, Parameter, ParameterDuality, Value } from 'gen/cloudTruthRestApi'
import {
  NameColumn,
  Table,
  ValueColumn,
  defaultOrder,
  emptyParametersMessage,
} from 'components/Table'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { emDash, valueOrEmDash } from 'lib/valueHelpers'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'

import { Dayjs } from 'dayjs'
import { TableProps } from 'antd'
import { VersionValueColumn } from './VersionValueColumn'
import { getCurrentEnvironment } from 'data/environment/selectors'
import { idFromUrl } from 'data/dataUtils'
import styles from './ParameterHistoryTable.module.scss'
import { useAppSelector } from 'data/hooks'

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

interface Props {
  loading: boolean
  checkDiff: boolean
  searchTerm: string
  totalParameters?: number
  parameters: ParameterDuality[]
  maskSecrets: boolean
  fetchError: boolean
  timestamp: nullable<Dayjs>
  setTimestamp: React.Dispatch<React.SetStateAction<nullable<Dayjs>>>
  setOrdering: React.Dispatch<React.SetStateAction<option<string>>>
  setPage: React.Dispatch<React.SetStateAction<{ pageNumber: number; project: string }>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setParameters: React.Dispatch<React.SetStateAction<ParameterDuality[]>>
  getParametersByProject: () => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  setLocalPageSize: (pageSize: number) => void
  selectedTimestamp?: string | undefined
}

export function ParameterHistoryTable(props: Props) {
  const {
    checkDiff,
    searchTerm,
    loading,
    setOrdering,
    setPage,
    setLoading,
    totalParameters,
    parameters,
    setParameters,
    fetchError,
    timestamp,

    pageSize,
    setPageSize,
    setLocalPageSize,
    maskSecrets,
    selectedTimestamp,
  } = props

  const { url: currentEnvUrl } = useAppSelector(getCurrentEnvironment)!

  const { getQuery, setQuery, removeQuery } = useQuery()

  const sortBy = getQuery(QueryParamName.SortBy)
  const project = useAppSelector(getCurrentProject)!
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const pageNumberFromParams = () => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : undefined
  }

  const updateCurrentValue = useCallback(
    (payload: Value, parameter: Parameter, currentEnvironment: Environment) => {
      const index = parameters.findIndex((param) => param.t2?.name === parameter.name)

      const newParams = parameters.map((param, key) => {
        if (key === index) {
          param!.t2!.values[currentEnvironment.url] = payload
        }

        return param
      })

      setParameters(newParams)
    },
    [parameters, setParameters]
  )

  const initialColumns: TableProps<ParameterDuality>['columns'] = useMemo(() => {
    return [
      {
        title: 'NAME',
        key: 'name',
        ellipsis: true,
        width: 200,
        defaultSortOrder: defaultOrder(sortBy, 'name'),
        sorter: (a, b) => {
          const firstName = a.t2!.name.toUpperCase()
          const secondName = b.t2!.name.toUpperCase()

          return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
        },
        render: (_value, parameter) => (
          <NameColumn
            parameter={parameter?.t2 || parameter.t1!}
            disabled={!parameter?.t2}
            inheritedFrom={
              project.url === (parameter.t2?.project || parameter.t1?.project)
                ? undefined
                : projectEntities[idFromUrl(parameter.t2?.project || parameter.t1!.project)]!.name
            }
          />
        ),
      },
      {
        title: `ENVIRONMENT VALUE: Current`,
        key: `value`,
        width: 200,
        dataIndex: 'values',
        render: (_value, parameter) => {
          return (
            <>
              {parameter.t2 ? (
                <ValueColumn
                  maskSecrets={maskSecrets}
                  key={parameter.t2?.id}
                  parameter={parameter.t2}
                  envUrl={currentEnvUrl}
                  updateCurrentValue={updateCurrentValue}
                  inherited={project.url !== parameter.t2.project}
                />
              ) : (
                <div className={styles.deleted}>deleted</div>
              )}
            </>
          )
        },
      },

      {
        title: `ENVIRONMENT VALUE: ${valueOrEmDash(selectedTimestamp)}`,
        key: `value2`,
        width: 200,
        render: (_value, parameter) => {
          return (
            <>
              {selectedTimestamp ? (
                <VersionValueColumn
                  parameters={parameters}
                  setParameters={setParameters}
                  timestamp={timestamp}
                  key={`versioned_${parameter?.t1?.id}`}
                  parameter={parameter?.t1}
                />
              ) : (
                emDash
              )}
            </>
          )
        },
      },
    ]
  }, [
    sortBy,

    timestamp,
    currentEnvUrl,
    parameters,
    project.url,
    projectEntities,
    setParameters,
    updateCurrentValue,
    maskSecrets,
    selectedTimestamp,
  ])

  const [columns, setColumns] = useState(initialColumns)
  useEffect(() => {
    setColumns(initialColumns)
  }, [initialColumns])

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setQuery(QueryParamName.Page, pageNumber.toString())
    setQuery(QueryParamName.PageSize, pageSize.toString())
    setPage((prev) => ({ ...prev, pageNumber }))
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const onTableChange: TableProps<Parameter>['onChange'] = (_, __, sorter) => {
    setLoading(true)
    const { order, columnKey } = (sorter as any) || {
      order: null,
      columnKey: null,
    }

    if (order) {
      order === 'descend' ? setOrdering(`-${columnKey}`) : setOrdering(`${columnKey}`)
      setQuery(QueryParamName.SortBy, decodeURIComponent(`${columnKey}+${order}`))
    } else {
      setOrdering(undefined)
      removeQuery(QueryParamName.SortBy)
    }
    setLoading(false)
  }

  return (
    <>
      <Table
        caption={
          <div className={styles.tableTitleContainer}>
            <h3>{`Project: ${project.name}`}</h3>
          </div>
        }
        scroll={{ x: columns.length > 6 ? 1300 : undefined }}
        onChange={onTableChange}
        columns={resizableColumns(columns, setColumns)}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: totalParameters,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: pageNumberFromParams() ? pageNumberFromParams() : 1,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : parameters}
        rowKey={(parameter: ParameterDuality) => parameter?.t2?.id || parameter.t1!.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div>
                  <h4>
                    {checkDiff
                      ? 'There are no differences in this project at this tag/timestamp.'
                      : emptyParametersMessage(searchTerm, [], fetchError)}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </>
  )
}

/* eslint react/no-children-prop: 0 */
/* eslint unused-imports/no-unused-vars-ts: 0 */

import { CopyText } from 'components/CopyText'
import Markdown from 'react-markdown'
import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'

type MessageDisplayProps = { message: string }
const MessageDisplay: React.FC<MessageDisplayProps> = ({ message }) => {
  return (
    <Markdown
      components={{
        //     const match = /language-(\w+)/.exec(className || '') //
        code({ node, className, children, ...props }) {
          // {{
          const match = /language-(\w+)/.exec(className || '') //
          return match ? (
            <div>
              <CopyText
                prompt="Copy Code"
                payload={String(children).replace(/\n$/, '')}
                color="rgb(101, 103, 110)"
                margin="5px 0px"
              />
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={oneLight}
                language={match[1]}
                PreTag="div"
                showLineNumbers
              />
            </div>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        },
      }}
    >
      {message}
    </Markdown>
  )
}

export default MessageDisplay

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { FeaturesResponse } from 'gen/cloudTruthRestApi'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetFeatures = createAsyncThunk<FeaturesResponse, null, ThunkConfig>(
  'features/getFeatures',
  async (_, thunkApi) => await handleFetch(Verbs.Get, `features`, null, thunkApi)
)

export const GetGptApiKey = createAsyncThunk<FeaturesResponse, null, ThunkConfig>(
  'features/getFeatures',
  async (_, thunkApi) => await handleFetch(Verbs.Get, `features/aichat`, null, thunkApi)
)

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */

import {
  AwsPull,
  AwsRegionEnum,
  AwsServiceEnum,
  AzureKeyVaultPull,
  DiscoveryResult,
} from 'gen/cloudTruthRestApi'
import { Form, FormData, InlineLabelInput, LabeledInputItem, TextArea } from 'components/Forms'
import { FormInstance, FormRule, Spin } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  formatAwsPushService,
  initialMustacheResourceString,
  initialRegexResourceString,
  pullIds,
  resourceNames,
  scanString,
  validateImportResourceString,
} from 'components/Actions/utils'

import { ActionEnum } from 'data/actions/utils'
import Editor from 'components/Editor/Editor'
import { ImportResourceButtons } from './ImportResourceButtons'
import { IntegrationSelector } from 'components/Actions/ActionsForm/IntegrationSelector'
import { PreviewImport } from './PreviewImport'
import { RegionCheckboxes } from 'components/Actions/ActionsForm/RegionCheckboxes'
import { ResourceDescription } from './ResourceDescription'
import { ScanDisplayFilters } from './ScanDisplayFilters'
import { ServiceCheckboxes } from '../ActionsForm/ServiceCheckboxes'
import styles from './ImportTable.module.scss'

interface Props {
  form: FormInstance
  steps: number
  handleSubmit: (formData: FormData) => void
  pull?: AwsPull | AzureKeyVaultPull
  editing?: boolean
  actionType: ActionEnum
}

export function ImportForm(props: Props) {
  const { form, steps, handleSubmit, pull, editing, actionType } = props

  const awsPull = pull as AwsPull

  const initialRegion = awsPull?.region ? [awsPull.region] : []
  const initialService = awsPull?.service ? [awsPull.service] : []
  const initialIntegration = pull?.url ? pullIds(pull.url, actionType).integrationId : null
  const initialCreateEnvironments =
    pull?.create_environments === undefined ? true : pull.create_environments
  const initialCreateProjects = pull?.create_projects === undefined ? true : pull.create_projects
  const initialMustacheResource = pull?.resource || initialMustacheResourceString(actionType)
  const initialRegexResource = pull?.resource || initialRegexResourceString(actionType)

  const [mustacheResource, setMustacheResource] = useState<string>(initialMustacheResource)
  const [regexResource, setRegexResource] = useState<string>(initialRegexResource)
  const [regionValues, setRegionValues] = useState<AwsRegionEnum[]>(initialRegion)
  // this will be changed once more services are available
  const [serviceValues, setServiceValues] = useState<string[]>(initialService)
  const [integration, setIntegration] = useState<nullable<string>>(initialIntegration)

  const [scan, setScan] = useState<DiscoveryResult>()
  const [scanLoading, setScanLoading] = useState(false)
  const [searchVenue, setSearchVenue] = useState('')
  const [sortVenue, setSortVenue] = useState<'asc' | 'desc'>('asc')
  const [errorMessage, setErrorMessage] = useState<nullable<string>>(null)

  const defaultRadioValue = () => {
    if (!pull) {
      return 'mustache'
    }

    return pull.resource?.includes('{{') ? 'mustache' : 'regex'
  }

  const [radioValue, setRadioValue] = useState<'mustache' | 'regex'>(defaultRadioValue())

  const resource = useMemo(() => {
    return radioValue === 'mustache' ? mustacheResource : regexResource
  }, [radioValue, mustacheResource, regexResource])

  const service = useMemo(() => {
    return serviceValues[0] === 'secretsmanager'
      ? AwsServiceEnum.Secretsmanager
      : AwsServiceEnum.Ssm
  }, [serviceValues])

  const displayScanString = useMemo(() => {
    return scanString(searchVenue, sortVenue, scan)
  }, [searchVenue, sortVenue, scan])

  const validateResource = (_rule: FormRule, value: string) => {
    if (errorMessage) {
      return Promise.reject(errorMessage)
    } else if (radioValue === 'mustache') {
      return validateImportResourceString(_rule, value)
    } else {
      return Promise.resolve()
    }
  }

  useEffect(() => {
    form.setFieldsValue({ resource })
    setErrorMessage(null)
  }, [form, resource, setErrorMessage])

  const renderEditor = useCallback(() => {
    if (scanLoading) {
      return (
        <div className={styles.spinContainer}>
          <Spin />
        </div>
      )
    }

    if (scan) {
      return (
        <div>
          <ScanDisplayFilters
            setVenueSearch={setSearchVenue}
            venueSort={sortVenue}
            setVenueSort={setSortVenue}
          />
          <Editor className={styles.scanEditor} body={displayScanString} readOnly />
        </div>
      )
    }

    return radioValue === 'mustache' ? (
      <Editor
        className={styles.editor}
        body={mustacheResource}
        onChange={setMustacheResource}
        includeOnly={resourceNames}
        mode="resourcePattern"
      />
    ) : (
      <Editor
        className={styles.editor}
        body={regexResource}
        onChange={setRegexResource}
        includeOnly={[]}
        mode={'python-regex'}
      />
    )
  }, [scan, radioValue, scanLoading, displayScanString, mustacheResource, regexResource, sortVenue])

  const getNames = () => {
    return {
      regionName: form.getFieldValue('region')[0],
      serviceName: form.getFieldValue('service')[0],
    }
  }

  const resetResource = () => {
    radioValue === 'mustache'
      ? setMustacheResource(initialMustacheResource)
      : setRegexResource(initialRegexResource)
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          resource,
          integration: initialIntegration,
          service: initialService,
          region: initialRegion,
          name: pull?.name,
          description: pull?.description,
          createEnvironments: initialCreateEnvironments,
          createProjects: initialCreateProjects,
        }}
      >
        {steps === 0 && (
          <>
            <InlineLabelInput autoFocus name="name" label="Name" required="Please enter a name." />
            <TextArea name="description" label="Description" />

            <LabeledInputItem name="integration" label={{ text: 'Integration' }} required>
              <IntegrationSelector
                actionType={actionType}
                editing={editing}
                integration={integration}
                selectIntegration={setIntegration}
                form={form}
                clearServices={() => setServiceValues([])}
                clearRegions={() => setRegionValues([])}
              />
            </LabeledInputItem>

            {integration && actionType === ActionEnum.AwsPull && (
              <>
                <LabeledInputItem name="region" label={{ text: 'Region' }} required>
                  <RegionCheckboxes
                    editing={editing}
                    values={regionValues}
                    setValues={setRegionValues}
                    form={form}
                    integration={integration}
                  />
                </LabeledInputItem>

                <LabeledInputItem name="service" label={{ text: 'Service' }} required>
                  <ServiceCheckboxes
                    editing={editing}
                    integration={integration}
                    values={serviceValues}
                    setValues={setServiceValues}
                    form={form}
                  />
                </LabeledInputItem>
              </>
            )}
          </>
        )}

        {steps === 1 && (
          <>
            {actionType === ActionEnum.AwsPull ? (
              <p className={styles.formDescription}>
                {`Imports require knowing the environment, project, and parameter name.
                Define the pattern match that will identify these three items in your
                ${getNames().regionName}: ${formatAwsPushService(getNames().serviceName)} content:`}
              </p>
            ) : (
              <p>Imports require knowing the environment, project, and parameter name.</p>
            )}
            <br />
            <LabeledInputItem
              name="resource"
              label="Resource Pattern"
              rules={[
                {
                  validator: validateResource,
                },
              ]}
              required
            >
              <ImportResourceButtons
                actionType={actionType}
                setErrorMessage={setErrorMessage}
                form={form}
                resource={resource}
                resetResource={resetResource}
                value={radioValue}
                setValue={setRadioValue}
                setScan={setScan}
                setScanLoading={setScanLoading}
                scanLoading={scanLoading}
                displayingScan={!!scan}
                scanRequestObj={{
                  integrationId: integration!,
                  region: regionValues[0],
                  service: service,
                  resource,
                }}
              />

              <> {renderEditor()}</>
            </LabeledInputItem>
            <ResourceDescription pull regex={radioValue === 'regex'} actionType={actionType} />
            <div className={styles.autoCreate}>
              <InlineLabelInput
                required
                checkbox
                name="createEnvironments"
                label={{ text: 'Auto-Create Environments' }}
              />
              <InlineLabelInput
                required
                checkbox
                name="createProjects"
                label={{ text: 'Auto-Create Projects' }}
              />
            </div>
          </>
        )}

        {steps === 2 && <PreviewImport form={form} actionType={actionType} />}

        {/* <Steps size="small" current={steps} style={{ marginTop: '20px' }}>
          <Step title="Pull info" />
          <Step title="Resource Pattern" />
          <Step title="Preview" />
        </Steps> */}
      </Form>
    </>
  )
}

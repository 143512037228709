import Editor from 'components/Editor/Editor'
import React from 'react'
import { Spin } from 'antd'
import styles from './TemplatePreviewDisplay.module.scss'

interface Props {
  updateShowPreview: (bool: boolean) => void
  showPreview: boolean
  currentBody: maybe<string>
  editing: boolean
  wrapping: boolean
  error: nullable<string>
  evaluatedTemplate: maybe<string>
  loading: boolean
}

interface PreviewEditor {
  evaluatedTemplate: maybe<string>
  errors: nullable<string>
  loading: boolean
  wrapping: boolean
}

function PreviewEditor(props: PreviewEditor) {
  const { evaluatedTemplate, errors, loading, wrapping } = props

  if (errors) {
    return <Editor body={errors} readOnly wrapText={wrapping} />
  } else if (loading) {
    return <Spin data-testid="loading" />
  } else {
    return <Editor body={evaluatedTemplate} readOnly wrapText={wrapping} />
  }
}

export function TemplatePreviewDisplay(props: Props) {
  const { wrapping, error, evaluatedTemplate, loading } = props

  return (
    <div className={styles.container}>
      <div
        data-private="redact"
        className={
          evaluatedTemplate || typeof evaluatedTemplate === 'string'
            ? styles.previewContainer
            : styles.previewContainerLoading
        }
      >
        <PreviewEditor
          errors={error}
          evaluatedTemplate={evaluatedTemplate}
          loading={loading}
          wrapping={wrapping}
        />
      </div>
    </div>
  )
}

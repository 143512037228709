import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { DeleteInvitationComponent as DeleteInvite } from './DeleteInvitation'
import { Invitation } from 'gen/cloudTruthRestApi'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { ResendInvitation } from 'data/user/actions'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  invitation: Invitation
}

enum MenuItemKeys {
  DeleteInvitation = 'deleteInvitation',
  Resend = 'resend',
}

export function InvitationDropdown(props: Props) {
  const { invitation } = props
  const [deleteVisible, setDeleteVisible] = useState(false)
  const dispatch = useAppDispatch()
  const { successToast, errorToast } = useToast()

  const resendInvitation = () => {
    dispatch(ResendInvitation(invitation.id)).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Email sent')
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteInvitation: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.Resend: {
        resendInvitation()
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    { key: MenuItemKeys.Resend, label: 'Resend' },
    {
      key: MenuItemKeys.DeleteInvitation,
      label: 'Delete',
      danger: true,
    },
  ]

  return (
    <>
      <DeleteInvite invitation={invitation} visible={deleteVisible} setVisible={setDeleteVisible} />
      <MoreIconDropdown menu={{ items, onClick }} color="black" dataCy="invite-dropdown" />
    </>
  )
}

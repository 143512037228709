import { Alert, AlertContent } from '@ory/themes'

import React from 'react'
import { UiText } from '@ory/kratos-client'

interface MessageProps {
  message: UiText
}

export const Message = ({ message }: MessageProps) => {
  return (
    <Alert severity={message.type === 'error' ? 'error' : 'info'}>
      <AlertContent data-testid={`ui/message/${message.id}`}>{message.text}</AlertContent>
    </Alert>
  )
}

interface MessagesProps {
  messages?: UiText[]
}

export const Messages = ({ messages }: MessagesProps) => {
  if (!messages) {
    return null
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ExpiringValuesResponse } from 'gen/cloudTruthRestApi'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetExpired = createAsyncThunk<ExpiringValuesResponse, null, ThunkConfig>(
  'expire/expires',
  async (_, thunkApi) => await handleFetch(Verbs.Get, `reports/expiring-values`, null, thunkApi)
)

import React, { useCallback, useState } from 'react'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AddAwsAccount } from './AddAwsAccount'
import { AwsAccountListItem } from './AwsAccountListItem'
import { CustomThunk } from 'data/dataUtils'
import { GetAwsIntegrations } from 'data/integration/awsIntegration/actions'
import { List } from 'antd'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { PageDescription } from 'components/PageDescription'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { getPolicy } from 'data/membership/selectors'
import styles from './AwsAccountsList.module.scss'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

export function AwsAccountsList() {
  const awsIntegrations = awsIntegrationEntitySelectors.selectAll(useSelectAwsIntegrations())
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const [showForm, setShowForm] = useState(false)

  const { goToOrgRoute } = useHistoryPush()
  const awsAccountId = process.env.REACT_APP_AWS_ACCOUNT_ID

  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const getAwsIntegrations = useCallback(() => {
    setLoading(true)
    dispatch(GetAwsIntegrations(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }

      setLoading(false)
    })
  }, [dispatch, errorToast])

  return (
    <div className={styles.container}>
      <PageTitle
        title="AWS"
        description='To grant CloudTruth access to an AWS account, click "Connect AWS Account" and complete the
        form provided. We will not be able to establish a connection to your AWS account until
        the policy that allows it is put in place.'
        buttons={
          <>
            <Reload onClick={getAwsIntegrations} loading={loading} />
            {canAdministrate && (
              <ActionButton
                customType="add"
                size="large"
                onClick={() => setShowForm(true)}
                disabled={showForm}
                loading={loading}
              >
                Connect AWS Account
              </ActionButton>
            )}
          </>
        }
      />
      <div className={styles.content}>
        <PageDescription subtitle={`CloudTruth Account #${awsAccountId}`} />

        <div className={styles.accountList}>
          {showForm && (
            <AddAwsAccount
              onCancel={() => setShowForm(false)}
              loading={loading}
              onSuccess={() => {
                setShowForm(false)
                goToOrgRoute(OrganizationPaths.Aws)
              }}
            />
          )}

          <List
            pagination={{ pageSize: 8 }}
            className={styles.list}
            bordered
            dataSource={awsIntegrations}
            loading={loading}
            itemLayout="vertical"
            renderItem={(integration) => <AwsAccountListItem integration={integration} />}
          />
        </div>
      </div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */

import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { Divider, Space } from 'antd'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getLocalSession, setLocalSession } from 'lib/sessionPersistance'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CompareTable } from './CompareTable'
import { EnvironmentComparisonSelector } from 'components/EnvironmentComparisonSelector'
import { GetPaginatedParameters } from 'data/parameter/actions'
import { ProjectsParametersListParams } from 'gen/cloudTruthRestApi'
import { Reload } from 'components/Reload'
import { ShowDifferencesConfirmModal } from 'components/Modals/ShowDifferencesConfirmModal'
import { TableSearch } from 'components/Table'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { ToggleText } from 'components/ToggleText'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getCurrentProject } from 'data/project/selectors'
import styles from './CompareContainer.module.scss'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Params {
  projectId: string
  params: Partial<ProjectsParametersListParams>
}

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

export function CompareContainer() {
  const project = useAppSelector(getCurrentProject)!
  const subscribeToAsyncPulls = useAppSelector((state) => state.session.subscribeToAsyncPulls)
  const parameters = parameterEntitySelectors.selectAll(useSelectParameters())
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'compare' })
  const localPageSize = localSession?.pageSize
  const localCurrentOnly = localSession?.currentOnly

  const [environmentSelectValues, setEnvironmentSelectValues] = useState<string[]>([])
  const [difference, setDifference] = useState<string>()
  const [maskSecret, setMaskSecret] = useState(true)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [showInherited, setShowInherited] = useState(!!localCurrentOnly)

  const [checkDiff, setCheckDiff] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  const differencesString = (envUrls: string[]) => envUrls.map((url) => idFromUrl(url)).join(',')

  const showToggleSecrets = useMemo(() => {
    return parameters.some((parameter) =>
      Object.values(parameter.values).some((value) => value?.secret)
    )
  }, [parameters])

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const { getQuery, removeQuery } = useQuery()

  const removePages = useCallback(() => {
    removeQuery(QueryParamName.Page)
    removeQuery(QueryParamName.PageSize)
  }, [removeQuery])

  const [ordering, setOrdering] = useState<option<string>>(() => {
    const query = getQuery(QueryParamName.SortBy)
    if (query?.includes('name')) {
      return query.includes('ascend') ? 'name' : '-name'
    }

    return
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return { pageNumber: pageNumber ? parseInt(pageNumber) : 1, project: project.id }
  })
  const paginatedParams: Params = useMemo(
    () => ({
      projectId: project.id,
      params: {
        // If the user switches projects, the page should return to 1
        page: page.project !== project.id ? 1 : page.pageNumber,
        page_size: pageSize,
        name__icontains: searchTerm,
        ordering,
        difference,
        mask_secrets: maskSecret,
        immediate_parameters: !showInherited,
      },
    }),
    [page, project.id, searchTerm, ordering, difference, maskSecret, showInherited, pageSize]
  )

  const search = (searchTerm: string) => {
    setSearchTerm(searchTerm), setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
  }

  // If the user switches projects, the page should return to 1
  useEffect(() => {
    if (page.project !== project.id) {
      setPage({ pageNumber: 1, project: project.id }), removePages()
    }
  }, [project, page, removePages])

  useEffect(() => {
    if (subscribeToAsyncPulls) {
      dispatch(GetPaginatedParameters(paginatedParams))
    }
  }, [dispatch, project.id, subscribeToAsyncPulls, paginatedParams])

  /*********** Get parameters by project ********************************* */
  const getPaginatedParameters = useCallback(() => {
    setLoading(true)
    setFetchError(false)

    dispatch(GetPaginatedParameters(paginatedParams)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setFetchError(true)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, paginatedParams])

  useEffect(() => {
    getPaginatedParameters()
  }, [getPaginatedParameters])

  const handleCheckboxChange = (checked: boolean) => {
    if (!checked) {
      setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
      setCheckDiff(false)
      setMaskSecret(true)
      setDifference(undefined)
    } else {
      setConfirmVisible(true)
    }
  }

  const handleSecret = () => {
    setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
    setMaskSecret(false)
    setCheckDiff(true)
    setDifference(differencesString(environmentSelectValues))
    setConfirmVisible(false)
  }

  const handleNonSecret = () => {
    setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
    setMaskSecret(true)
    setCheckDiff(true)
    setDifference(differencesString(environmentSelectValues))
    setConfirmVisible(false)
  }

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'compare',
      args: { currentOnly: showInherited, pageSize },
    })

  const setLocalCurrentOnly = (currentOnly: boolean) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'compare',
      args: { currentOnly },
    })

  return (
    <>
      <ShowDifferencesConfirmModal
        handleSecret={handleSecret}
        handleNonSecret={handleNonSecret}
        visible={confirmVisible}
        loading={loading}
      />

      <div className={styles.searchSelectors}>
        <div className={styles.title}>Compare</div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.searchSelectors}>
        <div className={styles.searchContainer}>
          <TableSearch
            defaultValue={searchTerm}
            updateSearchTerm={search}
            form={form}
            setPage={setPage}
          />

          <div className={styles.reload}>
            <Reload onClick={getPaginatedParameters} loading={loading} />
          </div>

          {showToggleSecrets && (
            <ToggleSecrets
              showSecretValues={!maskSecret}
              handleToggleClick={() => setMaskSecret((prevState) => !prevState)}
            />
          )}

          <ToggleText
            handleToggleClick={() => {
              handleCheckboxChange(!checkDiff)
            }}
            showing={checkDiff}
            text="Differences"
          />

          <ToggleText
            handleToggleClick={() => {
              setLocalCurrentOnly(!showInherited)
              setShowInherited(!showInherited)
              setPage((prev) => ({ ...prev, pageNumber: 1 }))
              removePages()
            }}
            showing={showInherited}
            text="Inherited"
          />
        </div>

        <div className={styles.environmentsCascader}>
          <div>
            <Space className={styles.spaceContainer}>
              <div className={styles.selectorContainer}>
                <div className={styles.environmentsLabel}>Compare:</div>

                <EnvironmentComparisonSelector
                  environmentSelectValue={environmentSelectValues}
                  onEnvChange={(values) => {
                    if (checkDiff) {
                      setDifference(differencesString(values))
                    }

                    setEnvironmentSelectValues(values)
                  }}
                />
              </div>
            </Space>
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <CompareTable
          maskSecret={maskSecret}
          loading={loading}
          getPaginatedParameters={getPaginatedParameters}
          checkDiff={checkDiff}
          searchTerm={searchTerm}
          fetchError={fetchError}
          parameters={parameters}
          environmentSelectValues={environmentSelectValues}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setPage={setPage}
          setLocalPageSize={setLocalPageSize}
          setOrdering={setOrdering}
          page={page}
        />
      </div>
    </>
  )
}

import { CustomThunk, projectIdFromAnyUrl } from 'data/dataUtils'
import { Parameter, ParameterDuality } from 'gen/cloudTruthRestApi'
import React, { useCallback, useMemo, useState } from 'react'
import { Tooltip, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { validateString, valueOrEmDash } from 'lib/valueHelpers'

import { Dayjs } from 'dayjs'
import { GetVersionedValue } from 'data/parameter/actions'
import { ProviderIcon } from 'components/ProviderIcon'
import { ValueError } from 'components/Table'
import { VersionValueColumnDropdown } from './VersionValueColumnDropdown'
import { getCurrentEnvironment } from 'data/environment/selectors'
import { getCurrentProject } from 'data/project/selectors'
import styles from './VersionValueColumn.module.scss'
import { useToast } from 'hooks'

interface Props {
  parameter?: nullable<Parameter>
  timestamp: nullable<Dayjs>
  parameters: ParameterDuality[]
  setParameters: React.Dispatch<React.SetStateAction<ParameterDuality[]>>
}
const { Text } = Typography

export function VersionValueColumn(props: Props) {
  const { parameter, timestamp, parameters, setParameters } = props

  const [loading, setLoading] = useState(false)

  const { errorToast } = useToast()
  const dispatch = useAppDispatch()
  const currentEnvironment = useAppSelector(getCurrentEnvironment)!
  const { id: projectId } = useAppSelector(getCurrentProject)!
  const envValue = parameter?.values[currentEnvironment.url]
  const [showEvaluated, setShowEvaluated] = useState(!!envValue?.evaluated)

  const value = envValue?.interpolated
    ? showEvaluated
      ? envValue?.value
      : envValue?.internal_value
    : envValue?.value
  const error = envValue?.external_error

  const maskSecrets = useMemo(() => {
    return envValue?.value === '*****'
  }, [envValue])

  const toggleSecrets = () => {
    setLoading(true)
    if (!parameter || !envValue || !timestamp) {
      setLoading(false)
      return
    }

    dispatch(
      GetVersionedValue({
        paramId: parameter?.id,
        projectId,
        maskSecret: !maskSecrets,
        valueId: envValue?.id,
        timestamp: timestamp.toISOString(),
      })
    )
      .then(({ payload, error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setLoading(false)
          return
        }

        const index = parameters.findIndex((param) => param.t1?.name === parameter.name)

        const newParams = parameters.map((param, key) => {
          if (key === index) {
            param.t1!.values[currentEnvironment.url] = payload
          }

          return param
        })

        setParameters(newParams)
      })
      .then(() => {
        setLoading(false)
      })
  }

  const evaluateRaw = () => {
    setLoading(true)
    if (!parameter || !envValue || !timestamp) {
      setLoading(false)
      return
    }

    if (envValue.evaluated) {
      setShowEvaluated(!showEvaluated)
      setLoading(false)
      return
    } else {
      dispatch(
        GetVersionedValue({
          paramId: envValue.parameter_id,
          projectId: projectIdFromAnyUrl(envValue.url),
          maskSecret: false,
          valueId: envValue?.id,
          timestamp: timestamp.toISOString(),
          evaluate: !envValue.evaluated,
        })
      )
        .then(({ payload, error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
            setLoading(false)
            return
          }

          const index = parameters.findIndex((param) => param.t1?.name === parameter.name)

          const newParams = parameters.map((param, key) => {
            if (key === index) {
              param.t1!.values[currentEnvironment.url] = payload
            }

            return param
          })

          setParameters(newParams)
        })
        .then(() => {
          setLoading(false)
          setShowEvaluated((prev) => !prev)
        })
    }
  }

  const showValue = useCallback(() => {
    return valueOrEmDash(validateString(value))
  }, [value])

  const valueTooltip = () => {
    return value && value?.length > 25 && !error ? validateString(value) : undefined
  }

  return (
    <div data-private="redact">
      <div>
        <div className={styles.providerContainer}>
          <ProviderIcon isInterpolated={envValue?.interpolated} fqn={envValue?.external_fqn} />
          {error ? (
            <ValueError error={error} />
          ) : (
            <>
              <Tooltip
                title={valueTooltip()}
                placement="topLeft"
                overlayInnerStyle={{
                  maxWidth: '600px',
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-line',
                }}
                overlayStyle={{ maxWidth: '600px', wordBreak: 'break-all', whiteSpace: 'pre-line' }}
              >
                <Text ellipsis className={styles.valueEllipses}>
                  {!parameter && timestamp ? `No parameter at this timestamp.` : showValue()}
                </Text>
              </Tooltip>
              {(parameter?.secret || envValue?.interpolated) && (
                <VersionValueColumnDropdown
                  envValue={envValue || null}
                  loading={loading}
                  isSecret={parameter?.secret}
                  maskedSecret={maskSecrets}
                  toggleSecrets={toggleSecrets}
                  evaluateRaw={evaluateRaw}
                  showEvaluated={showEvaluated}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

import { FormInstance, TreeSelect } from 'antd'
import {
  environmentEntitySelectors,
  getTags,
  useSelectEnvironments,
} from 'data/environment/selectors'

import React from 'react'
import { Tag } from 'gen/cloudTruthRestApi'
import { useAppSelector } from 'data/hooks'

interface Props {
  values: string[]
  setValues: (values: string[]) => void
  form: FormInstance
}

const { SHOW_PARENT } = TreeSelect

export function TagSelector(props: Props) {
  const { values, setValues, form } = props
  const tags = useAppSelector(getTags())
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())

  const onChange = (values: string[]) => {
    setValues(values)
    form.setFieldsValue({ tags: values })
  }

  const buildTree = () => {
    if (!tags) {
      return []
    }

    const disablelNode = (values: string[], tags: Tag[], currentTag: string) => {
      const hasValueInArray = tags.some((item) => values.includes(item.id))

      return values.includes(currentTag) ? false : hasValueInArray
    }

    return Object.entries(tags).map(([environmentId, tags]) => {
      return {
        title: environmentEntities[environmentId]!.name,
        key: environmentId,
        value: environmentId,
        checkable: false,
        selectable: false,
        children: tags.map((tag) => {
          return {
            title: `${environmentEntities[environmentId]!.name}: ${tag.name}`,
            key: tag.id,
            value: tag.id,
            disabled: disablelNode(values, tags, tag.id),
          }
        }),
      }
    })
  }

  return (
    <TreeSelect
      treeData={buildTree()}
      value={values}
      onChange={onChange}
      treeCheckable={true}
      treeDefaultExpandAll={true}
      showCheckedStrategy={SHOW_PARENT}
      multiple
      placeholder="Please select"
    />
  )
}

import { IntegrationNode, NodeTypeEnum } from 'gen/cloudTruthRestApi'
import { LoadingOutlined, LockOutlined } from '@ant-design/icons'
import { Spin, Tooltip, Typography } from 'antd'

import { ProviderIcon } from 'components/ProviderIcon'
import React from 'react'
import styles from './ServiceTreeEntry.module.scss'

interface Props {
  integrationTree: IntegrationNode
  showExternalSecretValues: boolean
  onSelect?: (fqn: string) => void
  pending: boolean
  selectedFqn: nullable<string>
}

export function ServiceTreeEntry(props: Props) {
  const { integrationTree, onSelect, pending, showExternalSecretValues, selectedFqn } = props

  const onClick = () => onSelect && onSelect(integrationTree.fqn)
  const rightArrow = '\u2192'

  const loadingIcon = <LoadingOutlined className={styles.loading} spin />

  const selected = () => {
    if (!selectedFqn || !integrationTree.name) {
      return false
    } else {
      return (
        selectedFqn.includes(integrationTree.name) &&
        integrationTree.node_type === NodeTypeEnum.File
      )
    }
  }

  const DisabledEntry = () => {
    return (
      <Tooltip
        title={`Mark parameter as a secret in parameter's settings to access ${integrationTree.name}`}
        className={styles.disabledContainer}
      >
        <Typography.Text disabled>{integrationTree.name}</Typography.Text>
        <LockOutlined className={styles.disabled} />
      </Tooltip>
    )
  }

  return (
    <>
      {integrationTree.secret && !showExternalSecretValues ? (
        <DisabledEntry />
      ) : (
        <div className={selected() ? styles.selectedContainer : styles.container} onClick={onClick}>
          {integrationTree.node_type !== NodeTypeEnum.File && (
            <ProviderIcon fqn={integrationTree.fqn} />
          )}
          <div className={selected() ? styles.selectedContent : styles.content}>
            {integrationTree.name}
          </div>
          <div className={selected() ? styles.selectedArrow : styles.arrow}>
            {pending ? (
              <Spin
                indicator={loadingIcon}
                className={selected() ? styles.selectedSpin : undefined}
              />
            ) : (
              rightArrow
            )}
          </div>
        </div>
      )}
    </>
  )
}

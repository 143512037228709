import { Form, FormData, LabeledInputItem, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { EditModal } from 'components/Modals/EditModal'
import { IdentityWithSessions } from 'components/Ory/Admin/ListIdentities/ListIdentities'
import { Input } from 'antd'
import { oryAdmin } from 'components/Ory/ory'
import { updateIdentityEmail } from 'components/Ory'
import { useToast } from 'hooks'

interface Props {
  visible: boolean
  setVisible: (bool: boolean) => void
  updateState: (identity: IdentityWithSessions) => void
  identity: IdentityWithSessions
}

export function EditEmail(props: Props) {
  const { visible, setVisible, updateState, identity } = props
  const [pending, setPending] = useState<boolean>(false)
  const { successToast, errorToast } = useToast()

  const [form] = useForm()
  // const dispatch = useAppDispatch()
  // const { errorToast, successToast } = useToast()

  const onFinish = (formValues: FormData) => {
    setPending(true)
    const { email } = formValues

    updateIdentityEmail(oryAdmin, identity, email)
      .then(({ data }) => {
        successToast('Email successfully updated.')
        updateState(data as IdentityWithSessions)
      })
      .catch(errorToast)
      .then(() => {
        setPending(false)
        onCancel()
      })
  }

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <EditModal
        visible={visible}
        objectName="User"
        afterClose={onCancel}
        onCancel={onCancel}
        onOk={() => form.submit()}
        pending={pending}
      >
        <Form form={form} onFinish={onFinish} initialValues={{ email: identity.traits.email }}>
          <LabeledInputItem name="email" label="Email" required="Please enter email.">
            <Input />
          </LabeledInputItem>
        </Form>
      </EditModal>
    </>
  )
}

import React from 'react'
import styles from './PageDescription.module.scss'

interface Props {
  subtitle?: string | JSX.Element
  buttons?: React.ReactElement
  description?: string
  link?: JSX.Element
}

export function PageDescription(props: Props) {
  const { subtitle, buttons, description, link } = props

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.descriptionContainer}>
          {subtitle && (
            <h3 data-testid="subtitle" className={styles.subtitle}>
              {subtitle}
            </h3>
          )}
          <p className={styles.description}>{description}</p>
          {link}
        </div>
        <div className={styles.buttonContainer}>{buttons}</div>
      </div>
    </div>
  )
}

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons/lib'
import React, { ReactNode, useState } from 'react'

import { QuestionIconTooltip } from './QuestionIconTooltip'
import { Typography } from 'antd'
import styles from './LabelText.module.scss'

interface Props {
  label: string
  text: string | ReactNode
  isHorizontal?: boolean
  maxWidth?: string
  textMaxHeight?: string
  textMaxWidth?: string
  uppercase?: boolean
  paragraph?: boolean
  secret?: boolean
  tooltip?: string
}

export function LabelText(props: Props) {
  const {
    label,
    text,
    isHorizontal,
    maxWidth,
    paragraph,
    uppercase,
    secret,
    textMaxHeight,
    textMaxWidth,
    tooltip,
  } = props
  const [showSecret, setShowSecret] = useState<boolean>(false)
  const className = isHorizontal ? styles.horizontalLabelContainer : styles.verticalLabelContainer
  const iconProps = {
    className: styles.eyeIcon,
    onClick: () => setShowSecret(!showSecret),
  }

  return (
    <div className={className} style={{ maxWidth }}>
      <label className={styles.label}>
        {uppercase ? label.toUpperCase() : label}
        {tooltip && <QuestionIconTooltip title={tooltip} />}
        {secret &&
          (showSecret ? <EyeOutlined {...iconProps} /> : <EyeInvisibleOutlined {...iconProps} />)}
      </label>
      <Typography
        {...(secret && { 'data-private': 'redact' })}
        className={paragraph ? [styles.paragraph, styles.text].join(' ') : styles.text}
        style={{ maxHeight: textMaxHeight, maxWidth: textMaxWidth }}
      >
        {!secret || showSecret ? text : '*****'}
      </Typography>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { FeaturesResponse } from 'gen/cloudTruthRestApi'
import { GetFeatures } from './actions'
import { epoch } from 'data/dataUtils'

interface FeatureState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const featureAdapter = createEntityAdapter<FeaturesResponse>({})

const initialState = featureAdapter.getInitialState({
  cacheLastChanged: epoch,
} as FeatureState)

export type FeatureRootState = EntityState<FeaturesResponse> & FeatureState

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setAllfeature: featureAdapter.setAll,
    addOnefeature: featureAdapter.addOne,
    updateOnefeature: featureAdapter.upsertOne,
    removeOnefeature: featureAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetFeatures.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      featureAdapter.setOne(state, action.payload)
    })
  },
})

export const { resetState, setAllfeature, updateOnefeature, removeOnefeature, addOnefeature } =
  featureSlice.actions

export default featureSlice.reducer

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { GrantRootState, grantAdapter } from './reducer'

import { Grant } from 'gen/cloudTruthRestApi'
import { RootState } from '../store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from '../hooks'

const selectGrant = (state: RootState) => state.grant

export const getCurrentGrant = createSelector(selectGrant, (state): nullable<Grant> => {
  return state.current ? state.entities[state.current]! : null
})

export const getGrantByScope = (scope: maybe<string>): Selector<RootState, Grant[]> => {
  return createSelector(selectGrant, (state): Grant[] => {
    return (Object.values(state.entities) as Grant[]).filter((grant) => grant.scope === scope)
  })
}

export const getProjectGroupGrants = (groupUrl: string): Selector<RootState, Grant[]> => {
  return createSelector(selectGrant, (state): Grant[] => {
    return (Object.values(state.entities) as Grant[]).filter(
      (grant) => grant.scope.includes('project') && grant.principal === groupUrl
    )
  })
}

export const getEnvironmentGroupGrants = (groupUrl: string): Selector<RootState, Grant[]> => {
  return createSelector(selectGrant, (state): Grant[] => {
    return (Object.values(state.entities) as Grant[]).filter(
      (grant) => grant.scope.includes('environment') && grant.principal === groupUrl
    )
  })
}

export const getUserGrant = (scope: string, userUrl: string): Selector<RootState, Grant> => {
  return createSelector(selectGrant, (state): Grant => {
    return (Object.values(state.entities) as Grant[])
      .filter((grant) => grant.scope === scope)
      .find((grant) => grant.principal === userUrl)!
  })
}

export const grantEntitySelectors = grantAdapter.getSelectors()
export const useSelectGrants = (): GrantRootState => useSelectState().grant

import React, { PropsWithChildren, useEffect, useState } from 'react'
import { ory, useLogoutHandler } from './Ory'

import { ActionButton } from './ActionButton'
import { buildQuery } from 'data/dataUtils'
import { useHistoryPush } from 'router/customHooks'
import { useNavigate } from 'react-router-dom'

export function Auth2AuthGate(props: PropsWithChildren<any>) {
  const { children } = props
  const [loading, setLoading] = useState(true)

  const {
    goToLoginRoute,
    goToMfaSetupRoute,
    goToOryAdminRoute,
    goToRecoveryRoute,
    goToRegistrationRoute,
    goToOrySettingsRoute,
  } = useHistoryPush()

  const navigate = useNavigate()
  const logout = useLogoutHandler()

  useEffect(() => {
    ory
      .toSession()
      .then(async ({ data }) => {
        // const recovery = data.identity.verifiable_addresses
        // if (recovery && recovery.length > 0) {
        //   console.log(!recovery[0].verified)
        //   if (!recovery[0].verified) {
        //     navigate(`/verification`)
        //   }
        // } else {

        if (data.authenticator_assurance_level === 'aal1') {
          const flow = await ory.createBrowserSettingsFlow()
          const totpNodes = flow.data.ui.nodes.filter((node) => node.group === 'totp')

          totpNodes.length > 1
            ? goToMfaSetupRoute()
            : navigate(`/login${buildQuery({ aal: 'aal2' })}`)
        }

        // }
      })
      .catch(() => {
        if (window.location.pathname.includes('registration')) {
          goToRegistrationRoute()
        } else if (window.location.pathname.includes('recovery')) {
          goToRecoveryRoute()
        } else {
          goToLoginRoute()
        }
      })
      .finally(() => setLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  if (loading) {
    return null
  }

  return (
    <div>
      {children}
      <ActionButton onClick={logout}>Logout</ActionButton>
      <ActionButton onClick={goToOrySettingsRoute}>Settings</ActionButton>
      <ActionButton onClick={goToOryAdminRoute}>Admin</ActionButton>
    </div>
  )
}

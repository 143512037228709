//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateAzureKeyVaultPush,
  DeleteAzureKeyVaultPush,
  GetAzureKeyVaultPush,
  GetAzureKeyVaultPushes,
  UpdateAzureKeyVaultPush,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AzureKeyVaultPush } from 'gen/cloudTruthRestApi'
import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { epoch } from 'data/dataUtils'

interface PushState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedIntegrations: string[]
}

export const akvPushAdapter = createEntityAdapter<AzureKeyVaultPush>({
  selectId: (int) => int.id,
})

const initialState = akvPushAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  cachedIntegrations: [],
} as PushState)

export type PushRootState = EntityState<AzureKeyVaultPush> & PushState

const akvPushSlice = createSlice({
  name: 'akvpush',
  initialState,
  reducers: {
    // direct actions
    selectPush(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllPush: akvPushAdapter.setAll,
    addOnePush: akvPushAdapter.addOne,
    updateOnePush: akvPushAdapter.upsertOne,
    removeOnePush: akvPushAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAzureKeyVaultPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPushAdapter.addOne(state, action.payload)
    })

    builder.addCase(GetAzureKeyVaultPushes.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      if (!state.cachedIntegrations.includes(action.meta.arg)) {
        state.cachedIntegrations.push(action.meta.arg)
      }
      akvPushAdapter.setMany(state, action.payload.results!)
    })
    builder.addCase(GetAzureKeyVaultPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPushAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateAzureKeyVaultPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPushAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAzureKeyVaultPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvPushAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const { resetState, selectPush, setAllPush, addOnePush, updateOnePush, removeOnePush } =
  akvPushSlice.actions

export default akvPushSlice.reducer

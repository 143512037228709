import React, { PropsWithChildren } from 'react'

import { ConfigProvider } from 'antd'

/* eslint @typescript-eslint/ban-types: 0 */
/* eslint @typescript-eslint/naming-convention: 0 */

export function AntTheme(props: PropsWithChildren<{}>) {
  const { children } = props

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#154e9d',

            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },
          Radio: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },
          Pagination: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },
          Tabs: {
            colorPrimary: '#000000',
            colorPrimaryBg: '#154e9d',
            colorBorderSecondary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
            colorBorder: '#154e9d',
          },

          Switch: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },

          // TreeSelect: {
          //   colorPrimary: '#154e9d',
          //   colorPrimaryHover: '#154e9d',
          //   colorPrimaryActive: '#154e9d',
          //   colorBgTextActive: '#154e9d',
          // },

          Input: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
            colorBgTextActive: '#154e9d',
          },

          Checkbox: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },

          Tree: {
            colorPrimary: '#154e9d',
            colorPrimaryHover: '#154e9d',
            colorPrimaryActive: '#154e9d',
          },

          Spin: {
            colorPrimary: '#003a65',
            colorPrimaryHover: '#003a65',
            colorPrimaryActive: '#003a65',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

import { Spin, Tooltip } from 'antd'

import React from 'react'
import styles from './ToggleSecrets.module.scss'

interface Props {
  handleToggleClick: () => void
  showing: boolean
  text?: string
  hideText?: string
  showText?: string
  loading?: boolean
  cyData?: string
  toolTip?: string
}

export function ToggleText(props: Props) {
  const { handleToggleClick, showing, text, hideText, showText, loading, cyData, toolTip } = props

  const handleClick = () => {
    handleToggleClick()
  }

  if (loading) {
    return <Spin size="small" className={styles.spin} />
  }

  return (
    <>
      {showing ? (
        <>
          <Tooltip title={toolTip} placement="bottom" className={styles.tooltip}>
            <span
              onClick={handleClick}
              className={styles.showSecrets}
              data-cy={cyData || `toggle_hide_${text?.toLowerCase() || hideText?.toLowerCase()}`}
            >
              {hideText ? hideText : `Hide ${text}`}
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={toolTip} placement="bottom" className={styles.tooltip}>
            <span
              onClick={handleClick}
              className={styles.showSecrets}
              data-cy={cyData || `toggle_show_${text?.toLowerCase() || showText?.toLowerCase()}`}
            >
              {showText ? showText : `Show ${text}`}
            </span>
          </Tooltip>
        </>
      )}
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { MessageTile } from './MessageTile'
import { getSessionErrors } from 'data/session/selectors'
import { markErrorAsRead } from 'data/session/reducer'
import styles from './Messages.module.scss'

export function Messages() {
  const sessionErrors = useAppSelector(getSessionErrors)
  const messages = Object.values(sessionErrors)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      Object.values(sessionErrors).forEach((message) => {
        if (!message.read) {
          dispatch(markErrorAsRead(message.id))
        }
      })
    }
    // run only on unmount to mark all unread messages as read
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      {messages.length > 0 ? (
        messages
          .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
          .map((message) => {
            return <MessageTile key={message.id} message={message} />
          })
      ) : (
        <p className={styles.noMessage}>No messages to display</p>
      )}
    </div>
  )
}

import { LoggerUtils } from 'lib/loggerUtils'
import React from 'react'
import { Result } from 'antd'

export default function SentryServerError() {
  LoggerUtils.captureMessage('An error occurred while loading resources', undefined)

  return (
    <Result
      status="500"
      title="Internal Server Error"
      subTitle="CloudTruth encountered a problem with your request. Our team has been alerted and will fix the problem as quickly as possible."
    />
  )
}

import * as faker from 'faker'

import { Empty, Table, TableProps } from 'antd'
import { Identity, Session } from '@ory/kratos-client'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { AddIdentity } from '../CrudModels/AddIdentity/AddIdentity'
import { AdminActionsDetailsDropdown } from '../AdminActionsDropdown'
import { OrganizationLayout } from 'components/Layout'
import dayjs from 'dayjs'
import { oryAdmin } from 'components/Ory/ory'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from './ListIdentities.module.scss'
import { useHistoryPush } from 'router/customHooks'

export interface IdentityWithSessions extends Identity {
  sessions?: Session[]
}

export function ListIdentities() {
  const [identities, setIdentities] = useState<IdentityWithSessions[]>()
  const [loading, setLoading] = useState(true)
  const { goToOryAdminUserPage } = useHistoryPush()
  const [addVisible, setAddVisible] = useState(false)

  const updateIdentity = (identity: IdentityWithSessions) =>
    setIdentities(
      identities?.map((prev) =>
        prev.id === identity.id ? { ...identity, sessions: prev?.sessions } : prev
      )
    )

  useEffect(() => {
    oryAdmin.listIdentities().then(async (response) => {
      let identitiesHash: any = {}

      response.data.forEach((identity) => {
        identitiesHash = { ...identitiesHash, [identity.id]: identity }
      })

      const allSessionsPromise = Object.keys(identitiesHash).map((id) => {
        return new Promise((resolve) => {
          oryAdmin
            .listIdentitySessions({ id })
            .then(({ data: sessions }) => (identitiesHash[id]['sessions'] = sessions))
            .then(() => resolve({}))
        })
      })

      Promise.all(allSessionsPromise)
        .then(() => console.log(identitiesHash))
        .then(() => setIdentities(Object.values(identitiesHash)))
        .then(() => setLoading(false))
    })
  }, [])

  const columns: TableProps<IdentityWithSessions>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_value, identity) => {
        return (
          <div className={styles.nameContainer}>
            <span
              className={styles.name}
              onClick={() => goToOryAdminUserPage(identity.id)}
            >{`${identity.traits.name.first} ${identity.traits.name.last}`}</span>
            <span className={styles.email}>{identity.traits.email}</span>
          </div>
        )
      },
    },
    {
      title: 'Connection',
      dataIndex: 'connection',
      key: 'connection',
      render: (_value, identity) => {
        if (!identity.sessions) {
          return 'No Sessions'
        }
        const authMethods = identity.sessions.at(-1)?.authentication_methods
        return (
          <div className={styles.nameContainer}>
            {authMethods ? authMethods[0].method : 'Unavailable'}
          </div>
        )
      },
    },
    {
      title: 'Sessions',
      dataIndex: 'session',
      key: 'session',
      render: (_value, identity) => {
        if (!identity.sessions) {
          return 'No Sessions'
        }
        return <div className={styles.nameContainer}>{identity.sessions.length}</div>
      },
    },
    {
      title: 'Latest Session',
      dataIndex: 'latestSession',
      key: 'latestSession',
      render: (_value, identity) => {
        if (!identity.sessions) {
          return 'No Sessions'
        }
        const latestSession = identity.sessions.at(-1)?.issued_at
        dayjs.extend(relativeTime)
        return (
          <div className={styles.nameContainer}>
            {latestSession ? dayjs(latestSession as any).fromNow() : 'Unavailable'}
          </div>
        )
      },
    },

    {
      title: 'ACTIONS',
      width: 100,
      render: (_, identity) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AdminActionsDetailsDropdown
            identity={identity}
            updateIdentity={updateIdentity}
            filterIdentities={(id: string) =>
              setIdentities((prev) => prev?.filter((i) => i.id !== id))
            }
          >
            <span className={styles.moreIconContainer}>
              <MoreOutlined className={styles.moreIcon} />
            </span>
          </AdminActionsDetailsDropdown>
        </div>
      ),
    },
  ]

  return (
    <OrganizationLayout>
      <AddIdentity visible={addVisible} setVisible={setAddVisible} setIdentities={setIdentities} />
      <div className={styles.container}>
        <div className={styles.tableContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              <h1>Users</h1>
              <ActionButton
                icon={<PlusOutlined className={styles.plusIcon} />}
                type="primary"
                onClick={() => setAddVisible(true)}
              >
                Create User
              </ActionButton>
            </div>
            <p className={styles.description}>{faker.lorem.paragraph()}</p>
          </div>
          <Table
            dataSource={identities}
            loading={loading}
            columns={columns}
            locale={{ emptyText: loading ? undefined : <Empty /> }}
          />
        </div>
      </div>
    </OrganizationLayout>
  )
}

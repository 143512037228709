import React, { useState } from 'react'

import { EditEmail } from 'components/Ory/Admin/CrudModels/EditEmail/EditEmail'
import { EditName } from 'components/Ory/Admin/CrudModels/EditName/EditName'
import { IdentityWithSessions } from '../ListIdentities'
import { LabelText } from 'components/LabelText'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './UserDetailsCard.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  identity: IdentityWithSessions
  updateIdentity: (identity: IdentityWithSessions) => void
}

export function UserDetailsCard(props: Props) {
  const { identity, updateIdentity } = props
  const [emailVisible, setEmailVisible] = useState(false)
  const [nameVisible, setNameVisible] = useState(false)

  const latestSession = identity.sessions ? identity.sessions.at(-1)?.issued_at : null

  const linkedProviders = () => {
    const credentials = identity.credentials
    const password = credentials?.password ? credentials.password.identifiers : null
    const oidc = credentials?.oidc ? credentials.oidc.identifiers : null

    const passwordString = password && password.length > 0 ? ['email/password'] : [null]
    const oidcString =
      oidc && oidc.length > 0
        ? oidc.map((oidc) => {
            const [provider] = oidc.split(':')
            return provider
          })
        : [null]

    const linkedArray = [...passwordString, ...oidcString].filter((notNull) => notNull)

    return linkedArray.join(', ')
  }

  const verified = () =>
    !identity?.verifiable_addresses
      ? false
      : identity.verifiable_addresses.some((identity) => identity.verified)

  return (
    <div className={styles.container}>
      <EditEmail
        visible={emailVisible}
        setVisible={setEmailVisible}
        identity={identity}
        updateState={updateIdentity}
      />

      <EditName
        visible={nameVisible}
        setVisible={setNameVisible}
        identity={identity}
        updateState={updateIdentity}
      />
      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <div className={styles.rowContent}>
            <LabelText
              label="Name"
              text={`${identity?.traits.name.first} ${identity?.traits.name.last}`}
            />
            <a onClick={() => setNameVisible(true)}>Edit</a>
          </div>

          <div className={styles.rowContent}>
            <LabelText label="Email" text={identity.traits.email} />
            <a onClick={() => setEmailVisible(true)}>Edit</a>
          </div>

          <div className={styles.rowContent}>
            <LabelText label="Signed Up" text={formatDateTime(identity.created_at!)} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowContent}>
            <LabelText
              label="Latest Login"
              text={latestSession ? formatDateTime(latestSession) : valueOrEmDash(undefined)}
            />
          </div>
          <div className={styles.rowContent}>
            <LabelText label="Linked Accounts" text={linkedProviders()} />
          </div>
          <div className={styles.rowContent}>
            <LabelText label="Verified" text={String(verified())} />
          </div>
        </div>
      </div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { CreateAwsPush, DeleteAwsPush, GetAwsPush, GetAwsPushes, UpdateAwsPush } from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AwsPush } from 'gen/cloudTruthRestApi'
import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { epoch } from 'data/dataUtils'

interface PushState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedIntegrations: string[]
}

export const awsPushAdapter = createEntityAdapter<AwsPush>({
  selectId: (int) => int.id,
})

const initialState = awsPushAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  cachedIntegrations: [],
} as PushState)

export type PushRootState = EntityState<AwsPush> & PushState

const awsPushSlice = createSlice({
  name: 'awspush',
  initialState,
  reducers: {
    // direct actions
    selectPush(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllPush: awsPushAdapter.setAll,
    addOnePush: awsPushAdapter.addOne,
    updateOnePush: awsPushAdapter.upsertOne,
    removeOnePush: awsPushAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAwsPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPushAdapter.addOne(state, action.payload)
    })

    builder.addCase(GetAwsPushes.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      if (!state.cachedIntegrations.includes(action.meta.arg)) {
        state.cachedIntegrations.push(action.meta.arg)
      }
      awsPushAdapter.setMany(state, action.payload.results!)
    })
    builder.addCase(GetAwsPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPushAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateAwsPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPushAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAwsPush.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      awsPushAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const { resetState, selectPush, setAllPush, addOnePush, updateOnePush, removeOnePush } =
  awsPushSlice.actions

export default awsPushSlice.reducer

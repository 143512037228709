//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionEnum, AsyncJob, getAsyncJobs } from '../utils'
import { PullRootState, githubPullAdapter } from './reducer'

import { AwsPull } from 'gen/cloudTruthRestApi'
import { GetGithubPull } from './actions'
import { RootState } from 'data/store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { integrationIdFromGithubPullUrl } from 'data/dataUtils'
import { useSelectState } from 'data/hooks'

const selectGithubPulls = (state: RootState) => state.githubPull

export const getCurrentPull = createSelector(selectGithubPulls, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getIntegrationPulls = (integrationId: maybe<string>): Selector<RootState, AwsPull[]> =>
  createSelector(selectGithubPulls, (state): AwsPull[] => {
    return (Object.values(state.entities) as AwsPull[])
      .filter(
        (pull) => !integrationId || integrationId === integrationIdFromGithubPullUrl(pull.url)
      )
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
  })

export const getRunningGithubPulls = createSelector(selectGithubPulls, (state): AsyncJob[] => {
  return getAsyncJobs(state).map((job) => ({
    ...job,
    type: ActionEnum.GithubPull,
    action: GetGithubPull,
  }))
})

export const githubPullEntitySelectors = githubPullAdapter.getSelectors()
export const useSelectGithubPull = (): PullRootState => useSelectState().githubPull

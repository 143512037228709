import { AwsRegionEnum } from 'gen/cloudTruthRestApi'

export interface RegionObject {
  regionLabel: RegionLocations
  regions: Region[]
}

export interface RegionDisplayListObject {
  regionLabel: RegionObject['regionLabel']
  regions: AwsRegionEnum[]
}

export interface Region {
  label: string
  value: AwsRegionEnum
}

export enum RegionLocations {
  UnitedStates = 'United States',
  AsiaPacific = 'Asia Pacific',
  Europe = 'Europe',
  China = 'China',
  Africa = 'Africa',
  Canada = 'Canada',
  MiddleEast = 'Middle East',
  SouthAmerica = 'South America',
}

enum RegionPrefix {
  US = 'US',
  AF = 'AF',
  AP = 'AP',
  CA = 'CA',
  CN = 'CN',
  EU = 'EU',
  ME = 'ME',
  SA = 'SA',
}

export const regionToDisplayName: { [key in AwsRegionEnum]: string } = {
  [AwsRegionEnum.UsEast1]: 'US East 1 (N. Virginia)',
  [AwsRegionEnum.UsEast2]: 'US East 2 (Ohio)',
  [AwsRegionEnum.UsWest1]: 'US West 1 (N. California)',
  [AwsRegionEnum.UsWest2]: 'US West 2 (Oregon)',
  [AwsRegionEnum.AfSouth1]: 'AF South 1 (Cape Town)',
  [AwsRegionEnum.ApEast1]: 'AP East 1 (Hong Kong)',
  [AwsRegionEnum.ApNortheast1]: 'AP Northeast 1 (Tokyo)',
  [AwsRegionEnum.ApNortheast2]: 'AP Northeast 2 (Seoul)',
  [AwsRegionEnum.ApNortheast3]: 'AP Northeast 3 (Osaka)',
  [AwsRegionEnum.ApSouth1]: 'AP South 1 (Mumbai)',
  [AwsRegionEnum.ApSoutheast1]: 'AP Southeast 1 (Singapore)',
  [AwsRegionEnum.ApSoutheast2]: 'AP Southeast 2 (Sydney)',
  [AwsRegionEnum.CaCentral1]: 'CA Central 1 (Montreal)',
  [AwsRegionEnum.CnNorth1]: 'CN North 1 (Beijing)',
  [AwsRegionEnum.CnNorthwest1]: 'CN Northwest 1 (Ningxia)',
  [AwsRegionEnum.EuCentral1]: 'EU Central 1 (Frankfurt)',
  [AwsRegionEnum.EuNorth1]: 'EU North 1 (Stockholm)',
  [AwsRegionEnum.EuSouth1]: 'EU South 1 (Milan)',
  [AwsRegionEnum.EuWest1]: 'EU West 1 (Ireland)',
  [AwsRegionEnum.EuWest2]: 'EU West 2 (London)',
  [AwsRegionEnum.EuWest3]: 'EU West 3 (Paris)',
  [AwsRegionEnum.MeSouth1]: 'ME South 1 (Bahrain)',
  [AwsRegionEnum.SaEast1]: 'SA East 1 (São Paulo)',
}

export const checkRegion = ([regionKey]: AwsRegionEnum[]): nullable<RegionLocations> => {
  const regionPrefix = regionKey.substring(0, 2).toUpperCase()

  switch (regionPrefix) {
    case RegionPrefix.US: {
      return RegionLocations.UnitedStates
    }
    case RegionPrefix.AF: {
      return RegionLocations.Africa
    }
    case RegionPrefix.AP: {
      return RegionLocations.AsiaPacific
    }
    case RegionPrefix.CA: {
      return RegionLocations.Canada
    }
    case RegionPrefix.CN: {
      return RegionLocations.China
    }
    case RegionPrefix.EU: {
      return RegionLocations.Europe
    }
    case RegionPrefix.ME: {
      return RegionLocations.MiddleEast
    }
    case RegionPrefix.SA: {
      return RegionLocations.SouthAmerica
    }
    default: {
      return null
    }
  }
}

export const formatRegionInputArray = Object.values(RegionLocations).map((regionLabel) => ({
  regionLabel: regionLabel,
  regions: (Object.entries(regionToDisplayName) as AwsRegionEnum[][])
    .filter((region) => checkRegion(region) === regionLabel)
    .map((region) => ({ value: region[0], label: region[1] })) as Region[],
}))

export const formatRegionDisplayList = (regions: AwsRegionEnum[]): RegionDisplayListObject[] => {
  let regionList: RegionDisplayListObject[] = []

  const addRegionObject = (index: number, label: RegionLocations, region: AwsRegionEnum) => {
    regionList[index].regionLabel = label
    regionList[index].regions = [...regionList[index].regions, region]
  }

  regions.map((region) => {
    const label = checkRegion([region])!
    const index = regionList.findIndex((i) => i.regionLabel === label)

    index >= 0
      ? addRegionObject(index, label!, region)
      : (regionList = [...regionList, { regionLabel: label, regions: [region] }])
  })

  return regionList
}

export const formatUsRegionDisplay = (regions: AwsRegionEnum[]): RegionDisplayListObject[] => {
  const usRegionList: RegionDisplayListObject[] = [
    { regionLabel: RegionLocations.UnitedStates, regions: [] },
  ]

  regions.forEach((region) => {
    if (checkRegion([region]) === RegionLocations.UnitedStates) {
      usRegionList[0].regions = [...usRegionList[0].regions, region]
    }
  })

  return usRegionList
}

export function displayNameForRegion(region: AwsRegionEnum): string {
  return regionToDisplayName[region]
}

import { Button, ButtonProps } from 'antd'
import { PlusOutlined, RightOutlined } from '@ant-design/icons'

import React from 'react'
import styles from './ActionButton.module.scss'

interface Props extends ButtonProps {
  customType?: 'add' | 'redirect'
  dataCy?: string
}

export function ActionButton(props: Props) {
  const { type, danger, customType, children, dataCy, ...buttonProps } = props

  if (customType === 'add') {
    return (
      <Button
        type="default"
        className={styles.button}
        icon={<PlusOutlined />}
        data-cy={dataCy}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  }

  if (customType === 'redirect') {
    return (
      <Button type="default" className={styles.button} data-cy={dataCy} {...buttonProps}>
        {children}
        <RightOutlined />
      </Button>
    )
  }

  return (
    <Button type={type} danger={danger} className={styles.button} data-cy={dataCy} {...buttonProps}>
      {children}
    </Button>
  )
}

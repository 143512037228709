import React from 'react'

export function ResourceDescription() {
  return (
    <>
      <span>
        The following{' '}
        <a target="_blank" href="https://mustache.github.io/mustache.5.html" rel="noreferrer">
          mustache-style
        </a>{' '}
        templating substitutions can be used in the name:
        <br />
        <ul style={{ listStyleType: 'none' }}>
          <li>{"{{ environment }} to insert the tag's environment name"}</li>
          <li>{'{{ parameter }} to insert the parameter name'}</li>
          <li>{'{{ project }} to insert the project name'}</li>
          <li>{'{{ push }} to insert the push name'}</li>
          <li>{'{{ tag }} to insert the tag name'}</li>
        </ul>
      </span>
      <span>
        We recommend that you use project, environment, and parameter at a minimum to disambiguate
        your pushed resource names.
        <br />
        <br />
      </span>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateGithubPull,
  DeleteGithubPull,
  GetGithubPull,
  GetGithubPulls,
  UpdateGithubPull,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { GitHubPull } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface PullState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedIntegrations: string[]
}

export const githubPullAdapter = createEntityAdapter<GitHubPull>({
  selectId: (int) => int.id,
})

const initialState = githubPullAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  cachedIntegrations: [],
} as PullState)

export type PullRootState = EntityState<GitHubPull> & PullState

const githubPullSlice = createSlice({
  name: 'githubPull',
  initialState,
  reducers: {
    // direct actions
    selectGithubPull(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllGithubPull(state, action: PayloadAction<GitHubPull[]>) {
      githubPullAdapter.setAll(state, action.payload)
    },

    addOneGithubPull: githubPullAdapter.addOne,
    updateOneGithubPull: githubPullAdapter.upsertOne,
    removeOneGithubPull: githubPullAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateGithubPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubPullAdapter.addOne(state, action.payload)
    })

    builder.addCase(GetGithubPulls.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      if (!state.cachedIntegrations.includes(action.meta.arg)) {
        state.cachedIntegrations.push(action.meta.arg)
      }
      githubPullAdapter.setMany(state, action.payload.results!)
    })
    builder.addCase(GetGithubPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubPullAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateGithubPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubPullAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteGithubPull.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      githubPullAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
  },
})

export const {
  resetState,
  selectGithubPull,
  setAllGithubPull,
  addOneGithubPull,
  updateOneGithubPull,
  removeOneGithubPull,
} = githubPullSlice.actions

export default githubPullSlice.reducer

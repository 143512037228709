import { CustomThunk, idFromUrl, projectIdFromAnyUrl } from 'data/dataUtils'
import { Environment, Parameter, Value } from 'gen/cloudTruthRestApi'
import { GetParameter, GetValue } from 'data/parameter/actions'
import React, { useMemo, useState } from 'react'
import { Spin, Tree, Typography } from 'antd'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Label } from 'components/Forms'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { ToggleText } from 'components/ToggleText'
import { ValueError } from 'components/Table'
import { getCurrentProject } from 'data/project/selectors'
import { getValueByEnv } from 'data/parameter/selectors'
import { showSecretValueOrAstricks } from 'lib/secretsHelpers'
import styles from './ParameterEnvironmentsTree.module.scss'
import { updateValue } from 'data/parameter/reducer'
import { useToast } from 'hooks'
import { validateString } from 'lib/valueHelpers'

const { Text } = Typography

interface Props {
  parameter: Parameter
  showSecretValues: boolean
  setShowSecretValues: () => void
  showSecretLoading: boolean
  selectedEnvironment: string
  setSelectedEnvironment: (env: string) => void
  disabled?: boolean
  width?: string
  showEvaluated: Record<string, boolean>
  setShowEvaluated: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  hideEvaluate?: boolean
  onSelect: (environment: string) => void
}

interface TreeNodeObj {
  key: string
  title: JSX.Element
  children: TreeNodeObj[]
}

interface DisplayValue {
  value: nullable<Value>
  showSecretValues: boolean
  isSecret: boolean
  environmentUrl: string
}

export function ParameterEnvironmentsTree(props: Props) {
  const {
    showSecretValues,
    setShowSecretValues,
    showSecretLoading,
    selectedEnvironment,
    setSelectedEnvironment,
    disabled,
    parameter,
    width,
    showEvaluated,
    setShowEvaluated,
    hideEvaluate,
    onSelect,
  } = props

  const selectEnv = useSelectEnvironments()
  const { secret } = parameter
  const { url: baseEnvUrl, name: baseEnvName } = useAppSelector(getBaseEnvironment)!

  const baseEnvValue = useAppSelector(getValueByEnv(parameter.id, baseEnvUrl))!
  const currentProject = useAppSelector(getCurrentProject)!

  const environments = environmentEntitySelectors.selectAll(selectEnv)!
  const hasSecret = Object.values(parameter.values).some((value) => value?.secret)

  const [evaluateLoading, setEvaluateLoading] = useState(false)
  const [showAllLoading, setShowAllLoading] = useState(false)
  const [showAllLoadingButton, setShowAllLoadingButton] = useState(
    Object.keys(parameter.values).length !== environments.length
  )

  const showAllValues = () => {
    setShowAllLoadingButton(false)

    dispatch(
      GetParameter({ paramId: parameter.id, projectId: currentProject.id, maskSecret: false })
    ).then(({ _, error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setShowAllLoading(false)
    })
  }

  const selectedValue = useMemo(() => {
    return parameter.values[selectedEnvironment]!
  }, [selectedEnvironment, parameter])

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const evaluateRaw = () => {
    setEvaluateLoading(true)

    dispatch(
      GetValue({
        paramId: selectedValue.parameter_id,
        projectId: projectIdFromAnyUrl(selectedValue.url),
        maskSecret: !showSecretValues,
        valueId: selectedValue.id,
        evaluate: !showEvaluated[selectedEnvironment],
        environment: idFromUrl(selectedEnvironment),
      })
    )
      .then(({ payload, error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)

          return
        } else {
          dispatch(
            updateValue({
              value: payload,
              parameterId: parameter.id,
              valueEnvUrl: selectedEnvironment,
            })
          )
        }
      })
      .then(() => {
        setShowEvaluated((prev) => ({
          ...prev,
          [selectedEnvironment]: !prev[selectedEnvironment],
        }))
        setEvaluateLoading(false)
      })
  }

  const DisplayValue = (props: DisplayValue) => {
    const { environmentUrl, value, isSecret } = props

    const { environment } = value || {}

    const envValue = value?.interpolated
      ? showEvaluated[environment || '']
        ? value?.value
        : value?.internal_value
      : value?.value

    const error = value?.external_error

    const sourceEnvName = environment
      ? environmentEntitySelectors.selectById(selectEnv, idFromUrl(environment))!.name
      : baseEnvName

    const { name: envName } = environmentEntitySelectors.selectById(
      selectEnv,
      idFromUrl(environmentUrl)
    )!

    const source = sourceEnvName ? sourceEnvName : envName === baseEnvName ? '' : baseEnvName

    return (
      <span className={styles.entry} data-cy={`${envName}-container`}>
        <Text
          className={styles.environmentName}
          data-cy={`option-${envName}`}
          data-testid={`tree-option-${envName}`}
        >
          {envName}
        </Text>

        {!parameter!.values[environmentUrl] ? (
          <></>
        ) : (
          <>
            {sourceEnvName !== envName ? (
              <Text className={styles.inheritedFrom}>{source ? `(${source})` : ''} </Text>
            ) : (
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Text className={styles.valueContainer}>
                  <span
                    data-private="redact"
                    className={styles.parameterValue}
                    data-cy={`tree-${envValue}`}
                  >
                    {error ? (
                      <ValueError error={error} inTree />
                    ) : (
                      showSecretValueOrAstricks(
                        isSecret,
                        showSecretValues,
                        validateString(envValue)
                      )
                    )}
                  </span>
                </Text>
              </div>
            )}
          </>
        )}
      </span>
    )
  }

  const assembleTree = (
    key: string,
    envValue: nullable<Value>,
    allEnvironmentValues: Environment[],
    showSecretValues: boolean,
    isSecret: boolean
  ): TreeNodeObj => {
    return {
      key: key,
      title: (
        <DisplayValue
          value={envValue}
          data-cy={`option-${envValue}`}
          showSecretValues={showSecretValues}
          isSecret={isSecret}
          environmentUrl={key}
        />
      ),
      children: environments
        .filter(({ parent }) => key === parent)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((environment) => {
          return assembleTree(
            environment.url,
            parameter.values[environment.url],
            allEnvironmentValues,
            showSecretValues,
            isSecret
          )
        }),
    }
  }

  const treeData = assembleTree(baseEnvUrl, baseEnvValue, environments, showSecretValues, !!secret)

  return (
    <div className={styles.container} style={{ width }}>
      <div className={styles.toggleSecretsContainer}>
        <Label text="environments" />

        <div className={styles.buttonContainer}>
          {showAllLoadingButton && (
            <>
              {showAllLoading ? (
                <Spin size="small" />
              ) : (
                <div
                  className={styles.showAllValues}
                  onClick={() => {
                    setShowAllLoading(true), showAllValues()
                  }}
                >
                  Show All Values
                </div>
              )}
            </>
          )}

          {(secret || hasSecret) && (
            <ToggleSecrets
              handleToggleClick={setShowSecretValues}
              showSecretValues={showSecretValues}
              disabled={showSecretLoading}
            />
          )}

          {selectedValue?.interpolated && !hideEvaluate && (
            <ToggleText
              loading={evaluateLoading}
              handleToggleClick={evaluateRaw}
              showing={!!showEvaluated[selectedEnvironment]}
              hideText="Show Raw"
              showText="Evaluate"
              cyData="evaluate"
            />
          )}
        </div>
      </div>
      <div className={styles.environmentTreeContainer}>
        <Tree
          className={styles.tree}
          disabled={showAllLoading || disabled}
          treeData={[treeData]}
          defaultExpandAll
          selectedKeys={[selectedEnvironment]}
          onSelect={(selectedKeys) => {
            if (selectedKeys.length > 0) {
              if (!parameter.values[selectedKeys[0].toString()]) {
                onSelect(selectedKeys[0].toString())
              } else {
                if (!parameter.values[selectedKeys[0].toString()]) {
                  onSelect(selectedKeys[0].toString())
                }
              }

              setSelectedEnvironment(selectedKeys[0].toString())
            }
          }}
          data-cy="envSelectTree"
        />
      </div>
    </div>
  )
}

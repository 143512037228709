//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { IntegrationsExploreListParams, PaginatedIntegrationNodeList } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetIntegrationExplorer = createAsyncThunk<
  PaginatedIntegrationNodeList,
  IntegrationsExploreListParams,
  ThunkConfig
>(
  'integrations/explore',
  async (params, thunkApi) =>
    await handleFetch(Verbs.Get, 'integrations/explore', null, thunkApi, buildQuery(params))
)

import {
  ActionCard,
  CenterLink,
  Flow,
  MarginCard,
  handleFlowError,
  handleGetFlowError,
  ory,
  useLogoutHandler,
} from '..'
import { Link, useNavigate } from 'react-router-dom'
import { LoginFlow, UpdateLoginFlowBody } from '@ory/kratos-client'
import { OryQueryParamName, useHistoryPush, useQuery } from 'router/customHooks'
import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import { CardTitle } from '@ory/themes'
import styles from './Login.module.scss'
import { useToast } from 'hooks'

/**
 * Login flow for ory
 *
 * First get query params returnTo, flowId, refresh, aal
 *
 * It works by requesting for the UI nodes to be displayed which are provided by the ory server
 * once the component mounts. The nomenclature for this is called "flow". In the first
 * useEffect we initialize the flow. If there was a previous attempt of logging in the
 * flowId is appeneded to the url params, and we continue with the previous flow.
 * If the flowId is not in the URL we start a new login flow.
 *
 * Once we initialize the flow, the flow is held in state. You can think of flow as an array
 * of objects that represent UI nodes. That flow object is then passed down to the flow component.
 * That flow component knows how to dynamically render all the UI nodes that are passed from the
 * Ory server. Everything in Ory/components are reusable pieces that displays form input fields and buttons
 * based on the flow object we get back from the Ory server.
 *
 * The submit function gets passed down to the flow component. The submit value logic gets handled in the flow
 * component because the values will change based on email/password, and social auth (oidc).
 *
 */
export function Login() {
  const [flow, setFlow] = useState<LoginFlow>()
  const [loading, setLoading] = useState(true)
  const { errorToast } = useToast()
  const router = useNavigate()
  const { goHome } = useHistoryPush()
  const { getOryQuery } = useQuery()
  const returnTo = getOryQuery(OryQueryParamName.ReturnTo)
  const flowId = getOryQuery(OryQueryParamName.Flow)
  // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
  // of a user.
  const refresh = getOryQuery(OryQueryParamName.Refresh)
  // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
  // to perform two-factor authentication/verification.
  const aal = getOryQuery(OryQueryParamName.Aal)
  // This might be confusing, but we want to show the user an option
  // to sign out if they are performing two-factor authentication!

  const onLogout = useLogoutHandler(['aal1', refresh])

  const mfaLogout = () => {
    onLogout()
    setFlow(undefined)
  }

  useEffect(() => {
    const done = () => setLoading(false)

    // If we already have a flow, do nothing.
    if (flow) {
      done()
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId && !aal) {
      ory
        .getLoginFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data)
        })

        .catch(handleGetFlowError(router, 'login', setFlow, errorToast))
        .finally(done)
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserLoginFlow({ aal: aal ? aal : undefined })
      .then(({ data }) => {
        setFlow(data)
      })

      .catch(handleFlowError(router, 'login', setFlow, errorToast))
      .finally(done)
  }, [flowId, router, aal, refresh, returnTo, flow, errorToast])

  const onSubmit = (values: UpdateLoginFlowBody): any =>
    // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
    // his data when she/he reloads the page.

    new Promise((resolve) => {
      router(`/login?flow=${flow?.id}`)
      resolve({})
    })

      .then(
        () => ory.updateLoginFlow({ flow: String(flow?.id), updateLoginFlowBody: values })
        // We logged in successfully! Let's bring the user home.
      )
      .then(goHome)
      .catch(handleFlowError(router, 'login', setFlow, errorToast))
      .catch((err: AxiosError) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data)
          return
        }

        return Promise.reject(err)
      })

  if (loading) {
    return <div className={styles.container} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} />
          </div>
          <h2>Welcome</h2>
        </div>
        <MarginCard>
          <CardTitle className={styles.title}>
            {(() => {
              if (flow?.refresh) {
                return 'Confirm Action'
              } else if (flow?.requested_aal === 'aal2') {
                return 'Two-Factor Authentication'
              }
              return 'Sign in to CloudTruth'
            })()}
          </CardTitle>
          <Flow onSubmit={onSubmit} flow={flow} method={aal === 'aal?' ? 'totp' : undefined} />
        </MarginCard>
        {aal || refresh ? (
          <ActionCard>
            <CenterLink data-testid="logout-link" onClick={mfaLogout} className={styles.logout}>
              Log out
            </CenterLink>
          </ActionCard>
        ) : (
          <>
            <ActionCard
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '-2rem',
              }}
            >
              <Link to="/registration">
                <CenterLink>Sign up</CenterLink>
              </Link>
              <Link to="/recovery">
                <CenterLink>Forgot password?</CenterLink>
              </Link>
            </ActionCard>
          </>
        )}
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { ChangeMemberRole } from './ChangeMemberRole'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { RemoveAccess } from './RemoveAccess'
import { User } from 'gen/cloudTruthRestApi'
import { getCurrentUser } from 'data/user/selectors'
import { useAppSelector } from 'data/hooks'

interface Props {
  user: User
}

enum MenuItemKeys {
  DeleteUser = 'deleteUser',
  EditUser = 'editUser',
}

export function UserDropdown(props: Props) {
  const { user } = props
  const [editVisible, setEditVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)

  const currentUserId = useAppSelector(getCurrentUser)!.id
  const isSelf = currentUserId === user.id

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteUser: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.EditUser: {
        setEditVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    { key: MenuItemKeys.EditUser, label: 'Change Role' },
    {
      key: MenuItemKeys.DeleteUser,
      label: isSelf ? 'Leave Organization' : 'Remove Access',
      danger: true,
    },
  ]

  return (
    <>
      <RemoveAccess user={user} visible={deleteVisible} setVisible={setDeleteVisible} />
      <ChangeMemberRole visible={editVisible} setVisible={setEditVisible} user={user} />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </>
  )
}

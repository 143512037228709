import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AuditLogTable } from './AuditLogTable'
import { AuditTrailSummary } from 'gen/cloudTruthRestApi'
import { GetAuditSummary } from 'data/misc/actions'
// import { Link } from 'react-router-dom'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { PageTitle } from 'components/PageTitle'
import { TypedThunk } from 'data/dataUtils'
// import { curvedDownArrowToLeft } from 'lib/valueHelpers'
import { formatDate } from 'lib/dateHelpers'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './AuditLog.module.scss'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

export function AuditLog() {
  const [summary, setSummary] = useState<nullable<AuditTrailSummary>>(null)
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const { errorToast, infoToast } = useToast()
  const {
    name,
    // subscription_plan_name
  } = useAppSelector(getCurrentOrganization)!
  const { goToOrgRoute } = useHistoryPush()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (canAdministrate) {
      dispatch(GetAuditSummary(null)).then(({ error, payload }: TypedThunk<AuditTrailSummary>) => {
        error ? errorToast(error.message) : setSummary(payload)
      })
    }
  }, [canAdministrate, dispatch, errorToast])

  if (!canAdministrate) {
    infoToast('You do not have access to view audit logs. Contact an administrator for access.')
    goToOrgRoute(OrganizationPaths.Dashboard)
    return null
  }

  return (
    <div className={styles.container}>
      <PageTitle
        title="Audit Log"
        description="The Audit Log tracks specific users/tokens and their actions across CloudTruth
        configuration management objects. For more information click:"
        link={
          <a href="https://docs.cloudtruth.com/organization-management/audit-log">
            https://docs.cloudtruth.com/organization-management/audit-log
          </a>
        }
      />
      <div className={styles.body}>
        {summary?.earliest && (
          <div>
            {`${name} has ${summary.total} entr${
              summary.total === 1 ? 'y' : 'ies'
            } dating back to ${formatDate(summary.earliest)}`}
            .
          </div>
        )}
        <div>
          <div>
            {/* &nbsp;&nbsp;&nbsp;{curvedDownArrowToLeft}&nbsp;The '{subscription_plan_name}' plan retains
          up to {summary?.max_days} days or {summary?.max_records} audit records. &nbsp;
          <Link to={orgLink(OrganizationPaths.Settings)}>Upgrade</Link> */}
          </div>
        </div>
        {summary?.earliest && <AuditLogTable summary={summary} />}
      </div>
    </div>
  )
}

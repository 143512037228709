const setBodies = (jsonString: string) => localStorage.setItem('savedTemplateBodies', jsonString)

export const getLocalTemplateBody = (
  templateName?: string,
  projectId?: string
): nullable<string> => {
  if (!templateName || !projectId) {
    return null
  }

  const savedTemplateBodies = localStorage.getItem('savedTemplateBodies')

  if (savedTemplateBodies) {
    const template = JSON.parse(savedTemplateBodies)[templateName]

    if (template && typeof template === 'string') {
      return template
    }

    return template?.projectId === projectId ? template.body : null
  }

  return null
}

export const saveLocalTemplateBody = (
  templateName: string,
  body: string,
  projectId: string
): void => {
  const savedTemplateBodies = localStorage.getItem('savedTemplateBodies')

  savedTemplateBodies
    ? setBodies(
        JSON.stringify({ ...JSON.parse(savedTemplateBodies), [templateName]: { body, projectId } })
      )
    : setBodies(JSON.stringify({ [templateName]: { body, projectId } }))

  window.dispatchEvent(new Event('storage'))
}

export const removeLocalTemplateBody = (templateName: string): void => {
  const savedTemplateBodies = localStorage.getItem('savedTemplateBodies')

  if (savedTemplateBodies) {
    const parsedObject = JSON.parse(savedTemplateBodies)
    delete parsedObject[templateName]

    setBodies(JSON.stringify(parsedObject))

    window.dispatchEvent(new Event('storage'))
  }
}

import React, { useMemo } from 'react'
import { getCurrentUser, getInteractiveUsers } from 'data/user/selectors'

import { ChangeGrantRole } from './ChangeGrantRole'
import { List } from 'antd'
import { UserAvatar } from 'components/UserAvatar'
import { getGrantByScope } from 'data/grant/selectors'
import { grantHash } from './utils'
import styles from './UserGrantList.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  scope: string
  pageSize: number
  disableChangeRole?: boolean
}

export function UserGrantList(props: Props) {
  const { scope, pageSize, disableChangeRole } = props
  const scopeGrants = useAppSelector(getGrantByScope(scope))
  const currentUser = useAppSelector(getCurrentUser)
  const users = useAppSelector(getInteractiveUsers)

  const sortedUsers = useMemo(() => {
    return users.sort((user1, user2) => {
      if (user1.id === currentUser?.id) {
        return -1
      } else if (user2.id === currentUser?.id) {
        return 1
      } else {
        return 0
      }
    })
  }, [users, currentUser])

  const scopedGrantHash = useMemo(() => {
    return grantHash(scopeGrants)
  }, [scopeGrants])

  return (
    <List
      className={styles.list}
      dataSource={sortedUsers}
      pagination={{ pageSize }}
      renderItem={(user) => (
        <List.Item
          actions={[
            <ChangeGrantRole
              grant={scopedGrantHash[user.id]}
              principal={user.url}
              scope={scope}
              disabled={disableChangeRole}
              key="changeRole22"
            />,
          ]}
        >
          <List.Item.Meta
            className={styles.meta}
            avatar={<UserAvatar pictureUrl={user.picture_url} />}
            title={`${user.name}${user.id === currentUser?.id ? ' (You)' : ''}`}
          />
        </List.Item>
      )}
    />
  )
}

import React, { PropsWithChildren, createContext, useState } from 'react'

export interface ContextValue {
  setFeaturesGated: React.Dispatch<React.SetStateAction<boolean>>
  featuresGated: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isCypress = !!window.Cypress
export const isProduction = process.env.REACT_APP_ATMOS_ENV === 'production'
export const isStagingOrProduction = process.env.NODE_ENV === 'production'

export const FeatureGateContext = createContext<ContextValue>({
  featuresGated: false,
  setFeaturesGated: () => null,
})

export const FeatureGateProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { children } = props
  const [featuresGated, setFeaturesGated] = useState(true)

  return (
    <FeatureGateContext.Provider value={{ featuresGated, setFeaturesGated }}>
      {children}
    </FeatureGateContext.Provider>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useMemo } from 'react'
import {
  getCurrentProject,
  projectEntitySelectors,
  useSelectProjects,
} from 'data/project/selectors'

import { TreeSelect } from 'antd'
import { buildProjectTree } from '../Projects/utils'
import styles from './SideNav.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  onChange: (id: string) => void
}

export function ProjectSelect(props: Props) {
  const { onChange } = props
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const currentProject = useAppSelector(getCurrentProject)

  const projectTree = useMemo(() => {
    return buildProjectTree(projectEntities)
  }, [projectEntities])

  return (
    <TreeSelect
      className={styles.treeSelect}
      treeData={projectTree}
      value={currentProject?.id}
      treeDefaultExpandAll
      treeLine={{ showLeafIcon: false }}
      placeholder="Select a project"
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      showSearch
      treeNodeFilterProp="title"
    />
  )
}

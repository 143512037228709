//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { DecodedToken, decodeToken } from 'lib/jwtHelpers'
import {
  PaginatedAwsPullList,
  PaginatedAwsPushList,
  PaginatedGitHubPullList,
  StatusEnum,
} from 'gen/cloudTruthRestApi'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AsyncThunk } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { GetAwsIntegrations } from '../integration/awsIntegration/actions'
import { GetAwsPulls } from '../actions/awsImport/actions'
import { GetAwsPushes } from '../actions/awsPush/actions'
import { GetGithubPulls } from '../actions/githubImport/actions'
import { epoch } from 'data/dataUtils'

export enum ErrorType {
  ApiError = 'API Error',
  AwsConnection = 'AWS Connection Error',
  AwsImport = 'AWS Import Error',
  GithubImport = 'GitHub Import Error',
  AwsPush = 'AWS Push Error',
  StaleAction = 'Stale Action',
}

export interface CreateMessage {
  id: string
  type: ErrorType
  description: string
  name: string
}

export interface Message extends CreateMessage {
  read: boolean
  timestamp: typeof epoch
}

const nonReportingRejections = ['user/getUser/rejected']

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>

export interface SessionState {
  cacheLastChanged: typeof epoch
  claims: nullable<DecodedToken>
  jwt: JWT
  sessionErrors: Record<string, Message>
  subscribeToAsyncPulls: nullable<typeof epoch>
}

const initialState = {
  cacheLastChanged: epoch,
  claims: null,
  jwt: null,
  sessionErrors: {},
  subscribeToAsyncPulls: null,
} as SessionState

const checkActionErrors = (
  state: SessionState,
  action: PayloadAction<PaginatedAwsPullList | PaginatedAwsPushList | PaginatedGitHubPullList>,
  type: ErrorType
): void => {
  ;(action.payload.results || []).forEach((obj) => {
    if (obj.latest_task?.state === 'failure') {
      const timestamp = new Date(Date.now())
      state.cacheLastChanged = timestamp
      state.sessionErrors[obj.id] = {
        id: obj.id,
        type,
        description: obj.latest_task.error_detail!,
        read: false,
        timestamp,
        name: obj.name,
      }
    }
  })
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    // direct actions
    setJwt(state, action: PayloadAction<nullable<string>>) {
      state.cacheLastChanged = new Date(Date.now())
      state.claims = decodeToken(action.payload)
      state.jwt = action.payload

      // reset on new jwt
      state.subscribeToAsyncPulls = null
      state.sessionErrors = {}
    },
    addSessionError(state, action: PayloadAction<CreateMessage>) {
      const timestamp = new Date(Date.now())
      state.cacheLastChanged = timestamp
      state.sessionErrors[action.payload.id] = {
        id: action.payload.id,
        type: action.payload.type,
        description: action.payload.description,
        read: false,
        timestamp,
        name: action.payload.name,
      }
    },
    markErrorAsRead(state, action: PayloadAction<string>) {
      state.cacheLastChanged = new Date(Date.now())
      state.sessionErrors[action.payload] = { ...state.sessionErrors[action.payload], read: true }
    },
    // More information about async pull updates can be found in AppInstrumentation.tsx
    setAsyncPullUpdated: (state) => {
      state.subscribeToAsyncPulls = new Date(Date.now())
    },
    cancelAsyncPullSubscription: (state) => {
      state.subscribeToAsyncPulls = null
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetAwsIntegrations.fulfilled, (state, action) => {
      ;(action.payload.results || []).forEach((awsIntegration) => {
        if (awsIntegration.status === StatusEnum.Errored) {
          const timestamp = new Date(Date.now())
          state.cacheLastChanged = timestamp
          state.sessionErrors[awsIntegration.id] = {
            id: awsIntegration.id,
            type: ErrorType.AwsConnection,
            description: awsIntegration.status_detail,
            read: false,
            timestamp,
            name: awsIntegration.fqn,
          }
        }
      })
    })
    builder.addCase(GetGithubPulls.fulfilled, (state, action) => {
      checkActionErrors(state, action, ErrorType.GithubImport)
    })
    builder.addCase(GetAwsPulls.fulfilled, (state, action) => {
      checkActionErrors(state, action, ErrorType.AwsImport)
    })
    builder.addCase(GetAwsPushes.fulfilled, (state, action) => {
      checkActionErrors(state, action, ErrorType.AwsPush)
    })
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith('/rejected'),
      (state, action) => {
        if (action.error && !nonReportingRejections.includes(action.type)) {
          const timestamp = new Date(Date.now())
          state.cacheLastChanged = timestamp
          const error = action.error as Error
          const id = action.meta.requestId
          state.sessionErrors[id] = {
            id,
            type: ErrorType.ApiError,
            description: error.message,
            read: false,
            timestamp,
            name: action.type,
          }
        }
      }
    )
  },
})

export const {
  setJwt,
  addSessionError,
  markErrorAsRead,
  setAsyncPullUpdated,
  cancelAsyncPullSubscription,
} = sessionSlice.actions

export default sessionSlice.reducer

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import {
  CopyCreateProject,
  CreateProject,
  DeleteProject,
  GetProject,
  GetProjects,
  UpdateProject,
} from './actions'
import { CreateParameter, DeleteParameter, GetParameterNames } from 'data/parameter/actions'
import { CreateTemplate, DeleteTemplate, GetTemplateNames } from 'data/template/actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { epoch, projectIdFromUrl } from 'data/dataUtils'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Project } from 'gen/cloudTruthRestApi'
import { localStorageHelpers } from 'lib/localStorageHelpers'

interface ProjectState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  cachedTemplates: boolean
  cachedParameters: boolean
}

interface ObjectDetails {
  id: string
  url: string
  name: string
  description?: string
}

export interface ProjectDetails extends Project {
  parameters?: ObjectDetails[]
  templates?: ObjectDetails[]
  cachedTemplates?: boolean
  cachedParameters?: boolean
}

export const projectAdapter = createEntityAdapter<ProjectDetails>({
  selectId: (project) => project.id,
})

const initialState = projectAdapter.getInitialState({
  cacheLastChanged: epoch,

  current: localStorageHelpers.getActiveProjectId(),
} as ProjectState)

export type ProjectRootState = EntityState<Project> & ProjectState

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // direct actions
    selectProject(state, action: PayloadAction<nullable<nullable<string>>>) {
      state.current = action.payload
      localStorageHelpers.setActiveProjectId(state.current)
    },
    setAllProject: projectAdapter.setAll,
    addOneProject: projectAdapter.addOne,
    updateOneProject: projectAdapter.upsertOne,
    removeOneProject: projectAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateProject.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())

      projectAdapter.addOne(state, action.payload)

      const { current, ids } = state

      if (!current || !ids.includes(current)) {
        state.current = action.payload.id
        localStorageHelpers.setActiveProjectId(state.current)
      }
    })
    builder.addCase(CopyCreateProject.fulfilled, (state, action) => {
      const isRecursive = action.meta.arg.params.recursive
      if (!isRecursive) {
        state.cacheLastChanged = new Date(Date.now())
        projectAdapter.addOne(state, action.payload)
      }
    })
    builder.addCase(GetProjects.fulfilled, (state, action) => {
      const projects = action.payload.results!
      projectAdapter.setAll(state, projects)

      const { current, entities, ids } = state

      state.cacheLastChanged = new Date(Date.now())

      if (!current || !ids.includes(current)) {
        const projectFromStorage = localStorageHelpers.getActiveProjectId()
        const projectFromStorageExists = projectFromStorage ? !!entities[projectFromStorage] : false
        const firstProject = entities[ids[0]]?.id

        state.current =
          projectFromStorage && projectFromStorageExists
            ? projectFromStorage
            : firstProject
            ? firstProject
            : null

        localStorageHelpers.setActiveProjectId(state.current)
      }
    })

    builder.addCase(GetProject.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      projectAdapter.setOne(state, action.payload)
    })
    builder.addCase(UpdateProject.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      projectAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteProject.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      projectAdapter.removeOne(state, action.payload)

      const { current, entities, ids } = state

      if (!current || !ids.includes(current)) {
        const firstProject = entities[ids[0]]?.id
        state.current = firstProject ? firstProject : null
        localStorageHelpers.setActiveProjectId(state.current)
      }
    })
    builder.addCase(GetParameterNames.fulfilled, (state, action) => {
      state.entities[action.meta.arg]!.parameters = action.payload.results!.map((param) => ({
        id: param.id,
        url: param.url,
        name: param.name,
        description: param.description,
      }))

      state.entities[action.meta.arg]!.cachedParameters = true
    })
    builder.addCase(CreateParameter.fulfilled, (state, action) => {
      const { payload } = action
      const parameters = state.entities[projectIdFromUrl(payload.url)]?.parameters
      if (parameters) {
        parameters.push({
          id: payload.id,
          url: payload.url,
          name: payload.name,
          description: payload.description,
        })
      }
    })
    builder.addCase(DeleteParameter.fulfilled, (state, action) => {
      const parameters = state.entities[state.current || '']?.parameters
      if (parameters) {
        const idx = parameters.findIndex((param) => param.id === action.payload)
        if (idx !== -1) {
          parameters.splice(idx, 1)
        }
      }
    })
    builder.addCase(GetTemplateNames.fulfilled, (state, action) => {
      state.entities[action.meta.arg]!.templates = action.payload.results!.map((temp) => ({
        id: temp.id,
        url: temp.url,
        name: temp.name,
        description: temp.description,
      }))

      state.entities[action.meta.arg]!.cachedTemplates = true
    })
    builder.addCase(CreateTemplate.fulfilled, (state, action) => {
      const { payload } = action
      const templates = state.entities[projectIdFromUrl(payload.url)]?.templates
      if (templates) {
        templates.push({
          id: payload.id,
          url: payload.url,
          name: payload.name,
          description: payload.description,
        })
      }
    })
    builder.addCase(DeleteTemplate.fulfilled, (state, action) => {
      const templates = state.entities[state.current || '']?.templates
      if (templates) {
        const idx = templates.findIndex((temp) => temp.id === action.payload)
        if (idx !== -1) {
          templates.splice(idx, 1)
        }
      }
    })
  },
})

export const {
  resetState,
  selectProject,
  addOneProject,
  updateOneProject,
  removeOneProject,
  setAllProject,
} = projectSlice.actions

export default projectSlice.reducer

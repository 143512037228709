//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { PropsWithChildren } from 'react'

import { Modal } from './Modal'
import { Parameter } from 'gen/cloudTruthRestApi'

interface Props {
  visible: boolean
  objectName?: string
  afterClose?: () => void
  onCancel?: () => void
  onOk?: () => void
  pending?: boolean
  okText?: string
  footer?: JSX.Element
  width?: string
  isOverride?: boolean
  parameter?: Parameter
}

export const CopyModal = (props: PropsWithChildren<Props>) => {
  const { visible, children, objectName, afterClose, onCancel, onOk, pending, footer, width } =
    props

  return (
    <Modal
      visible={visible}
      title={`COPY ${objectName}`}
      afterClose={afterClose}
      onCancel={onCancel}
      onOk={onOk}
      pending={pending}
      okText={`Copy ${objectName}`}
      footer={footer}
      width={width}
    >
      {children}
    </Modal>
  )
}

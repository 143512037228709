import { Form, FormData, InlineLabelInput, TextArea } from 'components/Forms'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'

// import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CopyEnvironmentPreview } from './CopyEnvironmentPreview'
import { Environment } from 'gen/cloudTruthRestApi'
import { FormInstance } from 'antd'
import React from 'react'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
  environment: Environment
  steps: number
}

export function CopyEnvironmentForm(props: Props) {
  const { form, onFinish, environment, steps } = props

  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())

  const nameValidator = (_: any, value: string) => {
    if (value && environments.find((param) => param.name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('Environment with this name already exists')
    }

    return Promise.resolve()
  }

  return (
    <Form form={form} onFinish={onFinish}>
      {steps === 0 && (
        <>
          <InlineLabelInput
            autoFocus
            name="name"
            label="Environment Name"
            placeholder="New environment name"
            required="Please enter an environment name."
            rules={[{ validator: nameValidator }]}
          />
          <TextArea
            name="description"
            label="Description"
            placeholder={`Copy of environment ${environment?.name}`}
          />
          {environments.find((env) => env.parent === environment.url) && (
            <InlineLabelInput
              checkbox
              label="Copy Dependents"
              name="recursive"
              tooltipText={`Copies all sub-environments under ${environment.name} to the new environment.`}
            />
          )}
        </>
      )}

      {steps === 2 && <CopyEnvironmentPreview form={form} environment={environment} />}
    </Form>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  Environment,
  EnvironmentCopy,
  EnvironmentCreate,
  PaginatedEnvironmentList,
  PaginatedTagList,
  PatchedEnvironmentUpdate,
  PatchedTagUpdate,
  Tag,
  TagCreate,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export interface EnvironmentCreateCopy {
  envId: string
  params: EnvironmentCopy
}

export interface EnvironmentTagCreate {
  envId: string
  tag: TagCreate
}

export interface EnvironmentTagUpdate extends PatchedTagUpdate {
  envId: string
}

export interface EnvironmentTagDelete {
  envId: string
  id: string
}

export const GetEnvironments = createAsyncThunk<PaginatedEnvironmentList, null, ThunkConfig>(
  'environment/getEnvironments',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'environments', null, thunkApi)
)

export const GetEnvironment = createAsyncThunk<Environment, string, ThunkConfig>(
  'environment/getEnvironment',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `environments/${id}`, null, thunkApi)
)

export const CreateEnvironment = createAsyncThunk<Environment, EnvironmentCreate, ThunkConfig>(
  'environment/createEnvironment',
  async (env, thunkApi) => await handleFetch(Verbs.Post, 'environments', env, thunkApi)
)

export const UpdateEnvironment = createAsyncThunk<
  Environment,
  PatchedEnvironmentUpdate,
  ThunkConfig
>(
  'environment/updateEnvironment',
  async (env, thunkApi) => await handleFetch(Verbs.Put, `environments/${env.id}`, env, thunkApi)
)

export const CopyCreateEnvironment = createAsyncThunk<
  Environment,
  EnvironmentCreateCopy,
  ThunkConfig
>(
  'environment/copyEnvironment',
  async (req, thunkApi) =>
    await handleFetch(Verbs.Post, `environments/${req.envId}/copy`, req.params, thunkApi)
)

export const DeleteEnvironment = createAsyncThunk<string, string, ThunkConfig>(
  'environment/deleteEnvironment',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `environments/${id}`, null, thunkApi)
)

export const GetTags = createAsyncThunk<PaginatedTagList, string, ThunkConfig>(
  'environment/getTags',
  async (envId, thunkApi) =>
    await handleFetch(Verbs.Get, `environments/${envId}/tags`, null, thunkApi)
)

export const CreateTag = createAsyncThunk<Tag, EnvironmentTagCreate, ThunkConfig>(
  'environment/createTag',
  async (envTag, thunkApi) => {
    return await handleFetch(Verbs.Post, `environments/${envTag.envId}/tags`, envTag.tag, thunkApi)
  }
)

export const UpdateTag = createAsyncThunk<Tag, EnvironmentTagUpdate, ThunkConfig>(
  'environment/updateTag',
  async ({ envId, id, ...tag }, thunkApi) =>
    await handleFetch(Verbs.Put, `environments/${envId}/tags/${id}`, tag, thunkApi)
)

export const DeleteTag = createAsyncThunk<string, EnvironmentTagDelete, ThunkConfig>(
  'environment/deleteTag',
  async ({ envId, id }, thunkApi) =>
    await handleFetch(Verbs.Delete, `environments/${envId}/tags/${id}`, null, thunkApi)
)

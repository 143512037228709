//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateParameterType,
  CreateParameterTypeRule,
  DeleteParameterType,
  DeleteParameterTypeRule,
  GetParameterType,
  GetParameterTypeRule,
  GetParameterTypeRules,
  GetParameterTypes,
  UpdateParameterType,
  UpdateParameterTypeRule,
} from './actions'
import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ParameterType } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface ParameterTypeState {
  cacheLastChanged: typeof epoch
}

export type ParameterTypeRootState = EntityState<ParameterType> & ParameterTypeState

export const parameterTypeAdapter = createEntityAdapter<ParameterType>({
  selectId: (type) => type.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const initialState = parameterTypeAdapter.getInitialState({
  cacheLastChanged: epoch,
} as ParameterTypeState)

const parameterTypeSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    // direct actions
    setAllParameterTypes: parameterTypeAdapter.setAll,
    addOneParameterType: parameterTypeAdapter.addOne,
    updateOneParameterType: parameterTypeAdapter.upsertOne,
    removeOneParameterType: parameterTypeAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    /* -------------- Parameter Types -------------- */
    builder.addCase(GetParameterTypes.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterTypeAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetParameterType.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterTypeAdapter.addOne(state, action.payload)
    })
    builder.addCase(CreateParameterType.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterTypeAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateParameterType.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterTypeAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteParameterType.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      parameterTypeAdapter.removeOne(state, action.payload)
    })

    /* -------------- Parameter Type Rules -------------- */
    builder.addCase(GetParameterTypeRules.fulfilled, (state, action) => {
      const type = state.entities[action.meta.arg]!
      state.cacheLastChanged = new Date(Date.now())
      type.rules = action.payload.results!
    })
    builder.addCase(GetParameterTypeRule.fulfilled, (state, action) => {
      const type = state.entities[action.meta.arg.typeId]!
      state.cacheLastChanged = new Date(Date.now())
      if (type.rules) {
        type.rules.push(action.payload)
      } else {
        type.rules = [action.payload]
      }
    })
    builder.addCase(CreateParameterTypeRule.fulfilled, (state, action) => {
      const type = state.entities[action.meta.arg.typeId]!
      state.cacheLastChanged = new Date(Date.now())
      if (type.rules) {
        type.rules.push(action.payload)
      } else {
        type.rules = [action.payload]
      }
    })
    builder.addCase(UpdateParameterTypeRule.fulfilled, (state, action) => {
      const type = state.entities[action.meta.arg.typeId]!
      state.cacheLastChanged = new Date(Date.now())
      const idx = type.rules!.findIndex((rule) => rule.id === action.payload.id)
      type!.rules!.splice(idx, 1, action.payload)
    })
    builder.addCase(DeleteParameterTypeRule.fulfilled, (state, action) => {
      const type = state.entities[action.meta.arg.typeId]!
      state.cacheLastChanged = new Date(Date.now())
      const idx = type.rules!.findIndex((rule) => rule.id === action.payload)
      type.rules!.splice(idx, 1)
    })
  },
})

export const {
  resetState,
  setAllParameterTypes,
  addOneParameterType,
  updateOneParameterType,
  removeOneParameterType,
} = parameterTypeSlice.actions

export default parameterTypeSlice.reducer

import { Environment, Parameter, Value } from 'gen/cloudTruthRestApi'
import React, { useCallback, useEffect, useState } from 'react'
import { Spin, Tooltip, Typography } from 'antd'
import { validateString, valueOrEmDash } from 'lib/valueHelpers'

import { HourGlassTooltip } from 'components/HourGlassTooltip'
import { ProviderIcon } from 'components/ProviderIcon'
import { ValueColumnDropdown } from './ValueColumnDropdown'
import { ValueError } from './ValueError'
import dayjs from 'dayjs'
import { getPolicy } from 'data/membership/selectors'
import styles from './Table.module.scss'
import { useAppSelector } from 'data/hooks'

/* eslint react-hooks/rules-of-hooks: 0 */

interface Props {
  parameter: Parameter
  deleted?: boolean
  envUrl: string
  maskSecrets: boolean
  inherited: boolean
  resetColumnState?: nullable<() => boolean>
  valuesLoading?: Record<string, boolean>
  onUpdateCallback?: (value: string, oldValue: string) => void
  onRemoveCallback?: (value: string) => void

  updateCurrentValue?: (
    payload: Value,
    parameter: Parameter,
    currentEnvironment: Environment
  ) => void
}
const { Text } = Typography

export function ValueColumn(props: Props) {
  const {
    parameter,
    deleted,
    envUrl,
    inherited,
    updateCurrentValue,
    maskSecrets,
    resetColumnState,
    valuesLoading,
    onRemoveCallback,
  } = props

  const { canContribute } = useAppSelector(getPolicy(null))

  const envValue = parameter.values[envUrl]
  const [showEvaluated, setShowEvaluated] = useState(false)
  const days = dayjs(envValue?.expires_at).diff(dayjs(), 'day')
  const hours = days === 0 ? dayjs(envValue?.expires_at).diff(dayjs(), 'hour') : null
  const minutes =
    hours === 0 && days === 0 ? dayjs(envValue?.expires_at).diff(dayjs(), 'minute') : null

  const seconds =
    minutes === 0 && days === 0 && hours === 0
      ? dayjs(envValue?.expires_at).diff(dayjs(), 'second')
      : null

  const color = () => {
    if (seconds) {
      return seconds < 1 ? 'red' : '#faad14'
    }

    if (minutes) {
      return minutes < 1 ? 'red' : '#faad14'
    }

    if (hours) {
      return hours < 1 ? 'red' : '#faad14'
    }

    return days! < 1 ? 'red' : days! <= 5 ? '#faad14' : ''
  }

  const toolTip = () => {
    if (seconds) {
      return seconds < 1
        ? `Warning: This value expired ${Math.abs(seconds)} second${
            Math.abs(seconds) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${seconds} second${seconds > 1 ? 's' : ''}`
    }

    if (minutes) {
      return minutes < 1
        ? `Warning: This value expired ${Math.abs(minutes)} minute${
            Math.abs(minutes) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${minutes} minute${minutes > 1 ? 's' : ''}`
    }

    if (hours) {
      return hours < 1
        ? `Warning: This value expired ${Math.abs(hours)} hour${
            Math.abs(hours) > 1 ? 's' : ''
          } ago!`
        : `Warning: This value is set to expire in ${hours} hour${hours > 1 ? 's' : ''}`
    }

    return days < 0
      ? `Warning: This value expired ${Math.abs(days)} day${Math.abs(days) > 1 ? 's' : ''} ago!`
      : days && days > 0 && days && days <= 5
      ? `Warning: This value is set to expire in ${days} day${days > 1 ? 's' : ''}`
      : `This value is set to expire in ${days} days.`
  }

  const value = envValue?.interpolated
    ? showEvaluated
      ? envValue?.value
      : envValue?.internal_value
    : envValue?.value
  const error = envValue?.external_error
  const hideTooltipForSecrets = maskSecrets && envValue?.secret

  const showValue = useCallback(() => {
    return valueOrEmDash(validateString(value))
  }, [value])

  if (deleted) {
    return <div className={styles.disabledValue}>deleted</div>
  }

  const valueTooltip = () => {
    return value && !hideTooltipForSecrets && !error ? validateString(value) : undefined
  }

  useEffect(() => {
    if (resetColumnState) {
      setShowEvaluated(resetColumnState())
    }
  }, [resetColumnState])

  if (valuesLoading && valuesLoading[envValue?.id || '']) {
    return <Spin size="small" className={styles.valueEllipses} />
  }

  return (
    <div data-private="redact">
      <div>
        <div className={styles.providerContainer}>
          {envValue?.expires_at && (
            <HourGlassTooltip centerSpan title={toolTip()} color={color()} />
          )}
          <ProviderIcon isInterpolated={envValue?.interpolated} fqn={envValue?.external_fqn} />
          <Tooltip
            title={valueTooltip()}
            placement="topLeft"
            overlayInnerStyle={{
              maxWidth: '600px',
              wordBreak: 'break-all',
              whiteSpace: 'pre-line',
            }}
            overlayStyle={{ maxWidth: '600px', wordBreak: 'break-all', whiteSpace: 'pre-line' }}
          >
            <Text
              ellipsis
              className={styles.valueEllipses}
              data-testid="value"
              data-cy={`table-${value}`}
            >
              {error ? <ValueError error={error} /> : showValue()}
            </Text>
          </Tooltip>
          {canContribute && (
            <ValueColumnDropdown
              inherited={inherited}
              updateCurrentValue={updateCurrentValue}
              parameter={parameter}
              envValue={envValue}
              environmentUrl={envUrl}
              showEvaluated={showEvaluated}
              setShowEvaluated={setShowEvaluated}
              onRemoveCallback={onRemoveCallback}
              cyData={'dropdown-' + value}
            />
          )}
        </div>
      </div>
    </div>
  )
}

import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { GetServiceAccounts, ServiceAccountReKey } from 'data/serviceAccount/actions'
import { Input, TableColumnsType, Tooltip } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { displayDate, formatDateTime } from 'lib/dateHelpers'
import { emDash, makeTitleCase, valueOrEmDash } from 'lib/valueHelpers'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import {
  serviceAccountEntitySelectors,
  useSelectServiceAccounts,
} from 'data/serviceAccount/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { AddServiceAccount } from './AddServiceAccount'
import { CopyText } from 'components/CopyText'
import { DropdownMenu } from './DropdownMenu'
import { PageTitle } from 'components/PageTitle'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import { Reload } from 'components/Reload'
import { SearchOutlined } from '@ant-design/icons'
import { ServiceAccount } from 'gen/cloudTruthRestApi'
import { ServiceAccountKeysTable } from './ServiceAccountKeysTable'
import { Table } from 'components/Table'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import { saNameSorter } from 'lib/orderBy'
import styles from './ServiceAccountList.module.scss'
import { useToast } from 'hooks'

export interface TokenValue {
  id: string
  tokenValue: string
}

export interface SaWithToken extends ServiceAccount {
  apikey: string
}

export function ServiceAccountList() {
  const [token, setToken] = useState<nullable<TokenValue>>(null)
  const [loading, setLoading] = useState(false)
  const [regenerateLoading, setRegenerateLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [liveSearch, setLiveSearch] = useState('')

  const dispatch = useAppDispatch()
  const serviceAccounts = serviceAccountEntitySelectors.selectAll(useSelectServiceAccounts())
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const userEntities = userEntitySelectors.selectEntities(useSelectUsers())
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'serviceAccount' })
  const localPageSize = localSession?.pageSize
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null)!)

  const [pageSize, setPageSize] = useState(localPageSize ? localPageSize : 10)
  const columnSizes = localSession?.columnSizes

  const expandedRowRender = (sa: ServiceAccount) => <ServiceAccountKeysTable keys={sa.keys} />

  const saList = useMemo(() => {
    return serviceAccounts
      .filter((sa) =>
        searchTerm ? sa.user.name?.toLowerCase()?.startsWith(searchTerm.toLowerCase()) : true
      )
      .sort((sa) => (token?.id === sa.id ? -1 : 0))
  }, [searchTerm, token, serviceAccounts])

  const displayTokenInTable = (payload: SaWithToken) => {
    setColumns((prev) => {
      const newColumns = prev
      newColumns[3] = {
        title: 'ACCESS TOKEN',
        key: 'token',
        dataIndex: 'token',
        width: 500,
        ellipsis: true,
        render: (_, sa) => (
          <>
            {payload.id && payload.id === sa.id ? (
              <span>
                <span className={styles.tooltip}>
                  <QuestionIconTooltip title="Copy this access token now. It will only be available once." />
                </span>
                <span className={styles.apiKey}>{payload.apikey}</span>
                <CopyText prompt="Copy" payload={payload.apikey} />
              </span>
            ) : (
              emDash
            )}
          </>
        ),
      }

      return newColumns
    })
  }

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'serviceAccount',
      args: { pageSize },
    })

  const getServiceAccounts = useCallback(() => {
    setLoading(true)
    dispatch(GetServiceAccounts(null)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  const refreshServiceAccountApiToken = (sa: ServiceAccount, expire_at?: nullable<string>) => {
    setRegenerateLoading(true)

    dispatch(ServiceAccountReKey({ id: sa.id, request: { expire_at } }))
      .then(({ error, payload }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
        } else if (payload) {
          setToken({
            id: sa.id,
            tokenValue: payload.apikey,
          })

          displayTokenInTable(payload)
          successToast('Token regenerated!')
        }
      })

      .then(() => setRegenerateLoading(false))
  }

  const [columns, setColumns] = useState<TableColumnsType<ServiceAccount>>([
    {
      title: 'NAME',
      key: 'name',
      ellipsis: true,
      dataIndex: 'name',
      sorter: saNameSorter,

      width: storedColumnSize(columnSizes, 'name', 300),
      render: (_, sa) => <div className={styles.nameContainer}>{sa.user.name}</div>,
    },
    {
      title: 'SCOPE',
      key: 'scope',
      dataIndex: 'scope',
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'scope', 150),
      render: (_, sa) => (
        <>
          {sa.user.role === 'CONTRIB'
            ? 'Contributor'
            : sa.user.role === 'NO_SECRETS'
            ? 'NoSecretsViewer'
            : makeTitleCase(sa.user.role || '')}
        </>
      ),
    },
    {
      title: 'OWNER',
      key: 'owner',
      dataIndex: 'owner',
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'owner', 200),
      render: (_, sa) => (
        <>{valueOrEmDash(sa.owner ? userEntities[idFromUrl(sa.owner)]?.name || '' : '')}</>
      ),
    },
    {
      title: 'ACCESS TOKEN',
      key: 'token',
      dataIndex: 'token',
      width: storedColumnSize(columnSizes, 'token', 500),
      ellipsis: true,
      render: (_) => <>{emDash}</>,
    },
    {
      title: 'LAST USED',
      key: 'lastUsed',
      dataIndex: 'lastUsed',
      width: storedColumnSize(columnSizes, 'lastUsed', 150),
      ellipsis: true,
      render: (_, sa) => (
        <>
          {sa.last_used_at ? (
            <Tooltip title={formatDateTime(sa.last_used_at)}>
              {displayDate(sa.last_used_at)}
            </Tooltip>
          ) : (
            'Never'
          )}
        </>
      ),
    },
    {
      title: '',
      width: 100,
      render: (_, sa) => (
        <DropdownMenu
          sa={sa}
          onRegenerate={(expires_at?: nullable<string>) =>
            refreshServiceAccountApiToken(sa, expires_at)
          }
          regenerateLoading={regenerateLoading}
        />
      ),
    },
  ])

  const table = () => {
    return (
      <Table
        scroll={{ x: columns?.length > 6 ? 1300 : undefined }}
        loading={loading}
        columns={resizableColumns(columns, setColumns, currentOrganization.id, 'serviceAccount')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        expandable={{ expandedRowRender }}
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50'],
          onChange: (_, pageSize) => {
            setPageSize(pageSize)
            setLocalPageSize(pageSize)
          },
        }}
        tableLayout="fixed"
        dataSource={saList}
        rowClassName={styles.row}
        rowKey={(sa: ServiceAccount) => sa.id}
        locale={{
          emptyText: (
            <div>
              <h4 className={styles.emptyHeader}>
                You don't have any API tokens stored in CloudTruth
              </h4>
              <p className={styles.emptyParagraph}>Create an API token to store and use.</p>
            </div>
          ),
        }}
      />
    )
  }

  return (
    <div className={styles.container}>
      <PageTitle
        title="API Tokens"
        description="Create an API token to enable programmatic access to your organization’s data. You
                can regenerate an API token at anytime. Find additional information about CloudTruth
                API by clicking the link below."
        link={
          <a
            className={styles.descriptionLink}
            href="https://docs.cloudtruth.com/configuration-management/cli-and-api"
            target="_blank"
            rel="noreferrer"
          >
            API Documentation
          </a>
        }
        search={
          <div className={styles.search}>
            <Input
              placeholder="Search By Tokens"
              data-testid="search"
              onChange={(e) => {
                if (!e.target.value) {
                  setLiveSearch('')
                  setSearchTerm('')
                }
                setLiveSearch(e.target.value)
              }}
              disabled={loading}
              className={styles.search}
              suffix={
                <SearchOutlined
                  style={{ cursor: 'pointer' }}
                  data-testid="submit-search"
                  onClick={() => {
                    setSearchTerm(liveSearch)
                  }}
                />
              }
              onPressEnter={() => setSearchTerm(liveSearch)}
              allowClear
            />
          </div>
        }
        buttons={
          <>
            <Reload onClick={getServiceAccounts} loading={loading} />
            {canContribute && (
              <AddServiceAccount
                setToken={setToken}
                populateTokenInTable={displayTokenInTable}
                loading={loading}
                setLoading={setLoading}
              />
            )}
          </>
        }
      />
      {table()}
    </div>
  )
}

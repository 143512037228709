import { Form, InlineLabelInput, LabeledInputItem, TextArea, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { AddResourceTags } from 'components/Integrations/AddResourceTags'
import { AzureKeyVaultIntegration } from 'gen/cloudTruthRestApi'
import { Checkbox } from 'antd'
import { EditModal } from 'components/Modals/EditModal'
import { TypedThunk } from 'data/dataUtils'
import { UpdateAzureKeyVaultIntegration } from 'data/integration/akvIntegration/actions'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  setVisible: (bool: boolean) => void
  integration: AzureKeyVaultIntegration
  visible: boolean
}

export function EditAkv(props: Props) {
  const { setVisible, integration, visible } = props
  const { resource_tags } = integration
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [form] = useForm()

  const onFinish = async () => {
    const { writable, description } = form.getFieldsValue()
    setLoading(true)

    dispatch(
      UpdateAzureKeyVaultIntegration({
        ...integration,
        writable,
        description,
        resource_tags: form.getFieldValue('resource-tags')
          ? (form.getFieldValue('resource-tags') as Record<string, string>[]).reduce(
              (acc, { key, value }) => ({ ...acc, [key]: value }),
              {}
            )
          : undefined,
      })
    )
      .then(({ error }: TypedThunk<AzureKeyVaultIntegration>) => {
        error ? errorToast(error.message) : successToast('Azure integration successfully updated.')
      })
      .then(() => {
        setLoading(false)
        setVisible(false)
      })
  }

  return (
    <EditModal
      visible={visible}
      objectName="Azure"
      okText={'Update'}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      pending={loading}
      width="700px"
    >
      <Form form={form} initialValues={{ ...integration }} onFinish={onFinish}>
        <InlineLabelInput
          name="name"
          disabled
          label="Key Vault Name"
          required="Please enter your Azure account name."
          width="500px"
        />
        <TextArea name="description" label="Description" />
        <LabeledInputItem name="writable" label="write access">
          <Checkbox
            defaultChecked={integration.writable}
            onChange={(e) => form.setFieldsValue({ writable: e.target.checked })}
          >
            Writable
          </Checkbox>
        </LabeledInputItem>
        <LabeledInputItem name="resource-tags" label="resource tags">
          <AddResourceTags
            resourceTags={
              resource_tags
                ? Object.keys(resource_tags).map((key) => ({ key, value: resource_tags[key] }))
                : undefined
            }
          />
        </LabeledInputItem>
      </Form>
    </EditModal>
  )
}

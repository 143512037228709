import React, { PropsWithChildren } from 'react'

import styles from './SimpleLayout.module.scss'

interface Props {
  centerContent?: boolean
}
export function SimpleLayout(props: PropsWithChildren<Props>) {
  const { centerContent, children } = props
  return (
    <div className={styles.container}>
      <div className={styles.pageLayout}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} />
          </div>
        </div>
        <div className={centerContent ? styles.centeredContent : styles.content}>{children}</div>
      </div>
    </div>
  )
}

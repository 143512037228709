import { InvitationsTable } from './Invitations'
import React from 'react'
import { UserTable } from './Users/UserTable'

export function UserContainer() {
  return (
    <div>
      <UserTable />
      <InvitationsTable />
    </div>
  )
}

import { OrganizationPaths } from 'router/OrganizationRoutes'
import React from 'react'
import { WarningFilled } from '@ant-design/icons'
import styles from './NoRbacEnabled.module.scss'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  groups?: boolean
}

export function NoRbacEnabled(props: Props) {
  const { groups } = props
  const { goToOrgRoute } = useHistoryPush()

  return (
    <div className={styles.container}>
      <WarningFilled className={styles.warning} />

      <h2>
        {groups ? 'Groups are' : 'Access Control is'} not included in your current subscription. Go
        to the <a onClick={() => goToOrgRoute(OrganizationPaths.Settings)}>Organization Settings</a>{' '}
        page to modify your subscription.
      </h2>
    </div>
  )
}

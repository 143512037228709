//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { BaseParameterTypes, validateRuleOptions } from 'lib/validators'
import { Button, Divider, FormInstance, Input, Select, Space } from 'antd'
import { Form, FormData, Item, List } from 'components/Forms/Form'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { ParameterRuleTypeEnum, ParameterType } from 'gen/cloudTruthRestApi'
import React, { useEffect, useMemo, useState } from 'react'
import RuleTile, { RULE_TYPE_LABELS } from './RuleTile'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'

import { AddCustomTypeButton } from '../AddCustomTypeButton'
import { LabelText } from 'components/LabelText'
import { LabeledInputItem } from 'components/Forms/LabeledInputItem'
import { ParameterRuleType } from 'data/parameter/actions'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import { typeSorter } from 'lib/orderBy'

interface Props {
  form: FormInstance
  initialRules: ParameterRuleType[]
  onFinish: (formData: FormData) => void
  isOverride: boolean
}

const RulesForm = (props: Props) => {
  const { form: mainForm, initialRules, onFinish, isOverride } = props

  const parameterTypes = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())

  const [pendingType, setPendingType] = useState<nullable<string>>(null)
  const [customTypeName, setCustomTypeName] = useState<string>('')
  const [showRules, setShowRules] = useState<boolean>(false)
  const [rules, setRules] = useState<ParameterRuleType[]>(initialRules)
  const [parameterType, setParameterType] = useState<ParameterType>(() => {
    return {
      ...parameterTypes.find((type) => {
        return type.name === mainForm.getFieldValue('parameterType')
      })!,
      rules: initialRules as any,
    }.name === 'enum'
      ? {
          ...parameterTypes.find((type) => {
            return type.name === mainForm.getFieldValue('parameterType')
          })!,
          rules: initialRules as any,
        }
      : {
          ...parameterTypes.find((type) => {
            return type.name === mainForm.getFieldValue('parameterType')
          })!,
        }
  })

  const fieldVariants = useMemo(() => {
    return (
      (typeof parameterType?.rules[0] &&
        (parameterType?.rules[0]?.constraints as any)
          ?.map((constr: any) => ({ key: constr }))
          .map((rule: ParameterRuleType, index: number) => ({
            key: index,
            name: index,
            ...rule,
          }))) ||
      []
    )
  }, [parameterType])

  // The RulesForm cannot be nested within the main form, so the values are added to the main form here
  useEffect(() => {
    if (parameterType.name === 'enum' || parameterType.parent_name === 'enum') {
      mainForm.setFieldsValue({
        rules: (rules[0]?.constraints as any)?.map((constr: any) => ({ key: constr })),
        parameterType: parameterType.name,
      })
      setRules(parameterType.rules)
    } else {
      mainForm.setFieldsValue({ rules, parameterType: parameterType.name })

      // if parameter type changes, ensure existing invalid rules are removed
      const validRules: ParameterRuleType[] = []
      let foundInvalidRule = false
      rules.forEach((rule) => {
        if (validateRuleOptions(parameterType.name as BaseParameterTypes, rule.type)) {
          validRules.push(rule)
        } else {
          foundInvalidRule = true
        }
      })

      if (foundInvalidRule) {
        setRules(validRules)
      }
    }
  }, [mainForm, parameterType, rules])

  useEffect(() => {
    return () => {
      setRules([])
      setPendingType(null)
      setParameterType(parameterTypes.find((type) => type.name === 'string')!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Only run on mount and unmount

  // Wait until redux reloads after a new type is created before updating the select menu
  useEffect(() => {
    if (pendingType) {
      handleSelectChange(pendingType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterTypes, pendingType])

  const handleSelectChange = (option: string): void => {
    const newType = parameterTypes.find((type) => type.name === option)!
    if (newType) {
      setParameterType(newType)
      setPendingType(null)
    } else if (option !== pendingType) {
      setPendingType(option)
    }
  }

  return (
    <>
      <Form form={mainForm} formProps={{ layout: 'horizontal' }} onFinish={onFinish}>
        <LabeledInputItem
          name="rules"
          label={{
            text: 'Rules',
          }}
          tooltipText="Set rules for this parameter, such as minimum, maximum, length, or a regular expression.  Values cannot be set if they violate any rule."
        >
          <ShowHideButton
            showSettings={showRules}
            toggleShowSettings={() => setShowRules(!showRules)}
            label="rules"
          />
        </LabeledInputItem>
      </Form>

      {showRules && (
        <>
          <Form form={mainForm} formProps={{ layout: 'horizontal' }} onFinish={onFinish}>
            <LabeledInputItem required name="parameterType" label="Parameter Type">
              <Select
                showSearch
                disabled={isOverride}
                placeholder="Parameter Type"
                onChange={handleSelectChange}
                value={parameterType.name}
                notFoundContent="No types match this search"
                data-cy="type-selector"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        style={{ flex: 'auto' }}
                        value={customTypeName}
                        onChange={(e) => setCustomTypeName(e.target.value)}
                      />
                      <AddCustomTypeButton
                        customName={customTypeName}
                        afterCreate={handleSelectChange}
                      />
                    </div>
                  </div>
                )}
              >
                {parameterTypes.sort(typeSorter).map((type) => (
                  <Select.Option key={type.id} value={type.name} data-cy={`type-${type.name}`}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </LabeledInputItem>
          </Form>

          {parameterType.parent && parameterType.parent_name !== 'enum' ? (
            <>
              {parameterType.rules.map((rule) => {
                return (
                  <LabelText
                    key={rule.id}
                    isHorizontal
                    text={
                      rule.type === ParameterRuleTypeEnum.OneOf
                        ? (rule.constraint as any).join(', ')
                        : rule.constraint
                    }
                    label={`${RULE_TYPE_LABELS[rule.type]}:`}
                  />
                )
              })}
            </>
          ) : (
            <>
              {parameterType.name === 'enum' || parameterType.parent_name === 'enum' ? (
                <Form form={mainForm} formProps={{ layout: 'horizontal' }} onFinish={onFinish}>
                  <List
                    name="rules"
                    initialValue={rules.length > 0 ? (rules[0]?.constraint as any) : []}
                  >
                    {(fields = fieldVariants, { remove, add }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                          >
                            <Item
                              {...restField}
                              name={[name, 'key']}
                              rules={[{ required: true, message: 'Missing key' }]}
                            >
                              <Input
                                placeholder="Enum Name"
                                disabled={parameterType.parent_name === 'enum'}
                              />
                            </Item>

                            {parameterType.parent_name !== 'enum' && (
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            )}
                          </Space>
                        ))}
                        <Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={parameterType.parent_name === 'enum'}
                          >
                            Add Enum
                          </Button>
                        </Item>
                      </>
                    )}
                  </List>
                </Form>
              ) : (
                <>
                  {rules.map((rule) => {
                    return (
                      <RuleTile
                        isOverride={isOverride}
                        key={rule.id}
                        id={rule.id}
                        parameterType={parameterType.name as BaseParameterTypes}
                        setRules={setRules}
                        rules={rules}
                        rule={rule}
                      />
                    )
                  })}
                  {!isOverride && (
                    <RuleTile
                      isOverride={isOverride}
                      key="new"
                      id="new"
                      parameterType={parameterType.name as BaseParameterTypes}
                      setRules={setRules}
                      rules={rules}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default RulesForm

// //
// // Copyright (C) 2021 CloudTruth, Inc.
// // All Rights Reserved
// //
//
import {
  CreateTemplate,
  DeleteTemplate,
  GetTemplate,
  GetTemplates,
  PreviewTemplate,
  UpdateTemplate,
} from './actions'
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Template } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface TemplateWithEvaluated extends Template {
  evaluatedBody: string
  evaluatedValue: string
  evaluatedEnvironment: string
}

interface UpdateEvaluatedParams {
  templateId: string
  evaluatedBody: string
  evaluatedValue: string
  evaluatedEnvironment: string
}

interface TemplateState {
  cacheLastChanged: typeof epoch
  cachedProject: nullable<string>
  current: nullable<string>
  cachedEvaluatedTemplates: Record<string, TemplateWithEvaluated>
}

export const templateAdapter = createEntityAdapter<Template>({
  selectId: (temp) => temp.id,
})

const initialState = templateAdapter.getInitialState({
  cacheLastChanged: epoch,
  cachedProject: null, // the project ID for the current cache of templates
  current: null,
} as TemplateState)

export type TemplateRootState = EntityState<Template> & TemplateState

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    // direct actions
    selectTemplate(state, action: PayloadAction<nullable<nullable<string>>>) {
      state.current = action.payload
    },
    setCachedProject(state, action: PayloadAction<nullable<string>>) {
      state.cachedProject = action.payload
    },
    updateTemplateEvlauatedCache(state, action: PayloadAction<UpdateEvaluatedParams>) {
      state.cachedEvaluatedTemplates = {
        ...state.cachedEvaluatedTemplates,
        [action.payload.templateId]: {
          evaluatedBody: action.payload.evaluatedBody,
          evaluatedValue: action.payload.evaluatedValue,
          evaluatedEnvironment: action.payload.evaluatedEnvironment,
        } as TemplateWithEvaluated,
      }
    },
    clearTemplateEvlauatedCache(state) {
      state.cachedEvaluatedTemplates = {}
    },
    setAllTemplate: templateAdapter.setAll,
    addOneTemplate: templateAdapter.addOne,
    updateOneTemplate: templateAdapter.upsertOne,
    removeOneTemplate: templateAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateTemplate.fulfilled, (state, action) => {
      if (!action.meta.arg.dontUpdateCache) {
        if (!action.meta.arg.dontUpdateCurrent) {
          state.current = action.payload.id
        }

        state.cacheLastChanged = new Date(Date.now())
        templateAdapter.addOne(state, action.payload)
      }
    })
    builder.addCase(GetTemplates.fulfilled, (state, action) => {
      const query = new URLSearchParams(window.location.search)
      const templateFromParams = query.get('template')
      state.current = templateFromParams ? templateFromParams : null

      // GetTemplates clears the existing cache and sets the cached template id
      state.cacheLastChanged = new Date(Date.now())
      state.cachedProject = action.meta.arg
      templateAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetTemplates.rejected, (state, _) => {
      // If an issue occurs when listing templates we want to clear the cache to avoid confusing data between projects
      state.cacheLastChanged = new Date(Date.now())
      templateAdapter.removeAll(state)
    })
    builder.addCase(GetTemplate.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      templateAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateTemplate.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      templateAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteTemplate.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      templateAdapter.removeOne(state, action.payload)
    })
    builder.addCase(PreviewTemplate.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const templateId = action.meta.arg.templateId
      const updateHasSecrets = action.meta.arg.updateHasSecrets
      if (templateId && updateHasSecrets) {
        const template = state.entities[templateId] as Template
        templateAdapter.upsertOne(state, { ...template, has_secret: action.payload.has_secret })
      }
    })
  },
})

export const {
  resetState,
  selectTemplate,
  setCachedProject,
  setAllTemplate,
  addOneTemplate,
  updateOneTemplate,
  removeOneTemplate,
  updateTemplateEvlauatedCache,
  clearTemplateEvlauatedCache,
} = templateSlice.actions

export default templateSlice.reducer

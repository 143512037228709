//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, TextArea } from 'components/Forms'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { FormInstance } from 'antd'
import { Project } from 'gen/cloudTruthRestApi'
import React from 'react'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
  disabled: boolean
  project: Project
}

export function EditProjectForm(props: Props) {
  const { form, onFinish, project } = props
  const { name, parameter_name_pattern, description } = project

  const projects = projectEntitySelectors.selectAll(useSelectProjects())

  const nameValidator = (_: any, value: string) => {
    if (
      projects.find(
        (p) =>
          p.name.toLowerCase() === value.toLowerCase() &&
          // allow the same name if it's the same project
          p.name.toLowerCase() !== name.toLowerCase()
      )
    ) {
      return Promise.reject('Project with this name already exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{ name, parameter_name_pattern, description }}
    >
      <InlineLabelInput
        name="name"
        label="Project Name"
        required="Please enter a project name."
        rules={[{ validator: nameValidator }]}
      />
      <InlineLabelInput name="parameter_name_pattern" label="Parameter Name Pattern" />
      <TextArea name="description" label="Description" />
    </Form>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ActionEnum, AsyncJob, getAsyncJobs } from '../utils'
import { AzureKeyVaultPush, Environment, Tag } from 'gen/cloudTruthRestApi'
import { PushRootState, akvPushAdapter } from './reducer'
import { environmentIdFromUrl, idFromUrl } from 'data/dataUtils'

import { Dictionary } from '@reduxjs/toolkit/dist/entities'
import { GetAzureKeyVaultPush } from './actions'
import { RootState } from 'data/store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectPush = (state: RootState) => state.akvPush
const selectState = (state: RootState) => state

export interface AzureKeyVaultPushWithTags extends AzureKeyVaultPush {
  tagDisplayNames: string[]
}

export const displayTags = (
  tagUrls: string[],
  allTags: Record<string, Tag[]>,
  environments: Dictionary<Environment>
): string[] => {
  return tagUrls
    .map((tagUrl) => {
      const environmentId = environmentIdFromUrl(tagUrl)
      const tagId = idFromUrl(tagUrl)

      const envTags = allTags![environmentId]

      return `${environments[environmentId]?.name}: ${
        envTags?.find((tag) => tag.id === tagId)?.name
      }`
    })
    .sort((first, second) => {
      return first.localeCompare(second)
    })
}

const buildPushWithTag = (
  push: AzureKeyVaultPush,
  tags: Record<string, Tag[]>,
  environments: Dictionary<Environment>
): AzureKeyVaultPushWithTags => {
  return {
    ...push,
    tagDisplayNames: displayTags(push.tags, tags, environments),
  }
}

export const getCurrentPush = createSelector(selectPush, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const getIntegrationPushes = (): Selector<RootState, AzureKeyVaultPush[]> =>
  createSelector(selectPush, (state): AzureKeyVaultPush[] => {
    return (Object.values(state.entities) as AzureKeyVaultPush[]).sort(
      (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
    )
  })

export const getAzureKeyVaultPushesWithTagNames = (): Selector<
  RootState,
  AzureKeyVaultPushWithTags[]
> =>
  createSelector(selectState, (state): AzureKeyVaultPushWithTags[] => {
    const tags = state.environment.tags
    const environments = state.environment.entities

    return (Object.values(state.akvPush.entities) as AzureKeyVaultPush[])
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      .map((push) => {
        return buildPushWithTag(push, tags, environments)
      })
  })

export const getAzureKeyVaultPushWithTagNames = (
  pushId: string
): Selector<RootState, nullable<AzureKeyVaultPushWithTags>> =>
  createSelector(selectState, (state): nullable<AzureKeyVaultPushWithTags> => {
    const tags = state.environment.tags
    const environments = state.environment.entities
    const push = state.akvPush.entities[pushId]

    return push ? buildPushWithTag(push, tags, environments) : null
  })

export const getRunningPushes = createSelector(selectPush, (state): AsyncJob[] => {
  return getAsyncJobs(state).map((job) => ({
    ...job,
    type: ActionEnum.AzureKeyVaultPush,
    action: GetAzureKeyVaultPush,
  }))
})

export const akvPushEntitySelectors = akvPushAdapter.getSelectors()
export const useSelectAkvPush = (): PushRootState => useSelectState().akvPush

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { AwsPush, AwsServiceEnum, Tag } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { CreateAwsPush } from 'data/actions/awsPush/actions'
import { CreateAzureKeyVaultPush } from 'data/actions/akvPush/actions'
import { ModalStepsFooter } from '../ActionsForm/ModalStepsFooter'
import { PushForm } from './PushForm'
import { TypedThunk } from 'data/dataUtils'
import { getTags } from 'data/environment/selectors'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  actionType: ActionPushEnum
}

type TagHash = {
  [key: string]: Tag
}

export function AddPush(props: Props) {
  const { actionType } = props
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const tags = useAppSelector(getTags())!

  const [visible, setVisible] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [formError, setFormError] = useState<maybe<string>>(null)
  const [steps, setSteps] = useState<number>(0)

  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
    setFormError(null)
    setSteps(0)
  }

  const onOk = () => {
    const formCoercionCheck =
      form.getFieldValue('service') &&
      form.getFieldValue('integration') &&
      (!!form.getFieldValue('include_parameters') || !!form.getFieldValue('include_templates')) &&
      form.getFieldValue('service')[0] === AwsServiceEnum.Secretsmanager &&
      !form.getFieldValue('coerce_parameters')

    if (steps === 0 && actionType === ActionEnum.AwsPush && formCoercionCheck) {
      setFormError(
        "Enable 'coerce' for parameter types, template types, and Secrets Manager integrations."
      )
      return
    } else {
      form.submit()

      if (steps === 2) {
        form.submit()
      }
      if (steps < 2) {
        form.validateFields().then(() => setSteps((steps) => (steps += 1)))
      }
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 1))
    }
  }

  const allTagsHash = () => {
    const hash: TagHash = {}

    Object.values(tags)
      .flatMap((tags) => tags)
      .forEach((tag) => {
        hash[tag.id] = tag
      })

    return hash
  }

  const handleSubmit = () => {
    setPending(true)
    // Values aren't coming in with FormData, so they have to be fetched manually
    // It might have something to do with form components mounting and unmounting
    // due to steps
    const tags = form.getFieldValue('tags')
    const projects = form.getFieldValue('projects')
    const integrationId = form.getFieldValue('integration')
    const region = form.getFieldValue('region') ? form.getFieldValue('region')[0] : null
    const service = form.getFieldValue('service') ? form.getFieldValue('service')[0] : null
    const resource = form.getFieldValue('resource')
    const name = form.getFieldValue('name')
    const dry_run = form.getFieldValue('dry_run')
    const force = form.getFieldValue('force')
    const local = form.getFieldValue('local')
    const description = form.getFieldValue('description')

    const include_parameters = form.getFieldValue('include_parameters')
    const include_secrets = form.getFieldValue('include_secrets')
    const include_templates = form.getFieldValue('include_templates')
    const coerce_parameters =
      include_templates || include_parameters ? form.getFieldValue('coerce_parameters') : false
    const projectUrls = projects.map((projectId: string) => projectEntities[projectId]!.url)
    const tagUrls = tags.map((tag: string) => allTagsHash()[tag].url)
    // This leaves out service and region to make it reusable
    const push = {
      tags: tagUrls,
      projects: projectUrls,
      resource,
      description,
      name,
      dry_run,
      force,
      local,
      coerce_parameters,
      include_parameters,
      include_secrets,
      include_templates,
    }

    switch (actionType) {
      case ActionEnum.AwsPush:
        dispatch(
          CreateAwsPush({ push: { ...push, region, service }, awsIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPush>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully created')
            onCancel()
          }

          setPending(false)
        })

        break

      case ActionEnum.AzureKeyVaultPush:
        dispatch(CreateAzureKeyVaultPush({ push, akvIntegrationId: integrationId })).then(
          ({ error }: TypedThunk<AwsPush>) => {
            if (error) {
              errorToast(error.message)
            } else {
              successToast('Action successfully created')
              onCancel()
            }

            setPending(false)
          }
        )

        break

      default:
        break
    }
  }

  return (
    <>
      <ActionButton customType="add" size="large" onClick={() => setVisible(true)}>
        Create Push
      </ActionButton>

      <AddModal
        visible={visible}
        objectName="Push"
        footer={
          <ModalStepsFooter
            objectName="Push"
            onCancel={onCancel}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={pending}
          />
        }
        pending={pending}
      >
        <PushForm
          form={form}
          steps={steps}
          handleSubmit={handleSubmit}
          actionType={actionType}
          formError={formError}
          setFormError={setFormError}
        />
      </AddModal>
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { ProjectDetails, ProjectRootState, projectAdapter } from 'data/project/reducer'

import { RootState } from '../store'
import { Selector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { idFromUrl } from 'data/dataUtils'
import { useSelectState } from '../hooks'

const selectProject = (state: RootState) => state.project

export const getCurrentProject = createSelector(
  selectProject,
  (state): nullable<ProjectDetails> => {
    return state.current ? state.entities[state.current]! : null
  }
)

export const getProjectName = (
  projectUrl: nullable<string | undefined>
): Selector<RootState, nullable<string>> =>
  createSelector(selectProject, (state): nullable<string> => {
    return projectUrl ? state.entities[idFromUrl(projectUrl)]?.name || null : null
  })

export const projectEntitySelectors = projectAdapter.getSelectors()
export const useSelectProjects = (): ProjectRootState => useSelectState().project

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Organization, Tag } from 'gen/cloudTruthRestApi'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, TableSearch } from 'components/Table'
import { TableProps, Typography } from 'antd'
import { getCurrentEnvironment, getEnvironmentTags } from 'data/environment/selectors'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AddTag } from './AddTag'
import { GetTags } from 'data/environment/actions'
import { TableTitle } from 'components/TableTitle'
import { TagDropdownMenu } from './TagDropdownMenu'
import { formatDateTime } from 'lib/dateHelpers'
import { getPolicy } from 'data/membership/selectors'
import styles from './EnvironmentTags.module.scss'
import { useForm } from 'components/Forms'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  currentOrganization: Organization
}

export function EnvironmentTags(Props: Props) {
  const { currentOrganization } = Props
  const { canAdministrate, canContribute } = useAppSelector(getPolicy(null))
  const environment = useAppSelector(getCurrentEnvironment)
  const tags = useAppSelector(getEnvironmentTags(environment?.id || ''))!
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const [form] = useForm()
  const [search, setSearch] = useState('')

  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'tags' })
  const { getQuery } = useQuery()
  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const filteredTags = useCallback(() => {
    return (
      tags &&
      tags.filter((tag) => {
        return tag.name.toLowerCase().includes(search.toLowerCase())
      })
    )
  }, [search, tags])

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'tags',
      args: { pageSize },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  useEffect(() => {
    if (environment && !tags) {
      setLoading(true)
      dispatch(GetTags(environment!.id)).then(() => {
        setLoading(false)
      })
    }
  }, [dispatch, environment, tags])

  const actionButton = canContribute ? <AddTag /> : undefined

  const [columns, setColumns] = useState<TableProps<Tag>['columns']>([
    {
      title: 'TAG NAME',
      key: 'name',
      ellipsis: true,
      dataIndex: 'name',
      width: storedColumnSize(columnSizes, 'name', 150),
      sorter: (a, b) => {
        const firstName = a!.name.toUpperCase()
        const secondName = b!.name.toUpperCase()

        return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
      },
    },
    {
      title: 'DESCRIPTION',
      key: 'description',
      dataIndex: 'description',
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'description', 300),
      render: (_, tag: Tag) => (
        <Typography.Text className={styles.ellipsis}>
          {valueOrEmDash(tag.description)}
        </Typography.Text>
      ),
    },
    {
      title: 'TIMESTAMP',
      key: 'timestamp',
      dataIndex: 'timestamp',
      defaultSortOrder: 'ascend',
      width: storedColumnSize(columnSizes, 'timestamp', 200),
      sorter: (a, b) => {
        return a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
      },
      render: (_, tag: Tag) => <p>{formatDateTime(tag.timestamp)}</p>,
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      width: storedColumnSize(columnSizes, 'actions', 100),
      render: (_, tag: Tag) => (canAdministrate ? <TagDropdownMenu tag={tag} /> : null),
    },
  ])

  return (
    <div>
      <Table
        size="small"
        columns={resizableColumns(columns!, setColumns, currentOrganization.id, 'tags')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        loading={loading}
        title={() => (
          <TableTitle
            title="Tags"
            actionButton={actionButton}
            tooltip={
              <div>
                Tagging is a snapshot of parameter values for specific point in time. Use a tag to
                retrieve approved values for your applications.
                <br />
                <br />
                Learn more about{' '}
                <a
                  className={styles.link}
                  href="https://docs.cloudtruth.com/configuration-management/environments#tagging"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  environment tags
                </a>{' '}
                and{' '}
                <a
                  className={styles.link}
                  href="https://docs.cloudtruth.com/reference/knowledge-base/best-practices/versioned-releases"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  versioned releases
                </a>
              </div>
            }
            search={
              <TableSearch
                defaultValue={search || ''}
                updateSearchTerm={setSearch}
                form={form}
                onSearch={() => setPage(1)}
                placeHolder="Search Tags"
                marginLeft="10px"
              />
            }
          />
        )}
        pagination={{
          total: filteredTags()?.length || 0,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={filteredTags()}
        rowClassName={styles.row}
        rowKey={(tag: Tag) => tag.id}
        className={styles.table}
        locale={{
          emptyText: (
            <div className={styles.emptyContainer}>
              <h4 className={styles.emptyHeader}>No tags to display.</h4>
              <p className={styles.emptyParagraph}>Create a tag to store and use.</p>
            </div>
          ),
        }}
      />
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { ActionEnum } from 'data/actions/utils'
import { FormInstance } from 'antd'
import { InlineLabelInput } from 'components/Forms'
import { LabelText } from 'components/LabelText'
import React from 'react'
import { formatAwsPushService } from 'components/Actions/utils'
import styles from 'components/Actions/Push/PreviewAddPush.module.scss'

interface Props {
  form: FormInstance
  actionType: ActionEnum
}

export function PreviewImport(props: Props) {
  const { form, actionType } = props
  const awsIntegrations = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const createEnvironments = form.getFieldValue('createEnvironments')
  const createProjects = form.getFieldValue('createProjects')

  const integrationName = () => {
    const integrationId = form.getFieldValue('integration')

    switch (actionType) {
      case ActionEnum.AwsPull:
        return awsIntegrations[integrationId]!.name

      case ActionEnum.AzureKeyVaultPull:
        return akvIntegrations[integrationId]!.name

      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <LabelText text={form.getFieldValue('name')} label="Name" />
      <br />
      <LabelText text={integrationName()} label="Integration" />
      <br />
      {actionType === ActionEnum.AwsPull && (
        <div className={styles.labelContainer}>
          <LabelText
            text={formatAwsPushService(form.getFieldValue('service')[0])}
            label="Service"
          />
          <LabelText text={form.getFieldValue('region')[0]} label="Region" />
        </div>
      )}

      <br />
      <LabelText text={form.getFieldValue('resource')} label="Resource Name" />
      <br />

      {createEnvironments && (
        <InlineLabelInput
          checkbox
          checkboxProps={{ disabled: true, defaultChecked: createEnvironments }}
          name="createEnvironments"
          label={{ text: 'Auto-Create Environments' }}
        />
      )}

      {createProjects && (
        <InlineLabelInput
          checkbox
          checkboxProps={{ disabled: true, defaultChecked: createProjects }}
          name="createProjects"
          label={{ text: 'Auto-Create Projects' }}
        />
      )}
    </div>
  )
}

import {
  environmentEntitySelectors,
  getBaseEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'

import { Environment } from 'gen/cloudTruthRestApi'
import React from 'react'
import { TreeSelect } from 'antd'
import styles from './EnvironmentSelector.module.scss'
import { useAppSelector } from 'data/hooks'

const { SHOW_ALL } = TreeSelect

interface Props {
  environmentSelectValue: string[]
  onEnvChange: (values: string[]) => void
}

interface TreeNodeObj {
  key: string
  value: string
  title: string
  children: TreeNodeObj[]
}

const assembleTree = (env: Environment, allEnvironments: Environment[]): TreeNodeObj => {
  return {
    value: env.url,
    title: env.name,
    key: env.url,
    children: allEnvironments
      .filter((e) => e.parent === env.url)
      .map((childEnv) => assembleTree(childEnv, allEnvironments)),
  }
}

export function EnvironmentComparisonSelector(props: Props) {
  const { onEnvChange, environmentSelectValue } = props
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const baseEnvironment = useAppSelector(getBaseEnvironment)!
  const envTree = assembleTree(baseEnvironment, environments)

  return (
    <div className={styles.container}>
      <TreeSelect
        treeData={[envTree]}
        placeholder="Select environment"
        style={{ width: '100%' }}
        treeDefaultExpandAll={true}
        multiple
        defaultValue={environmentSelectValue}
        showCheckedStrategy={SHOW_ALL}
        value={environmentSelectValue}
        onChange={onEnvChange}
        allowClear={true}
        showSearch
        treeNodeFilterProp="title"
        data-cy="compare-select"
      />
    </div>
  )
}

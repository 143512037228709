//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useCallback, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, TableSearch } from 'components/Table'
import { conditionalArrayItem, makeTitleCase } from 'lib/valueHelpers'
import { getCurrentUser, getInteractiveUsers } from 'data/user/selectors'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { GetMemberships } from 'data/membership/actions'
import { GetUsers } from 'data/user/actions'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { RootPaths } from 'router/Router'
import { TableColumnsType } from 'antd'
import { User } from 'gen/cloudTruthRestApi'
import { UserDropdown } from './UserDropdown'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import { nameSorter } from 'lib/orderBy'
import styles from './UserTable.module.scss'
import { useForm } from 'components/Forms'
import { useHistoryPush } from 'router/customHooks'

export function UserTable() {
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const { goToRootRoute } = useHistoryPush()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const users = useAppSelector(getInteractiveUsers)
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'user' })
  const localPageSize = localSession?.pageSize
  const [pageSize, setPageSize] = useState(localPageSize ? localPageSize : 10)
  const columnSizes = localSession?.columnSizes
  const currentUser = useAppSelector(getCurrentUser)

  const [form] = useForm()
  const [search, setSearch] = useState('')

  const filteredUsers = useCallback(() => {
    return users.filter((user) => {
      if (user.name) {
        return user?.name.toLowerCase().includes(search.toLowerCase())
      }
    })
  }, [search, users])

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'user',
      args: { pageSize },
    })

  const [columns, setColumns] = useState<TableColumnsType<User>>([
    {
      title: 'NAME',
      key: 'name',
      ellipsis: true,
      dataIndex: 'name',
      sorter: nameSorter,

      width: storedColumnSize(columnSizes, 'name', 300),
      render: (_, user) => (
        <div>{user.id === currentUser?.id ? `${user.name} (You)` : user.name}</div>
      ),
    },
    {
      title: 'SCOPE',
      key: 'scope',
      dataIndex: 'scope',
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'scope', 150),
      render: (_, user) => (
        <>
          {user.role === 'CONTRIB'
            ? 'Contributor'
            : user.role === 'NO_SECRETS'
            ? 'NoSecretsViewer'
            : makeTitleCase(user.role || '')}
        </>
      ),
    },

    ...conditionalArrayItem(canAdministrate, [
      {
        title: '',
        width: 100,
        render: (_: any, user: User) => <UserDropdown user={user} />,
      },
    ]),
  ])

  const reloadData = useCallback(() => {
    setLoading(true)

    const getUsers = new Promise((resolve) => {
      return dispatch(GetUsers(null)).then(() => resolve({}))
    })
    const getMembers = new Promise((resolve) => {
      return dispatch(GetMemberships(null)).then(() => resolve({}))
    })

    Promise.all([getUsers, getMembers]).then(() => setLoading(false))
  }, [dispatch])

  return (
    <div>
      <PageTitle
        title="Users"
        description="Once a User accepts an invitation they will have an associated account shown here. You can change a Users's role to modify the level of access they have to the organization."
        buttons={
          <>
            <Reload onClick={reloadData} loading={loading} />
            {canAdministrate && (
              <ActionButton
                customType="add"
                size="large"
                onClick={() => goToRootRoute(RootPaths.Invite)}
              >
                Create Users
              </ActionButton>
            )}
          </>
        }
        search={
          <TableSearch
            defaultValue={search || ''}
            updateSearchTerm={setSearch}
            form={form}
            placeHolder="Search Users"
            marginRight="-15px"
          />
        }
      />
      <div>
        <Table
          loading={loading}
          columns={resizableColumns(columns, setColumns, currentOrganization.id, 'user')}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '50'],
            onChange: (_, pageSize) => {
              setPageSize(pageSize)
              setLocalPageSize(pageSize)
            },
          }}
          tableLayout="fixed"
          dataSource={filteredUsers()}
          rowKey={(user: User) => user.id}
          className={styles.table}
        />
      </div>
    </div>
  )
}

import { Menu, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'

import styles from './Details.module.scss'

interface Props {
  name: string
  dropdownMenu?: JSX.Element
  childrenContainerStyles?: React.CSSProperties
  styles?: React.CSSProperties
  headerStyles?: React.CSSProperties
  description?: string
}

export function Details(props: PropsWithChildren<Props>) {
  const {
    name,
    dropdownMenu,
    children,
    styles: containerStyles,
    childrenContainerStyles,
    headerStyles,
    description,
  } = props
  return (
    <div className={styles.container} style={containerStyles}>
      <div className={styles.header} style={headerStyles}>
        <div className={styles.nameContainer}>
          <div className={styles.name} data-cy="title">
            {name}
          </div>
          {description && (
            <Typography.Paragraph className={styles.description}>
              {description}
            </Typography.Paragraph>
          )}
        </div>

        {dropdownMenu}
      </div>

      <div className={styles.childrenContainer} style={childrenContainerStyles}>
        {children}
      </div>
    </div>
  )
}

export const AntMenu = Menu
export const MenuItem = Menu.Item

import { Form as AntForm, FormInstance, FormProps } from 'antd'
import React, { PropsWithChildren } from 'react'

import { Store } from 'rc-field-form/lib/interface'
import styles from './Form.module.scss'

export type FormData = Store

interface Props {
  form: FormInstance
  formProps?: FormProps
  onFinish?: (formData: FormData) => void
  initialValues?: FormData
}

export function Form(props: PropsWithChildren<Props>) {
  const { form, formProps, onFinish, initialValues, children } = props

  return (
    <AntForm
      className={styles.form}
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      hideRequiredMark={true}
      {...formProps}
    >
      {children}
    </AntForm>
  )
}

export const Item = AntForm.Item
export const List = AntForm.List
export const ErrorList = AntForm.ErrorList
export const useForm = AntForm.useForm

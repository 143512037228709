import { AzureKeyVaultIntegration, StatusEnum } from 'gen/cloudTruthRestApi'
import {
  CheckCircleFilled,
  CloseOutlined,
  ExclamationCircleFilled,
  WarningFilled,
} from '@ant-design/icons'
import { Popover, Spin, Typography } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CheckAzureKeyVaultIntegrationStatus } from 'data/integration/akvIntegration/actions'
import { emDash } from 'lib/valueHelpers'
import { formatLocalDateTime } from 'lib/dateHelpers'
import styles from './AkvStatus.module.scss'
import { useAppDispatch } from 'data/hooks'

interface Props {
  akvIntegration: AzureKeyVaultIntegration
  checking: boolean
  setChecking: (bool: boolean) => void
  closePopover: boolean
  setClosePopover: (bool: boolean) => void
  checkIntegration: () => void
}

export function AkvStatus(props: Props) {
  const { akvIntegration, checking, closePopover, setClosePopover, checkIntegration } = props
  const { status, status_detail, status_last_checked_at } = akvIntegration
  const [checkedIntegrationOnce, setCheckedIntegrationOnce] = useState<boolean | 'checking'>(false)

  const dispatch = useAppDispatch()
  const didCheckOnce = useRef(false)

  // poll status
  useEffect(() => {
    const now = new Date().getTime()
    const previous = status_last_checked_at ? new Date(status_last_checked_at).getTime() : null
    const statusTimer = setInterval(() => {
      if (akvIntegration.status === 'checking') {
        checkIntegration()
      }
    }, 3000)

    // didCheckOnce ensures that we do not double-check due to refreshes
    if (!previous || (!didCheckOnce.current && now - previous > 1000 * 60 * 60 * 12)) {
      didCheckOnce.current = true
      checkIntegration()
    }

    if (akvIntegration.status !== 'checking') {
      clearTimeout(statusTimer)
    }

    return () => clearTimeout(statusTimer)
  }, [akvIntegration, checkIntegration, didCheckOnce, status_last_checked_at])

  const visible = useMemo(() => {
    if (closePopover || checking) {
      return false
    }

    return status === StatusEnum.Errored && status_detail !== null
  }, [status, closePopover, status_detail, checking])

  // when the user performs an action that begins a check, instead of
  // polling, we force a checking until that action finishes

  const derivedAccountState = checkedIntegrationOnce === 'checking' ? StatusEnum.Checking : status

  const renderParts = () => {
    switch (derivedAccountState) {
      case StatusEnum.Connected: {
        return {
          statusText: <Typography.Text>Connected</Typography.Text>,
          statusIcon: <CheckCircleFilled className={styles.successIcon} />,
        }
      }

      case StatusEnum.Pending: {
        return {
          statusText: <Typography.Text>Pending</Typography.Text>,
          statusIcon: <WarningFilled className={styles.pendingIcon} />,
        }
      }

      case StatusEnum.Checking: {
        return {
          statusText: <Typography.Text>Checking Status</Typography.Text>,
          statusIcon: <Spin size="default" />,
        }
      }

      case StatusEnum.Errored: {
        return {
          statusText: <Typography.Text>Integration Error</Typography.Text>,
          statusIcon: <ExclamationCircleFilled className={styles.failureIcon} />,
        }
      }

      default: {
        if (!checkedIntegrationOnce) {
          setCheckedIntegrationOnce('checking')
          dispatch(CheckAzureKeyVaultIntegrationStatus(akvIntegration)).then(() => {
            setCheckedIntegrationOnce(true)
          })
          return {
            statusText: <Typography.Text>Checking Status</Typography.Text>,
            statusIcon: <Spin size="default" />,
          }
        }
        return {
          statusText: <Typography.Text>{`Status: ${status}`}</Typography.Text>,
          statusIcon: <ExclamationCircleFilled className={styles.failureIcon} />,
        }
      }
    }
  }

  const { statusText, statusIcon } = renderParts()

  return (
    <Popover
      placement="bottom"
      overlayClassName={styles.popover}
      content={status_detail}
      title={
        <div className={styles.detailsContainer}>
          <h4 className={styles.detailsHeader}>Details</h4>
          <CloseOutlined className={styles.pointer} onClick={() => setClosePopover(true)} />
        </div>
      }
      getPopupContainer={(trigger: any) => trigger.parentElement}
      open={visible}
    >
      <div className={styles.container}>
        <div className={styles.vertical}>
          <div className={styles.status}>{statusText}</div>
          <div className={styles.timestamp}>
            {status_last_checked_at
              ? `As of ${formatLocalDateTime(status_last_checked_at)}`
              : emDash}
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>{statusIcon}</div>
        </div>
      </div>
    </Popover>
  )
}

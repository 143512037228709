import { Divider } from 'antd'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import styles from './PageTitle.module.scss'

interface Props {
  title: string | JSX.Element
  description?: string | JSX.Element
  link?: JSX.Element
  buttons?: React.ReactElement
  search?: React.ReactElement
}

export function PageTitle(props: Props) {
  const { title, link, description, buttons, search } = props

  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div data-testid="title" className={styles.title}>
            {title}
          </div>

          {description && (
            <>
              <QuestionIconTooltip
                data-testid="tooltip"
                title={
                  <div>
                    <p>{description}</p>
                    <div className={styles.descriptionLink}>{link}</div>
                  </div>
                }
              />
            </>
          )}

          {search && <div className={styles.search}>{search}</div>}

          <div className={styles.buttonContainer}>{buttons}</div>
        </div>
      </div>
      <Divider className={styles.divider} />
    </>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Alert, Checkbox, FormInstance, InputNumber } from 'antd'
import {
  Form,
  FormData,
  InlineLabelInput,
  Label,
  LabeledInputItem,
  TextArea,
} from 'components/Forms'
import { Parameter, ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'

import ParameterPasswordGenerator from '../AddParameter/ParameterPasswordGenerator'
import { ParameterRuleType } from 'data/parameter/actions'
import RulesForm from 'components/Types/Rules/RulesForm'
import { SecretCheckbox } from '../SecretCheckbox'
import styles from './UpdateParameterSettingsForm.module.scss'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
  paramId: Parameter['id']
  error: nullable<string>
  generateChecked: boolean
  setGenerateChecked: (generateChecked: boolean) => void
  setExpirationDays: (test: maybe<string>) => void
  setLength: (length: number) => void
  setRequireUppercase: (requireUppercase: boolean) => void
  setRequireLowercase: (requireLowercase: boolean) => void
  setRequireNumbers: (requireNumbers: boolean) => void
  setRequireSymbols: (requireSymbols: boolean) => void
  setRequireSpaces: (requireSpaces: boolean) => void
  expiresChecked: boolean
  setExpiresChecked: (expiresChecked: boolean) => void

  passwordSettings: {
    action: string | null
    length: number
    require_uppercase: boolean
    require_lowercase: boolean
    require_numbers: boolean
    require_symbols: boolean
    require_spaces: boolean
  }
}

export function UpdateParameterSettingsForm(props: Props) {
  const {
    form,
    onFinish,
    paramId,
    error,
    setExpirationDays,
    setLength,
    setRequireUppercase,
    setRequireLowercase,
    setRequireNumbers,
    setRequireSymbols,
    setRequireSpaces,
    setGenerateChecked,
    expiresChecked,
    setExpiresChecked,
    generateChecked,
    passwordSettings,
  } = props

  const { name, description, rules, secret, type, expiration_action, expires, overrides } =
    parameterEntitySelectors.selectById(useSelectParameters(), paramId)!

  const initialRules = rules.map(({ constraint, id, type, constraints }) => {
    if (type === ParameterRuleTypeEnum.OneOf) {
      return { constraints, id, type } as ParameterRuleType
    }
    return { constraint, id, type } as ParameterRuleType
  })

  const [expiresInputNumber, setExpiresInputNumber] = useState<nullable<number>>(
    expires ? Number(expires.slice(0, 2)) : 30
  )

  const expiresOnChange = (value: number | null) => {
    form.setFieldsValue({ expires: value })
    setExpiresInputNumber(value)
    setExpirationDays(value?.toString())
  }

  const ParameterExpires = () => {
    return (
      <LabeledInputItem label={{ text: 'Expires in' }} name="expires" required={true}>
        <div className={styles.daysContainer}>
          <InputNumber
            min={1}
            max={365}
            defaultValue={expiresInputNumber || 30}
            onChange={expiresOnChange}
            className={styles.daysInput}
            onDragEnter={(e) => e.preventDefault()}
          />
          <Label text="Days" />
        </div>
      </LabeledInputItem>
    )
  }

  return (
    <>
      <Form
        form={form}
        initialValues={{
          description,
          secret: !!secret,
          parameterType: type,
          name,
          expires: expires || 30,
          generateChecked: generateChecked,
          expiration_action: {
            action: generateChecked ? 'generate_password' : null,
            length: expiration_action?.length || '16',
            require_uppercase: !!passwordSettings.require_uppercase,
            require_lowercase: !!passwordSettings.require_lowercase,
            require_numbers: !!passwordSettings.require_numbers,
            require_symbols: !!passwordSettings.require_symbols,
            require_spaces: !!passwordSettings.require_spaces,
          },
        }}
        onFinish={onFinish}
      >
        {error && <Alert message={error} type="error" showIcon closable />}

        <InlineLabelInput
          name="name"
          label="Name"
          required="Please enter a parameter name."
          disabled={!!overrides}
        />
        <TextArea
          autoFocus
          name="description"
          label="Description"
          placeholder="Type description here"
        />

        <SecretCheckbox disabled={true} secret={secret} />
      </Form>
      <RulesForm
        form={form}
        initialRules={initialRules}
        onFinish={onFinish}
        isOverride={!!overrides}
      />

      <LabeledInputItem
        name="expiration"
        label={{
          text: 'Expires',
        }}
        tooltipText="Parameter expires in a specified number of days"
      >
        <Checkbox checked={expiresChecked} onChange={(e) => setExpiresChecked(e.target.checked)} />
      </LabeledInputItem>

      {expiresChecked && (
        <div className={styles.expirationOptions}>
          <ParameterExpires />

          {
            <>
              <LabeledInputItem
                name="passwordGeneration"
                label={{
                  text: 'Auto-Generate Value',
                }}
              >
                <Checkbox
                  checked={generateChecked}
                  onChange={(e) => setGenerateChecked(e.target.checked)}
                />
              </LabeledInputItem>
              {generateChecked && (
                <ParameterPasswordGenerator
                  setLength={setLength}
                  setRequireUppercase={setRequireUppercase}
                  setRequireLowercase={setRequireLowercase}
                  setRequireNumbers={setRequireNumbers}
                  setRequireSymbols={setRequireSymbols}
                  setRequireSpaces={setRequireSpaces}
                  passwordSettings={passwordSettings}
                />
              )}
            </>
          }
        </div>
      )}
    </>
  )
}

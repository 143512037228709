import React, { useEffect } from 'react'

import { PageLoading } from 'components/PageLoading'
import { RootPaths } from 'router/Router'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

export function Signup() {
  const auth0Context = useAuth0()
  const { successToast } = useToast()
  const { goToRootRoute } = useHistoryPush()

  useEffect(() => {
    if (auth0Context.isAuthenticated) {
      goToRootRoute(RootPaths.App)
    }
  }, [auth0Context, goToRootRoute, successToast])

  return <PageLoading />
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'

export enum BaseParameterTypes {
  String = 'string',
  Integer = 'integer',
  Boolean = 'boolean',
  Enum = 'enum',
}

const trues = ['true', 'True', 'TRUE', '1', 'yes', 'Yes', 'YES']
const falses = ['false', 'False', 'FALSE', '0', 'no', 'No', 'NO']
export const booleans = trues.concat(falses)

export const validateBoolean = (value: string): boolean => {
  return booleans.includes(value)
}

export const validateInteger = (value: string): boolean => {
  return !!value.match(/^-?\d+$/)
}

export const validateRegex = (value: string): boolean => {
  let isValid = true

  try {
    new RegExp(value)
  } catch (_) {
    isValid = false
  }

  return isValid
}

export const validateRuleOptions = (
  type: BaseParameterTypes,
  option: ParameterRuleTypeEnum
): boolean => {
  switch (type) {
    case BaseParameterTypes.String:
      return [
        ParameterRuleTypeEnum.Regex,
        ParameterRuleTypeEnum.MaxLen,
        ParameterRuleTypeEnum.MinLen,
      ].includes(option)

    case BaseParameterTypes.Integer:
      return [ParameterRuleTypeEnum.Max, ParameterRuleTypeEnum.Min].includes(option)

    case BaseParameterTypes.Enum:
      return true

    default:
      return false
  }
}

import { CustomProvider, Nav, Sidenav } from 'rsuite'
import React, { useState } from 'react'
import { getLocalSession, setLocalSession } from 'lib/sessionPersistance'

import AdvancedAnalyticsIcon from '@rsuite/icons/AdvancedAnalytics'
import CloudReflashIcon from '@rsuite/icons/CloudReflash'
import DashboardIcon from '@rsuite/icons/Dashboard'
import FunnelStepsIcon from '@rsuite/icons/FunnelSteps'
import GearIcon from '@rsuite/icons/Gear'
import { HistoryViews } from '../HistoryTable'
import { MenuProps } from 'antd'
import MoveUpIcon from '@rsuite/icons/MoveUp'
import OffIcon from '@rsuite/icons/Off'
import { Organization } from 'gen/cloudTruthRestApi'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { RootPaths } from 'router/Router'
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal'
import TableColumnIcon from '@rsuite/icons/TableColumn'
import TreeIcon from '@rsuite/icons/Tree'
import TreemapIcon from '@rsuite/icons/Treemap'
import { authService } from 'lib/authService'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './SideNav.module.scss'
import { useAppSelector } from 'data/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistoryPush } from 'router/customHooks'
import { useLocation } from 'react-router'

enum ProjectMenuItemKey {
  Parameters = '/parameters',
  Templates = '/templates',
  Compare = '/compare',
  History = '/history',
  Expiration = '/expiration',
  NoSelect = '/NoSelect',
}

enum MiscKeys {
  Switch = '/switch',
  Logout = 'logout',
}

export function SideNav() {
  const userPolicy = useAppSelector(getPolicy(null))
  const auth0Context = useAuth0()
  const { pathname } = useLocation()
  const {
    goToOrgRoute,
    goToParameterRoute,
    goToTemplateRoute,
    goToCompareRoute,
    goToHistoryRoute,
    goToRootRoute,
  } = useHistoryPush()

  const allPaths = [
    ...Object.values(ProjectMenuItemKey),
    ...Object.values(OrganizationPaths),
    ...Object.values(MiscKeys),
  ]
  const currentKey = allPaths.find((key) => pathname.includes(key))

  const currentOrganization = useAppSelector(getCurrentOrganization)
  const localSession = currentOrganization
    ? getLocalSession({ org: currentOrganization?.id, pageType: 'sideNav' })
    : null

  const expandedNav = localSession?.expandedNav

  const [expanded, setExpanded] = useState<boolean>(
    typeof expandedNav === 'boolean' ? expandedNav : true
  )

  const [activeKey, setActiveKey] = useState(currentKey || '')

  const setLocalExpandedNav = (expanded: boolean, currentOrganization: Organization) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'sideNav',
      args: { expandedNav: expanded },
    })

  /************************************  HANDLE FUNCTIONS *******************************************************/
  const handleMenuClick = (key: string) => {
    setActiveKey(key)

    const path = key as OrganizationPaths
    if (Object.values(OrganizationPaths).includes(path)) {
      const orgPath = path as OrganizationPaths
      goToOrgRoute(orgPath)
      // check if it's a project path
    } else if (key === ProjectMenuItemKey.Parameters) {
      goToParameterRoute(null)
    } else if (key === ProjectMenuItemKey.Templates) {
      goToTemplateRoute(null)
    } else if (key === ProjectMenuItemKey.Compare) {
      goToCompareRoute(null)
    } else if (key === ProjectMenuItemKey.History) {
      goToHistoryRoute(null, HistoryViews.Parameter)
    } else if (key === MiscKeys.Switch) {
      goToRootRoute(RootPaths.Switch)
    } else if (key === MiscKeys.Logout) {
      authService.logout(auth0Context)
    }

    return
  }

  const reportsSubMenu: MenuProps['items'] = [
    {
      key: ProjectMenuItemKey.Compare,
      label: 'Compare',
    },
    {
      key: ProjectMenuItemKey.History,
      label: 'History',
    },
    {
      key: ProjectMenuItemKey.Expiration,
      label: 'Expiration',
    },
  ].map((item) => ({
    ...item,
    label: (
      <span className={styles.menuItem} data-testid={item.key}>
        {item.label}
      </span>
    ),
  }))

  const integrationSubItems: MenuProps['items'] = [
    { key: OrganizationPaths.Aws, label: 'AWS' },
    { key: OrganizationPaths.Akv, label: 'Azure' },
    { key: OrganizationPaths.Github, label: 'GitHub' },
    { key: OrganizationPaths.Explorer, label: 'Explorer' },
  ].map((item) => ({
    ...item,
    label: (
      <span className={styles.menuItem} data-testid={item.key}>
        {item.label}
      </span>
    ),
  }))

  const actionSubItems: MenuProps['items'] = [
    { key: OrganizationPaths.PullActions, label: 'Import' },
    { key: OrganizationPaths.PushActions, label: 'Push' },
  ].map((item) => ({
    ...item,
    label: (
      <span className={styles.menuItem} data-testid={item.key}>
        {item.label}
      </span>
    ),
  }))

  const accessControlSubItems: MenuProps['items'] = [
    { key: OrganizationPaths.UserDirectory, label: 'Users' },

    ...conditionalArrayItem(userPolicy.canContribute, [
      {
        key: OrganizationPaths.Groups,
        label: 'Groups',
      },
      {
        key: OrganizationPaths.ProjectsAccessControl,
        label: 'Projects',
      },

      {
        key: OrganizationPaths.EnvironmentsAccessControl,
        label: 'Environments',
      },
    ]),
  ].map((item) => ({
    ...item,
    label: (
      <span className={styles.menuItem} data-testid={item.key}>
        {item.label}
      </span>
    ),
  }))

  const adminSubItems: MenuProps['items'] = [
    ...conditionalArrayItem(userPolicy.canAdministrate, [
      {
        key: 'access-control-sub-menu',
        label: 'Access Control',
        children: accessControlSubItems,
      },
      {
        key: 'integration-sub-menu',
        label: 'Integrations',
        children: integrationSubItems,
      },
    ]),

    { key: OrganizationPaths.Api, label: 'API Tokens', icon: <SettingHorizontalIcon /> },
    { key: OrganizationPaths.Types, label: 'Types', icon: <FunnelStepsIcon /> },

    ...conditionalArrayItem(userPolicy.canAdministrate, [
      {
        key: OrganizationPaths.AuditLog,
        label: 'Audit Log',
      },
      {
        key: OrganizationPaths.Settings,
        label: 'Settings',
      },
    ]),
  ].map((item) => ({
    ...item,
    label: (
      <span className={styles.menuItem} data-testid={item.key}>
        {item.label}
      </span>
    ),
  }))

  const navItems = [
    { key: OrganizationPaths.Dashboard, label: 'Dashboard', icon: <DashboardIcon /> },
    {
      key: ProjectMenuItemKey.Parameters,
      label: 'Parameters',
      icon: <TableColumnIcon />,
    },
    {
      key: ProjectMenuItemKey.Templates,
      label: 'Templates',
      icon: <SettingHorizontalIcon />,
    },
    {
      key: 'reports-sub-menu',
      label: 'Reports',
      children: reportsSubMenu,
      icon: <AdvancedAnalyticsIcon />,
    },
    { key: OrganizationPaths.Projects, label: 'Projects', icon: <TreemapIcon /> },
    { key: OrganizationPaths.Environments, label: 'Environments', icon: <TreeIcon /> },
    { key: 'actions-sub-menu', label: 'Actions', children: actionSubItems, icon: <MoveUpIcon /> },

    ...conditionalArrayItem(userPolicy.canContribute, [
      {
        key: 'admin-sub-menu',
        label: 'Admin',
        children: adminSubItems,
        icon: <GearIcon />,
      },
    ]),

    { key: MiscKeys.Switch, label: 'Switch Organizations', icon: <CloudReflashIcon /> },
    { key: MiscKeys.Logout, label: 'Logout', icon: <OffIcon /> },
  ]

  return (
    <div className={expanded ? styles.container : styles.collapsedContainer}>
      <CustomProvider>
        <Sidenav expanded={expanded} defaultOpenKeys={['project-sub-menu']}>
          <Sidenav.Body>
            <Nav activeKey={activeKey} onSelect={handleMenuClick}>
              {navItems.map((item) => {
                if (item.children) {
                  return (
                    <Nav.Menu
                      key={item.key}
                      icon={item.icon}
                      placement="rightStart"
                      eventKey={item.key}
                      title={item.label}
                    >
                      {item.children.map((child: { label: string; key: string; children: any }) => {
                        if (child.children) {
                          return (
                            <Nav.Menu
                              placement="rightStart"
                              key={child.key}
                              eventKey={child.key}
                              title={child.label}
                            >
                              {child.children.map((child: { label: string; key: string }) => {
                                return (
                                  <Nav.Item eventKey={child.key} key={`${child.key}+2`}>
                                    {child.label}
                                  </Nav.Item>
                                )
                              })}
                            </Nav.Menu>
                          )
                        } else {
                          return (
                            <Nav.Item key={`${child.key}+4`} eventKey={child.key}>
                              {child.label}
                            </Nav.Item>
                          )
                        }
                      })}
                    </Nav.Menu>
                  )
                } else {
                  return (
                    <Nav.Item key={`${item.key}+4`} eventKey={item.key} icon={item.icon}>
                      {item.label}
                    </Nav.Item>
                  )
                }
              })}
            </Nav>
          </Sidenav.Body>
          <Sidenav.Toggle
            onToggle={(expanded) => {
              setExpanded(expanded), setLocalExpandedNav(expanded, currentOrganization!)
            }}
            data-testid="toggle"
          />
        </Sidenav>
      </CustomProvider>
    </div>
  )
}

import { FormInstance, Select } from 'antd'
import React, { useMemo } from 'react'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { ActionEnum } from 'data/actions/utils'
import { AwsServiceEnum } from 'gen/cloudTruthRestApi'
import { useToast } from 'hooks'

const { Option } = Select

interface Props {
  integration: nullable<string>
  selectIntegration: (integration: nullable<string>) => void
  clearServices: () => void
  clearRegions: () => void
  form: FormInstance
  editing?: boolean
  actionType: ActionEnum
}

export function IntegrationSelector(props: Props) {
  const { selectIntegration, form, clearRegions, clearServices, integration, editing, actionType } =
    props
  const awsIntegrations = awsIntegrationEntitySelectors.selectAll(useSelectAwsIntegrations())
  const awsIntegrationEntities = awsIntegrationEntitySelectors.selectEntities(
    useSelectAwsIntegrations()
  )
  const akvIntegrations = akvIntegrationEntitySelectors.selectAll(useSelectAkvIntegrations())
  const akvIntegrationEntities = akvIntegrationEntitySelectors.selectEntities(
    useSelectAkvIntegrations()
  )

  const { errorToast } = useToast()

  const usableIntegrations = useMemo(() => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return awsIntegrations.filter((integration) => {
          return (
            integration.aws_enabled_services.includes(AwsServiceEnum.Secretsmanager) ||
            integration.aws_enabled_services.includes(AwsServiceEnum.Ssm)
          )
        })

      case ActionEnum.AwsPull:
        return awsIntegrations.filter((integration) => {
          return integration.aws_enabled_services.includes(AwsServiceEnum.Ssm)
        })

      case ActionEnum.AzureKeyVaultPush:
        return akvIntegrations

      case ActionEnum.AzureKeyVaultPull:
        return akvIntegrations

      default:
        return []
    }
  }, [awsIntegrations, actionType, akvIntegrations])

  const handleChange = (id: string) => {
    const chooseIntegration = () => {
      selectIntegration(id)
      form.setFieldsValue({ integration: id, service: null, region: null })
    }

    clearRegions()
    clearServices()

    const handleAwsPush = () => {
      const selectedIntegration = awsIntegrationEntities[id]
      if (!selectedIntegration?.writable) {
        selectIntegration(null)
        form.setFieldsValue({ integration: null, service: null, region: null })
        errorToast('Write access must be enabled to use this integration.')
      } else {
        chooseIntegration()
      }
    }

    const handleAkvPush = () => {
      const selectedIntegration = akvIntegrationEntities[id]
      if (!selectedIntegration?.writable) {
        selectIntegration(null)
        form.setFieldsValue({ integration: null, service: null, region: null })
        errorToast('Write access must be enabled to use this integration.')
      } else {
        chooseIntegration()
      }
    }

    switch (actionType) {
      case ActionEnum.AwsPush:
        handleAwsPush()

        break

      case ActionEnum.AzureKeyVaultPush:
        handleAkvPush()

        break

      case ActionEnum.AwsPull:
        chooseIntegration()
        break

      case ActionEnum.AzureKeyVaultPull:
        chooseIntegration()
        break

      default:
        break
    }
  }

  const value = () => {
    switch (actionType) {
      case ActionEnum.AwsPush:
        return awsIntegrationEntities[integration || '']?.name || null

      case ActionEnum.AzureKeyVaultPush:
        return akvIntegrationEntities[integration || '']?.name || null

      case ActionEnum.AzureKeyVaultPull:
        return akvIntegrationEntities[integration || '']?.name || null

      case ActionEnum.AwsPull:
        return awsIntegrationEntities[integration || '']?.name || null

      default:
        return null
    }
  }

  return (
    <Select
      placeholder="Select an Integration"
      style={{ width: '100%' }}
      onChange={handleChange}
      disabled={editing}
      value={value()}
    >
      {usableIntegrations.map((integration, index) => (
        <Option key={index} value={integration.id}>
          {integration.name}
        </Option>
      ))}
    </Select>
  )
}

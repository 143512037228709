import { LabelText } from 'components/LabelText'
import React from 'react'
import styles from './DetailsDescription.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  description: maybe<string>
  noContainerStyle?: boolean
  maxWidth?: string
}

export function DetailsDescription(props: Props) {
  const { description, noContainerStyle, maxWidth } = props

  return (
    <div className={!noContainerStyle ? styles.container : undefined}>
      <LabelText
        text={valueOrEmDash(description)}
        paragraph
        isHorizontal
        textMaxHeight="90px"
        maxWidth={maxWidth || '400px'}
        label="Description:"
      />
    </div>
  )
}

import { ActionCard, Flow, H3Styled, Messages, handleFlowError, ory } from '..'
import { CardTitle, P } from '@ory/themes'
import { OryQueryParamName, useQuery } from 'router/customHooks'
import React, { ReactNode, useEffect, useState } from 'react'
import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/kratos-client'

import { AxiosError } from 'axios'
import { Methods } from 'components/Ory/types'
import styles from './Settings.module.scss'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'hooks'

interface Props {
  flow?: SettingsFlow
  only?: Methods
}

function SettingsCard({ flow, only, children }: Props & { children: ReactNode }) {
  if (!flow) {
    return null
  }

  const nodes = only ? flow.ui.nodes.filter(({ group }) => group === only) : flow.ui.nodes

  if (nodes.length === 0) {
    return null
  }

  return <ActionCard wide>{children}</ActionCard>
}

export function Settings() {
  const [flow, setFlow] = useState<SettingsFlow>()
  const [loading, setLoading] = useState(true)

  // Get ?flow=... from the URL
  const router = useNavigate()
  const { getOryQuery } = useQuery()
  const { errorToast } = useToast()
  const flowId = getOryQuery(OryQueryParamName.Flow)
  const returnTo = getOryQuery(OryQueryParamName.ReturnTo)

  useEffect(() => {
    const done = () => setLoading(false)
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      done()
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getSettingsFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data)
        })
        .catch(handleFlowError(router, 'settings', setFlow, errorToast))
        .finally(done)
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserSettingsFlow({ returnTo: returnTo ? String(returnTo) : undefined })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(handleFlowError(router, 'settings', setFlow, errorToast))
      .finally(done)
  }, [flowId, router, returnTo, flow, errorToast])

  const onSubmit = (values: UpdateSettingsFlowBody) =>
    new Promise((resolve) => {
      router(`/settings?flow=${flow?.id}`)
      resolve({})
    }).then(() =>
      ory
        .updateSettingsFlow({ flow: String(flow?.id), updateSettingsFlowBody: values })
        .then(({ data }) => {
          // The settings have been saved and the flow was updated. Let's show it to the user!
          setFlow(data)
        })
        .catch(handleFlowError(router, 'settings', setFlow, errorToast))
        .catch(async (err: AxiosError) => {
          // If the previous handler did not catch the error it's most likely a form validation error
          if (err.response?.status === 400) {
            // Yup, it is!
            setFlow(err.response?.data)
            return
          }

          return Promise.reject(err)
        })
    )

  if (loading) {
    return <div className={styles.container} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.settingsContainer}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} />
          </div>
        </div>

        <CardTitle style={{ marginTop: 80 }} className={styles.title}>
          Profile Management and Security Settings
        </CardTitle>
        <Messages messages={flow?.ui.messages} />

        <SettingsCard only="profile" flow={flow}>
          <H3Styled>Profile Settings</H3Styled>

          <Flow hideGlobalMessages onSubmit={onSubmit} only="profile" flow={flow} />
        </SettingsCard>
        <SettingsCard only="password" flow={flow}>
          <H3Styled>Change Password</H3Styled>
          <Flow hideGlobalMessages onSubmit={onSubmit} only="password" flow={flow} />
        </SettingsCard>
        <SettingsCard only="oidc" flow={flow}>
          <H3Styled>Manage Social Sign In</H3Styled>

          <Flow hideGlobalMessages onSubmit={onSubmit} only="oidc" flow={flow} />
        </SettingsCard>
        {/* <SettingsCard only="lookup_secret" flow={flow}>
        <H3>Manage 2FA Backup Recovery Codes</H3>
        <Messages messages={flow?.ui.messages} />
        <P>
          Recovery codes can be used in panic situations where you have lost
          access to your 2FA device.
        </P>

        <Flow
          hideGlobalMessages
          onSubmit={onSubmit}
          only="lookup_secret"
          flow={flow}
        />
      </SettingsCard> */}
        <SettingsCard only="totp" flow={flow}>
          <H3Styled>Manage 2FA TOTP Authenticator App</H3Styled>
          <P>
            Add a TOTP Authenticator App to your account to improve your account security. Popular
            Authenticator Apps are{' '}
            <a href="https://www.lastpass.com" rel="noreferrer" target="_blank">
              LastPass
            </a>{' '}
            and Google Authenticator (
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer"
            >
              iOS
            </a>
            ,{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              Android
            </a>
            ).
          </P>

          <Flow hideGlobalMessages onSubmit={onSubmit} only="totp" flow={flow} />
        </SettingsCard>
        {/* <SettingsCard only="webauthn" flow={flow}>
        <H3>Manage Hardware Tokens and Biometrics</H3>
        <Messages messages={flow?.ui.messages} />
        <P>
          Use Hardware Tokens (e.g. YubiKey) or Biometrics (e.g. FaceID,
          TouchID) to enhance your account security.
        </P>
        <Flow
          hideGlobalMessages
          onSubmit={onSubmit}
          only="webauthn"
          flow={flow}
        />
      </SettingsCard> */}
      </div>
    </div>
  )
}

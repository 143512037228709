import { HourGlassTooltip } from './HourGlassTooltip'
import React from 'react'
import dayjs from 'dayjs'

interface Props {
  date: string | Date
}

const calculateTimeDifference = (date: string | Date) => {
  const days = dayjs(date).diff(dayjs(), 'day')
  const hours = days === 0 ? dayjs(date).diff(dayjs(), 'hour') : null
  const minutes = hours === 0 && days === 0 ? dayjs(date).diff(dayjs(), 'minute') : null

  const seconds =
    minutes === 0 && days === 0 && hours === 0 ? dayjs(date).diff(dayjs(), 'second') : null

  return { days, hours, minutes, seconds }
}

const toolTip = (date: string | Date) => {
  const { seconds, minutes, hours, days } = calculateTimeDifference(date)

  if (seconds) {
    return seconds < 1
      ? `Warning: This value expired ${Math.abs(seconds)} second${
          Math.abs(seconds) > 1 ? 's' : ''
        } ago!`
      : `Warning: This value is set to expire in ${seconds} second${seconds > 1 ? 's' : ''}`
  }

  if (minutes) {
    return minutes < 1
      ? `Warning: This value expired ${Math.abs(minutes)} minute${
          Math.abs(minutes) > 1 ? 's' : ''
        } ago!`
      : `Warning: This value is set to expire in ${minutes} minute${minutes > 1 ? 's' : ''}`
  }

  if (hours) {
    return hours < 1
      ? `Warning: This value expired ${Math.abs(hours)} hour${Math.abs(hours) > 1 ? 's' : ''} ago!`
      : `Warning: This value is set to expire in ${hours} hour${hours > 1 ? 's' : ''}`
  }

  return days < 0
    ? `Warning: This value expired ${Math.abs(days)} day${Math.abs(days) > 1 ? 's' : ''} ago!`
    : days && days > 0 && days && days <= 5
    ? `Warning: This value is set to expire in ${days} day${days > 1 ? 's' : ''}`
    : `This va

    lue is set to expire in ${days} days.`
}

const color = (date: string | Date) => {
  const { seconds, minutes, hours, days } = calculateTimeDifference(date)

  if (seconds) {
    return seconds < 1 ? 'red' : '#faad14'
  }

  if (minutes) {
    return minutes < 1 ? 'red' : '#faad14'
  }

  if (hours) {
    return hours < 1 ? 'red' : '#faad14'
  }

  return days! < 1 ? 'red' : days! <= 5 ? '#faad14' : ''
}

export function ExpiresHourGlass(props: Props) {
  const { date } = props
  const [tooltip, colour] = [toolTip(date), color(date)]

  return <HourGlassTooltip centerSpan title={tooltip} color={colour} />
}

import { Flow as FlowType, Methods, Values } from 'components/Ory/types'
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client'
import { getNodeId, isUiNodeInputAttributes } from '@ory/integrations/ui'

import { Messages } from './Messages'
import { Node } from './Node'
import { useLocation } from 'react-router-dom'

interface Props {
  flow?: FlowType
  only?: Methods
  onSubmit: (values: any) => Promise<void>
  hideGlobalMessages?: boolean
  // overrides password method
  method?: string
}

export function Flow(props: Props) {
  const { flow, only, onSubmit, hideGlobalMessages } = props

  const method = () => {
    return flow?.ui.nodes
      .filter(({ group }) => {
        if (!only) {
          return true
        }
        return group === 'default' || group === only
      })
      .filter((node) => {
        const attributes = node.attributes

        if (attributes.node_type === 'input') {
          const att = attributes as UiNodeInputAttributes

          return att.name === 'method'
        } else {
          return false
        }
      })
      .map((node) => {
        const att = node.attributes as UiNodeInputAttributes
        return att.value
      })[0]
  }

  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<any>({})

  // returns UI nodes returned from Ory server
  const nodes = useMemo((): Array<UiNode> => {
    if (!flow) {
      return []
    }

    return flow.ui.nodes.filter(({ group }) => {
      if (!only) {
        return true
      }
      return group === 'default' || group === only
    })
  }, [flow, only])

  // sets values for
  const initializeValues = useCallback(() => {
    const values: any = {}

    nodes.forEach(({ attributes }) => {
      // This only makes sense for text nodes
      if (isUiNodeInputAttributes(attributes)) {
        if (attributes.type === 'button') {
          // In order to mimic real HTML forms, we need to skip setting the value
          // for buttons as the button value will (in normal HTML forms) only trigger
          // if the user clicks it.
          return
        }

        values[attributes.name] = attributes.value
      }
    })

    setValues(values)
  }, [nodes])

  useEffect(() => {
    initializeValues()
  }, [initializeValues])

  const handleSubmit = async (e: MouseEvent | FormEvent, provider?: string) => {
    // Prevent all native handlers
    e.stopPropagation()
    e.preventDefault()

    // Prevent double submission!
    if (loading) {
      return Promise.resolve()
    }

    const submitValues = () => {
      // if a user needs to fill out additional information after social auth sign in or registration
      // we need to provide all of the form field values, and also inject the provider, and method
      if (
        provider &&
        pathname.includes('registration') &&
        !Object.keys(values).includes('password')
      ) {
        return { ...values, method: 'oidc', provider }
      }

      if (provider && pathname.includes('settings')) {
        const linkOrUnlink = () => {
          const oidcNode = nodes.filter((node) => {
            if (node.attributes.node_type === 'input') {
              const att = node.attributes as UiNodeInputAttributes
              return att.value === provider
            }
          })

          if (oidcNode.length === 1) {
            const att = oidcNode[0].attributes as UiNodeInputAttributes
            return { [att.name]: att.value }
          }
        }

        return { ...linkOrUnlink(), csrf_token: values['csrf_token'] }
      }

      /**
       *  if theres a provider, we know it's social auth, so we shouldn't pass in email/password form values
       *  if there's no provider we know it's email & password
       *   we pass the provider to dispatchSubmit function via props
       */

      return provider
        ? { provider, method: 'oidc', csrf_token: values['csrf_token'] }
        : { ...values, method: method() }
    }

    return onSubmit(submitValues()).finally(() => {
      // We wait for reconciliation and update the state after 50ms
      // Done submitting - update loading status
      setLoading(false)
    })
  }

  // flow is required for signing in
  if (!flow) {
    return null
  }

  return (
    <form action={flow.ui.action} method={flow.ui.method} onSubmit={handleSubmit}>
      {!hideGlobalMessages ? <Messages messages={flow.ui.messages} /> : null}
      {nodes
        .filter((node) => {
          if (isUiNodeInputAttributes(node.attributes)) {
            return node.attributes.name !== 'traits.current_org'
          } else {
            return true
          }
        })
        .map((node, k) => {
          const id = getNodeId(node as any) as keyof Values

          return (
            <Node
              key={`${id}-${k}`}
              disabled={loading}
              node={node}
              value={values[id]}
              dispatchSubmit={handleSubmit}
              setValue={(value) =>
                new Promise((resolve) => {
                  setValues((prevState: any) => ({
                    ...prevState,
                    [getNodeId(node as any)]: value,
                  }))

                  resolve()
                })
              }
            />
          )
        })}
    </form>
  )
}

import React, { useState } from 'react'

import { IntegrationNode } from 'gen/cloudTruthRestApi'
import MonacoEditor from '@monaco-editor/react'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { mimeType } from 'lib/valueHelpers'
import styles from './FileBody.module.scss'

interface Props {
  integrationFile: IntegrationNode
  showSecret?: boolean
  secret?: nullable<boolean>
}

export function FileBody(props: Props) {
  const { integrationFile, showSecret, secret } = props
  const [showSecrets, setShowSecrets] = useState(showSecret || false)

  const hideValue = () => !showSecrets && secret

  return (
    <div className={styles.container}>
      <div className={styles.valueContainer}>
        <label className={styles.label}>VALUE</label>
        <div className={styles.toggleSecrets}>
          {secret && (
            <ToggleSecrets
              handleToggleClick={() => setShowSecrets((prev) => !prev)}
              showSecretValues={showSecrets}
            />
          )}
        </div>
      </div>
      <div data-private="redact" className={styles.editor}>
        <MonacoEditor
          language={mimeType(integrationFile.name)}
          value={hideValue() ? '*****' : integrationFile.content_data || ''}
          options={{ readOnly: true, contextmenu: false }}
          loading={<div />}
        />
      </div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AzureKeyVaultIntegration,
  AzureKeyVaultIntegrationCreate,
  IntegrationsAzureKeyVaultDestroyParams,
  PaginatedAzureKeyVaultIntegrationList,
  PatchedAzureKeyVaultIntegration,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetAzureKeyVaultIntegrations = createAsyncThunk<
  PaginatedAzureKeyVaultIntegrationList,
  null,
  ThunkConfig
>(
  'akvIntegration/getAzureKeyVaultIntegrations',
  async (_, thunkApi) =>
    await handleFetch(Verbs.Get, 'integrations/azure/key_vault', null, thunkApi)
)

export const GetAzureKeyVaultIntegration = createAsyncThunk<
  AzureKeyVaultIntegration,
  string,
  ThunkConfig
>(
  'akvIntegration/getAzureKeyVaultIntegration',
  async (id, thunkApi) =>
    await handleFetch(Verbs.Get, `integrations/azure/key_vault/${id}`, null, thunkApi)
)

export const CreateAzureKeyVaultIntegration = createAsyncThunk<
  AzureKeyVaultIntegration,
  AzureKeyVaultIntegrationCreate,
  ThunkConfig
>(
  'akvIntegration/createAzureKeyVaultIntegration',
  async (akvIntegration, thunkApi) =>
    await handleFetch(Verbs.Post, 'integrations/azure/key_vault', akvIntegration, thunkApi)
)

export const UpdateAzureKeyVaultIntegration = createAsyncThunk<
  AzureKeyVaultIntegration,
  PatchedAzureKeyVaultIntegration,
  ThunkConfig
>(
  'akvIntegration/updateAzureKeyVaultIntegration',
  async (akvIntegration, thunkApi) =>
    await handleFetch(
      Verbs.Put,
      `integrations/azure/key_vault/${akvIntegration.id}`,
      akvIntegration,
      thunkApi
    )
)

export const DeleteAzureKeyVaultIntegration = createAsyncThunk<
  string,
  IntegrationsAzureKeyVaultDestroyParams,
  ThunkConfig
>('akvIntegration/deleteAzureKeyVaultIntegration', async (args, thunkApi) => {
  const query = { in_use: args.in_use || 'fail' }
  return await handleFetch(
    Verbs.Delete,
    `integrations/azure/key_vault/${args.id}`,
    null,
    thunkApi,
    buildQuery(query)
  )
})

export const CheckAzureKeyVaultIntegrationStatus = createAsyncThunk<
  AzureKeyVaultIntegration,
  PatchedAzureKeyVaultIntegration,
  ThunkConfig
>('akvIntegration/checkAzureKeyVaultIntegrationStatus', async (akvIntegration, thunkApi) => {
  // This is a hack to force a 'status_last_checked_at' update. It will be replaced by a proper route
  const query = { refresh_status: 'true' }
  return await handleFetch(
    Verbs.Get,
    `integrations/azure/key_vault/${akvIntegration.id}`,
    null,
    thunkApi,
    buildQuery(query)
  )
})

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { FormAlert, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { ActionEnum } from 'data/actions/utils'
import { AddModal } from 'components/Modals'
import { AwsPull } from 'gen/cloudTruthRestApi'
import { CreateAwsPull } from 'data/actions/awsImport/actions'
import { CreateAzureKeyVaultPull } from 'data/actions/akvImport/actions'
import { ImportForm } from './ImportForm'
import { ModalStepsFooter } from 'components/Actions/ActionsForm/ModalStepsFooter'
import { TypedThunk } from 'data/dataUtils'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  actionType: ActionEnum
}

export function AddImport(props: Props) {
  const { actionType } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [error, setError] = useState<nullable<string>>(null)
  const [pending, setPending] = useState(false)
  const [form] = useForm()
  const [steps, setSteps] = useState(0)
  const dispatch = useAppDispatch()
  const { successToast } = useToast()

  const onCancel = () => {
    setVisible(false)
    setError(null)
    setSteps(0)
    form.resetFields()
  }

  const onOk = () => {
    form.submit()
    setError(null)
    if (steps === 2) {
      form.submit()
    }
    if (steps < 2) {
      form.validateFields().then(() => setSteps((steps) => (steps += 1)))
    }
  }

  const onPrevious = () => {
    setError(null)
    if (steps > 0) {
      setSteps((steps) => (steps -= 1))
    }
  }

  const handleSubmit = (dry_run: boolean) => {
    setPending(true)
    // Values aren't coming in with FormData, so they have to be fetched manually
    // It might have something to do with form components mounting and unmounting
    // due to steps

    const integrationId = form.getFieldValue('integration')
    const region = form.getFieldValue('region') ? form.getFieldValue('region')[0] : null
    const service = form.getFieldValue('service') ? form.getFieldValue('service')[0] : null
    const resource = form.getFieldValue('resource')
    const name = form.getFieldValue('name')
    const description = form.getFieldValue('description')
    const create_environments = form.getFieldValue('createEnvironments')
    const create_projects = form.getFieldValue('createProjects')

    const pull = {
      service,
      resource,
      description,
      name,
      dry_run,
      create_environments,
      create_projects,
    }

    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(
          CreateAwsPull({ pull: { ...pull, region, service }, awsIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPull>) => {
          if (error) {
            setError(error.message)
          } else {
            successToast('Import successfully created')
            onCancel()
          }

          setPending(false)
        })

        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(CreateAzureKeyVaultPull({ pull, akvIntegrationId: integrationId })).then(
          ({ error }: TypedThunk<AwsPull>) => {
            if (error) {
              setError(error.message)
            } else {
              successToast('Import successfully created')
              onCancel()
            }

            setPending(false)
          }
        )

        break

      default:
        break
    }
  }

  return (
    <>
      <ActionButton
        size="large"
        customType="add"
        onClick={() => setVisible(true)}
        data-cy="import-button"
      >
        Import
      </ActionButton>

      <AddModal
        visible={visible}
        width="1000px"
        objectName="Import"
        footer={
          <ModalStepsFooter
            objectName="Import"
            onCancel={onCancel}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={pending}
            onDryRun={() => handleSubmit(true)}
          />
        }
        pending={pending}
      >
        {error && <FormAlert description={error} message="Action could not be created." />}

        <ImportForm
          form={form}
          steps={steps}
          handleSubmit={() => handleSubmit(false)}
          actionType={actionType}
        />
      </AddModal>
    </>
  )
}

import { Login, MfaSetup, Recovery, Registration } from 'components/Ory'
import { Route, Routes } from 'react-router-dom'

import { App } from 'App'
import { AppInstrumentation } from 'components/AppInstrumentation'
import { EmailVerification } from 'components/Ory/EmailVerification'
import { GithubCallback } from 'components/Integrations/Github/GithubCallback'
import { InviteAccept } from 'components/Invitations'
import { InviteMembers } from 'components/InviteMembers'
import { ListIdentities } from 'components/Ory/Admin/ListIdentities/ListIdentities'
import { Organizations } from 'components/Organizations'
import React from 'react'
import { Settings } from 'components/Ory/Settings'
import { Signup } from 'components/Invitations/signup'
import { UserDetails } from 'components/Ory/Admin/ListIdentities/UserDetails/UserDetails'

export enum RootPaths {
  App = '/',
  Account = '/account',
  Invitation = '/invitations',
  Invite = '/invite',
  Organization = '/organization',
  Projects = '/projects/:id',
  Signup = '/signup',
  Switch = '/switch',
  GithubCallback = '/github-callback',
}

export enum OryPaths {
  Login = '/login',
  Registration = '/registration',
  Recovery = '/recovery',
  Verification = '/verification',
  Settings = '/settings',
  MfaSetup = '/mfa-setup',
  OryAdmin = '/ory-admin',
  OryAdminUserPage = '/ory-admin/users/:identityId',
}

export function Router(): JSX.Element {
  return (
    <AppInstrumentation>
      <Routes>
        <Route path={OryPaths.Login} element={<Login />} />
        <Route path={OryPaths.Registration} element={<Registration />} />
        <Route path={OryPaths.Recovery} element={<Recovery />} />
        <Route path={OryPaths.Verification} element={<EmailVerification />} />
        <Route path={OryPaths.Settings} element={<Settings />} />
        <Route path={OryPaths.MfaSetup} element={<MfaSetup />} />
        <Route path={OryPaths.OryAdmin} element={<ListIdentities />} />
        <Route path={OryPaths.OryAdminUserPage} element={<UserDetails />} />
        <Route path={RootPaths.Organization} element={<Organizations />} />
        <Route path={RootPaths.Signup} element={<Signup />} />
        <Route path={RootPaths.Invite} element={<InviteMembers />} />
        <Route
          path={`${RootPaths.Organization}/:id${RootPaths.Invitation}/:id`}
          element={<InviteAccept />}
        />
        <Route path={RootPaths.GithubCallback} element={<GithubCallback />} />
        <Route path={`${RootPaths.App}*`} element={<App />} />
      </Routes>
    </AppInstrumentation>
  )
}

import { IntegrationNode } from 'gen/cloudTruthRestApi'
import { IntegrationTree } from '../IntegrationExplorer'
import React from 'react'
import { ServiceTreeEntry } from './ServiceTreeEntry'

interface Props {
  integrationTree: IntegrationTree
  onSelect: (fqn: string) => void
  pending: boolean
  selectedFqn: nullable<string>
  showExternalSecretValues: boolean
}

export function ServiceTree(props: Props) {
  const { integrationTree, onSelect, pending, selectedFqn, showExternalSecretValues } = props
  return (
    <>
      {integrationTree.entries.map((entry: IntegrationNode) => (
        <ServiceTreeEntry
          showExternalSecretValues={showExternalSecretValues}
          key={entry.fqn}
          integrationTree={entry}
          onSelect={onSelect}
          selectedFqn={selectedFqn}
          pending={pending && entry.fqn === selectedFqn}
        />
      ))}
    </>
  )
}

import { CreateRule, DeleteRule, UpdateParameter, UpdateRule } from 'data/parameter/actions'
import { FormData, useForm } from 'components/Forms'
import { Parameter, ParameterRuleTypeEnum } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { EditModal } from 'components/Modals/EditModal'
import { UpdateParameterSettingsForm } from './UpdateParameterSettingsForm'
import { buildRuleUpdates } from 'components/Types/Rules/utils'
import { getCurrentProject } from 'data/project/selectors'
import { useToast } from 'hooks'

interface Props {
  closeModal: () => void
  visible: boolean
  paramId: Parameter['id']
  maskSecrets?: boolean
  getUpdatedTimeline?: () => void
}

export function UpdateParameterSettings(props: Props) {
  const { closeModal, visible, paramId, maskSecrets } = props

  const {
    id,
    rules: initialRules,
    overrides,
    project_name,
    expires,
    expiration_action,
  } = parameterEntitySelectors.selectById(useSelectParameters(), paramId)!
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const [pending, setPending] = useState<boolean>(false)
  const [form] = useForm()
  const [updateError, setUpdateError] = useState<nullable<string>>(null)

  const [expirationDays, setExpirationDays] = useState<maybe<string>>(
    expires ? expires.slice(0, 2).trim() : '30'
  )
  const [generateChecked, setGenerateChecked] = useState(!!expiration_action)
  const [length, setLength] = useState(expiration_action?.length || 99)
  const [requireUppercase, setRequireUppercase] = useState(
    expiration_action?.require_uppercase || true
  )
  const [requireLowercase, setRequireLowercase] = useState(
    expiration_action?.require_lowercase || true
  )
  const [requireNumbers, setRequireNumbers] = useState(expiration_action?.require_lowercase || true)
  const [requireSymbols, setRequireSymbols] = useState(expiration_action?.require_symbols || false)
  const [requireSpaces, setRequireSpaces] = useState(expiration_action?.require_spaces || false)
  const [expiresChecked, setExpiresChecked] = useState(!!expires)

  const passwordSettings = {
    action: expiration_action?.action,
    length,
    require_uppercase: requireUppercase,
    require_lowercase: requireLowercase,
    require_numbers: requireNumbers,
    require_symbols: requireSymbols,
    require_spaces: requireSpaces,
  }

  const currentProject = useAppSelector(getCurrentProject)!

  const isOverride = !!overrides && currentProject?.name === project_name

  const onFinish = async (formData: FormData) => {
    const { description, secret, parameterType: type, rules, name } = formData

    const expirationObject = {
      expires: expirationDays && expiresChecked ? `${expirationDays} 00:00:00` : null,
      expiration_action: generateChecked ? passwordSettings : undefined,
    }

    setPending(true)

    let successes = 0
    const errorList: string[] = []
    const { createRules, updateRules, deleteRules } = buildRuleUpdates(initialRules, rules)

    const remainingUpdates = createRules.length + updateRules.length

    const done = () => {
      setPending(false)
      if (errorList.length === 0) {
        setUpdateError(null)
        successToast('Parameter successfully updated')
        closeModal()
      } else {
        errorList.forEach((error) => errorToast(error))
      }
    }

    const continueUpdate = () => {
      successes = 0
      dispatch(
        UpdateParameter({ id, name, description, secret, type, maskSecrets, ...expirationObject })
      ).then(({ error }: CustomThunk) => {
        if (error) {
          setUpdateError(
            `Error: Current parameter values do not match the requested type: ${type}.`
          )

          setPending(false)
          return
        } else if (!isOverride) {
          updateRules.forEach((rule) => {
            dispatch(UpdateRule({ paramId, ruleId: rule.id, rule: rule })).then(
              ({ error }: CustomThunk) => {
                error
                  ? setUpdateError(
                      `Error: Current parameter values do not match the requested type: ${type}.`
                    )
                  : successes++
                if (remainingUpdates === successes + errorList.length) {
                  done()
                }
              }
            )
          })

          if (type === 'enum') {
            dispatch(
              CreateRule({
                paramId,
                type: ParameterRuleTypeEnum.OneOf,
                constraints: rules.map((value: any) => value['key']),
              })
            ).then(({ error }: CustomThunk) => {
              if (error) {
                errorToast(error.message)
              }
            })
          } else {
            createRules.forEach((rule) => {
              dispatch(CreateRule({ paramId, ...rule })).then(({ error }: CustomThunk) => {
                error ? errorList.push(error.message) : successes++
                if (remainingUpdates === successes + errorList.length) {
                  done()
                }
              })
            })
          }

          if (remainingUpdates === 0) {
            done()
          }
        } else {
          done()
        }
      })
    }

    if (deleteRules.length > 0) {
      deleteRules.forEach((rule) => {
        dispatch(DeleteRule({ paramId, ruleId: rule.id })).then(({ error }: CustomThunk) => {
          if (error) {
            errorToast(error.message)
            setPending(false)
          } else {
            successes++
            if (deleteRules.length === successes) {
              continueUpdate()
            }
          }
        })
      })
    } else {
      continueUpdate()
    }
  }

  return (
    <EditModal
      visible={visible}
      onCancel={() => {
        closeModal(), setUpdateError(null), form.resetFields()
      }}
      afterClose={() => {
        setUpdateError(null), form.resetFields()
      }}
      onOk={form.submit}
      pending={pending}
      objectName="parameter"
    >
      <UpdateParameterSettingsForm
        form={form}
        onFinish={onFinish}
        paramId={paramId}
        error={updateError}
        setLength={setLength}
        passwordSettings={passwordSettings}
        generateChecked={generateChecked}
        expiresChecked={expiresChecked}
        setExpiresChecked={setExpiresChecked}
        setGenerateChecked={setGenerateChecked}
        setRequireUppercase={setRequireUppercase}
        setRequireLowercase={setRequireLowercase}
        setRequireNumbers={setRequireNumbers}
        setRequireSymbols={setRequireSymbols}
        setRequireSpaces={setRequireSpaces}
        setExpirationDays={setExpirationDays}
      />
    </EditModal>
  )
}

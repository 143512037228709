import React from 'react'
import { Spin } from 'antd'
import styles from './PageLoading.module.scss'

export function PageLoading() {
  return <Spin className={styles.pageLoadingIndicator} size="large" data-testid="loading" />
}

export function ComponentLoading() {
  return <Spin className={styles.componentLoadingIndicator} data-testid="loading" />
}

import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

import styles from './CopyText.module.scss'

interface Props {
  payload: string
  prompt: string
  color?: string
  margin?: string
}

export function CopyText(props: Props) {
  const { payload, prompt, color, margin } = props

  const [hasCopied, setHasCopied] = useState(false)

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false)
      }, 2500)
    }
  }, [hasCopied])

  const copyString = async (copy: string) => {
    await navigator.clipboard.writeText(copy)
    setHasCopied(true)
  }

  return (
    <span>
      {!hasCopied ? (
        <span className={styles.copyContainer} onClick={() => copyString(payload)}>
          <CopyOutlined className={styles.copyIcon} style={{ color }} />
          <span className={styles.copyLabel} style={{ color }}>
            {prompt}
          </span>
        </span>
      ) : (
        <span onClick={() => copyString(payload)} style={{ margin }}>
          <CheckOutlined className={styles.checkIcon} />
          <span className={styles.copiedLabel}>Copied</span>
        </span>
      )}
    </span>
  )
}

import { ActionButton } from 'components/ActionButton'
import { Button } from 'antd'
import React from 'react'
import styles from './UpdateModalFooter.module.scss'

interface Props {
  showDeleteOverride?: boolean
  pending?: boolean
  disabled?: boolean
  onDelete?: () => void
  loading: boolean
  evaluated?: boolean
  interpolated?: boolean
  onCancel: () => void
  onSave: () => void
  evaluatedRaw?: () => void
  isEnumType?: boolean
}

export function UpdateModalFooter(props: Props) {
  const {
    showDeleteOverride,
    pending,
    onCancel,
    onDelete,
    onSave,
    disabled,
    loading,
    evaluatedRaw,
    evaluated,
    interpolated,
    isEnumType,
  } = props

  const DeleteOverride = () => {
    if (!showDeleteOverride) {
      return null
    }
    return (
      <Button danger type="link" onClick={onDelete}>
        Remove Environment Override
      </Button>
    )
  }

  const InterpolatedAction = () => {
    if (interpolated) {
      return (
        <ActionButton
          disabled={loading}
          className={styles.interpolatedAction}
          onClick={evaluatedRaw}
        >
          {!evaluated ? 'Evaluate' : 'Show Raw'}
        </ActionButton>
      )
    } else {
      return null
    }
  }

  return (
    <div className={styles.footer} style={isEnumType ? { marginTop: '150px' } : undefined}>
      <div>
        <DeleteOverride />
      </div>
      <div>
        <ActionButton onClick={onCancel} disabled={pending}>
          Cancel
        </ActionButton>
        {!pending && <InterpolatedAction />}
        <ActionButton
          type="primary"
          htmlType="submit"
          onClick={onSave}
          disabled={disabled}
          loading={pending}
        >
          Save
        </ActionButton>
      </div>
    </div>
  )
}

import { Checkbox, Divider, Input } from 'antd'
import { Item, LabeledInputItem } from 'components/Forms'

import { AddResourceTags } from '../AddResourceTags'
import { AwsServiceCheckboxes } from './AwsServiceCheckboxes'
import { QuestionIconTooltip } from 'components/QuestionIconTooltip'
import React from 'react'
import styles from './AwsAccountFormBody.module.scss'
import { useFeatureGate } from 'hooks'

export const ACCOUNT_ID_FIELD = 'accountId'
export const EXTERNAL_ID_FIELD = 'externalId'
export const KMS_ID_FIELD = 'kmsId'
export const ROLE_NAME_FIELD = 'roleName'

interface Props {
  resourceTags?: Record<string, string>[]
}

export function AwsAccountFormBody(props: Props) {
  const { featuresGated } = useFeatureGate()

  const { resourceTags } = props

  return (
    <div className={styles.body}>
      <div className={styles.accountInfoColumn}>
        <div className={styles.accountFieldsGroup}>
          <h3>Account Information</h3>
          <LabeledInputItem
            name={ACCOUNT_ID_FIELD}
            label="AWS ACCOUNT ID"
            required="Please enter the ID of the AWS account you'd like CloudTruth to access"
          >
            <Input autoFocus className={styles.input} />
          </LabeledInputItem>
          <LabeledInputItem
            name={ROLE_NAME_FIELD}
            label="ROLE NAME"
            required="Please enter the name of the IAM role you've created for CloudTruth to access your configuration data"
          >
            <Input data-private="redact" className={styles.input} />
          </LabeledInputItem>
          <LabeledInputItem
            name={KMS_ID_FIELD}
            label={{
              text: 'KMS KEY ID',
            }}
            tooltipText="Enter an AWS KMS Key identifier to be used with all push updates, or leave this blank to use your AWS account default key."
          >
            <Input data-private="redact" className={styles.input} />
          </LabeledInputItem>
          {!featuresGated && (
            <LabeledInputItem
              name={EXTERNAL_ID_FIELD}
              label={{
                text: 'AWS External ID',
              }}
              tooltipText="Enter an External ID, or leave this blank to have an External ID autogenerated."
            >
              <Input.Password data-private="redact" className={styles.input} />
            </LabeledInputItem>
          )}
        </div>
        <div>
          <h3>CloudTruth Access</h3>
          <p>Select which AWS services CloudTruth can access.</p>
          <div className={styles.checkboxGroups}>
            <AwsServiceCheckboxes className={styles.checkbox} />
            <Item name="checkbox_writable" valuePropName="checked">
              <Checkbox>
                Write Access
                <QuestionIconTooltip title="Enabling write access will allow outbound push actions for the selected services." />
              </Checkbox>
            </Item>
          </div>
        </div>

        <div className={styles.resourceTags}>
          <h3>Resource Tags</h3>

          <AddResourceTags resourceTags={resourceTags} />
        </div>
      </div>

      <Divider className={styles.dividerColumn} type="vertical" />

      <div className={styles.policyColumn}>
        <h3>AWS IAM Policy Setup</h3>
        <p>
          To finish setting up this AWS integration, please go to your AWS account to set
          permissions for CloudTruth to access the services that have been specified. Detailed
          instructions for setting up the AWS integration and IAM roles and policies can be found at
          the links below.
        </p>
        <p>
          <a
            href="https://docs.cloudtruth.com/integrations/aws/setup-aws-role"
            target="_blank"
            rel="noreferrer"
          >
            CloudTruth Integrations
          </a>
        </p>
        <p>
          <a href="https://docs.aws.amazon.com/iam/index.html" target="_blank" rel="noreferrer">
            AWS Identity and Access Management documentation
          </a>
        </p>
      </div>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { Invitation, User } from 'gen/cloudTruthRestApi'
import { UserRootState, userAdapter } from 'data/user/reducer'

import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectUser = (state: RootState) => state.user

export const getCurrentUser = createSelector(selectUser, (state): nullable<User> => {
  return state.current ? state.entities[state.current]! : null
})

export const userEntitySelectors = userAdapter.getSelectors()
export const useSelectUsers = (): UserRootState => useSelectState().user

export const getPendingInvitations = createSelector(selectUser, (state): Invitation[] => {
  return state.invitations.filter((i) => i.state !== 'accepted')
})

export const getInvitations = createSelector(selectUser, (state): Invitation[] => {
  return state.invitations
})

export const getInteractiveUsers = createSelector(selectUser, (state): User[] => {
  return Object.values(state.entities).filter((user) => user?.type === 'interactive') as User[]
})

export const getServiceUsers = createSelector(selectUser, (state): User[] => {
  return Object.values(state.entities).filter((user) => user?.type === 'service') as User[]
})

import { AwsIntegration, AwsRegionEnum, AwsServiceEnum } from 'gen/cloudTruthRestApi'
import { displayNameForRegion, formatRegionDisplayList } from '../util'

import { AwsActionList } from 'components/Integrations/ActionList'
import { Label } from 'components/Forms'
import { LabelText } from 'components/LabelText'
import React from 'react'
import styles from './AwsAccountsListPanelContent.module.scss'

interface Props {
  awsIntegration: AwsIntegration
}

export function AwsAccountsListPanelContent(props: Props) {
  const { awsIntegration } = props
  const {
    aws_account_id,
    aws_role_name,
    aws_enabled_services,
    aws_external_id,
    aws_enabled_regions,
    aws_kms_key_id,
    writable,
    resource_tags,
  } = awsIntegration

  const regions = aws_enabled_regions.map((region: AwsRegionEnum) => region)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.regionContainer}>
          <h2 className={styles.accountInfoHeader}>Account Information</h2>
          <LabelText label="AWS Account #" text={aws_account_id} />
          <LabelText label="Role Name" text={aws_role_name} />
          <LabelText label="KMS Key Id" text={aws_kms_key_id || '(AWS account default)'} />
          <LabelText secret label="External Id" text={aws_external_id} />
        </div>

        <div className={styles.cloudtruthAccessContainer}>
          <h2 className={styles.cloudtruthAccess}>CloudTruth Access</h2>
          <ul className={styles.servicesList}>
            {aws_enabled_services.map((item: AwsServiceEnum, key: number) => (
              <li key={key}>{serviceDisplayName(item)}</li>
            ))}
            {writable && <li>Write Access</li>}
          </ul>
        </div>

        <div className={styles.regionContainer}>
          <h2>Region Preference</h2>

          <div className={styles.regionListContainer}>
            {formatRegionDisplayList(regions).map((item, key) => (
              <div key={key}>
                <Label text={item.regionLabel} />
                <ul className={styles.regionList}>
                  {item.regions.map((region) => (
                    <li key={region}>{displayNameForRegion(region)}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {resource_tags && Object.values(resource_tags).length > 0 && (
          <div className={styles.regionContainer}>
            <h2>Resource Tags</h2>

            <div className={styles.regionListContainer}>
              <table className={styles.table}>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>

                {Object.entries(resource_tags).map(([key, value], index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
      </div>
      {writable && <AwsActionList awsIntegration={awsIntegration} />}
    </>
  )
}

export function serviceDisplayName(service: AwsServiceEnum) {
  if (service == AwsServiceEnum.Secretsmanager) {
    return 'Secrets Manager'
  } else {
    return service.toUpperCase()
  }
}

import { Checkbox, FormInstance } from 'antd'

import { AwsServiceEnum } from 'gen/cloudTruthRestApi'
import { FeatureGate } from 'components/FeatureGate'
import { Item } from 'components/Forms'
import React from 'react'

/*********** Checkbox props definition, used internally to this module **********/
export interface CheckboxProps {
  display: string
  featureGated: boolean
  fieldName: string
  value?: boolean
}

export const allAwsServices: readonly AwsServiceEnum[] = [
  AwsServiceEnum.S3,
  AwsServiceEnum.Secretsmanager,
  AwsServiceEnum.Ssm,
]

function checkboxField(service: AwsServiceEnum) {
  return `checkbox_${service}`
}

function checkboxProps(service: AwsServiceEnum) {
  switch (service) {
    case AwsServiceEnum.S3:
      return {
        display: 'S3',
        featureGated: false,
        fieldName: checkboxField(AwsServiceEnum.S3),
      }
    case AwsServiceEnum.Ssm:
      return {
        display: 'SSM Parameter Store',
        featureGated: false,
        fieldName: checkboxField(AwsServiceEnum.Ssm),
      }
    case AwsServiceEnum.Secretsmanager:
      return {
        display: 'Secrets Manager',
        featureGated: false,
        fieldName: checkboxField(AwsServiceEnum.Secretsmanager),
      }
    default:
      throw new Error(`Unhandled service type ${service}`)
  }
}

function serviceToCheckboxValuesMap() {
  return new Map<AwsServiceEnum, CheckboxProps>(
    allAwsServices.map((service) => [service, checkboxProps(service)])
  )
}

function fieldNameForService(service: AwsServiceEnum): string {
  return serviceToCheckboxValuesMap().get(service)!.fieldName
}

/********** exported functions for consumption of checkbox fields **********/

export function getEnabledServices(form: FormInstance): AwsServiceEnum[] {
  return allAwsServices.filter((service) => form.getFieldValue(fieldNameForService(service)))
}

export function initialServiceCheckboxValues(
  enabledServices: AwsServiceEnum[] | readonly AwsServiceEnum[]
) {
  const serviceMap = serviceToCheckboxValuesMap()

  return Object.fromEntries(
    allAwsServices.map((service) => [
      serviceMap.get(service)!.fieldName,
      enabledServices.includes(service),
    ])
  )
}

/********** service checkboxes component **********/

type AwsServiceCheckboxesProps = {
  className?: string
}

export function AwsServiceCheckboxes(props: AwsServiceCheckboxesProps) {
  const { className } = props
  const servicesMap = serviceToCheckboxValuesMap()

  return (
    <div>
      {Array.from(servicesMap.entries()).map(([service, { fieldName, featureGated, display }]) => {
        const item = (
          <Item key={service} className={className} name={fieldName} valuePropName="checked">
            <Checkbox>{display}</Checkbox>
          </Item>
        )

        if (featureGated) {
          return <FeatureGate key={item.key}>{item}</FeatureGate>
        } else {
          return item
        }
      })}
    </div>
  )
}

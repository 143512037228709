import { ActionButton } from '../ActionButton'
import { Modal } from 'antd'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons/lib'
import styles from './ConfirmModal.module.scss'

interface Props {
  onCancel: () => void
  visible: boolean
  onOk: () => void
  subject?: string
  body?: nullable<string | JSX.Element>
  loading?: boolean
  cancelText?: string
  confirmText?: string
  changeDetails?: JSX.Element
}

export function ConfirmModal(props: Props) {
  const {
    body,
    cancelText,
    confirmText,
    visible,
    onCancel,
    onOk,
    subject,
    loading,
    changeDetails,
  } = props

  return (
    <Modal
      footer={null}
      open={visible}
      closable={false}
      width="550px"
      data-testid="modal"
      destroyOnClose
    >
      <div className={styles.container}>
        <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
        {changeDetails}
        {subject && <h2>{subject}</h2>}

        {body && (
          <p className={styles.body} data-testid="confirmBody">
            <br />
            {body}
          </p>
        )}
        <div className={styles.buttonContainer}>
          <ActionButton onClick={onCancel}>{cancelText || 'Cancel'}</ActionButton>
          <ActionButton type="primary" danger onClick={onOk} loading={loading}>
            {confirmText || 'OK'}
          </ActionButton>
        </div>
      </div>
    </Modal>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPull, ModeEnum } from 'gen/cloudTruthRestApi'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { displayStatus, formatAwsPushService, pullIds } from 'components/Actions/utils'
import {
  githubIntegrationEntitySelectors,
  useSelectGithubIntegrations,
} from 'data/integration/githubIntegration/selectors'

import { ActionEnum } from 'data/actions/utils'
import { Details } from 'components/Details'
import { ImportDetailsDropdown } from './ImportDetailsDropdown'
import { LabelText } from 'components/LabelText'
import { PageLoading } from 'components/PageLoading'
import { PullType } from './ImportDetailsPage'
import React from 'react'
import { displayLastPush } from 'components/Actions/Push/PushTable'
import { getPolicy } from 'data/membership/selectors'
import styles from './ImportDetails.module.scss'
import { useAppSelector } from 'data/hooks'
import { valueOrEmDash } from 'lib/valueHelpers'

interface Props {
  refreshPull: () => void
  actionType: ActionEnum
  pull: PullType
}

const renderName = (pull: PullType): string => {
  return pull.dry_run ? `${pull.name} (Dry Run)` : pull.name
}

export function ImportDetails(props: Props) {
  const { refreshPull, actionType, pull } = props
  const { canContribute } = useAppSelector(getPolicy(null))
  const awsEntities = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvEntities = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const githubEntities = githubIntegrationEntitySelectors.selectEntities(
    useSelectGithubIntegrations()
  )

  const displayIntegration = (url: string) => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return awsEntities[pullIds(url, actionType).integrationId]?.name

      case ActionEnum.AzureKeyVaultPull:
        return akvEntities[pullIds(url, actionType).integrationId]?.name

      case ActionEnum.GithubPull:
        return githubEntities[pullIds(url, actionType).integrationId]?.name

      default:
        return ''
    }
  }

  if (!pull) {
    return <PageLoading />
  }

  const { description, url, latest_task } = pull
  const awsPull = pull as AwsPull

  const dropdownMenu = canContribute ? (
    <ImportDetailsDropdown
      pull={pull}
      refreshPull={refreshPull}
      actionType={actionType}
      redirect
      color="black"
    />
  ) : undefined

  return (
    <Details
      name={renderName(pull)}
      dropdownMenu={dropdownMenu}
      description={description}
      headerStyles={{ alignItems: 'flex-start' }}
    >
      <div className={styles.row}>
        {actionType === ActionEnum.AwsPull && (
          <div className={styles.column}>
            <LabelText isHorizontal text={displayIntegration(url)} label="Integration:" />
            <LabelText
              isHorizontal
              text={valueOrEmDash(formatAwsPushService(awsPull.service))}
              label="Service:"
            />
            <LabelText isHorizontal text={valueOrEmDash(awsPull.region)} label="Region:" />
          </div>
        )}
        <div className={styles.column}>
          {pull.mode !== ModeEnum.Mapped && (
            <LabelText isHorizontal text={awsPull.resource} label="Resource Pattern:" />
          )}
          <LabelText
            isHorizontal
            text={displayLastPush(latest_task?.modified_at)}
            label="Last Import Time:"
          />
          <LabelText isHorizontal text={displayStatus(latest_task?.state)} label="Status:" />
        </div>
      </div>
    </Details>
  )
}

import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { CustomThunk } from 'data/dataUtils'
import { DeleteGroup } from 'data/group/actions'
import { EditGroupSettings } from './EditGroupSettings'
import { Group } from 'gen/cloudTruthRestApi'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MenuProps } from 'antd'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

const EDIT_GROUP = 'editGroup'
const DELETE_GROUP = 'deleteGroup'

interface Props {
  group: Group
}

export function GroupDetailsDropdown(props: Props) {
  const { group } = props
  const { errorToast, successToast } = useToast()
  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()

  const [deleteVisible, setDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [settingsModalVisible, setSettingsModalVisible] = useState(false)

  const deleteGroup = () => {
    setLoading(true)

    const handleError = (error: string) => {
      errorToast(error)
      setDeleteVisible(false)
    }

    dispatch(DeleteGroup(group.id)).then(({ error }: CustomThunk) => {
      error ? handleError(error.message) : successToast('Group successfully deleted.')

      setLoading(false)
    })
  }

  const menuClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case EDIT_GROUP: {
        setSettingsModalVisible(true)
        break
      }

      case DELETE_GROUP: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items: MenuProps['items'] = [
    ...conditionalArrayItem(canContribute, [
      { key: EDIT_GROUP, label: 'Edit Group Settings' },
      { key: DELETE_GROUP, label: 'Delete Group', danger: true },
    ]),
  ]

  return (
    <div>
      <ConfirmModal
        visible={deleteVisible}
        subject={`Are you sure you want to delete ${group.name}?`}
        onCancel={() => setDeleteVisible(false)}
        onOk={deleteGroup}
        loading={loading}
      />
      <EditGroupSettings
        group={group}
        visible={settingsModalVisible}
        closeModal={() => setSettingsModalVisible(false)}
      />

      <MoreIconDropdown menu={{ items, onClick: menuClick }} color="black" />
    </div>
  )
}

import { Checkbox, FormInstance } from 'antd'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { AwsServiceEnum } from 'gen/cloudTruthRestApi'
import React from 'react'
import { formatAwsPushService } from '../utils'

interface Props {
  values: string[]
  setValues: (values: string[]) => void
  form: FormInstance
  integration: string
  editing?: boolean
}

export function ServiceCheckboxes(props: Props) {
  const { values, setValues, form, integration, editing } = props
  const awsIntegrations = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())

  const services = awsIntegrations[integration]?.aws_enabled_services.filter(
    (service) => service === AwsServiceEnum.Secretsmanager || service === AwsServiceEnum.Ssm
  )

  const options = services?.map((service) => ({
    label: formatAwsPushService(service),
    value: service,
    disabled: values.length > 0 && !values.includes(service),
  }))

  const onChange = (values: any) => {
    setValues(values)
    form.setFieldsValue({ service: values })
  }

  return <Checkbox.Group options={options} onChange={onChange} value={values} disabled={editing} />
}

import { OrganizationParams, OrganizationPaths } from './OrganizationRoutes'
import { OryPaths, RootPaths } from './Router'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ActionViews } from 'components/Actions/ActionContainer'
import { HistoryViews } from 'components/HistoryTable'
import { ProjectParams } from './ProjectRoutes'
import { useAppSelector } from 'data/hooks'
import { useToast } from '../hooks'

interface HistoryPushReturnType {
  goToOrgRoute: (path: OrganizationPaths) => void
  goToLoginRoute: () => void
  goToRegistrationRoute: () => void
  goToVerificationRoute: () => void
  goToOryAdminRoute: () => void
  goToOrySettingsRoute: () => void
  goToMfaSetupRoute: () => void
  goToRecoveryRoute: () => void
  goToParameterPage: (id: string, projectId?: string) => void
  goToGroupPage: (id: string) => void
  goToExpireRoute: (id: string) => void
  goToTemplatePage: (id: string) => void
  goToOryAdminUserPage: (id: string) => void
  goToAwsPushActionPage: (integrationId: string, actionId: string) => void
  goToAwsPullActionPage: (integrationId: string, actionId: string) => void
  goToAkvPullActionPage: (integrationId: string, actionId: string) => void
  goToAkvPushActionPage: (integrationId: string, actionId: string) => void
  goToGithubPullActionPage: (integrationId: string, actionId: string) => void
  goToRootRoute: (path: RootPaths) => void
  // current project does not update in time for sidenav project switch
  goToParameterRoute: (id: nullable<string>, noSearch?: boolean) => void
  goToCompareRoute: (id: nullable<string>, noSearch?: boolean) => void
  goToActionsRoute: (path: ActionViews, hash?: ActionsHash) => void
  goToHistoryRoute: (id: nullable<string>, view: HistoryViews, noSearch?: boolean) => void
  goToTemplateRoute: (id: nullable<string>, search?: string) => void
  goToAwsRoute: () => void
  goToAkvRoute: () => void
  goHome: () => void
  goToCopiedParameterPage: (id: string) => void
}

export enum ActionsHash {
  AWS = '#aws',
  AKV = '#azure-key-vault',
  GITHUB = '#github',
}

export const useHistoryPush = (): HistoryPushReturnType => {
  const navigate = useNavigate()
  const { infoToast } = useToast()
  const projectId = useAppSelector((state) => state.project.current)
  const search = useLocation().search

  const infoToastMessageHelper = (path: string) => {
    const isHistory = path.includes('history')
    const isParameter = path.includes('parameters')
    const isCompare = path.includes('compare')
    const isTemplate = path.includes('templates')

    return isHistory
      ? 'history'
      : isParameter
      ? 'parameters'
      : isCompare
      ? 'compare'
      : isTemplate
      ? 'templates'
      : path
  }

  const handleProjectRouting = (
    id: nullable<string>,
    path: string,
    additionalSearch: nullable<string>,
    noSearch?: boolean
  ): void => {
    if (id || projectId) {
      noSearch
        ? navigate(`/projects/${id || projectId}${path}`)
        : navigate(
            `/projects/${id || projectId}${path}` +
              (additionalSearch || '') +
              (path === '/parameters' || path.includes('templates') ? search : '')
          )
    } else {
      infoToast(`A project is required to use ${infoToastMessageHelper(path)}.`)
      navigate(`/organization${OrganizationPaths.Projects}`)
    }
  }

  const goToParameterPage = (id: string, pid?: string) =>
    navigate(`/projects/${pid ? pid : projectId}/parameters/${id}` + search)
  const goToCopiedParameterPage = (id: string) => navigate(`/projects/${id}/parameters`)
  const goToGroupPage = (id: string) => navigate(`/organization/access-control/groups/${id}`)
  const goToAwsPushActionPage = (integrationId: string, actionId: string) =>
    navigate(`/organization/actions${ActionViews.Push}/aws/${integrationId}/${actionId}`)
  const goToAkvPushActionPage = (integrationId: string, actionId: string) =>
    navigate(`/organization/actions${ActionViews.Push}/akv/${integrationId}/${actionId}`)
  const goToAwsPullActionPage = (integrationId: string, actionId: string) =>
    navigate(`/organization/actions${ActionViews.Import}/aws/${integrationId}/${actionId}`)
  const goToAkvPullActionPage = (integrationId: string, actionId: string) =>
    navigate(`/organization/actions${ActionViews.Import}/akv/${integrationId}/${actionId}`)
  const goToGithubPullActionPage = (integrationId: string, actionId: string) =>
    navigate(`/organization/actions${ActionViews.Import}/github/${integrationId}/${actionId}`)
  const goToTemplatePage = (id: string) => navigate(`/projects/${projectId}/templates/${id}`)
  const goToCompareRoute = (id: nullable<string>, noSearch?: boolean) =>
    handleProjectRouting(id, '/compare', null, noSearch)
  const goToHistoryRoute = (id: nullable<string>, view: HistoryViews, noSearch?: boolean) =>
    handleProjectRouting(id, `/history${view}`, null, noSearch)

  const goToParameterRoute = (id: nullable<string>, noSearch?: boolean) =>
    handleProjectRouting(id, '/parameters', null, noSearch)
  const goToTemplateRoute = (id: nullable<string>, search?: string) =>
    handleProjectRouting(id, '/templates', search || null)
  const goToActionsRoute = (path: ActionViews, hash?: ActionsHash) =>
    navigate(`/organization/actions${path}${hash ? hash : ActionsHash.AWS}`)
  const goToAwsRoute = () => navigate('/organization/integrations/aws')
  const goToAkvRoute = () => navigate('/organization/integrations/akv')
  const goToExpireRoute = () => navigate('/organization/expiration')
  const goToOrgRoute = (path: OrganizationPaths) => navigate(`/organization${path}`)
  const goToRootRoute = (path: RootPaths) => navigate(path)
  const goHome = () => navigate('/')
  const goToLoginRoute = () => navigate(OryPaths.Login)
  const goToRegistrationRoute = () => navigate(OryPaths.Registration)
  const goToMfaSetupRoute = () => navigate(OryPaths.MfaSetup)
  const goToVerificationRoute = () => navigate(OryPaths.Verification)
  const goToOrySettingsRoute = () => navigate(OryPaths.Settings)
  const goToRecoveryRoute = () => navigate(OryPaths.Recovery)
  const goToOryAdminRoute = () => navigate(OryPaths.OryAdmin)
  const goToOryAdminUserPage = (id: string) => navigate(`${OryPaths.OryAdmin}/users/${id}`)

  return {
    goToOryAdminRoute,
    goToOryAdminUserPage,
    goToCompareRoute,
    goToLoginRoute,
    goToOrySettingsRoute,
    goToVerificationRoute,
    goToRegistrationRoute,
    goToMfaSetupRoute,
    goToExpireRoute,
    goToRecoveryRoute,
    goToHistoryRoute,
    goToParameterRoute,
    goToParameterPage,
    goToTemplateRoute,
    goToTemplatePage,
    goToGroupPage,
    goToActionsRoute,
    goToRootRoute,
    goToOrgRoute,
    goToAwsRoute,
    goToAkvRoute,
    goHome,
    goToAwsPushActionPage,
    goToAwsPullActionPage,
    goToAkvPushActionPage,
    goToAkvPullActionPage,
    goToGithubPullActionPage,
    goToCopiedParameterPage,
  }
}

export enum QueryParamName {
  EnvId = 'envId',
  SortBy = 'sortBy',
  ValueSource = 'valueSource',
  Page = 'page',
  PageSize = 'pageSize',
  AccessToken = 'access_token',
  Template = 'template',
}

export enum OryQueryParamName {
  Flow = 'flow',
  ReturnTo = 'returnTo',
  Aal = 'aal',
  Refresh = 'refresh',
}

interface QueryParamReturnType {
  getQuery: (queryName: QueryParamName) => nullable<string>
  getOryQuery: (queryName: OryQueryParamName) => nullable<string>
  setQuery: (queryName: QueryParamName, value: string) => void
  removeQuery: (queryName: QueryParamName) => void
}

export const useQuery = (): QueryParamReturnType => {
  const query = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()

  const getQuery = (queryName: QueryParamName) => query.get(queryName)!
  const getOryQuery = (queryName: OryQueryParamName) => query.get(queryName)!
  const setQuery = (queryName: QueryParamName, value: string) => {
    query.set(queryName, value)
    navigate({ search: decodeURIComponent(query.toString()) })
  }
  const removeQuery = (queryName: QueryParamName) => {
    query.delete(queryName)
    navigate({ search: decodeURIComponent(query.toString()) })
  }

  return { getQuery, setQuery, removeQuery, getOryQuery }
}

interface ProjectParamsReturnType {
  projectId: string
  parameterId?: string
  templateId?: string
}

interface OrganzationParamsReturnType {
  actionId?: string
  integrationId?: string
  groupId?: string
}

interface RootParamsReturnType {
  identityId?: string
}

type RootParams = {
  identityId?: string
}

export const useProjectParams = (): ProjectParamsReturnType => {
  const { id, templateId, parameterId } = useParams<ProjectParams>()

  return { projectId: id!, templateId, parameterId }
}

export const useOrganizationParams = (): OrganzationParamsReturnType => {
  const { actionId, integrationId, groupId } = useParams<OrganizationParams>()

  return { actionId, integrationId, groupId }
}

export const useRootParams = (): RootParamsReturnType => {
  const { identityId } = useParams<RootParams>()

  return { identityId }
}

import { isUuid } from 'data/dataUtils'
import { makeTitleCase } from './valueHelpers'

interface Data {
  path?: string
}

export default function seo(data: Data): void {
  if (data.path) {
    const pathArray: string[] = data.path.split('/')

    // ensure the path has a fallback
    pathArray.unshift('Home')
    let ending = pathArray.pop()
    while (!ending || isUuid(ending)) {
      ending = pathArray.pop()
    }

    document.title = `CloudTruth | ${makeTitleCase(ending)}`
  }
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CreateInvitation,
  DeleteInvitation,
  GetInvitations,
  GetUser,
  GetUsers,
  UpdateInvitation,
  UpdateUser,
} from './actions'
import { EntityId, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Invitation, User } from 'gen/cloudTruthRestApi'
import { epoch, idFromUrl } from 'data/dataUtils'

import { CreateServiceAccount } from '../serviceAccount/actions'
import { DeleteMembership } from '../membership/actions'
import { EntityState } from '@reduxjs/toolkit/dist/entities'

interface UserState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  invitations: Invitation[]
}

export const userAdapter = createEntityAdapter<User>({
  selectId: (user: User) => user.id,
})

const initialState = userAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  invitations: [],
} as UserState)

export type UserRootState = EntityState<User> & UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // direct actions
    selectUser(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    removeUser(state, action: PayloadAction<EntityId>) {
      userAdapter.removeOne(state, action.payload)
    },

    addOneInvitation(state, action: PayloadAction<Invitation>) {
      state.invitations.push(action.payload)
    },

    setAllUser: userAdapter.setAll,
    addOneUser: userAdapter.addOne,
    updateOneUser: userAdapter.upsertOne,
    removeOneUser: userAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetUsers.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      userAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetUser.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      userAdapter.setOne(state, action.payload)
      if (!action.meta.arg.dontUpdateCurrent) {
        state.current = action.payload.id
      }
    })
    builder.addCase(UpdateUser.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())

      const newUser: User = {
        ...state.entities[action.meta.arg.id]!,
        chatgpt_threads: action.payload.chatgpt_threads,
      }

      userAdapter.upsertOne(state, newUser)
    })
    builder.addCase(GetInvitations.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      state.invitations = action.payload.results || []
    })
    builder.addCase(CreateInvitation.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      state.invitations.push(action.payload)
    })
    builder.addCase(UpdateInvitation.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const idx = state.invitations.findIndex((i) => i.id === action.payload.id)
      state.invitations.splice(idx, 1, action.payload)
    })
    builder.addCase(DeleteInvitation.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const idx = state.invitations.findIndex((i) => i.id === action.payload)
      state.invitations.splice(idx, 1)
    })
    builder.addCase(DeleteMembership.fulfilled, (state, action) => {
      const idx = state.invitations.findIndex(
        (i) => i.membership && idFromUrl(i.membership) === action.payload
      )
      if (idx !== -1) {
        state.cacheLastChanged = new Date(Date.now())
        state.invitations.splice(idx, 1)
      }
    })
    builder.addCase(CreateServiceAccount.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      userAdapter.addOne(state, action.payload.user)
    })
  },
})

export const {
  resetState,
  selectUser,
  removeUser,
  addOneUser,
  removeOneUser,
  setAllUser,
  updateOneUser,
  addOneInvitation,
} = userSlice.actions

export default userSlice.reducer

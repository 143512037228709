/* eslint no-unsafe-optional-chaining: 0 */

import { Button, Tooltip } from 'antd'
import { CreateParameter, GetParameterNames } from 'data/parameter/actions'
import { CreateTemplate, GetTemplateNames } from 'data/template/actions'
import { Parameter, Project, Template, Value } from 'gen/cloudTruthRestApi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createAssistantThread, createMessage, currentTemplateAssistant } from '../utils'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { extractCrossProjectBody, formatMessage } from './utils'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AssistantStream } from 'openai/lib/AssistantStream'
import { ConfirmModal } from './ConfirmModal'
import { CreateProject } from 'data/project/actions'
import { CustomThunk } from 'data/dataUtils'
import { FaWandMagicSparkles } from 'react-icons/fa6'
import OpenAI from 'openai'
import { OpenAIError } from 'openai/error'
import { getCurrentProject } from 'data/project/selectors'
import { getCurrentUser } from 'data/user/selectors'
import { saveLocalTemplateBody } from 'components/Templates/utils'
import styles from './TemplateFix.module.scss'
import { useToast } from 'hooks'

const openai_url = process.env.REACT_APP_OPENAI_URL

interface Props {
  parameters: Parameter[]
  templates: Template[]
  projects: Project[]
  body: maybe<string>
  error: string
  setBody: (body: maybe<string>) => void
  setOriginalBody: (body: maybe<string>) => void
  loading: boolean
  disabled: boolean
  setLoading: (loading: boolean) => void
  preview: (mask?: boolean, body?: string) => void
  template: Template
}

interface WithProject {
  name: string
  project: string
}

interface ConfirmOptions {
  parameters: WithProject[]
  templates: WithProject[]
  projects: string[]
}

export function TemplateFix(props: Props) {
  const {
    parameters,
    templates,
    projects,
    body,
    error,
    setBody,
    setOriginalBody,
    preview,
    loading,
    setLoading,
    disabled,
    template,
  } = props
  const [confirmOptions, setConfirmOptions] = useState<nullable<ConfirmOptions>>(null)
  const [stream, setStream] = useState<nullable<AssistantStream>>(null)
  const token = useAppSelector((state) => state.session.jwt)
  const currentUser = useAppSelector(getCurrentUser)
  const currentProject = useAppSelector(getCurrentProject)
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const openai = useMemo(() => {
    return new OpenAI({
      apiKey: 'abc123',
      dangerouslyAllowBrowser: true,
      baseURL: openai_url,
      defaultHeaders: {
        [`X-Authorization`]: `Api-Key ${token}`,
      },
    })
  }, [token])

  const onSubmit = useCallback(
    async (body: maybe<string>) => {
      setLoading(true)

      let thread = null
      try {
        thread = await createAssistantThread(
          openai,
          {
            user: currentUser!,
            dispatch,
          },
          true
        )
      } catch (error) {
        errorToast('Error communicating with template assistant')
        setLoading(false)
        return
      }

      const crossProjectsToFetch = extractCrossProjectBody(body || '')
      const crossTemplates: string[] = []
      const crossParameters: string[] = []
      const crossTemplateHash: Record<string, boolean> = {}
      const crossParameterHash: Record<string, boolean> = {}
      const finalFormatHash: Record<string, Record<'parameters' | 'templates', string[]>> = {}

      if (crossProjectsToFetch.length > 0) {
        crossProjectsToFetch.forEach((crossProject) => {
          if (
            crossProject?.project &&
            crossProject?.type === 'template' &&
            !crossTemplateHash.hasOwnProperty(crossProject.project) // eslint-disable-line no-prototype-builtins
          ) {
            crossTemplates.push(crossProject.project)
            crossTemplateHash[crossProject.project] = true
          } else if (
            crossProject?.project &&
            crossProject?.type === 'parameter' &&
            !crossParameterHash.hasOwnProperty(crossProject.project) // eslint-disable-line no-prototype-builtins
          ) {
            crossParameters.push(crossProject.project)
            crossParameterHash[crossProject.project] = true
          }
        })
      }

      const fetchTemplates = crossTemplates.map(async (crossProject) => {
        const project = projects.find((proj) => proj.name === crossProject)?.id
        if (project) {
          return dispatch(GetTemplateNames(project)).then(({ payload, error }: CustomThunk) => {
            if (error) {
              errorToast('Error fetching cross project templates')
              return
            }
            finalFormatHash[crossProject] = {
              parameters: [],
              templates: payload.results.map((temp: Template) => temp.name),
            }
          })
        }
      })

      const fetchParameters = crossParameters.map(async (crossProject) => {
        const project = projects.find((proj) => proj.name === crossProject)?.id
        if (project) {
          return dispatch(GetParameterNames(project)).then(({ payload, error }: CustomThunk) => {
            if (error) {
              errorToast('Error fetching cross project parameters')
              return
            }
            finalFormatHash[crossProject] = {
              parameters: payload.results.map((param: Parameter) => param.name),
              templates: finalFormatHash[crossProject]?.templates
                ? [...finalFormatHash[crossProject]?.templates]
                : [],
            }
          })
        }
      })

      await Promise.all([...fetchTemplates, ...fetchParameters])

      finalFormatHash[`${currentProject!.name}<current_project>`] = {
        parameters: parameters.map((param) => param.name),
        templates: templates.map((temp) => temp.name),
      }

      const message = formatMessage({
        error,
        body,
        projectHash: finalFormatHash,
        projects,
      })

      try {
        await createMessage(openai, thread.id, message)

        const stream = await openai.beta.threads.runs.stream(thread.id, {
          assistant_id: currentTemplateAssistant,
        })

        setStream(stream)
      } catch (error) {
        const err = error as any
        if (err.error.message) {
          errorToast('Error creating message' + ' ' + err.error.message)
        } else {
          errorToast('error creating message')
        }

        return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentTemplateAssistant,
      openai,
      currentUser?.chatgpt_threads,
      projects,
      parameters,
      templates,
    ]
  )

  const handleConfirm = useCallback(async () => {
    if (!confirmOptions) {
      return
    }
    const projs = projects

    try {
      // Create projects
      for (const project of confirmOptions.projects) {
        await dispatch(CreateProject({ name: project })).then(({ payload }: CustomThunk) => {
          projs.push(payload)
        })
      }

      // Create parameters
      for (const param of confirmOptions.parameters) {
        const project = projs.find((proj) => proj.name === param.project)?.id || currentProject?.id
        const values = () => {
          const obj: Record<string, nullable<Value>> = {}
          environments.forEach((env) => {
            obj[env.url] = null
          })
          return obj
        }

        await dispatch(CreateParameter({ name: param.name, values: values(), project }))
      }

      // Create templates
      for (const temp of confirmOptions.templates) {
        const project = projs.find((proj) => proj.name === temp.project)?.id
        await dispatch(
          CreateTemplate({
            name: temp.name,
            project,
            dontUpdateCache: !!temp.project,
            dontUpdateCurrent: temp.project === currentProject?.name || !temp.project,
          })
        )
      }

      // After all dispatches are done
    } catch (error) {
      errorToast('Error executing creation operations:' + JSON.stringify(error))
    }

    setConfirmOptions(null)
    await preview(false, body || '')
  }, [confirmOptions, currentProject, dispatch, environments, errorToast, preview, projects, body])

  useEffect(() => {
    if (stream) {
      stream
        .on('event', (event) => {
          if (event.event === 'thread.message.completed' && event.data.content[0].type === 'text') {
            const data = JSON.parse(event.data.content[0].text.value)
            const hasCreatedItems =
              data.parameters.length > 0 || data.templates.length > 0 || data.projects.length > 0

            if (data?.newBody) {
              setOriginalBody(body)
              setBody(data.newBody)
              saveLocalTemplateBody(template.name, data.newBody, currentProject!.id)
              if (!hasCreatedItems) {
                preview(undefined, data.newBody)
              }
            } else {
              setOriginalBody(null)
            }
            if (hasCreatedItems) {
              setConfirmOptions({
                parameters: data.parameters,
                templates: data.templates,
                projects: data.projects,
              })
            }
          }
        })

        .on('end', async () => {
          setLoading(false)
        })

        .on('error', (error) => {
          errorToast(error.message)
        })
        .on('error', (error: OpenAIError) => {
          errorToast(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream])

  return (
    <div>
      {confirmOptions && (
        <ConfirmModal
          visible={!!confirmOptions}
          cancel={() => setConfirmOptions(null)}
          parameters={confirmOptions.parameters.map((param) => param.name)}
          templates={confirmOptions.templates.map((temp) => temp.name)}
          projects={confirmOptions.projects}
          handleSubmit={handleConfirm}
        />
      )}
      <Tooltip title="AI Template Assistant" placement="top">
        <Button
          disabled={disabled}
          className={disabled ? styles.disabledButton : styles.button}
          icon={<FaWandMagicSparkles />}
          color="black"
          loading={loading}
          onClick={() => onSubmit(body)}
        />
      </Tooltip>
    </div>
  )
}

import { Checkbox, TableColumnsType } from 'antd'
import { ExpiredValue, ExpiringValue, ExpiringValuesResponse } from 'gen/cloudTruthRestApi'
import { QueryParamName, useHistoryPush, useQuery } from 'router/customHooks'
import React, { useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, TableSearch } from 'components/Table'
import { getLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ExpiresHourGlass } from 'components/ExpiresHourGlass'
import { GetExpired } from 'data/expire/actions'
import { PageTitle } from 'components/PageTitle'
import { TypedThunk } from 'data/dataUtils'
import { formatDateTime } from 'lib/dateHelpers'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './Expire.module.scss'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

export function Expire() {
  const [expireList, setExpireList] = useState<ExpiringValue[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [onlyExpired, setOnlyExpired] = useState(false)
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'expire' })
  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes
  const { goToParameterPage } = useHistoryPush()
  const { getQuery } = useQuery()
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())
  const [form] = useForm()

  const filteredExpirationList = useMemo(() => {
    if (!search && !onlyExpired) {
      return expireList
    }

    return expireList.filter((report) => {
      if (onlyExpired && search) {
        return report.expired && report.parameter.toLowerCase().includes(search.toLowerCase())
      } else if (search) {
        return report.parameter.toLowerCase().includes(search.toLowerCase())
      } else if (onlyExpired) {
        return report.expired
      }
      return true
    })
  }, [search, expireList, onlyExpired])

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  useEffect(() => {
    setLoading(true)
    dispatch(GetExpired(null)).then(({ payload, error }: TypedThunk<ExpiringValuesResponse>) => {
      if (error) {
        errorToast(error.message)
      } else if (payload) {
        setExpireList(payload.results)
      }
      setLoading(false)
    })
  }, [dispatch, errorToast])

  const [columns, setColumns] = useState<TableColumnsType<ExpiredValue>>([
    {
      title: 'PARAMETER NAME',
      key: 'parameter',
      ellipsis: true,
      dataIndex: 'parameter',
      sorter: (a, b) => {
        const firstName = a!.parameter.toUpperCase()
        const secondName = b!.parameter.toUpperCase()

        return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
      },
      defaultSortOrder: null,
      width: storedColumnSize(columnSizes, 'name', 300),
      render: (_, expireObj: ExpiredValue) => {
        const { parameter_id, project_id, parameter } = expireObj
        return (
          <p className={styles.name} onClick={() => goToParameterPage(parameter_id, project_id)}>
            {parameter}
          </p>
        )
      },
    },
    {
      title: 'ENVIRONMENT',
      key: 'environment',
      dataIndex: 'environment',
      sorter: (a, b) => {
        const firstName = a!.environment.toUpperCase()
        const secondName = b!.environment.toUpperCase()

        return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
      },
      ellipsis: true,
      width: storedColumnSize(columnSizes, 'environment', 300),
      render: (_, expireObj: ExpiredValue) => (
        <div className={styles.hourglassContainer}>
          <ExpiresHourGlass date={expireObj.expires_at} />
          <p className={styles.environment}>{expireObj.environment}</p>
        </div>
      ),
    },
    {
      title: 'PROJECT',
      key: 'project',
      ellipsis: true,
      dataIndex: 'project',
      width: storedColumnSize(columnSizes, 'project', 300),
      sorter: (a, b) => {
        const firstName = projectEntities[a!.project_id]!.name.toUpperCase()
        const secondName = projectEntities[b!.project_id]!.name.toUpperCase()

        return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
      },
      render: (_, expireObj: ExpiredValue) => (
        <p>
          {Object.prototype.hasOwnProperty.call(projectEntities, expireObj.project_id)
            ? projectEntities[expireObj.project_id]!.name
            : 'project has been removed'}
        </p>
      ),
    },
    {
      title: 'EXPIRATION DATE',
      key: 'expiration',
      width: storedColumnSize(columnSizes, 'expiration', 300),
      render: (_, expireObj: ExpiredValue) => <p>{formatDateTime(expireObj.expires_at)}</p>,
    },
  ])

  return (
    <div>
      <PageTitle
        title="Parameter Expirations"
        buttons={
          <div className={styles.buttonContainer}>
            <div className={styles.search}>
              <TableSearch
                defaultValue={search}
                updateSearchTerm={(searchTerm: string) => {
                  setSearch(searchTerm)
                }}
                form={form}
                placeHolder="Search By Parameter"
              />
            </div>

            <Checkbox
              checked={onlyExpired}
              onChange={(event) => setOnlyExpired(event.target.checked)}
              className={styles.checkbox}
            >
              Show Expired Only
            </Checkbox>
          </div>
        }
      />
      <Table
        loading={loading}
        columns={resizableColumns(columns, setColumns, currentOrganization.id, 'expire')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: filteredExpirationList.length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onChange: (_, pageSz) => {
            if (pageSize !== pageSz) {
              setPageSize(pageSz)
            }
          },
        }}
        tableLayout="fixed"
        dataSource={filteredExpirationList}
        rowKey={(expire: ExpiredValue) => expire.value_id}
        locale={{
          emptyText: (
            <div>
              <h4 className={styles.emptyHeader}>
                You don't have any expiration values stored in CloudTruth
              </h4>
            </div>
          ),
        }}
      />
    </div>
  )
}

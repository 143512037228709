import React, { PropsWithChildren, createContext, useEffect, useState } from 'react'

export interface ContextValue {
  viewportHeight: number
}

export const ViewportHeightContext = createContext<ContextValue>({
  viewportHeight: 0,
})

export const ViewHeightProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { children } = props
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  useEffect(() => {
    // Function to update the viewport height
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ViewportHeightContext.Provider value={{ viewportHeight }}>
      {children}
    </ViewportHeightContext.Provider>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

export interface Snippet {
  keyword: string
  description: string
  snippet: string
  cursor?: string
}

// match ace editor default behavior of four spaces in a tab
export const TAB = '    '

export const templateSnippets: Snippet[] = [
  {
    keyword: 'if',
    description: 'insert if block',
    snippet: `{% if condition -%}\n${TAB}\n{%- endif %}`,
    cursor: 'condition',
  },
  {
    keyword: 'ife',
    description: 'insert if/else block',
    snippet: `{% if condition -%}\n${TAB}\n{%- else -%}\n${TAB}\n{%- endif %}`,
    cursor: 'condition',
  },
  {
    keyword: 'for',
    description: 'insert for loop',
    snippet: `{% for x in iterator -%}\n${TAB}\n{% endfor %}`,
    cursor: 'iterator',
  },
  {
    keyword: 'capture',
    description: 'insert capture block',
    snippet: `{% capture variable -%}\n${TAB}\n{%- endcapture %}`,
    cursor: 'variable',
  },
  {
    keyword: 'comment',
    description: 'insert comment block',
    snippet: `{% comment -%}\n${TAB}note\n{% endcomment %}`,
    cursor: 'note',
  },
  {
    keyword: 'docker',
    description: 'all parameters in docker format',
    snippet: `{% for parameter in cloudtruth.parameters -%}\n${TAB}{{ parameter | first | upcase }}={{ parameter | last }}\n{% endfor %}\n`,
  },
  {
    keyword: 'dotenv',
    description: 'all parameters in dotenv format',
    snippet: `{% for parameter in cloudtruth.parameters -%}\n${TAB}{{ parameter | first | upcase }}="{{ parameter | last }}"\n{% endfor %}\n`,
  },
  {
    keyword: 'shell',
    description: 'all parameters in shell format',
    snippet: `{% for parameter in cloudtruth.parameters -%}\n${TAB}{{ parameter | first | upcase }}={{ parameter | last | escape }}\n{% endfor %}\n`,
  },
]

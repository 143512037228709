import { CustomThunk, TypedThunk, orgIdFromInviteUrl } from 'data/dataUtils'
import { GetInvitations, GetUser, GetUsers } from 'data/user/actions'
import { OryPaths, RootPaths } from 'router/Router'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import {
  getCurrentOrganization,
  organizationEntitySelectors,
  useSelectOrganizations,
} from 'data/organization/selectors'
import { isCypress, isStagingOrProduction } from 'contexts/FeatureGateContext'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Forbidden } from './Error/Forbidden'
import { GetAwsIntegrations } from 'data/integration/awsIntegration/actions'
import { GetAzureKeyVaultIntegrations } from 'data/integration/akvIntegration/actions'
import { GetEnvironments } from 'data/environment/actions'
import { GetGithubIntegrations } from 'data/integration/githubIntegration/actions'
import { GetMemberships } from 'data/membership/actions'
import { GetOrganizations } from 'data/organization/actions'
import { GetParameterTypes } from 'data/parameterType/actions'
import { GetProjects } from 'data/project/actions'
import { GetServiceAccounts } from 'data/serviceAccount/actions'
import { PageLoading } from './PageLoading'
import ServerError from './Error/ServerError'
import { SessionWarningModal } from './Modals/SessionWarningModal'
import { User } from 'gen/cloudTruthRestApi'
import { authService } from 'lib/authService'
import { ctJwtNamespace } from 'lib/jwtHelpers'
import { getCurrentUser } from 'data/user/selectors'
import { identifyLogRocketSession } from 'lib/logrocketHelpers'
import { localStorageHelpers } from 'lib/localStorageHelpers'
import { resetState as resetAkvPush } from 'data/actions/akvPush/reducer'
import { resetState as resetAwsPulls } from 'data/actions/awsImport/reducer'
import { resetState as resetAwsPush } from 'data/actions/awsPush/reducer'
import { resetState as resetGithubPulls } from 'data/actions/githubImport/reducer'
import { resetState as resetParameters } from 'data/parameter/reducer'
import { resetState as resetTemplates } from 'data/template/reducer'
import { selectOrganization } from 'data/organization/reducer'
import { selectProject } from 'data/project/reducer'
import { setJwt } from 'data/session/reducer'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistoryPush } from 'router/customHooks'
import { useLocation } from 'react-router-dom'

/*
  1) loading is true when the user first visits the app
  2) auth0Context logs in the user via login, sign up, or local cache
  3) userClaims are updated when auth0 fetches a JWT
  4) get the session (user, organization(s), and membership(s))
    a) active org NOT identified: loading: false, redirect to /organizations
    b) active org identified: auth0 updates JWT claims
  5) when a current user and active org exist:
    a) start promise chain to dispatch all models
    b) loading is false when all models are cached in redux
  -----------
  6) loading is false if a user switches orgs
    a) restart step 5
*/

const RESOURCES = [
  GetUsers,
  GetInvitations,
  GetMemberships,
  GetEnvironments,
  GetProjects,
  GetServiceAccounts,
  GetAwsIntegrations,
  GetAzureKeyVaultIntegrations,
  GetGithubIntegrations,
  GetParameterTypes,
]

const RESET_LIST = [
  resetAwsPulls,
  resetGithubPulls,
  resetParameters,
  resetAkvPush,
  resetAwsPush,
  resetTemplates,
]

export function Auth0AuthGate(props: PropsWithChildren<unknown>) {
  const { children } = props

  const userClaims = useAppSelector((state) => state.session.claims)
  const orgIds = organizationEntitySelectors.selectIds(useSelectOrganizations())
  const activeOrg = useAppSelector(getCurrentOrganization)
  const currentUser = useAppSelector(getCurrentUser)
  const activeOrgId = activeOrg?.id || null

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [resourcesCalled, setResourcesCalled] = useState<boolean>(false)
  const [sessionCalled, setSessionCalled] = useState<boolean>(false)
  const [error, setError] = useState<nullable<JSX.Element>>(null)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { goHome, goToRootRoute } = useHistoryPush()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const auth0Context = useAuth0()

  const logoutTimer = useRef<ReturnType<typeof setTimeout>>()
  const warnTimer = useRef<ReturnType<typeof setTimeout>>()

  const clearCache = useCallback(() => {
    dispatch(selectOrganization(null))
    dispatch(selectProject(null))
    localStorageHelpers.clearActiveIds()
  }, [dispatch])

  const loadResources = useCallback(() => {
    setResourcesCalled(true)
    // A session exists and we have new org information
    // The actions here will replace any existing entities in the redux store
    Promise.all(
      RESOURCES.map(
        (resource) =>
          new Promise((resolve) => {
            dispatch(resource(null)).then(() => resolve({}))
          })
      )
    )
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setError(<ServerError clear />)
      })

    // Reset state for remaining entities
    RESET_LIST.forEach((reset) => dispatch(reset()))
  }, [dispatch])

  const loadSession = useCallback(() => {
    setSessionCalled(true)
    // Clear sessionid cookie on new session
    authService.removeCookie('sessionid')

    // The user is authenticated, but we need user and org information
    if (userClaims) {
      dispatch(GetUser({ id: userClaims.sub })).then(({ payload, error }: TypedThunk<User>) => {
        if (
          !error &&
          isStagingOrProduction &&
          Date.now() - Date.parse(payload.created_at) < 10000 &&
          !isCypress
        ) {
          // redirect new staging and production sign-ups to corporate site to link email with HubSpot
          window.location.href = `https://cloudtruth.com/thank-you-message/?email=${payload.email}&returnTo=${window.location.href}`
        } else if (error) {
          setError(<ServerError clear />)
        } else if (pathname.includes('invitations')) {
          setLoading(false)
        } else {
          dispatch(GetOrganizations(null)).then(({ error, payload }: CustomThunk) => {
            if (error) {
              setError(<ServerError clear />)
            } else if (payload.count === 0) {
              goToRootRoute(RootPaths.Organization)
              setLoading(false)
            }
          })
        }
      })
    }
  }, [dispatch, goToRootRoute, pathname, userClaims])

  // LOCAL DEV CYPRESS TESTING ONLY:
  // Special handling of auth0 is required for cypress in local dev due to cross-site limitations
  if (!isStagingOrProduction && isCypress) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const accessToken = JSON.parse(localStorage.getItem('auth0Cypress')!)

      dispatch(setJwt(accessToken))
    }, [dispatch])
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // redirect if user is trying to go to Ory route
      const isOryRoute = Object.values(OryPaths).some((path) => pathname.includes(path))
      if (isOryRoute) {
        goHome()
      }
      // Authorization flow. Fetches user's JWT, or redirects to login or signup
      // Also called when current organization changes in order to update JWT claims
      if (!auth0Context.isLoading && !isLoggingOut) {
        if (pathname.match(/accept\/?$/)) {
          // invitation links are to /invitations/:id/accept
          // force the user to authenticate then redirect to /invitations/:id
          const orgId = orgIdFromInviteUrl(window.location.href)

          clearCache()
          authService.signup(auth0Context, pathname.replace(/\/accept\/?/, ''), orgId)
        } else if (pathname.match(/join\/?$/)) {
          // invitation links to existing users are to /invitations/:id/join
          // force the user to authenticate then redirect to /invitations/:id
          const orgId = orgIdFromInviteUrl(window.location.href)
          clearCache()
          authService.login(auth0Context, pathname.replace(/\/join\/?/, ''), orgId)
        } else if (pathname === RootPaths.Signup) {
          authService.signup(auth0Context, null)
        } else if (auth0Context.isAuthenticated) {
          setLoading(true)
          setResourcesCalled(false)
          authService.getJwt(auth0Context, activeOrgId, (jwt: JWT): void => dispatch(setJwt(jwt)))
        } else if (auth0Context.error) {
          // an error occurred in auth0. Clear local storage and pass in a login prop to allow the user to try again
          setError(
            <Forbidden
              clear
              login={() => authService.login(auth0Context)}
              message={`An error occurred while authenticating: ${auth0Context.error.message}`}
            />
          )
        } else {
          authService.login(auth0Context)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrgId, auth0Context.isAuthenticated, auth0Context.isLoading])
  }

  useEffect(() => {
    if (!resourcesCalled && userClaims && orgIds.includes(userClaims[`${ctJwtNamespace}orgid`])) {
      loadResources()
    } else if (!sessionCalled && !resourcesCalled && userClaims) {
      loadSession()
    } else if (!activeOrgId && orgIds.length > 1) {
      goToRootRoute(RootPaths.Switch)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrgId, orgIds, userClaims])

  useEffect(() => {
    if (currentUser) {
      identifyLogRocketSession(currentUser, activeOrg)
    }
  }, [activeOrg, currentUser])

  useEffect(() => {
    const subscription = authService.subscribeToLogout((localAuthOnly) => {
      // If the login state was completely managed by a cached JWT then we cannot send Auth0 a
      // logout request. In order to do so, the Auth0 library must be initialized, but its login
      // state is managed in memory based upon an API call to their servers. So, there's no way to
      // initialize it without showing the user a login screen, which we want to avoid if we have
      // cached credentials already. Normally, we rely on Auth0 to redirect back out of the app
      // once it has finished logging the user out. But, if we can't tell Auth0 to perform a logout,
      // we have to manage the redirect ourselves. However, we should *not* manually redirect if
      // we're telling Auth0 to log out because if we manage to hit this path before the browser
      // sends out its request, it won't be sent. Likewise, if the request is in progress, it may
      // get killed before reaching Auth0's servers. In that case, we need to hang around in the
      // app somewhere until the Auth0 redirect comes through.

      if (localAuthOnly) {
        goHome()
      } else {
        setIsLoggingOut(true)
      }
    })

    return function cleanup() {
      subscription.stop()
    }
  }, [auth0Context, goHome])

  useEffect(() => {
    // The auth0 access token expires in 24 hours
    // Give the user a five-minute warning to choose to keep working or sign out,
    // or be signed out automatically

    // Refreshing the page with a valid token will return a new token that expires in 24 hours

    if (userClaims?.exp) {
      const expiry = userClaims.exp - Math.round(Date.now() / 1000)

      if (expiry > 600) {
        warnTimer.current = setTimeout(() => {
          setShowWarningModal(true)
        }, (expiry - 360) * 1000)

        logoutTimer.current = setTimeout(() => {
          authService.logout(auth0Context)
        }, (expiry - 60) * 1000)

        return () => {
          clearTimeout(warnTimer.current!)
          clearTimeout(logoutTimer.current!)
        }
      } else {
        authService.logout(auth0Context)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClaims?.exp])

  return error ? (
    error
  ) : loading ? (
    <PageLoading />
  ) : (
    <>
      {children}
      <SessionWarningModal visible={showWarningModal} setVisible={setShowWarningModal} />
    </>
  )
}

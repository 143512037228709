//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CopyCreateEnvironment,
  CreateEnvironment,
  CreateTag,
  DeleteEnvironment,
  DeleteTag,
  GetEnvironment,
  GetEnvironments,
  GetTags,
  UpdateEnvironment,
  UpdateTag,
} from './actions'
import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Environment, Tag } from 'gen/cloudTruthRestApi'
import { envIdFromParams, epoch } from 'data/dataUtils'

interface EnvironmentState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
  tags: Record<string, Tag[]>
}

interface AddTag {
  tag: Tag
  environmentId: string
}

export const environmentAdapter = createEntityAdapter<Environment>({
  selectId: (env) => env.id,
})

const initialState = environmentAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
  tags: {},
} as EnvironmentState)

export type EnvironmentRootState = EntityState<Environment> & EnvironmentState

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    // direct actions
    selectEnvironment(state, action: PayloadAction<nullable<string>>) {
      if (action.payload) {
        state.current = action.payload
      } else {
        const environments = Object.values(state.entities)
        state.current = environments.find((e) => !e?.parent)?.id ?? null
      }
    },

    addTag(state, action: PayloadAction<AddTag>) {
      const { environmentId, tag } = action.payload
      state.tags[environmentId]
        ? (state.tags[environmentId] = [...state.tags[environmentId], tag])
        : (state.tags[environmentId] = [tag])
    },

    setAllEnvironment: environmentAdapter.setAll,
    addOneEnvironment: environmentAdapter.addOne,
    updateOneEnvironment: environmentAdapter.upsertOne,
    removeOneEnvironment: environmentAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateEnvironment.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      environmentAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetEnvironments.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const environments = action.payload.results!
      environmentAdapter.setAll(state, environments)

      // reset tags
      state.tags = {}

      // find current environment
      const baseEnv = environments.find((e) => !e?.parent)?.id ?? null
      const searchEnv = envIdFromParams()
      const environmentExists = searchEnv ? state.entities[searchEnv] : null
      state.current = environmentExists ? searchEnv : baseEnv
    })
    builder.addCase(GetEnvironment.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      environmentAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateEnvironment.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      environmentAdapter.upsertOne(state, action.payload)
    })

    builder.addCase(CopyCreateEnvironment.fulfilled, (state, action) => {
      const isRecursive = action.meta.arg.params.recursive
      if (!isRecursive) {
        state.cacheLastChanged = new Date(Date.now())
        environmentAdapter.addOne(state, action.payload)
      }
    })
    builder.addCase(DeleteEnvironment.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      environmentAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        const environments = Object.values(state.entities)
        state.current = environments.find((e) => !e?.parent)?.id ?? null
      }
    })
    builder.addCase(GetTags.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      Object.assign(state.tags, { [action.meta.arg]: action.payload.results })
    })
    builder.addCase(CreateTag.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      const tags = state.tags[action.meta.arg.envId]
      if (tags) {
        tags.push(action.payload)
      } else {
        state.tags[action.meta.arg.envId] = [action.payload]
      }
    })
    builder.addCase(UpdateTag.fulfilled, (state, action) => {
      const tags = state.tags[action.meta.arg.envId]
      if (tags) {
        const idx = tags.findIndex((tag) => tag.id === action.payload.id)
        if (idx !== -1) {
          tags.splice(idx, 1, action.payload)
          state.cacheLastChanged = new Date(Date.now())
        }
      }
    })
    builder.addCase(DeleteTag.fulfilled, (state, action) => {
      const tags = state.tags[action.meta.arg.envId]
      if (tags) {
        const idx = tags.findIndex((tag) => tag.id === action.payload)
        if (idx !== -1) {
          tags.splice(idx, 1)
          state.cacheLastChanged = new Date(Date.now())
        }
      }
    })
  },
})

export const {
  resetState,
  selectEnvironment,
  addOneEnvironment,
  updateOneEnvironment,
  removeOneEnvironment,
  setAllEnvironment,
  addTag,
} = environmentSlice.actions

export default environmentSlice.reducer

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { OrganizationRootState, organizationAdapter } from './reducer'

import { Organization } from 'gen/cloudTruthRestApi'
import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from '../hooks'

const selectOrganization = (state: RootState) => state.organization

export const getCurrentOrganization = createSelector(
  selectOrganization,
  (state): nullable<Organization> => {
    return state.current ? state.entities[state.current]! : null
  }
)

export const organizationEntitySelectors = organizationAdapter.getSelectors()
export const useSelectOrganizations = (): OrganizationRootState => useSelectState().organization

import { Tooltip, TooltipProps } from 'antd'

import React from 'react'
import { WarningIcon } from './Forms/WarningIcon'

interface Props {
  title: string | JSX.Element
  placement?: TooltipProps['placement']
  centerSpan?: boolean
}

export function WarningIconTooltip(props: Props) {
  const { title, placement, centerSpan } = props

  return (
    <Tooltip placement={placement} color="#434343" title={title}>
      <span
        style={{
          display: centerSpan ? 'flex' : undefined,
          alignItems: centerSpan ? 'center' : undefined,
        }}
      >
        <WarningIcon />
      </span>
    </Tooltip>
  )
}

import { ConfirmModal as Modal } from 'components/Modals/ConfirmModal'
import React from 'react'

interface Props {
  handleSubmit: () => void
  cancel: () => void
  templates: string[]
  projects: string[]
  parameters: string[]
  visible: boolean
}

export function ConfirmModal(props: Props) {
  const { handleSubmit, cancel, templates, projects, parameters, visible } = props

  const renderBody = () => (
    <div>
      {parameters.length > 0 && (
        <p>
          <strong>Parameters:</strong> {parameters.join(', ')}
        </p>
      )}
      <div>
        {templates.length > 0 && (
          <p>
            <strong>Templates:</strong> {templates.join(', ')}
          </p>
        )}

        {projects.length > 0 && (
          <p>
            <strong>Projects:</strong> {projects.join(', ')}
          </p>
        )}
      </div>
    </div>
  )

  return (
    <Modal
      onOk={handleSubmit}
      visible={visible}
      subject="Create the following items:"
      body={renderBody()}
      onCancel={cancel}
    />
  )
}

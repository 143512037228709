//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  Organization,
  OrganizationCreate,
  PaginatedOrganizationList,
  PatchedOrganization,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

export const GetOrganizations = createAsyncThunk<PaginatedOrganizationList, null, ThunkConfig>(
  'organization/getOrganizations',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'organizations', null, thunkApi)
)

export const GetOrganization = createAsyncThunk<Organization, string, ThunkConfig>(
  'organization/getOrganization',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `organizations/${id}`, null, thunkApi)
)

export const CreateOrganization = createAsyncThunk<Organization, OrganizationCreate, ThunkConfig>(
  'organization/createOrganization',
  async (org, thunkApi) => await handleFetch(Verbs.Post, 'organizations', org, thunkApi)
)

export const UpdateOrganization = createAsyncThunk<Organization, PatchedOrganization, ThunkConfig>(
  'organization/updateOrganization',
  async (org, thunkApi) => await handleFetch(Verbs.Put, `organizations/${org.id}`, org, thunkApi)
)

export const DeleteOrganization = createAsyncThunk<string, string, ThunkConfig>(
  'organization/deleteOrganization',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `organizations/${id}`, null, thunkApi)
)

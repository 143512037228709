//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */

import { Divider, Form, TableProps, Tooltip } from 'antd'
import {
  NameColumn,
  Table,
  TableSearch,
  ValueColumn,
  defaultOrder,
  emptyParametersMessage,
} from 'components/Table'
import { QueryParamName, useHistoryPush, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import {
  getParameterCount,
  parameterEntitySelectors,
  useSelectParameters,
} from 'data/parameter/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { CustomThunk } from 'data/dataUtils'
import { DescriptionColumn } from './DescriptionColumn'
import { GetPaginatedParameters } from 'data/parameter/actions'
import { HistoryViews } from 'components/HistoryTable'
import { InheritedFromColumn } from './InheritedFromColumn'
import { Parameter } from 'gen/cloudTruthRestApi'
import { ParameterRowDropdown } from './ParameterRowDropdown'
import { Reload } from 'components/Reload'
import { ShowEvaluateConfirmModal } from 'components/Modals/ShowEvaluateConfirmModal'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { ToggleText } from 'components/ToggleText'
import { UpdatedAtColumn } from './UpdatedAtColumn'
import { getCurrentEnvironment } from 'data/environment/selectors'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getCurrentProject } from 'data/project/selectors'
import { getPolicy } from 'data/membership/selectors'
import { getSubscribeToAsyncPulls } from 'data/session/selectors'
import styles from './ParameterTable.module.scss'
import { useToast } from 'hooks'

// cache to keep track of all individual evaluated toggle states
interface EvaluateToggleCache {
  currentProject: nullable<string>
  currentEnvironment: nullable<string>
  toggleStates: Record<string, boolean>
}

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

const { useForm } = Form

export function ParameterTable() {
  const project = useAppSelector(getCurrentProject)!
  const currentEnvironment = useAppSelector(getCurrentEnvironment)!
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const subscribeToAsyncPulls = useAppSelector(getSubscribeToAsyncPulls)
  const parameterCount = useAppSelector(getParameterCount)
  const { canContribute } = useAppSelector(getPolicy(null))
  const parameters = parameterEntitySelectors.selectAll(useSelectParameters())

  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'parameter' })
  const localPageSize = localSession?.pageSize
  const localCurrentOnly = localSession?.currentOnly
  const columnSizes = localSession?.columnSizes
  const disableEvaluatedModal = localSession?.disableEvaluatedModal || false

  const [loading, setLoading] = useState(true)
  const [maskSecrets, setMaskSecrets] = useState(true)
  const [evaluateConfirm, setEvaluateConfirm] = useState(false)
  const [showEvaluated, setShowEvaluated] = useState<nullable<boolean>>(false)
  const [evaluatedCount, setEvaluatedCount] = useState(0)

  const [fetchError, setFetchError] = useState(false)
  const [showInherited, setShowInherited] = useState(!!localCurrentOnly)
  const [resetColumnState, setResetColumnState] = useState<nullable<() => boolean>>(null)
  // cache to keep track of all individual evaluated toggle states
  const [_, setColumnEvaluatedCache] = useState<EvaluateToggleCache>({
    currentProject: project?.id,
    currentEnvironment: currentEnvironment.id,
    toggleStates: {},
  })

  const [search, setSearch] = useState('')
  const [form] = useForm()

  const { goToCompareRoute, goToHistoryRoute } = useHistoryPush()

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const { getQuery, setQuery, removeQuery } = useQuery()
  const sortBy = getQuery(QueryParamName.SortBy)

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return { pageNumber: pageNumber ? parseInt(pageNumber) : 1, project: project.id }
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }
    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const [ordering, setOrdering] = useState<option<string>>(() => {
    const query = getQuery(QueryParamName.SortBy)
    if (query?.includes('modified_at')) {
      return query.includes('ascend') ? 'modified_at' : '-modified_at'
    } else if (query?.includes('name')) {
      return query.includes('ascend') ? 'name' : '-name'
    } else if (query?.includes('inheritedFrom')) {
      return query.includes('ascend') ? 'inheritedFrom' : '-inheritedFrom'
    }
    return
  })

  // this holds an object with the id being the value id and the value being a loading state boolean
  // this allows us to show loading states for each individual value column
  const [valuesLoading, setValuesLoading] = useState<Record<string, boolean>>({})

  const showToggleSecrets = useMemo(() => {
    return parameters.some((parameter) =>
      Object.values(parameter.values).some((value) => value?.secret)
    )
  }, [parameters])

  const removePages = useCallback(() => {
    removeQuery(QueryParamName.Page)
    removeQuery(QueryParamName.PageSize)
  }, [removeQuery])

  const paginatedParams = useMemo(
    () => ({
      evaluate: !!showEvaluated,
      projectId: project.id,
      params: {
        // If the user switches projects, the page should return to 1
        page: page.project !== project.id ? 1 : page.pageNumber,
        page_size: pageSize,
        ordering,
        name__icontains: search,
        mask_secrets: maskSecrets,
        environment: currentEnvironment.id,
        immediate_parameters: !showInherited,
      },
    }),
    [
      page,
      pageSize,
      project.id,
      ordering,
      search,
      maskSecrets,
      currentEnvironment,
      showInherited,
      showEvaluated,
    ]
  )

  const hasEvaluatedValues = useMemo(() => {
    return parameters.some((parameter) => parameter.values[currentEnvironment.url]?.interpolated)
  }, [parameters, currentEnvironment])

  // this function resets the cache for each individual value column dropdown "raw" or "evaluated state"
  const resetEvaluatedCache = (project: string, environment: string) => {
    setColumnEvaluatedCache({
      currentProject: project,
      currentEnvironment: environment,
      toggleStates: {},
    })
  }

  // this function removes the cache for each individual value column dropdown "raw" or "evaluated state"
  const removeItemEvaluatedCache = (value: string) => {
    const AllToggled = (cache: EvaluateToggleCache) => {
      return parameters
        .map((parameter) => parameter.values[currentEnvironment.url])
        .filter((value) => !!value?.interpolated)
        .every((value) => {
          return cache.toggleStates[value!.id]
        })
    }

    new Promise((resolve) => {
      setColumnEvaluatedCache((prev) => {
        const newCache = prev
        delete newCache.toggleStates[value]

        if (AllToggled(newCache)) {
          setShowEvaluated(true)
        } else {
          setShowEvaluated(false)
        }
        return newCache
      })
      resolve({})
    })
  }

  const bulkEvaluate = () => {
    new Promise((resolve) => {
      // individual load states for evaluated values

      const loadingHash: Record<string, boolean> = {}
      const filteredValues = parameters
        .map((parameter) => parameter.values[currentEnvironment.url])
        .filter((value) => value && value.interpolated)

      filteredValues.forEach((value) => {
        loadingHash[value!.id] = true
      })

      setValuesLoading(loadingHash)

      resolve({})
    }).then(() => {
      setShowEvaluated((prev) => {
        setResetColumnState(() => () => !prev)
        return !prev
      })
    })
  }

  const handleEvalueConfirm = () => {
    if (disableEvaluatedModal || evaluatedCount > 0) {
      bulkEvaluate()
    } else {
      setEvaluateConfirm(true)
    }
  }

  const getParameters = useCallback(() => {
    // if (Object.values(valuesLoading).length < 1) {
    //   setLoading(true)
    // }
    setLoading(true)
    setFetchError(false)

    dispatch(GetPaginatedParameters({ ...paginatedParams, evaluate: !!showEvaluated })).then(
      ({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setFetchError(true)
        }
        setLoading(false)
        setValuesLoading({})

        // resets the evaluation cache when a user switches projects or environments or page
        resetEvaluatedCache(paginatedParams.projectId, paginatedParams.params.environment)
        setEvaluateConfirm(false)
      }
    )
  }, [dispatch, project.id, paginatedParams])

  // If the user switches projects, the page should return to 1
  useEffect(() => {
    if (page.project !== project.id) {
      setPage({ pageNumber: 1, project: project.id }), removePages()
    }
  }, [project, page])

  useEffect(() => {
    if (subscribeToAsyncPulls) {
      dispatch(GetPaginatedParameters(paginatedParams))
    }
  }, [subscribeToAsyncPulls])

  useEffect(() => {
    getParameters()
  }, [getParameters])

  const initialColumns: TableProps<Parameter>['columns'] = useMemo(
    () => [
      {
        title: 'NAME',
        key: 'name',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'name', 200),
        defaultSortOrder: defaultOrder(sortBy, 'name'),
        sorter: (a, b) => {
          const firstName = a!.name.toUpperCase()
          const secondName = b!.name.toUpperCase()

          return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
        },
        render: (_value, parameter: Parameter) => {
          return (
            <div className={styles.nameContainer}>
              <NameColumn
                parameter={parameter}
                inheritedFrom={
                  project.name === parameter.project_name ? undefined : parameter.project_name
                }
              />
            </div>
          )
        },
      },
      {
        title: 'ENVIRONMENT VALUE',
        key: 'value',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'value', 210),
        dataIndex: 'values',
        render: (_value, parameter: Parameter) => (
          <ValueColumn
            maskSecrets={maskSecrets}
            parameter={parameter}
            envUrl={currentEnvironment.url}
            inherited={parameter.project !== project.url}
            resetColumnState={resetColumnState}
            valuesLoading={valuesLoading}
            onRemoveCallback={(value: string) => removeItemEvaluatedCache(value)}
          />
        ),
      },

      {
        title: 'DESCRIPTION',
        key: 'description',
        dataIndex: 'description',
        width: storedColumnSize(columnSizes, 'description', 300),
        render: (_value, parameter: Parameter) => <DescriptionColumn parameter={parameter} />,
      },
      {
        title: 'INHERITED FROM',
        key: 'inheritedFrom',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'inheritedFrom', 200),
        defaultSortOrder: defaultOrder(sortBy, 'inheritedFrom'),
        sorter: (a, b) => {
          const firstName = a!.project_name.toUpperCase()
          const secondName = b!.project_name.toUpperCase()

          return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
        },
        render: (_value, parameter: Parameter) => <InheritedFromColumn parameter={parameter} />,
      },
      {
        title: 'LAST EDITED',
        key: 'modified_at',
        defaultSortOrder: defaultOrder('modified_at'),
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'modified_at', 150),
        align: 'center',
        dataIndex: 'modified_at',

        sorter: (a, b) => {
          if (!a.modified_at || !b.modified_at) {
            return -1
          }
          const aTime = Date.parse(a.modified_at)
          const bTime = Date.parse(b.modified_at)

          return aTime - bTime
        },
        render: (_value, parameter: Parameter) => <UpdatedAtColumn parameter={parameter} />,
      },
      {
        title: '',
        width: 65,
        render: (_, parameter) => {
          return canContribute ? (
            <ParameterRowDropdown
              paramId={parameter.id}
              maskSecrets={maskSecrets}
              disabled={parameter.project !== project.url}
              getParameters={getParameters}
            />
          ) : null
        },
      },
    ],
    [parameters, sortBy, defaultOrder, currentEnvironment, resetColumnState, valuesLoading]
  )

  const [columns, setColumns] = useState(initialColumns)

  useEffect(() => {
    setColumns(initialColumns)
  }, [initialColumns])

  const onTableChange: TableProps<Parameter>['onChange'] = (_, __, sorter) => {
    const { order, columnKey } = (sorter as any) || {
      order: null,
      columnKey: null,
    }

    if (order) {
      order === 'descend' ? setOrdering(`-${columnKey}`) : setOrdering(`${columnKey}`)
      setQuery(QueryParamName.SortBy, decodeURIComponent(`${columnKey}+${order}`))
    } else {
      setOrdering(undefined)
      removeQuery(QueryParamName.SortBy)
    }
  }

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'parameter',
      args: { currentOnly: showInherited, pageSize },
    })

  const setLocalCurrentOnly = (currentOnly: boolean) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'parameter',
      args: { currentOnly, pageSize },
    })

  const setDisableEvaluatedModal = (disableEvaluatedModal: boolean) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'parameter',
      args: { disableEvaluatedModal: disableEvaluatedModal },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setQuery(QueryParamName.Page, pageNumber.toString())
    setQuery(QueryParamName.PageSize, pageSize.toString())
    setPage((prev) => ({ ...prev, pageNumber }))
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  return (
    <>
      <ShowEvaluateConfirmModal
        visible={evaluateConfirm && !disableEvaluatedModal}
        onOk={() => {
          bulkEvaluate(), setEvaluatedCount((prev) => prev + 1)
        }}
        onCancel={() => setEvaluateConfirm(false)}
        setDisableEvaluatedModal={setDisableEvaluatedModal}
      />
      <div className={styles.actionContainer}>
        <div className={styles.pageTitleContainer}>
          <div data-testid="title" className={styles.title}>
            Parameters
          </div>
          <div className={styles.searchSelectors}>
            <TableSearch
              defaultValue={search}
              updateSearchTerm={(searchTerm: string) => {
                setSearch(searchTerm),
                  setPage((prev) => ({ ...prev, pageNumber: 1 })),
                  removePages()
              }}
              form={form}
            />
          </div>
          <div className={styles.reload}>
            <Reload
              onClick={() => {
                getParameters()
              }}
              loading={loading}
            />
          </div>

          {showToggleSecrets && (
            <ToggleSecrets
              showSecretValues={!maskSecrets}
              handleToggleClick={() => {
                new Promise((resolve) => {
                  // all of this logic is just so we can show loading states for indivual value columns
                  // & so that clicking "show secrets" doesn't load the whole table
                  if (maskSecrets) {
                    const loadingHash: Record<string, boolean> = {}
                    const filteredValues = parameters
                      .map((parameter) => parameter.values[currentEnvironment.url])
                      .filter((value) => value && value.secret && value.value === '*****')

                    filteredValues.forEach((value) => {
                      loadingHash[value!.id] = true
                    })

                    setValuesLoading(loadingHash)
                  } else {
                    const loadingHash: Record<string, boolean> = {}
                    const filteredValues = parameters
                      .map((parameter) => parameter.values[currentEnvironment.url])
                      .filter((value) => value && value.secret && value.value !== '*****')

                    filteredValues.forEach((value) => {
                      loadingHash[value!.id] = true
                    })

                    setValuesLoading(loadingHash)
                  }

                  resolve({})
                }).then(() => {
                  setMaskSecrets(!maskSecrets)
                  if (!showEvaluated) {
                    setResetColumnState(() => () => false)
                  }
                })
              }}
            />
          )}

          {project.depends_on && (
            <ToggleText
              showing={showInherited}
              handleToggleClick={() => {
                setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
                setShowInherited(!showInherited)
                setLocalCurrentOnly(!showInherited)
              }}
              text="Inherited"
              toolTip="Toggle inherited value display"
            />
          )}

          {hasEvaluatedValues && (
            <ToggleText
              showing={!!showEvaluated}
              text="Evaluated"
              handleToggleClick={handleEvalueConfirm}
              toolTip="Evaluate all interpolated values"
            />
          )}
        </div>

        <div className={styles.linkContainer}>
          <Tooltip
            title="Go to the value compare screen"
            placement="bottom"
            className={styles.tooltip}
          >
            <ActionButton
              size="large"
              customType="redirect"
              onClick={() => goToCompareRoute(project.id, true)}
            >
              Compare
            </ActionButton>
          </Tooltip>
          <Tooltip
            title="Go to the value change history screen"
            className={styles.tooltip}
            placement="bottomRight"
          >
            <ActionButton
              size="large"
              customType="redirect"
              onClick={() => goToHistoryRoute(project.id, HistoryViews.Parameter)}
            >
              History
            </ActionButton>
          </Tooltip>
        </div>
      </div>

      <Divider className={styles.divider} />

      <Table
        scroll={{ x: columns?.length > 6 ? 1300 : undefined }}
        onChange={onTableChange}
        columns={resizableColumns(columns, setColumns, currentOrganization.id, 'parameter')}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total:
            parameterCount && parameters.length > pageSize
              ? parameters.length - pageSize + parameterCount
              : parameterCount,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page.pageNumber,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : parameters}
        rowKey={(parameter: Parameter) => parameter.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>
                    {emptyParametersMessage(search, [], fetchError)}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </>
  )
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, Item, LabeledItem, TextArea } from 'components/Forms'
import { FormInstance, FormRule, Radio, Select } from 'antd'
import React, { useState } from 'react'
import { RoleEnum, ServiceAccount } from 'gen/cloudTruthRestApi'
import {
  serviceAccountEntitySelectors,
  useSelectServiceAccounts,
} from 'data/serviceAccount/selectors'

import { LabelText } from 'components/LabelText'
import { getInteractiveUsers } from 'data/user/selectors'
import { getPolicy } from 'data/membership/selectors'
import { idFromUrl } from 'data/dataUtils'
import styles from './ServiceAccountForm.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  form: FormInstance
  loading: boolean
  serviceAccount?: Partial<ServiceAccount>
  rhsHeader?: JSX.Element
  onCancel: () => void
  onFinish: (formData: FormData) => void
}

export const NAME = 'name'
export const ROLE = 'role'
export const DESCRIPTION = 'description'
export const OWNER = 'owner'

export function ServiceAccountForm(props: Props) {
  const { form, onFinish, serviceAccount, loading } = props
  const { canAdministrate, hasOwnership, canContribute } = useAppSelector(getPolicy(null))
  const serviceAccounts = serviceAccountEntitySelectors.selectAll(useSelectServiceAccounts())

  const users = useAppSelector(getInteractiveUsers)
  const [isNewToken, setIsNewToken] = useState(true)

  const interactiveOptions = users.map((user) => ({
    key: user.id,
    label: user.name,
    value: user.id,
  }))

  const selectOptions = [
    {
      label: 'Users',
      options: interactiveOptions,
    },
  ]

  const nameValidator = (rule: FormRule, value: string) => {
    if (
      serviceAccounts.find(
        (sa) => sa.user.name?.toLowerCase() === value.toLowerCase() && !isNewToken
      )
    ) {
      return Promise.reject('Access token by this name already exists')
    }
    setIsNewToken(false)
    return Promise.resolve()
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        [NAME]: serviceAccount?.user?.name,
        [ROLE]: serviceAccount?.user?.role || RoleEnum.VIEWER,
        [DESCRIPTION]: serviceAccount?.description,
        [OWNER]: serviceAccount?.owner ? idFromUrl(serviceAccount.owner) : undefined,
      }}
    >
      <InlineLabelInput
        autoFocus={!serviceAccount?.user?.name}
        disabled={!!serviceAccount?.user?.name || loading}
        name={NAME}
        label="Token Name"
        required="Please enter a name to identify the access token"
        placeholder="API Token Name."
        rules={[{ validator: nameValidator }]}
      />
      <div className={styles.descriptionContainer}>
        <TextArea
          autoFocus={!!serviceAccount?.user?.name}
          name={DESCRIPTION}
          label="DESCRIPTION"
          disabled={loading}
        />
      </div>
      <div className={styles.ownerContainer}>
        <LabeledItem label="owner" name="owner" required>
          <Select
            options={selectOptions}
            disabled={!canAdministrate || loading}
            onChange={(userId) => {
              form.setFieldsValue({ owner: userId })
            }}
            className={styles.select}
          />
        </LabeledItem>
      </div>
      <div className={styles.permissionsContainer}>
        <LabelText
          label="token permissions"
          uppercase
          text={
            <p className={styles.tokenPermission}>
              Define{' '}
              <a
                href="https://docs.cloudtruth.com/organization-management/user-directory#role-permissions"
                target="_blank"
                rel="noreferrer"
              >
                access permissions
              </a>{' '}
              for this token. These permissions will apply across your organization.
            </p>
          }
        />
        <div className={styles.radioContainer}>
          <Item className={styles.checkbox} name={ROLE}>
            <Radio.Group disabled={loading}>
              <Radio className={styles.radio} value={RoleEnum.NO_SECRETS}>
                NoSecretsViewer
              </Radio>
              <Radio className={styles.radio} value={RoleEnum.VIEWER}>
                Viewer
              </Radio>
              <Radio className={styles.radio} value={RoleEnum.CONTRIB} disabled={!canContribute}>
                Contributor
              </Radio>
              <Radio className={styles.radio} value={RoleEnum.ADMIN} disabled={!canAdministrate}>
                Admin
              </Radio>
              <Radio className={styles.radio} value={RoleEnum.OWNER} disabled={!hasOwnership}>
                Owner
              </Radio>
            </Radio.Group>
          </Item>
        </div>
      </div>
    </Form>
  )
}

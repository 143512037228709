import { PatchedProjectUpdate, Project } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { EditModal } from 'components/Modals/EditModal'
import { EditProjectForm } from './EditProjectForm'
import { FormInstance } from 'antd'
import { UpdateProject } from 'data/project/actions'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  project: Project
  closeModal: () => void
  visible: boolean
  form: FormInstance
}

export function EditProject(props: Props) {
  const { project, closeModal, visible, form } = props
  const { errorToast, successToast } = useToast()
  const [disabled, setDisabled] = useState(false)
  const dispatch = useAppDispatch()

  const onFinish = (formData: PatchedProjectUpdate) => {
    const { name, parameter_name_pattern, description } = formData

    setDisabled(true)
    dispatch(UpdateProject({ id: project.id, name, parameter_name_pattern, description })).then(
      ({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          return
        } else {
          closeModal()
          setDisabled(false)
          successToast('Project successfully updated')
        }
      }
    )
  }

  return (
    <EditModal
      pending={disabled}
      visible={visible}
      objectName="Project"
      afterClose={() => form.resetFields()}
      onCancel={closeModal}
      onOk={() => form.submit()}
    >
      <EditProjectForm form={form} onFinish={onFinish} project={project} disabled={disabled} />
    </EditModal>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  GithubRootState,
  githubIntegrationAdapter,
} from 'data/integration/githubIntegration/reducer'

import { RootState } from 'data/store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from 'data/hooks'

const selectGithubIntegration = (state: RootState) => state.githubIntegration

export const getCurrentGithubIntegration = createSelector(selectGithubIntegration, (state) => {
  return state.current ? state.entities[state.current] : null
})

export const githubIntegrationEntitySelectors = githubIntegrationAdapter.getSelectors()
export const useSelectGithubIntegrations = (): GithubRootState => useSelectState().githubIntegration

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  AwsIntegration,
  IntegrationNode,
  NodeTypeEnum,
  Organization,
  Parameter,
  User,
} from 'gen/cloudTruthRestApi'
import {
  CreateAwsIntegration,
  GetAwsIntegration,
  GetAwsIntegrations,
  UpdateAwsIntegration,
} from 'data/integration/awsIntegration/actions'
import {
  CreateParameter,
  CreateValue,
  GetPaginatedParameters,
  GetPaginatedVersionedParameters,
  GetParameter,
  GetParameters,
  GetValue,
  GetValues,
  GetVersionedParameters,
  GetVersionedValue,
  UpdateParameter,
  UpdateValue,
} from 'data/parameter/actions'
import { GetEvaluatedTemplate, PreviewTemplate } from 'data/template/actions'
import { ParameterRootState, updateValue } from 'data/parameter/reducer'
import { SessionState, setJwt } from 'data/session/reducer'

import { AwsIntegrationRootState } from 'data/integration/awsIntegration/reducer'
import { CreateServiceAccount } from 'data/serviceAccount/actions'
import { GeneratePassword } from 'data/utility/actions'
import { GetIntegrationExplorer } from 'data/integration/actions'
import LogRocket from 'logrocket'
import { RootState } from 'data/store'
import hash_sum from 'hash-sum'
import { isCypress } from 'contexts/FeatureGateContext'

export interface LrAction {
  payload?: any
  meta?: any
  type: string
}

let logRocketInfoHash: maybe<string> = null
let logRocketInitted: boolean = false

const logRocketAccount = '2pkry3'
const logRocketProjects: Map<string, string> = new Map([
  // allows local stack (docker-compose) to use another tenant for dev/test
  // ['local', 'dev-jim'],
  ['staging', 'staging-conserv'],
  ['production', 'production-conserv'],
])
const logRocketProjectUnknown = 'unknown'
const logRocketProject =
  logRocketProjects.get(process.env.REACT_APP_ATMOS_ENV!) || logRocketProjectUnknown
const logRocketSlug = logRocketAccount + '/' + logRocketProject

export function initializeLogRocket(): void {
  if (logRocketProject != logRocketProjectUnknown && !isCypress) {
    if (!logRocketInitted) {
      logRocketInitted = true
      LogRocket.init(logRocketSlug, {
        network: {
          requestSanitizer: (request) => {
            const sanitizedRequest = { ...request }
            if (request.headers['Authorization']) {
              sanitizedRequest.headers = {
                ...request.headers,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: 'redacted',
              }
            }
            if (request.url.indexOf('values') !== -1) {
              sanitizedRequest.body = 'body redacted'
            }
            if (request.url.indexOf('oauth') !== -1) {
              sanitizedRequest.body = 'body redacted'
            }
            return sanitizedRequest
          },
          responseSanitizer: (response) => {
            const sanitizedResponse = { ...response }
            sanitizedResponse.body = 'body redacted'
            return sanitizedResponse
          },
          isEnabled: true,
        },
      })
    }
  }
}

export function identifyLogRocketSession(
  currentUser: User,
  activeOrganization: maybe<Organization>
): void {
  if (logRocketProject != logRocketProjectUnknown) {
    const info = {
      // standard fields
      name: currentUser!.name!,
      email: currentUser!.email!,
      // custom fields
      organizationId: activeOrganization?.id || '',
      organizationName: activeOrganization?.name || '',
      subscriptionPlanId: activeOrganization?.subscription_plan_id || '',
    }

    const infoHash = hash_sum(info)
    if (logRocketInfoHash != infoHash) {
      logRocketInfoHash = infoHash
      LogRocket.identify(currentUser!.id, info)
    }
  }
}

const sanitizeParameterState = (paramState: ParameterRootState): ParameterRootState => {
  const sanitizedParamState: ParameterRootState = {
    ...paramState,
    entities: { ...paramState.entities },
  }
  Object.entries(paramState.entities).forEach(([key, parameter]) => {
    if (parameter?.secret) {
      // replace value object with 'redacted' instead of {} to not be confused with an empty object
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sanitizedParamState.entities[key] = { ...paramState.entities[key]!, values: 'redacted' }
    }
  })
  return sanitizedParamState
}

const sanitizeSessionState = (sessionState: SessionState): SessionState => {
  return {
    ...sessionState,
    jwt: 'redacted',
  }
}

const sanitizedAwsIntegrationState = (
  awsIntegrationState: AwsIntegrationRootState
): AwsIntegrationRootState => {
  const sanitizedAwsIntegrationState: AwsIntegrationRootState = {
    ...awsIntegrationState,
    entities: { ...awsIntegrationState.entities },
  }
  Object.entries(awsIntegrationState.entities).forEach(([key, _integration]) => {
    sanitizedAwsIntegrationState.entities[key] = {
      ...awsIntegrationState.entities[key]!,
      aws_external_id: 'redacted',
      aws_account_id: 'redacted',
      aws_kms_key_id: 'redacted',
    }
  })
  return sanitizedAwsIntegrationState
}

const actionSanitizer = (action: LrAction): LrAction => {
  let newPayload = action.payload
  let newMeta = action.meta
  const internal_value = 'redacted',
    static_value = 'redacted',
    value = 'redacted',
    values = 'redacted'

  switch (action.type) {
    /* --------------- Integrations --------------- */
    case GetIntegrationExplorer.fulfilled.type:
      newPayload = {
        ...action.payload,
        results: action.payload.results.map((result: IntegrationNode) => {
          if (result.node_type === NodeTypeEnum.File) {
            return {
              ...result,
              content_data: 'redacted',
              content_keys: [],
            }
          } else {
            return result
          }
        }),
      }
      break

    case GetAwsIntegrations.fulfilled.type:
      newPayload = {
        ...action.payload,
        results: action.payload.results.map((result: AwsIntegration) => {
          return {
            ...result,
            aws_external_id: 'redacted',
            aws_account_id: 'redacted',
            aws_kms_key_id: 'redacted',
          }
        }),
      }
      break

    case GetAwsIntegration.fulfilled.type:
      newPayload = {
        ...action.payload,
        aws_external_id: 'redacted',
        aws_account_id: 'redacted',
        aws_kms_key_id: 'redacted',
      }
      break

    case CreateAwsIntegration.fulfilled.type:
    case UpdateAwsIntegration.fulfilled.type:
      newPayload = {
        ...action.payload,
        aws_external_id: 'redacted',
        aws_account_id: 'redacted',
        aws_kms_key_id: 'redacted',
      }
      newMeta = {
        ...action.meta,
        arg: {
          ...action.meta.arg,
          aws_external_id: 'redacted',
          aws_account_id: 'redacted',
          aws_kms_key_id: 'redacted',
        },
      }
      break

    case CreateAwsIntegration.pending.type:
    case CreateAwsIntegration.rejected.type:
    case UpdateAwsIntegration.pending.type:
    case UpdateAwsIntegration.rejected.type:
      newMeta = {
        ...action.meta,
        arg: {
          ...action.meta.arg,
          aws_external_id: 'redacted',
          aws_account_id: 'redacted',
          aws_kms_key_id: 'redacted',
        },
      }
      break

    /* --------------- Parameters --------------- */
    case GetParameters.fulfilled.type:
    case GetPaginatedParameters.fulfilled.type:
    case GetVersionedParameters.fulfilled.type:
    case GetPaginatedVersionedParameters.fulfilled.type:
      newPayload = {
        ...action.payload,
        results: action.payload.results.map((result: Parameter): Parameter | any => {
          if (result.secret) {
            return { ...result, values }
          } else {
            return result
          }
        }),
      }
      break

    case GetParameter.fulfilled.type:
    case CreateParameter.fulfilled.type:
    case UpdateParameter.fulfilled.type:
      if (action.payload.secret) {
        newPayload = { ...action.payload, values }
      }
      break

    case GetValue.fulfilled.type:
    case GetValues.fulfilled.type:
    case GetVersionedValue.fulfilled.type:
      if (action.payload.secret) {
        newPayload = { ...action.payload, value, internal_value, static_value }
      }
      break

    case CreateValue.pending.type:
    case CreateValue.rejected.type:
    case UpdateValue.pending.type:
    case UpdateValue.rejected.type:
      newMeta = {
        ...action.meta,
        arg: { ...action.meta.arg, value, internal_value, static_value },
      }
      break

    case CreateValue.fulfilled.type:
    case UpdateValue.fulfilled.type:
      if (action.payload.secret) {
        newPayload = { ...action.payload, value, internal_value, static_value }
        newMeta = {
          ...action.meta,
          arg: { ...action.meta.arg, value, internal_value, static_value },
        }
      }
      break

    case updateValue.type:
      // direct action from parameter reducer
      if (action.payload.value?.secret) {
        newPayload = {
          ...action.payload,
          value: { ...action.payload.value, internal_value, static_value, value },
        }
      }
      break

    /* --------------- Service Accounts --------------- */
    case CreateServiceAccount.fulfilled.type:
      newPayload = { ...action.payload, apikey: 'redacted' }
      break

    /* --------------- Session --------------- */
    case setJwt.type:
      newPayload = 'redacted'
      break

    /* --------------- Templates --------------- */
    case PreviewTemplate.fulfilled.type:
    case GetEvaluatedTemplate.fulfilled.type:
      newPayload = { ...action.payload, body: 'redacted' }
      break

    /* --------------- Utilities --------------- */
    case GeneratePassword.fulfilled.type:
      newPayload = { value }
      break

    default:
      newPayload = action.payload
      newMeta = action.meta
  }

  return {
    ...action,
    meta: newMeta,
    payload: newPayload,
  }
}

export const lrMiddleware = LogRocket.reduxMiddleware({
  // LogRocket doesn't export their types so these have to be inline
  stateSanitizer: (state): RootState => {
    const sanitizedState = { ...state } as RootState
    sanitizedState.parameter = sanitizeParameterState(sanitizedState.parameter)
    sanitizedState.session = sanitizeSessionState(sanitizedState.session)
    sanitizedState.awsIntegration = sanitizedAwsIntegrationState(sanitizedState.awsIntegration)
    return sanitizedState
  },
  actionSanitizer: actionSanitizer,
})

export const exportsForTesting = {
  actionSanitizer,
  sanitizeSessionState,
  sanitizeParameterState,
  sanitizedAwsIntegrationState,
}

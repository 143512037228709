import React, { PropsWithChildren } from 'react'

import { Auth0AuthGate } from './Auth0AuthGate'
import { Auth2AuthGate } from './Auth2AuthGate'

export function AuthGate(props: PropsWithChildren<any>) {
  const { children } = props

  // eslint-disable-next-line no-extra-boolean-cast
  return Boolean(process.env.REACT_APP_AUTH_2) ? (
    <Auth2AuthGate>{children}</Auth2AuthGate>
  ) : (
    <Auth0AuthGate>{children}</Auth0AuthGate>
  )
}

import { AwsPull, AwsPush, AwsServiceEnum, DiscoveryResult } from 'gen/cloudTruthRestApi'
import {
  idFromUrl,
  integrationIdFromAkvPullUrl,
  integrationIdFromAkvPushUrl,
  integrationIdFromAwsPullUrl,
  integrationIdFromAwsPushUrl,
  integrationIdFromGithubPullUrl,
} from 'data/dataUtils'

import { ActionEnum } from 'data/actions/utils'
import { FormRule } from 'antd'

/* eslint no-useless-escape: 0 */

interface PushIds {
  integrationId: string
  actionId: string
}

interface PullIds {
  integrationId: string
  importId: string
}

export const formatAwsPushService = (service: AwsServiceEnum): option<string> => {
  if (service === AwsServiceEnum.Secretsmanager) {
    return 'Secrets Manager'
  } else if (service === AwsServiceEnum.Ssm) {
    return 'SSM'
  } else if (service === AwsServiceEnum.S3) {
    return 'S3'
  }
}

export const pushIds = (url: AwsPush['url'], actionType: ActionEnum): PushIds => {
  const actionId = idFromUrl(url)

  switch (actionType) {
    case ActionEnum.AwsPush:
      return {
        actionId,
        integrationId: integrationIdFromAwsPushUrl(url),
      }

    case ActionEnum.AzureKeyVaultPush:
      return {
        actionId,
        integrationId: integrationIdFromAkvPushUrl(url),
      }

    default:
      return { actionId: '', integrationId: '' }
  }
}

export const pullIds = (url: AwsPush['url'], actionType: ActionEnum): PushIds => {
  const actionId = idFromUrl(url)

  switch (actionType) {
    case ActionEnum.AwsPull:
      return {
        actionId,
        integrationId: integrationIdFromAwsPullUrl(url),
      }

    case ActionEnum.AzureKeyVaultPull:
      return {
        actionId,
        integrationId: integrationIdFromAkvPullUrl(url),
      }

    case ActionEnum.GithubPull:
      return {
        actionId,
        integrationId: integrationIdFromGithubPullUrl(url),
      }

    default:
      return { actionId: '', integrationId: '' }
  }
}

export const awsImportIds = (url: AwsPull['url']): PullIds => {
  const integrationId = integrationIdFromAwsPullUrl(url)
  const importId = idFromUrl(url)

  return {
    integrationId,
    importId,
  }
}
export const githubImportIds = (url: AwsPull['url']): PullIds => {
  const integrationId = integrationIdFromGithubPullUrl(url)
  const importId = idFromUrl(url)

  return {
    integrationId,
    importId,
  }
}

export const displayStatus = (status: maybe<string>): string => {
  return status ? status : 'N/A'
}

export const resourceNames = ['environment', 'project', 'parameter']
export const pushResourceNames = [...resourceNames, 'push', 'tag']
export const initialResourceString = `/${resourceNames.map((name) => `{{ ${name} }}`).join('/')}`
// prettier-ignore
export const initialAwsRegexString =
  `^\\/(?P<environment>[^/]+)\\/(?P<project>[^/]+)\\/(?P<parameter>.+)$`

export const initialAkvRegexString = `^(?P<environment>[^-]+)-(?P<project>[^-]+)-(?P<parameter>.+)$`

export const initialMustacheResourceString = (actionType: ActionEnum): string => {
  switch (actionType) {
    case ActionEnum.AwsPush:
      return initialResourceString

    case ActionEnum.AwsPull:
      return initialResourceString

    case ActionEnum.AzureKeyVaultPush:
      return `${resourceNames.map((name) => `{{ ${name} }}`).join('-')}`

    case ActionEnum.AzureKeyVaultPull:
      return `${resourceNames.map((name) => `{{ ${name} }}`).join('-')}`

    default:
      return initialResourceString
  }
}

export const initialRegexResourceString = (actionType: ActionEnum): string => {
  switch (actionType) {
    case ActionEnum.AwsPush:
      return initialAwsRegexString

    case ActionEnum.AwsPull:
      return initialAwsRegexString

    case ActionEnum.AzureKeyVaultPush:
      return initialAkvRegexString

    case ActionEnum.AzureKeyVaultPull:
      return initialAkvRegexString

    default:
      return initialAwsRegexString
  }
}

export const scanString = (
  searchVenue: string,
  sortVenue: 'asc' | 'desc',
  scan?: DiscoveryResult
): string => {
  let matched
  let skip

  if (scan?.matched) {
    const matches = Object.values(scan.matched)
      .flat()
      .filter((scan) => {
        if (searchVenue.length > 0) {
          return scan.venue_name.includes(searchVenue)
        }
        return true
      })
      .sort((first, second) => {
        if (sortVenue === 'asc') {
          return first.venue_name.localeCompare(second.venue_name)
        } else {
          return second.venue_name.localeCompare(first.venue_name)
        }
      })

    matched =
      matches.length === 0
        ? `Matched\nNo Results`
        : `Matched\n${matches
            .map(
              (match) =>
                `${match.venue_name}, Environment: ${match.environment_name}, Project: ${match.project_name}, Parameter: ${match.parameter_name}`
            )
            .join('\n')}`
  } else {
    matched = `Matched\nNo Results`
  }

  if (scan?.skipped) {
    const skipped = Object.entries(scan.skipped)
      .map(([skip]) => `${skip.split('r=')[1]}`)
      .filter((skip) => {
        if (searchVenue.length > 0) {
          return skip.includes(searchVenue)
        }
        return true
      })
      .sort((first, second) => {
        if (sortVenue === 'asc') {
          return first.localeCompare(second)
        } else {
          return second.localeCompare(first)
        }
      })

    skip = skipped.length === 0 ? `Skipped\nNo Results` : `Skipped\n${skipped.join('\n')}`
  } else {
    skip = `Skipped\nNo Results`
  }

  return `${matched}\n\n${skip}`
}

const sharedResourceValidation = (
  allowed: string[],
  required: string[],
  value: string
): nullable<string> => {
  const missing: string[] = []

  required.forEach((name) => {
    const findVariable = new RegExp(`{{\\s?${name}\\s?}}`)
    if (!value.match(findVariable)) {
      missing.push(name)
    }
  })

  if (missing.length > 0) {
    const plural = missing.length > 1
    return `The following required segment${plural ? 's are' : ' is'} missing: ${missing
      .map((name) => `{{ ${name} }}`)
      .join(', ')}`
  }

  if (value.includes('}}{{')) {
    return 'Resource segments must be separated.'
  }

  const forbidden = new RegExp(/^(?:aws|ssm)/, 'i')
  if (value.match(forbidden)) {
    return 'Resource cannot begin with "aws" or "ssm".'
  }

  const removeAllowed = new RegExp(`{{\\s?(?:${allowed.join('|')})\\s?}}`, 'g')
  const unmustachioedValue = value.replace(removeAllowed, '')

  if (unmustachioedValue.match(/[{}]/)) {
    return 'Invalid mustache syntax. Only the variables described below are allowed.'
  }

  if (!unmustachioedValue.match(/^[\w-./]*$/)) {
    return 'Resource pattern can only contain alphanumerics, underscores, hyphens, slashes, and periods.'
  }

  return null
}

export const validateImportResourceString = (_rule: FormRule, value: string): Promise<void> => {
  const result = sharedResourceValidation(resourceNames, resourceNames, value)
  return result ? Promise.reject(result) : Promise.resolve()
}

export const validatePushResourceString = (
  tags: string[],
  projects: string[],
  _rule: FormRule,
  value: string
): Promise<void> => {
  const required = ['parameter']

  if (tags.length > 1) {
    required.push('environment')
  }

  if (projects.length > 1) {
    required.push('project')
  }

  const result = sharedResourceValidation(pushResourceNames, required, value)

  return result ? Promise.reject(result) : Promise.resolve()
}

export const exportsForTesting = {
  sharedResourceValidation,
}

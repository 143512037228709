import React, { useEffect } from 'react'

import Editor from 'components/Editor/Editor'
import { Template } from 'gen/cloudTruthRestApi'
import { getCurrentProject } from 'data/project/selectors'
import { saveLocalTemplateBody } from '../utils'
import styles from './TemplateBodyForm.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  updateShowPreview: (bool: boolean) => void
  currentBody: maybe<string>
  setCurrentBody: (body: maybe<string>) => void
  setOriginalBody?: (body: maybe<string>) => void
  wrapping: boolean
  template: Template
}

export function TemplateBodyForm(props: Props) {
  const { updateShowPreview, currentBody, setCurrentBody, wrapping, template, setOriginalBody } =
    props

  const { name } = template
  const projectId = useAppSelector(getCurrentProject)!.id

  const onEditorChange = (newBody: string): void => {
    saveLocalTemplateBody(name, newBody, projectId)
    updateShowPreview(true)
    setCurrentBody(newBody)
  }

  useEffect(() => {
    // reset current body on unmount
    return () => {
      setCurrentBody(null)
      if (setOriginalBody) {
        setOriginalBody(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run on unmount
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.textArea}>
        <Editor
          body={currentBody}
          readOnly={false}
          wrapText={wrapping}
          onChange={onEditorChange}
          exclude={{ templates: [name] }}
        />
      </div>
    </div>
  )
}

import { IntegrationNode } from 'gen/cloudTruthRestApi'
import { LabelText } from 'components/LabelText'
import React from 'react'
import styles from './FileSelector.module.scss'

interface Props {
  integrationNode: nullable<IntegrationNode>
}

export function FileSelector(props: Props) {
  const { integrationNode } = props

  return (
    <div className={styles.fileSelectorContainer}>
      <LabelText label="reference location" text={integrationNode?.fqn} uppercase />
    </div>
  )
}

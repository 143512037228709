import React, { useState } from 'react'

import { EditServiceAccount } from './EditServiceAccount'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { ReKeyModal } from './ReKeyModal'
import { RemoveServiceAccount } from './RemoveServiceAccount'
import { ServiceAccount } from 'gen/cloudTruthRestApi'

enum MenuItemKeys {
  ManageApiToken = 'manageAccessTokens',
  DeleteApiToken = 'deleteToken',
  RegenerateToken = 'regenerateToken',
}

interface Props {
  sa: ServiceAccount
  regenerateLoading: boolean
  onRegenerate: (expires_at?: nullable<string>) => void
}

export function DropdownMenu(props: Props) {
  const { sa, onRegenerate, regenerateLoading } = props
  const [editVisible, setEditVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [regenerateVisible, setRegenerateVisible] = useState(false)

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteApiToken: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.ManageApiToken: {
        setEditVisible(true)
        break
      }

      case MenuItemKeys.RegenerateToken: {
        setRegenerateVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    { key: MenuItemKeys.ManageApiToken, label: 'Manage API Token' },
    { key: MenuItemKeys.RegenerateToken, label: 'Regenerate API Token', danger: true },
    { key: MenuItemKeys.DeleteApiToken, label: 'Delete API Token', danger: true },
  ]

  return (
    <>
      <ReKeyModal
        visible={regenerateVisible}
        onCancel={() => setRegenerateVisible(false)}
        loading={regenerateLoading}
        handleSubmit={onRegenerate}
      />
      <EditServiceAccount
        serviceAccount={sa}
        visible={editVisible}
        closeForm={() => setEditVisible(false)}
      />
      <RemoveServiceAccount
        onClose={() => setDeleteVisible(false)}
        visible={deleteVisible}
        serviceAccount={sa}
      />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </>
  )
}

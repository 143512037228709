//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { Form, FormData, InlineLabelInput, LabeledInputItem, TextArea } from 'components/Forms'
import React, { useState } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { CopyProjectPreview } from './CopyProjectPreview'
import { FormInstance } from 'antd'
import { Project } from 'gen/cloudTruthRestApi'
import { ProjectSelect } from './ProjectSelect'

interface Props {
  form: FormInstance
  steps: number
  handleSubmit: (formData: FormData) => void
  project: Project
}

export function CopyProjectForm(props: Props) {
  const { form, handleSubmit, project, steps } = props
  const projects = projectEntitySelectors.selectAll(useSelectProjects())

  const nameValidator = (_: any, value: string) => {
    if (value && projects.find((project) => project.name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('Project with this name already exists')
    }

    return Promise.resolve()
  }

  const [projectName, setProjectName] = useState('')

  return (
    <Form form={form} onFinish={handleSubmit}>
      {steps === 0 && (
        <>
          <InlineLabelInput
            autoFocus
            name="name"
            label="Project Name"
            required="Please enter a project name."
            rules={[{ validator: nameValidator }]}
          />

          <TextArea
            name="description"
            label="Description"
            placeholder={`Copy of project ${project?.name}`}
          />

          <LabeledInputItem
            name="parent"
            label={{
              text: 'Parent Project',
            }}
            tooltipText="Copy the project under an existing parent project. If blank, the project and its config will be created as a top-level (root) project"
            warningText={
              projectName && projectName.length > 0
                ? `Warning: The copied project will inherit ${projectName}’s configuration overwriting the copied configuration from the original source project`
                : ''
            }
          >
            <ProjectSelect
              onChange={(projectName) => {
                form.setFieldsValue({ parent: projectName })
                setProjectName(projectName)
              }}
            />
          </LabeledInputItem>
          {projects.find((p) => p.depends_on === project.url) && (
            <InlineLabelInput
              checkbox
              label="Copy Dependents"
              name="recursive"
              tooltipText={`Copies all sub-projects under ${project.name} to the new project.`}
            />
          )}
        </>
      )}

      {steps === 2 && <CopyProjectPreview form={form} project={project} />}
    </Form>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { CopyCreateProject, GetProjects } from 'data/project/actions'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import React, { useCallback, useMemo, useState } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { CopyModal } from 'components/Modals/CopyModal'
import { CopyProjectForm } from './CopyProjectForm'
import { ModalStepsFooter } from 'components/Actions/ActionsForm/ModalStepsFooter'
import { Project } from 'gen/cloudTruthRestApi'
import { useAppDispatch } from 'data/hooks'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  project: Project
  closeModal: () => void
  visibleFromProps: boolean
}

export function CopyProject(props: Props) {
  const { project, closeModal, visibleFromProps } = props
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()

  const [visible, setVisible] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [steps, setSteps] = useState<number>(0)

  // const args = {} as ProjectCreate
  const [form] = useForm()
  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  // eslint-disable-next-line
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
    setSteps(0)
    setPending(false)
    closeModal()
  }

  const closeModalCancel = useCallback(() => {
    closeModal ? (closeModal(), onCancel()) : onCancel()
  }, [closeModal, onCancel])

  const onOk = () => {
    form.submit()
    if (steps === 2) {
      form.submit()
    }
    if (steps < 1) {
      form.validateFields().then(() => setSteps((steps) => (steps += 2)))
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 2))
    }
  }

  const handleSubmit = () => {
    setPending(true)

    const description = form.getFieldValue('description') || `Copy of ${project.name}`
    const parent = form.getFieldValue('parent')
    const name = form.getFieldValue('name')
    const projectId = project.id
    const dependsOn = projects.find((project) => project.name === parent)?.url || null
    const recursive = form.getFieldValue('recursive')

    dispatch(
      CopyCreateProject({
        id: projectId,
        params: {
          name,
          description,
          depends_on: dependsOn,
          recursive: recursive,
        },
      })
    ).then(({ error }: TypedThunk<Project>) => {
      if (error) {
        errorToast(error.message)
        onCancel()
        return
      } else {
        successToast('Project successfully created')
        if (recursive) {
          dispatch(GetProjects(null)).then(({ error }: CustomThunk) => {
            if (error) {
              errorToast(error.message)
              onCancel()
            }
            onCancel()
          })
        }
        onCancel()
      }
    })
  }

  return (
    <CopyModal
      pending={pending}
      visible={modalVisible}
      objectName={`Project: ${project?.name}`}
      afterClose={() => form.resetFields()}
      onCancel={closeModalCancel}
      onOk={() => form.submit()}
      footer={
        <ModalStepsFooter
          objectName="Project"
          onCancel={closeModalCancel}
          onOk={onOk}
          steps={steps}
          onPrevious={onPrevious}
          pending={pending}
          visibleFromProps={visibleFromProps}
        />
      }
    >
      <CopyProjectForm form={form} steps={steps} handleSubmit={handleSubmit} project={project} />
    </CopyModal>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { ExpiringValuesResponse } from 'gen/cloudTruthRestApi'
import { GetExpired } from './actions'
import { epoch } from 'data/dataUtils'

interface ExpireState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const expireAdapter = createEntityAdapter<ExpiringValuesResponse>({})

const initialState = expireAdapter.getInitialState({
  cacheLastChanged: epoch,
} as ExpireState)

export type ExpireRootState = EntityState<ExpiringValuesResponse> & ExpireState

const expireSlice = createSlice({
  name: 'expire',
  initialState,
  reducers: {
    setAllExpire: expireAdapter.setAll,
    addOneExpire: expireAdapter.addOne,
    updateOneExpire: expireAdapter.upsertOne,
    removeOneExpire: expireAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GetExpired.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      expireAdapter.setOne(state, action.payload)
    })
  },
})

export const { resetState, setAllExpire, updateOneExpire, removeOneExpire, addOneExpire } =
  expireSlice.actions

export default expireSlice.reducer

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import {
  CreateParameterType,
  CreateParameterTypeRule,
  DeleteParameterTypeRule,
  UpdateParameterType,
  UpdateParameterTypeRule,
} from 'data/parameterType/actions'
import { CustomThunk, TypedThunk } from 'data/dataUtils'
import { FormData, useForm } from 'components/Forms'
import { ParameterRuleTypeEnum, ParameterType, ParameterTypeCreate } from 'gen/cloudTruthRestApi'
import React, { useEffect, useState } from 'react'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'

import { AddModal } from 'components/Modals'
import CustomTypeForm from './CustomTypeForm'
import { EditModal } from 'components/Modals/EditModal'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { ParameterRuleType } from 'data/parameter/actions'
import { buildRuleUpdates } from './Rules/utils'
import { updateOneParameterType } from 'data/parameterType/reducer'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  visible: boolean
  setVisible: React.Dispatch<boolean>
  type?: ParameterType
  customName?: string
  afterCreate?: (option: string) => void
  redirect?: boolean
}

export function CustomTypeModal(props: Props) {
  const { visible, setVisible, type, customName, afterCreate, redirect } = props

  const parameterTypes = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())

  const [loading, setLoading] = useState<boolean>(false)
  const [rules, setRules] = useState<ParameterRuleType[]>(type?.rules || [])

  const { errorToast, successToast } = useToast()
  const { goToOrgRoute } = useHistoryPush()
  const dispatch = useAppDispatch()
  const [form] = useForm()

  useEffect(() => {
    // This component's state must update when a type updates
    if (type && !loading) {
      setRules(type.rules)
    }
  }, [loading, type])

  const afterClose = () => {
    form.resetFields()
    setRules([])
    setLoading(false)
  }

  const onFinish = (formData: FormData) => {
    setLoading(true)

    const { name, description, parentName } = formData
    const parent = parameterTypes.find((type) => type.name === parentName)!.url
    const args = { name, description, parent } as ParameterTypeCreate

    if (type) {
      const { createRules, updateRules, deleteRules } = buildRuleUpdates(type.rules, rules)
      const promises: Promise<CustomThunk>[] = []
      if (parentName !== 'enum') {
        createRules.forEach((create) => {
          promises.push(
            new Promise((resolve) =>
              dispatch(CreateParameterTypeRule({ ...create, typeId: type.id })).then(() => {
                resolve({})
              })
            )
          )
        })

        updateRules.forEach((update) => {
          promises.push(
            new Promise((resolve) =>
              dispatch(UpdateParameterTypeRule({ ...update, typeId: type.id })).then(() => {
                resolve({})
              })
            )
          )
        })

        deleteRules.forEach((deletion) => {
          promises.push(
            new Promise((resolve) =>
              dispatch(DeleteParameterTypeRule({ ruleId: deletion.id, typeId: type.id })).then(
                () => {
                  resolve({})
                }
              )
            )
          )
        })
      }

      const breakOrContinue = (responses: CustomThunk[], next: () => void): void => {
        const error = responses.find((response) => response.error)?.error

        if (error) {
          errorToast(error.message)
          setLoading(false)
        } else {
          next()
        }
      }

      const finishUpdate = () => {
        if (formData.enumRules && parentName === 'enum') {
          if (type.rules.length === 0) {
            dispatch(
              CreateParameterTypeRule({
                type: ParameterRuleTypeEnum.OneOf,
                constraints: formData.enumRules.map((value: any) => value['key']),
                typeId: type.id,
              })
            ).then(({ error, payload: rule }: CustomThunk) => {
              if (error) {
                errorToast(error.message)
              } else {
                setRules([rule])
                dispatch(updateOneParameterType({ ...type, rules: [rule] }))
                successToast('Custom type successfully updated.')
                setVisible(false)
                setLoading(false)
              }
            })
          } else {
            dispatch(
              UpdateParameterTypeRule({
                id: type.rules[0].id,
                type: ParameterRuleTypeEnum.OneOf,
                constraints: formData.enumRules.map((value: any) => value['key']),
                typeId: type.id,
              })
            ).then(({ error, payload }: CustomThunk) => {
              if (error) {
                errorToast(error.message)
              } else {
                setRules([payload])
                successToast('Custom type successfully updated.')
                setVisible(false)
                setLoading(false)
              }
            })
          }
        } else {
          Promise.all(promises).then((responses) => {
            breakOrContinue(responses, done)
          })
        }
      }

      const done = () => {
        successToast('Custom type successfully updated.')
        setVisible(false)
        setLoading(false)
      }

      if (name !== type.name || description !== type.description || parent !== type.parent) {
        dispatch(UpdateParameterType({ ...type, name, description, parent })).then(
          (response: CustomThunk) => {
            breakOrContinue([response], finishUpdate)
          }
        )
      } else {
        finishUpdate()
      }
    } else {
      dispatch(CreateParameterType(args)).then(({ error, payload }: TypedThunk<ParameterType>) => {
        if (error) {
          errorToast(error.message)
          setLoading(false)
        } else {
          const promises: Promise<CustomThunk>[] = rules.map((rule) => {
            return new Promise((resolve) =>
              dispatch(CreateParameterTypeRule({ ...rule, typeId: payload.id })).then(() => {
                resolve({})
              })
            )
          })
          if (formData.enumRules && parentName === 'enum') {
            dispatch(
              CreateParameterTypeRule({
                type: ParameterRuleTypeEnum.OneOf,
                constraints: formData.enumRules.map((value: any) => value['key']),
                typeId: payload.id,
              })
            ).then(({ error, payload: rule }: CustomThunk) => {
              if (error) {
                errorToast(error.message)
              } else {
                setRules([rule])
                dispatch(updateOneParameterType({ ...payload, rules: [rule] }))
                successToast('Custom type successfully created.')
                setVisible(false)
                if (afterCreate) {
                  afterCreate(name)
                }

                if (redirect) {
                  goToOrgRoute(OrganizationPaths.Types)
                }
              }
            })
          } else {
            Promise.all(promises).then((responses) => {
              const error = responses.find((response) => response.error)?.error

              if (error) {
                errorToast(error.message)
              } else {
                successToast('Custom type successfully created.')
                setVisible(false)
                if (afterCreate) {
                  afterCreate(name)
                }

                if (redirect) {
                  goToOrgRoute(OrganizationPaths.Types)
                }
              }
              setLoading(false)
            })
          }
        }
      })
    }
  }

  const customTypeForm = (
    <CustomTypeForm
      type={type}
      customName={customName}
      rules={rules}
      setRules={setRules}
      form={form}
      onFinish={onFinish}
    />
  )

  return (
    <>
      {type ? (
        <EditModal
          visible={visible}
          objectName="Type"
          afterClose={afterClose}
          onCancel={() => setVisible(false)}
          onOk={form.submit}
          pending={loading}
        >
          {customTypeForm}
        </EditModal>
      ) : (
        <AddModal
          visible={visible}
          objectName="Type"
          afterClose={afterClose}
          onCancel={() => setVisible(false)}
          onOk={form.submit}
          pending={loading}
          okText={type ? 'Update Type' : 'Create Type'}
        >
          {customTypeForm}
        </AddModal>
      )}
    </>
  )
}

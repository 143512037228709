//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import { FormInstance } from 'antd'
import { LabelText } from 'components/LabelText'
import { Parameter } from 'gen/cloudTruthRestApi'
import React from 'react'
import styles from 'components/Actions/Push/PreviewAddPush.module.scss'
interface Props {
  form: FormInstance
  parameter?: Parameter
}

export function CopyParameterPreview(props: Props) {
  const { form, parameter } = props
  const description = form.getFieldValue('description')
  const parent = form.getFieldValue('parent')
  const name = form.getFieldValue('name')

  return (
    <div className={styles.container}>
      <LabelText text={parameter?.name} label="Parameter To Copy" />
      <br />
      <LabelText text={name} label="Name" />
      <br />

      {parent && (
        <>
          <LabelText text={parent} label="Parent Project" />
          <br />
        </>
      )}
      {description?.length > 0 && (
        <>
          <LabelText text={description} label="Description" />
          <br />
        </>
      )}
    </div>
  )
}

import { CustomThunk, integrationIdFromAkvPullUrl } from 'data/dataUtils'
import { akvPullEntitySelectors, useSelectAkvPull } from 'data/actions/akvImport/selectors'

import { AzureKeyVaultIntegration } from 'gen/cloudTruthRestApi'
import { ConfirmModal } from 'components/Modals/ConfirmModal'
import { DeleteAzureKeyVaultIntegration } from 'data/integration/akvIntegration/actions'
import React from 'react'
import { removeOneAzureKeyVaultPull } from 'data/actions/akvImport/reducer'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  integration: AzureKeyVaultIntegration
  visible: boolean
  setVisible: (bool: boolean) => void
  loading: boolean
  setLoading: (bool: boolean) => void
  setDeleteOptionsVisible: (bool: boolean) => void
}

export function DeleteAkv(props: Props) {
  const { integration, visible, setVisible, loading, setLoading, setDeleteOptionsVisible } = props

  const dispatch = useAppDispatch()
  const { successToast, errorToast } = useToast()
  const akvMappedPulls = akvPullEntitySelectors
    .selectAll(useSelectAkvPull())
    .filter((pull) => pull.mode === 'mapped')

  const deleteAkv = () => {
    setLoading(true)
    dispatch(DeleteAzureKeyVaultIntegration({ id: integration.id })).then(
      ({ error }: CustomThunk) => {
        setLoading(false)
        setVisible(false)

        if (error?.message.includes('in use')) {
          setDeleteOptionsVisible(true)
        } else {
          if (error) {
            errorToast(error.message)
            return
          }

          const mappedValue = akvMappedPulls.find(
            (pull) => integrationIdFromAkvPullUrl(pull.url) === integration.id
          )

          if (mappedValue) {
            dispatch(removeOneAzureKeyVaultPull(mappedValue.id))
          }

          successToast('Azure integration successfully deleted.')
        }
      }
    )
  }

  return (
    <>
      <ConfirmModal
        visible={visible}
        loading={loading}
        subject={`Are you sure you want to delete ${integration.vault_name}?`}
        onCancel={() => setVisible(false)}
        onOk={deleteAkv}
      />
    </>
  )
}

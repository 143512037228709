import { FormData, useForm } from 'components/Forms'
import { Project, ProjectCreate } from 'gen/cloudTruthRestApi'
import React, { useCallback, useMemo, useState } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { ActionButton } from 'components/ActionButton'
import { AddModal } from 'components/Modals'
import { AddProjectForm } from './AddProjectForm'
import { CreateProject } from 'data/project/actions'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import { TreeItem } from 'react-sortable-tree'
import { TypedThunk } from 'data/dataUtils'
import { Typography } from 'antd'
import styles from './Projects.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useHistoryPush } from 'router/customHooks'
import { useToast } from 'hooks'

interface Props {
  addProjectToTree?: (name: string, description: string) => void
  addProjectUnderParent?: (parentName: string, node: TreeItem) => void
  visibleFromProps?: boolean
  onClose?: () => void
  hide?: boolean
  redirect?: boolean
}

interface Create extends ProjectCreate {
  parent: string
  children: string[]
}

export function AddProject(props: Props) {
  const { addProjectToTree, addProjectUnderParent, visibleFromProps, hide, onClose, redirect } =
    props
  const projects = projectEntitySelectors.selectAll(useSelectProjects())
  const [visible, setVisible] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [form] = useForm()
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const { goToOrgRoute } = useHistoryPush()

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  const closeModal = useCallback(() => {
    onClose ? onClose() : setVisible(false)
  }, [onClose])

  const onFinish = (formData: FormData) => {
    const { name, parameter_name_pattern, description, copy_rbac } = formData as Create
    const parent = formData?.parent
    const dependsOn = projects.find((project) => project.name === parent)?.url || null

    setDisabled(true)
    dispatch(
      CreateProject({ name, parameter_name_pattern, description, depends_on: dependsOn, copy_rbac })
    )
      .then(({ error }: TypedThunk<Project>) => {
        if (error) {
          errorToast(error.message)
          return
        } else {
          successToast('Project successfully created')
          if (redirect) {
            goToOrgRoute(OrganizationPaths.Projects)
          }
        }

        const node = {
          title: name,
          expanded: true,
          subtitle: (
            <Typography.Text className={styles.subtitle} ellipsis>
              {description || ''}
            </Typography.Text>
          ),
          children: undefined,
        }

        if (addProjectToTree && parent === null) {
          addProjectToTree(name, description || '')
        } else if (addProjectUnderParent && parent !== null) {
          addProjectUnderParent(parent, node)
        }
      })
      .then(() => {
        setDisabled(false)
        setVisible(false)
        closeModal()
      })
  }

  return (
    <>
      {!hide && (
        <ActionButton customType="add" size="large" onClick={() => setVisible(true)}>
          Add Project
        </ActionButton>
      )}

      <AddModal
        pending={disabled}
        visible={modalVisible}
        objectName="Project"
        afterClose={() => form.resetFields()}
        onCancel={closeModal}
        onOk={() => form.submit()}
      >
        <AddProjectForm form={form} onFinish={onFinish} />
      </AddModal>
    </>
  )
}

import { DeleteConfirmModal, DeleteErrorModal } from '../Modals'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CopyEnvironment } from './CopyEnvironment/CopyEnvironment'
import { CustomThunk } from 'data/dataUtils'
import { DeleteEnvironment } from 'data/environment/actions'
import { Environment } from 'gen/cloudTruthRestApi'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from '../MoreIconDropdown'
import { UpdateEnvironmentDescription } from './UpdateEnvironmentDescription'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getPolicy } from 'data/membership/selectors'
import { useToast } from 'hooks'

interface Props {
  environment: Environment
  getEnvironments: () => void
}

const EDIT_DESCRIPTION = 'editDescription'
const COPY_ENVIRONMENT = 'copyEnvironment'
const DELETE_ENVIRONMENT = 'deleteEnvironment'

export function EnvironmentDetailsDropdown(props: Props) {
  const { environment, getEnvironments } = props
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [descriptionVisible, setDescriptionVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [copyVisible, setCopyVisible] = useState(false)
  const { canAdministrate } = useAppSelector(getPolicy(null))

  const { canContribute } = useAppSelector(getPolicy(null))
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const [loading, setLoading] = useState(false)

  const deleteEnvironment = () => {
    setLoading(true)
    dispatch(DeleteEnvironment(environment.id))
      .then(({ error }: CustomThunk) => {
        error ? errorToast(error.message) : successToast('Environment successfully deleted')
        setDeleteVisible(false)
      })
      .then(() => {
        setLoading(false)
      })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case EDIT_DESCRIPTION: {
        setDescriptionVisible(true)
        break
      }

      case COPY_ENVIRONMENT: {
        setCopyVisible(true)
        break
      }

      case DELETE_ENVIRONMENT: {
        setDeleteVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(canAdministrate, [
      {
        key: COPY_ENVIRONMENT,
        label: 'Copy',
      },
    ]),
    ...conditionalArrayItem(canContribute, [
      {
        key: EDIT_DESCRIPTION,
        label: 'Edit',
      },

      {
        key: DELETE_ENVIRONMENT,
        label: 'Delete',
        danger: true,
      },
    ]),
  ]

  return (
    <>
      {environment.name !== 'default' && (
        <>
          <DeleteErrorModal
            closeModal={() => setErrorMessage('')}
            errorMessage={errorMessage}
            subject="environment"
            visible={errorMessage !== ''}
          />
          <DeleteConfirmModal
            closeModal={() => setDeleteVisible(false)}
            visible={deleteVisible}
            onDelete={deleteEnvironment}
            subject={`"${environment.name}"`}
            loading={loading}
            removePronoun
          />
          {canAdministrate && (
            <CopyEnvironment
              visibleFromProps={copyVisible}
              environment={environment}
              closeModal={() => setCopyVisible(false)}
              getEnvironments={getEnvironments}
            />
          )}
          <UpdateEnvironmentDescription
            environment={environment}
            visible={descriptionVisible}
            closeModal={() => setDescriptionVisible(false)}
          />
          <MoreIconDropdown menu={{ items, onClick }} color="black" />
        </>
      )}
    </>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  CheckAzureKeyVaultIntegrationStatus,
  CreateAzureKeyVaultIntegration,
  DeleteAzureKeyVaultIntegration,
  GetAzureKeyVaultIntegration,
  GetAzureKeyVaultIntegrations,
  UpdateAzureKeyVaultIntegration,
} from './actions'
import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AzureKeyVaultIntegration } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface AzureKeyVaultIntegrationState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const akvIntegrationAdapter = createEntityAdapter<AzureKeyVaultIntegration>({
  selectId: (int) => int.id,
})

const initialState = akvIntegrationAdapter.getInitialState({
  cacheLastChanged: epoch,
  current: null,
} as AzureKeyVaultIntegrationState)

export type AzureKeyVaultIntegrationRootState = EntityState<AzureKeyVaultIntegration> &
  AzureKeyVaultIntegrationState

const akvIntegrationSlice = createSlice({
  name: 'akvIntegration',
  initialState,
  reducers: {
    // direct actions
    selectAzureKeyVaultIntegration(state, action: PayloadAction<string>) {
      state.current = action.payload
    },
    setAllAzureKeyVaultIntegration: akvIntegrationAdapter.setAll,
    addOneAzureKeyVaultIntegration: akvIntegrationAdapter.addOne,
    updateOneAzureKeyVaultIntegration: akvIntegrationAdapter.upsertOne,
    removeOneAzureKeyVaultIntegration: akvIntegrationAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateAzureKeyVaultIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetAzureKeyVaultIntegrations.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.setAll(state, action.payload.results!)
      state.current = null
    })
    builder.addCase(GetAzureKeyVaultIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(UpdateAzureKeyVaultIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteAzureKeyVaultIntegration.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.removeOne(state, action.payload)
      if (state.current === action.payload) {
        state.current = null
      }
    })
    builder.addCase(CheckAzureKeyVaultIntegrationStatus.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      akvIntegrationAdapter.upsertOne(state, action.payload)
    })
  },
})

export const {
  resetState,
  selectAzureKeyVaultIntegration,
  setAllAzureKeyVaultIntegration,
  addOneAzureKeyVaultIntegration,
  updateOneAzureKeyVaultIntegration,
  removeOneAzureKeyVaultIntegration,
} = akvIntegrationSlice.actions

export default akvIntegrationSlice.reducer

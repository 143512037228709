//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { CreateGrant, DeleteGrant, GetGrant, GetGrants, UpdateGrant } from './actions'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { EntityState } from '@reduxjs/toolkit/dist/entities'
import { Grant } from 'gen/cloudTruthRestApi'
import { epoch } from 'data/dataUtils'

interface GrantState {
  cacheLastChanged: typeof epoch
  current: nullable<string>
}

export const grantAdapter = createEntityAdapter<Grant>({
  selectId: (org) => org.id,
})

const initialState = grantAdapter.getInitialState({
  cacheLastChanged: epoch,
} as GrantState)

export type GrantRootState = EntityState<Grant> & GrantState

const grantSlice = createSlice({
  name: 'grant',
  initialState,
  reducers: {
    setAllGrant: grantAdapter.setAll,
    addOneGrant: grantAdapter.addOne,
    updateOneGrant: grantAdapter.upsertOne,
    removeOneGrant: grantAdapter.removeOne,
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(CreateGrant.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      grantAdapter.addOne(state, action.payload)
    })
    builder.addCase(GetGrants.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())

      grantAdapter.setAll(state, action.payload.results!)
    })
    builder.addCase(GetGrant.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      grantAdapter.addOne(state, action.payload)
    })
    builder.addCase(UpdateGrant.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      grantAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(DeleteGrant.fulfilled, (state, action) => {
      state.cacheLastChanged = new Date(Date.now())
      grantAdapter.removeOne(state, action.payload)
    })
  },
})

export const { resetState, setAllGrant, updateOneGrant, removeOneGrant, addOneGrant } =
  grantSlice.actions

export default grantSlice.reducer

import React, { useMemo } from 'react'
import { Upload, UploadProps } from 'antd'

import { FileUpload } from './Gpt'
import OpenAI from 'openai'
import { PaperClipOutlined } from '@ant-design/icons'
import { handleUpload } from './utils'

interface Props {
  files: FileUpload[]
  setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>
  openai: OpenAI
}

export function PaperClipUpload(props: Props) {
  const { setFiles, openai } = props

  const uploadProps: UploadProps = useMemo(
    () => ({
      name: 'file',
      multiple: true,
      customRequest: async ({ file }) => {
        const fileResponse = await handleUpload(file, openai)
        setFiles((prev: any) => (prev ? [...prev, fileResponse] : [fileResponse]))
      },
      // onChange: () => {},
      itemRender: () => {
        return null
      },
    }),
    [openai, setFiles]
  )
  return (
    <Upload
      {...uploadProps}
      style={{
        width: '100%',
        border: 'none',
        background: 'none',
        textAlign: 'inherit',
        cursor: 'default',
        padding: '0px',
      }}
    >
      <PaperClipOutlined
        style={{
          fontSize: '24px',
          padding: '5px 8px 5px 0px',
          cursor: 'pointer',
          alignSelf: 'flex-end',
        }}
      />
    </Upload>
  )
}

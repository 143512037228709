//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Membership, RoleEnum } from 'gen/cloudTruthRestApi'
import { MembershipRootState, membershipAdapter } from './reducer'

import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { idFromUrl } from 'data/dataUtils'
import { useSelectState } from '../hooks'

const rootState = (state: RootState) => state

interface Policy {
  canContribute: boolean
  canAdministrate: boolean
  hasOwnership: boolean
  canUpdateMembership: (role: RoleEnum) => boolean
}

const selectMembership = (state: RootState, id: nullable<string>): Membership => {
  const memberships = Object.values(state.membership.entities),
    userId = id || state.user.current
  // If in the future a user can belong to multiple orgs, search by active org id as well.
  return memberships.find((m) => idFromUrl(m!.user) === userId)!
}

const selectMemberships = (
  state: RootState,
  id: nullable<string>
): Array<Membership | undefined> => {
  const memberships = Object.values(state.membership.entities),
    userId = id || state.user.current

  return memberships.filter((m) => idFromUrl(m!.user) === userId)!
}

export const getRole = (id: nullable<string>): ((S: any) => RoleEnum) =>
  createSelector(rootState, (state) => {
    return selectMembership(state, id)?.role ?? RoleEnum.NO_SECRETS
  })

export const getPolicy = (id: nullable<string>): ((S: any) => Policy) =>
  createSelector(rootState, (state) => {
    const membership = selectMembership(state, id)
    const currentMemberRole = membership?.role

    const membershipHierarchy: { [key in RoleEnum]: number } = {
      /* eslint-disable  -- Key types come from database (RoleEnum) - disabling the naming convention keeps it more type safe */
      NO_SECRETS: 0,
      VIEWER: 1,
      CONTRIB: 2,
      ADMIN: 3,
      OWNER: 4,
      /* eslint-enable */
    }

    const canUpdateMembership = (role: RoleEnum) => {
      return membershipHierarchy[currentMemberRole] >= membershipHierarchy[role]
    }

    return {
      canContribute: membershipHierarchy[currentMemberRole] > 1,
      canAdministrate: membershipHierarchy[currentMemberRole] > 2,
      hasOwnership: membershipHierarchy[currentMemberRole] === membershipHierarchy[RoleEnum.OWNER],
      canUpdateMembership,
    }
  })

export const getUserMemberships = (
  id: nullable<string>
): ((S: any) => Array<Membership | undefined>) =>
  createSelector(rootState, (state) => {
    return selectMemberships(state, id)
  })

export const getUserMembership = (id: nullable<string>): ((S: any) => Membership | undefined) =>
  createSelector(rootState, (state) => {
    return selectMembership(state, id)
  })

export const membershipEntitySelectors = membershipAdapter.getSelectors()
export const useSelectMemberships = (): MembershipRootState => useSelectState().membership

import { Checkbox, FormInstance, Space } from 'antd'
import {
  Form,
  FormData,
  InlineLabelInput,
  Item,
  LabeledInputItem,
  TextArea,
} from 'components/Forms'
import React, { useState } from 'react'

import { ActionButton } from 'components/ActionButton'
import { AddResourceTags } from 'components/Integrations/AddResourceTags'
import { AzureKeyVaultIntegration } from 'gen/cloudTruthRestApi'
import { CreateAzureKeyVaultIntegration } from 'data/integration/akvIntegration/actions'
import { Details } from 'components/Details'
import { TypedThunk } from 'data/dataUtils'
import styles from './AddAkv.module.scss'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  tenant: string
  form: FormInstance
  onCancel: () => void
}

export function AddAkv(props: Props) {
  const { tenant, form, onCancel } = props
  const [loading, setLoading] = useState(false)
  const [writable, setWritable] = useState(false)

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const onFinish = (formData: FormData) => {
    const { name, description } = formData
    setLoading(true)

    dispatch(
      CreateAzureKeyVaultIntegration({
        tenant_id: tenant,
        vault_name: name,
        resource_tags: form.getFieldValue('resource-tags')
          ? (form.getFieldValue('resource-tags') as Record<string, string>[]).reduce(
              (acc, { key, value }) => ({ ...acc, [key]: value }),
              {}
            )
          : undefined,
        description,
        writable,
      })
    ).then(({ error }: TypedThunk<AzureKeyVaultIntegration>) => {
      if (error) {
        errorToast(error.message)
        setLoading(false)
        return
      }

      successToast('Azure integration successfully created')
      onCancel()
    })
  }

  return (
    <div className={styles.container}>
      <Details name="Connect Azure Account">
        <div className={styles.form}>
          <Form form={form} initialValues={{ tenant }} onFinish={onFinish}>
            <InlineLabelInput
              name="tenant"
              label="Azure Tenant ID"
              required
              disabled
              width={'480px'}
            />
            <InlineLabelInput
              autoFocus
              name="name"
              label="Key Vault Name"
              required="Please enter your Azure account name."
              width={'500px'}
            />
            <TextArea name="description" label="Description" />
            <LabeledInputItem name="writable" label="write access">
              <Checkbox onChange={(e) => setWritable(e.target.checked)}>Writable</Checkbox>
            </LabeledInputItem>
            <LabeledInputItem name="resource-tags" label="resource tags">
              <AddResourceTags />
            </LabeledInputItem>

            <Item>
              <Space>
                <ActionButton type="primary" htmlType="submit" loading={loading}>
                  Submit
                </ActionButton>
                <ActionButton htmlType="button" onClick={onCancel} disabled={loading}>
                  Cancel
                </ActionButton>
              </Space>
            </Item>
          </Form>
        </div>
      </Details>
    </div>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import React, { useEffect } from 'react'
import { actionTense, emDash } from 'lib/valueHelpers'

import { AuditTrail } from 'gen/cloudTruthRestApi'
import { Spin } from 'antd'
import { formatDateTime } from 'lib/dateHelpers'
import styles from './RecentActivity.module.scss'

const formatActivityDetails = (log: AuditTrail): string => {
  let objectName = log.object_name
  if (objectName.length > 16) {
    objectName =
      objectName.slice(0, 7) + '...' + objectName.slice(objectName.length - 7, objectName.length)
  }
  return ` ${emDash} ${log.object_type} ${objectName} ${actionTense(log.action)}`
}
interface Props {
  loading: boolean
  activity: AuditTrail[]
  getRecent?: () => void
}

export function RecentActivity(props: Props) {
  const { loading, activity, getRecent } = props

  useEffect(() => {
    getRecent && getRecent()
  }, [getRecent])

  const ActivityLog = () => {
    return (
      <div>
        {activity &&
          activity.map((log) => {
            return (
              <div key={log.id} className={styles.activityItem}>
                <span className={styles.time}>{formatDateTime(log.timestamp)}</span>
                {formatActivityDetails(log)}
              </div>
            )
          })}
      </div>
    )
  }

  if (loading) {
    return (
      <div className={styles.spinContainer}>
        <Spin data-testid="loading" />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <ActivityLog />
    </div>
  )
}

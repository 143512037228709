import { CustomThunk, environmentIdFromUrl } from 'data/dataUtils'
import { DeleteTag, EnvironmentTagDelete } from 'data/environment/actions'
//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useState } from 'react'

import { DeleteConfirmModal } from 'components/Modals'
import { EditTag } from './EditTag'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { MoreIconDropdown } from 'components/MoreIconDropdown'
import { Tag } from 'gen/cloudTruthRestApi'
import { Tooltip } from 'antd'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

enum MenuItemKeys {
  DeleteTag = 'deleteTag',
  EditTag = 'editTagDescription',
}

interface Props {
  tag: Tag
}

export function TagDropdownMenu(props: Props) {
  const { tag } = props

  const [deleteVisible, setDeleteVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)

  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()
  const [loading, setLoading] = useState(false)

  const deleteTag = () => {
    setLoading(true)
    const args = {
      envId: environmentIdFromUrl(tag.url),
      id: tag.id,
    } as EnvironmentTagDelete
    dispatch(DeleteTag(args)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
        setDeleteVisible(false)
      } else {
        successToast('Tag successfully deleted')
      }
    })
  }

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case MenuItemKeys.DeleteTag: {
        setDeleteVisible(true)
        break
      }

      case MenuItemKeys.EditTag: {
        setEditVisible(true)
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    {
      key: MenuItemKeys.EditTag,
      label: (
        <>
          {tag.immutable ? (
            <Tooltip title="Immutable tags are not editable">
              <div>Edit Tag</div>
            </Tooltip>
          ) : (
            'Edit Tag'
          )}
        </>
      ),
      toolbar: 'asdf',
      disabled: !!tag.immutable,
    },
    { key: MenuItemKeys.DeleteTag, label: 'Delete Tag', danger: true },
  ]

  return (
    <>
      <DeleteConfirmModal
        visible={deleteVisible}
        closeModal={() => setDeleteVisible(false)}
        onDelete={deleteTag}
        subject="tag"
        loading={loading}
      />
      <EditTag tag={tag} visible={editVisible} onClose={() => setEditVisible(false)} />
      <MoreIconDropdown menu={{ items, onClick }} color="black" />
    </>
  )
}

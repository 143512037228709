//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { ConfigProvider, Tooltip, theme } from 'antd'
import React, { useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { AddEnvironment } from 'components/Environments/AddEnvironment'
import { AddParameter } from 'components/Parameters/AddParameter'
import { AddProject } from 'components/Projects/AddProject'
import { AddTag } from 'components/Environments/Tags/AddTag'
import { AddTemplate } from 'components/Templates/AddTemplate'
import { CustomTypeModal } from 'components/Types/CustomTypeModal'
import { EnvironmentSelector } from 'components/EnvironmentSelector'
import { GlobalCreate } from './GlobalCreate'
import { HistoryViews } from 'components/HistoryTable'
import { ProjectSelect } from 'components/SideNav/ProjectSelect'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getPolicy } from 'data/membership/selectors'
import { selectProject } from 'data/project/reducer'
import styles from './HeaderActions.module.scss'
import { useHistoryPush } from 'router/customHooks'
import { useLocation } from 'react-router-dom'

export const HeaderActions = () => {
  const [parameterVisible, setParameterVisible] = useState(false)
  const [templateVisible, setTemplateVisible] = useState(false)
  const [environmentVisible, setEnvironmentVisible] = useState(false)
  const [projectVisible, setProjectVisible] = useState(false)
  const [typeVisible, setTypeVisible] = useState(false)
  const [tagVisible, setTagVisible] = useState(false)

  const openParameters = () => setParameterVisible(true)
  const openTemplates = () => setTemplateVisible(true)
  const openEnvironments = () => setEnvironmentVisible(true)
  const openProjects = () => setProjectVisible(true)
  const openTypes = () => setTypeVisible(true)
  const openTags = () => setTagVisible(true)

  const dispatch = useAppDispatch()
  const { canContribute } = useAppSelector(getPolicy(null))
  createImageBitmap
  const { pathname } = useLocation()
  const { goToParameterRoute, goToTemplateRoute, goToCompareRoute, goToHistoryRoute } =
    useHistoryPush()

  const handleSelect = (id: string) => {
    const path = pathname.split('/').pop()

    dispatch(selectProject(id))
    if (path === 'parameters') {
      goToParameterRoute(id, true)
    } else if (path === 'templates') {
      goToTemplateRoute(id)
    } else if (path === 'compare') {
      goToCompareRoute(id, true)
      // history route ends in /parameter
    } else if (path === 'parameter') {
      goToHistoryRoute(id, HistoryViews.Parameter, true)
    }
  }

  const globalCreateData = useMemo(() => {
    const defaultData = {
      label: 'Create Project',
      key: 'project',
      onClick: openProjects,
    }

    const buttons = [
      {
        label: 'Create Parameter',
        key: 'parameter',
        secondKey: 'compare',
        onClick: openParameters,
      },
      {
        label: 'Create Template',
        key: 'template',
        onClick: openTemplates,
      },
      {
        label: 'Create  Environment',
        key: 'environment',
        onClick: openEnvironments,
      },
      {
        label: 'Create Type',
        key: 'type',
        onClick: openTypes,
      },

      {
        label: 'Create Tag',
        key: 'tag',
        onClick: openTags,
      },
      {
        label: 'Create Project',
        key: 'project',
        onClick: openProjects,
      },
    ]

    const data = buttons.find(
      (button) =>
        window.location.pathname.includes(button.key) ||
        window.location.pathname.includes(button.secondKey ?? 'not-found')
    )

    return data ? data : defaultData
  }, [window.location.pathname])

  return (
    <>
      <AddParameter
        visibleFromProps={parameterVisible}
        closeOverrideModal={() => setParameterVisible(false)}
        hide
      />

      <AddTag hide onClose={() => setTagVisible(false)} visibleFromProps={tagVisible} redirect />

      <AddProject
        hide
        onClose={() => setProjectVisible(false)}
        visibleFromProps={projectVisible}
        redirect
      />

      <AddTemplate
        hide
        onClose={() => setTemplateVisible(false)}
        visibleFromProps={templateVisible}
        redirect
      />

      <AddEnvironment
        hide
        onClose={() => setEnvironmentVisible(false)}
        visibleFromProps={environmentVisible}
        redirect
      />

      <CustomTypeModal visible={typeVisible} setVisible={setTypeVisible} redirect />

      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          components: {
            TreeSelect: {
              colorPrimary: 'black',
              colorPrimaryHover: 'black',
              colorPrimaryActive: 'black',
            },
          },
        }}
      >
        <div className={styles.container}>
          <div className={styles.projectSelector}>
            <span className={styles.label}>Project:</span>

            <div className={styles.iconWrapper}>
              <ProjectSelect onChange={handleSelect} />
              <Tooltip title="Selecting a global project context controls the parameter list.">
                <QuestionCircleOutlined className={styles.icon} data-cy="project-tooltip" />
              </Tooltip>
            </div>
          </div>

          <div className={styles.environmentSelector}>
            <span className={styles.label}>Environment:</span>
            <div className={styles.iconWrapper}>
              <EnvironmentSelector />
              <Tooltip title="Selecting a global environment context displays parameters values for the environment.">
                <QuestionCircleOutlined className={styles.icon} data-cy="environment-tooltip" />
              </Tooltip>
            </div>
          </div>
          {canContribute && (
            <GlobalCreate
              openEnvironments={openEnvironments}
              openParameter={openParameters}
              openProjects={openProjects}
              openTags={openTags}
              openTypes={openTypes}
              openTemplates={openTemplates}
              onClick={globalCreateData.onClick}
              title={globalCreateData.label}
            />
          )}
        </div>
      </ConfigProvider>
    </>
  )
}

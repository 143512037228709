import { Button, Switch } from 'antd'
import { GetOrganization, UpdateOrganization } from 'data/organization/actions'
import { Label, useForm } from 'components/Forms'
//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { Options, SetLocalSessionArgs, setLocalSession } from 'lib/sessionPersistance'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionButton } from 'components/ActionButton'
import { CustomThunk } from 'data/dataUtils'
import { DeleteConfirmModal } from 'components/Modals'
import { EditProjectPatternForm } from 'components/OrganizationManagement/OrganizationDetails/EditProjectPatternForm'
import { PageTitle } from 'components/PageTitle'
import { getCurrentOrganization } from 'data/organization/selectors'
import styles from './OrganizationDetails.module.scss'
import { subscriptionDaysLeft } from 'lib/dateHelpers'
import { useToast } from 'hooks'

// NOTE: we do not normally allow "any" types, but the ChargeBee
//       drop-in script is not typescript, so we need it here unless
//       someone with more typescript-fu can wrangle it.
declare let Chargebee: any

export function OrganizationDetails() {
  const organization = useAppSelector(getCurrentOrganization)
  const {
    id,
    name,
    subscription_expires_at,
    subscription_id,
    subscription_plan_name,
    notification_config,
  } = organization!
  const token = useAppSelector((state) => state.session.jwt)
  const dispatch = useAppDispatch()
  const { successToast, errorToast } = useToast()

  const apiKeyExp = notification_config?.api_key_expiration

  const [resetVisible, setResetVisible] = useState<boolean>(false)

  const handleNotifyChange = (checked: boolean) => {
    dispatch(
      UpdateOrganization({
        ...organization!,
        notification_config: { api_key_expiration: { ...apiKeyExp, enabled: checked } },
      })
    ).then(({ error }: CustomThunk) => {
      error
        ? errorToast(error.message)
        : successToast('Notification settings successfully updated.')
    })
  }

  const pageTypes: SetLocalSessionArgs['pageType'][] = [
    'parameter',
    'compare',
    'history',
    'types',
    'projects',
    'serviceAccount',
    'sideNav',
    'user',
    'auditLog',
    'groups',
    'tags',
    'imports',
    'pushes',
    'parameterTimeline',
  ]

  const resetLocalColumns = (page: SetLocalSessionArgs['pageType']): void => {
    setLocalSession({
      org: id,
      pageType: page,
      args: { columnSizes: undefined } as Options,
    })
  }

  const handleReset = () => {
    pageTypes.forEach((pageType) => {
      resetLocalColumns(pageType)
    }),
      setResetVisible(false)
  }

  const ctcaasEndpoint = process.env.REACT_APP_CTCAAS_ENDPOINT ?? ''
  const audience = ctcaasEndpoint.substring(0, ctcaasEndpoint.lastIndexOf('/'))
  const [pattern_form] = useForm()

  function manageSubscription(upgradeSubscription: boolean) {
    const cbInstance = Chargebee.getInstance()

    cbInstance.setPortalSession(() =>
      fetch(`${audience}/chargebee/sso/`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        redirect: 'error',
      }).then((response) => {
        return response.json()
      })
    )

    const cbPortal = cbInstance.createChargebeePortal()
    const visited = []
    const callbacks = {
      visit(where: string) {
        visited.push(where)
      },
      close() {
        // if they clicked on any link to go to a sub-page, we assume they changed something
        if (visited.length > 1) {
          dispatch(GetOrganization(id))
        }
      },
    }
    const options = upgradeSubscription
      ? {
          sectionType: Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
          params: { openSection: true, subscriptionId: subscription_id },
        }
      : null
    cbPortal.open(callbacks, options)
  }

  function OrganizationInformation() {
    return (
      <div className={styles.orgNameContainer}>
        <div>
          <div className={styles.sectionHeaderFirst}>Organization Name</div>
          <div>{name}</div>
        </div>

        <div className={styles.apiContainer}>
          <Label text="API Key Notifications" />
          <Switch
            data-cy="switch"
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(checked) => handleNotifyChange(checked)}
            checked={!!apiKeyExp?.enabled}
            className={styles.switch}
          />
        </div>
      </div>
    )
  }

  function SubscriptionInformation() {
    const daysLeft = subscriptionDaysLeft(subscription_expires_at)

    let expirationNotice = ''
    let upgradeButton = (
      <div className={styles.buttonContainer}>
        <ActionButton type="primary" onClick={() => manageSubscription(true)}>
          Upgrade Subscription
        </ActionButton>
      </div>
    )
    if (daysLeft < 1) {
      expirationNotice = `Your plan has expired!`
    } else if (daysLeft < 15) {
      expirationNotice = `Expires in ${daysLeft} day${daysLeft > 1 ? 's' : ''}`
    } else {
      upgradeButton = <b></b>
    }

    return (
      <div>
        <div className={styles.sectionHeader}>Subscription Plan</div>
        <div>
          {subscription_plan_name}
          {expirationNotice ? <br></br> : <b></b>}
          {expirationNotice}
          {upgradeButton ? <br></br> : <b></b>}
          {upgradeButton}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <PageTitle title="Organization Settings" />

      <div className={styles.content}>
        <OrganizationInformation />
        {subscription_plan_name && <SubscriptionInformation />}

        <div className={styles.accountInformationHeader}>Account Management</div>
        <div>
          Click &quot;Manage Account&quot; to change:
          <ul>
            <li>Organization Name</li>
            <li>Billing Contact</li>
            <li>Payment Method</li>
            <li>Subscription Plan</li>
          </ul>
          <div className={styles.accountInformation}>
            {!subscription_plan_name && (
              <div className={styles.error}>
                Subscription management cannot be accessed at this time.
              </div>
            )}
            <Button
              disabled={!subscription_plan_name}
              type="link"
              onClick={() => manageSubscription(false)}
            >
              Manage Account
            </Button>
          </div>
        </div>

        <div className={styles.namePatternContainer}>
          <EditProjectPatternForm form={pattern_form} organization={organization!} />
        </div>
        <DeleteConfirmModal
          noConfig
          plural
          closeModal={() => setResetVisible(false)}
          visible={resetVisible}
          onDelete={handleReset}
          subject="column sizes"
          actionWord="reset"
        />
        <div className={styles.namePatternContainer}>
          <ActionButton onClick={() => setResetVisible(true)} danger>
            Reset Column Sizes
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

import { Grant, RoleEnum } from 'gen/cloudTruthRestApi'

import { idFromUrl } from 'data/dataUtils'
import { makeTitleCase } from 'lib/valueHelpers'

// hash that indexes grants by principle id
export const grantHash = (scopedGrants: Grant[]): Record<string, Grant> => {
  const hash: Record<string, Grant> = {}

  scopedGrants.forEach((grant) => {
    const id = idFromUrl(grant.principal)!
    hash[id] = grant
  })

  return hash
}

export const formatRole = (role: RoleEnum): string =>
  role === 'NO_SECRETS'
    ? 'NoSecretsViewer'
    : makeTitleCase(role === 'CONTRIB' ? 'contributor' : role!)

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, LabeledInputItem, TextArea } from 'components/Forms'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { CheckboxItem } from 'components/Forms/CheckboxItem'
import { FormInstance } from 'antd'
import { ProjectSelect } from './ProjectSelect'
import React from 'react'

interface Props {
  form: FormInstance
  onFinish: (formData: FormData) => void
}

export function AddProjectForm(props: Props) {
  const { form, onFinish } = props
  const projects = projectEntitySelectors.selectAll(useSelectProjects())

  const nameValidator = (_: any, value: string) => {
    if (value && projects.find((p) => p.name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('Project with this name already exists')
    }

    return Promise.resolve({})
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <InlineLabelInput
        autoFocus
        name="name"
        label="Project Name"
        required="Please enter a project name."
        rules={[{ validator: nameValidator }]}
      />
      <InlineLabelInput name="parameter_name_pattern" label="Parameter Name Pattern" />
      <TextArea name="description" label="Description" />

      <LabeledInputItem
        name="parent"
        label={{
          text: 'Parent Project',
        }}
        tooltipText="Adding a parent project dependency will inherit the parents config"
      >
        <ProjectSelect onChange={(projectName) => form.setFieldsValue({ parent: projectName })} />
      </LabeledInputItem>

      <CheckboxItem
        name="copy_rbac"
        text="Copy RBAC From Parent"
        style={{ marginBottom: '24px' }}
      />
    </Form>
  )
}

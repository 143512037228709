//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { GroupRootState, groupAdapter } from './reducer'

import { Group } from 'gen/cloudTruthRestApi'
import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelectState } from '../hooks'

const selectGroup = (state: RootState) => state.group

export const getCurrentGroup = createSelector(selectGroup, (state): nullable<Group> => {
  return state.current ? state.entities[state.current]! : null
})

export const groupEntitySelectors = groupAdapter.getSelectors()
export const useSelectGroups = (): GroupRootState => useSelectState().group

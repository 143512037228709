export const emptyParametersMessage = (
  searchTerm: string,
  valueSourceFilters: string[],
  error: boolean
): string => {
  if (searchTerm) {
    return `There were no parameters found for search term "${searchTerm}".`
  }

  if (valueSourceFilters.length > 0) {
    return 'There were no parameters found for the selected value source filter.'
  }

  if (error) {
    return 'Unable to retrieve parameters from the server at this time.'
  }

  return 'You do not have any parameters stored in this project. Add parameter or secret to store in CloudTruth'
}

export const defaultOrder = (sortBy: nullable<string>, columnKey?: string): option<any> => {
  if (sortBy) {
    const keyFromParams = sortBy.split(' ')
    const [key, order] = keyFromParams

    if (key === columnKey) {
      return order as any
    }
  }
}

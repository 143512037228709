import { Button, Form, Input, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import React from 'react'

interface Props {
  resourceTags?: Record<string, string>[]
}

export function AddResourceTags(props: Props) {
  const { resourceTags } = props

  const fieldVariants =
    resourceTags?.map((tag, index) => ({
      key: index,
      name: index,
      ...tag,
    })) || []

  return (
    <Form.List name="resource-tags" initialValue={resourceTags}>
      {(fields = fieldVariants, { remove, add }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <Form.Item
                {...restField}
                name={[name, 'key']}
                rules={[{ required: true, message: 'Missing key' }]}
              >
                <Input placeholder="Key" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'value']}
                rules={[{ required: true, message: 'Missing value' }]}
              >
                <Input placeholder="Value" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Resource Tag
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

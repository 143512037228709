import { ActionButton } from 'components/ActionButton'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import styles from './NoDeleteNoticeModal.module.scss'

interface Props {
  closeModal: () => void
  visible: boolean
  errorMessage: string
}

export function NoDeleteNoticeModal(props: Props) {
  const { closeModal, visible, errorMessage } = props

  return (
    <Modal className={styles.modal} closable={false} open={visible} footer={null} destroyOnClose>
      <div className={styles.container}>
        <ExclamationCircleOutlined className={styles.warningIcon} />
        <div className={styles.bodyContainer}>
          <h3>This parameter can not be deleted.</h3>
          <p>{errorMessage}</p>
        </div>

        <ActionButton type="primary" onClick={closeModal}>
          Okay
        </ActionButton>
      </div>
    </Modal>
  )
}

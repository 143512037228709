//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//
import { Form, FormData, InlineLabelInput, LabeledInputItem, TextArea } from 'components/Forms'
import React, { useState } from 'react'
import { parameterEntitySelectors, useSelectParameters } from 'data/parameter/selectors'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'

import { CopyParameterPreview } from './CopyParameterPreview'
import { FormInstance } from 'antd'
import { Parameter } from 'gen/cloudTruthRestApi'
import { ProjectSelect } from 'components/Projects/ProjectSelect'

interface Props {
  form: FormInstance
  steps: number
  handleSubmit: (formData: FormData) => void
  parameter?: Parameter
  isOverride?: boolean
  visibleFromProps?: boolean
}

export function CopyParameterForm(props: Props) {
  const { form, handleSubmit, parameter, isOverride, steps } = props
  const parameters = parameterEntitySelectors.selectAll(useSelectParameters())
  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const parent = parameter!.project_name

  const nameValidator = (_: any, value: string) => {
    if (value && parameters.find((param) => param.name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('Parameter with this name already exists')
    } else if (
      value &&
      templates.find((template) => template.name.toLowerCase() === value.toLowerCase())
    ) {
      return Promise.reject('Template with this name already exists')
    }

    return Promise.resolve()
  }

  const [projectName, setProjectName] = useState('')

  return (
    <>
      <Form form={form} onFinish={handleSubmit} initialValues={{ parent: parent }}>
        {steps === 0 && (
          <>
            <InlineLabelInput
              disabled={isOverride}
              autoFocus
              name="name"
              label="Name"
              required="Please enter a name."
              rules={isOverride ? [] : [{ validator: nameValidator }]}
            />
            <TextArea
              name="description"
              label="Description"
              placeholder={`Copy of parameter ${parameter?.name}`}
            />
            <LabeledInputItem
              name="parent"
              label={{
                text: 'Parent Project',
              }}
              required
              tooltipText="Copy the parameter under an existing parent project. If blank, the parameter and its config will be created in the current project"
              warningText={
                projectName && projectName.length > 0
                  ? `Warning: The copied parameter will inherit ${projectName}’s configuration overwriting the copied configuration from the original source project`
                  : ''
              }
            >
              <ProjectSelect
                onChange={(projectName) => {
                  form.setFieldsValue({ parent: projectName || parent })
                  setProjectName(projectName)
                }}
              />
            </LabeledInputItem>
          </>
        )}

        {steps === 2 && <CopyParameterPreview form={form} parameter={parameter} />}
      </Form>
    </>
  )
}

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { Alert, Card, FormInstance, FormRule, Row } from 'antd'
import { AwsPush, AwsRegionEnum, AzureKeyVaultPush, Tag } from 'gen/cloudTruthRestApi'
import {
  Form,
  FormData,
  InlineLabelInput,
  Item,
  Label,
  LabeledInputItem,
  TextArea,
} from 'components/Forms'
import React, { useEffect, useState } from 'react'
import { initialMustacheResourceString, pushIds } from '../utils'

import { CheckboxItem } from 'components/Forms/CheckboxItem'
import Editor from 'components/Editor/Editor'
import { IntegrationSelector } from '../ActionsForm/IntegrationSelector'
import { PreviewAddPush } from './PreviewAddPush'
import { ProjectSelector } from './ProjectSelector'
import { RegionCheckboxes } from '../ActionsForm/RegionCheckboxes'
import { ResourceDescription } from './ResourceDescription'
import { ServiceCheckboxes } from '../ActionsForm/ServiceCheckboxes'
import { TagSelector } from './TagSelector'
import formstyles from './PushForm.module.scss'
import { idFromUrl } from 'data/dataUtils'
import styles from '../Import/ImportTable.module.scss'

interface Props {
  form: FormInstance
  steps: number
  handleSubmit: (formData: FormData) => void
  push?: AwsPush | AzureKeyVaultPush
  editing?: boolean
  actionType: ActionPushEnum
  formError?: nullable<string>
  setFormError?: (error: nullable<string>) => void
}

const TOOLTIP_COERCE_PARAMETERS =
  '(This option is only available if parameters or templates are included.)  Allows parameters (non-secrets) to be pushed to a destination that only supports storing secrets.  This may increase your overall cost from the cloud provider as some cloud providers charge a premium for secrets-only storage.'
const TOOLTIP_INCLUDE_PARAMETERS =
  'Include parameters (non-secrets) in the values being pushed.  This setting requires the destination to support parameters or for the `coerce_parameters` flag to be enabled, otherwise the push will fail.'
const TOOLTIP_INCLUDE_SECRETS =
  'Include secrets in the values being pushed.  This setting requires the destination to support secrets, otherwise the push will fail.'
const TOOLTIP_INCLUDE_TEMPLATES = 'Include templates in the values being pushed.'
const TOOLTIP_DRY_RUN =
  'A dry run will simulate what would happen - no changes are made.  We recommend you start with a dry run, examine the result, and then turn off dry run.'
const TOOLTIP_FORCE =
  'Push normally will not overwrite any value it did not place, however a forced push will ignore this safeguard.  This is useful after an import to move management of values to CloudTruth.'
const TOOLTIP_LOCAL =
  'Normally, push will process all parameters including those that flow in from project dependencies.  Declaring a push as local will cause it to only process the parameters defined in the selected projects.'
const TOOLTIP_TAG =
  'Tags are used to select values by environment from the projects included in the push.  You cannot have two tags from the same environment in the same push.'

export function PushForm(props: Props) {
  const { form, steps, handleSubmit, push, editing, actionType, formError, setFormError } = props
  const awsPush = push as AwsPush

  const initialRegion = awsPush?.region ? [awsPush?.region] : []
  const initialService = awsPush?.service ? [awsPush?.service] : []
  const initialResource = push?.resource || initialMustacheResourceString(actionType)
  const initialIntegration = push?.url ? pushIds(push.url, actionType).integrationId : null
  const initialTags = push?.tags ? push.tags.map((tag: Tag['url']) => idFromUrl(tag)) : []
  const initialProjects = push?.projects
    ? push.projects.map((project: string) => idFromUrl(project))
    : []

  const initialDryRun = push?.dry_run ?? true
  const initialForce = push?.force ?? false
  const initialLocal = push?.local ?? false
  const initialCoerceParameters = push?.coerce_parameters ?? false
  const initialIncludeParameters = push?.include_parameters ?? true
  const initialIncludeSecrets = push?.include_secrets ?? true
  const initialIncludeTemplates = push?.include_templates ?? true

  const [tagValues, setTagValues] = useState<string[]>(initialTags)
  const [resource, setResource] = useState<string>(initialResource)
  const [regionValues, setRegionValues] = useState<AwsRegionEnum[]>(initialRegion)
  const [serviceValues, setServiceValues] = useState<string[]>(initialService)
  const [projectValues, setProjectValues] = useState<string[]>(initialProjects)
  const [integration, setIntegration] = useState<nullable<string>>(initialIntegration)
  const [includeParameters, setIncludeParameters] = useState<boolean>(initialIncludeParameters)
  const [includeSecrets, setIncludeSecrets] = useState<boolean>(initialIncludeSecrets)
  const [includeTemplates, setIncludeTemplates] = useState<boolean>(initialIncludeTemplates)

  useEffect(() => {
    form.setFieldsValue({ resource })
  }, [form, resource])

  const includeValidation = (_rule: FormRule, _value: any) => {
    if (includeParameters || includeSecrets || includeTemplates) {
      return Promise.resolve()
    }

    return Promise.reject('A push must include at least one of: Parameters, Secrets, or Templates.')
  }

  return (
    <>
      <Form
        form={form}
        formProps={{
          onFieldsChange: () => {
            if (setFormError) {
              setFormError(null)
            }
          },
        }}
        onFinish={handleSubmit}
        initialValues={{
          resource: initialResource,
          projects: initialProjects,
          integration: initialIntegration,
          service: initialService,
          region: initialRegion,
          name: push?.name,
          dry_run: initialDryRun,
          force: initialForce,
          local: initialLocal,
          description: push?.description,
          tags: initialTags,
          coerce_parameters: initialCoerceParameters,
          include_parameters: initialIncludeParameters,
          include_secrets: initialIncludeSecrets,
          include_templates: initialIncludeTemplates,
        }}
      >
        {steps === 0 && (
          <>
            {formError && (
              <Alert message={formError} type="error" style={{ marginBottom: '10px' }} />
            )}
            <div className={formstyles.optionsContainer}>
              <InlineLabelInput
                autoFocus
                name="name"
                label="Name"
                width="275px"
                required="Please enter a name."
              />
            </div>
            <div className={formstyles.optionsContainerCheckbox}>
              <CheckboxItem name="dry_run" text="Dry Run" tooltipText={TOOLTIP_DRY_RUN} />
              <CheckboxItem name="force" text="Force" tooltipText={TOOLTIP_FORCE} />
              <CheckboxItem name="local" text="Local" tooltipText={TOOLTIP_LOCAL} />
            </div>
            <br />
            <TextArea name="description" label="Description" />

            <LabeledInputItem name="projects" label={{ text: 'Projects' }} required>
              <ProjectSelector values={projectValues} setValues={setProjectValues} form={form} />
            </LabeledInputItem>

            <Item name="include" rules={[{ validator: includeValidation }]}>
              <Card size="small" title="Include">
                <div className={formstyles.includeLabel}>
                  <Label text="At least one type selection is required." />
                </div>

                <Row>
                  <CheckboxItem
                    name="include_parameters"
                    text="Parameters"
                    tooltipText={TOOLTIP_INCLUDE_PARAMETERS}
                    checkboxProps={{
                      onChange: (e) => setIncludeParameters(e.target.checked),
                      style: { marginRight: '10px' },
                    }}
                  />

                  <CheckboxItem
                    name="coerce_parameters"
                    text="Coerce"
                    tooltipText={TOOLTIP_COERCE_PARAMETERS}
                    disabled={!includeParameters && !includeTemplates}
                  />
                </Row>

                <CheckboxItem
                  name="include_templates"
                  checkboxProps={{
                    onChange: (e) => setIncludeTemplates(e.target.checked),
                  }}
                  text="Templates"
                  tooltipText={TOOLTIP_INCLUDE_TEMPLATES}
                />

                <CheckboxItem
                  name="include_secrets"
                  text="Secrets"
                  tooltipText={TOOLTIP_INCLUDE_SECRETS}
                  checkboxProps={{
                    onChange: (e) => setIncludeSecrets(e.target.checked),
                  }}
                />
              </Card>
            </Item>

            <LabeledInputItem
              name="tags"
              label={{ text: 'Tags' }}
              tooltipText={TOOLTIP_TAG}
              required
            >
              <TagSelector values={tagValues} setValues={setTagValues} form={form} />
            </LabeledInputItem>

            <LabeledInputItem name="integration" label={{ text: 'Integration' }} required>
              <IntegrationSelector
                actionType={actionType}
                editing={editing}
                integration={integration}
                selectIntegration={setIntegration}
                form={form}
                clearServices={() => setServiceValues([])}
                clearRegions={() => setRegionValues([])}
              />
            </LabeledInputItem>

            {integration && actionType === ActionEnum.AwsPush && (
              <>
                <LabeledInputItem name="region" label={{ text: 'Region' }} required>
                  <RegionCheckboxes
                    editing={editing}
                    values={regionValues}
                    setValues={setRegionValues}
                    form={form}
                    integration={integration}
                  />
                </LabeledInputItem>

                <LabeledInputItem name="service" label={{ text: 'Service' }} required>
                  <ServiceCheckboxes
                    editing={editing}
                    integration={integration}
                    values={serviceValues}
                    setValues={setServiceValues}
                    form={form}
                  />
                </LabeledInputItem>
              </>
            )}
          </>
        )}

        {steps === 1 && (
          <>
            <p>
              Customize the name of your parameters when pushed: <br />
            </p>

            <LabeledInputItem name="resource" label="Resource Pattern" required>
              <Editor
                className={styles.editor}
                body={resource}
                onChange={setResource}
                mode="resourcePattern"
              />
            </LabeledInputItem>
            <ResourceDescription />
          </>
        )}

        {steps === 2 && <PreviewAddPush form={form} actionType={actionType} />}

        {/* <Steps size="small" current={steps} style={{ marginTop: '20px' }}>
          <Step title="Push info" />
          <Step title="Resource Pattern" />
          <Step title="Preview" />
        </Steps> */}
      </Form>
    </>
  )
}

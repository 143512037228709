import { DownOutlined, LoadingOutlined } from '@ant-design/icons'

import { Dropdown } from 'antd'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import React from 'react'
import { Value } from 'gen/cloudTruthRestApi'
import { conditionalArrayItem } from 'lib/valueHelpers'
import { getInterpolatedSecretStatus } from 'data/parameter/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './VersionValueColumnDropdown.module.scss'
import { useAppSelector } from 'data/hooks'

enum Events {
  ShowSecret = 'showSecret',
  EvaluateRaw = 'evaluateRaw',
}

interface Props {
  toggleSecrets: () => void
  maskedSecret: boolean
  isSecret: option<boolean>
  loading: boolean
  envValue: nullable<Value>
  evaluateRaw: () => void
  showEvaluated?: boolean
}

export function VersionValueColumnDropdown(props: Props) {
  const { toggleSecrets, maskedSecret, isSecret, loading, envValue, evaluateRaw, showEvaluated } =
    props
  const { canContribute } = useAppSelector(getPolicy(null))
  const hasSecret = useAppSelector(getInterpolatedSecretStatus([envValue]))

  const onClick: MenuClickEventHandler = ({ key }) => {
    switch (key) {
      case Events.ShowSecret: {
        toggleSecrets()
        break
      }

      case Events.EvaluateRaw: {
        evaluateRaw()
        break
      }

      default: {
        throw new Error(`Unknown menu item: ${key}`)
      }
    }
  }

  const items = [
    ...conditionalArrayItem(isSecret || hasSecret, [
      {
        key: Events.ShowSecret,
        //  interpolated values cannot be secrets
        disabled: envValue?.interpolated,
        label: maskedSecret ? 'Show' : 'Hide',
      },
    ]),

    ...conditionalArrayItem(!!envValue?.interpolated, [
      {
        key: Events.EvaluateRaw,
        label: !showEvaluated ? 'Evaluate' : 'Show Raw',
      },
    ]),
  ]

  return (
    <>
      {canContribute && envValue && !envValue.external_error && (
        <Dropdown menu={{ items, onClick }} disabled={loading}>
          {loading ? (
            <LoadingOutlined className={styles.icon} />
          ) : (
            <DownOutlined className={styles.icon} />
          )}
        </Dropdown>
      )}
    </>
  )
}

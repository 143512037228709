import React, { useEffect, useState } from 'react'

const FileDragOverlay = () => {
  const [isDragging, setIsDragging] = useState(false)
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, setDragCounter] = useState(0)

  useEffect(() => {
    const handleDragEnter = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter((prevCounter) => prevCounter + 1)
      setIsDragging(true)
    }

    const handleDragOver = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleDragLeave = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter((prevCounter) => {
        const newCounter = prevCounter - 1
        if (newCounter === 0) {
          setIsDragging(false)
        }
        return newCounter
      })
    }

    const handleDrop = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter(0)
      setIsDragging(false)

      const files = e.dataTransfer.files
      if (files && files.length > 0) {
        // Handle the files
      }
    }

    window.addEventListener('dragenter', handleDragEnter)
    window.addEventListener('dragover', handleDragOver)
    window.addEventListener('dragleave', handleDragLeave)
    window.addEventListener('drop', handleDrop)

    return () => {
      window.removeEventListener('dragenter', handleDragEnter)
      window.removeEventListener('dragover', handleDragOver)
      window.removeEventListener('dragleave', handleDragLeave)
      window.removeEventListener('drop', handleDrop)
    }
  }, [])

  return (
    <>
      {isDragging && (
        <div style={overlayStyle}>
          <div style={overlayContentStyle}>Drop your files here</div>
        </div>
      )}
    </>
  )
}

const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
}

const overlayContentStyle: React.CSSProperties = {
  color: '#fff',
  fontSize: '24px',
  textAlign: 'center',
}

export default FileDragOverlay

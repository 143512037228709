import { DeleteConfirmModal, DeleteErrorModal } from 'components/Modals'
import React, { useState } from 'react'
import { useSelectUsers, userEntitySelectors } from 'data/user/selectors'

import { CustomThunk } from 'data/dataUtils'
import { DeleteServiceAccount } from 'data/serviceAccount/actions'
import { ServiceAccount } from 'gen/cloudTruthRestApi'
import { removeOneMembership } from 'data/membership/reducer'
import { removeUser } from 'data/user/reducer'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  serviceAccount: ServiceAccount
  visible: boolean
  onClose: () => void
}

export function RemoveServiceAccount(props: Props) {
  const { serviceAccount, visible, onClose } = props

  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const users = userEntitySelectors.selectEntities(useSelectUsers())

  const [errorMessage, setErrorMessage] = useState<nullable<string>>(null)
  const [loading, setLoading] = useState(false)

  const deleteServiceAccount = () => {
    setLoading(true)

    dispatch(DeleteServiceAccount(serviceAccount.id)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        if (serviceAccount.user.membership_id) {
          dispatch(removeOneMembership(serviceAccount.user.membership_id))
        }
        if (users[serviceAccount.user.id]) {
          dispatch(removeUser(serviceAccount.user.id))
        }

        successToast('API token successfully removed')
      }
    })
  }

  return (
    <>
      <DeleteErrorModal
        subject={`"${serviceAccount.user.name}"` || 'this API token'}
        visible={errorMessage !== null}
        closeModal={() => setErrorMessage(null)}
        errorMessage={errorMessage}
      />

      <DeleteConfirmModal
        loading={loading}
        visible={visible}
        closeModal={onClose}
        onDelete={deleteServiceAccount}
        subject={`"${serviceAccount.user.name}"` || 'this API token'}
        removePronoun={true}
      />
    </>
  )
}

import { Checkbox, FormInstance } from 'antd'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'

import { AwsRegionEnum } from 'gen/cloudTruthRestApi'
import React from 'react'
import styles from './RegionCheckboxes.module.scss'

interface Props {
  values: AwsRegionEnum[]
  setValues: (values: AwsRegionEnum[]) => void
  form: FormInstance
  integration: string
  editing?: boolean
}

export function RegionCheckboxes(props: Props) {
  const { values, setValues, form, integration, editing } = props
  const awsIntegrations = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const regionList = awsIntegrations[integration]?.aws_enabled_regions

  const options = regionList
    ?.map((region) => ({
      label: region,
      value: region,
      key: region,
      disabled: values.length > 0 && !values.includes(region),
    }))
    .sort((first, second) => {
      return first.key.localeCompare(second.key)
    })

  const onChange = (values: any) => {
    setValues(values)
    form.setFieldsValue({ region: values })
  }

  return (
    <div className={styles.container}>
      <Checkbox.Group
        defaultValue={values}
        options={options}
        disabled={!!editing}
        onChange={onChange}
        style={{ flexWrap: 'wrap' }}
      />
    </div>
  )
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { ModeEnum, StateEnum } from 'gen/cloudTruthRestApi'
import { ThunkConfig, integrationIdFromUrl } from 'data/dataUtils'

import { PushRootState as AkvPushState } from './akvPush/reducer'
import { AsyncThunk } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { PullRootState as AwsPullRootState } from './awsImport/reducer'
import { PullRootState as GithubPullRootState } from './githubImport/reducer'
import { PushRootState } from './awsPush/reducer'

// TODO (mwellman17 Jan 11, 2022): This will go away.
//  In the future, we will look to see if the top level object has `state: 'complete'`
export const terminalStates: any = [StateEnum.Success, StateEnum.Failure, 'complete']

export interface ActionIds {
  actionId: string
  integrationId: string
}

interface JobDetails extends ActionIds {
  name: string
}

export interface AsyncJob extends JobDetails {
  type: ActionEnum
  action: AsyncThunk<any, ActionIds, ThunkConfig>
}

export enum ActionEnum {
  AwsPush = 'AWS Push',
  AzureKeyVaultPush = 'Azure Push',
  AzureKeyVaultPull = 'Azure Pull',
  AwsPull = 'AWS Pull',
  GithubPull = 'GitHub Pull',
}

export type ActionPushEnum = ActionEnum.AwsPush | ActionEnum.AzureKeyVaultPush

export const getAsyncJobs = (
  state: PushRootState | AwsPullRootState | GithubPullRootState | AkvPushState
): JobDetails[] => {
  const results: JobDetails[] = []
  Object.values(state.entities).forEach((action) => {
    if (action?.mode !== ModeEnum.Mapped || action.latest_task) {
      if (!terminalStates.includes(action?.latest_task?.state)) {
        results.push({
          integrationId: integrationIdFromUrl(action!.url),
          actionId: action.id,
          name: action.name,
        })
      }
    }
  })
  return results
}

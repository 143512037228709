//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum, ActionPushEnum } from 'data/actions/utils'
import { AwsPushWithTags, getAwsPushesWithTagNames } from 'data/actions/awsPush/selectors'
import {
  AzureKeyVaultPushWithTags,
  getAzureKeyVaultPushesWithTagNames,
} from 'data/actions/akvPush/selectors'
import { Form, TableProps, Typography } from 'antd'
import { GetAwsPush, GetAwsPushes } from 'data/actions/awsPush/actions'
import { GetAzureKeyVaultPush, GetAzureKeyVaultPushes } from 'data/actions/akvPush/actions'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { Table, defaultOrder } from 'components/Table'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { displayStatus, formatAwsPushService, pushIds } from '../utils'
import { environmentEntitySelectors, useSelectEnvironments } from 'data/environment/selectors'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import { idFromUrl, integrationIdFromAkvPushUrl, integrationIdFromAwsPushUrl } from 'data/dataUtils'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionViews } from '../ActionContainer'
import { GetTags } from 'data/environment/actions'
import { Link } from 'react-router-dom'
import { PushActionButtons } from './PushActionButtons'
import { PushDetailsDropdown } from './PushDetails/PushDetailsDropdown'
import { Reload } from '../ActionsReload/Reload'
import { TableSearch } from './TableSearch'
import { TableTitle } from 'components/TableTitle'
import { formatDateTime } from 'lib/dateHelpers'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './PushTable.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

const { Text } = Typography
const { useForm } = Form
interface Props {
  awsIntegrationId?: string
  actionType: ActionPushEnum
}

export const displayLastPush = (lastPush: maybe<string>) => {
  return lastPush ? formatDateTime(lastPush) : 'N/A'
}

export function PushTable(props: Props) {
  const { awsIntegrationId, actionType } = props
  const { canContribute } = useAppSelector(getPolicy(null))
  const { getQuery } = useQuery()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const projects = projectEntitySelectors.selectEntities(useSelectProjects())
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const sortBy = getQuery(QueryParamName.SortBy)
  const awsIntegrations = awsIntegrationEntitySelectors.selectIds(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectIds(useSelectAkvIntegrations())
  const awsEntities = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvEntities = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const cachedAwsIntegrations = useAppSelector((state) => state.awsPush.cachedIntegrations)
  const cachedAkvIntegrations = useAppSelector((state) => state.akvPush.cachedIntegrations)
  const awsPushes = useAppSelector(getAwsPushesWithTagNames(awsIntegrationId))
  const akvPushes = useAppSelector(getAzureKeyVaultPushesWithTagNames())
  const [search, setSearch] = useState('')
  const [form] = useForm()

  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'pushes' })

  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const pushLink = useCallback(
    (pushUrl: string, actionId: string) => {
      switch (actionType) {
        case ActionEnum.AwsPush:
          return `/organization/actions${ActionViews.Push}/aws/${integrationIdFromAwsPushUrl(
            pushUrl
          )}/${actionId}`

        case ActionEnum.AzureKeyVaultPush:
          return `/organization/actions${ActionViews.Push}/akv/${integrationIdFromAkvPushUrl(
            pushUrl
          )}/${actionId}`

        default:
          return ''
      }
    },
    [actionType]
  )

  const pushes: AzureKeyVaultPushWithTags[] | AwsPushWithTags[] = useMemo(() => {
    const filteredPushes = () => {
      switch (actionType) {
        case ActionEnum.AwsPush:
          return awsPushes
        case ActionEnum.AzureKeyVaultPush:
          return akvPushes
        default:
          return []
      }
    }

    return filteredPushes().filter((push) => {
      return push.projects.some((url) =>
        projects[idFromUrl(url)]?.name.toLowerCase().includes(search.toLowerCase())
      )
    })
  }, [actionType, awsPushes, akvPushes, search, projects])

  const loadPush = useCallback(
    (integrationId: string, actionId: string) => {
      setLoading(true)
      switch (actionType) {
        case ActionEnum.AwsPush:
          return dispatch(GetAwsPush({ integrationId: integrationId!, actionId: actionId! })).then(
            () => setLoading(false)
          )

        case ActionEnum.AzureKeyVaultPush:
          return dispatch(
            GetAzureKeyVaultPush({ integrationId: integrationId!, actionId: actionId! })
          ).then(() => setLoading(false))

        default:
          break
      }
    },
    [dispatch, actionType]
  )

  const reloadPushes = useCallback(() => {
    setLoading(true)

    switch (actionType) {
      case ActionEnum.AwsPush: {
        const getAwsPushes = awsIntegrations.map((id) => {
          return new Promise((resolve) =>
            dispatch(GetAwsPushes(id.toString())).then(() => {
              resolve({})
            })
          )
        })

        Promise.all(getAwsPushes).then(() => {
          setLoading(false)
        })

        break
      }

      case ActionEnum.AzureKeyVaultPush: {
        const getAkvPushes = akvIntegrations.map((id) => {
          return new Promise((resolve) =>
            dispatch(GetAzureKeyVaultPushes(id.toString())).then(() => {
              resolve({})
            })
          )
        })

        Promise.all(getAkvPushes).then(() => {
          setLoading(false)
        })
        break
      }

      default:
        break
    }
  }, [awsIntegrations, dispatch, actionType, akvIntegrations])

  const getInitialPushes = useCallback(
    (actionType: ActionPushEnum) => {
      setLoading(true)

      const getTags = environments.map((env) => {
        return new Promise((resolve) =>
          dispatch(GetTags(env.id)).then(() => {
            resolve({})
          })
        )
      })

      const getAwsPushes = (): Promise<unknown>[] => {
        // if there's an awsIntegrationId that means it only needs pushes for that integration
        if (awsIntegrationId) {
          // if cached, don't return any promises
          if (cachedAwsIntegrations.includes(awsIntegrationId)) {
            return []
          } else {
            // return promises for that specific integration
            return [
              new Promise((resolve) =>
                dispatch(GetAwsPushes(awsIntegrationId)).then(() => {
                  resolve({})
                })
              ),
            ]
          }
          // if no awsIntegrationId, retreive pushes for every aws integration
        } else {
          const promises = awsIntegrations
            .filter((id) => !cachedAwsIntegrations.includes(id.toString()))
            .map((id) => {
              return new Promise((resolve) =>
                dispatch(GetAwsPushes(id.toString())).then(() => {
                  resolve({})
                })
              )
            })

          return promises
        }
      }

      const getAkvPushes = akvIntegrations
        .filter((id) => !cachedAkvIntegrations.includes(id.toString()))
        .map((id) => {
          return new Promise((resolve) =>
            dispatch(GetAzureKeyVaultPushes(id.toString())).then(() => {
              resolve({})
            })
          )
        })

      switch (actionType) {
        case ActionEnum.AwsPush:
          Promise.all([...getTags, ...getAwsPushes()]).then(() => {
            setLoading(false)
          })

          break

        case ActionEnum.AzureKeyVaultPush:
          Promise.all([...getTags, ...getAkvPushes]).then(() => {
            setLoading(false)
          })
          break

        default:
          break
      }
    },
    [
      akvIntegrations,
      awsIntegrationId,
      awsIntegrations,
      cachedAkvIntegrations,
      cachedAwsIntegrations,
      dispatch,
      environments,
    ]
  )

  const displayIntegration = useCallback(
    (url: string) => {
      switch (actionType) {
        case ActionEnum.AwsPush:
          return (
            awsEntities[integrationIdFromAwsPushUrl(url)]?.aws_role_name ||
            awsEntities[integrationIdFromAwsPushUrl(url)]?.name
          )

        case ActionEnum.AzureKeyVaultPush:
          return (
            akvEntities[integrationIdFromAkvPushUrl(url)]?.vault_name ||
            akvEntities[integrationIdFromAkvPushUrl(url)]?.name
          )

        default:
          return ''
      }
    },
    [actionType, akvEntities, awsEntities]
  )

  useEffect(() => {
    getInitialPushes(actionType)
  }, [actionType, getInitialPushes])

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const setLocalPageSize = (size: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'pushes',
      args: { pageSize: size },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const initialColumns: TableProps<AwsPushWithTags>['columns'] = useMemo(() => {
    return [
      {
        title: 'NAME',
        key: 'name',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'name', 200),
        defaultSortOrder: defaultOrder(sortBy, 'name'),
        sorter: (a, b) => {
          const firstName = a!.name.toUpperCase()
          const secondName = b!.name.toUpperCase()

          return firstName > secondName ? 1 : firstName < secondName ? -1 : 0
        },
        render: (_value, push: AwsPushWithTags) => {
          return (
            <Link
              className={[styles.valueEllipses, styles.link].join(' ')}
              to={pushLink(push.url, push.id)}
            >
              {push.name}
            </Link>
          )
        },
      },

      {
        title: 'TAGS',
        key: 'tags',
        width: storedColumnSize(columnSizes, 'tags', 175),

        render: (_value, push: AwsPushWithTags) => (
          <>
            {push.tagDisplayNames.map((tag, key) => {
              return (
                <Text ellipsis className={styles.tag} key={key}>
                  {tag}
                </Text>
              )
            })}
          </>
        ),
      },

      {
        title: 'PROJECTS',
        key: 'projects',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'project', 175),
        render: (_value: any, push: AwsPushWithTags) => (
          <Text ellipsis className={styles.valueEllipses}>
            {valueOrEmDash(
              push.projects
                .map((url) => projects[idFromUrl(url)]?.name || '')
                .filter((project) => !!project)
                .join(', ')
            )}
          </Text>
        ),
      },

      ...(actionType === ActionEnum.AwsPush
        ? [
            {
              title: 'REGION',
              key: 'region',
              ellipsis: true,
              width: storedColumnSize(columnSizes, 'region', 125),
              render: (_: any, push: AwsPushWithTags) => (
                <Text ellipsis className={styles.valueEllipses}>
                  {push.region}
                </Text>
              ),
            },
          ]
        : []),

      ...(actionType === ActionEnum.AwsPush
        ? [
            {
              title: 'SERVICE',
              key: 'service',
              ellipsis: true,
              width: storedColumnSize(columnSizes, 'service', 175),
              render: (_: any, push: AwsPushWithTags) => (
                <Text ellipsis className={styles.valueEllipses}>
                  {formatAwsPushService(push.service)}
                </Text>
              ),
            },
          ]
        : []),
      {
        title: 'STORE',
        key: 'url',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'url', 175),
        render: (_value: any, push: AwsPushWithTags) => (
          <Text ellipsis className={styles.valueEllipses}>
            {displayIntegration(push.url)}
          </Text>
        ),
      },
      {
        title: 'STATUS',
        key: 'status',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'status', 100),
        render: (_value, push: AwsPushWithTags) => (
          <div className={styles.valueEllipses}>{displayStatus(push.latest_task?.state)}</div>
        ),
      },
      {
        title: 'LAST PUSH TIME',
        key: 'last_push',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'last_push', 210),
        render: (_value, push: AwsPushWithTags) => (
          <div className={styles.valueEllipses}>
            {displayLastPush(push.latest_task?.modified_at)}
          </div>
        ),
      },
      {
        title: '',
        width: 65,
        render: (_, push: AwsPushWithTags) => {
          const { integrationId, actionId } = pushIds(push.url, actionType)
          return canContribute ? (
            <PushDetailsDropdown
              actionType={actionType}
              push={push}
              color="black"
              refreshPush={() => loadPush(integrationId, actionId)}
            />
          ) : null
        },
      },
    ]
  }, [
    projects,
    sortBy,
    canContribute,
    loadPush,
    actionType,
    pushLink,
    columnSizes,
    displayIntegration,
  ])

  const [columns, setColumns] = useState(initialColumns)

  useCallback(() => {
    loading && setColumns(initialColumns)
  }, [loading, initialColumns])

  return (
    <>
      <div className={styles.searchContainer}>
        <div>
          <TableSearch
            defaultValue={search}
            updateSearchTerm={(searchTerm: string) => {
              setSearch(searchTerm), setPage(1)
            }}
            form={form}
            placeholder="Search By Project"
          />
        </div>
      </div>
      <Table
        title={() => (
          <TableTitle
            title="Pushes"
            actionButton={
              <>
                {!loading && (
                  <div className={styles.buttonContainer}>
                    <Reload
                      loading={loading}
                      reload={() => reloadPushes()}
                      listLength={pushes.length}
                    />{' '}
                    {!awsIntegrationId && canContribute && (
                      <PushActionButtons actionType={actionType} />
                    )}
                  </div>
                )}
              </>
            }
          />
        )}
        columns={
          !loading
            ? resizableColumns(columns, setColumns, currentOrganization.id, 'pushes')
            : undefined
        }
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: pushes.length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : pushes}
        rowKey={(push: AwsPushWithTags) => push.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>
                    {`You do not have any pushes stored in this ${
                      awsIntegrationId ? 'integration' : 'organization'
                    }.`}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </>
  )
}

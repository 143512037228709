//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//
import React, { useMemo } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { TreeSelect } from 'antd'
import { buildProjectTree } from './utils'

interface Props {
  onChange: (projectName: string) => void
}

export function ProjectSelect(props: Props) {
  const { onChange } = props
  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const projectTree = useMemo(() => {
    return buildProjectTree(projectEntities, 'project-select')
  }, [projectEntities])

  return (
    <TreeSelect
      data-cy="project-select"
      treeData={projectTree}
      treeDefaultExpandAll
      allowClear
      treeLine={{ showLeafIcon: false }}
      placeholder="Select a parent project"
      onChange={(value) => onChange(projectEntities[value]?.name || '')}
    />
  )
}

//
// Copyright (C) 2020 CloudTruth, Inc.
// All Rights Reserved
//

import { Alert, AlertProps, Drawer as AntDrawer, Button } from 'antd'
import {
  BookOutlined,
  FundOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  WarningFilled,
} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'

import { Drawer } from 'components/Drawer/Drawer'
import { Gpt } from 'components/GPT/Gpt'
import { HeaderActions } from './HeaderActions'
import { HeaderLink } from './HeaderLink'
import OpenAI from 'openai'
import { Organization } from 'gen/cloudTruthRestApi'
import { OrganizationPaths } from 'router/OrganizationRoutes'
import linkStyles from './HeaderLink.module.scss'
import styles from './Header.module.scss'
import { subscriptionDaysLeft } from 'lib/dateHelpers'
import { useHistoryPush } from 'router/customHooks'

interface Props {
  organization?: nullable<Organization>
}

interface SubscriptionExpiresProps {
  daysLeft: number
  planName: string
}

function SubscriptionExpiresWarning(props: SubscriptionExpiresProps) {
  const { daysLeft, planName } = props

  const { goToOrgRoute } = useHistoryPush()

  let message: string = ''
  let msgtype: AlertProps['type'] = 'warning'

  if (daysLeft < 1) {
    message = `Your ${planName} plan has expired.`
    msgtype = 'error'
  } else if (daysLeft < 15) {
    message = `Your ${planName} plan expires in ${daysLeft} day${daysLeft > 1 ? 's' : ''}.`
  }

  if (message) {
    return (
      <Alert
        message={message}
        type={msgtype}
        className={styles.subscriptionWarning}
        action={
          <Button
            size="small"
            type="link"
            className={styles.subscriptionSettingsLink}
            onClick={() => goToOrgRoute(OrganizationPaths.Settings)}
          >
            Upgrade Subscription
          </Button>
        }
        banner
      />
    )
  }

  // otherwise return something that renders nothing
  return <b></b>
}

export function Header(props: Props) {
  const { organization } = props
  const {
    name: orgName,
    subscription_expires_at,
    subscription_plan_name,
    copilot_enabled,
  } = organization || {}
  const { goToOrgRoute } = useHistoryPush()
  const showCopilot = copilot_enabled
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [chatDrawerOpen, setChatDrawerOpen] = React.useState(false)
  const [assistants, setAssistants] = useState<nullable<OpenAI.Beta.Assistant[]>>(null)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [gptType, _] = React.useState(0)
  const isMaintenanceMode = organization?.maintenance
  const maintenanceMessage: string =
    'Your organization is in maintenance mode. Only view/get operations are allowed.'

  const organizationNameClick = () => {
    goToOrgRoute(OrganizationPaths.Dashboard)
  }

  const daysLeft = subscriptionDaysLeft(subscription_expires_at)

  const onOpenChange = () => {
    setChatDrawerOpen((prev) => !prev)
  }

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  useEffect(() => {
    // Function to update the viewport height
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // const GptSwitch = () => (
  //   <Radio.Group value={gptType} onChange={(e) => setGptType(e.target.value)}>
  //     <Radio.Button value={0}>GPT 4</Radio.Button>
  //     <Radio.Button value={1}>GPT 3.5</Radio.Button>
  //   </Radio.Group>
  // )

  return (
    <div>
      <div className={styles.container}>
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
          <h2 className={styles.orgName} onClick={organizationNameClick}>
            {orgName}
          </h2>

          <HeaderActions />
        </div>

        <SubscriptionExpiresWarning planName={subscription_plan_name || ''} daysLeft={daysLeft} />
        <div className={styles.menuContainer}>
          <HeaderLink
            onClick={() => setDrawerOpen(true)}
            icon={<NotificationOutlined style={{ fontSize: '20px' }} className={linkStyles.icon} />}
            tooltip="Activity"
          />
          <HeaderLink
            href="https://docs.cloudtruth.com/reference/roadmap"
            icon={<FundOutlined className={linkStyles.icon} style={{ fontSize: '20px' }} />}
            tooltip="Roadmap"
          />

          <HeaderLink
            text="Learn"
            href="https://docs.cloudtruth.com/learn"
            icon={<BookOutlined className={linkStyles.icon} />}
          />

          <HeaderLink
            text="Help"
            href="https://docs.cloudtruth.com"
            icon={<QuestionCircleOutlined className={linkStyles.icon} />}
          />

          {showCopilot && <HeaderLink onClick={onOpenChange} text="Copilot (Beta)" />}
          <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
          <AntDrawer
            open={chatDrawerOpen}
            placement="bottom"
            onClose={() => setChatDrawerOpen(false)}
            height={`${viewportHeight - 70}px`}
            rootClassName={styles.drawer}
            headerStyle={{ display: 'none' }}
            // title={<>{assistants && assistants?.length > 0 && <GptSwitch />}</>}
          >
            <Gpt
              gptType={gptType}
              assistants={assistants}
              setAssistants={setAssistants}
              setOpen={setChatDrawerOpen}
            />
          </AntDrawer>
        </div>
      </div>

      {isMaintenanceMode && (
        <div className={styles.subscriptionWarning}>
          <WarningFilled className={styles.warningIcon} />{' '}
          <p className={styles.warning}>{maintenanceMessage}</p>
        </div>
      )}
    </div>
  )
}

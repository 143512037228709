//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { AwsPull, AzureKeyVaultPull } from 'gen/cloudTruthRestApi'
import React, { useState } from 'react'

import { ActionEnum } from 'data/actions/utils'
import { EditModal } from 'components/Modals/EditModal'
import { ImportForm } from './ImportForm'
import { ModalStepsFooter } from 'components/Actions/ActionsForm/ModalStepsFooter'
import { TypedThunk } from 'data/dataUtils'
import { UpdateAwsPull } from 'data/actions/awsImport/actions'
import { UpdateAzureKeyVaultPull } from 'data/actions/akvImport/actions'
import { useAppDispatch } from 'data/hooks'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  pull: AwsPull | AzureKeyVaultPull
  visible: boolean
  onClose: () => void
  actionType: ActionEnum
}

export function EditImport(props: Props) {
  const { pull, visible, onClose, actionType } = props
  const [loading, setLoading] = useState(false)
  const [steps, setSteps] = useState(0)
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()
  const [form] = useForm()

  const onOk = () => {
    form.submit()
    if (steps === 2) {
      form.submit()
    }
    if (steps < 2) {
      form.validateFields().then(() => setSteps((steps) => (steps += 1)))
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 1))
    }
  }

  const onCancel = () => {
    onClose()
    setSteps(0)
    form.resetFields()
  }

  const handleSubmit = (dry_run: boolean) => {
    setLoading(true)
    // Values aren't coming in with FormData, so they have to be fetched manually
    // It might have something to do with form components mounting and unmounting
    // due to steps

    const integrationId = form.getFieldValue('integration')
    const region = form.getFieldValue('region') ? form.getFieldValue('region')[0] : null
    const service = form.getFieldValue('service') ? form.getFieldValue('service')[0] : null
    const resource = form.getFieldValue('resource')
    const name = form.getFieldValue('name')
    const description = form.getFieldValue('description')
    const create_environments = form.getFieldValue('createEnvironments')
    const create_projects = form.getFieldValue('createProjects')

    const pullObj = {
      resource,
      description,
      name,
      id: pull.id,
      dry_run,
      create_projects,
      create_environments,
    }

    switch (actionType) {
      case ActionEnum.AwsPull:
        dispatch(
          UpdateAwsPull({ pull: { ...pullObj, service, region }, awsIntegrationId: integrationId })
        ).then(({ error }: TypedThunk<AwsPull>) => {
          if (error) {
            errorToast(error.message)
          } else {
            successToast('Action successfully updated')
            onCancel()
          }

          setLoading(false)
        })

        break

      case ActionEnum.AzureKeyVaultPull:
        dispatch(UpdateAzureKeyVaultPull({ pull: pullObj, akvIntegrationId: integrationId })).then(
          ({ error }: TypedThunk<AwsPull>) => {
            if (error) {
              errorToast(error.message)
            } else {
              successToast('Action successfully updated')
              onCancel()
            }

            setLoading(false)
          }
        )

        break

      default:
        break
    }
  }

  return (
    <>
      <EditModal
        pending={loading}
        visible={visible}
        width="1000px"
        objectName="Import"
        afterClose={() => form.resetFields()}
        onCancel={onCancel}
        onOk={() => form.submit()}
        footer={
          <ModalStepsFooter
            objectName="Import"
            editing
            onCancel={onCancel}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={loading}
            onDryRun={() => handleSubmit(true)}
          />
        }
      >
        <ImportForm
          actionType={actionType}
          form={form}
          handleSubmit={() => handleSubmit(false)}
          pull={pull}
          steps={steps}
          editing
        />
      </EditModal>
    </>
  )
}

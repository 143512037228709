import React, { useCallback, useMemo, useState } from 'react'

import { CopyCreateEnvironment } from 'data/environment/actions'
import { CopyEnvironmentForm } from './CopyEnvironmentForm'
import { CopyModal } from 'components/Modals/CopyModal'
import { CustomThunk } from 'data/dataUtils'
import { Environment } from 'gen/cloudTruthRestApi'
import { ModalStepsFooter } from 'components/Actions/ActionsForm/ModalStepsFooter'
import { useAppDispatch } from 'data/hooks'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

interface Props {
  visibleFromProps?: boolean
  environment: Environment
  closeModal: () => void
  getEnvironments: () => void
}

export function CopyEnvironment(props: Props) {
  const { environment, closeModal, visibleFromProps, getEnvironments } = props

  const [visible, setVisible] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [steps, setSteps] = useState<number>(0)

  const [form] = useForm()
  const dispatch = useAppDispatch()
  const { errorToast, successToast } = useToast()

  const modalVisible = useMemo(() => {
    return visibleFromProps || visible
  }, [visibleFromProps, visible])

  // eslint-disable-next-line
  const onCancel = () => {
    form.resetFields()
    closeModal()
    setSteps(0)
    setVisible(false)
  }

  const closeModalCancel = useCallback(() => {
    closeModal ? (closeModal(), onCancel()) : onCancel()
  }, [closeModal, onCancel])

  const onOk = () => {
    form.submit()
    if (steps === 2) {
      form.submit()
    }
    if (steps < 1) {
      form.validateFields().then(() => setSteps((steps) => (steps += 2)))
    }
  }

  const onPrevious = () => {
    if (steps > 0) {
      setSteps((steps) => (steps -= 2))
    }
  }

  const onFinish = () => {
    const name = form.getFieldValue('name')
    const description = form.getFieldValue('description') || `Copy of ${environment.name}`
    const recursive = form.getFieldValue('recursive') || false

    setPending(true)

    dispatch(
      CopyCreateEnvironment({
        envId: environment.id,
        params: {
          name: name,
          description: description,
          recursive: recursive,
        },
      })
    ).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        successToast('Environment successfully copied')
        setVisible(false)
        setPending(false)
        onCancel()
        if (recursive) {
          getEnvironments()
        }
      }
    })
  }

  return (
    <>
      <CopyModal
        visible={modalVisible}
        objectName={`Environment: ${environment?.name}`}
        onCancel={closeModalCancel}
        onOk={() => form.submit()}
        pending={pending}
        footer={
          <ModalStepsFooter
            objectName="Environment"
            onCancel={closeModalCancel}
            onOk={onOk}
            steps={steps}
            onPrevious={onPrevious}
            pending={pending}
            isCopy={true}
          />
        }
      >
        <CopyEnvironmentForm
          form={form}
          environment={environment}
          steps={steps}
          onFinish={onFinish}
        />
      </CopyModal>
    </>
  )
}

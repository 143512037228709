import {
  FeatureGateContext,
  ContextValue as FeatureGateContextValue,
} from 'contexts/FeatureGateContext'
import { ToastContext, ContextValue as ToastContextValue } from 'contexts/ToastContext'
import {
  ViewportHeightContext,
  ContextValue as ViewportHeightContextValue,
} from 'contexts/ViewportHeight'
import { useContext, useEffect, useState } from 'react'

import { getLocalTemplateBody } from 'components/Templates/utils'

export function useToast(): ToastContextValue {
  return useContext(ToastContext)
}

export function useFeatureGate(): FeatureGateContextValue {
  return useContext(FeatureGateContext)
}

export function useViewportHeight(): ViewportHeightContextValue {
  return useContext(ViewportHeightContext)
}

export function useLocalTemplateBody(name: string, projectId: string): nullable<string> {
  const [template, setTemplate] = useState(getLocalTemplateBody(name, projectId))

  useEffect(() => {
    function handleChangeStorage() {
      setTemplate(getLocalTemplateBody(name, projectId))
    }

    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [name, projectId])

  return template
}

//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import { FormInstance, TreeSelect } from 'antd'
import React, { useMemo } from 'react'
import { projectEntitySelectors, useSelectProjects } from 'data/project/selectors'

import { buildProjectTree } from 'components/Projects/utils'

const { SHOW_ALL } = TreeSelect

interface Props {
  values: string[]
  setValues: (values: string[]) => void
  form: FormInstance
}

export function ProjectSelector(props: Props) {
  const { values, setValues, form } = props

  const projectEntities = projectEntitySelectors.selectEntities(useSelectProjects())

  const onChange = (values: string[]) => {
    setValues(values)
    form.setFieldsValue({ projects: values })
  }

  const projectTree = useMemo(() => {
    return buildProjectTree(projectEntities)
  }, [projectEntities])

  return (
    <TreeSelect
      treeData={projectTree}
      value={values}
      onChange={onChange}
      treeDefaultExpandAll={true}
      showCheckedStrategy={SHOW_ALL}
      multiple
      placeholder="Please select"
    />
  )
}

import { Checkbox, Modal } from 'antd'

import { ActionButton } from '../ActionButton'
import { LabeledItem } from 'components/Forms'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons/lib'
import styles from './ShowDifferencesConfirmModal.module.scss'

interface Props {
  onCancel: () => void
  onOk: () => void
  visible: boolean
  loading?: boolean
  setDisableEvaluatedModal?: (disableEvaluatedModal: boolean) => void
}

export function ShowEvaluateConfirmModal(props: Props) {
  const { visible, onCancel, onOk, loading, setDisableEvaluatedModal } = props

  return (
    <Modal footer={null} open={visible} closable={false} width="550px" destroyOnClose>
      <div className={styles.container}>
        <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
        <h2>
          Evaluating dynamic parameter values could take a few minutes, depending on how many
          parameters use interpolation and whether the values are inherited from other projects.{' '}
        </h2>

        <div>
          <LabeledItem label="Don't show message again" marginTop="18px" marginBottom="0">
            <Checkbox
              disabled={loading}
              onChange={() => {
                setDisableEvaluatedModal && setDisableEvaluatedModal(true)
              }}
            />
          </LabeledItem>
        </div>

        <div className={styles.buttonContainer}>
          <ActionButton onClick={onCancel}>Cancel</ActionButton>
          <ActionButton type="primary" danger onClick={onOk} loading={loading}>
            Go ahead and Evaluate
          </ActionButton>
        </div>
      </div>
    </Modal>
  )
}

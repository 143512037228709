//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */

import {
  Environment,
  PaginatedParameterList,
  PaginatedValueList,
  Parameter,
  ParameterCopy,
  ParameterCreate,
  ParameterDuality,
  ParameterRule,
  ParameterRuleCreate,
  PatchedParameterRuleUpdate,
  PatchedParameterUpdate,
  PatchedValueUpdate,
  ProjectsParametersDualityListParams,
  ProjectsParametersListParams,
  Value,
  ValueCreate,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, buildQuery, handleFetch, projectId } from 'data/dataUtils'

import { Dayjs } from 'dayjs'
import { StringDecoder } from 'string_decoder'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface DeleteValueReq {
  valueId: string
  paramId: string
  envUrl: string
  environments: Environment[]
  dontUpdateCache?: boolean
}

interface DeleteRuleReq {
  ruleId: string
  paramId: string
}

interface GetParameterObj {
  projectId: string
  paramId: string
  maskSecret: boolean
  environment?: string
  evaluate?: boolean
}

interface GetVersionedParameters {
  projectId: string
  timestamp: Dayjs | string
}

export interface GetPaginatedVersionedReq {
  projectId: string
  params: ProjectsParametersDualityListParams
}

interface GetValueObj {
  projectId: string
  paramId: string
  valueId: string
  maskSecret: boolean
  evaluate?: boolean
  environment?: string
}

interface GetValuesObj {
  projectId: string
  paramId: string
  maskSecret: boolean
  evaluate?: boolean
  environment: string
  envUrl: string
}

interface GetVersionedObj extends GetValueObj {
  timestamp: string
}

export interface CreateParameterReq extends ParameterCreate {
  values: Parameter['values']
  isOverride?: boolean
  currentProject?: StringDecoder
  project?: string
}

export interface CreateValueReq extends ValueCreate {
  paramId: string
  envUrl: string
  maskSecrets?: boolean
  environments: Environment[]
}

export interface PatchValueReq {
  paramId: string
  valueId: string
  value: PatchedValueUpdate
  envUrl: string
  environments: Environment[]
  maskSecrets?: boolean
}

export interface CreateRuleReq extends ParameterRuleCreate {
  paramId: string
}

export interface PatchRuleReq {
  paramId: string
  ruleId: string
  rule: PatchedParameterRuleUpdate
}

export interface ParameterRuleType extends ParameterRuleCreate {
  id: string
}

export interface ValueQuery {
  evaluate?: boolean
  mask_secrets?: boolean
}

export interface GetPaginatedParametersReq {
  projectId: string
  params: Partial<ProjectsParametersListParams>
  evaluate?: boolean
}
export interface GetPaginatedHistoryReq {
  projectId: string
  params: Partial<ProjectsParametersDualityListParams>
}

export interface UpdateParameter extends PatchedParameterUpdate {
  maskSecrets?: boolean
}

export interface CopyProjectParameterReq {
  id: string
  params: ParameterCopy
}

/* -------------------------------- IMPORTANT -------------------------------- */
/* Ensure LogRocket Redux Middleware reflect any changes made to these actions */

export const GetParameters = createAsyncThunk<PaginatedParameterList, string, ThunkConfig>(
  'parameter/getParameters',
  async (projectId, thunkApi) =>
    await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters`,
      null,
      thunkApi,
      '?mask_secrets=true'
    )
)

export const GetPaginatedParameters = createAsyncThunk<
  PaginatedParameterList,
  GetPaginatedParametersReq,
  ThunkConfig
>('parameter/getPaginatedParameters', async (req, thunkApi) => {
  return await handleFetch(
    Verbs.Get,
    `projects/${req.projectId}/parameters`,
    null,
    thunkApi,
    buildQuery({
      ...req.params,
      ...{
        mask_secrets:
          typeof req.params?.mask_secrets === 'boolean' ? req.params.mask_secrets : true,
      },
      evaluate: !!req?.evaluate,
    })
  )
})

export const GetParameterNames = createAsyncThunk<PaginatedParameterList, string, ThunkConfig>(
  'parameter/getNoValuesParameters',
  async (projectId, thunkApi) =>
    await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters`,
      null,
      thunkApi,
      '?values=false'
    )
)

export const GetVersionedParameters = createAsyncThunk<
  PaginatedParameterList,
  GetVersionedParameters,
  ThunkConfig
>('parameter/getVersionedParameters', async (getObj, thunkApi) => {
  const { projectId, timestamp } = getObj

  return await handleFetch(
    Verbs.Get,
    `projects/${projectId}/parameters`,
    null,
    thunkApi,
    `?as_of=${timestamp}&mask_secrets=true`
  )
})

export const GetPaginatedVersionedParameters = createAsyncThunk<
  ParameterDuality,
  GetPaginatedHistoryReq,
  ThunkConfig
>('parameter/getPaginatedVersionedParameters', async (req, thunkApi) => {
  return await handleFetch(
    Verbs.Get,
    `projects/${req.projectId}/parameters/duality`,
    null,
    thunkApi,
    buildQuery({
      ...req.params,
      ...{
        mask_secrets:
          typeof req.params?.mask_secrets === 'boolean' ? req.params.mask_secrets : true,
      },
      evaluate: false,
    })
  )
})

export const GetParameter = createAsyncThunk<Parameter, GetParameterObj, ThunkConfig>(
  'parameter/getParameter',
  async (parameter, thunkApi) => {
    const { paramId, projectId, maskSecret, environment, evaluate } = parameter
    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}`,
      null,
      thunkApi,
      buildQuery({ mask_secrets: maskSecret, evaluate: !!evaluate, environment })
    )
  }
)

export const GetInheritedParameter = createAsyncThunk<Parameter, GetParameterObj, ThunkConfig>(
  'parameter/getInheritedParameter',
  async (parameter, thunkApi) => {
    const { paramId, projectId, maskSecret, environment } = parameter
    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}`,
      null,
      thunkApi,
      buildQuery({ mask_secrets: maskSecret, evaluate: false, environment })
    )
  }
)

export const GetParameterTimeline = createAsyncThunk<Parameter, GetParameterObj, ThunkConfig>(
  'parameter/getParameterTimeline',
  async (parameter, thunkApi) => {
    const { paramId, projectId, maskSecret } = parameter
    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}/timeline`,
      null,
      thunkApi,
      `?mask_secrets=${maskSecret}`
    )
  }
)

export const CreateParameter = createAsyncThunk<Parameter, CreateParameterReq, ThunkConfig>(
  'parameter/createParameter',
  async (param, thunkApi) => {
    const pId = param.project ? param.project : projectId(thunkApi.getState)

    return await handleFetch(Verbs.Post, `projects/${pId}/parameters`, param, thunkApi)
  }
)

export const UpdateParameter = createAsyncThunk<Parameter, UpdateParameter, ThunkConfig>(
  'parameter/updateParameter',
  async (param, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Put,
      `projects/${pId}/parameters/${param.id}`,
      param,
      thunkApi,
      `?mask_secrets=${!!param.maskSecrets}`
    )
  }
)

export const CopyProjectParameter = createAsyncThunk<
  Parameter,
  CopyProjectParameterReq,
  ThunkConfig
>('parameter/copyProjectParameter', async (req, thunkApi) => {
  const pId = projectId(thunkApi.getState)
  return await handleFetch(
    Verbs.Post,
    `projects/${pId}/parameters/${req.id}/copy`,
    req.params,
    thunkApi
  )
})

export const DeleteParameter = createAsyncThunk<string, string, ThunkConfig>(
  'parameter/deleteParameter',
  async (id, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(Verbs.Delete, `projects/${pId}/parameters/${id}`, null, thunkApi)
  }
)

export const CreateValue = createAsyncThunk<Value, CreateValueReq, ThunkConfig>(
  'parameter/createValue',
  async (value, thunkApi) => {
    const prodId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Post,
      `projects/${prodId}/parameters/${value.paramId}/values`,
      value,
      thunkApi,
      buildQuery({ mask_secrets: !!value.maskSecrets, evaluate: false })
    )
  }
)

export const UpdateValue = createAsyncThunk<Value, PatchValueReq, ThunkConfig>(
  'parameter/updateValue',
  async (req, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Put,
      `projects/${pId}/parameters/${req.paramId}/values/${req.valueId}`,
      req.value,
      thunkApi,
      buildQuery({ mask_secrets: !!req.maskSecrets, evaluate: false })
    )
  }
)

export const DeleteValue = createAsyncThunk<string, DeleteValueReq, ThunkConfig>(
  'parameter/deleteValue',
  async (req, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Delete,
      `projects/${pId}/parameters/${req.paramId}/values/${req.valueId}`,
      null,
      thunkApi
    )
  }
)

export const GetValue = createAsyncThunk<Value, GetValueObj, ThunkConfig>(
  'parameter/getValue',
  async (valueObj, thunkApi) => {
    const { paramId, projectId, maskSecret, valueId, evaluate, environment } = valueObj

    const query = {
      evaluate: !!evaluate,
      mask_secrets: maskSecret,
      environment,
    }

    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}/values/${valueId}`,
      null,
      thunkApi,
      buildQuery(query)
    )
  }
)

export const GetValues = createAsyncThunk<PaginatedValueList, GetValuesObj, ThunkConfig>(
  'parameter/getValues',
  async (valueObj, thunkApi) => {
    const { paramId, projectId, maskSecret, evaluate, environment } = valueObj

    const query = {
      evaluate: !!evaluate,
      mask_secrets: maskSecret,
      environment,
    }

    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}/values`,
      null,
      thunkApi,
      buildQuery(query)
    )
  }
)

export const GetVersionedValue = createAsyncThunk<Value, GetVersionedObj, ThunkConfig>(
  'parameter/getVersionedValue',
  async (valueObj, thunkApi) => {
    const { paramId, projectId, maskSecret, valueId, timestamp, evaluate } = valueObj

    const query = buildQuery({
      evaluate: !!evaluate,
      mask_secrets: maskSecret,
      as_of: timestamp,
    })

    return await handleFetch(
      Verbs.Get,
      `projects/${projectId}/parameters/${paramId}/values/${valueId}`,
      null,
      thunkApi,
      query
    )
  }
)

export const CreateRule = createAsyncThunk<ParameterRule, CreateRuleReq, ThunkConfig>(
  'parameter/createRule',
  async (rule, thunkApi) => {
    const projId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Post,
      `projects/${projId}/parameters/${rule.paramId}/rules`,
      rule,
      thunkApi
    )
  }
)

export const UpdateRule = createAsyncThunk<ParameterRule, PatchRuleReq, ThunkConfig>(
  'parameter/updateRule',
  async (req, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Put,
      `projects/${pId}/parameters/${req.paramId}/rules/${req.ruleId}`,
      req.rule,
      thunkApi
    )
  }
)

export const DeleteRule = createAsyncThunk<string, DeleteRuleReq, ThunkConfig>(
  'parameter/deleteRule',
  async (req, thunkApi) => {
    const pId = projectId(thunkApi.getState)
    return await handleFetch(
      Verbs.Delete,
      `projects/${pId}/parameters/${req.paramId}/rules/${req.ruleId}`,
      null,
      thunkApi
    )
  }
)

import { Form, FormData, InlineLabelInput, TextArea, useForm } from 'components/Forms'
import React, { useState } from 'react'

import { CustomThunk } from 'data/dataUtils'
import { EditModal } from 'components/Modals/EditModal'
import { Group } from 'gen/cloudTruthRestApi'
import { UpdateGroup } from 'data/group/actions'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

interface Props {
  closeModal: () => void
  visible: boolean
  group: Group
}

export function EditGroupSettings(props: Props) {
  const { closeModal, visible, group } = props
  const { errorToast, successToast } = useToast()
  const dispatch = useAppDispatch()

  const [pending, setPending] = useState<boolean>(false)
  const [form] = useForm()

  const onFinish = async (formData: FormData) => {
    const { description, name } = formData
    setPending(true)

    dispatch(UpdateGroup({ ...group, description, name })).then(({ error }: CustomThunk) => {
      error ? errorToast(error.message) : successToast('Group successfully updated.')

      setPending(false)
      closeModal()
    })
  }

  return (
    <EditModal
      visible={visible}
      onCancel={closeModal}
      onOk={form.submit}
      pending={pending}
      objectName="group"
    >
      <Form
        form={form}
        initialValues={{ name: group.name, description: group.description }}
        onFinish={onFinish}
      >
        <InlineLabelInput autoFocus name="name" label="Name" required="Please enter a name." />
        <TextArea
          autoFocus
          name="description"
          label="Description"
          placeholder="Type description here"
        />
      </Form>
    </EditModal>
  )
}

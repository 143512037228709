//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//
import { CopyText } from '../CopyText'
import { LabelText } from '../LabelText'
import React from 'react'
import styles from './CopySecretText.module.scss'

interface Props {
  label: string
  value: string
  notSecret?: boolean
  tooltip?: string
}

export default function CopySecretText(props: Props) {
  const { label, notSecret, value, tooltip } = props

  return (
    <LabelText
      label={label}
      tooltip={tooltip}
      uppercase
      text={
        <div>
          <span
            id="value"
            data-private={notSecret ? false : 'redact'}
            className={styles.valueDisplay}
          >
            {value}
          </span>
          <CopyText prompt="Copy" payload={value} />
        </div>
      }
    />
  )
}

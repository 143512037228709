//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

/* eslint @typescript-eslint/naming-convention: 0 */

import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getLocalSession, setLocalSession } from 'lib/sessionPersistance'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { Dayjs } from 'dayjs'
import { Form } from 'antd'
import { GetPaginatedVersionedParameters } from 'data/parameter/actions'
import { ParameterDuality } from 'gen/cloudTruthRestApi'
import { ParameterHistoryTable } from './ParameterHistoryTable'
import { ShowDifferencesConfirmModal } from 'components/Modals/ShowDifferencesConfirmModal'
import { TableSearch } from 'components/Table'
import { TimestampSelector } from 'components/Forms/TimestampSelector'
import { ToggleSecrets } from 'components/ToggleSecrets'
import { ToggleText } from 'components/ToggleText'
import { getCurrentEnvironment } from 'data/environment/selectors'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getCurrentProject } from 'data/project/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './ParameterHistory.module.scss'
import { useToast } from 'hooks'

const { useForm } = Form

export const SEARCH_TERM_QUERY_PARAM = 'q'
export const SORT_QUERY_PARAM = 'sort'
export const ENVIRONMENT_ID_QUERY_PARAM = 'env'

export function ParameterHistory() {
  const project = useAppSelector(getCurrentProject)!

  const subscribeToAsyncPulls = useAppSelector((state) => state.session.subscribeToAsyncPulls)
  const { id: currentEnvId } = useAppSelector(getCurrentEnvironment)!
  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'history' })
  const localPageSize = localSession?.pageSize

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const [searchTerm, setSearchTerm] = useState('')
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [fetchError, setFetchError] = useState<boolean>(false)
  const [checkDiff, setCheckDiff] = useState(false)
  const [timestamp, setTimestamp] = useState<nullable<Dayjs>>(null)

  const [parameters, setParameters] = useState<ParameterDuality[]>([])
  const [difference, setDifference] = useState<option<boolean>>()
  const [maskSecret, setMaskSecret] = useState(true)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const { getQuery, removeQuery } = useQuery()
  const { canContribute } = useAppSelector(getPolicy(null))

  const [selectedTimestamp, setSelectedTimestamp] = useState<string | undefined>(undefined)

  const showToggleSecrets = useMemo(() => {
    return parameters.some(
      (parameter) =>
        Object.values(parameter.t1?.values || {}).some((value) => value?.secret) ||
        Object.values(parameter.t2?.values || {}).some((value) => value?.secret)
    )
  }, [parameters])

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return { pageNumber: pageNumber ? parseInt(pageNumber) : 1, project: project.id }
  })

  const removePages = useCallback(() => {
    removeQuery(QueryParamName.Page)
    removeQuery(QueryParamName.PageSize)
  }, [removeQuery])

  const search = (searchTerm: string) => {
    setSearchTerm(searchTerm), setPage((prev) => ({ ...prev, pageNumber: 1 })), removePages()
  }

  const [totalParams, setTotalParams] = useState<option<number>>()

  const [ordering, setOrdering] = useState<option<string>>(() => {
    const query = getQuery(QueryParamName.SortBy)
    if (query?.includes('name')) {
      return query.includes('ascend') ? 'name' : '-name'
    }

    return
  })

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const paginatedParams = useMemo(
    () => ({
      projectId: project.id,
      params: {
        // If the user switches projects, the page should return to 1
        page: page.project !== project.id ? 1 : page.pageNumber,
        page_size: pageSize,
        t1_as_of: timestamp?.toISOString() || '2018-01-27T10:51:17.487Z',
        t2_as_of: undefined,
        name__icontains: searchTerm,
        environment: currentEnvId,
        difference: difference,
        mask_secrets: maskSecret,
        ordering,
      },
    }),
    [
      page,
      timestamp,
      project.id,
      searchTerm,
      ordering,
      maskSecret,
      currentEnvId,
      difference,
      pageSize,
    ]
  )

  // If the user switches projects, the page should return to 1
  useEffect(() => {
    if (page.project !== project.id) {
      setPage({ pageNumber: 1, project: project.id })
      removePages()
    }
  }, [project, page, removePages])

  useEffect(() => {
    if (subscribeToAsyncPulls) {
      dispatch(GetPaginatedVersionedParameters(paginatedParams))
    }
  }, [dispatch, project.id, subscribeToAsyncPulls, paginatedParams])

  /*********** Get parameters by project ********************************* */
  const getParametersByProject = useCallback(() => {
    setLoading(true)
    setFetchError(false)

    dispatch(GetPaginatedVersionedParameters(paginatedParams)).then(
      ({ error, payload }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
          setFetchError(true)
          setLoading(false)
          setParameters([])
          return
        }
        setLoading(false)
        setParameters(payload.results)
        setTotalParams(payload.count)
      }
    )
  }, [dispatch, errorToast, paginatedParams])

  useEffect(() => {
    getParametersByProject()
  }, [getParametersByProject])

  const handleCheckboxChange = (checked: boolean) => {
    if (!checked) {
      setPage((prevState) => ({ ...prevState, pageNumber: 1 }))
      removePages()
      setCheckDiff(false)
      setMaskSecret(true)
      setDifference(undefined)
    } else {
      setConfirmVisible(true)
    }
  }

  const handleSecret = () => {
    setPage((prevState) => ({ ...prevState, pageNumber: 1 }))
    removePages()
    setMaskSecret(false)
    setCheckDiff(true)
    setDifference(true)
    setConfirmVisible(false)
  }

  const handleNonSecret = () => {
    setPage((prevState) => ({ ...prevState, pageNumber: 1 }))
    removePages()
    setMaskSecret(true)
    setCheckDiff(true)
    setDifference(true)
    setConfirmVisible(false)
  }

  const setLocalPageSize = (pageSize: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'history',
      args: { pageSize },
    })

  return (
    <>
      <ShowDifferencesConfirmModal
        handleSecret={handleSecret}
        handleNonSecret={handleNonSecret}
        visible={confirmVisible}
        loading={loading}
      />
      <div className={styles.searchSelectors}>
        <div className={styles.filterContainer}>
          <TableSearch
            form={form}
            defaultValue={searchTerm}
            updateSearchTerm={search}
            setPage={setPage}
          />

          {showToggleSecrets && (
            <ToggleSecrets
              showSecretValues={!maskSecret}
              handleToggleClick={() => setMaskSecret((prevState) => !prevState)}
            />
          )}

          {timestamp && (
            <ToggleText
              handleToggleClick={() => handleCheckboxChange(!difference)}
              text="Differences"
              showing={!!difference}
            />
          )}
        </div>

        {canContribute ? (
          <TimestampSelector
            setSelectedTimestamp={setSelectedTimestamp}
            setTimestamp={setTimestamp}
            timestamp={timestamp}
            loading={loading}
            setPage={setPage}
          />
        ) : undefined}
      </div>

      <ParameterHistoryTable
        selectedTimestamp={selectedTimestamp}
        maskSecrets={maskSecret}
        loading={loading}
        checkDiff={checkDiff}
        searchTerm={searchTerm}
        totalParameters={totalParams}
        parameters={parameters}
        fetchError={fetchError}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        setOrdering={setOrdering}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setPage}
        setLoading={setLoading}
        setParameters={setParameters}
        getParametersByProject={getParametersByProject}
        setLocalPageSize={setLocalPageSize}
      />
    </>
  )
}

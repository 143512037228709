import { CustomThunk, idFromUrl } from 'data/dataUtils'
import { GetEnvironments, GetTags } from 'data/environment/actions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getCurrentEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { Environment } from 'gen/cloudTruthRestApi'
import { EnvironmentDetails } from './EnvironmentDetails'
import { PageTitle } from 'components/PageTitle'
import { Reload } from 'components/Reload'
import { Tree } from 'antd'
import { selectEnvironment } from 'data/environment/reducer'
import styles from './Environments.module.scss'
import { useToast } from 'hooks'

export function Environments() {
  const environments = environmentEntitySelectors.selectAll(useSelectEnvironments())
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())
  const currentEnvironment = useAppSelector(getCurrentEnvironment)

  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState<string[]>(environments.map((env) => env.id))
  const { errorToast } = useToast()

  const getEnvironments = useCallback(() => {
    setLoading(true)
    dispatch(GetEnvironments(null)).then(({ error, payload }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      } else {
        setExpandedKeys(payload?.results.map((env: Environment) => env.id))
      }
      setLoading(false)
    })
  }, [dispatch, errorToast, setLoading])

  useEffect(() => {
    dispatch(GetTags(currentEnvironment!.id))
  }, [currentEnvironment, dispatch])

  const onSelect = useCallback(
    (projectId: maybe<string>) => {
      if (projectId) {
        dispatch(selectEnvironment(projectId))
      }
    },
    [dispatch]
  )

  const assembleChildren = useCallback(
    (parent: Environment): any[] => {
      const childProjectFromUrl = (url: string) => {
        const id = idFromUrl(url)

        return environmentEntities[id]
      }

      return environments
        .filter((p) => p.parent === parent.url)
        .map((p) => ({
          title: (
            <span data-cy={`tree-${childProjectFromUrl(p.url)!.name}`}>
              {childProjectFromUrl(p.url)!.name}
            </span>
          ),
          value: childProjectFromUrl(p.url)!.name,
          key: childProjectFromUrl(p.url)!.id,
          children: assembleChildren(p),
          expanded: true,
        }))
    },
    [environments, environmentEntities]
  )

  const environmentTree = useMemo(() => {
    return environments
      .filter((environment) => environment.parent === null)
      .map((environment) => {
        return {
          title: <span data-cy={`tree-${environment.name}`}>{environment.name}</span>,
          value: environment.name,
          key: environment.id,
          children: assembleChildren(environment),
          expanded: true,
        }
      })
  }, [environments, assembleChildren])

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys)
  }

  return (
    <div className={styles.container}>
      <PageTitle
        title="Environments"
        description="Environments model the organization's hierarchy and establish values for parameters that
      are particular to a specific environment."
        link={
          <a
            className={styles.link}
            href="https://docs.cloudtruth.com/configuration-management/environments"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Environments
          </a>
        }
        buttons={<Reload onClick={getEnvironments} loading={loading} />}
      />

      <div className={styles.content}>
        <Tree
          selectedKeys={[currentEnvironment!.id]}
          className={styles.tree}
          showLine={true && { showLeafIcon: false }}
          treeData={environmentTree}
          expandedKeys={expandedKeys}
          onSelect={(keys) => {
            onSelect(keys[0]?.toString())
          }}
          onExpand={onExpand}
        />

        {
          <>
            {currentEnvironment ? (
              <EnvironmentDetails
                environment={currentEnvironment}
                getEnvironments={getEnvironments}
              />
            ) : (
              'select'
            )}
          </>
        }
      </div>
    </div>
  )
}

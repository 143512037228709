//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout'
import { OrganizationPaths, orgLink } from 'router/OrganizationRoutes'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { ReloadTile, TileName } from './ReloadTile'
import { ActionButton } from 'components/ActionButton'
import { DeleteConfirmModal } from 'components/Modals'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'

import { getCurrentUser } from 'data/user/selectors'
import { getPolicy } from 'data/membership/selectors'
import { localStorageHelpers } from 'lib/localStorageHelpers'
import styles from './Dashboard.module.scss'
import { useAppSelector } from 'data/hooks'

// import-sort-ignore
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveGridLayout: any = WidthProvider(Responsive)

// Dashboard components imported via lazy loading
const Projects = React.lazy(async () => ({
  default: (await import('./Projects/Projects')).Projects,
}))
const Environments = React.lazy(async () => ({
  default: (await import('./Environments/Environments')).Environments,
}))
const Resources = React.lazy(async () => ({
  default: (await import('./Resources/Resources')).Resources,
}))
const Integrations = React.lazy(async () => ({
  default: (await import('./Integrations/Integrations')).Integrations,
}))
const Users = React.lazy(async () => ({ default: (await import('./Users/Users')).Users }))

export enum TileId {
  Projects = 'projects',
  Environments = 'environments',
  Integrations = 'integrations',
  Users = 'users',
  Resources = 'resources',
}

export interface TileConfig {
  component: JSX.Element
  config: Layout
  title: string
  link?: Array<{
    linkText: string
    to: string
    policy?: boolean
  }>
  actionButton?: JSX.Element
  policy?: boolean
}

export type Tiles = Record<TileId, TileConfig>

export function Dashboard() {
  const { canAdministrate } = useAppSelector(getPolicy(null))
  const { id: userId } = useAppSelector(getCurrentUser)!

  // checks if caches has the current number of tiles, if not its legacy and reset it
  const cachedLayout = localStorageHelpers.getUserDashboardSettings(userId)
  const legacyLayout = Object.values(cachedLayout).every(
    (size) => Array.isArray(size) && size.length !== 5
  )

  const [userLayouts, setUserLayouts] = useState<Layouts>(legacyLayout ? {} : cachedLayout)

  const [projectsLoading, setProjectsLoading] = useState(false)
  const [environmentsLoading, setEnvironmentsLoading] = useState(false)

  const [usersLoading, setUsersLoading] = useState(false)
  const [integrationsLoading, setIntegrationsLoading] = useState(false)
  const [resetVisible, setResetVisible] = useState<boolean>(false)

  const tiles: Tiles = useMemo(
    () => ({
      [TileId.Projects]: {
        title: 'Projects',
        link: [{ linkText: 'View Projects', to: orgLink(OrganizationPaths.Projects) }],
        component: (
          <Suspense fallback={<Spin />}>
            <Projects loading={projectsLoading} setLoading={setProjectsLoading} />
          </Suspense>
        ),
        actionButton: <ReloadTile setLoading={setProjectsLoading} name={TileName.Projects} />,
        config: { i: TileId.Projects, w: 6, h: 2, x: 0, y: 2, minW: 4, minH: 2 },
      },
      [TileId.Environments]: {
        title: 'Environments',
        link: [{ linkText: 'View Environments', to: orgLink(OrganizationPaths.Environments) }],
        component: (
          <Suspense fallback={<Spin />}>
            <Environments loading={environmentsLoading} setLoading={setEnvironmentsLoading} />
          </Suspense>
        ),
        actionButton: (
          <ReloadTile setLoading={setEnvironmentsLoading} name={TileName.Environment} />
        ),
        config: { i: TileId.Environments, w: 6, h: 2, x: 6, y: 2, minW: 6, minH: 2 },
      },
      [TileId.Integrations]: {
        title: 'Integrations',
        component: (
          <Suspense fallback={<Spin />}>
            <Integrations loading={integrationsLoading} />
          </Suspense>
        ),
        actionButton: (
          <ReloadTile setLoading={setIntegrationsLoading} name={TileName.Integrations} />
        ),
        config: { i: TileId.Integrations, w: 6, h: 2, x: 0, y: 4, minW: 4, minH: 2 },
        policy: canAdministrate,
      },

      [TileId.Resources]: {
        title: 'Resources',
        component: (
          <Suspense fallback={<Spin />}>
            <Resources />
          </Suspense>
        ),
        config: { i: TileId.Resources, w: 12, h: 2, x: 0, y: 0, minW: 4, minH: 2 },
      },
      [TileId.Users]: {
        title: 'Users',
        link: [{ linkText: 'View User Directory', to: orgLink(OrganizationPaths.UserDirectory) }],
        component: (
          <Suspense fallback={<Spin />}>
            <Users loading={usersLoading} />
          </Suspense>
        ),
        actionButton: <ReloadTile setLoading={setUsersLoading} name={TileName.Users} />,
        config: { i: TileId.Users, w: 6, h: 2, x: 6, y: 4, minW: 4, minH: 2 },
        policy: canAdministrate,
      },
    }),
    [canAdministrate, projectsLoading, integrationsLoading, usersLoading, environmentsLoading]
  )

  const handleMove = (_layout: Layout[], layouts: Layouts) => {
    // Layouts contain a record of layout arrays keyed by breakpoint,
    // so user settings can be saved based on screen size
    setUserLayouts(layouts)
  }

  const handleReset = () => {
    setUserLayouts({})
    setResetVisible(false)
  }

  useEffect(() => {
    localStorageHelpers.setUserDashboardSettings(userId, userLayouts)
  }, [userId, userLayouts])

  return (
    <div className={styles.container}>
      <DeleteConfirmModal
        noConfig
        plural
        closeModal={() => setResetVisible(false)}
        visible={resetVisible}
        onDelete={handleReset}
        subject="custom layout settings"
        actionWord="reset"
      />
      <div className={styles.mainHeader}>
        <ActionButton onClick={() => setResetVisible(true)} danger>
          Reset Layout
        </ActionButton>
      </div>

      <ResponsiveGridLayout
        layouts={userLayouts}
        draggableHandle={'.draggableHeader'}
        onLayoutChange={handleMove}
        className={styles.layout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 8, sm: 4, xs: 3, xxs: 2 }}
      >
        {Object.entries(tiles)
          .filter(([_, tile]) => (typeof tile.policy === 'boolean' ? tile.policy : true))
          .map(([id, tile]) => {
            return (
              <div key={id} id={id} data-grid={tile.config} className={styles.tile}>
                <div className={styles.tileHeader + ' draggableHeader'}>
                  <h3>{tile.title}</h3>
                  <span>
                    {tile.link &&
                      tile.link
                        .map((link) => (
                          <span key={link.to}>
                            {typeof link.policy === 'boolean' ? (
                              <>
                                {link.policy && (
                                  <Link key={link.to} to={link.to} className={styles.headerLink}>
                                    {link.linkText}
                                  </Link>
                                )}
                              </>
                            ) : (
                              <Link key={link.to} to={link.to} className={styles.headerLink}>
                                {link.linkText}
                              </Link>
                            )}
                          </span>
                        ))
                        .reduce((prev, curr): any => [prev, ' | ', curr])}
                    {tile.actionButton}
                  </span>
                </div>
                <div className={styles.tileComponentContainer}>{tile.component}</div>
              </div>
            )
          })}
      </ResponsiveGridLayout>
    </div>
  )
}

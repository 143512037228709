import {
  CreateIdentityBody,
  Identity,
  IdentityApi,
  RecoveryLinkForIdentity,
} from '@ory/kratos-client'

import { AxiosResponse } from 'axios'

interface Name {
  first: string
  last: string
}

const schema_id = 'default'

export const createIdentity = async (
  ory: IdentityApi,
  traits: CreateIdentityBody['traits'],
  password: string
): Promise<AxiosResponse<Identity>> => {
  return await ory.createIdentity({
    createIdentityBody: {
      schema_id,
      traits,
      credentials: { password: { config: { password } } },
    },
  })
}

export const updateIdentityEmail = async (
  ory: IdentityApi,
  identity: Identity,
  email: string
): Promise<AxiosResponse<Identity>> => {
  return await ory.updateIdentity({
    id: identity.id,
    updateIdentityBody: {
      traits: { ...identity.traits, email },
      schema_id,
      state: identity.state!,
    },
  })
}
export const updateIdentityName = async (
  ory: IdentityApi,
  identity: Identity,
  name: Name
): Promise<AxiosResponse<Identity>> => {
  return await ory.updateIdentity({
    id: identity.id,
    updateIdentityBody: {
      traits: { ...identity.traits, name },
      schema_id,
      state: identity.state!,
    },
  })
}

export const createRecoveryLink = async (
  ory: IdentityApi,
  identity_id: Identity['id']
): Promise<AxiosResponse<RecoveryLinkForIdentity>> => {
  return await ory.createRecoveryLinkForIdentity({
    createRecoveryLinkForIdentityBody: { identity_id },
  })
}

export const deleteIdentity = async (
  ory: IdentityApi,
  id: Identity['id']
): Promise<AxiosResponse<void>> => {
  return await ory.deleteIdentity({ id })
}

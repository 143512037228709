//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { GetTemplates, GetVersionedTemplates } from 'data/template/actions'
import React, { useEffect, useState } from 'react'
import { templateEntitySelectors, useSelectTemplates } from 'data/template/selectors'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { CustomThunk } from 'data/dataUtils'
import { Dayjs } from 'dayjs'
import { Reload } from 'components/Reload'
import { Spin } from 'antd'
import { TableSearch } from 'components/Table'
import { Template } from 'gen/cloudTruthRestApi'
import TemplateComparison from './TemplateComparison/TemplateComparison'
import { TimestampSelector } from '../Forms/TimestampSelector'
import { getCurrentProject } from 'data/project/selectors'
import styles from './TemplateHistoryTable.module.scss'
import { useForm } from 'components/Forms'
import { useToast } from 'hooks'

export default function TemplateHistoryTable() {
  const { id: projectId, name: projectName } = useAppSelector(getCurrentProject)!
  const templates = templateEntitySelectors.selectAll(useSelectTemplates())
  const templateCache = useAppSelector((state) => state.template.cachedProject)
  const [loading, setLoading] = useState<boolean>(false)
  const [deletedTemplates, setDeletedTemplates] = useState<Template[]>([])
  const [versionedTemplates, setVersionedTemplates] = useState<Template[]>([])
  const [timestamp, setTimestamp] = useState<nullable<Dayjs>>(null)
  const [tagName, setTagName] = useState<nullable<string>>(null)

  const dispatch = useAppDispatch()
  const { errorToast } = useToast()
  const [form] = useForm()

  const [searchTerm, setSearchTerm] = useState('')
  const search = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  useEffect(() => {
    if (templateCache !== projectId) {
      dispatch(GetTemplates(projectId)).then(({ error }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
        }
        setLoading(false)
      })
    }
  }, [dispatch, projectId, templateCache, errorToast])

  const renderEmptyMessage = (message: string): JSX.Element => {
    return (
      <div className={styles.emptyContainer}>
        <h4 className={styles.emptyText}>{message}</h4>
      </div>
    )
  }

  const handleSubmit = (timestamp: Dayjs | string, callback: () => void) => {
    const populateDeletedObjectList = (versionedObjectList: Template[], objectList: Template[]) => {
      return versionedObjectList
        .filter((obj) => !objectList.some(({ id }) => id === obj.id))
        .map((object) => {
          const newObj = object
          newObj.id = `${object.id}_deleted`
          return newObj
        })
    }

    setLoading(true)
    setTagName(null)
    dispatch(GetVersionedTemplates({ projectId, params: { as_of: timestamp } })).then(
      ({ error, payload }: CustomThunk) => {
        if (error) {
          errorToast(error.message)
        } else {
          const deletedObjects = populateDeletedObjectList(payload.results, templates)

          callback()
          setDeletedTemplates(deletedObjects)
          setVersionedTemplates(payload.results)
        }

        setLoading(false)
      }
    )
  }

  const reloadTable = () => {
    setLoading(true)
    dispatch(GetTemplates(projectId)).then(({ error }: CustomThunk) => {
      if (error) {
        errorToast(error.message)
      }
      setLoading(false)
    })
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <div className={styles.searchContainer}>
          <TableSearch
            defaultValue={searchTerm}
            updateSearchTerm={search}
            form={form}
            // setPage={setPage}
            placeHolder="Search Templates"
          />

          <Reload onClick={reloadTable} loading={loading} />
        </div>
        <div className={styles.timeSelector}>
          <TimestampSelector
            handleSubmit={handleSubmit}
            setTimestamp={setTimestamp}
            setTagName={setTagName}
            loading={loading}
          />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.tableTitleContainer}>
          <h3>{`Project: ${projectName}`}</h3>
        </div>
        {loading ? (
          <div className={styles.spinContainer}>
            <Spin />
          </div>
        ) : (
          <>
            {templates.length + deletedTemplates.length > 0 ? (
              <div className={styles.templatesContainer}>
                {templates
                  .sort((first, second) => {
                    return first.name.localeCompare(second.name)
                  })
                  .filter((template) => {
                    if (searchTerm) {
                      return template.name.toLowerCase().includes(searchTerm.toLowerCase())
                    } else {
                      return templates
                    }
                  })
                  .map((item) => {
                    const timeTemplate = versionedTemplates.find((ver) => ver.id === item.id)
                    return (
                      <TemplateComparison
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        timestamp={timestamp}
                        tagName={tagName}
                        currentBody={item.body}
                        timeBody={timeTemplate?.body}
                      />
                    )
                  })}
                {deletedTemplates
                  .sort((first, second) => {
                    return first.name.localeCompare(second.name)
                  })
                  .map((item) => {
                    return (
                      <TemplateComparison
                        key={item.id}
                        name={item.name}
                        timestamp={timestamp}
                        tagName={tagName}
                        timeBody={item.body}
                      />
                    )
                  })}
              </div>
            ) : (
              renderEmptyMessage('You do not have any templates stored in CloudTruth.')
            )}
          </>
        )}
      </div>
    </div>
  )
}

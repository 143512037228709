//
// Copyright (C) 2022 CloudTruth, Inc.
// All Rights Reserved
//

import {
  PaginatedParameterTypeList,
  PaginatedParameterTypeRuleList,
  ParameterType,
  ParameterTypeCreate,
  ParameterTypeRule,
  ParameterTypeRuleCreate,
  PatchedParameterTypeRuleUpdate,
  PatchedParameterTypeUpdate,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

/* -------------- Parameter Types -------------- */

export const GetParameterTypes = createAsyncThunk<PaginatedParameterTypeList, null, ThunkConfig>(
  'parameterType/getParameterTypes',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'types', null, thunkApi)
)

export const GetParameterType = createAsyncThunk<ParameterType, string, ThunkConfig>(
  'parameterType/getParameterType',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `types/${id}`, null, thunkApi)
)

export const CreateParameterType = createAsyncThunk<
  ParameterType,
  ParameterTypeCreate,
  ThunkConfig
>(
  'parameterType/createParameterType',
  async (type, thunkApi) => await handleFetch(Verbs.Post, 'types', type, thunkApi)
)

export const UpdateParameterType = createAsyncThunk<
  ParameterType,
  PatchedParameterTypeUpdate,
  ThunkConfig
>(
  'parameterType/updateParameterType',
  async (type, thunkApi) => await handleFetch(Verbs.Put, `types/${type.id}`, type, thunkApi)
)

export const DeleteParameterType = createAsyncThunk<string, string, ThunkConfig>(
  'parameterType/deleteParameterType',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `types/${id}`, null, thunkApi)
)

/* -------------- Parameter Type Rules -------------- */

export interface ParameterTypeRuleReq {
  typeId: string
  ruleId: string
}

export interface ParameterTypeRuleCreateReq extends ParameterTypeRuleCreate {
  typeId: string
}

export interface ParameterTypeRuleUpdateReq extends PatchedParameterTypeRuleUpdate {
  typeId: string
}

export const GetParameterTypeRules = createAsyncThunk<
  PaginatedParameterTypeRuleList,
  string,
  ThunkConfig
>(
  'parameterType/getParameterTypeRules',
  async (typeId, thunkApi) => await handleFetch(Verbs.Get, `types/${typeId}/rules`, null, thunkApi)
)

export const GetParameterTypeRule = createAsyncThunk<
  ParameterTypeRule,
  ParameterTypeRuleReq,
  ThunkConfig
>(
  'parameterType/getParameterTypeRule',
  async (params, thunkApi) =>
    await handleFetch(Verbs.Get, `types/${params.typeId}/rules/${params.ruleId}`, null, thunkApi)
)

export const CreateParameterTypeRule = createAsyncThunk<
  ParameterTypeRule,
  ParameterTypeRuleCreateReq,
  ThunkConfig
>(
  'parameterType/createParameterTypeRule',
  async (rule, thunkApi) =>
    await handleFetch(Verbs.Post, `types/${rule.typeId}/rules`, rule, thunkApi)
)

export const UpdateParameterTypeRule = createAsyncThunk<
  ParameterTypeRule,
  ParameterTypeRuleUpdateReq,
  ThunkConfig
>('parameterType/updateParameterTypeRule', async (rule, thunkApi) => {
  return await handleFetch(Verbs.Put, `types/${rule.typeId}/rules/${rule.id}`, rule, thunkApi)
})

export const DeleteParameterTypeRule = createAsyncThunk<string, ParameterTypeRuleReq, ThunkConfig>(
  'parameterType/deleteParameterTypeRule',
  async (params, thunkApi) =>
    await handleFetch(Verbs.Delete, `types/${params.typeId}/rules/${params.ruleId}`, null, thunkApi)
)

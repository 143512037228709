import React, { useEffect, useMemo, useState } from 'react'
import {
  environmentEntitySelectors,
  getBaseEnvironment,
  useSelectEnvironments,
} from 'data/environment/selectors'
import {
  getValueByEnv,
  parameterEntitySelectors,
  useSelectParameters,
} from 'data/parameter/selectors'
import { idFromUrl, projectIdFromAnyUrl } from 'data/dataUtils'
import { parameterTypeEntitySelectors, useSelectParameterTypes } from 'data/parameterType/selectors'

import { LabelText } from 'components/LabelText'
import { ModalWithoutFooter } from 'components/Modals'
import { RULE_TYPE_LABELS } from 'components/Types/Rules/RuleTile'
import { ReferencedParameter } from './ReferencedParameter'
import { ShowHideButton } from 'components/misc/ShowHideButton'
import { UpdateValueForm } from './UpdateValueForm'
import { ValueTypeButton } from './ValueTypeButton'
import { getCurrentProject } from 'data/project/selectors'
import styles from './UpdateValueModal.module.scss'
import { useAppSelector } from 'data/hooks'

interface Props {
  hasValue: boolean
  hideRemoveOverride?: boolean
  setVisible: (v: boolean) => void
  environmentValueUrl: string
  parameterId: string
  showSecret?: boolean
  resetShow?: () => void
  setValueDropdownLoading?: (bool: boolean) => void
  onUpdateCallback?: (value: string, oldValue: string) => void
}

export function UpdateValueModal(props: Props) {
  const {
    setVisible,
    hasValue,
    environmentValueUrl,
    parameterId,
    hideRemoveOverride,
    showSecret,
    resetShow,
    setValueDropdownLoading,
    onUpdateCallback,
  } = props
  const { url: baseUrl } = useAppSelector(getBaseEnvironment)
  const currentProject = useAppSelector(getCurrentProject)!

  const environmentValue = useAppSelector(getValueByEnv(parameterId, environmentValueUrl))
  const parameter = parameterEntitySelectors.selectById(useSelectParameters(), parameterId)!
  const id = idFromUrl(environmentValueUrl)
  const environmentEntities = environmentEntitySelectors.selectEntities(useSelectEnvironments())
  const { name: envName } = environmentEntities[id]!

  const [isInterpolated, setIsInterpolated] = useState(environmentValue?.interpolated ?? false)
  const [isExternal, setIsExternal] = useState(environmentValue?.external ?? false)
  const [showRules, setShowRules] = useState<boolean>(false)
  const types = parameterTypeEntitySelectors.selectAll(useSelectParameterTypes())

  const findType = types.find((type) => type.name === parameter.type)

  const isEnumType = useMemo(() => {
    const type = types.find((t) => t.name === parameter.type)
    return type?.name === 'enum' || type?.parent_name === 'enum'
  }, [types, parameter])

  const showDeleteOverride =
    !hideRemoveOverride &&
    hasValue &&
    !(environmentValue?.environment === baseUrl || false) &&
    projectIdFromAnyUrl(environmentValue.url) === currentProject.id

  // Changing to external must reset the interpolated setting
  useEffect(() => {
    if (isExternal) {
      setIsInterpolated(environmentValue?.interpolated ?? false)
    }
  }, [environmentValue, isExternal])

  const ShowRulesComponent = () => {
    if (findType?.parent_name === 'enum') {
      return (
        <LabelText
          key={findType?.id}
          isHorizontal
          text={findType?.rules[0]?.constraints?.join(', ')}
          label={''}
        />
      )
    } else if (parameter.type === 'enum') {
      return (
        <LabelText isHorizontal text={parameter?.rules[0]?.constraints?.join(', ')} label={''} />
      )
    }

    return (
      <>
        {parameter.rules.map((rule) => (
          <LabelText
            key={rule.id}
            isHorizontal
            text={rule.constraint}
            label={RULE_TYPE_LABELS[rule.type] as string}
          />
        ))}
      </>
    )
  }

  const ParameterHeading = () => {
    return (
      <div
        className={
          isEnumType ? styles.enumParameterHeadingContainer : styles.parameterHeadingContainer
        }
      >
        <div>
          <LabelText label="name" text={parameter.name} uppercase isHorizontal />
          <LabelText label="environment" text={envName} uppercase isHorizontal />
          <LabelText label="type" text={parameter.type} uppercase isHorizontal />
          <LabelText
            label="rules"
            text={
              parameter.rules.length === 0 && findType?.rules.length === 0 ? (
                'none'
              ) : (
                <ShowHideButton
                  showSettings={showRules}
                  toggleShowSettings={() => setShowRules(!showRules)}
                  label="rules"
                />
              )
            }
            uppercase
            isHorizontal
          />
          {showRules && <ShowRulesComponent />}
        </div>
        {!isEnumType && (
          <div className={styles.parameterValueTypeContainer}>
            <ValueTypeButton
              isInterpolated={isInterpolated}
              isExternal={isExternal}
              isSecret={!!parameter.secret}
              setIsInterpolated={setIsInterpolated}
              setIsExternal={setIsExternal}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <ModalWithoutFooter
      visible={true}
      width={isExternal ? '90%' : '650px'}
      title={hasValue ? 'edit environment value' : 'add environment value'}
    >
      {!isExternal ? (
        <div className={isEnumType ? styles.enumContainer : ''}>
          <ParameterHeading />
          <UpdateValueForm
            setValueDropdownLoading={setValueDropdownLoading}
            parameter={parameter}
            showSecret={!!showSecret}
            resetShow={resetShow}
            environmentValueUrl={environmentValueUrl}
            hasValue={hasValue}
            environmentValue={environmentValue}
            showDeleteOverride={showDeleteOverride}
            setVisible={setVisible}
            isInterpolated={isInterpolated}
            onUpdateCallback={onUpdateCallback}
          />
        </div>
      ) : (
        <>
          <div className={styles.referencedParameterContainer}>
            <ParameterHeading />
          </div>
          <ReferencedParameter
            setValueDropdownLoading={setValueDropdownLoading}
            resetShow={resetShow}
            showSecret={showSecret}
            hasValue={hasValue}
            environmentValue={environmentValue}
            parameter={parameter}
            environmentValueUrl={environmentValueUrl}
            showDeleteOverride={showDeleteOverride}
            setVisible={setVisible}
            isInterpolated={isInterpolated}
          />
        </>
      )}
    </ModalWithoutFooter>
  )
}

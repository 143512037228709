import { Divider, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { GetTemplateNames } from 'data/template/actions'
import { Link } from 'react-router-dom'
import { Parameter } from 'gen/cloudTruthRestApi'
import { getCurrentProject } from 'data/project/selectors'
import { selectTemplate } from 'data/template/reducer'
import styles from './TemplateList.module.scss'
import { templateByIdLink } from 'router/OrganizationRoutes'

interface Props {
  parameter: Parameter
}

export function TemplateList(props: Props) {
  const { parameter } = props
  const { templates, cachedTemplates, id: projectId } = useAppSelector(getCurrentProject)!
  const { referencing_templates: templateList } = parameter
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!cachedTemplates) {
      setLoading(true)
      dispatch(GetTemplateNames(projectId)).then(() => setLoading(false))
    }
  }, [cachedTemplates, projectId, dispatch])

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h2>Template List</h2>
        <Divider className={styles.divider} />
      </div>

      {loading ? (
        <Spin />
      ) : (
        <ul className={styles.templateContainer}>
          {templateList.length > 0 ? (
            templateList.map((url: string, key: number) => {
              const template = templates?.find((temp) => temp.url === url)
              if (template) {
                return (
                  <li className={styles.listItem} key={key}>
                    <Link
                      to={templateByIdLink(projectId, template.id)}
                      onClick={() => dispatch(selectTemplate(template.id))}
                      className={styles.templateListItem}
                    >
                      {template.name}
                    </Link>
                  </li>
                )
              }
            })
          ) : (
            <li className={styles.listItem}>
              This parameter is not currently referenced in any templates.
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

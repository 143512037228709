import { IntegrationNode, NodeTypeEnum, PaginatedIntegrationNodeList } from 'gen/cloudTruthRestApi'
import React, { Key, useCallback, useEffect, useState } from 'react'
import { Tooltip, Tree, Typography } from 'antd'

import { ActionButton } from 'components/ActionButton'
import { DataNode } from 'rc-tree/es/interface'
import { GetIntegrationExplorer } from 'data/integration/actions'
import { IntegrationTree } from '../IntegrationExplorer'
import { LockOutlined } from '@ant-design/icons'
import { TypedThunk } from 'data/dataUtils'
import styles from './FileTree.module.scss'
import uniqBy from 'lodash.uniqby'
import { useAppDispatch } from 'data/hooks'
import { useToast } from 'hooks'

/*
 * This component is the expandable file/folder tree on the LHS of the "Edit Value" modal for an external value. It can
 * optionally take in a selected IntegrationFile (in the case of editing an existing external value). It will always
 * have the root IntegrationTree as a prop which represents the top level of the file/folder tree. The contents
 * of folders are loaded on-demand as they are expanded.
 *
 * If a file is already selected (edit case), then we need to start with all the branches of the tree to that leaf
 * expanded and the file selected. To achieve this, we fetch the ancestors of the IntegrationFile and rebuild that
 * part of the tree below.
 *
 * If there is no IntegrationFile (and therefore no currently selected file) then we only display the root of the tree.
 */

/*
 * The Node interface is a tree node from the graph.
 * This is different than Ant's DataNode.
 */

// converts a Node to an Ant DataNode
function wrapEntry(entry: IntegrationNode, showExternalSecretValues: boolean): DataNode {
  const { fqn: key, name: title, node_type: type, secret } = entry
  const isLeaf = type === NodeTypeEnum.File

  const DisabledTooltip = () => (
    <Tooltip title={`Mark parameter as a secret in parameter's settings to access ${title}`}>
      <Typography.Text disabled>{title} </Typography.Text>
    </Tooltip>
  )

  const hideExternalValues = !showExternalSecretValues && secret

  return {
    key,
    title: hideExternalValues ? <DisabledTooltip /> : title,
    isLeaf,
    selectable: isLeaf,
    disabled: hideExternalValues,
    switcherIcon: hideExternalValues && isLeaf && (
      <LockOutlined disabled className={styles.disabled} />
    ),
  }
}

// all keys in the Ant tree nodes which have populated children
const allKeysWithChildren = (treeData: DataNode[]): string[] => {
  const keysWithChildren = (dataNode: DataNode): string[] => {
    if (dataNode.children) {
      const childrenKeyArrs = dataNode.children.map(keysWithChildren)
      return [dataNode.key.toString()].concat(...childrenKeyArrs)
    }
    return []
  }

  const [first, ...rest] = treeData.map(keysWithChildren)
  return first.concat(...rest)
}

/*
 * used to lazy load Ant tree data with children. Given the current list of DataNodes, the parent key, and a list of
 * children nodes, find the parent in the tree and add the children
 */
function updateTreeData(list: DataNode[], key: Key, children: DataNode[]): DataNode[] {
  function removeTrailingSlash(str: string | number) {
    if (typeof str === 'number') {
      return str
    }
    return str.replace(/\/+$/, '')
  }

  return list.map((node) => {
    if (removeTrailingSlash(node.key) === removeTrailingSlash(key)) {
      return {
        ...node,
        title:
          (node.title as string).includes('ssm') && children.length > 0
            ? `ssm (${children.length - 1})`
            : node.title,
        children,
      }
    } else if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      }
    } else {
      return node
    }
  })
}

interface Props {
  integrationTree: IntegrationTree
  integrationFile: nullable<IntegrationNode>
  onSelect: (fqn: string) => void
  hasSelectedFile: () => void
  showExternalSecretValues: boolean
  setPending: (pending: boolean) => void
  externalError?: nullable<string>
  selectedFqn: nullable<string>
}

interface LoadMoreProps {
  pageToken: option<string>
  ssmParams: IntegrationNode[]
  loading: boolean
  ssmFqn: string
}

export function FileTree(props: Props) {
  const {
    integrationTree,
    integrationFile,
    onSelect,
    hasSelectedFile,
    setPending,
    showExternalSecretValues,
    externalError,
  } = props
  const dispatch = useAppDispatch()
  const { errorToast } = useToast()

  const initialTreeData = () => {
    return integrationTree.entries.map((entry: IntegrationNode) =>
      wrapEntry(entry, showExternalSecretValues)
    )
  }

  const [treeData, setTreeData] = useState<DataNode[]>(initialTreeData())
  const [loading, setLoading] = useState<boolean>(!!integrationFile)
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(allKeysWithChildren(treeData))
  const [pageToken, setPageToken] = useState<string>()
  const [ssmParameters, setSsmParameters] = useState<IntegrationNode[]>([])
  const [loadMorePending, setLoadMorePending] = useState(false)
  const [ssmFqn, setSSmFqn] = useState<string>()

  const LoadMore = useCallback((props: LoadMoreProps) => {
    const { ssmFqn, loading, pageToken, ssmParams } = props

    return (
      <ActionButton
        loading={loading}
        onClick={async () => {
          if (!loading) {
            setLoadMorePending(true)
            await handleData(ssmFqn, pageToken, ssmParams)
          }
        }}
        className={styles.loadMoreButton}
      >
        Load More
      </ActionButton>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const titleRenderer = useCallback(
    ({ key, title }: DataNode): React.ReactNode => {
      if (key === 'loadMore' && ssmFqn) {
        return (
          <LoadMore
            pageToken={pageToken}
            loading={loadMorePending}
            ssmParams={ssmParameters}
            ssmFqn={ssmFqn}
          />
        )
      } else {
        return <>{title}</>
      }
    },
    [pageToken, loadMorePending, ssmParameters, ssmFqn, LoadMore]
  )

  useEffect(() => {
    const finish = () => {
      setPending(false)
      setLoading(false)
    }

    const buildRecursiveTreeData = async (): Promise<void> => {
      const re = new RegExp(`\\/(?:\\?r=)?${integrationFile!.name!}`)
      const fullFqn = integrationFile!.fqn.replace(re, '')
      const fqnArray = fullFqn.split('/')
      let fqn = `${fqnArray[0]}/${fqnArray[1]}/${fqnArray[2]}/`

      const selectedFqnSsmFile = await (async () => {
        if (integrationFile?.fqn && integrationFile.fqn.includes('ssm')) {
          const { error, payload }: TypedThunk<PaginatedIntegrationNodeList> = await dispatch(
            GetIntegrationExplorer({ fqn: integrationFile.fqn.toString() })
          )

          if (error) {
            return
          } else if (payload.results) {
            return payload.results
          }
        }
      })()

      if (selectedFqnSsmFile) {
        setSsmParameters((prev) => [...prev, ...selectedFqnSsmFile])
      }

      for (let i = 3; i < fqnArray.length; i++) {
        fqn += `${fqnArray[i]}/`
        try {
          selectedFqnSsmFile
            ? await handleData(fqn, undefined, selectedFqnSsmFile)
            : await handleData(fqn)
        } catch {
          break
        }
      }
    }

    if (integrationFile && !externalError) {
      buildRecursiveTreeData()
        .then(() => {
          finish()
        })
        .catch(() => {
          finish()
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loading) {
      setExpandedKeys(allKeysWithChildren(treeData))
    }
  }, [loading, treeData])

  // lazy-load folder contents when expanded
  const onLoadData = async ({ key, children }: DataNode) => {
    return new Promise<void>((resolve) => {
      if (children) {
        resolve()
        return // children are already populated
      }
      handleData(key).then(() => resolve())
    })
  }

  const handleData = useCallback(
    async (
      fqn: string | number,
      page_token: option<string> = pageToken,
      ssmParams: option<IntegrationNode[]> = ssmParameters
    ): Promise<void> => {
      const pageTokenCache = fqn.toString().includes('ssm') ? page_token : undefined
      const pageSize = fqn.toString().includes('ssm') ? 50 : undefined

      const { error, payload }: TypedThunk<PaginatedIntegrationNodeList> = await dispatch(
        GetIntegrationExplorer({
          page_size: pageSize,
          fqn: fqn.toString(),
          page_token: pageTokenCache,
        })
      )

      if (error) {
        errorToast(error.message)
        return Promise.reject()
      }

      if (payload.next) {
        setPageToken(payload.next)
      }

      const children = () => {
        if (!payload.results || payload.results.length < 1) {
          return []
        }

        const renderChildren = (): DataNode[] => {
          if (fqn.toString().includes('ssm')) {
            setSSmFqn(fqn.toString())

            const loadMore: DataNode[] = [
              {
                title: (
                  <LoadMore
                    ssmFqn={fqn as string}
                    pageToken={pageTokenCache!}
                    loading={loadMorePending}
                    ssmParams={ssmParams}
                  />
                ),
                className: styles.loadMoreNode,
                isLeaf: true,
                key: 'loadMore',
                selectable: false,
              },
            ]

            const combinedSsmArray = uniqBy([...ssmParams, ...payload.results!], 'fqn').sort(
              (first, second) => {
                return first.name!.localeCompare(second.name!)
              }
            )

            setSsmParameters(combinedSsmArray)

            return combinedSsmArray
              .map((entry: IntegrationNode) => wrapEntry(entry, showExternalSecretValues))
              .concat(loadMore)
          } else {
            return payload.results!.map((entry: IntegrationNode) =>
              wrapEntry(entry, showExternalSecretValues)
            )
          }
        }

        return renderChildren()
      }

      setTreeData((origin: DataNode[]) => updateTreeData(origin, fqn, children()))
      setLoadMorePending(false)
    },
    [
      ssmParameters,
      pageToken,
      LoadMore,
      dispatch,
      errorToast,
      loadMorePending,
      showExternalSecretValues,
    ]
  )

  if (integrationFile && loading) {
    return null
  }

  // Have Ant auto expand any folders with initial children and highlight the currently selected file
  const defaultSelectedKeys = integrationFile ? [integrationFile.fqn] : []

  return (
    <Tree.DirectoryTree
      icon={undefined}
      className={styles.tree}
      loadedKeys={['loadMore']}
      loadData={onLoadData}
      titleRender={titleRenderer}
      treeData={treeData}
      defaultSelectedKeys={defaultSelectedKeys}
      expandedKeys={expandedKeys}
      onSelect={(keys) => {
        if (keys.length > 0) {
          hasSelectedFile()
          onSelect(keys[0].toString())
        }
      }}
      onExpand={(keys) => setExpandedKeys(keys)}
      blockNode={true}
      height={600}
    />
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import {
  PaginatedProjectList,
  PatchedProjectUpdate,
  Project,
  ProjectCopy,
  ProjectCreate,
} from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { createAsyncThunk } from '@reduxjs/toolkit'

interface CopyProjectReq {
  id: string
  params: ProjectCopy
}

export const GetProjects = createAsyncThunk<PaginatedProjectList, null, ThunkConfig>(
  'project/getProjects',
  async (_, thunkApi) => await handleFetch(Verbs.Get, 'projects', null, thunkApi)
)

export const GetProject = createAsyncThunk<Project, string, ThunkConfig>(
  'project/getProject',
  async (id, thunkApi) => await handleFetch(Verbs.Get, `projects/${id}`, null, thunkApi)
)

export const CreateProject = createAsyncThunk<Project, ProjectCreate, ThunkConfig>(
  'project/createProject',
  async (project, thunkApi) => await handleFetch(Verbs.Post, 'projects', project, thunkApi)
)

export const UpdateProject = createAsyncThunk<Project, PatchedProjectUpdate, ThunkConfig>(
  'project/updateProject',
  async (project, thunkApi) =>
    await handleFetch(Verbs.Put, `projects/${project.id}`, project, thunkApi)
)

export const CopyCreateProject = createAsyncThunk<Project, CopyProjectReq, ThunkConfig>(
  'project/copyProject',
  async (req, thunkApi) => {
    return await handleFetch(Verbs.Post, `projects/${req.id}/copy`, req.params, thunkApi)
  }
)

export const DeleteProject = createAsyncThunk<string, string, ThunkConfig>(
  'project/deleteProject',
  async (id, thunkApi) => await handleFetch(Verbs.Delete, `projects/${id}`, null, thunkApi)
)

import { ActionCard, CenterLink, Flow, MarginCard, handleFlowError, ory } from '..'
import { Link, useNavigate } from 'react-router-dom'
import { OryQueryParamName, useQuery } from 'router/customHooks'
import React, { useEffect, useState } from 'react'
import { RegistrationFlow, UpdateRegistrationFlowBody } from '@ory/kratos-client'

import { AxiosError } from 'axios'
import { CardTitle } from '@ory/themes'
import styles from './Registration.module.scss'
import { useToast } from 'hooks'

/**
 * Registration flow for ory
 *
 * First get query params returnTo, flowId, refresh, aal
 *
 * It works by requesting for the UI nodes to be displayed which are provided by the ory server
 * once the component mounts. The nomenclature for this is called "flow". In the first
 * useEffect we initialize the flow. If there was a previous attempt of logging in the
 * flowId is appeneded to the url params, and we continue with the previous flow.
 * If the flowId is not in the URL we start a new registration flow.
 *
 * Once we initialize the flow, the flow is held in state. You can think of flow as an array
 * of objects that represent UI nodes. That flow object is then passed down to the flow component.
 * That flow component knows how to dynamically render all the UI nodes that are passed from the
 * Ory server. Everything in Ory/components are reusable pieces that displays form input fields and buttons
 * based on the flow object we get back from the Ory server.
 *
 * The submit function gets passed down to the flow component. The submit value logic gets handled in the flow
 * component because the values will change based on email/password, and social auth (oidc).
 *
 */
export const Registration = () => {
  const [flow, setFlow] = useState<RegistrationFlow>()
  const [loading, setLoading] = useState(true)
  const { errorToast } = useToast()
  const router = useNavigate()
  const { getOryQuery } = useQuery()

  const returnTo = getOryQuery(OryQueryParamName.ReturnTo)
  const flowId = getOryQuery(OryQueryParamName.Flow)

  // In this effect we either initiate a new registration flow, or we fetch an existing registration flow.
  useEffect(() => {
    const done = () => setLoading(false)
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      return
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getRegistrationFlow({ id: String(flowId) })
        .then(({ data }) => {
          // We received the flow - let's use its data and render the form!
          setFlow(data)
        })
        .then(done)
        .catch(handleFlowError(router, 'registration', setFlow, errorToast))
      return
    }

    // Otherwise we initialize it
    ory
      .createBrowserRegistrationFlow({ returnTo: returnTo ? String(returnTo) : undefined })
      .then(({ data }) => {
        setFlow(data)
      })
      .then(done)
      .catch(handleFlowError(router, 'registration', setFlow, errorToast))
  }, [flowId, router, returnTo, flow, errorToast])

  const onSubmit = (values: UpdateRegistrationFlowBody) =>
    // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
    // his data when she/he reloads the page.
    new Promise((resolve) => {
      router(`/registration?flow=${flow?.id}`)
      resolve({})
    }).then(() =>
      ory
        .updateRegistrationFlow({ flow: String(flow?.id), updateRegistrationFlowBody: values })
        .then(() => {
          // If we ended up here, it means we are successfully signed up!
          //
          // You can do cool stuff here, like having access to the identity which just signed up:

          if (flow?.return_to) {
            window.location.href = flow?.return_to
            return
          }
          router('/')

          // For now however we just want to redirect home!
        })
        .catch(handleFlowError(router, 'registration', setFlow, errorToast))
        .catch((err: AxiosError) => {
          // If the previous handler did not catch the error it's most likely a form validation error
          if (err.response?.status === 400) {
            // Yup, it is!
            setFlow(err.response?.data)
            return
          }

          return Promise.reject(err)
        })
    )

  if (loading) {
    return <div className={styles.container} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.registrationContainer}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo} />
          </div>
          <h2>Welcome</h2>
        </div>
        <MarginCard>
          <CardTitle className={styles.title}>
            Please enter your email address & password or choose a login provider to create your
            account
          </CardTitle>
          <Flow onSubmit={onSubmit} flow={flow} />
        </MarginCard>
        <ActionCard style={{ marginTop: '-2rem' }}>
          <Link to="/login">
            <span style={{ color: 'black', paddingRight: '.5rem' }}>Already have an account?</span>
            <CenterLink>Sign in</CenterLink>
          </Link>
        </ActionCard>
      </div>
    </div>
  )
}

export const getParameters = {
  name: 'get_parameters',
  description:
    "When a customer asks a question that involve paramters, the ai agent will call an external api to get the parameters and then send another message with the attached parameters. Don't forget to prompt the user if they don't specify the project. When you prompt the user to specify the project, you should ask if they want to use their current project or another one.",
  parameters: {
    type: 'object',
    properties: {
      project: {
        type: 'string',
        description: 'The project that the parameters should belong to',
      },
      callCurrentProject: {
        type: 'boolean',
        description:
          "If the user doesn't specify the project, ask if they want to use their current project. If they are using a current project this boolean should be true. If not this should be false and then the project should be specified.",
      },
    },
    required: ['callCurrentProject'],
  },
}

export const createParameters = {
  name: 'create_parameters',
  description:
    'When asked to create parameters, you should always confirm the parameters the user is creating and ask if they want to add a secret, description, or default value to any of them.',
  parameters: {
    type: 'object',
    properties: {
      project: {
        type: 'string',
        description: 'optional project name to create the parameter in',
      },
      parameters: {
        type: 'array',
        description:
          'An array of parameter objects that have a name string, a secret boolean, and a description.',
        items: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'The name of the parameter',
            },
            description: {
              type: 'string',
              description: 'The description of the parameter',
            },
            secret: {
              type: 'boolean',
              description: 'Boolean indicating whether the parameter is secret or not',
            },
            defaultValue: {
              type: 'string',
              description: 'The default value of the parameter',
            },
          },
          required: ['name'],
        },
      },
    },
  },
}

export const updateParameterDescriptions = {
  name: 'update_parameter_descriptions',
  description:
    'When asked to update parameter descriptions parameters, you should always confirm the parameters the user is updating.',
  parameters: {
    type: 'object',
    properties: {
      parameters: {
        type: 'array',
        description:
          'An array of parameter objects that have a name string, secret boolean, description string, and optional default value.',
        items: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'The name of the parameter',
            },
            description: {
              type: 'string',
              description: 'The description of the parameter',
            },
          },
          required: ['name', 'description'],
        },
      },
    },
  },
}

export const getTemplates = {
  name: 'get_templates',
  description:
    "When a customer asks a question that involve paramters, the ai agent will call an external api to get the parameters and then send another message with the attached parameters. Don't forget to prompt the user if they don't specify the project. When you prompt the user to specify the project, you should ask if they want to use their current project or another one.",
  parameters: {
    type: 'object',
    properties: {
      project: {
        type: 'string',
        description: 'The project that the parameters should belong to',
      },
      callCurrentProject: {
        type: 'boolean',
        description:
          "If the user doesn't specify the project, ask if they want to use their current project. If they are using a current project this boolean should be true. If not this should be false and then the project should be specified.",
      },
    },
    required: ['callCurrentProject'],
  },
}

export const createTemplates = {
  name: 'create_templates',
  description:
    'Always reference instructions and the body description to understand how to create a template. When asked to create template, you should always confirm if the user is okay with the generated body (or bodies if there are multiple). Remember the template body should always be in liquid following the cloudtruth access syntax stated in the intructions. Sometimes a user might want to just generate a template and not create it so always confirm if they want to create the template after generating it.',
  parameters: {
    type: 'object',
    properties: {
      project: {
        type: 'string',
        description: 'optional project name to create the template in',
      },
      parameters: {
        type: 'array',
        description:
          'An array of template objects that have a name string, a body string, and description that is in liquid cloudtruth format',
        items: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'The name of the template',
            },
            description: {
              type: 'string',
              description: 'The description of the template',
            },
            body: {
              type: 'string',
              description:
                "The body of the template that is in liquid cloudtruth format. - **Syntax for Accessing CloudTruth Information**:\n              - cloudtruth.self - access name of the current template\n              - cloudtruth.project - access name of the current project\n              - cloudtruth.environment - access name of the current environment\n              - cloudtruth.environment.children - returns a list of the current environment's children\n              - cloudtruth.templates.<name> - references a template from the current project\n              - cloudtruth.parameters - references all parameters in a (name, value) array\n              - cloudtruth.templates - references all templates in a (name, value) array\n              - cloudtruth.parameters.<name> - references a parameter from the current project\n              - cloudtruth.projects.<project_name>.templates.<name> - references a template from the specified project\n              - cloudtruth.projects.<project_name>.parameters.<name> - references a parameter from the specified project",
            },
          },
          required: ['name', 'body'],
        },
      },
    },
  },
}

export const getProjects = {
  name: 'get_projects',
  description: 'Gets and returns all project names',
}

export const getCurrentProject = {
  name: 'get_current_project',
  description: 'Gets the name of the current project context.',
}

export const createProject = {
  name: 'create_project',
  description:
    'create a project in our system. Parameters and templates belong to a project. So if a user asks to create a project then asks to create a template or parameter using this project, the project should come first in the function calling order. You should always have a follow up with a list of parameters before creating. If the message contains files list each file contained in the message along with the parameters to be created from each file.',
  parameters: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        description: 'The name of the project',
      },
      description: {
        type: 'boolean',
        description: 'The description of the project. This is optional.',
      },
      depends_on: {
        type: 'string',
        description:
          "A project can be a child of another project. If this project is a child of another project, specify the parent project's name here. If this project is not a child of another project, this should be undefined or null.",
      },
    },
    required: ['name'],
  },
}

//
// Copyright (C) 2023 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPull, ModeEnum } from 'gen/cloudTruthRestApi'
import { GetAwsPull, GetAwsPulls } from 'data/actions/awsImport/actions'
import { GetAzureKeyVaultPull, GetAzureKeyVaultPulls } from 'data/actions/akvImport/actions'
import { GetGithubPull, GetGithubPulls } from 'data/actions/githubImport/actions'
import { QueryParamName, useQuery } from 'router/customHooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableTitle, resizableColumns } from 'components/Table/ResizableTitle'
import { TableProps, Typography } from 'antd'
import {
  akvIntegrationEntitySelectors,
  useSelectAkvIntegrations,
} from 'data/integration/akvIntegration/selectors'
import { akvPullEntitySelectors, useSelectAkvPull } from 'data/actions/akvImport/selectors'
import {
  awsIntegrationEntitySelectors,
  useSelectAwsIntegrations,
} from 'data/integration/awsIntegration/selectors'
import { displayStatus, formatAwsPushService, pullIds } from 'components/Actions/utils'
import { getLocalSession, setLocalSession, storedColumnSize } from 'lib/sessionPersistance'
import {
  githubIntegrationEntitySelectors,
  useSelectGithubIntegrations,
} from 'data/integration/githubIntegration/selectors'
import { githubPullEntitySelectors, useSelectGithubPull } from 'data/actions/githubImport/selectors'
import {
  integrationIdFromAkvPullUrl,
  integrationIdFromAwsPullUrl,
  integrationIdFromGithubPullUrl,
} from 'data/dataUtils'
import { useAppDispatch, useAppSelector } from 'data/hooks'

import { ActionEnum } from 'data/actions/utils'
import { ActionViews } from '../ActionContainer'
import { ImportDetailsDropdown } from './ImportDetails/ImportDetailsDropdown'
import { ImportTableButtons } from './ImportTableButtons'
import { Link } from 'react-router-dom'
import { Reload } from '../ActionsReload/Reload'
import { Table } from 'components/Table'
import { TableTitle } from 'components/TableTitle'
import { getCurrentOrganization } from 'data/organization/selectors'
import { getIntegrationPulls } from 'data/actions/awsImport/selectors'
import { getPolicy } from 'data/membership/selectors'
import styles from './ImportTable.module.scss'

const { Text } = Typography

interface Props {
  awsIntegrationId?: string
  mode?: ModeEnum
  actionType: ActionEnum
}

export function ImportTable(props: Props) {
  const { awsIntegrationId, mode = ModeEnum.Pattern, actionType } = props
  const { canContribute } = useAppSelector(getPolicy(null))
  const awsIntegrations = awsIntegrationEntitySelectors.selectIds(useSelectAwsIntegrations())
  const akvIntegrations = akvIntegrationEntitySelectors.selectIds(useSelectAkvIntegrations())
  const githubIntegrations = githubIntegrationEntitySelectors.selectIds(
    useSelectGithubIntegrations()
  )
  const awsIntegrationList = awsIntegrationEntitySelectors.selectAll(useSelectAwsIntegrations())
  const awsEntities = awsIntegrationEntitySelectors.selectEntities(useSelectAwsIntegrations())
  const akvEntities = akvIntegrationEntitySelectors.selectEntities(useSelectAkvIntegrations())
  const githubEntities = githubIntegrationEntitySelectors.selectEntities(
    useSelectGithubIntegrations()
  )
  const cachedAwsIntegrations = useAppSelector((state) => state.awsPull.cachedIntegrations)
  const cachedAkvIntegrations = useAppSelector((state) => state.akvPull.cachedIntegrations)
  const cachedGithubIntegrations = useAppSelector((state) => state.githubPull.cachedIntegrations)

  const awsPulls = useAppSelector(getIntegrationPulls(awsIntegrationId)).filter((pull) =>
    mode === 'mapped' ? pull.mode === 'mapped' : pull.mode !== 'mapped'
  )

  const akvPulls = akvPullEntitySelectors
    .selectAll(useSelectAkvPull())
    .filter((pull) => (mode === 'mapped' ? pull.mode === 'mapped' : pull.mode !== 'mapped'))

  const githubPulls = githubPullEntitySelectors.selectAll(useSelectGithubPull())

  const imports = useMemo(() => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return awsPulls

      case ActionEnum.AzureKeyVaultPull:
        return akvPulls

      case ActionEnum.GithubPull:
        return githubPulls

      default:
        return []
    }
  }, [actionType, akvPulls, awsPulls, githubPulls])

  const [loading, setLoading] = useState<boolean>(false)
  const { getQuery } = useQuery()
  const dispatch = useAppDispatch()

  const pullLink = useCallback(
    (pullUrl: string, actionId: string) => {
      switch (actionType) {
        case ActionEnum.AwsPull:
          return `/organization/actions${ActionViews.Import}/aws/${integrationIdFromAwsPullUrl(
            pullUrl
          )}/${actionId}`

        case ActionEnum.AzureKeyVaultPull:
          return `/organization/actions${ActionViews.Import}/akv/${integrationIdFromAkvPullUrl(
            pullUrl
          )}/${actionId}`

        case ActionEnum.GithubPull:
          return `/organization/actions${
            ActionViews.Import
          }/github/${integrationIdFromGithubPullUrl(pullUrl)}/${actionId}`

        default:
          return ''
      }
    },
    [actionType]
  )

  const loadPull = useCallback(
    (integrationId: string, importId: string) => {
      setLoading(true)

      switch (actionType) {
        case ActionEnum.AwsPull:
          return dispatch(GetAwsPull({ integrationId, actionId: importId })).then(() =>
            setLoading(false)
          )

        case ActionEnum.AzureKeyVaultPull:
          return dispatch(GetAzureKeyVaultPull({ integrationId, actionId: importId })).then(() =>
            setLoading(false)
          )

        case ActionEnum.GithubPull:
          return dispatch(GetGithubPull({ integrationId, actionId: importId })).then(() =>
            setLoading(false)
          )

        default:
          return
      }
    },
    [dispatch, actionType]
  )

  const reloadImports = useCallback(() => {
    setLoading(true)

    const reloadAwsPulls = () => {
      const promises = awsIntegrations.map((id) => {
        return new Promise((resolve) =>
          dispatch(GetAwsPulls(id.toString())).then(() => {
            resolve({})
          })
        )
      })
      Promise.all(promises).then(() => setLoading(false))
    }

    const reloadAkvPulls = () => {
      const promises = akvIntegrations.map((id) => {
        return new Promise((resolve) =>
          dispatch(GetAzureKeyVaultPulls(id.toString())).then(() => {
            resolve({})
          })
        )
      })
      Promise.all(promises).then(() => setLoading(false))
    }

    const reloadGithubPulls = () => {
      const promises = githubIntegrations.map((id) => {
        return new Promise((resolve) =>
          dispatch(GetGithubPulls(id.toString())).then(() => {
            resolve({})
          })
        )
      })
      Promise.all(promises).then(() => setLoading(false))
    }

    switch (actionType) {
      case ActionEnum.AwsPull: {
        reloadAwsPulls()
        break
      }
      case ActionEnum.AzureKeyVaultPull: {
        reloadAkvPulls()
        break
      }

      case ActionEnum.GithubPull: {
        reloadGithubPulls()
        break
      }

      default:
        break
    }
  }, [awsIntegrations, dispatch, actionType, akvIntegrations, githubIntegrations])

  useEffect(() => {
    setLoading(true)

    const done = () => {
      setLoading(false)
    }

    const getAwsPulls = () => {
      if (awsIntegrationId) {
        if (cachedAwsIntegrations.includes(awsIntegrationId)) {
          done()
        } else {
          dispatch(GetAwsPulls(awsIntegrationId)).then(() => done())
        }
      } else {
        const promises = awsIntegrations
          .filter((id) => !cachedAwsIntegrations.includes(id.toString()))
          .map((id) => {
            return new Promise((resolve) =>
              dispatch(GetAwsPulls(id.toString())).then(() => {
                resolve({})
              })
            )
          })

        Promise.all(promises).then(() => done())
      }
    }

    const getAkvPulls = () => {
      const promises = akvIntegrations
        .filter((id) => !cachedAkvIntegrations.includes(id.toString()))
        .map((id) => {
          return new Promise((resolve) =>
            dispatch(GetAzureKeyVaultPulls(id.toString())).then(() => {
              resolve({})
            })
          )
        })

      Promise.all(promises).then(() => done())
    }

    const getGithubPulls = () => {
      const promises = githubIntegrations
        .filter((id) => !cachedGithubIntegrations.includes(id.toString()))
        .map((id) => {
          return new Promise((resolve) =>
            dispatch(GetGithubPulls(id.toString())).then(() => {
              resolve({})
            })
          )
        })

      Promise.all(promises).then(() => done())
    }

    switch (actionType) {
      case ActionEnum.AwsPull:
        getAwsPulls()
        break
      case ActionEnum.AzureKeyVaultPull:
        getAkvPulls()
        break

      case ActionEnum.GithubPull:
        getGithubPulls()
        break

      default:
        break
    }
  }, [
    githubIntegrations,
    awsIntegrationId,
    awsIntegrations,
    akvIntegrations,
    cachedAwsIntegrations,
    dispatch,
    awsIntegrationList,
    actionType,
    cachedAkvIntegrations,
    cachedGithubIntegrations,
  ])

  const currentOrganization = useAppSelector(getCurrentOrganization)!
  const localSession = getLocalSession({ org: currentOrganization.id, pageType: 'imports' })

  const localPageSize = localSession?.pageSize
  const columnSizes = localSession?.columnSizes

  const [pageSize, setPageSize] = useState(() => {
    if (localPageSize) {
      return localPageSize
    }

    const pageSize = getQuery(QueryParamName.PageSize)
    return pageSize ? parseInt(pageSize) : 10
  })

  const [page, setPage] = useState(() => {
    const pageNumber = getQuery(QueryParamName.Page)
    return pageNumber ? parseInt(pageNumber) : 1
  })

  const setLocalPageSize = (size: number) =>
    setLocalSession({
      org: currentOrganization.id,
      pageType: 'imports',
      args: { pageSize: size },
    })

  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber)
    setPageSize(pageSize)
    setLocalPageSize(pageSize)
  }

  const renderName = useCallback(
    (url: string, name: string) => {
      switch (actionType) {
        case ActionEnum.AwsPull: {
          const awsId = integrationIdFromAwsPullUrl(url)
          return mode === ModeEnum.Mapped ? `${awsEntities[awsId]!.name}: ${name}` : name
        }
        case ActionEnum.AzureKeyVaultPull: {
          const akvId = integrationIdFromAkvPullUrl(url)
          return mode === ModeEnum.Mapped ? `${akvEntities[akvId]!.name}: ${name}` : name
        }

        case ActionEnum.GithubPull: {
          const githubId = integrationIdFromGithubPullUrl(url)
          return mode === ModeEnum.Mapped ? `${githubEntities[githubId]!.name}: ${name}` : name
        }

        default:
          return
      }
    },
    [actionType, mode, akvEntities, awsEntities, githubEntities]
  )

  const renderTitle = useCallback(() => {
    switch (actionType) {
      case ActionEnum.AwsPull:
        return mode === ModeEnum.Mapped ? 'AWS (Value Mappings)' : 'AWS (by Pattern)'
      case ActionEnum.AzureKeyVaultPull:
        return mode === ModeEnum.Mapped ? 'Azure (Value Mappings)' : 'Azure (by Pattern)'

      case ActionEnum.GithubPull:
        return mode === ModeEnum.Mapped ? 'GitHub (Value Mappings)' : 'GitHub (by Pattern)'

      default:
        return ''
    }
  }, [actionType, mode])

  const initialColumns: TableProps<AwsPull>['columns'] = useMemo(() => {
    return [
      {
        title: 'NAME',
        key: 'name',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'name', 200),
        render: (_: any, pull: AwsPull) => {
          return (
            <Link
              to={pullLink(pull.url, pull.id)}
              className={[styles.valueEllipses, styles.link].join(' ')}
            >
              {renderName(pull.url, pull.name)}
            </Link>
          )
        },
      },

      ...(mode !== ModeEnum.Mapped && actionType === ActionEnum.AwsPull
        ? [
            {
              title: 'SERVICE',
              key: 'service',
              ellipsis: true,
              width: storedColumnSize(columnSizes, 'service', 175),
              render: (_: any, pull: AwsPull) => (
                <Text ellipsis className={styles.valueEllipses}>
                  {formatAwsPushService(pull.service)}
                </Text>
              ),
            },
          ]
        : []),

      ...(mode !== ModeEnum.Mapped && actionType === ActionEnum.AwsPull
        ? [
            {
              title: 'REGION',
              key: 'region',
              ellipsis: true,
              width: storedColumnSize(columnSizes, 'region', 175),
              render: (_: any, pull: AwsPull) => (
                <Text ellipsis className={styles.valueEllipses}>
                  {pull.region}
                </Text>
              ),
            },
          ]
        : []),
      {
        title: 'STATUS',
        key: 'status',
        ellipsis: true,
        width: storedColumnSize(columnSizes, 'name', 100),
        render: (_value, pull: AwsPull) => (
          <div className={styles.valueEllipses}>{displayStatus(pull.latest_task?.state)}</div>
        ),
      },
      {
        title: '',
        width: 65,
        render: (_, pull: AwsPull) => {
          const { integrationId, actionId } = pullIds(pull.url, actionType)
          return canContribute ? (
            <div className={styles.dropdown}>
              <ImportDetailsDropdown
                actionType={actionType}
                pull={pull}
                color="black"
                refreshPull={() => loadPull(integrationId, actionId)}
              />
            </div>
          ) : null
        },
      },
    ]
  }, [canContribute, loadPull, mode, actionType, renderName, columnSizes, pullLink])

  const [columns, setColumns] = useState(initialColumns)

  useCallback(() => {
    loading && setColumns(initialColumns)
  }, [loading, initialColumns])

  return (
    <div className={styles.container}>
      <Table
        title={() => (
          <TableTitle
            title={renderTitle()}
            actionButton={
              <>
                {!loading && (
                  <div className={styles.buttonContainer}>
                    <Reload
                      loading={loading}
                      reload={() => reloadImports()}
                      listLength={imports.length}
                    />{' '}
                    {!awsIntegrationId && canContribute && mode !== ModeEnum.Mapped && (
                      <ImportTableButtons actionType={actionType} />
                    )}
                  </div>
                )}
              </>
            }
          />
        )}
        columns={
          !loading
            ? resizableColumns(columns, setColumns, currentOrganization.id, 'imports')
            : undefined
        }
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        pagination={{
          total: imports.length,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          current: page,
          onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
        }}
        tableLayout="fixed"
        dataSource={loading ? undefined : imports}
        rowKey={(pull: AwsPull) => pull.id}
        loading={loading}
        locale={{
          emptyText: (
            <>
              {!loading && (
                <div className={styles.emptyContainer}>
                  <h4 className={styles.emptyHeader}>
                    {`You do not have any imports stored in this ${
                      awsIntegrationId ? 'integration' : 'organization'
                    }.`}
                  </h4>
                </div>
              )}
            </>
          ),
        }}
      />
    </div>
  )
}

import { DownOutlined, UpOutlined } from '@ant-design/icons'

import React from 'react'
import styles from './ShowHideButton.module.scss'

interface Props {
  showSettings: boolean
  toggleShowSettings: () => void
  label: string
}

export function ShowHideButton(props: Props) {
  const { label, showSettings, toggleShowSettings } = props

  return (
    <>
      {!showSettings ? (
        <div onClick={toggleShowSettings} data-cy="show-hide-button">
          <DownOutlined className={styles.icon} />
          <span className={styles.text}>{`Show ${label}`}</span>
        </div>
      ) : (
        <div onClick={toggleShowSettings} data-cy="show-hide-button">
          <UpOutlined className={styles.icon} />
          <span className={styles.text}>{`Hide ${label}`}</span>
        </div>
      )}
    </>
  )
}

import { Parameter } from 'gen/cloudTruthRestApi'
import React from 'react'
import { Typography } from 'antd'
import styles from './ParameterTable.module.scss'
import { valueOrEmDash } from 'lib/valueHelpers'

const { Paragraph } = Typography
interface Props {
  parameter: Parameter
}

export function DescriptionColumn(props: Props) {
  const { parameter } = props
  const { description } = parameter

  return (
    <Paragraph ellipsis={{ rows: 2 }} className={styles.description} data-testid="description">
      {valueOrEmDash(description)}
    </Paragraph>
  )
}

import { ArrowUpOutlined, StopOutlined } from '@ant-design/icons'
import { FileUpload, PromptType } from './Gpt'
import React, { memo, useCallback, useMemo } from 'react'

import { Button } from 'antd'
import FileTags from './FileTags' // Importing memoized FileTags
import OpenAI from 'openai'
import { PaperClipUpload } from './PaperClipUpload'
import styles from './Textbox.module.scss'

interface Props {
  promptType: PromptType
  onSubmit: (text: string) => void
  precannedSubmit: (text: string) => void
  cancelRun: () => void
  createLoading: boolean
  getLoading: boolean
  run: boolean
  lineCount: number
  setLineCount: (height: number) => void
  selectedPrecannedItem: string
  setSelectedPrecannedItem: (item: string) => void
  getParameterLoading: boolean
  openai: OpenAI
  files: FileUpload[]
  setFiles: React.Dispatch<React.SetStateAction<FileUpload[]>>
  deleteFile: (fileId: string) => void
  setError: (message: maybe<string>) => void
  error: maybe<string>
  text: string
  setText: React.Dispatch<React.SetStateAction<string>>
  textref: React.RefObject<HTMLTextAreaElement>
  disable: boolean
}

export const Textbox = memo((props: Props) => {
  const {
    onSubmit,
    cancelRun,
    createLoading,
    getLoading,
    run,
    lineCount,
    setLineCount,
    openai,
    files,
    setFiles,
    deleteFile,
    error,
    setError,
    text,
    setText,
    textref,
    disable,
  } = props

  const disabled = getLoading || !text || text.trim().length < 1 || disable

  const handleChange = (event: any) => {
    const text = event.target.value

    if (error) {
      setError(null)
    }

    setText(text)

    const count = (text.match(/\n/g) || []).length + 1

    setLineCount(count)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault()
      if (text.trim().length > 0) {
        setText(''), setLineCount(1), onSubmit(text)
      }
    }
  }

  // Memoize deleteFile to ensure it is stable and doesn't change on re-renders
  const memoizedDeleteFile = useCallback(
    (fileId: string) => {
      deleteFile(fileId)
    },
    [deleteFile]
  )

  // Memoize files to ensure it is stable and doesn't change on re-renders
  const memoizedFiles = useMemo(() => files, [files])

  return (
    <div className={styles.container}>
      <div className={styles.textbox}>
        {memoizedFiles?.length > 0 && (
          <FileTags files={memoizedFiles} deleteFile={memoizedDeleteFile} />
        )}
        <div className={styles.textareaContainer}>
          <PaperClipUpload files={files} setFiles={setFiles} openai={openai} />

          <textarea
            ref={textref}
            autoFocus
            className={styles.textarea}
            placeholder="Send a Copilot message (Ctrl + Enter to submit)"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={text}
            disabled={createLoading || disable}
            style={{
              height: lineCount <= 10 ? `${lineCount <= 1 ? 19.4 : lineCount * 19.4}px` : '194px',
            }}
            name="prompt"
          />

          <div className={styles.buttonContainer}>
            {run && createLoading ? (
              <Button icon={<StopOutlined />} shape="circle" style={{}} onClick={cancelRun} />
            ) : (
              <Button
                icon={<ArrowUpOutlined className={disabled ? undefined : styles.arrowIcon} />}
                style={{ background: disabled ? '#EBEBE4' : 'black' }}
                shape="circle"
                onClick={() => {
                  setText(''), setLineCount(1), onSubmit(text)
                }}
                loading={createLoading}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>

      <span className={styles.betaText}>
        CloudTruth Copilot is in beta. Please review all responses carefully.
      </span>
    </div>
  )
})

import { LeftOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './BackToList.module.scss'

interface Props {
  text: string
  onLinkClick: () => void
}

export function BackToList(props: Props) {
  const { text, onLinkClick } = props

  return (
    <div onClick={onLinkClick} className={styles.container}>
      <LeftOutlined className={styles.icon} /> <span className={styles.text}>{text}</span>
    </div>
  )
}

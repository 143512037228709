//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AwsPush, PaginatedAwsPushList } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ActionIds } from '../utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface UpdateAwsPush {
  push: Partial<AwsPush>
  awsIntegrationId: string
}

interface AddAwsPush {
  push: Partial<AwsPush>
  awsIntegrationId: string
}

interface GetTaskSteps extends ActionIds {
  taskId: string
}

export const GetAwsPushes = createAsyncThunk<PaginatedAwsPushList, string, ThunkConfig>(
  'push/getAwsPushes',
  async (awsIntegrationId, thunkApi) =>
    await handleFetch(Verbs.Get, `integrations/aws/${awsIntegrationId}/pushes`, null, thunkApi)
)

export const GetAwsPush = createAsyncThunk<AwsPush, ActionIds, ThunkConfig>(
  'push/getAwsPush',
  async (getAwsPush, thunkApi) => {
    const { integrationId, actionId } = getAwsPush
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pushes/${actionId}`,
      null,
      thunkApi
    )
  }
)

export const GetAwsPushTasks = createAsyncThunk<AwsPush, ActionIds, ThunkConfig>(
  'push/getAwsPushTasks',
  async (getAwsPush, thunkApi) => {
    const { integrationId, actionId } = getAwsPush
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pushes/${actionId}/tasks`,
      null,
      thunkApi
    )
  }
)

export const GetAwsPushTaskSteps = createAsyncThunk<AwsPush, GetTaskSteps, ThunkConfig>(
  'push/getAwsPushTaskSteps',
  async (getSteps, thunkApi) => {
    const { integrationId, actionId, taskId } = getSteps
    return await handleFetch(
      Verbs.Get,
      `integrations/aws/${integrationId}/pushes/${actionId}/tasks/${taskId}/steps`,
      null,
      thunkApi
    )
  }
)

export const CreateAwsPush = createAsyncThunk<AwsPush, AddAwsPush, ThunkConfig>(
  'push/createAwsPush',
  async (addObj, thunkApi) => {
    const { push, awsIntegrationId } = addObj

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${awsIntegrationId}/pushes`,
      push,
      thunkApi
    )
  }
)

export const UpdateAwsPush = createAsyncThunk<AwsPush, UpdateAwsPush, ThunkConfig>(
  'push/updateAwsPush',
  async (updateObj, thunkApi) => {
    const { push, awsIntegrationId } = updateObj

    return await handleFetch(
      Verbs.Put,
      `integrations/aws/${awsIntegrationId}/pushes/${push.id}`,
      push,
      thunkApi
    )
  }
)

export const SyncAwsPush = createAsyncThunk<AwsPush, ActionIds, ThunkConfig>(
  'push/syncAwsPush',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/aws/${integrationId}/pushes/${actionId}/sync`,
      null,
      thunkApi
    )
  }
)

export const DeleteAwsPush = createAsyncThunk<string, ActionIds, ThunkConfig>(
  'push/deleteAwsPush',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Delete,
      `integrations/aws/${req.integrationId}/pushes/${req.actionId}`,
      null,
      thunkApi
    )
)

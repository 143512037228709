//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { ActionEnum } from 'data/actions/utils'
import React from 'react'
import { initialRegexResourceString } from '../utils'
import styles from './ResourceDescription.module.scss'

interface Props {
  pull?: boolean
  regex?: boolean
  actionType: ActionEnum
}

export function ResourceDescription(props: Props) {
  const { pull, regex, actionType } = props

  if (regex) {
    return (
      <div className={styles.regexContainer}>
        <span>
          Use a python regular expression with named capture groups that identify the environment,
          project, and parameter. You may find{' '}
          <a href="https://regex101.com/" target="_blank" rel="noreferrer">
            regular expression tester
          </a>{' '}
          helpful when building your expression.
        </span>
        <br />
        For example: <code className={styles.code}>{initialRegexResourceString(actionType)}</code>
        <br />
        <span>
          on <code className={styles.code}>/foo/bar/sam</code> will extract environment name{' '}
          <code className={styles.code}>foo</code>, project name{' '}
          <code className={styles.code}>bar</code>, and parameter name{' '}
          <code className={styles.code}>sam</code>.
        </span>
        <br />
      </div>
    )
  }

  return (
    <>
      <span>
        The following{' '}
        <a target="_blank" href="https://mustache.github.io/mustache.5.html" rel="noreferrer">
          mustache-style
        </a>{' '}
        templating substitutions can be used in the pattern match:
        <br />
        <ul style={{ listStyleType: 'none' }}>
          <li>{`{{ environment }} to ${pull ? 'locate' : 'insert'} the environment name`}</li>
          <li>{`{{ parameter }} to ${pull ? 'locate' : 'insert'} the parameter name`}</li>
          <li>{`{{ project }} to ${pull ? 'locate' : 'insert'} the project name`}</li>
        </ul>
      </span>
      <span>
        Import will only pull in values if the environment, project, and parameter name can be
        determined using the resource pattern.
        <br />
        <br />
      </span>
    </>
  )
}

import { Alert, Tooltip } from 'antd'

import React from 'react'
import styles from './ValueError.module.scss'

interface Props {
  error: string
  inTree?: boolean
}

export function ValueError(props: Props) {
  const { error, inTree } = props

  return (
    <Tooltip placement="topLeft" title={error} className={inTree ? styles.inTree : ''}>
      <Alert message="Error retrieving value." type="error" showIcon className={styles.error} />
    </Tooltip>
  )
}

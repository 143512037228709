import { Dropdown, MenuProps } from 'antd'
import { EllipsisOutlined, MoreOutlined } from '@ant-design/icons'

import React from 'react'
import styles from './MoreIconDropdown.module.scss'

interface Props {
  menu: MenuProps
  color?: string
  horizontal?: boolean
  dataCy?: string
  containerStyle?: React.CSSProperties
  iconStyles?: React.CSSProperties
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  onOpenChange?: (open: boolean) => void
  disabled?: boolean
}

export function MoreIconDropdown(props: Props) {
  const {
    menu,
    color = 'white',
    horizontal,
    dataCy,
    containerStyle,
    iconStyles,
    trigger,
    onOpenChange,
  } = props

  const config = {
    className: styles.moreIcon,
    style: { color, ...iconStyles },
  }

  if (color === 'black') {
    return (
      <Dropdown menu={menu} trigger={['click']} className={styles.dropdownV2}>
        <span
          className={styles.moreIconContainer}
          style={containerStyle}
          data-testid="dropdown"
          data-cy={dataCy}
        >
          <MoreOutlined className={styles.moreIconV2} />
        </span>
      </Dropdown>
    )
  }

  return (
    <Dropdown
      menu={menu}
      trigger={trigger ? trigger : ['click']}
      className={styles.dropdown}
      data-testid="dropdown"
      onOpenChange={onOpenChange}
    >
      <div className={styles.dropdown} data-testid="dropdown">
        {horizontal ? <EllipsisOutlined {...config} /> : <MoreOutlined {...config} />}
      </div>
    </Dropdown>
  )
}

//
// Copyright (C) 2021 CloudTruth, Inc.
// All Rights Reserved
//

import { AzureKeyVaultPush, PaginatedAzureKeyVaultPushList } from 'gen/cloudTruthRestApi'
import { ThunkConfig, Verbs, handleFetch } from 'data/dataUtils'

import { ActionIds } from '../utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface UpdateAzureKeyVaultPush {
  push: Partial<AzureKeyVaultPush>
  akvIntegrationId: string
}

interface AddAzureKeyVaultPush {
  push: Partial<AzureKeyVaultPush>
  akvIntegrationId: string
}

interface GetTaskSteps extends ActionIds {
  taskId: string
}

export const GetAzureKeyVaultPushes = createAsyncThunk<
  PaginatedAzureKeyVaultPushList,
  string,
  ThunkConfig
>(
  'push/getAzureKeyVaultPushes',
  async (akvIntegrationId, thunkApi) =>
    await handleFetch(
      Verbs.Get,
      `integrations/azure/key_vault/${akvIntegrationId}/pushes`,
      null,
      thunkApi
    )
)

export const GetAzureKeyVaultPush = createAsyncThunk<AzureKeyVaultPush, ActionIds, ThunkConfig>(
  'push/getAzureKeyVaultPush',
  async (getAzureKeyVaultPush, thunkApi) => {
    const { integrationId, actionId } = getAzureKeyVaultPush
    return await handleFetch(
      Verbs.Get,
      `integrations/azure/key_vault/${integrationId}/pushes/${actionId}`,
      null,
      thunkApi
    )
  }
)

export const GetAzureKeyVaultPushTasks = createAsyncThunk<
  AzureKeyVaultPush,
  ActionIds,
  ThunkConfig
>('push/getAzureKeyVaultPushTasks', async (getAzureKeyVaultPush, thunkApi) => {
  const { integrationId, actionId } = getAzureKeyVaultPush
  return await handleFetch(
    Verbs.Get,
    `integrations/azure/key_vault/${integrationId}/pushes/${actionId}/tasks`,
    null,
    thunkApi
  )
})

export const GetAzureKeyVaultPushTaskSteps = createAsyncThunk<
  AzureKeyVaultPush,
  GetTaskSteps,
  ThunkConfig
>('push/getAzureKeyVaultPushTaskSteps', async (getSteps, thunkApi) => {
  const { integrationId, actionId, taskId } = getSteps
  return await handleFetch(
    Verbs.Get,
    `integrations/azure/key_vault/${integrationId}/pushes/${actionId}/tasks/${taskId}/steps`,
    null,
    thunkApi
  )
})

export const CreateAzureKeyVaultPush = createAsyncThunk<
  AzureKeyVaultPush,
  AddAzureKeyVaultPush,
  ThunkConfig
>('push/createAzureKeyVaultPush', async (addObj, thunkApi) => {
  const { push, akvIntegrationId } = addObj

  return await handleFetch(
    Verbs.Post,
    `integrations/azure/key_vault/${akvIntegrationId}/pushes`,
    push,
    thunkApi
  )
})

export const UpdateAzureKeyVaultPush = createAsyncThunk<
  AzureKeyVaultPush,
  UpdateAzureKeyVaultPush,
  ThunkConfig
>('push/updateAzureKeyVaultPush', async (updateObj, thunkApi) => {
  const { push, akvIntegrationId } = updateObj

  return await handleFetch(
    Verbs.Put,
    `integrations/azure/key_vault/${akvIntegrationId}/pushes/${push.id}`,
    push,
    thunkApi
  )
})

export const SyncAzureKeyVaultPush = createAsyncThunk<AzureKeyVaultPush, ActionIds, ThunkConfig>(
  'push/syncAzureKeyVaultPush',
  async (syncObj, thunkApi) => {
    const { actionId, integrationId } = syncObj

    return await handleFetch(
      Verbs.Post,
      `integrations/azure/key_vault/${integrationId}/pushes/${actionId}/sync`,
      null,
      thunkApi
    )
  }
)

export const DeleteAzureKeyVaultPush = createAsyncThunk<string, ActionIds, ThunkConfig>(
  'push/deleteAzureKeyVaultPush',
  async (req, thunkApi) =>
    await handleFetch(
      Verbs.Delete,
      `integrations/azure/key_vault/${req.integrationId}/pushes/${req.actionId}`,
      null,
      thunkApi
    )
)

import { ActionButton } from '../ActionButton'
import { Modal } from 'antd'
import React from 'react'
import { WarningTwoTone } from '@ant-design/icons/lib'
import styles from './ShowDifferencesConfirmModal.module.scss'

interface Props {
  handleNonSecret: () => void
  handleSecret: () => void
  visible: boolean
  loading?: boolean
}

export function ShowDifferencesConfirmModal(props: Props) {
  const { visible, handleNonSecret, handleSecret, loading } = props

  return (
    <Modal footer={null} open={visible} closable={false} width="550px" destroyOnClose>
      <div className={styles.container}>
        <WarningTwoTone twoToneColor="#faad14" className={styles.warningIcon} />
        <h2>Showing differences for secret parameters require all secret values to be revealed.</h2>

        <div className={styles.buttonContainer}>
          <ActionButton onClick={handleNonSecret}>Only Show Non-Secrets</ActionButton>
          <ActionButton type="primary" danger onClick={handleSecret} loading={loading}>
            Reveal and Include Secrets
          </ActionButton>
        </div>
      </div>
    </Modal>
  )
}
